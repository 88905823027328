import { BATTLESHIP, CALYPSO, CANDY_APPLE, LORAX, MARIGOLD, NORMAN, OBSIDIAN, OZ, THUNDERDOME } from 'HubStyleTokens/colors';

// Should stay in sync with https://git.hubteam.com/HubSpot/PropertiesExtensions/blob/dfa89e7d25306f76b89402adb93157a5e756de97/PropertiesExtensionsModels/src/main/java/com/hubspot/properties/extensions/models/decorators/DecoratorColor.java#L3-L3
export const decoratorColorToHexColorMap = {
  // IMPORTANT: The order of these determine how they are displayed in the UI
  GRAY: BATTLESHIP,
  GREEN: OZ,
  YELLOW: MARIGOLD,
  RED: CANDY_APPLE,
  BLUE: CALYPSO,
  ORANGE: LORAX,
  BLACK: OBSIDIAN,
  PINK: NORMAN,
  PURPLE: THUNDERDOME
};
export const asDecoratorColor = color => {
  if (color in decoratorColorToHexColorMap) {
    return color;
  }
  return undefined;
};

// Should stay in sync with https://git.hubteam.com/HubSpot/PropertiesExtensions/blob/dfa89e7d25306f76b89402adb93157a5e756de97/PropertiesExtensionsModels/src/main/java/com/hubspot/properties/extensions/models/decorators/OptionDecoratorStyle.java#L9
export const OptionDecoratorStyles = {
  LABEL_ONLY: 'LABEL_ONLY',
  LABEL_WITH_COLOR: 'LABEL_WITH_COLOR'
};
export const {
  LABEL_ONLY,
  LABEL_WITH_COLOR
} = OptionDecoratorStyles;
export const asOptionDecoratorStyle = value => {
  if (value in OptionDecoratorStyles) {
    return value;
  }
  return undefined;
};