import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
import { useEffect } from 'react';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'customer-data-objects-ui-compo... Remove this comment to see the full error message
import { AnyCrmObjectTypePropType } from 'customer-data-objects-ui-components/propTypes/CrmObjectTypes';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_ui/profile/shortcuts/Keybo... Remove this comment to see the full error message
import { createKeyboardShortcut } from 'crm_ui/profile/shortcuts/KeyboardShortcut';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_ui/profile/shortcuts/Profi... Remove this comment to see the full error message
import { createShortcutHandler } from 'crm_ui/profile/shortcuts/ProfileKeyboardShortcutHandler';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_ui/profile/shortcuts/KeyCo... Remove this comment to see the full error message
import KeyCodes, { MODIFIER_KEYS } from 'crm_ui/profile/shortcuts/KeyCodes';
import { keyCombination, anyOf, exactly
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_ui/profile/shortcuts/KeyTe... Remove this comment to see the full error message
} from 'crm_ui/profile/shortcuts/KeyTesters';
export default function PageRefreshUsageLogger({
  objectType
}) {
  const logPageRefresh = function logPageRefresh() {
    CrmLogger.logImmediate('pageRefresh', {
      objectType
    });
  };
  const refreshShortcutCmdR = createKeyboardShortcut(logPageRefresh, keyCombination(anyOf(...MODIFIER_KEYS), exactly(KeyCodes.R_KEY)));
  const refreshShortcutF5 = createKeyboardShortcut(logPageRefresh, exactly(KeyCodes.F5_KEY));
  const shortcutHandler = createShortcutHandler(refreshShortcutCmdR, refreshShortcutF5);
  useEffect(() => shortcutHandler.initializeShortcutListener(false));
  return null;
}
PageRefreshUsageLogger.propTypes = {
  objectType: AnyCrmObjectTypePropType
};