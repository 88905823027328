import { useQuery } from '@apollo/client';
import { FETCH_ASSOCIATED_OBJECTS_CARD_PROPERTY_DEFINITIONS } from '../queries';
import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export function useFetchPropertyDefinitions({
  configPropertyNames = [],
  toObjectTypeId
}) {
  const explicitlyReferencedPropertyDefinition = toObjectTypeId === CONTACT_TYPE_ID ? ['jobtitle'] : [];
  const dedupedPropertyNames = new Set([...configPropertyNames, ...explicitlyReferencedPropertyDefinition]);
  const propertyNames = Array.from(dedupedPropertyNames);
  return useQuery(FETCH_ASSOCIATED_OBJECTS_CARD_PROPERTY_DEFINITIONS, {
    variables: {
      toObjectTypeId,
      properties: propertyNames
    }
  });
}
export function getPropertyDefinition(propertyName, propertyDefinitions) {
  if (!propertyDefinitions || !propertyDefinitions.objectTypeDefinition || !propertyDefinitions.objectTypeDefinition.propertyDefinitions) {
    return null;
  }
  return propertyDefinitions.objectTypeDefinition.propertyDefinitions.find(propertyDefinition => propertyDefinition.name === propertyName) || null;
}