'use es6';

import { List } from 'immutable';
const MERGE_TAGS = List(['CONTACT', 'COMPANY', 'SENDER', 'PLACEHOLDER', 'DEAL', 'TICKET']);
export default (({
  includeTicketTokens,
  includeCustomTokens
}) => {
  const filteredTags = [];
  if (!includeTicketTokens) {
    filteredTags.push('TICKET');
  }
  if (!includeCustomTokens) {
    filteredTags.push('PLACEHOLDER');
  }
  return MERGE_TAGS.filter(tag => filteredTags.indexOf(tag) === -1).toArray();
});