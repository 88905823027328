'use es6';

import dispatcher from 'dispatcher/dispatcher';
import { defineLazyKeyStore } from '../store/LazyKeyStore';
import { isLegacyObjectType } from 'customer-data-objects/types/LegacyObjectType';
import { isObjectTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { registerLazyKeyService } from '../store/LazyKeyStore';
import makeBatch from 'crm_data/api/makeBatch';
import { fetchPipelinePermissions } from './PipelinePermissionsAPI';
export const PIPELINE_PERMISSIONS_NAMESPACE = 'PIPELINE_PERMISSIONS';
registerLazyKeyService({
  namespace: PIPELINE_PERMISSIONS_NAMESPACE,
  fetch: makeBatch(fetchPipelinePermissions)
});
const PipelinePermissionsStore = defineLazyKeyStore({
  namespace: PIPELINE_PERMISSIONS_NAMESPACE,
  idIsValid: objectTypeOrId => isLegacyObjectType(objectTypeOrId) || isObjectTypeId(objectTypeOrId)
}).defineName('PipelinePermissionsStore').register(dispatcher);
export default PipelinePermissionsStore;