// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { dispatch } from 'crm_data/flux/dispatch';
import { fetchComments, addComment, editComment, deleteComment } from './TimelineCommentsAPI';
import { TIMELINE_COMMENT_CREATED, TIMELINE_COMMENT_EDITED, TIMELINE_COMMENT_DELETED, TIMELINE_COMMENT_FETCHED_MORE
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { transact } from 'crm_data/flux/transact';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { dispatchImmediate } from 'crm_data/dispatch/Dispatch';
export function createComment({
  engagementId,
  message,
  atMentionsOwnerIds,
  ctaUrl
}) {
  return addComment(engagementId, message, atMentionsOwnerIds, ctaUrl).then(response => {
    dispatch(TIMELINE_COMMENT_CREATED, {
      engagementId,
      response
    });
  });
}
export function fetchMoreComments(engagementId, offset) {
  return fetchComments(engagementId, offset).then(response => {
    dispatchImmediate(TIMELINE_COMMENT_FETCHED_MORE, {
      engagementId,
      response
    });
  });
}
export function updateComment({
  engagementId,
  commentId,
  message,
  atMentionsOwnerIds,
  ctaUrl
}) {
  return transact({
    operation() {
      return editComment(commentId, message, atMentionsOwnerIds, ctaUrl);
    },
    commit: [TIMELINE_COMMENT_EDITED, {
      engagementId,
      commentId,
      message,
      atMentionedUsers: atMentionsOwnerIds
    }],
    rollback: [TIMELINE_COMMENT_EDITED, {}]
  });
}
export function deleteTimelineComment(engagementId, commentId, onSuccess, onFailed) {
  return deleteComment(commentId).then(() => {
    if (onSuccess) {
      onSuccess();
    }
    dispatch(TIMELINE_COMMENT_DELETED, {
      engagementId,
      commentId
    });
  }).catch(error => onFailed(error));
}