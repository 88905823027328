'use es6';

import { DropTarget } from 'react-dnd';
const noop = () => ({});
export default ((Component, collect = noop) => DropTarget(({
  dragGroup
}) => dragGroup, {
  drop(props, monitor, component) {
    const {
      onDndDrop
    } = props;
    if (typeof onDndDrop === 'function') {
      onDndDrop(props, monitor, component);
    }
  },
  hover(props, monitor, component) {
    const {
      onDndHover
    } = props;
    if (typeof onDndHover === 'function') {
      onDndHover(props, monitor, component);
    }
  },
  canDrop(props, monitor) {
    const {
      canDrop
    } = props;
    if (typeof canDrop === 'function') {
      return canDrop(props, monitor);
    }
    return true;
  }
}, (connect, monitor) => Object.assign({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDropResult: monitor.canDrop(),
  isOverCurrent: monitor.isOver({
    shallow: true
  })
}, collect(connect, monitor)))(Component));
export const WEBPACK_3_FORCE_MODULE_IMPORT = 1;