import { findContainerWithCard } from './findContainerWithCard';
export const getIsTimelineContainer = content => {
  if (content.type !== 'CONTAINER') {
    return false;
  }
  return findContainerWithCard(content, {
    cardType: 'RECORD_TIMELINE'
  }) !== undefined;
};
export const findTimelineContainer = locationData => {
  if (locationData) {
    return findContainerWithCard(locationData.container, {
      cardType: 'RECORD_TIMELINE'
    });
  }
  return undefined;
};