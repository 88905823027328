// The following disable directive for no-restricted-imports is intentional. We need to import it for setup.
// eslint-disable-next-line no-restricted-imports
import GlobalCurrentTeamsContainer from 'crm-legacy-global-containers/GlobalCurrentTeamsContainer';
import CurrentTeamsContainer from '../containers/CurrentTeamsContainer';
export const setupCurrentTeamsContainer = auth => {
  const {
    user
  } = auth;
  const teams = user.teams ? user.teams.map(team => team.id) : [];
  CurrentTeamsContainer.set(teams);
  GlobalCurrentTeamsContainer.setContainer(CurrentTeamsContainer);
};