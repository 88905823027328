import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { registerQuery, useQuery } from 'data-fetching-client';
import userInfo from 'hub-http/userInfo';
const USER_INFO_QUERY = registerQuery({
  fieldName: 'userInfo',
  fetcher: () => userInfo()
});
export const useUserInfo = () => {
  const _useQuery = useQuery(USER_INFO_QUERY),
    {
      data
    } = _useQuery,
    query = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return Object.assign({}, query, {
    userInfo: data === null || data === void 0 ? void 0 : data.userInfo
  });
};