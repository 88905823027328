'use es6';

import isFunction from 'transmute/isFunction';
import GenericPluginGroup from './GenericPluginGroup';
export default ((options, ...plugins) => {
  if (isFunction(options)) {
    // options are optional
    plugins = [options, ...plugins];
    options = {};
  }
  return GenericPluginGroup(plugins, Object.assign({}, options, {
    className: `draft-extend-popover-controls p-x-2 p-top-2 ${options.className}`
  }));
});