/* hs-eslint ignored failing-rules */

import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { fillExtraEventOptionsJS } from '../helpers/fillExtraEventOptions';
import Raven from 'raven-js';
export const UpdateTimelineFilters = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"mutation\",\"name\":{\"kind\":\"Name\",\"value\":\"UpdateTimelineFilters\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"UpdateUserSelectedTimelineFiltersInput\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"updateUserSelectedTimelineFilters\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"timeline\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"filterGroups\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"filters\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"selected\"}}]}}]}}]}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "UpdateTimelineFilters"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "input"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "UpdateUserSelectedTimelineFiltersInput"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "updateUserSelectedTimelineFilters"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "input"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "input"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "timeline"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "filterGroups"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "filters"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "id"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "selected"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
});
const _getExpectedResponse = ({
  cachedFiltersData,
  selectedEventFilters
}) => {
  if (!cachedFiltersData) {
    return null;
  }
  const updatedFilterGroups = cachedFiltersData.crmObject.timeline.filterGroups.map(filterGroup => ({
    id: filterGroup.id,
    __typename: 'TimelineFilterGroup',
    filters: filterGroup.filters.map(filter => ({
      id: filter.id,
      __typename: 'TimelineFilter',
      selected: selectedEventFilters.includes(filter.internalName)
    }))
  }));
  return {
    timeline: {
      __typename: 'TimelineData',
      filterGroups: updatedFilterGroups
    }
  };
};
export const useUpdateTimelineFilters = () => {
  const [updateTimelineFilters] = useMutation(UpdateTimelineFilters);
  return {
    updateTimelineFilters: useCallback(({
      objectTypeOrId,
      cachedFiltersData,
      selectedEventFilters
    }) => {
      const selectedEventFiltersWithExtras = fillExtraEventOptionsJS({
        objectTypeOrId,
        eventTypes: selectedEventFilters
      });
      const selectedFiltersInput = selectedEventFiltersWithExtras.map(internalName => ({
        internalName
      }));
      const expectedResponse = _getExpectedResponse({
        cachedFiltersData,
        selectedEventFilters
      });
      updateTimelineFilters({
        variables: {
          input: {
            objectTypeId: normalizeTypeId(objectTypeOrId),
            selectedFilters: selectedFiltersInput
          }
        },
        optimisticResponse: {
          updateUserSelectedTimelineFilters: expectedResponse
        }
      }).catch(error => {
        console.error(error);
        Raven.captureException(error, {
          tags: {
            apiError: 'Error updating timeline filters'
          }
        });
      });
    }, [updateTimelineFilters])
  };
};