'use es6';

import { Record } from 'immutable';
import { ENABLED, DISABLED } from './ConnectedAccountFeatureStates';
const EmailIntegrationRecord = Record({
  type: null,
  state: DISABLED
}, 'EmailIntegrationRecord');
export default class EmailIntegration extends EmailIntegrationRecord {
  exists() {
    return !!this.type;
  }
  isEnabled() {
    return this.state === ENABLED;
  }
  static fromJS(json) {
    if (!json || typeof json !== 'object') {
      return null;
    }
    return new EmailIntegration(json);
  }
}