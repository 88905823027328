import PropTypes from 'prop-types';
import PinnedEngagementsStore from '../stores/PinnedEngagementsStore';
// @ts-expect-error FIXME: Could not find a declaration file
import { AnyCrmObjectTypePropType } from 'customer-data-objects-ui-components/propTypes/CrmObjectTypes';
import { Map as ImmutableMap } from 'immutable';
// @ts-expect-error FIXME: Could not find a declaration file
import { isLoading, LOADING } from 'crm_data/flux/LoadingStatus';
const PinnedEngagementIdDependency = {
  propTypes: {
    objectType: AnyCrmObjectTypePropType.isRequired,
    subjectId: PropTypes.string.isRequired
  },
  stores: [PinnedEngagementsStore],
  deref({
    objectType,
    subjectId
  }) {
    const pinnedEngagementMetadata = PinnedEngagementsStore.get(ImmutableMap({
      objectType,
      subjectId
    }));
    if (isLoading(pinnedEngagementMetadata)) {
      return LOADING;
    }
    return pinnedEngagementMetadata.get('engagementId');
  }
};
export default PinnedEngagementIdDependency;