import { registerQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
// @ts-expect-error Untyped module
import { catchAndRethrowNetworkError } from 'crm_data/bCommerce/monitoring/errorUtils';
const MULTI_CURRENCY_ENDPOINT = 'multi-currency/v2/currencies/information';
const paymentLinksQueryName = 'paymentLinks';
export const getPaymentLinksQuery = registerQuery({
  fieldName: paymentLinksQueryName,
  args: [],
  fetcher: () => {
    return http.get('sales-checkout/v2/session-templates/active');
  }
});
const associatedObjectIdsQueryName = 'associatedObjectIds';
export const getAssociatedObjectIdsQuery = registerQuery({
  fieldName: associatedObjectIdsQueryName,
  args: ['fromObjectId', 'category', 'definitionId'],
  fetcher: ({
    fromObjectId,
    category,
    definitionId
  }) => {
    return http.get(`/crm-associations/v1/associations/${fromObjectId}/${category}/${definitionId}`);
  }
});
const validatedLineItemsQueryName = 'validateLineItems';
export const getValidatedLineItemsQuery = registerQuery({
  fieldName: validatedLineItemsQueryName,
  fetcher: ({
    lineItemObjectIds,
    portalId,
    sourceCurrency
  }) => {
    if (sourceCurrency) {
      return http.post(`/sales-checkout/v1/validate/line-items?portalId=${portalId}&sourceCurrency=${sourceCurrency}`, {
        data: lineItemObjectIds
      });
    }
    return http.post(`/sales-checkout/v1/validate/line-items?portalId=${portalId}`, {
      data: lineItemObjectIds
    });
  }
});
const fetchCurrencyInformation = () => http.get(MULTI_CURRENCY_ENDPOINT).catch(catchAndRethrowNetworkError);
export const FETCH_CURRENCY_INFORMATION = registerQuery({
  fieldName: 'currencyInformation',
  fetcher: fetchCurrencyInformation
});