import { ChickletHoverActions, ChickletPrimaryDisplayLabel, ChickletSecondaryDisplayLabels, CardEmptyState, CardAction } from '../constants/BehaviorTypes';
import getSubscriptionChickletHoverActions from '../behaviors/subscription/getSubscriptionChickletHoverActions';
import SubscriptionCardEmptyState from '../behaviors/subscription/SubscriptionCardEmptyState';
import SubscriptionCardAction from '../behaviors/subscription/SubscriptionCardAction';
import { SubscriptionChickletPrimaryDisplayLabel } from '../behaviors/subscription/SubscriptionChickletPrimaryDisplayLabel';
export const Subscription = {
  [CardAction]: () => SubscriptionCardAction,
  [CardEmptyState]: () => SubscriptionCardEmptyState,
  [ChickletHoverActions]: () => getSubscriptionChickletHoverActions,
  [ChickletPrimaryDisplayLabel]: () => SubscriptionChickletPrimaryDisplayLabel,
  [ChickletSecondaryDisplayLabels]: () => () => {
    return {
      copyDisabled: true
    };
  }
};