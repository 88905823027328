import { Map as ImmutableMap } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { defineKeyStore } from 'crm_data/store/KeyStore';
import { SET_FAVORITE_EVENT_OVERRIDE, CLEAR_FAVORITE_EVENT_OVERRIDE, FETCH_TIMELINE_ITEMS_SUCCESS, FETCHING_INITIAL_TIMELINE_EVENTS_STARTED
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
import { isLegacyObjectType } from 'customer-data-objects/types/LegacyObjectType';
import { isObjectTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
export default defineKeyStore({
  namespace: 'TIMELINE_FAVORITE_EVENTS_OVERRIDE',
  idIsValid: objectTypeOrId => isLegacyObjectType(objectTypeOrId) || isObjectTypeId(objectTypeOrId)
}).defineResponseTo(SET_FAVORITE_EVENT_OVERRIDE, (state, {
  objectType,
  eventTypes
}) => {
  return state.set(objectType, ImmutableMap({
    isLoading: true,
    eventTypes
  }));
}).defineResponseTo(FETCH_TIMELINE_ITEMS_SUCCESS, (state, data) => {
  const objectType = data.getIn(['originalPayload', 'objectType']);
  if (state.get(objectType)) {
    return state.setIn([objectType, 'isLoading'], false);
  }
  return state;
}).defineResponseTo(CLEAR_FAVORITE_EVENT_OVERRIDE, (state, {
  objectType
}) => {
  return state.delete(objectType);
}).defineResponseTo(FETCHING_INITIAL_TIMELINE_EVENTS_STARTED, state => {
  return state.clear();
}).register();