'use es6';

import { toCrmObjectKey, fromCrmObjectKey } from 'customer-data-objects/crmObject/CrmObjectKey';
import userInfo from 'hub-http/userInfo';
import { CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
import invariant from 'react-utils/invariant';
import { List, Map as ImmutableMap } from 'immutable';
import { PUT, DELETE } from 'crm_data/constants/HTTPVerbs';
import CrmObjectRecord from 'customer-data-objects/crmObject/CrmObjectRecord';
import http from 'hub-http/clients/apiClient';
const BASE_URI = 'inbounddb-objects/v1/crm-objects';
const parseKeys = keys => keys.reduce((acc, id) => {
  const {
    objectId,
    objectTypeId
  } = fromCrmObjectKey(id);
  const isValidKey = acc.objectTypeId === undefined || objectTypeId === acc.objectTypeId;
  invariant(isValidKey, 'CrmObjectAPI: All keys must have the same objectTypeId');
  return {
    ids: [...acc.ids, objectId],
    objectTypeId
  };
}, {
  ids: [],
  objectTypeId: undefined
});
const parseItems = result => result.mapEntries(([objectId, object]) => [toCrmObjectKey({
  objectId,
  objectTypeId: object.get('objectTypeId')
}), CrmObjectRecord.fromJS(object)]);
export const fetchById = (objectTypeId, id) => {
  return http.get(`${BASE_URI}/${encodeURIComponent(objectTypeId)}/${id}?flpViewValidation=true`);
};
export const fetch = keys => {
  invariant(List.isList(keys), 'CrmObjectAPI: expected keys to be a List but got %s', keys);

  // Bail out early if there are no ids because we can't make the request URL
  if (keys.isEmpty()) {
    return Promise.resolve(ImmutableMap());
  }
  const {
    ids,
    objectTypeId
  } = parseKeys(keys);
  const query = {
    id: ids,
    allPropertiesFetchMode: 'latest_version'
  };
  const url = `${BASE_URI}/${encodeURIComponent(objectTypeId)}/batch?flpViewValidation=true`;
  return ImmutableAPI.get(url, query).then(parseItems).catch(error => {
    if (error.status === 400 && error.responseJSON && error.responseJSON.message.includes('Unable to find objectTypeIds')) {
      // The `inbounddb-objects/v1/crm-objects/{objectType}/batch` endpoint returns a
      // 400 instead an empty result if the object type doesn't exist. We want to
      // treat non-existent object types and object ids identically, as an empty result,
      // rather than throwing an error
      return ImmutableMap();
    }
    throw error;
  });
};
export function deleteObject(objectTypeId, objectId) {
  return ImmutableAPI.send({
    headers: {
      'X-Properties-Source': CRM_UI
    },
    type: DELETE
  }, `${BASE_URI}/${encodeURIComponent(objectTypeId)}/${encodeURIComponent(objectId)}`);
}
const updateCrmObjectPropertiesWithUser = (user, crmObject, propertyUpdates) => {
  const objectId = crmObject.get('objectId');
  const objectTypeId = crmObject.get('objectTypeId');
  return ImmutableAPI.send({
    headers: {
      'X-Properties-SourceId': CRM_UI
    },
    type: PUT
  }, `${BASE_URI}/${encodeURIComponent(objectTypeId)}/${encodeURIComponent(objectId)}?allPropertiesFetchMode=latest_version`, propertyUpdates.reduce((acc, value, name) => {
    acc.push({
      name,
      value,
      source: CRM_UI,
      sourceId: user.email
    });
    return acc;
  }, []), CrmObjectRecord.fromJS);
};
export const updateCrmObjectProperties = (crmObject, propertyUpdates) => {
  return userInfo().then(({
    user
  }) => {
    return updateCrmObjectPropertiesWithUser(user, crmObject, propertyUpdates);
  });
};