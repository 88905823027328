import { useCallback } from 'react';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
// @ts-expect-error File is untyped
import { openPlaybook } from 'crm_data/playbooks/actions/PlaybooksActions';
export default function PlaybookModalHandler() {
  const handleOpenPlaybook = useCallback(data => {
    openPlaybook(data.playbookId);
  }, []);
  useOnCrmMessageTopic(TOPIC_NAMES.OPEN_PLAYBOOK, handleOpenPlaybook);
  return null;
}