import { ADVISOR_TO_COMPANY, BOARD_MEMBER_TO_COMPANY, CONTRACTOR_TO_COMPANY, MANAGER_TO_COMPANY, OWNER_TO_COMPANY, PARTNER_TO_COMPANY, RESELLER_TO_COMPANY, COMPANY_TO_ADVISOR, COMPANY_TO_BOARD_MEMBER, COMPANY_TO_CONTRACTOR, COMPANY_TO_MANAGER, COMPANY_TO_OWNER, COMPANY_TO_PARTNER, COMPANY_TO_RESELLER
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/associations/AssociationTypes';
export const getRelatedObjectTranslationPath = associationType => {
  switch (associationType) {
    case ADVISOR_TO_COMPANY:
      return 'cardAssociatedObjects.roles.advisor';
    case COMPANY_TO_ADVISOR:
      return 'cardAssociatedObjects.roles.advisor';
    case BOARD_MEMBER_TO_COMPANY:
      return 'cardAssociatedObjects.roles.boardMember';
    case COMPANY_TO_BOARD_MEMBER:
      return 'cardAssociatedObjects.roles.boardMember';
    case CONTRACTOR_TO_COMPANY:
      return 'cardAssociatedObjects.roles.contractor';
    case COMPANY_TO_CONTRACTOR:
      return 'cardAssociatedObjects.roles.contractor';
    case MANAGER_TO_COMPANY:
      return 'cardAssociatedObjects.roles.manager';
    case COMPANY_TO_MANAGER:
      return 'cardAssociatedObjects.roles.manager';
    case OWNER_TO_COMPANY:
      return 'cardAssociatedObjects.roles.owner';
    case COMPANY_TO_OWNER:
      return 'cardAssociatedObjects.roles.owner';
    case PARTNER_TO_COMPANY:
      return 'cardAssociatedObjects.roles.partner';
    case COMPANY_TO_PARTNER:
      return 'cardAssociatedObjects.roles.partner';
    case RESELLER_TO_COMPANY:
      return 'cardAssociatedObjects.roles.reseller';
    case COMPANY_TO_RESELLER:
      return 'cardAssociatedObjects.roles.reseller';
    default:
      return null;
  }
};