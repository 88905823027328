import { createTracker } from 'usage-tracker';
import Raven from 'raven-js';
import events from '../events.yaml';
const DEFAULT_TRACKER_PROPS = {
  events,
  properties: {
    namespace: 'commerce-billing'
  },
  onError: err => {
    Raven.captureMessage('commerce-billing-lib tracker event error', {
      stacktrace: true,
      extra: {
        message: err.message
      }
    });
  }
};
export const tracker = createTracker(DEFAULT_TRACKER_PROPS);