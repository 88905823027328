'use es6';

import { compose } from 'draft-extend';
import FileDropPlugin from './FileDropPlugin';
export default (options => (...plugins) => WrappingComponent => {
  if (plugins.length === 0) {
    return WrappingComponent;
  }
  const pluginComposition = compose(FileDropPlugin(options), ...plugins);
  const WrappedComponentWithPlugins = pluginComposition(WrappingComponent);
  return WrappedComponentWithPlugins;
});