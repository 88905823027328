import { useCallback } from 'react';
import { useCardLocationContext } from '../internal/CardLocationContext';
export function useGetHasFeature() {
  const {
    features
  } = useCardLocationContext();
  const getHasFeature = useCallback(feature => {
    if (features && features.has(feature)) {
      return true;
    }
    return false;
  }, [features]);
  return getHasFeature;
}