import { useCallback } from 'react';
import { useTimelineDispatch, useTimelineSelector } from '../../reduxHooks';
import { makeGetIsEventCardCommentsCollapsed, collapseComments, expandComments, setIsForcingIntersection } from '../eventCardsSlice';
import { batch } from 'react-redux';
export function useIsEventCommentsCollapsed(eventId) {
  const getIsEventCardCommentsCollapsed = makeGetIsEventCardCommentsCollapsed(eventId);
  const isCommentsCollapsed = useTimelineSelector(getIsEventCardCommentsCollapsed);
  const dispatch = useTimelineDispatch();
  const setIsCommentsCollapsed = useCallback(shouldCollapseComments => {
    if (shouldCollapseComments) {
      dispatch(collapseComments(eventId));
    } else {
      batch(() => {
        dispatch(expandComments(eventId));
        dispatch(setIsForcingIntersection(eventId));
      });
    }
  }, [dispatch, eventId]);
  return {
    isCommentsCollapsed,
    setIsCommentsCollapsed
  };
}