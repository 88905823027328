import once from 'transmute/once';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import * as LocalSettings from 'crm_data/settings/LocalSettings';
function logTimelineMountTiming() {
  // Making sure start_load_profile was actually marked
  if (window.performance.getEntriesByName('start_load_profile').length === 0) {
    return;
  }
  window.performance.measure('measure_load_profile', 'start_load_profile');

  // Making sure the performance API is working as expected
  if (window.performance.getEntriesByName('measure_load_profile').length === 0) {
    return;
  }
  const duration = window.performance.getEntriesByName('measure_load_profile')[0].duration;
  if (LocalSettings.getFrom(localStorage, 'RECORD_TIMINGS')) {
    console.log('Timeline mount time since profile load: ', duration);
  }
  if (window.newrelic) {
    window.newrelic.setCustomAttribute('load_profile', duration);
    window.newrelic.setCustomAttribute('is_direct_profile_load', Boolean(window.isDirectProfileLoad));
    if (Math.random() * 100 < 5) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      window.newrelic.addPageAction('crmProfileLoaded');
    }
  }
  window.isDirectProfileLoad = false;
}
export default once(logTimelineMountTiming);