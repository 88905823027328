import { useAssociatedObjectsCardContentContext } from '../context/AssociatedObjectsCardContext';
export function useGetIsArtificialIntelligenceAssociation({
  toObjectId
}) {
  const {
    associationAuditSourcesData
  } = useAssociatedObjectsCardContentContext();
  const matchingAudit = associationAuditSourcesData && associationAuditSourcesData.find(associationAudit => associationAudit.toObjectId === toObjectId);
  const sourceMetadata = matchingAudit ? matchingAudit.sourceMetadata : {
    source: '',
    sourceId: ''
  };
  return sourceMetadata.source === 'ACTIVITY_AUTO_ASSOCIATE' && sourceMetadata.sourceId === 'ci-auto-associations';
}