import { useCallback } from 'react';
import { makeGetEventIsForcingIntersection, removeIsForcingIntersection as removeEventIsForcingIntersection, setIsForcingIntersection as setEventIsForcingIntersection } from '../eventCardsSlice';
import { useTimelineDispatch, useTimelineSelector } from '../../reduxHooks';
export function useIsEventForcingIntersection(eventId) {
  const getEventIsForcingIntersection = makeGetEventIsForcingIntersection(eventId);
  const isForcingIntersection = useTimelineSelector(getEventIsForcingIntersection);
  const dispatch = useTimelineDispatch();
  const removeIsForcingIntersection = useCallback(() => {
    dispatch(removeEventIsForcingIntersection(eventId));
  }, [dispatch, eventId]);
  const setIsForcingIntersection = useCallback(() => {
    dispatch(setEventIsForcingIntersection(eventId));
  }, [dispatch, eventId]);
  return {
    isForcingIntersection,
    removeIsForcingIntersection,
    setIsForcingIntersection
  };
}