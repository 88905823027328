import { useFetchCrmPropertiesAIEnabled, useIsScoped, useIsUngated } from 'crm-events-data';
export const useShouldShowDataEntrySuggestions = () => {
  const isUngated = useIsUngated();
  const isScoped = useIsScoped();
  const isUngatedForDataEntryAssistant = isUngated('CRM:Events:DataEntryAssistant');
  const isScopedForDataEntrySuggestions = isScoped('crm-data-entry-suggestions-access');
  const {
    hasCrmPropertiesAIEnabled
  } = useFetchCrmPropertiesAIEnabled();
  return !!(isUngatedForDataEntryAssistant && isScopedForDataEntrySuggestions && hasCrmPropertiesAIEnabled);
};