import { useMemo } from 'react';
import { useInteractionTypeGateOverrides } from './useInteractionTypeGateOverrides';
import { INTERACTION_TYPES } from 'crm-activity-creator-data/interaction/InteractionTypes';

/** Any interactions not behind the gate and doesn't have active gate is not supported to use AC Window */
const getUnsupportedInteractions = interactionTypeGatedMap => {
  return INTERACTION_TYPES.filter(interaction => !(interactionTypeGatedMap.has(interaction) && interactionTypeGatedMap.get(interaction)));
};
export const useOverrideInteractionTypes = () => {
  const interactionTypeGatedMap = useInteractionTypeGateOverrides();
  return useMemo(() => {
    // The following types are fully supported
    // === This area intentionally left blank ===

    // The following types are not yet supported behind a gate
    const unsupportedInteractions = getUnsupportedInteractions(interactionTypeGatedMap);
    return [...unsupportedInteractions];
  }, [interactionTypeGatedMap]);
};