'use es6';

import { Record, List } from 'immutable';
import { emailFields } from 'customer-data-email/schema/email/EmailRecord';
const hubSpotHostedEmailFields = Object.assign({}, emailFields, {
  associations: List(),
  connectedAccountAddress: '',
  replyToEngagementId: undefined,
  isForward: false
});
const HubSpotHostedEmailRecord = Record(hubSpotHostedEmailFields, 'HubSpotHostedEmailRecord');
export default HubSpotHostedEmailRecord;