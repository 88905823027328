export const getObjectFromContext = context => {
  const {
    objectTypeId,
    objectId
  } = context;
  if (!objectTypeId || !objectId) {
    throw new Error('The location must provide objectTypeId and objectId for this card');
  }
  return {
    objectTypeId,
    objectId
  };
};