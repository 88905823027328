'use es6';

import { Map as ImmutableMap } from 'immutable';
import { MergeTagDefaultOptions } from '../lib/mergeTagConstants';
import getPropertiesWithDefaults from './getPropertiesWithDefaults';
let _memoizedOptions = ImmutableMap();
const generatePropertyOptions = properties => {
  return properties.toList().map(propertyGroup => {
    return {
      text: propertyGroup.get('displayName'),
      value: propertyGroup.get('name'),
      options: propertyGroup.get('properties').toArray().reduce((filteredOptions, property) => {
        if (!property.get('hidden')) {
          const isSensitive = property.get('dataSensitivity') && property.get('dataSensitivity') !== 'none';
          filteredOptions.push({
            text: property.get('label'),
            value: property.get('name'),
            disabled: isSensitive,
            isSensitive
          });
        }
        return filteredOptions;
      }, [])
    };
  }).toJS();
};
export default ((selectedType, data) => {
  if (!data) {
    return null;
  }
  const properties = getPropertiesWithDefaults(data);
  const objectType = MergeTagDefaultOptions.getIn([selectedType, 'objectType']);
  if (_memoizedOptions.has(objectType)) {
    return _memoizedOptions.get(objectType);
  }
  const objectTypeProperties = properties.get(objectType);
  const result = generatePropertyOptions(objectTypeProperties);
  _memoizedOptions = _memoizedOptions.set(objectType, result);
  return result;
});