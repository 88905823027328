// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'gene... Remove this comment to see the full error message
import { define } from 'general-store';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'disp... Remove this comment to see the full error message
import dispatcher from 'dispatcher/dispatcher';
// @ts-expect-error not typed
import * as SequenceActionTypes from 'sequences-crm-lib/flux/SequenceActionTypes';
import { normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { fetchNextTimelineEvents } from 'crm-events-data/internal/timeline/actions/TimelineFetchingActions';
import { FETCH_TIMELINE_FILTERS, getSelectedFilters } from 'crm-events-data/internal/filters/hooks/useFetchTimelineFilters';
import { fillExtraEventOptions } from 'crm-events-data/internal/filters/helpers/fillExtraEventOptions';
import { buildTypedMap } from 'crm-events-data/types';
const createSequenceEnrollmentTimelineStore = ({
  client
}) => define().defineName('SequenceEnrollmentTimelineStore').defineGet(() => null).defineResponseTo([SequenceActionTypes.ENROLL_SUCCESS, SequenceActionTypes.ENROLLMENT_UPDATE_SUCCESS, SequenceActionTypes.PAUSE_SUCCESS, SequenceActionTypes.RESUME_SUCCESS, SequenceActionTypes.UNENROLL_SUCCESS], data => {
  const objectType = data.get('objectType');
  const subjectId = `${data.get('subjectId')}`;
  client.query({
    query: FETCH_TIMELINE_FILTERS,
    variables: {
      subjectId: Number(subjectId),
      objectTypeId: normalizeTypeId(objectType)
    }
  }).then(res => {
    const eventOptions = fillExtraEventOptions(objectType, getSelectedFilters(res.data.crmObject.timeline.filterGroups));
    const payload = buildTypedMap({
      eventOptions,
      now: Date.now(),
      objectType,
      subjectId
    });
    fetchNextTimelineEvents(payload);
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
}).register(dispatcher);
export default createSequenceEnrollmentTimelineStore;