module.exports = {
  "/contact/email/:email": {},
  "/record/:objectTypeId/email/:email": {},
  "/contact-sample/:tab": {},
  "/contact-sample": {},
  "/deal/:subjectId/new": {},
  "/quote/:quoteId": {},
  "/deal/:subjectId/line-items": {},
  "/contact/:subjectId/list-memberships": {},
  "/contact/:subjectId/workflow-memberships": {},
  "/:objectType/:subjectId/sales-navigator": {
    "name": "sales-navigator",
    "success": {
      "salesNavigatorIframe": [
        "SALES_NAVIGATOR_IFRAME"
      ]
    }
  },
  "/record/:objectTypeId/:subjectId/list-memberships": {
    "name": "list-memberships",
    "success": {
      "listMemberships": [
        "LIST_MEMBERSHIPS"
      ]
    }
  },
  "/record/:objectTypeId/:subjectId/workflow-memberships": {
    "name": "workflow-memberships",
    "success": {
      "workflowMemberships": [
        "WORKFLOW_MEMBERSHIPS"
      ]
    }
  },
  "/record/:objectTypeId/:subjectId": {
    "name": "main-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/record/:objectTypeId/:subjectId/view/:tabId": {
    "name": "main-route-tab",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/companies/:subjectId": {
    "name": "companies-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/company/:subjectId": {
    "name": "companies-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/companies/:subjectId/view/:tabId": {
    "name": "companies-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/company/:subjectId/view/:tabId": {
    "name": "companies-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/contacts/:subjectId": {
    "name": "contacts-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/contact/:subjectId": {
    "name": "contacts-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/contacts/:subjectId/view/:tabId": {
    "name": "contacts-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/contact/:subjectId/view/:tabId": {
    "name": "contacts-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/deals/:subjectId": {
    "name": "deals-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/deal/:subjectId": {
    "name": "deals-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/deals/:subjectId/view/:tabId": {
    "name": "deals-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/deal/:subjectId/view/:tabId": {
    "name": "deals-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/tickets/:subjectId": {
    "name": "tickets-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/ticket/:subjectId": {
    "name": "tickets-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/tickets/:subjectId/view/:tabId": {
    "name": "tickets-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/ticket/:subjectId/view/:tabId": {
    "name": "tickets-route",
    "success": {
      "recordLoadedRightSidebarCollapsed": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "RIGHT_SIDEBAR_LOADED_COLLAPSED"
      ],
      "recordLoadedRightSidebarExpanded": [
        "LOCATION_CRM_RECORD_MIDDLE_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_LEFT_INITIAL_CARDS_READY",
        "LOCATION_CRM_RECORD_RIGHT_INITIAL_CARDS_READY"
      ],
      "notFound": [
        "RECORD_NOT_FOUND"
      ],
      "noPermissions": [
        "NO_PERMISSIONS"
      ]
    },
    "error": [
      "LEFT_SIDEBAR_ERROR",
      "RIGHT_SIDEBAR_ERROR",
      "TIMELINE_ERROR",
      "EXTENSION_PANE_ERROR",
      "PERMISSIONS_FETCH_ERROR",
      "CARD_LOCATION_MIDDLEPANE_ERROR"
    ]
  },
  "/contact": {
    "name": "redirect-screens",
    "success": {
      "redirectPageDoesNotExist": [
        "RECORD_NOT_FOUND"
      ]
    }
  },
  "/company": {
    "name": "redirect-screens",
    "success": {
      "redirectPageDoesNotExist": [
        "RECORD_NOT_FOUND"
      ]
    }
  },
  "/deal": {
    "name": "redirect-screens",
    "success": {
      "redirectPageDoesNotExist": [
        "RECORD_NOT_FOUND"
      ]
    }
  },
  "/ticket": {
    "name": "redirect-screens",
    "success": {
      "redirectPageDoesNotExist": [
        "RECORD_NOT_FOUND"
      ]
    }
  },
  "/contacts": {},
  "/tasks": {},
  "/deals": {},
  "/companies": {},
  "/tickets": {},
  "/lists": {},
  "/tasks/list/view/all": {},
  "/companies/view/all": {},
  "/objects/:objectTypeId/restore": {},
  "/:objectType/:subjectId/properties": {},
  "/:objectType/:subjectId/history/:propertyName": {},
  "/:objectType/:subjectId/history": {},
  "/record/:objectTypeId/:subjectId/properties": {},
  "/record/:objectTypeId/:subjectId/history/:propertyName": {},
  "/record/:objectTypeId/:subjectId/history": {}
};