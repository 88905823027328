import { useTheme } from '../useTheme';
export const useGradientColor = variant => {
  const {
    color
  } = useTheme();
  const gradient = {
    info: {
      default: color['fill-info-default'],
      light: color['fill-info-subtle'],
      medium: color['fill-info-hover'],
      dark: color['fill-info-pressed']
    },
    warning: {
      default: color['fill-caution-default'],
      light: color['fill-caution-subtle'],
      medium: color['fill-caution-hover'],
      dark: color['fill-caution-pressed']
    },
    danger: {
      default: color['fill-alert-default'],
      light: color['fill-alert-subtle'],
      medium: color['fill-alert-hover'],
      dark: color['fill-alert-pressed']
    }
  };
  return {
    gradient: gradient[variant]
  };
};