import { List, Map as ImmutableMap } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { EMPTY } from 'crm_data/flux/LoadingStatus';
import { parse } from 'hub-http/helpers/params';
export const getBETInitialQueryIds = (queryParams = {}) => {
  const windowQueryParams = parse(window.location.search.substring(1));
  const windowQueryParamsExist = Boolean(windowQueryParams && Object.keys(windowQueryParams).length);
  if (!Object.keys(queryParams).length && !windowQueryParamsExist) {
    return EMPTY;
  }
  const {
    portalObjectId,
    serviceObjectId
  } = windowQueryParamsExist ? windowQueryParams : queryParams;
  const portalObjectIds = Array.isArray(portalObjectId) ? portalObjectId : [portalObjectId];
  const serviceObjectIds = Array.isArray(serviceObjectId) ? serviceObjectId : [serviceObjectId];
  return ImmutableMap({
    portalObjectIds: List(portalObjectIds).filter(item => !!item),
    serviceObjectIds: List(serviceObjectIds).filter(item => !!item)
  });
};
export const setBETQueryPortalObjects = ({
  BETPortalAssociationsForQuery,
  BETInitialQueryIds,
  onSetBETAssociations
}) => {
  const portalAssociations = List(BETPortalAssociationsForQuery).filter(obj => {
    return BETInitialQueryIds.get('portalObjectIds').contains(`${obj.value}`);
  }).map(portal => {
    return ImmutableMap({
      label: portal.text,
      objectId: portal.value
    });
  });
  onSetBETAssociations('BETPortalAssociations', portalAssociations);
};
export const setBETQueryServiceObjects = ({
  BETServiceAssociationsForQuery,
  BETInitialQueryIds,
  onSetBETAssociations
}) => {
  const serviceAssociations = List(BETServiceAssociationsForQuery).filter(obj => {
    return BETInitialQueryIds.get('serviceObjectIds').contains(`${obj.value}`);
  }).map(service => {
    return ImmutableMap({
      label: service.text,
      objectId: service.value
    });
  });
  onSetBETAssociations('BETServiceAssociations', serviceAssociations);
};
export const getBetAssociationsCount = (...associationLists) => [...associationLists].reduce((count, list) => count + (list ? list.size : 0), 0);