import { useEffect, useState } from 'react';
import emptyFunction from 'react-utils/emptyFunction';
import { getIntersectionObserverManager } from '../getIntersectionObserverManager';
export const useIsIntersecting = ({
  target,
  initialIntersecting = false,
  observerOptions = {},
  onIntersectionChange
}) => {
  const [isIntersecting, setIsIntersecting] = useState(initialIntersecting);
  useEffect(() => {
    let isSubscribed = true;
    if (!target) {
      return emptyFunction;
    }
    const {
      root,
      rootMargin,
      threshold
    } = observerOptions;
    const observerManager = getIntersectionObserverManager(root, rootMargin, threshold);
    const handleIntersection = entry => {
      if (!isSubscribed) {
        return;
      }
      if (onIntersectionChange) {
        onIntersectionChange(entry, observerManager);
      }
      setIsIntersecting(entry.isIntersecting);
    };
    observerManager.subscribe(target, handleIntersection);
    return () => {
      observerManager.unsubscribe(target);
      isSubscribed = false;
    };
  }, [target, onIntersectionChange, observerOptions]);
  return {
    isIntersecting
  };
};