import { useIsScoped } from 'crm-cards-sdk/hooks/useIsScoped';
import { useCardLocationContext } from '../internal/CardLocationContext';
import { useCardData } from '../internal/CardDataContext';
import { CRM_LOCATION_NAMES } from '../constants/CrmLocationName';
import { useMemo } from 'react';
const SUPPORTED_LOCATIONS = [CRM_LOCATION_NAMES.CRM_CONTAINER_MIDDLE, CRM_LOCATION_NAMES.CRM_RECORD_MIDDLE, CRM_LOCATION_NAMES.CRM_OBJECT_PREVIEW, CRM_LOCATION_NAMES.CRM_RECORD_LEFT];
export function useCardSettingsActionConfig() {
  const {
    location,
    viewId,
    additionalLocationData: {
      objectTypeId
    },
    usageTracker
  } = useCardLocationContext();
  const {
    cardData
  } = useCardData();
  const hasEditCardScope = useIsScoped('crm-page-editor-admin');
  return useMemo(() => {
    const isSupportedLocation = SUPPORTED_LOCATIONS.includes(location);
    if (!hasEditCardScope || !viewId || !isSupportedLocation) {
      return null;
    }
    if (!objectTypeId) {
      throw new Error('The location must provide objectTypeId for this card');
    }
    if (!cardData) {
      throw new Error('cardData is missing for this card.');
    }
    const handleTrackClick = () => {
      if (usageTracker) {
        let cardType = cardData.definition.cardType;
        if (cardType === 'LEGACY_SIDEBAR_CARD') {
          cardType = cardData.configuration.singletonCardType || cardData.configuration.cardType;
        }
        usageTracker.track('crmCardInteraction', {
          action: 'Clicked on a crm card settings link',
          objectTypeId,
          cardType: cardData.definition.cardType,
          location
        });
      }
    };
    return {
      type: 'hubspot-app-link',
      iconName: 'settings',
      linkType: 'CARD_SETTINGS_V2',
      linkTypeProps: {
        objectTypeId,
        containerViewId: viewId,
        cardId: cardData.definition.cardId,
        location
      },
      onTrackClick: handleTrackClick
    };
  }, [objectTypeId, viewId, cardData, location, hasEditCardScope, usageTracker]);
}