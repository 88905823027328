import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
const eventName = 'use-android-app-installprompt';
window.addEventListener('beforeinstallprompt', evt => {
  CrmLogger.log(eventName, {
    what_event_subtype: 'seen'
  });

  // On Windows 10 Edge, this event can fire with an undefined `userChoice`
  // May be related to users cancelling the PWA install dialog on Windows 10
  // See https://sentry.hubteam.com/sentry/crm/issues/353616/
  if (!evt.userChoice) {
    return;
  }
  evt.userChoice.then(choiceResult => {
    const {
      platform,
      // 'android' or 'web'
      outcome // 'accepted' or 'dismissed'
    } = choiceResult;
    CrmLogger.log(eventName, {
      what_event_subtype: `${outcome} ${platform}`
    });
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
});