import { useCallback } from 'react';
import { useTimelineAuthContext } from '../context/TimelineAuthContext';

/**
 * Creates a callback that subscribes to the current `auth` object to check scopes.
 *
 * @example
 * function MyScopedFeature() {
 *   const isScoped = useIsScoped();
 *   if (!isScoped('my-feature')) {
 *     return null;
 *   }
 *   return <MyFeatureContents />;
 * }
 * @returns (scope: string) => boolean
 */
export function useIsScoped() {
  const {
    user
  } = useTimelineAuthContext();
  return useCallback(scope => {
    return user ? (user.scopes || []).includes(scope) : false;
  }, [user]);
}