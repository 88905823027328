'use es6';

export const GMAIL = 'GMAIL';
export const OUTLOOK365 = 'OUTLOOK365';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}