import I18n from 'I18n';
import { isMultienum, isEnum, isDate, isPercent, isPercentWholeNumber, isProbability, isDuration, isFormattedNumber, isBillingFrequency } from 'customer-data-objects/property/PropertyIdentifier';
// @ts-expect-error Legacy code isn't typed
import { propertyLabelTranslatorWithIsHubSpotDefined } from 'property-translator/propertyTranslator';
import { parseMultiEnumValue } from './parseMultiEnumValue';
import { EMPTY_PROPERTY_VALUE } from './constants/EmptyPropertyValue';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import unescapedText from 'I18n/utils/unescapedText';
import { DURATION_THRESHOLDS, formatDuration, getDisplayedValueForDateOrDateTime } from './utils/dateAndDateTimeFormatting';

// We can't use `UISelectProps['options']` because this lib does not have a dependency on UIComponents
// See https://git.hubteam.com/HubSpot/UIComponents/blob/master/UIComponents/static-3.5037/js/input/UISelect.d.ts
function indexBy(keyMapper) {
  return subject => subject.reduce((acc, v) => {
    acc[keyMapper(v)] = v;
    return acc;
  }, {});
}
const indexChoices = indexBy(c => c.id || c.value); // TODO: is `id` ever actually used? we have to cast to 'as any' here because `id` isn't a legal field on a UISelect option anyway

const isPropertyRecord = property => property instanceof PropertyRecord;
const toOptions = (property, transformOption) => {
  const translatedPropertyOptions = property.options.map(option => {
    const translatedOption = {
      text: propertyLabelTranslatorWithIsHubSpotDefined({
        label: option.label,
        isHubSpotDefined: property.hubspotDefined
      }),
      value: option.value
    };
    if (transformOption) {
      return transformOption(translatedOption);
    }
    return translatedOption;
  });
  return Array.isArray(translatedPropertyOptions) ? translatedPropertyOptions : translatedPropertyOptions.toJS();
};

//if the dropdown is allowed to be cleared, use makeOptionsFromProperty
export function makeOptionsFromPropertyWithoutBlankOptions(property, transformOption) {
  return toOptions(property, transformOption);
}
export function makeOptionsFromProperty(property, transformOption) {
  let options;
  const placeholder = propertyLabelTranslatorWithIsHubSpotDefined({
    label: isPropertyRecord(property) ? property.placeholder : '',
    isHubSpotDefined: property.hubspotDefined
  });
  const optionsArrFromProperties = toOptions(property, transformOption);
  const hasBlankOption = property.options.some(option => option.value === '');
  if (!hasBlankOption) {
    const placeholderOption = {
      text: placeholder,
      value: ''
    };
    options = [placeholderOption, ...optionsArrFromProperties];
  } else {
    options = optionsArrFromProperties;
  }
  return options;
}
export function getDisplayedValue(property, value, choices = undefined, {
  dateFormat = 'L',
  dateTimeFormat = 'L LT z',
  relativeDate = false,
  relativeDateTime = false,
  showDateDisplayHintLabel = false
} = {}, objectTypeId) {
  let displayValue;
  if (value == null) {
    value = '';
  }
  if (value === '') {
    // Check for a placeholder value if value is empty
    const placeholder = getPropertyPlaceholder(property, objectTypeId);
    if (placeholder !== undefined) {
      return placeholder;
    }
  }
  const propIsMultienum = isMultienum(property);
  if (isEnum(property) || propIsMultienum) {
    const values = propIsMultienum ? parseMultiEnumValue(value) : [value];
    // TODO: is `choices` ever actually passed in?
    if (choices == null) {
      choices = makeOptionsFromProperty(property);
    }
    const choiceMap = indexChoices(choices);
    displayValue = values.map(val => {
      if (val == null) {
        return val;
      }
      const option = choiceMap[val];
      return option ? option.text || val : val;
    }).join(', ');
  } else if (isDate(property)) {
    displayValue = getDisplayedValueForDateOrDateTime(property, value, {
      dateFormat,
      dateTimeFormat,
      relativeDate,
      relativeDateTime
    }, showDateDisplayHintLabel);
  } else if (isPercent(property) || isProbability(property)) {
    if (value === '') {
      displayValue = EMPTY_PROPERTY_VALUE;
    } else {
      displayValue = I18n.formatPercentage(Number(value) * 100, {
        precision: 2
      });
    }
  } else if (isPercentWholeNumber(property)) {
    if (value === '') {
      displayValue = EMPTY_PROPERTY_VALUE;
    } else {
      displayValue = I18n.formatPercentage(Number(value), {
        precision: 2
      });
    }
  } else if (isDuration(property)) {
    if (value === null || value === undefined || isNaN(+value) || value === '') {
      displayValue = EMPTY_PROPERTY_VALUE;
    } else {
      displayValue = formatDuration(I18n.moment.duration(Math.abs(+value)), {
        thresholds: DURATION_THRESHOLDS,
        withSuffix: false
      });
    }
  } else if (isFormattedNumber(property)) {
    // See `PropertyInputNumber`
    displayValue = value === '' ? '' : I18n.formatNumber(Number(value), {
      precision: 5
    });
  }
  return displayValue || value;
}
function getPropertyPlaceholder(property, objectTypeId) {
  // Product and line item billing frequencies
  if (objectTypeId && isBillingFrequency(property, objectTypeId)) {
    return unescapedText('customerDataPropertyUtils.PropertyValueDisplay.billingFrequencyPlaceholder.placeholder');
  }
  return undefined;
}