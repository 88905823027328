import * as ObjectTypes from 'customer-data-objects/constants/ObjectTypes';
const CRMToContactsObjectTypes = {
  [ObjectTypes.AD_CAMPAIGN]: 'Ad Campaign',
  [ObjectTypes.APPOINTMENT]: 'Appointments',
  [ObjectTypes.ATTRIBUTION]: 'Attribution',
  [ObjectTypes.AUTOMATION_PLATFORM_FLOW]: 'Workflow',
  [ObjectTypes.BLOG_LISTING_PAGE]: 'Blog listing page',
  [ObjectTypes.BLOG_POST]: 'Blog post',
  [ObjectTypes.CAMPAIGN]: 'Campaign',
  [ObjectTypes.CART]: 'Cart',
  [ObjectTypes.COMMUNICATION]: 'Communication',
  [ObjectTypes.COMPANY]: 'Companies',
  [ObjectTypes.CONTACT]: 'Contacts',
  [ObjectTypes.CONTACT_CREATE_ATTRIBUTION]: 'Contact Create Attribution',
  [ObjectTypes.CONVERSATION]: 'Conversation',
  [ObjectTypes.COURSE]: 'Courses',
  [ObjectTypes.CTA]: 'CTA',
  [ObjectTypes.DATA_PRIVACY_REQUEST]: 'Data privacy requests',
  [ObjectTypes.DEAL]: 'Deals',
  [ObjectTypes.DISCOUNT]: 'Discount',
  [ObjectTypes.DISCOUNT_TEMPLATE]: 'Discount templates',
  [ObjectTypes.ENGAGEMENT]: 'Engagements',
  [ObjectTypes.FEE]: 'Fee',
  [ObjectTypes.FEEDBACK_SUBMISSION]: 'Feedback Submissions',
  [ObjectTypes.FEEDBACK_SURVEY]: 'Feedback Surveys',
  [ObjectTypes.FOLDER]: 'Folder',
  [ObjectTypes.FORM]: 'Form',
  [ObjectTypes.FORM_DASHBOARD]: 'Form Dashboard',
  [ObjectTypes.GOAL_TARGET]: 'Goal targets',
  [ObjectTypes.KNOWLEDGE_ARTICLE]: 'Knowledge article',
  [ObjectTypes.LANDING_PAGE]: 'Landing page',
  [ObjectTypes.LEAD]: 'Leads',
  [ObjectTypes.LINE_ITEM]: 'Line Item',
  [ObjectTypes.LISTING]: 'Listings',
  [ObjectTypes.MARKETING_EMAIL]: 'Marketing email',
  [ObjectTypes.MARKETING_EVENT]: 'Marketing events',
  [ObjectTypes.MARKETING_SMS]: 'SMS message',
  [ObjectTypes.MEETING_EVENT]: 'Meeting events',
  [ObjectTypes.OBJECT_LIST]: 'Lists',
  [ObjectTypes.ORDER]: 'Order',
  [ObjectTypes.PARTNER_ACCOUNT]: 'Partner Account',
  [ObjectTypes.PARTNER_CLIENT]: 'Partner Client',
  [ObjectTypes.PARTNER_SERVICE]: 'Partner Service',
  [ObjectTypes.DATA_PRIVACY_CONSENT]: 'Data Privacy Consent',
  [ObjectTypes.PRODUCT]: 'Product',
  [ObjectTypes.QUOTE]: 'Quotes',
  [ObjectTypes.QUOTE_MODULE]: 'Quote modules',
  [ObjectTypes.QUOTE_MODULE_FIELD]: 'Quote module fields',
  [ObjectTypes.QUOTE_TEMPLATE]: 'Quote templates',
  [ObjectTypes.SALES_DOCUMENT]: 'Sales documents',
  [ObjectTypes.SEQUENCE_ENROLLMENT]: 'Sequence enrollments',
  [ObjectTypes.SERVICE]: 'Services',
  [ObjectTypes.SITE_PAGE]: 'Site page',
  [ObjectTypes.SOCIAL_BROADCAST]: 'Social broadcast',
  [ObjectTypes.SUBSCRIPTION]: 'Subscription',
  [ObjectTypes.TASK]: 'Tasks',
  [ObjectTypes.TAX]: 'Tax',
  [ObjectTypes.TICKET]: 'Tickets',
  [ObjectTypes.VISIT]: 'Visits',
  [ObjectTypes.USER]: 'Users',
  [ObjectTypes.WEB_INTERACTIVE]: 'Call-to-Action',
  [ObjectTypes.NOTE]: 'Note'
};
export default CRMToContactsObjectTypes;