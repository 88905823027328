'use es6';

import { fromJS, Map as ImmutableMap } from 'immutable';
import reduce from 'transmute/reduce';
import { TASK } from 'customer-data-objects/constants/ObjectTypes';
import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
import { GET, POST } from 'crm_data/constants/HTTPVerbs';
import { send } from 'crm_data/api/ImmutableAPI';
import { del, patch, post, put } from 'crm_data/inboundDB/sharedAPI';
import Raven from 'raven-js';
const API = 'engagements/v1/engagements';
export const API_V2 = 'engagements/v2/engagements';
const uri = engagementId => `${API}/${engagementId}`;
const parseSaveResponse = engagement => {
  // If is a task, check if we have the taskType property
  if (engagement.getIn(['engagement', 'type']) === TASK) {
    if (!engagement.hasIn(['metadata', 'taskType'])) {
      engagement = engagement.setIn(['metadata', 'taskType'], null);
    }
  }
  return engagement;
};
const toSaveable = reduce(ImmutableMap(), (acc, val, key) => {
  if (ImmutableMap.isMap(val) || key === 'attachments') {
    return acc.set(key, fromJS(val));
  }
  return acc;
});
export function addAssociation(engagementId, objectType, objectId) {
  return put(`${uri(engagementId)}/associations/${objectType}/${objectId}`, {});
}
export function associateEngagmentToObject(engagementId, objectType, subjectId) {
  return put(`${uri(engagementId)}/associations/${objectType}/${subjectId}`);
}

// todo eventually - move this out to `EngagementsBatchAPI`
export function batchFetch(data, resultsOption) {
  if (!resultsOption) {
    resultsOption = {
      reducerInitialValue: ImmutableMap()
    };
  }
  const options = {
    type: POST,
    timeout: 12000
  };
  return send(options, `${API}/batch-get`, data, results => results.reduce((keyedEngagments, result) => keyedEngagments.set(`${result.engagement.id}`, EngagementRecord.fromJS(result)), resultsOption.reducerInitialValue)).catch(error => {
    Raven.captureMessage('Engagements/v1 batch-get failure', {
      extra: {
        error,
        data,
        resultsOption
      }
    });
    throw error;
  });
}

// don't use this anymore, use `batchFetch`
// todo - replace this with `batchFetch`
export function fetch(query) {
  const options = {
    type: GET,
    timeout: 20000
  };
  return send(options, `${API}/batch`, query, results => results.reduce((keyedEngagments, result) => keyedEngagments.set(`${result.engagement.id}`, EngagementRecord.fromJS(result)), ImmutableMap()));
}
export function create(engagement) {
  // temporary for https://git.hubteam.com/HubSpot/CRM-Issues/issues/426
  const ownerId = engagement.getIn && engagement.getIn(['engagement', 'ownerId']);
  if (!ownerId) {
    Raven.captureMessage('Engagement on create missing ownerId', {
      extra: {
        engagement
      }
    });
  }
  return post(`${API}/`, engagement, result => {
    return {
      createdEngagementRecord: EngagementRecord.fromJS(result),
      associationCreateFailures: result.associationCreateFailures
    };
  });
}
export function deleteEngagement(engagementId) {
  return del(uri(engagementId));
}

// todo - deprecate this - causes too many issues w/firewalls
export function update(engagementId, updates) {
  return patch(uri(engagementId), toSaveable(fromJS(updates))).then(parseSaveResponse);
}
export function updateAssociations(engagementId, associationUpdates) {
  return put(`${uri(engagementId)}/associations`, associationUpdates);
}