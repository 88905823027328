import http from 'hub-http/clients/apiClient';
import { getCrmLocationQuickFetchKey, getCardLocationQueryUrl } from '../../quick-fetch/quickFetchCrmLocation';
import quickFetch from 'quick-fetch';
const fetchCrmLocationInternal = ({
  objectTypeId,
  objectId,
  location
}) => {
  if (objectTypeId === undefined || objectId === undefined) {
    return Promise.reject(new Error(`Could not fetch FAS location without both objectId and objectTypeId, instead got objectId: [${objectId}] and objectTypeId: [${objectTypeId}]`));
  }
  return http.get(getCardLocationQueryUrl({
    objectTypeId,
    objectId,
    location
  }));
};
export const fetchCrmLocation = queryVariables => {
  const fallbackRequest = () => fetchCrmLocationInternal(queryVariables);
  const quickFetchKey = getCrmLocationQuickFetchKey(queryVariables);
  const earlyRequest = quickFetch.getRequestStateByName(quickFetchKey);
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(response => {
        resolve(response);
        quickFetch.removeEarlyRequest(quickFetchKey);
      });
      earlyRequest.onError(() => {
        fallbackRequest().then(resolve).catch(reject);
      });
    });
  }
  return fallbackRequest();
};