import { getExtensions, getDefaultExtension } from '../extensions';
import { getFlywheelExtensions, getFlywheelDefaultExtension } from '../extensions/flywheel';
import { isBetPortal } from '../extensions/flywheel/FlywheelUtils';
const getCustomerPortalExtension = objectTypeId => {
  const extensions = getExtensions();
  const defaultExtension = getDefaultExtension();
  if (extensions[objectTypeId]) {
    return Object.assign({}, defaultExtension, extensions[objectTypeId]);
  }
  return defaultExtension;
};
const getFlywheelPortalExtension = objectTypeId => {
  const flywheelExtensions = getFlywheelExtensions();
  const defaultExtension = getFlywheelDefaultExtension();
  const customerPortalExtension = getCustomerPortalExtension(objectTypeId);
  return Object.assign({}, customerPortalExtension, defaultExtension, flywheelExtensions[objectTypeId]);
};

/**
 *
 * getExtension will return the appropriate Extension for the given objectTypeId and scopes.
 *
 * If an extension is not defined for the object type, it will return the default.
 * The DefaultExtension is comprehensive and includes definitions for all available BehaviorTypes.
 *
 * If an extension is defined for the object type, the returned Extension will include all overridden Behaviors and defaults
 * from DefaultExtension that have not been overridden.
 *
 * If the portal is determined to be a Flywheel one via scopes, the returned Extension will include all overridden Behaviors for Flywheel, then
 * falling back to overridden Behaviors for customer portals, and finally falling back to the defaults from DefaultExtension.
 *
 * @param {ObjectTypeId} objectTypeId The object type to pull extensions for
 * @param {string[]} scopes List of string scopes used to determine at runtime if user is in Flywheel or customer portal
 * @returns {Extension} A comprehensive Extension object
 */
export const getExtension = (objectTypeId, hasScope) => {
  if (isBetPortal(hasScope)) {
    return getFlywheelPortalExtension(objectTypeId);
  }
  return getCustomerPortalExtension(objectTypeId);
};