import { useCallback } from 'react';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { MISSING_PERMISSIONS } from '../../types/ValidationError';
import { useFetchUserPermissionsForObjectType } from '../../hooks/useFetchUserPermissionsForObjectType';
export const buildOpenObjectBuilderActionHook = () => {
  return args => {
    const {
      objectTypeId
    } = args;
    const {
      currentUserCanCreate,
      error
    } = useFetchUserPermissionsForObjectType(objectTypeId);
    const sendOpenObjectBuilderMessage = useSendCrmMessageTopic(TOPIC_NAMES.OPEN_OBJECT_BUILDER);
    const callback = useCallback(() => {
      sendOpenObjectBuilderMessage(args);
    }, [sendOpenObjectBuilderMessage, args]);
    const errorMsg = error || (!currentUserCanCreate ? MISSING_PERMISSIONS : null);
    if (errorMsg) {
      return {
        errors: [errorMsg]
      };
    }
    return {
      callback
    };
  };
};