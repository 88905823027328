import { List, Map as ImmutableMap } from 'immutable';
import keyMirror from 'react-utils/keyMirror';

/**
 * NOTICE: These constants also exist in crm_universal. Please make updates to both.
 * https://git.hubteam.com/HubSpot/crm_universal/blob/master/static/js/timeline/TimelineConstants.js
 *
 */
// @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
const EVENT_TYPE_MAP = new ImmutableMap({
  addressChange: 'addressChange',
  BOUNCE: 'bounce',
  BOUNCED: 'bounce',
  CLICK: 'click',
  DELIVERED: 'delivered',
  DROPPED: 'dropped',
  SUPPRESSED: 'suppressed',
  EMAIL_CLICK: 'click',
  EMAIL_OPEN: 'open',
  EMAIL_REPLY: 'reply',
  EMAIL_TRACKER_CREATE: 'sent',
  FORWARD: 'forward',
  FAILED: 'failed',
  INCOMING_EMAIL: 'reply',
  OPEN: 'open',
  PRINT: 'print',
  REPLY: 'reply',
  SENT: 'sent',
  SENDING: 'sent',
  SPAMREPORT: 'spamReport',
  SCHEDULED: 'scheduled'
});
const EMAIL_EVENT_EVENT_TYPES = keyMirror({
  ADDRESS_LIST_BOMBED: null,
  BOUNCE: null,
  BOUNCED: null,
  CANCELED_DUE_TO_PORTAL_DATA_MIGRATION: null,
  CLICK: null,
  DELIVERED: null,
  DROPPED: null,
  FAILED: null,
  SUPPRESSED: null,
  MTA_DROPPED: null,
  OPEN: null,
  SPAMREPORT: null,
  STATUSCHANGE: null,
  UNBOUNCE: null,
  UNSUBSCRIBED: null,
  GLOBALLY_BLOCKED_RECIPIENT_ADDRESS: null,
  RECIPIENT_PREVIOUSLY_BOUNCED_GLOBALLY: null,
  GLOBALLY_BLOCKED_RECIPIENT_DOMAIN: null,
  RECIPIENT_PREVIOUSLY_BOUNCED_ON_PORTAL: null,
  PREVIOUSLY_MARKED_AS_SPAM: null,
  UNSUBSCRIBED_PORTAL: null,
  OTHER_SEND_REJECTED: null,
  PERMANENT_FAIL: null,
  UNSUBSCRIBED_PERSONAL: null,
  SEND_AS_PERMISSIONS_DENIED: null,
  SENT_USER_REMOVED_FROM_PORTAL: null,
  RATE_LIMITED: null,
  RATE_LIMITED_BY_CLIENT: null,
  MINUTE_RATE_LIMITED_BY_CLIENT: null,
  RATE_LIMITED_BY_PROVIDER: null,
  PREVIOUSLY_BOUNCED: null,
  PREVIOUS_SPAM: null,
  PREVIOUSLY_UNSUBSCRIBED_MESSAGE: null,
  PREVIOUSLY_UNSUBSCRIBED_MESSAGE_GDPR: null,
  PREVIOUSLY_UNSUBSCRIBED_PORTAL: null,
  PREVIOUSLY_UNSUBSCRIBED_PORTAL_GDPR: null,
  INVALID_TO_ADDRESS: null,
  INVALID_FROM_ADDRESS: null,
  BLOCKED_DOMAIN: null,
  BLOCKED_ADDRESS: null,
  EMAIL_UNCONFIRMED: null,
  CAMPAIGN_CANCELLED: null,
  MTA_IGNORE: null,
  PORTAL_OVER_LIMIT: null,
  PORTAL_SUSPENDED: null,
  CANCELLED_ABUSE: null,
  UNKNOWN_REASON: null,
  QUARANTINED_ADDRESS: null,
  GRAYMAIL_SUPPRESSED: null,
  RECIPIENT_FATIGUE_SUPPRESSED: null,
  MAILBOX_FULL: null,
  PROVIDER_SUSPENDED_ACCOUNT: null,
  SMTP_CONNECTION_FAILED: null,
  RECIPIENT_INVALID: null,
  SMTP_CLIENT_NOT_AUTHENTICATED: null,
  CONSIDERED_SPAM: null,
  OFFICE_SENT_TIMEOUT: null,
  PROVIDER_SEND_TIMEOUT: null,
  CANCELLED_DUE_TO_INBOX_DISCONNECTED: null
});
const EMAIL_SUBSCRIPTION_STATUS = keyMirror({
  SUBSCRIBED: null,
  UNSUBSCRIBED: null
});
const BATCH_REQUEST_SEPARATOR = '!';
const NON_INDEXED_MESSAGE_ID = 'NON_INDEXED_MESSAGE_ID';

// Used for not filtering out certain eventEmailSends
const SMTP_API_APP_ID = 22709;
const UNKNOWN_EVENT = 'unknownEmailEvent';
const SENT = EVENT_TYPE_MAP.get('SENT');
const DELIVERED = EVENT_TYPE_MAP.get('DELIVERED');
const OPEN = EVENT_TYPE_MAP.get('OPEN');
const CLICK = EVENT_TYPE_MAP.get('CLICK');
const REPLY = EVENT_TYPE_MAP.get('REPLY');

// @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
const EMAIL_EVENTS_PROGRESSION = new List([EVENT_TYPE_MAP.get('SCHEDULED'), EVENT_TYPE_MAP.get('SENT'), EVENT_TYPE_MAP.get('DELIVERED'), EVENT_TYPE_MAP.get('OPEN'), EVENT_TYPE_MAP.get('CLICK'), EVENT_TYPE_MAP.get('INCOMING_EMAIL'), EVENT_TYPE_MAP.get('BOUNCE'), EVENT_TYPE_MAP.get('DROPPED'), EVENT_TYPE_MAP.get('SUPPRESSED'), EVENT_TYPE_MAP.get('FAILED')]);

// Sales email engagement types
const SALES_EMAIL_EVENT_TYPES = keyMirror({
  EMAIL: null,
  INCOMING_EMAIL: null
});

// Sidekick event types
const TRACKED_EVENT_TYPES = keyMirror({
  EMAIL_CLICK: null,
  EMAIL_OPEN: null,
  EMAIL_REPLY: null
});

// Marketing event types
const MARKETING_EVENT_TYPES = keyMirror({
  CLICK: null,
  OPEN: null,
  DELIVERED: null,
  REPLY: null
});

// Used for fetching / filtering specific timeline events
// @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
const FAVORITE_ENGAGEMENT_TYPES = new ImmutableMap({
  CALL: 'ENGAGEMENTS_CALLS',
  EMAIL_OLD: 'ENGAGEMENTS_EMAILS',
  EMAIL: 'ENGAGEMENTS_EMAILS_TRIMMED',
  FORWARDED_EMAIL: 'ENGAGEMENTS_EMAILS_TRIMMED',
  INCOMING_EMAIL: 'ENGAGEMENTS_EMAILS_TRIMMED',
  MEETING: 'ENGAGEMENTS_MEETINGS',
  NOTE: 'ENGAGEMENTS_NOTES',
  TASK: 'ENGAGEMENTS_TASKS',
  FEEDBACK_SUBMISSION: 'ENGAGEMENTS_FEEDBACK_SUBMISSIONS',
  CONVERSATION_SESSION: 'ENGAGEMENTS_CONVERSATION_SESSIONS',
  CUSTOM_CHANNEL_CONVERSATION: 'ENGAGEMENTS_CONVERSATION_SESSIONS',
  WHATS_APP: 'ENGAGEMENTS_WHATS_APP',
  SMS: 'ENGAGEMENTS_SMS',
  LINKEDIN_MESSAGE: 'ENGAGEMENTS_LINKEDIN_MESSAGE',
  POSTAL_MAIL: 'ENGAGEMENTS_POSTAL_MAIL'
});
const SIDEKICK = 'SIDEKICK';
const BIDEN = 'BIDEN';
const EMAIL_EVENTS = 'EMAIL_EVENTS';
const EMAIL_SENDS = 'EMAIL_SENDS';
const EMAIL_UNBOUNCES = 'EMAIL_UNBOUNCES';
const EMAIL_OPTOUTS = 'EMAIL_OPTOUTS';
const INTEGRATION_EVENTS = 'INTEGRATION_EVENTS';
const LIST_MEMBERSHIPS = 'LIST_MEMBERSHIPS';

// These are for checking the 'etype'
const EVENT_EMAIL_SEND = 'eventEmailSend';
const EVENT_EMAIL_OPTOUTS = 'eventEmailOptOut';
const EVENT_ENGAGEMENT = 'eventEngagement';
const EVENT_INTEGRATIONS = 'eventIntegrations';
const EVENT_SIDEKICK = 'eventSidekick';
const EVENT_MONTH = 'eventMonth';
const TIMELINE_STATUSES = keyMirror({
  ERROR: null
});
const PATHS = ImmutableMap({
  ASSIGNED_OWNER: ['eventData', 'engagement', 'ownerId'],
  ASSOCIATED_COMPANIES: ['eventData', 'associations', 'companyIds'],
  ASSOCIATED_CONTACTS: ['eventData', 'associations', 'contactIds'],
  ASSOCIATED_DEALS: ['eventData', 'associations', 'dealIds'],
  BODY: ['eventData', 'metadata', 'body'],
  END_TIME: ['eventData', 'metadata', 'endTime'],
  HTML: ['eventData', 'metadata', 'html'],
  OWNERS: ['eventData', 'associations', 'ownerIds'],
  START_TIME: ['eventData', 'metadata', 'startTime'],
  SUBJECT: ['eventData', 'metadata', 'subject'],
  TIMESTAMP: ['eventData', 'engagement', 'timestamp'],
  TITLE: ['eventData', 'metadata', 'title'],
  ENGAGEMENT_TYPE: ['eventData', 'engagement', 'type']
});

// @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
const EMPTY_MONTH = new ImmutableMap({
  etype: 'eventMonth',
  hasEvents: false,
  id: null,
  timestamp: null
});

// @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
const UNITS_OF_TIME = new ImmutableMap({
  DAYS: 'days',
  MONTHS: 'months',
  WEEKS: 'weeks'
});
const ENGAGEMENT_TYPE_TO_I18N_KEY = {
  ENGAGEMENTS_ACTIVITY: 'activity',
  ENGAGEMENTS_NOTES: 'notes',
  ENGAGEMENTS_EMAILS_TRIMMED: 'emails',
  ENGAGEMENTS_CALLS: 'calls',
  ENGAGEMENTS_TASKS: 'tasks',
  ENGAGEMENTS_MEETINGS: 'meetings'
};

// Bodies with a length greater than this will show the expand / collapse button
const BODY_LENGTH_THRESHOLD = 1000;

// Marketing Event CRM object timeline event types
const MARKETING_WEBINAR_EVENT_TYPES = keyMirror({
  REGISTERED: null,
  ATTENDED: null,
  CANCELLED: null
});
const CTA = 'CTA';
const WEB_INTERACTIVE = 'WEB_INTERACTIVE';
export { BATCH_REQUEST_SEPARATOR, BIDEN, BODY_LENGTH_THRESHOLD, CLICK, CTA, DELIVERED, EMAIL_EVENT_EVENT_TYPES, EMAIL_EVENTS_PROGRESSION, EMAIL_EVENTS, EMAIL_OPTOUTS, EMAIL_SENDS, EMAIL_SUBSCRIPTION_STATUS, EMAIL_UNBOUNCES, EMPTY_MONTH, ENGAGEMENT_TYPE_TO_I18N_KEY, EVENT_EMAIL_OPTOUTS, EVENT_EMAIL_SEND, EVENT_ENGAGEMENT, EVENT_INTEGRATIONS, EVENT_MONTH, EVENT_SIDEKICK, EVENT_TYPE_MAP, FAVORITE_ENGAGEMENT_TYPES, INTEGRATION_EVENTS, LIST_MEMBERSHIPS, MARKETING_EVENT_TYPES, MARKETING_WEBINAR_EVENT_TYPES, NON_INDEXED_MESSAGE_ID, OPEN, PATHS, REPLY, SALES_EMAIL_EVENT_TYPES, SENT, SIDEKICK, SMTP_API_APP_ID, TIMELINE_STATUSES, TRACKED_EVENT_TYPES, UNITS_OF_TIME, UNKNOWN_EVENT, WEB_INTERACTIVE };