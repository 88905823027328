import { parse } from 'hub-http/helpers/params';
import PortalIdParser from 'PortalIdParser';
import { normalizeTypeId, isObjectTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
const getBaseUrl = () => '/contacts';
export const getRootUrl = () => `${getBaseUrl()}/${PortalIdParser.get()}`;
export const navigateToUrl = url => {
  window.location.href = url;
};
export const getSearchParam = key => {
  const searchParams = parse(window.location.search.slice(1));
  return searchParams[key];
};
export const getObjectTypeIdFromPath = route => {
  const urlParts = route.split('/');
  let typeId = '';
  if (urlParts.length >= 3) {
    typeId = normalizeTypeId(urlParts[2]);
  }
  // Legacy routes would return the wrong id, i.e /contacts/123456 would return 123456. In this case, we ignore
  if (!isObjectTypeId(typeId)) {
    return;
  }
  return typeId;
};