// This is aligned to:
// https://git.hubteam.com/HubSpot/customer-data-sidebar/blob/master/customer-data-sidebar/static/js/universal/UniversalSidebarCardTypes.js
// and it should be kept up to date the best we can, without adding a dependency on customer-data-sidebar.
// This map does not prevent a card from being treated as a native crm-sdk card if there is a matching card type.
export const LEGACY_SIDEBAR_CARD_TYPES = {
  AssociatedObjectsCard: 'AssociatedObjectsCard',
  BetAssociatedCompaniesCard: 'BetAssociatedCompaniesCard',
  BetCustomerSummaryCard: 'BetCustomerSummaryCard',
  BetDealSupportRequestCard: 'BetDealSupportRequestCard',
  BetPartnerCollabCard: 'BetPartnerCollabCard',
  BetPartnerPoiCard: 'BetPartnerPoiCard',
  BetQuotesCard: 'BetQuotesCard',
  BizOpsCustomerSuccessCard: 'BizOpsCustomerSuccessCard',
  BizOpsDomainControlsCard: 'BizOpsDomainControlsCard',
  BizOpsRelatedContactsCard: 'BizOpsRelatedContactsCard',
  BuyerIntentCard: 'BuyerIntentCard',
  CartDiscountCard: 'CartDiscountCard',
  CartShipmentDetailCard: 'CartShipmentDetailCard',
  CartTotalCard: 'CartTotalCard',
  CommercePaymentHistoryCard: 'CommercePaymentHistoryCard',
  CompanyParentChildCard: 'CompanyParentChildCard',
  ContactCreateAttributionCard: 'ContactCreateAttributionCard',
  ConversationsContactTicketsCard: 'ConversationsContactTicketsCard',
  ConversationsDealCard: 'ConversationsDealCard',
  ConversationsThreadTicketCard: 'ConversationsThreadTicketCard',
  CrmExtensionCard: 'CrmExtensionCard',
  DealCollaboratorsCard: 'DealCollaboratorsCard',
  DealCreateAttributionCard: 'DealCreateAttributionCard',
  DealRegistrationCard: 'DealRegistrationCard',
  DealSharedViewCard: 'DealSharedViewCard',
  DealSplitsCard: 'DealSplitsCard',
  DocusignCard: 'DocusignCard',
  EngagementAttachmentsCard: 'EngagementAttachmentsCard',
  FeedbackCard: 'FeedbackCard',
  FlywheelQlSummaryCard: 'FlywheelQlSummaryCard',
  TalkingPointsCard: 'TalkingPointsCard',
  FlywheelRecentConversionsCard: 'FlywheelRecentConversionsCard',
  GdprSubscriptionsCard: 'GdprSubscriptionsCard',
  IntegrationCard: 'IntegrationCard',
  IntegrationsSyncStatusCard: 'IntegrationsSyncStatusCard',
  InvoicesCard: 'InvoicesCard',
  LeadStageTrackerCard: 'LeadStageTrackerCard',
  LinkedInSalesNavigatorCard: 'LinkedInSalesNavigatorCard',
  ListsCard: 'ListsCard',
  MarketingEventAboutCard: 'MarketingEventAboutCard',
  MarketingEventActivityCard: 'MarketingEventActivityCard',
  NativeInvoicesCard: 'NativeInvoicesCard',
  NetSuiteCard: 'NetSuiteCard',
  OrderDiscountCard: 'OrderDiscountCard',
  OrderShipmentDetailCard: 'OrderShipmentDetailCard',
  OrderTotalCard: 'OrderTotalCard',
  PartnerRegistrationCard: 'PartnerRegistrationCard',
  PastConversationsCard: 'PastConversationsCard',
  PinnedActivityCard: 'PinnedActivityCard',
  PlaybooksCard: 'PlaybooksCard',
  ProfileProductsCard: 'ProfileProductsCard',
  ProfileQuotesCard: 'ProfileQuotesCard',
  PropertiesCard: 'PropertiesCard',
  ProspectingNotesCard: 'ProspectingNotesCard',
  QualifiedLeadsCard: 'QualifiedLeadsCard',
  RevenueAttributionCard: 'RevenueAttributionCard',
  SalesActivitiesTimelineCard: 'SalesActivitiesTimelineCard',
  ServiceDeliveryHoursLoggingCard: 'ServiceDeliveryHoursLoggingCard',
  SfdcSyncCard: 'SfdcSyncCard',
  SubscriptionBillingInfoCard: 'SubscriptionBillingInfoCard',
  SubscriptionPaymentsTimelineCard: 'SubscriptionPaymentsTimelineCard',
  SurveyMonkeyCard: 'SurveyMonkeyCard',
  TargetAccountCard: 'TargetAccountCard',
  ToolLinksCard: 'ToolLinksCard',
  TranscriptCard: 'TranscriptCard',
  UpcomingActivitiesCard: 'UpcomingActivitiesCard',
  UniversalTimelineCard: 'UniversalTimelineCard',
  WebsiteActivityCard: 'WebsiteActivityCard',
  WorkflowsCard: 'WorkflowsCard',
  ZorseConversationsTaxonomyCard: 'ZorseConversationsTaxonomyCard',
  ZorseMoveInboxCard: 'ZorseMoveInboxCard',
  ZorseRepChatAssignmentCard: 'ZorseRepChatAssignmentCard',
  ZorseTicketSearchCard: 'ZorseTicketSearchCard'
};