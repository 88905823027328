import quickFetch from 'quick-fetch';
import { useEffect } from 'react';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_data/api/ImmutableAPI'. Di... Remove this comment to see the full error message
import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_data/dispatch/Dispatch'. D... Remove this comment to see the full error message
import { dispatchQueue } from 'crm_data/dispatch/Dispatch';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_data/settings/UserSettings... Remove this comment to see the full error message
import { normalizeUserSettingsResponse } from 'crm_data/settings/UserSettingsAPI';
import Raven from 'raven-js';
export function makeEarlyThreeColumnRequest(earlyThreeColumnRequest) {
  const dispatchFetchSucceeded = response => {
    dispatchQueue('USERSETTINGS_FETCH_SUCCEEDED', normalizeUserSettingsResponse(response));
  };
  earlyThreeColumnRequest.whenFinished(response => {
    dispatchFetchSucceeded(response);
    quickFetch.removeEarlyRequest('threeColumn');
  });
  earlyThreeColumnRequest.onError(error => {
    Raven.captureMessage('ProfileContainer: threeColumn early requester failed', {
      extra: {
        error
      }
    });
    const payload = JSON.stringify({
      keys: ['has-collapsed-right-sidebar']
    });
    return ImmutableAPI.put('users/v1/app/attributes/bulk-get', payload).then(response => {
      dispatchFetchSucceeded(response);
    });
  });
}
export function useSidebarCollapseQuickFetch() {
  useEffect(() => {
    const earlyThreeColumnRequest = quickFetch.getRequestStateByName('threeColumn');
    if (earlyThreeColumnRequest) {
      makeEarlyThreeColumnRequest(earlyThreeColumnRequest);
    }
  }, []);
}