import invariant from 'react-utils/invariant';
export function curry(fn) {
  return (...xs) => {
    invariant(xs.length > 0, 'Empty Invocation');
    if (xs.length >= fn.length) {
      return fn(...xs);
    }
    return curry(fn.bind(null, ...xs));
  };
}
export const restoreFocusAfter = callback => {
  const activeElement = document.activeElement;
  callback();
  if (activeElement) {
    activeElement.focus();
  }
};
export const serializeStyles = styleMap => {
  if (typeof styleMap === 'string') return styleMap;
  if (typeof styleMap !== 'object') return '';
  let styles = '';
  Object.keys(styleMap).forEach(style => {
    styles += `${style}: ${styleMap[style]}; `;
  });
  return styles.trim();
};
export const parseStyles = styles => {
  if (typeof styles === 'object') return styles;
  if (typeof styles !== 'string') return '';
  const stylesMap = {};
  styles.split(';').forEach(style => {
    const [key, value] = style.split(':');
    if (key && value) stylesMap[key.trim()] = value.trim();
  });
  return stylesMap;
};
export const fontSizeTransformer = {
  'font-size': originalValue => {
    const value = parseFloat(originalValue);
    const isUsingPoints = originalValue.includes('pt');

    // We pass through the pasted font-size value as is instead of allowing
    // the browser to perform pt to px conversion for google docs/word docs pasting.
    // Reference https://git.hubteam.com/HubSpot/rich-text-lib/pull/1233
    if (isUsingPoints) {
      return `${Math.ceil(value)}px`;
    }
    return originalValue;
  }
};
export const transformStyles = (stylesMap, transformers) => Object.keys(stylesMap).reduce((acc, styleName) => {
  if (Object.prototype.hasOwnProperty.call(transformers, styleName)) {
    acc[styleName] = transformers[styleName](stylesMap[styleName]);
  }
  return acc;
}, stylesMap);

// NodeList.forEach is not supported in IE11
export const forEachNode = curry((callback, nodes) => {
  Array.prototype.forEach.call(nodes, callback);
});