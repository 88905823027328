import { gql } from '@apollo/client';
export const PropertiesV3ConfigFragment = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"PropertiesV3ConfigFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"PropertiesV3CardConfiguration\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"propertyNames\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"userPermission\"}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "PropertiesV3ConfigFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "PropertiesV3CardConfiguration"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "propertyNames"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "userPermission"
        }
      }]
    }
  }]
});