import { useCallback } from 'react';
import { OPEN_CALL_VOICEMAIL_DELETE_MODAL } from 'crm-events-data';
//@ts-expect-error untyped
import DeleteObjectPrompt from 'customer-data-objects-ui-components/prompt/DeleteObjectPrompt';
//@ts-expect-error untyped
import { dispatch } from 'crm_data/flux/dispatch';
//@ts-expect-error untyped
import { DELETE_RECORDING } from 'crm_data/actions/ActionTypes';
//@ts-expect-error untyped
import { send } from 'crm_data/api/ImmutableAPI';
//@ts-expect-error untyped
import { DELETE } from 'crm_data/actions/ActionVerbs';
import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
import I18n from 'I18n';
import Raven from 'raven-js';
import { CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
// @ts-expect-error Could not find a declaration file
import { PUT } from 'crm_data/constants/HTTPVerbs';
function removeCallRecordingUrl(engagementId) {
  return send({
    type: PUT,
    headers: {
      'X-Properties-Source': CRM_UI
    }
  }, `engagements/v2/engagements/${engagementId}`, {
    properties: [{
      name: 'hs_call_recording_url',
      value: ''
    }]
  });
}
const deleteUri = callsServiceCallId => `post-call/v1/voice-recording/${callsServiceCallId}`;
const deleteVoicemail = (callsServiceCallId, engagementId, isFirstPartyCallEngagement) => {
  const isFirstPartyCallRecording = callsServiceCallId && isFirstPartyCallEngagement;
  const deletionPromise = isFirstPartyCallRecording ? send({
    type: DELETE,
    query: {
      engagementId,
      retryOnFailure: true
    }
  }, deleteUri(callsServiceCallId)) : removeCallRecordingUrl(engagementId);
  return deletionPromise.then(() => {
    dispatch(DELETE_RECORDING, {
      engagementId
    });
  }).catch(error => {
    Raven.captureException(error, {
      extra: {
        engagementId,
        callsServiceCallId
      }
    });
  });
};
export const useDeleteCallVoicemailModalActionHandler = ({
  objectType
}) => {
  const handleDeleteCallVoicemailModalAction = useCallback((actionType, data) => {
    if (actionType === OPEN_CALL_VOICEMAIL_DELETE_MODAL) {
      DeleteObjectPrompt({
        confirmLabel: I18n.text('timeline.voicemail.deleteConfirmCTA'),
        rejectLabel: I18n.text('timeline.voicemail.deleteCancelCTA'),
        message: I18n.text('timeline.voicemail.deleteVoicemailConfirmDescription'),
        title: I18n.text('timeline.voicemail.deleteVoicemailConfirmTitle'),
        callback: () => {
          CrmLogger.log('timelineInteraction', {
            action: 'Delete call voicemail',
            eventType: 'call',
            type: objectType
          });
          return deleteVoicemail(data.callsServiceCallId, data.engagementId, data.isFirstPartyCallEngagement);
        }
      });
    }
  }, [objectType]);
  return {
    handleDeleteCallVoicemailModalAction
  };
};