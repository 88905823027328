export const MINI_TIMELINE_STATUS_TRANSLATION_KEYS = {
  addressChange: 'components.miniTimeline.status.addressChange',
  click: 'components.miniTimeline.status.click',
  delivered: 'components.miniTimeline.status.delivered',
  dropped: 'components.miniTimeline.status.dropped',
  forward: 'components.miniTimeline.status.forward',
  ineligible: 'components.miniTimeline.status.ineligible',
  joined: 'components.miniTimeline.status.joined',
  left: 'components.miniTimeline.status.left',
  open: 'components.miniTimeline.status.open',
  pageView: 'components.miniTimeline.status.pageView',
  pageViewNoHref: 'components.miniTimeline.status.pageViewNoHref',
  print: 'components.miniTimeline.status.print',
  reply: 'components.miniTimeline.status.reply',
  scheduled: 'components.miniTimeline.status.scheduled',
  sent: 'components.miniTimeline.status.sent',
  spamReport: 'components.miniTimeline.status.spamReport'
};