'use es6';

import { send } from 'crm_data/api/ImmutableAPI';
import { ENGAGEMENT_TO_COMPANY, ENGAGEMENT_TO_CONTACT, ENGAGEMENT_TO_DEAL, ENGAGEMENT_TO_TICKET } from 'crm_data/associations/AssociationTypes';
import { PATCH, POST } from 'crm_data/constants/HTTPVerbs';
import { ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';
import { getId } from 'customer-data-objects/model/ImmutableModel';
import { CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
import { mapTaskAPIV1ResponseToTaskRecord } from 'customer-data-objects/task/mapTaskAPIResponseToTaskRecord';
import { MENTIONED_OWNER_IDS, QUEUE_IDS, REMINDERS } from 'customer-data-objects/task/TaskPropertyNames';
import userInfo from 'hub-http/userInfo';
import { List, Map as ImmutableMap } from 'immutable';
import filter from 'transmute/filter';
import flatten from 'transmute/flatten';
import get from 'transmute/get';
import set from 'transmute/set';
import indexBy from 'transmute/indexBy';
import map from 'transmute/map';
import merge from 'transmute/merge';
import pick from 'transmute/pick';
import pipe from 'transmute/pipe';
import toJS from 'transmute/toJS';
import AssociationTypeToAssociationTypeId from '../associations/AssociationTypeToAssociationTypeId';
const TO_API_ASSOCIATION_KEYS = ImmutableMap({
  contactIds: ENGAGEMENT_TO_CONTACT,
  companyIds: ENGAGEMENT_TO_COMPANY,
  dealIds: ENGAGEMENT_TO_DEAL,
  ticketIds: ENGAGEMENT_TO_TICKET
});
const ARRAY_PROPERTIES = [REMINDERS, QUEUE_IDS, MENTIONED_OWNER_IDS];
export const createTaskKey = key => {
  if (key === 'FETCH_FAILED_KEY') return key;
  return ImmutableMap({
    objectType: get('objectType', key),
    objectId: get('objectId', key).toString(),
    taskId: get('taskId', key).toString()
  });
};
const getRequestHeaders = () => userInfo().then(({
  user
}) => ({
  'X-Properties-Source': CRM_UI,
  'X-Properties-SourceId': user.email
}));

/**
 * June 8, 2021
 * The tasks/v2 api requires some properties to be arrays.
 * For historical reasons, these are not stored as arrays in a lot of TaskRecord instances.
 * @param properties - an object of {name, value} pairs keyed by name
 * @private
 */
export function _transformArrayProperties(properties) {
  return map((property, propertyName) => {
    if (ARRAY_PROPERTIES.includes(propertyName)) {
      const maybeBareValue = get('value', property);
      const wrappedValue = pipe(List.of.bind(List), flatten, filter(Boolean))(maybeBareValue);
      return set('value', wrappedValue, property);
    }
    return property;
  }, properties);
}
export const _formatPropertiesForRequest = taskRecordProperties => _transformArrayProperties(taskRecordProperties).map(property => get('value', property)).toJS();
const formattedAssociation = (objectId, formattedKey) => ({
  associationType: formattedKey,
  toObjectId: objectId,
  associationTypeId: AssociationTypeToAssociationTypeId.get(formattedKey),
  // for now this will always be HUBSPOT_DEFINED, but may become more varied in
  // the future (such as: user defined associations with custom objects)
  associationCategory: 'HUBSPOT_DEFINED'
});
export const _formatLegacyAssociationsForRequest = taskRecordAssociations => taskRecordAssociations.map((objectIds, associationKey) => {
  const formattedKey = TO_API_ASSOCIATION_KEYS.get(associationKey);
  return objectIds.map(objectId => formattedAssociation(objectId, formattedKey));
}).toList().flatten(1).toArray();

/**
 *
 * @param universalAssociations
 * {
 *   "2-201234": {
 *     associationSpec: {
 *       associationCategory: "USER_DEFINED",
 *       associationTypeId: 16
 *     },
 *     objectIds: ["97689713"]
 *   }
 * }
 *
 * @return
 * [
 *   {
 *     associationCategory: "USER_DEFINED",
 *     associationTypeId: 16,
 *     objectId: 97689713
 *   }
 * ]
 */

export function _formatUniversalAssociationsForRequest(universalAssociations) {
  return universalAssociations.map(associationCategory => {
    const {
      objectIds,
      associationSpec
    } = associationCategory.toObject();
    return objectIds.map(objectId => associationSpec.set('toObjectId', Number(objectId)));
  }).toList().flatten(1).toJS();
}

/* Save the given task definition on the backend
 * @param {TaskRecord} task
 * @param {Boolean} isLegacyAssociationFormat - whether the associations are specified in "contactIds", etc. format.
 *                                              We can delete legacy association stuff when UAS is fully rolled out.
 */
export function createTask(task, isLegacyAssociationFormat = false) {
  const associations = isLegacyAssociationFormat ? _formatLegacyAssociationsForRequest(task.associations) : _formatUniversalAssociationsForRequest(task.associations);
  const properties = pipe(_transformArrayProperties, map(property => property.value), toJS)(task.properties);
  return getRequestHeaders().then(requestHeaders => send({
    type: POST,
    headers: requestHeaders
  }, `tasks/v2/tasks`, {
    associations,
    properties
  }, mapTaskAPIV1ResponseToTaskRecord)).catch(error => {
    throw error;
  });
}
const prepareForUpdate = task => {
  // NOTE: currently only these four associations are supported on the backend,
  // but that will likely change as custom objects become prevalent
  const supportedAssociations = ['contactIds', 'companyIds', 'dealIds', 'ticketIds'];
  return task.update('associations', pick(supportedAssociations));
};

/**
 * @deprecated prefer using patchTask instead which passes arguments more granularly.
 * @param {*} requestedTaskUpdate
 */
export function updateTask(requestedTaskUpdate) {
  const task = prepareForUpdate(requestedTaskUpdate);
  return getRequestHeaders().then(requestHeaders => send({
    type: PATCH,
    headers: requestHeaders
  }, `tasks/v1/tasks/${getId(task)}`, {
    properties: _formatPropertiesForRequest(task.properties),
    associations: _formatLegacyAssociationsForRequest(task.associations)
  }, mapTaskAPIV1ResponseToTaskRecord)).catch(error => {
    throw error;
  });
}

/**
 *
 * @param {Number} taskId
 * @param {Object} propertyUpdates { propertyName: propertyValue }
 * @param {Array} associations
 */
export function patchTask(taskId, propertyUpdates, associations) {
  const payload = {};
  if (propertyUpdates) {
    payload.properties = _formatPropertiesForRequest(propertyUpdates);
  }
  if (associations) {
    payload.associations = _formatLegacyAssociationsForRequest(associations);
  }
  return getRequestHeaders().then(requestHeaders => send({
    type: PATCH,
    headers: requestHeaders
  }, `tasks/v2/tasks/${taskId}`, payload, mapTaskAPIV1ResponseToTaskRecord)).catch(error => {
    throw error;
  });
}

/* Batch fetch tasks for a specific object type
 * @param ids - array of IDs in the format:
 *    ImmutableMap({ objectType: string, objectId: string, taskId: string })
 */
export const fetchTasksByIds = ids => {
  const objectType = get('objectType', ids.first());
  // for custom objects, the objectType is also the objectTypeId
  const objectTypeId = ObjectTypesToIds[objectType] || objectType;
  const objectId = get('objectId', ids.first());
  const taskIds = ids.map(id => get('taskId', id));
  return getRequestHeaders().then(requestHeaders => send({
    type: POST,
    headers: requestHeaders,
    query: {
      includeAssociations: true,
      allPropertiesFetchMode: 'latest_version'
    }
  }, `tasks/v2/tasks/timeline/${objectTypeId}/${objectId}`, taskIds, ({
    results,
    errors
  }) => {
    const tasksObject = map(mapTaskAPIV1ResponseToTaskRecord, indexBy(result => createTaskKey({
      objectId,
      objectType,
      taskId: get('objectId', result)
    }), results));
    if (errors) {
      const notFoundTasksObject = map(() => null, indexBy(x => {
        const taskId = x.context.id[0];
        return createTaskKey({
          objectId,
          objectType,
          taskId
        });
      }, errors || []));
      return merge(tasksObject, notFoundTasksObject);
    }
    return tasksObject;
  })).catch(error => {
    throw error;
  });
};