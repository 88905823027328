import I18n from 'I18n';
import { getPreviewDefaultActionsData, getRemoveDefaultActionsData } from '../../default/getDefaultChickletHoverActions';
export const getFlywheelCompanyChickletHoverActions = behaviorArgs => args => {
  const {
    hasScope
  } = behaviorArgs;
  const removeActionButtonOverrides = {
    disabled: !hasScope('bet-change-associated-company'),
    disabledMessage: I18n.text('flywheel.FlywheelCompanyCardAction.removeAssociationDisabledMessage')
  };
  const SecondaryActions = args.canEdit ? [getRemoveDefaultActionsData(args, removeActionButtonOverrides)] : [];
  return {
    PrimaryAction: getPreviewDefaultActionsData(args),
    SecondaryActions
  };
};