import { COMPANY_TYPE_ID, CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const getAvatarLookupType = ({
  objectTypeId
}) => {
  switch (objectTypeId) {
    case COMPANY_TYPE_ID:
      return 'companyId';
    case CONTACT_TYPE_ID:
      return 'vid';
    default:
      return undefined;
  }
};
export const getAvatarProps = ({
  objectTypeId,
  objectId
}) => {
  switch (objectTypeId) {
    case COMPANY_TYPE_ID:
      return {
        companyId: objectId
      };
    case CONTACT_TYPE_ID:
      return {
        vid: objectId
      };
    default:
      return undefined;
  }
};