import uniqueId from 'transmute/uniqueId';
import invariant from 'react-utils/invariant';
import PortalIdParser from 'PortalIdParser';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import UserStore from 'crm_data/user/UserStore';
const REQUIRED_DATA_TYPE_MAP = {
  eventType: 'string',
  itemType: 'string',
  created: 'number'
};
const _requiredDataInvariantCheck = requiredData => Object.keys(REQUIRED_DATA_TYPE_MAP).forEach(key => {
  const property = requiredData[key];
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const expectedType = REQUIRED_DATA_TYPE_MAP[key];
  const actualType = typeof property;
  return invariant(actualType === expectedType, 'Sample event error: required property "%s" expected type "%s". Received: "%s"', key, expectedType, actualType);
});
const makeBaseSampleTimelineEvent = (requiredData, ownerId) => {
  requiredData = requiredData || {};
  const USER_ID = UserStore.get('user_id');
  const PORTAL_ID = PortalIdParser.get();
  _requiredDataInvariantCheck(requiredData);
  const {
    eventType,
    itemType,
    created,
    timestamp
  } = requiredData;
  const SampleEvent = {
    etype: eventType,
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    id: `${eventType}-${created}-${uniqueId()}`,
    timestamp,
    eventData: {
      associations: {
        companyIds: [],
        contactIds: [],
        dealIds: [],
        ownerIds: [],
        workflowIds: []
      },
      attachments: [],
      engagement: {
        isSample: true,
        active: true,
        createdAt: created,
        createdBy: USER_ID,
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        id: parseInt(uniqueId(), 10),
        lastUpdated: created,
        modifiedBy: USER_ID,
        ownerId,
        portalId: PORTAL_ID,
        timestamp,
        type: itemType
      },
      metadata: {}
    }
  };
  return SampleEvent;
};
export default makeBaseSampleTimelineEvent;