import { useCallback, useEffect, useRef, useState } from 'react';
import { useCardContainerContext } from './CardContainerContext';
import Raven from 'raven-js';
function useObserveElement(observerManager, element, callback, shouldSubscribeRef) {
  useEffect(() => {
    if (observerManager && element && shouldSubscribeRef.current) {
      observerManager.subscribe(element, callback);
      return () => {
        observerManager.unsubscribe(element);
      };
    }
  }, [element, observerManager, callback, shouldSubscribeRef]);
}
function isElementInitiallyVisible(el, root) {
  const elRect = el.getBoundingClientRect();
  const rootRect = root === document || !root ? document.body.getBoundingClientRect() : root.getBoundingClientRect();
  return elRect.top <= rootRect.bottom && elRect.bottom >= rootRect.top && elRect.left <= rootRect.right && elRect.right >= rootRect.left;
}

/*
 * Observes an element. Uses an IntersectionObserverManager from CardContainerContext.
 * If there is no IntersectionObserverManager, this will only return the initial visibility state of the element.
 * @returns true if the element is intersecting, false if it is not, and undefined if the element is not in the dom yet.
 */
export function useIsIntersecting(element, unobserveAfterFirstIntersect = false) {
  const {
    observerManager
  } = useCardContainerContext();
  const [isIntersecting, setIntersecting] = useState(undefined);
  const shouldSubscribe = useRef(true);

  // If this is one of the first few renders where isIntersecting isn't initialized, grab
  // the initial element visibility state by manually checking the dom.
  // This helps get the visibility state back to the caller a render cycle faster
  const isInitiallyVisible = element && isIntersecting === undefined ? isElementInitiallyVisible(element, observerManager === null || observerManager === void 0 ? void 0 : observerManager.getRoot()) : undefined;
  if (isInitiallyVisible !== undefined) {
    // Ref must be updated before calling the `useObserveElement` hook
    shouldSubscribe.current = !(unobserveAfterFirstIntersect && isInitiallyVisible);
  }
  useEffect(() => {
    if (isIntersecting === undefined && isInitiallyVisible !== undefined) {
      setIntersecting(isInitiallyVisible);
    }
  }, [isIntersecting, isInitiallyVisible]);
  const callback = useCallback(entry => {
    if (unobserveAfterFirstIntersect) {
      if (entry.isIntersecting) {
        observerManager.unsubscribe(entry.target);
        setIntersecting(entry.isIntersecting);
      }
    } else {
      setIntersecting(entry.isIntersecting);
    }
  }, [observerManager, unobserveAfterFirstIntersect]);
  useObserveElement(observerManager, element, callback, shouldSubscribe);
  if (!observerManager) {
    Raven.captureException(new Error("useIsIntersecting was called, but CardContainerContext doesn't provide an `observerManager`."));
    return true;
  }
  return isIntersecting === undefined ? isInitiallyVisible : isIntersecting;
}