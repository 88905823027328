import Loadable from 'UIComponents/decorators/Loadable';
import { AsyncComponentErrorHandler, AsyncComponentLoadingHandler } from 'crm-events-components';
const ActivityQuickViewModalContainerAsync = Loadable({
  loader: () => import(
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'BizO... Remove this comment to see the full error message
  /* webpackChunkName: "activity-quick-view-modal-container" */
  'BizOpsCrmUIComponents/components/customerSummary/activityHistory/quickView/ActivityQuickViewModalContainer').then(mod => mod.default),
  LoadingComponent: AsyncComponentLoadingHandler(),
  ErrorComponent: AsyncComponentErrorHandler('activity-quick-view-modal-container')
});
export default ActivityQuickViewModalContainerAsync;