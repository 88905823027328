import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import quickFetch from 'quick-fetch';
const CUSTOM_OBJECT_REGEX = /^(2-\d+)$/;
const fetchObjectTypeCrmRecordsPageFAS = objectType => {
  const fallbackRequest = () => http.get(`framework-builder/v1/read/metadata/type/${objectType}/crmRecordsPage`);
  const earlyRequest = quickFetch.getRequestStateByName('record:FAS');
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(response => {
        resolve(response);
        quickFetch.removeEarlyRequest('record:FAS');
      });
      earlyRequest.onError(() => {
        fallbackRequest().then(resolve, reject);
      });
    });
  }
  return fallbackRequest();
};
const ObjectTypeCrmRecordsPageFAS = registerQuery({
  fieldName: 'objectTypeCrmRecordsPageFAS',
  args: ['objectType'],
  fetcher({
    objectType
  }) {
    return fetchObjectTypeCrmRecordsPageFAS(objectType);
  }
});
export const useFetchObjectTypeCrmRecordsPageFAS = objectType => {
  return useQuery(ObjectTypeCrmRecordsPageFAS, {
    variables: {
      objectType
    },
    skip: Boolean(objectType.match(CUSTOM_OBJECT_REGEX)),
    onError: error => {
      console.error(error);
    }
  });
};