/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import debounce from 'transmute/debounce';
import { useState, useEffect, useCallback, useMemo } from 'react';
const buildQuery = searchQuery => ({
  query: searchQuery,
  count: 50,
  offset: 0
});
export default ((search, transformData, initialQuery = '', minimumSearch = 0) => {
  const [data, setData] = useState(null);
  const [searchQuery, _setSearchQuery] = useState(initialQuery);
  const [isLoading, setLoading] = useState(true);
  const performSearch = useCallback(_searchQuery => {
    setLoading(true);
    search(buildQuery(_searchQuery)).then(results => {
      setData(results);
      setLoading(false);
    });
  }, [search, setData, setLoading]);
  const debouncedSearch = useMemo(() => debounce(200, performSearch), [performSearch]);
  const setSearchQuery = useCallback(newSearchQuery => {
    _setSearchQuery(newSearchQuery);
  }, [_setSearchQuery]);
  useEffect(() => {
    if (searchQuery.length < minimumSearch) {
      return;
    }
    if (searchQuery.length === 0) {
      performSearch(searchQuery);
    }
    debouncedSearch(searchQuery);
  }, [debouncedSearch, searchQuery, minimumSearch, performSearch]);
  const options = useMemo(() => transformData ? transformData(data) : data, [data, transformData]);
  return [data, searchQuery, setSearchQuery, options, isLoading];
});