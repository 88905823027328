const EMPTY = null;
const LOADING = undefined;
export {
/**
 * Placeholder for a value that could not be resolved.
 *
 * @type {null}
 */
EMPTY,
/**
 * Placeholder for a value that is currently being resolved.
 *
 * @type {undefined}
 */
LOADING };
function equalsEmpty(value) {
  return value === EMPTY;
}

/**
 * Returns `true` if any item in `values` is `EMPTY`.
 *
 * @param  {Array<EMPTY|LOADING|any>} ...values
 * @return {boolean}
 */
export function isEmpty(...values) {
  return values.some(equalsEmpty);
}
function equalsLoading(value) {
  return value === LOADING;
}

/**
 * Returns `true` if any item in `values` is `LOADING`.
 *
 * @param  {Array<EMPTY|LOADING|any>} ...values
 * @return {boolean}
 */
export function isLoading(...values) {
  return values.some(equalsLoading);
}
function equalsResolved(value) {
  return !equalsLoading(value) && !equalsEmpty(value);
}

/**
 * Returns `true` if no item in `values` is `EMPTY` or `LOADING`.
 *
 * @param  {Array<EMPTY|LOADING|any>} ...values
 * @return {boolean}
 */
export function isResolved(...values) {
  return values.every(equalsResolved);
}