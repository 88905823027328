'use es6';

import { EMAIL, CALL, MEETING, NOTE, TASK, WHATS_APP, POSTAL_MAIL } from 'customer-data-objects/engagement/EngagementTypes';
import { TITLE, REMINDERS, TYPE, PRIORITY, SEND_DEFAULT_REMINDER, NOTES, STATUS, QUEUE_IDS, FOR_OBJECT_TYPE } from 'customer-data-objects/task/TaskPropertyNames';
import { Map as ImmutableMap } from 'immutable';

/**
 * Useful links:
 *
 * BE list of all properties for engagements: https://git.hubteam.com/HubSpot/CrmPropertyNames/blob/master/CrmPropertyNamesBase/src/main/java/com/hubspot/crm/properties/defaults/properties/DefaultEngagementProperty.java
 * BE properties (engagament v1 --> engaegment v2): https://git.hubteam.com/HubSpot/Engagements/blob/4d61ae3c431d0a11393a2179dd4750cf0232c26c/EngagementsData/src/main/java/com/hubspot/engagements/data/hbase/PropertyValueAdapter.java#L164
 * Permissions properties (only have to set owner): https://git.hubteam.com/HubSpot/CrmPropertyNames/blob/master/CrmPropertyNamesBase/src/main/java/com/hubspot/crm/properties/defaults/properties/StandardPermissionsProperty.java
 *
 */

/**
 * propertyNameV1 is the name of the property in the { engagement, metadata, associations, etc} format
 * sourcePropertyNameV2 is the raw property name that the property is derived/parsed from
 * propertyNameV2 is the raw property name
 * type is the type of data of the property's value (if undefined, we assume string)
 * v2ToV1Parser is a function that allows the property value to be parsed into the V1 format from the V2 format
 * v1ToV2Parser is a function that allows the property value to be parsed into the V2 format from the V1 format
 * excludeOnCreate is a boolean that indicates whether a property is a read only value on engagement create
 */

export const ARRAY_OF_NUMBERS = 'ARRAY_OF_NUMBERS';
const parseArrayOfNumbers = values => {
  if (Array.isArray(values)) {
    return values;
  }
  return values ? `${values}`.split(';') : values;
};
const reverseParseArrayOfNumbers = values => {
  return values ? values.join(';') : values;
};
const getBodyLength = body => body ? body.length : undefined;
const convertToNumber = value => isNaN(value) ? value : +value;
const getEmailParticipantDataFetcher = propertyName => value => {
  const valueArray = value.reduce((acc, participant) => {
    const participantData = participant.get(propertyName);
    if (participantData) {
      acc.push(participantData);
    }
    return acc;
  }, []);
  return valueArray.length ? valueArray.join(';') : undefined;
};
export const PROPERTIES_BY_ENGAGEMENT_TYPE = ImmutableMap({
  [CALL]: [{
    propertyNameV1: 'body',
    propertyPathV1: ['metadata', 'body'],
    propertyNameV2: 'hs_call_body'
  }, {
    propertyNameV1: 'bodyLength',
    propertyPathV1: ['metadata', 'bodyLength'],
    propertyNameV2: 'hs_call_body_length',
    sourcePropertyNameV2: 'hs_call_body',
    v2ToV1Parser: body => body ? body.length : undefined,
    v1ToV2Parser: () => undefined,
    type: Number
  }, {
    propertyNameV1: 'title',
    propertyPathV1: ['metadata', 'title'],
    propertyNameV2: 'hs_call_title'
  }, {
    propertyNameV1: 'disposition',
    propertyPathV1: ['metadata', 'disposition'],
    propertyNameV2: 'hs_call_disposition'
  }, {
    propertyNameV1: 'status',
    propertyPathV1: ['metadata', 'status'],
    propertyNameV2: 'hs_call_status'
  }, {
    propertyNameV1: 'bodyPreview',
    propertyPathV1: ['engagement', 'bodyPreview'],
    propertyNameV2: 'hs_body_preview'
  }, {
    propertyNameV1: 'bodyPreviewHtml',
    propertyPathV1: ['engagement', 'bodyPreviewHtml'],
    propertyNameV2: 'hs_body_preview_html'
  }, {
    propertyNameV1: 'bodyPreviewIsTruncated',
    propertyPathV1: ['engagement', 'bodyPreviewIsTruncated'],
    propertyNameV2: 'hs_body_preview_is_truncated',
    type: Boolean
  }, {
    propertyNameV1: 'fromNumber',
    propertyPathV1: ['metadata', 'fromNumber'],
    propertyNameV2: 'hs_call_from_number'
  }, {
    propertyNameV1: 'calleeObjectType',
    propertyPathV1: ['metadata', 'calleeObjectType'],
    propertyNameV2: 'hs_call_callee_object_type'
  }, {
    propertyNameV1: 'calleeObjectId',
    propertyPathV1: ['metadata', 'calleeObjectId'],
    propertyNameV2: 'hs_call_callee_object_id',
    type: Number
  }, {
    propertyNameV1: 'callsServiceCallId',
    propertyPathV1: ['metadata', 'callsServiceCallId'],
    propertyNameV2: 'hs_calls_service_call_id',
    type: Number
  }, {
    propertyNameV1: 'toNumber',
    propertyPathV1: ['metadata', 'toNumber'],
    propertyNameV2: 'hs_call_to_number'
  }, {
    propertyNameV1: 'durationMilliseconds',
    propertyPathV1: ['metadata', 'durationMilliseconds'],
    propertyNameV2: 'hs_call_duration',
    type: Number
  }, {
    propertyNameV1: 'externalAccountId',
    propertyPathV1: ['metadata', 'externalAccountId'],
    propertyNameV2: 'hs_call_external_account_id'
  }, {
    propertyNameV1: 'externalId',
    propertyPathV1: ['metadata', 'externalId'],
    propertyNameV2: 'hs_call_external_id'
  }, {
    propertyNameV1: 'source',
    propertyPathV1: ['metadata', 'source'],
    propertyNameV2: 'hs_call_source'
  }, {
    propertyNameV1: 'recordingUrl',
    propertyPathV1: ['metadata', 'recordingUrl'],
    propertyNameV2: 'hs_call_recording_url'
  }, {
    propertyNameV1: 'direction',
    propertyPathV1: ['metadata', 'direction'],
    propertyNameV2: 'hs_call_direction'
  }],
  [EMAIL]: [{
    propertyNameV1: 'bodyPreview',
    propertyPathV1: ['engagement', 'bodyPreview'],
    propertyNameV2: 'hs_body_preview'
  }, {
    propertyNameV1: 'bodyPreviewHtml',
    propertyPathV1: ['engagement', 'bodyPreviewHtml'],
    propertyNameV2: 'hs_body_preview_html'
  }, {
    propertyNameV1: 'uid',
    propertyPathV1: ['engagement', 'uid'],
    propertyNameV2: 'hs_unique_id'
  }, {
    propertyNameV1: 'bodyPreviewIsTruncated',
    propertyPathV1: ['engagement', 'bodyPreviewIsTruncated'],
    propertyNameV2: 'hs_body_preview_is_truncated',
    type: Boolean
  }, {
    propertyNameV1: 'queueMembershipIds',
    propertyPathV1: ['engagement', 'queueMembershipIds'],
    propertyNameV2: 'hs_queue_membership_ids',
    v1ToV2Parser: reverseParseArrayOfNumbers,
    v2ToV1Parser: parseArrayOfNumbers,
    type: ARRAY_OF_NUMBERS
  }, {
    propertyNameV1: 'subject',
    propertyPathV1: ['metadata', 'subject'],
    propertyNameV2: 'hs_email_subject'
  }, {
    propertyNameV1: 'html',
    propertyPathV1: ['metadata', 'html'],
    propertyNameV2: 'hs_email_html'
  }, {
    propertyNameV1: 'plainText',
    propertyPathV1: ['metadata', 'plainText'],
    propertyNameV2: 'hs_email_text'
  }, {
    propertyNameV1: 'bodyLength',
    propertyPathV1: ['metadata', 'bodyLength'],
    propertyNameV2: 'hs_email_body_length',
    sourcePropertyNameV2: 'hs_email_html',
    v2ToV1Parser: getBodyLength,
    v1ToV2Parser: () => undefined,
    type: Number
  }, {
    propertyNameV1: 'threadId',
    propertyPathV1: ['metadata', 'threadId'],
    propertyNameV2: 'hs_email_thread_id'
  }, {
    propertyNameV1: 'messageId',
    propertyPathV1: ['metadata', 'messageId'],
    propertyNameV2: 'hs_email_message_id'
  }, {
    propertyNameV1: 'sentVia',
    propertyPathV1: ['metadata', 'sentVia'],
    propertyNameV2: 'hs_email_sent_via'
  }, {
    propertyNameV1: 'trackerKey',
    propertyPathV1: ['metadata', 'trackerKey'],
    propertyNameV2: 'hs_email_tracker_key'
  }, {
    propertyNameV1: 'status',
    propertyPathV1: ['metadata', 'status'],
    propertyNameV2: 'hs_email_status'
  }, {
    propertyNameV1: 'gdprDeleted',
    propertyPathV1: ['engagement', 'gdprDeleted'],
    propertyNameV2: 'hs_gdpr_deleted',
    type: Boolean
  }, {
    propertyNameV1: 'postSendStatus',
    propertyPathV1: ['metadata', 'postSendStatus'],
    propertyNameV2: 'hs_email_post_send_status'
  }, {
    propertyNameV1: 'facsimileSendId',
    propertyPathV1: ['metadata', 'facsimileSendId'],
    propertyNameV2: 'hs_email_facsimile_send_id'
  }, {
    propertyNameV1: 'sender',
    propertyPathV1: ['metadata', 'sender'],
    sourcePropertyNameV2: 'hs_email_headers',
    v2ToV1Parser: header => {
      const headerObj = header ? JSON.parse(header) : {};
      return headerObj.sender;
    },
    v1ToV2Parser: () => undefined,
    excludeOnCreate: true
  }, {
    propertyNameV1: 'bcc',
    propertyPathV1: ['metadata', 'bcc'],
    sourcePropertyNameV2: 'hs_email_headers',
    v2ToV1Parser: header => {
      const headerObj = header ? JSON.parse(header) : {};
      return headerObj.bcc;
    },
    v1ToV2Parser: () => undefined,
    excludeOnCreate: true
  }, {
    propertyNameV1: 'cc',
    propertyPathV1: ['metadata', 'cc'],
    sourcePropertyNameV2: 'hs_email_headers',
    v2ToV1Parser: header => {
      const headerObj = header ? JSON.parse(header) : {};
      return headerObj.cc;
    },
    v1ToV2Parser: () => undefined,
    excludeOnCreate: true
  }, {
    propertyNameV1: 'from',
    propertyPathV1: ['metadata', 'from'],
    sourcePropertyNameV2: 'hs_email_headers',
    v2ToV1Parser: header => {
      const headerObj = header ? JSON.parse(header) : {};
      return headerObj.from;
    },
    v1ToV2Parser: () => undefined,
    excludeOnCreate: true
  }, {
    propertyNameV1: 'to',
    propertyPathV1: ['metadata', 'to'],
    sourcePropertyNameV2: 'hs_email_headers',
    v2ToV1Parser: header => {
      const headerObj = header ? JSON.parse(header) : {};
      return headerObj.to;
    },
    v1ToV2Parser: () => undefined,
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'to'],
    propertyNameV2: 'hs_email_to_firstname',
    v2ToV1Parser: () => undefined,
    v1ToV2Parser: getEmailParticipantDataFetcher('firstName'),
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'to'],
    propertyNameV2: 'hs_email_to_lastname',
    v2ToV1Parser: () => undefined,
    v1ToV2Parser: getEmailParticipantDataFetcher('lastName'),
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'to'],
    propertyNameV2: 'hs_email_to_email',
    v2ToV1Parser: () => undefined,
    v1ToV2Parser: getEmailParticipantDataFetcher('email'),
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'to'],
    propertyNameV2: 'hs_email_to_raw',
    v2ToV1Parser: () => undefined,
    v1ToV2Parser: getEmailParticipantDataFetcher('raw'),
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'cc'],
    propertyNameV2: 'hs_email_cc_firstname',
    v2ToV1Parser: () => undefined,
    v1ToV2Parser: getEmailParticipantDataFetcher('firstName'),
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'cc'],
    propertyNameV2: 'hs_email_cc_lastname',
    v2ToV1Parser: () => undefined,
    v1ToV2Parser: getEmailParticipantDataFetcher('lastName'),
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'cc'],
    propertyNameV2: 'hs_email_cc_email',
    v2ToV1Parser: () => undefined,
    v1ToV2Parser: getEmailParticipantDataFetcher('email'),
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'cc'],
    propertyNameV2: 'hs_email_cc_raw',
    v2ToV1Parser: () => undefined,
    v1ToV2Parser: getEmailParticipantDataFetcher('raw'),
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'bcc'],
    propertyNameV2: 'hs_email_bcc_firstname',
    v2ToV1Parser: () => undefined,
    v1ToV2Parser: getEmailParticipantDataFetcher('firstName'),
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'bcc'],
    propertyNameV2: 'hs_email_bcc_lastname',
    v2ToV1Parser: () => undefined,
    v1ToV2Parser: getEmailParticipantDataFetcher('lastName'),
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'bcc'],
    propertyNameV2: 'hs_email_bcc_email',
    v2ToV1Parser: () => undefined,
    v1ToV2Parser: getEmailParticipantDataFetcher('email'),
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'bcc'],
    propertyNameV2: 'hs_email_bcc_raw',
    v2ToV1Parser: () => undefined,
    v1ToV2Parser: getEmailParticipantDataFetcher('raw'),
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'from', 'firstName'],
    propertyNameV2: 'hs_email_from_firstname',
    v2ToV1Parser: () => undefined,
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'from', 'lastName'],
    propertyNameV2: 'hs_email_from_lastname',
    v2ToV1Parser: () => undefined,
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'from', 'email'],
    propertyNameV2: 'hs_email_from_email',
    v2ToV1Parser: () => undefined,
    excludeOnCreate: true
  }, {
    propertyPathV1: ['metadata', 'sender', 'email'],
    propertyNameV2: 'hs_email_sender_email',
    v2ToV1Parser: () => undefined,
    excludeOnCreate: true
  }],
  [MEETING]: [{
    propertyNameV1: 'body',
    propertyPathV1: ['metadata', 'body'],
    propertyNameV2: 'hs_meeting_body'
  }, {
    propertyNameV1: 'bodyPreview',
    propertyPathV1: ['engagement', 'bodyPreview'],
    propertyNameV2: 'hs_body_preview'
  }, {
    propertyNameV1: 'bodyPreviewHtml',
    propertyPathV1: ['engagement', 'bodyPreviewHtml'],
    propertyNameV2: 'hs_body_preview_html'
  }, {
    propertyNameV1: 'bodyLength',
    propertyPathV1: ['metadata', 'bodyLength'],
    sourcePropertyNameV2: 'hs_meeting_body',
    propertyNameV2: 'hs_meeting_body_length',
    v2ToV1Parser: getBodyLength,
    v1ToV2Parser: () => undefined,
    type: Number
  }, {
    propertyNameV1: 'internalMeetingNotes',
    propertyPathV1: ['metadata', 'internalMeetingNotes'],
    propertyNameV2: 'hs_internal_meeting_notes'
  }, {
    propertyNameV1: 'attendeeOwnerIds',
    propertyPathV1: ['metadata', 'attendeeOwnerIds'],
    propertyNameV2: 'hs_attendee_owner_ids',
    v1ToV2Parser: reverseParseArrayOfNumbers,
    v2ToV1Parser: parseArrayOfNumbers,
    type: ARRAY_OF_NUMBERS
  }, {
    propertyNameV1: 'location',
    propertyPathV1: ['metadata', 'location'],
    propertyNameV2: 'hs_meeting_location'
  }, {
    propertyNameV1: 'locationType',
    propertyPathV1: ['metadata', 'locationType'],
    propertyNameV2: 'hs_meeting_location_type'
  }, {
    propertyNameV1: 'endTime',
    propertyPathV1: ['metadata', 'endTime'],
    propertyNameV2: 'hs_meeting_end_time',
    type: Number
  }, {
    propertyNameV1: 'meetingOutcome',
    propertyPathV1: ['metadata', 'meetingOutcome'],
    propertyNameV2: 'hs_meeting_outcome'
  }, {
    propertyNameV1: 'rosterObjectCoordinates',
    propertyPathV1: ['metadata', 'rosterObjectCoordinates'],
    propertyNameV2: 'hs_roster_object_coordinates'
  }, {
    propertyNameV1: 'startTime',
    propertyPathV1: ['metadata', 'startTime'],
    propertyNameV2: 'hs_meeting_start_time',
    type: Number
  }, {
    propertyNameV1: 'title',
    propertyPathV1: ['metadata', 'title'],
    propertyNameV2: 'hs_meeting_title'
  }, {
    propertyNameV1: 'webConferenceMeetingId',
    propertyPathV1: ['metadata', 'webConferenceMeetingId'],
    propertyNameV2: 'hs_meeting_web_conference_meeting_id'
  }, {
    propertyNameV1: 'bodyPreviewIsTruncated',
    propertyPathV1: ['engagement', 'bodyPreviewIsTruncated'],
    propertyNameV2: 'hs_body_preview_is_truncated',
    type: Boolean
  }, {
    propertyNameV1: 'iCalUid',
    propertyPathV1: ['metadata', 'iCalUid'],
    propertyNameV2: 'hs_i_cal_uid'
  }, {
    propertyNameV1: 'externalUrl',
    propertyPathV1: ['metadata', 'externalUrl'],
    propertyNameV2: 'hs_meeting_external_url'
  }, {
    propertyNameV1: 'uid',
    propertyPathV1: ['engagement', 'uid'],
    propertyNameV2: 'hs_unique_id'
  }, {
    propertyNameV1: 'scheduledTasks',
    propertyPathV1: ['scheduledTasks'],
    propertyNameV2: 'hs_scheduled_tasks',
    v2ToV1Parser: scheduledTasksStr => {
      const scheduledTaskObj = scheduledTasksStr ? JSON.parse(scheduledTasksStr) : {};
      return scheduledTaskObj.scheduledTasks;
    },
    v1ToV2Parser: scheduledTasksList => {
      const scheduledTasksArray = scheduledTasksList.reduce((acc, scheduledTask) => {
        acc.push(scheduledTask.toJS());
        return acc;
      }, []);
      const objToConvert = {
        scheduledTasks: scheduledTasksArray
      };
      return JSON.stringify(objToConvert);
    },
    excludeOnCreate: true
  }],
  [NOTE]: [{
    propertyNameV1: 'body',
    propertyPathV1: ['metadata', 'body'],
    propertyNameV2: 'hs_note_body'
  }, {
    propertyNameV1: 'bodyLength',
    propertyPathV1: ['metadata', 'bodyLength'],
    sourcePropertyNameV2: 'hs_note_body',
    propertyNameV2: 'hs_note_body_length',
    v2ToV1Parser: getBodyLength,
    v1ToV2Parser: () => undefined,
    type: Number
  }, {
    propertyNameV1: 'bodyPreview',
    propertyPathV1: ['engagement', 'bodyPreview'],
    propertyNameV2: 'hs_body_preview'
  }, {
    propertyNameV1: 'bodyPreviewHtml',
    propertyPathV1: ['engagement', 'bodyPreviewHtml'],
    propertyNameV2: 'hs_body_preview_html'
  }, {
    propertyNameV1: 'bodyPreviewIsTruncated',
    propertyPathV1: ['engagement', 'bodyPreviewIsTruncated'],
    propertyNameV2: 'hs_body_preview_is_truncated',
    type: Boolean
  }],
  [TASK]: [{
    propertyNameV1: 'body',
    propertyPathV1: ['metadata', 'body'],
    propertyNameV2: NOTES
  }, {
    propertyNameV1: 'forObjectType',
    propertyPathV1: ['metadata', 'forObjectType'],
    propertyNameV2: FOR_OBJECT_TYPE
  }, {
    propertyNameV1: 'priority',
    propertyPathV1: ['metadata', 'priority'],
    propertyNameV2: PRIORITY
  }, {
    propertyNameV1: 'reminders',
    propertyPathV1: ['metadata', 'reminders'],
    propertyNameV2: REMINDERS,
    v1ToV2Parser: reverseParseArrayOfNumbers,
    v2ToV1Parser: parseArrayOfNumbers,
    type: ARRAY_OF_NUMBERS
  }, {
    propertyNameV1: 'setDefaultReminder',
    propertyPathV1: ['metadata', 'setDefaultReminder'],
    propertyNameV2: SEND_DEFAULT_REMINDER
  }, {
    propertyNameV1: 'status',
    propertyPathV1: ['metadata', 'status'],
    propertyNameV2: STATUS
  }, {
    propertyNameV1: 'subject',
    propertyPathV1: ['metadata', 'subject'],
    propertyNameV2: TITLE
  }, {
    propertyNameV1: 'taskType',
    propertyPathV1: ['metadata', 'taskType'],
    propertyNameV2: TYPE
  }, {
    propertyNameV1: 'queueMembershipIds',
    propertyPathV1: ['engagement', 'queueMembershipIds'],
    propertyNameV2: QUEUE_IDS,
    v1ToV2Parser: reverseParseArrayOfNumbers,
    v2ToV1Parser: parseArrayOfNumbers,
    type: ARRAY_OF_NUMBERS
  }, {
    propertyNameV1: 'bodyPreview',
    propertyPathV1: ['engagement', 'bodyPreview'],
    propertyNameV2: 'hs_body_preview'
  }, {
    propertyNameV1: 'bodyPreviewHtml',
    propertyPathV1: ['engagement', 'bodyPreviewHtml'],
    propertyNameV2: 'hs_body_preview_html'
  }, {
    propertyNameV1: 'bodyPreviewIsTruncated',
    propertyPathV1: ['engagement', 'bodyPreviewIsTruncated'],
    propertyNameV2: 'hs_body_preview_is_truncated',
    type: Boolean
  }, {
    propertyNameV1: 'isAllDay',
    propertyPathV1: ['metadata', 'isAllDay'],
    propertyNameV2: 'hs_task_is_all_day',
    type: Boolean
  }, {
    propertyNameV1: 'bodyLength',
    propertyPathV1: ['metadata', 'bodyLength'],
    sourcePropertyNameV2: 'hs_task_body',
    propertyNameV2: 'hs_task_body_length',
    v2ToV1Parser: getBodyLength,
    v1ToV2Parser: () => undefined,
    type: Number
  }, {
    propertyNameV1: 'scheduledTasks',
    propertyPathV1: ['scheduledTasks'],
    propertyNameV2: 'hs_scheduled_tasks',
    v2ToV1Parser: scheduledTasksStr => {
      const scheduledTaskObj = scheduledTasksStr ? JSON.parse(scheduledTasksStr) : {};
      return scheduledTaskObj.scheduledTasks;
    },
    v1ToV2Parser: scheduledTasksList => {
      const scheduledTasksArray = scheduledTasksList.reduce((acc, scheduledTask) => {
        acc.push(scheduledTask.toJS());
        return acc;
      }, []);
      const objToConvert = {
        scheduledTasks: scheduledTasksArray
      };
      return JSON.stringify(objToConvert);
    }
  }],
  [WHATS_APP]: [{
    propertyNameV1: 'body',
    propertyPathV1: ['metadata', 'body'],
    propertyNameV2: 'hs_communication_body'
  }, {
    propertyNameV1: 'loggedFrom',
    propertyPathV1: ['metadata', 'loggedFrom'],
    propertyNameV2: 'hs_communication_logged_from'
  }],
  [POSTAL_MAIL]: [{
    propertyNameV1: 'body',
    propertyPathV1: ['metadata', 'body'],
    propertyNameV2: 'hs_postal_mail_body'
  }, {
    propertyNameV1: 'mailType',
    propertyPathV1: ['metadata', 'mailType'],
    propertyNameV2: 'hs_postal_mail_type'
  }]
});
export const COMMON_PROPERTIES_WITH_PATHS = [{
  propertyNameV1: 'hs_activity_type',
  propertyPathV1: ['engagement', 'activityType'],
  propertyNameV2: 'hs_activity_type'
}, {
  propertyNameV1: 'id',
  propertyPathV1: ['engagement', 'id'],
  propertyNameV2: 'hs_object_id',
  type: Number
}, {
  propertyNameV1: 'createdAt',
  propertyPathV1: ['engagement', 'createdAt'],
  propertyNameV2: 'hs_createdate',
  type: Number
}, {
  propertyNameV1: 'createdBy',
  propertyPathV1: ['engagement', 'createdBy'],
  propertyNameV2: 'hs_created_by',
  type: Number
}, {
  propertyNameV1: 'type',
  propertyPathV1: ['engagement', 'type'],
  propertyNameV2: 'hs_engagement_type'
}, {
  propertyNameV1: 'ownerId',
  propertyPathV1: ['engagement', 'ownerId'],
  propertyNameV2: 'hubspot_owner_id',
  type: Number
}, {
  propertyNameV1: 'ownerIds',
  propertyPathV1: ['associations', 'ownerIds'],
  propertyNameV2: 'hs_at_mentioned_owner_ids',
  v1ToV2Parser: reverseParseArrayOfNumbers,
  v2ToV1Parser: parseArrayOfNumbers,
  type: ARRAY_OF_NUMBERS
}, {
  propertyNameV1: 'source',
  propertyPathV1: ['engagement', 'source'],
  propertyNameV2: 'hs_engagement_source'
}, {
  propertyNameV1: 'sourceId',
  propertyPathV1: ['engagement', 'sourceId'],
  propertyNameV2: 'hs_engagement_source_id'
}, {
  propertyNameV1: 'timestamp',
  propertyPathV1: ['engagement', 'timestamp'],
  propertyNameV2: 'hs_timestamp',
  type: Number
}, {
  propertyNameV1: 'attachments',
  propertyPathV1: ['attachments'],
  propertyNameV2: 'hs_attachment_ids',
  v1ToV2Parser: values => {
    const parsedValues = values.toJS().map(value => value.id);
    return parsedValues.join(';');
  },
  v2ToV1Parser: values => {
    const parsedValues = Array.isArray(values) ? values : values.split(';');
    return parsedValues.map(value => value.id ? convertToNumber(value) : {
      id: convertToNumber(value)
    });
  },
  type: ARRAY_OF_NUMBERS
}, {
  propertyNameV1: 'allAccessibleTeamIds',
  propertyPathV1: ['engagement', 'allAccessibleTeamIds'],
  propertyNameV2: 'hs_all_accessible_team_ids',
  v1ToV2Parser: reverseParseArrayOfNumbers,
  v2ToV1Parser: parseArrayOfNumbers,
  type: ARRAY_OF_NUMBERS
}];