import { registerQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
export const GET_MATCH_ID = registerQuery({
  fieldName: 'socialProfileMatchId',
  args: ['profileKey', 'contactId'],
  fetcher({
    profileKey,
    contactId
  }) {
    return http.get('socialcontacts/v2/matches/lookup', {
      query: {
        profileKey,
        contactId
      }
    });
  }
});