import { SOURCE_PATH, TASK_STATUS_PATH, TASK_UID_PATH } from '../constants/TimelineTaskConstants';
import { COMPLETED } from 'customer-data-objects/engagement/EngagementStatusTypes';
import { SEQUENCES } from 'customer-data-objects/property/PropertySourceTypes';
import promiseDone from 'hs-promise-utils/promiseDone';
// @ts-expect-error not typed
import SequenceActions from 'sequences-crm-lib/flux/SequenceActions';
export function shouldFetchTaskDependency(engagement, sequenceEnrollment, taskDependency) {
  if (!engagement) {
    return false;
  }
  return !taskDependency && sequenceEnrollment && engagement.getIn(SOURCE_PATH) === SEQUENCES;
}
export function maybeFetchTaskDependency(engagement, sequenceEnrollment, taskDependency) {
  if (!engagement) {
    return;
  }
  const shouldFetch = shouldFetchTaskDependency(engagement, sequenceEnrollment, taskDependency) &&
  // keep this here instead of in shouldFetchTaskDependency so other consumers
  // can use that logic to determine fetching regardless of engagement status
  engagement.getIn(TASK_STATUS_PATH) !== COMPLETED;
  if (shouldFetch) {
    const taskUid = engagement.getIn(TASK_UID_PATH);
    promiseDone(SequenceActions.fetchTaskDependency(taskUid));
  }
}
export function isTaskDependencyPausingSequence(engagement, sequenceEnrollment, taskDependency) {
  if (!taskDependency || !sequenceEnrollment) {
    return false;
  }
  const taskUid = engagement.getIn(TASK_UID_PATH);
  const taskDependencyUid = taskDependency.get('taskUid');
  const enrollmentId = sequenceEnrollment.get('sequenceEnrollmentId');
  const taskDependencyEnrollmentId = taskDependency.getIn(['taskDependencyMetadata', 'enrollmentId']);
  const resolvedAt = taskDependency.getIn(['taskDependencyMetadata', 'resolvedAt']);
  return taskDependencyUid === taskUid && taskDependencyEnrollmentId === enrollmentId && resolvedAt === null;
}
export function didTaskDependencyPauseSequence(engagement, sequenceEnrollment, taskDependency) {
  if (!taskDependency || !sequenceEnrollment) {
    return false;
  }
  const taskUid = engagement.getIn(TASK_UID_PATH);
  const taskDependencyUid = taskDependency.get('taskUid');
  const enrollmentId = sequenceEnrollment.get('sequenceEnrollmentId');
  const taskDependencyEnrollmentId = taskDependency.getIn(['taskDependencyMetadata', 'enrollmentId']);
  const resolvedAt = taskDependency.getIn(['taskDependencyMetadata', 'resolvedAt']);
  return taskDependencyUid === taskUid && taskDependencyEnrollmentId === enrollmentId && !!resolvedAt;
}