// The interaction types are duplicated in crm-message-bus for use in defining the message payload without bringing in an external dependency.
// https://git.hubteam.com/HubSpot/crm-activity-creator/blob/cd452bf9405cdcdb1e801ded92ec7dd31029519d/crm-activity-creator-data/static/js/interaction/InteractionTypes.ts
// If any changes are made, make sure both of these locations are in sync.

export const EMAIL = 'email';
export const LOGGED_EMAIL = 'logged-email';
export const NOTE = 'note';
export const CALL = 'call';
export const LOGGED_CALL = 'logged-call';
export const SCHEDULE = 'schedule';
export const LOGGED_MEETING = 'logged-meeting';
export const TASK = 'task';
export const LINKED_IN = 'linked-in';
export const LINKED_IN_MESSAGE = 'linked-in-message';
export const LINKED_IN_CONNECT = 'linked-in-connect';
export const LOGGED_LINKED_IN_MESSAGE = 'logged-linked-in-message';
export const LOGGED_POSTAL_MAIL = 'logged-postal-mail';
export const LOGGED_SMS = 'logged-sms';
export const LOGGED_WHATS_APP = 'logged-whats-app';
export const ENROLLED_IN_SEQUENCE = 'enrolled-in-sequence';
export const WHATS_APP = 'whats-app';