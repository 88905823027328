import createMark from '../../internal/createMark';
import { getCrmLocationInitialCardsReady, getCrmLocationReady } from '../crm-cards/performanceMarks';
export { AppContainerMounted, AppRenderApplicationStart, AppStart, BeginningOfTime, HTMLEnd, HTMLStart, Now } from '../standard/performanceMarks';

/**
 * {
 *  name:  Required. Mark that will be placed as a performance marker
 * }
 */

export const MountProfileContainer = createMark('mark-ProfileContainer');
export const ProfileMounted = createMark('render-profile');
export const RecordRouteMounted = createMark('mark-record-route-mounted');
export const RecordTimelineMounted = createMark('mark-RecordTimeline');
export const RightSidebarLoaded = createMark('mark-RightSidebarSuccess');
export const RouterMark = createMark('mark-router');
export const SetupFetched = createMark('mark_setup_fetched');
export const SetupLoaded = createMark('mark_setup_loaded');
export const I18nLoadStart = createMark('mark-i18n-load-start');
export const I18nLoadEnd = createMark('mark-i18n-load-end');
export const I18nRegisterStart = createMark('mark-i18n-register-start');
export const I18nRegisterEnd = createMark('mark-i18n-register-end');
export const SubjectHightlightReady = createMark('mark-SubjectHighlightDetails-ready');
export const TimelineLoaded = createMark('mark-timeline-rhumb-success');
export const StartJS = createMark('mark-startjs');
export const PortalAndUserAgeLoaded = createMark('mark-portal-user-age-loaded');
export const InitializeCallWidget = createMark('mark-initialize-call-widget');
export const EarlyI18nLoadStart = createMark('mark-i18n-early-load-start');
export const EarlyI18nLoadEnd = createMark('mark-i18n-early-load-end');
export const CrmRecordLeftLocationReady = getCrmLocationReady('CRM_RECORD_LEFT');
export const CrmRecordMiddleLocationReady = getCrmLocationReady('CRM_RECORD_MIDDLE');
export const CrmRecordRightLocationReady = getCrmLocationReady('CRM_RECORD_RIGHT');
export const CrmRecordLeftInitialCardsReady = getCrmLocationInitialCardsReady('CRM_RECORD_LEFT');
export const CrmRecordMiddleInitialCardsReady = getCrmLocationInitialCardsReady('CRM_RECORD_MIDDLE');
export const CrmRecordRightInitialCardsReady = getCrmLocationInitialCardsReady('CRM_RECORD_RIGHT');