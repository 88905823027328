'use es6';

import emptyFunction from 'react-utils/emptyFunction';
import GoVideoPopoverContent from 'draft-plugins/components/GoVideoPopoverContent';
import { RENDERED } from 'draft-plugins/plugins/content/ContentOutputTypes';
import MeetingsLinkPlugin from 'draft-plugins/plugins/meetings/MeetingsLinkPlugin';
import MeetingsLinkProTipPlugin from 'draft-plugins/plugins/meetings/MeetingsLinkProTipPlugin';
import VideoPlugin from 'draft-plugins/plugins/VideoPlugin';
import { createTracker } from 'draft-plugins/tracking/usageTracker';
import hubHttpClient from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import ContentButton from '../../components/ContentButton';
import InsertContentDecorator from 'draft-plugins/plugins/content/InsertContentDecoratorV2';
import KnowledgeArticlesPlugin from '../knowledgeArticles/KnowledgeArticlesPlugin';
import { defaultApiWrapper as KnowledgeArticlesApi } from 'hubspot-prosemirror/prosemirror/commands/knowledgeArticles/draft-content-transplant-ka/KnowledgeArticlesApi';
import { defaultApiWrapper as InvoicesApi } from 'hubspot-prosemirror/prosemirror/commands/invoices/InvoicesApi';
import transformArticlesData from 'hubspot-prosemirror/prosemirror/commands/knowledgeArticles/draft-content-transplant-ka/transformArticlesData';
import transformInvoicesData from 'hubspot-prosemirror/prosemirror/commands/invoices/transformInvoicesData';
import KnowledgePopoverEmptyState from '../knowledgeArticles/popover/KnowledgePopoverEmptyState';
import KnowledgePopoverErrorState from '../knowledgeArticles/popover/KnowledgePopoverErrorState';
import PaymentLinksPlugin from '../paymentLinks/PaymentLinksPlugin';
import InvoicesPlugin from '../invoices/InvoicesPlugin';
import transformPaymentLinksData from 'hubspot-prosemirror/prosemirror/commands/paymentLink/transformPaymentLinksData';
import WrappedPaymentLinkItem from 'hubspot-prosemirror/prosemirror/commands/paymentLink/WrappedPaymentLinkItem';
import { defaultApiWrapper as PaymentLinksApi } from 'hubspot-prosemirror/prosemirror/commands/paymentLink/PaymentLinksApi';
import PaymentLinksEmptyState from '../paymentLinks/popover/PaymentLinksEmptyState';
import PaymentLinksErrorState from '../paymentLinks/popover/PaymentLinksErrorState';
import InvoicesEmptyState from '../invoices/popover/InvoicesEmptyState';
import InvoicesErrorState from '../invoices/popover/InvoicesErrorState';
import { defaultApiWrapper as SnippetsApi } from '../snippets/SnippetsApi';
import SnippetsPlugin from '../snippets/SnippetsPlugin';
import SnippetsPopoverEmptyState from '../snippets/popover/SnippetsPopoverEmptyState';
import SnippetsPopoverErrorState from '../snippets/popover/SnippetsPopoverErrorState';
import transformSnippetsData from '../snippets/transformSnippetsData';
import { userInfoSync } from 'hub-http/userInfo';
let Tracker;
export default ((options = {}) => {
  if (!Tracker) {
    Tracker = createTracker();
  }
  const knowledgeApiFunctions = KnowledgeArticlesApi(hubHttpClient, userInfoSync().gates.includes('KnowledgeBase:V3.1') ? '3' : '2');
  const insertKnowledgeArticleDecoratorConfig = {
    outputType: RENDERED,
    apiFunctions: knowledgeApiFunctions,
    mergeTagOptions: {
      includeCustomTokens: true
    }
  };
  const paymentLinksApiFunctions = PaymentLinksApi(hubHttpClient, options.paymentLinks && options.paymentLinks.referrerParam);
  const insertPaymentLinkDecoratorConfig = {
    outputType: RENDERED,
    apiFunctions: paymentLinksApiFunctions,
    mergeTagOptions: {
      includeCustomTokens: true
    }
  };
  const invoiceApiFunctions = InvoicesApi(hubHttpClient);
  const insertInvoiceDecoratorConfig = {
    outputType: RENDERED,
    apiFunctions: invoiceApiFunctions,
    mergeTagOptions: {
      includeCustomTokens: true
    }
  };
  const snippetsApiWrapper = options.snippets.apiWrapper || SnippetsApi;
  const snippetsApiFunctions = snippetsApiWrapper(hubHttpClient);
  const insertSnippetDecoratorConfig = {
    outputType: RENDERED,
    apiFunctions: snippetsApiFunctions,
    mergeTagOptions: {
      includeCustomTokens: true
    }
  };
  const meetingsOptionsWithDefaults = Object.assign({
    onFetchMeetingsError: emptyFunction,
    onInsertMeetingsLink: emptyFunction
  }, options.meetings);
  const portalId = PortalIdParser.get();
  const config = {
    knowledgeArticles: {
      plugin: KnowledgeArticlesPlugin(options.knowledgeArticles),
      component: InsertContentDecorator(ContentButton(Object.assign({
        dataProp: 'KNOWLEDGE_ARTICLES',
        portalId,
        onInsertContent: emptyFunction,
        PopoverEmptyState: KnowledgePopoverEmptyState,
        PopoverErrorState: KnowledgePopoverErrorState,
        search: knowledgeApiFunctions.searchArticles,
        searchPlaceHolder: 'draftPlugins.knowledgeArticlesPlugin.popover.search.placeholder',
        onlyRenderContent: true,
        transformData: transformArticlesData
      }, options.knowledgeArticles)), insertKnowledgeArticleDecoratorConfig),
      trackClick: options.knowledgeArticles.trackClick
    },
    snippets: {
      plugin: SnippetsPlugin(options.snippets),
      component: InsertContentDecorator(ContentButton(Object.assign({
        portalId,
        onInsertContent: emptyFunction,
        optionsMapping: {
          text: 'name',
          help: 'body',
          value: 'id'
        },
        PopoverEmptyState: SnippetsPopoverEmptyState,
        PopoverErrorState: SnippetsPopoverErrorState,
        search: snippetsApiFunctions.searchSnippets,
        searchPlaceHolder: 'draftPlugins.snippetsPlugin.popover.search.placeholder',
        onlyRenderContent: true,
        transformData: transformSnippetsData
      }, options.snippets)), insertSnippetDecoratorConfig),
      trackClick: options.snippets.trackClick
    },
    meetings: {
      plugin: MeetingsLinkPlugin(meetingsOptionsWithDefaults)
    },
    meetingsProTips: {
      plugin: MeetingsLinkProTipPlugin(options.meetingsProTips)
    }
  };
  if (options.paymentLinks) {
    const CLICK_MANAGE_PAYMENTS = 'click-manage-payments';
    config.paymentLinks = {
      plugin: PaymentLinksPlugin(options.paymentLinks),
      component: InsertContentDecorator(ContentButton(Object.assign({
        dataProp: 'PAYMENT_LINKS',
        portalId,
        onInsertContent: emptyFunction,
        PopoverEmptyState: PaymentLinksEmptyState,
        PopoverErrorState: PaymentLinksErrorState,
        search: paymentLinksApiFunctions.searchLinks,
        searchPlaceHolder: 'draftPlugins.paymentLinksPlugin.popover.search.placeholder',
        onlyRenderContent: true,
        transformData: transformPaymentLinksData,
        CustomItemComponent: WrappedPaymentLinkItem
      }, options.paymentLinks)), insertPaymentLinkDecoratorConfig),
      trackClick: options.paymentLinks.trackClick,
      onClickManageButton: () => options.paymentLinks.tracker && options.paymentLinks.tracker(CLICK_MANAGE_PAYMENTS)
    };
  }
  if (options.invoices) {
    const CLICK_MANAGE_INVOICES = 'click-manage-invoices';
    config.invoices = {
      plugin: InvoicesPlugin(options.invoices),
      component: InsertContentDecorator(ContentButton(Object.assign({
        dataProp: 'INVOICES',
        portalId,
        onInsertContent: emptyFunction,
        PopoverEmptyState: InvoicesEmptyState,
        PopoverErrorState: InvoicesErrorState,
        search: invoiceApiFunctions.searchInvoices,
        searchPlaceHolder: 'draftPlugins.invoicesPlugin.popover.search.placeholder',
        onlyRenderContent: true,
        transformData: transformInvoicesData
      }, options.invoices)), insertInvoiceDecoratorConfig),
      trackClick: options.invoices.trackClick,
      onClickManageButton: () => options.invoices.tracker && options.invoices.tracker(CLICK_MANAGE_INVOICES)
    };
  }
  if (options.video) {
    config.video = {
      plugin: VideoPlugin(options.video),
      component: GoVideoPopoverContent,
      trackClick: options.video.trackClick
    };
  }
  if (options.signature) {
    config.signature = options.signature;
  }
  return config;
});