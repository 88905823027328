import { buildCrmCardRegistry as buildCrmCardRegistryFn } from './buildCrmCardRegistry';
import { CRM_CARDS_GLOBAL_REGISTRY_MAP } from 'crm-cards-global-registry/CrmCardsGlobalRegistry';
/**
 * @deprecated This map will be deleted. Use buildCrmCardRegistry from 'crm-cards/buildCrmCardRegistry' instead.
 */
export const DEFAULT_REGISTRY_MAP = CRM_CARDS_GLOBAL_REGISTRY_MAP;

/**
 * @deprecated Deprecated import path. Prefer using buildCrmCardRegistry from 'crm-cards/buildCrmCardRegistry';
 */
export const buildCrmCardRegistry = buildCrmCardRegistryFn;

/**
 * @deprecated prefer using buildCrmCardRegistry from 'crm-cards/buildCrmCardRegistry';
 */
export const DefaultCrmCardRegistry = buildCrmCardRegistryFn();