'use es6';

import CompaniesStore from 'crm_data/companies/CompaniesStore';
import { AnyCrmObjectTypePropType } from 'customer-data-objects-ui-components/propTypes/CrmObjectTypes';
import ContactsStore from 'crm_data/contacts/ContactsStore';
import DealsStore from 'crm_data/deals/DealsStore';
import * as LoadingStatus from 'crm_data/constants/LoadingStatus';
import { COMPANY, CONTACT, DEAL, VISIT, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import PropTypes from 'prop-types';
import VisitsStore from 'crm_data/prospects/VisitsStore';
import TicketsStore from 'crm_data/tickets/TicketsStore';
import CrmObjectStore from 'crm_data/crmObjects/CrmObjectStore';
import { toCrmObjectKey } from 'customer-data-objects/crmObject/CrmObjectKey';
const storesByType = {
  [COMPANY]: CompaniesStore,
  [CONTACT]: ContactsStore,
  [DEAL]: DealsStore,
  [VISIT]: VisitsStore,
  [TICKET]: TicketsStore
};
const SubjectDependency = {
  propTypes: {
    objectType: AnyCrmObjectTypePropType.isRequired,
    subjectId: PropTypes.string
  },
  stores: [CompaniesStore, ContactsStore, CrmObjectStore, DealsStore, TicketsStore, VisitsStore],
  deref(props) {
    const {
      objectType,
      subjectId
    } = props;
    if (subjectId === null) {
      return LoadingStatus.EMPTY;
    }
    const isHubSpotDefinedObject = [COMPANY, CONTACT, DEAL, VISIT, TICKET].includes(objectType);
    if (!isHubSpotDefinedObject) {
      const key = toCrmObjectKey({
        objectId: subjectId,
        objectTypeId: objectType
      });
      return CrmObjectStore.get(key);
    }
    const store = storesByType[objectType];
    return store && store.get(subjectId);
  }
};
export default SubjectDependency;