'use es6';

import keyMirror from 'react-utils/keyMirror';
/**
 * DEPRECATION NOTICE: This file will be removed in favor of the crm_ui version once the Compact Timeline work
 * is complete. Until then, please make updates to both.
 *
 * Compact Timeline Epic: https://zenhub.hubteam.com/app/workspaces/crmpire-5b2aa8283bb90f6017cdf828/issues/hubspot/crm-issues/4659
 * crm_ui version: https://git.hubteam.com/HubSpot/CRM/blob/master/crm_ui/static/js/timeline/constants/ETypes.js
 *
 * NOTE: Keep events alphabetized
 */
const ETypes = keyMirror({
  dealCreated: null,
  dealstageChange: null,
  eventAnalytics: null,
  eventAutomation: null,
  eventAutomationPlatform: null,
  eventAdInteraction: null,
  eventBiden: null,
  eventCreateSource: null,
  eventCtaClick: null,
  eventDealSplitCreated: null,
  eventDealSplitDeleted: null,
  eventDealSplitUpdated: null,
  eventEmailOptOut: null,
  eventEmailSend: null,
  eventEmailUnbounce: null,
  eventEngagement: null,
  eventFeedbackSubmission: null,
  eventFormSubmission: null,
  eventIntegrations: null,
  eventLifecycleStage: null,
  eventListMembership: null,
  eventMediaPlaySession: null,
  eventMerge: null,
  eventMessages: null,
  eventMessagingOutboundMessageDelivered: null,
  eventMessagingOutboundMessageFailed: null,
  eventMessagingOutboundMessageOpened: null,
  eventMessagingOutboundMessageSent: null,
  eventMonth: null,
  eventPageView: null,
  eventPaymentAchFailed: null,
  eventPaymentAchInitiated: null,
  eventPaymentAchSucceeded: null,
  eventPaymentCardFailed: null,
  eventPaymentCardSucceeded: null,
  eventPaymentRefundFailed: null,
  eventPaymentRefundProcessing: null,
  eventPaymentRefundSucceeded: null,
  eventPaymentSubscriptionCanceled: null,
  eventPaymentSubscriptionCreated: null,
  eventPaymentSubscriptionStatusChanged: null,
  eventPresentation: null,
  eventPrivacyConsent: null,
  eventProductQuote: null,
  eventSalesforceSync: null,
  eventSequence: null,
  eventSidekick: null,
  eventStageChange: null,
  eventSurveyMonkeySurvey: null,
  eventTicket: null,
  eventTicketCreated: null,
  eventTicketStageChange: null,
  eventTwitterMatch: null,
  eventWebinarAttendance: null,
  eventWebinarRegistration: null,
  eventWhatsAppCommunicationSubscriptionStatusChanged: null,
  e_social_interaction_detected: null,
  eventWistia: null
});
export default ETypes;