import memoize from 'react-utils/memoize';
export function _registerTranslation(__key, i18nLoader, lazyLoadedTranslations) {
  return i18nLoader.register(lazyLoadedTranslations);
}

/**
 * This function memoizes the I18n .register function based on cardType. Card's of the same type
 * load the same translation file, and we want to make sure that if two cards of the same type are on the screen,
 * they're using the same promise to determine when it's safe to render translated content.
 * This fixed a bug with Action buttons in the association table card rendering before translations were ready.
 */
export const _memoizedRegisterTranslation = memoize(_registerTranslation);

/**
 * This takes in the retryCount, i18nLoader, and translations, then generates a key for memoization
 * and calls our memo'd register function internally
 *
 * @param dangerouslyDisableMemoization - This is only used for testing in order to disable memoization
 */
export const memoizeRegisterTranslations = ({
  i18nLoader,
  lazyLoadedTranslations,
  dangerouslyDisableMemoization = false
}) => {
  const registerFn = dangerouslyDisableMemoization ? _registerTranslation : _memoizedRegisterTranslation;
  return registerFn(lazyLoadedTranslations.source, i18nLoader, lazyLoadedTranslations);
};