import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
import getSampleContacts from '../constants/getSampleContacts';
import { getSampleTimelineEvents } from '../constants/sampleTimelineEvents';
import { addSampleItems } from '../actions/SampleTimelineActions';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
export function injectSampleContactTimelineEvents(vid, email, ownerId) {
  const sampleTimelineEvents = getSampleTimelineEvents(ownerId);
  const {
    BRIAN,
    COOL_ROBOT,
    MARIA
  } = getSampleContacts();
  const BRIAN_TIMELINE_EVENTS = sampleTimelineEvents.BRIAN;
  const COOL_ROBOT_TIMELINE_EVENTS = sampleTimelineEvents.COOL_ROBOT;
  const MARIA_TIMELINE_EVENTS = sampleTimelineEvents.MARIA;
  const SAMPLE_CONTACT_EVENTS_MAP = {};
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  SAMPLE_CONTACT_EVENTS_MAP[BRIAN.CONTACT_EMAIL] = BRIAN_TIMELINE_EVENTS;
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  SAMPLE_CONTACT_EVENTS_MAP[BRIAN.CONTACT_EMAIL_UID] = BRIAN_TIMELINE_EVENTS;

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  SAMPLE_CONTACT_EVENTS_MAP[COOL_ROBOT.CONTACT_EMAIL] = COOL_ROBOT_TIMELINE_EVENTS;
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  SAMPLE_CONTACT_EVENTS_MAP[COOL_ROBOT.CONTACT_EMAIL_UID] = COOL_ROBOT_TIMELINE_EVENTS;

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  SAMPLE_CONTACT_EVENTS_MAP[MARIA.CONTACT_EMAIL] = MARIA_TIMELINE_EVENTS;
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  SAMPLE_CONTACT_EVENTS_MAP[MARIA.CONTACT_EMAIL_UID] = MARIA_TIMELINE_EVENTS;

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const sampleEvents = SAMPLE_CONTACT_EVENTS_MAP[email];
  if (sampleEvents) {
    addSampleItems(`${vid}`, sampleEvents);
    CrmLogger.logCRMOnboarding(CONTACT, {
      experiment: 'sample contact v3',
      step: 'shown sample timeline items'
    });
  }
}