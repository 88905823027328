import PropTypes from 'prop-types';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { AnyCrmObjectTypePropType } from 'customer-data-objects-ui-components/propTypes/CrmObjectTypes';
import PinnedEngagementDependency from './PinnedEngagementDependency';
import get from 'transmute/get';
import { ENGAGEMENT_FILTER_TYPE_TO_ENGAGEMENT_TYPES } from '../../filters/constants/TimelineFilterEngagementTypes';
import { List } from 'immutable';
import TimelineFavoriteEventsOverrideStore from '../../filters/stores/TimelineFavoriteEventsOverrideStore';
import TimelineFiltersStore from 'crm-events-data/internal/filters/stores/TimelineFiltersStore';
const ShouldDisplayPinnedEngagementDependency = {
  propTypes: {
    subjectId: PropTypes.string.isRequired,
    objectType: AnyCrmObjectTypePropType.isRequired
  },
  stores: [...PinnedEngagementDependency.stores, TimelineFavoriteEventsOverrideStore, TimelineFiltersStore],
  deref({
    objectType,
    subjectId
  }) {
    const pinnedEngagement = PinnedEngagementDependency.deref({
      objectType,
      subjectId
    });
    if (!pinnedEngagement) {
      return false;
    }
    const eventTypeOverride = get('eventTypes', TimelineFavoriteEventsOverrideStore.get(objectType));
    if (eventTypeOverride) {
      const engagementType = pinnedEngagement.getIn(['engagement', 'type']);
      const isFilteringByPinnedEngagementType = eventTypeOverride.some(filterType => {
        const filter = ENGAGEMENT_FILTER_TYPE_TO_ENGAGEMENT_TYPES.get(filterType);
        if (!filter) {
          return false;
        }
        return filter.contains(engagementType);
      });
      if (!isFilteringByPinnedEngagementType) {
        return false;
      }
    }
    const allFilters = TimelineFiltersStore.get(objectType);
    const selectedTeams = get('selectedTeams', allFilters) || List();
    const teamId = pinnedEngagement.getIn(['engagement', 'teamId'], '');
    if (selectedTeams.size && !selectedTeams.includes(`${teamId}`)) {
      return false;
    }
    const selectedOwners = get('selectedOwners', allFilters) || List();
    const ownerId = pinnedEngagement.getIn(['engagement', 'ownerId'], '');
    if (selectedOwners.size && !selectedOwners.includes(`${ownerId}`)) {
      return false;
    }
    const searchText = get('searchText', allFilters);
    const body = pinnedEngagement.getIn(['metadata', 'body'], '');
    const subject = pinnedEngagement.getIn(['metadata', 'subject'], '');
    if (searchText) {
      const searchTextLowercase = searchText.toLowerCase();
      if (!body.toLowerCase().includes(searchTextLowercase) && !subject.toLowerCase().includes(searchTextLowercase)) {
        return false;
      }
    }
    return true;
  }
};
export default ShouldDisplayPinnedEngagementDependency;