'use es6';

import { List, Seq } from 'immutable';
import compose from 'transmute/compose';
import { MEETING, CALL, NOTE, TASK, CONVERSATION_SESSION } from 'customer-data-objects/engagement/EngagementTypes';
import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
import { ENGAGEMENTS } from 'crm_data/actions/ActionNamespaces';
import { ADD_SAMPLE_TIMELINE_ITEMS, DELETE_RECORDING, ENGAGEMENTS_ATTACHMENTS_UPDATED, FETCH_TIMELINE_ITEMS_FOR_VFP_SUCCESS, FETCH_TIMELINE_ITEMS_SUCCESS, POLLING_FETCH_TIMELINE_ITEMS_SUCCESS, TASKS_AND_ENGAGEMENTS_UPDATED } from 'crm_data/actions/ActionTypes';
import { ASSOCIATE_CONTACT_AND_ENGAGEMENT, ASSOCIATE_COMPANY_AND_ENGAGEMENT, ASSOCIATE_DEAL_AND_ENGAGEMENT, DISASSOCIATE_CONTACT_AND_ENGAGEMENT, DISASSOCIATE_COMPANY_AND_ENGAGEMENT, DISASSOCIATE_DEAL_AND_ENGAGEMENT, ASSOCIATE_TICKET_AND_ENGAGEMENT, DISASSOCIATE_TICKET_AND_ENGAGEMENT, ASSOCIATE_UNIVERSAL_ASSOCIATION_AND_ENGAGEMENT, ASSOCIATE_ALL_UNIVERSAL_ASSOCIATIONS_AND_ENGAGEMENT, DISASSOCIATE_UNIVERSAL_ASSOCIATION_AND_ENGAGEMENT } from 'crm_schema/association/AssociationActionTypes';
import { definePooledObjectStore } from 'crm_data/flux/definePooledObjectStore';
import registerPooledObjectService from 'crm_data/flux/registerPooledObjectService';
import { getAssociationPath } from 'customer-data-objects/model/ImmutableEngagementModel';
import { fetch } from 'crm_data/engagements/api/EngagementsAPI';
import { byIds } from 'crm_data/engagements/api/EngagementsAPIQuery';
import dispatcher from 'dispatcher/dispatcher';
import { isEngagementEvent } from './isEngagementEvent';

// Do not put in email events from the timeline. We need to fetch emails from the
// engagement service to get the email body.
const TIMELINE_EVENT_TYPES = Seq([MEETING, CALL, NOTE, TASK, CONVERSATION_SESSION]);
const isSampleEventType = event => !!event.getIn(['eventData', 'engagement', 'isSample']);
const isTimelineEventType = event => TIMELINE_EVENT_TYPES.contains(event.getIn(['eventData', 'engagement', 'type']));
const isTimelineEvent = event => isEngagementEvent(event) && (isTimelineEventType(event) || isSampleEventType(event));
const _removeFromList = (list, toRemove) => {
  if (!list) {
    return List();
  }
  return list.filter(id => !toRemove.includes(id));
};
const disassociateFromState = (state, subjectId, objectIds, associationPath) => {
  const engagementId = `${subjectId}`;
  const engagement = state.get(engagementId);
  if (!engagement) {
    return state;
  }
  const ids = engagement.getIn(associationPath);
  const updatedList = _removeFromList(ids, objectIds);
  return state.setIn([engagementId, ...associationPath], updatedList);
};
const associateInState = (state, subjectId, objectIds, associationPath) => {
  const engagementId = `${subjectId}`;
  const ids = state.getIn([engagementId, ...associationPath]) || List();
  return state.setIn([engagementId, ...associationPath], ids.concat(objectIds).toSet().toList());
};
const updateEngagementsFromTimelineEvents = (state, data) => {
  const {
    sampleEvents
  } = data;
  const events = sampleEvents && sampleEvents.size ? sampleEvents : data.get('events');
  if (!events) {
    return state;
  }
  return events.reduce((acc, event) => {
    if (!isTimelineEvent(event)) {
      return acc;
    }
    const id = event.getIn(['eventData', 'engagement', 'id']);
    const record = EngagementRecord(event.get('eventData'));
    return acc.set(`${id}`, record);
  }, state);
};
registerPooledObjectService({
  actionTypePrefix: ENGAGEMENTS,
  fetcher: compose(fetch, byIds)
});
export default definePooledObjectStore({
  actionTypePrefix: ENGAGEMENTS
}).defineName('EngagementsStore').defineResponseTo([DISASSOCIATE_CONTACT_AND_ENGAGEMENT, DISASSOCIATE_COMPANY_AND_ENGAGEMENT, DISASSOCIATE_DEAL_AND_ENGAGEMENT, DISASSOCIATE_TICKET_AND_ENGAGEMENT], (state, {
  subjectId,
  objectIds,
  objectType
}) => {
  const associationPath = getAssociationPath(objectType);
  return disassociateFromState(state, subjectId, objectIds, associationPath);
}).defineResponseTo([ASSOCIATE_CONTACT_AND_ENGAGEMENT, ASSOCIATE_COMPANY_AND_ENGAGEMENT, ASSOCIATE_DEAL_AND_ENGAGEMENT, ASSOCIATE_TICKET_AND_ENGAGEMENT], (state, {
  subjectId,
  objectIds,
  objectType
}) => {
  const associationPath = getAssociationPath(objectType);
  return associateInState(state, subjectId, objectIds, associationPath);
}).defineResponseTo([FETCH_TIMELINE_ITEMS_SUCCESS, POLLING_FETCH_TIMELINE_ITEMS_SUCCESS, ADD_SAMPLE_TIMELINE_ITEMS, FETCH_TIMELINE_ITEMS_FOR_VFP_SUCCESS], updateEngagementsFromTimelineEvents).defineResponseTo(DELETE_RECORDING, (state, {
  engagementId
}) => state.setIn([`${engagementId}`, 'metadata', 'recordingUrl'], null)).defineResponseTo(ENGAGEMENTS_ATTACHMENTS_UPDATED, (state, engagements) => {
  const engagement = engagements.first();
  const engagementId = engagement.getIn(['engagement', 'id']);
  return state.setIn([`${engagementId}`, 'attachments'], engagement.get('attachments'));
}).defineResponseTo([ASSOCIATE_ALL_UNIVERSAL_ASSOCIATIONS_AND_ENGAGEMENT], (state, {
  engagementId,
  universalAssociationMap
}) => {
  return state.setIn([`${engagementId}`, 'associations', 'universalAssociations'], universalAssociationMap);
}).defineResponseTo([ASSOCIATE_UNIVERSAL_ASSOCIATION_AND_ENGAGEMENT, DISASSOCIATE_UNIVERSAL_ASSOCIATION_AND_ENGAGEMENT], (state, {
  engagementId,
  updatedAssociationRecord
}) => {
  const toObjectTypeId = updatedAssociationRecord.get('toObjectTypeId');
  return state.setIn([`${engagementId}`, 'associations', 'universalAssociations', toObjectTypeId], updatedAssociationRecord);
}).defineResponseTo(TASKS_AND_ENGAGEMENTS_UPDATED, (state, {
  engagementsMap
}) => state.mergeDeep(engagementsMap)).register(dispatcher);