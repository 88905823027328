'use es6';

import dispatcher from 'dispatcher/dispatcher';
import { QUOTES } from 'crm_data/actions/ActionNamespaces';
import * as ActionTypes from 'crm_data/actions/ActionTypes';
import { batchFetch } from 'crm_data/quotes/QuotesAPI';
import { definePooledObjectStore } from 'crm_data/flux/definePooledObjectStore';
import registerPooledObjectService from 'crm_data/flux/registerPooledObjectService';
import { getQuoteId } from 'customer-data-objects/quote/model/QuoteModel';
registerPooledObjectService({
  actionTypePrefix: QUOTES,
  fetcher: batchFetch
});
const QuotesStore = definePooledObjectStore({
  actionTypePrefix: QUOTES
}).defineName('QuotesStore').defineResponseTo(ActionTypes.QUOTES_CREATE_SUCCEEDED, (state, newQuote) => {
  const newQuoteIdKey = String(getQuoteId(newQuote));
  return state.set(newQuoteIdKey, newQuote);
}).defineResponseTo([ActionTypes.QUOTE_BY_ID_FETCH_SUCCEEDED, ActionTypes.QUOTES_UPDATE_SUCCEEDED, ActionTypes.QUOTES_RECALL_SUCCEEDED, ActionTypes.QUOTES_APPROVE_SUCCEEDED, ActionTypes.QUOTES_REQUEST_CHANGES_SUCCEEDED], (state, updatedQuote) => {
  const updatedQuoteIdKey = String(getQuoteId(updatedQuote));
  return state.set(updatedQuoteIdKey, updatedQuote);
}).register(dispatcher);
export default QuotesStore;