import { ETypes, getEngagementOrInteractionType, eventEngagement } from 'crm-events-data';
import { CompactEventConfig } from '../components/config/CompactEventConfig';
import { DEAL, TICKET } from 'customer-data-objects/constants/ObjectTypes';

// basic timelineItem type that just guarantees etype is an EType

/**
 * To access the 'component' property
 */
export const getCompactEventComponent = timelineItem => {
  const etype = timelineItem.get('etype');
  const isEngagement = etype === ETypes.eventEngagement;
  if (isEngagement) {
    const engagementType = getEngagementOrInteractionType({
      timelineItem
    });
    if (engagementType) {
      const engagementConfig = CompactEventConfig[eventEngagement][engagementType];
      if (engagementConfig) {
        return engagementConfig.component;
      }
    }
  }
  const eventConfig = CompactEventConfig[etype];
  if (eventConfig && 'component' in eventConfig) {
    return eventConfig.component;
  }
  return null;
};

/**
 * To access the 'minkowskiEventNameForFiltering' property
 */
export const getCompactEventMinkowskiEventNameForFiltering = (etype, objectType) => {
  const shouldUseStagesFilter = etype === ETypes.eventTicketStageChange && objectType === TICKET || etype === ETypes.dealstageChange && objectType === DEAL;
  if (shouldUseStagesFilter) {
    return 'STAGES';
  }
  const eventConfig = CompactEventConfig[etype];
  if (eventConfig && 'component' in eventConfig && 'minkowskiEventNameForFiltering' in eventConfig) {
    return eventConfig.minkowskiEventNameForFiltering;
  }
  return null;
};

/**
 * To access the 'timelineCardError' property
 */
export const getCompactEventErrorMessage = timelineItem => {
  const etype = timelineItem.get('etype');
  const isEngagement = etype === ETypes.eventEngagement;
  if (isEngagement) {
    const engagementType = getEngagementOrInteractionType({
      timelineItem
    });
    if (engagementType) {
      const engagementConfig = CompactEventConfig[eventEngagement][engagementType];
      if (engagementConfig) {
        return engagementConfig.getTimelineCardErrorMsg;
      }
    }
  }
  const eventConfig = CompactEventConfig[etype];
  if (eventConfig && 'getTimelineCardErrorMsg' in eventConfig) {
    return eventConfig.getTimelineCardErrorMsg;
  }
  return null;
};