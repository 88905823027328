import { useCallback } from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import addMutationResultAlert from 'customer-data-sidebar/universal/alertHelpers/addMutationResultAlert';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { put as sharedAPIPut } from 'crm_data/inboundDB/sharedAPI';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
export const BASE_URI_CREATE = 'associations-writes/v1/associations';
export const BASE_URI_DELETE = 'associations-writes/v1/associations/delete';

/**
 * Create callback to bulk create associations between two objects
 *
 * @example
 *
 * function CrmObjectActionsDropdown({ objectId, objectTypeId }) {
 *
 *   ...
 *
 *   const handleCreateCrmObjectAssociationBulk = useCreateCrmObjectAssociationBulk({
 *    fromObjectTypeId,
 *    fromObjectId,
 *    toObjectTypeId: objectTypeId,
 *    toObjectId: objectId,
 *    onSuccess: handleCreateSuccess,
 *    onError: handleCreateError,
 *  });
 *
 *   ...
 *
 *   return (
 *     ...
 *      <UIButton onClick={handleCreateCrmObjectAssociationBulk}>
 *         Create
 *      </UIButton>
 *     ...
 *   )
 * }
 *
 * @param {Number}                                        options.fromObjectId        the ID of the object to disassociate from, usually the subject in a record page
 * @param {Number}                                        options.toObjectId          the ID of the object to be disassociated from the "fromObject", i.e. the ID of the object in a sidebar chicklet
 * @param {Function}                                      options.onSuccess           callback for when the association request is queued on the server
 * @param {Function}                                      options.onError             callback for when the association request fails
 *
 * @return {Function} useUpdateCrmObjectAssociationsBulk
 */
export const useUpdateCrmObjectAssociationsBulk = ({
  fromObjectId,
  fromObjectTypeId,
  toObjectId,
  toObjectTypeId,
  onSuccess,
  onError,
  onAssociationUpdate
}) => {
  const sendUpdateAssociationsMessage = useSendCrmMessageTopic(TOPIC_NAMES.UPDATE_ASSOCIATIONS);
  const handleUpdateCrmObjectAssociationsBulk = useCallback(({
    createAssociations,
    deleteAssociations,
    onSuccessDispatch = () => {},
    onFailureDispatch = () => {}
  }) => {
    Promise.all([createAssociations.length > 0 && sharedAPIPut(`${BASE_URI_CREATE}`, createAssociations.map(({
      associationCategory,
      associationTypeId
    }) => {
      return {
        fromObjectId,
        toObjectId,
        associationCategory,
        associationTypeId
      };
    })), deleteAssociations.length > 0 && sharedAPIPut(`${BASE_URI_DELETE}`, deleteAssociations.map(({
      associationCategory,
      associationTypeId
    }) => {
      return {
        fromObjectId,
        toObjectId,
        associationCategory,
        associationTypeId
      };
    }))]).then(() => {
      if (typeof onSuccess === 'function') onSuccess();
      onSuccessDispatch();
      if (typeof onAssociationUpdate === 'function') {
        onAssociationUpdate({
          objectId: +fromObjectId,
          objectType: fromObjectTypeId,
          associationObjectType: toObjectTypeId
        });
      }
      sendUpdateAssociationsMessage({
        objectId: `${fromObjectId}`,
        objectTypeId: toObjectTypeId,
        actionType: 'UPDATE_LABELS'
      });
      addMutationResultAlert({
        titleKey: 'cardAssociatedObjects.chicklet.ChickletHoverEditAssociationModal.successfullyUpdated'
      });
    }).catch(error => {
      if (typeof onError === 'function') onError();
      onFailureDispatch();
      throw error;
    });
  }, [fromObjectId, toObjectId, onSuccess, onAssociationUpdate, sendUpdateAssociationsMessage, toObjectTypeId, fromObjectTypeId, onError]);
  return handleUpdateCrmObjectAssociationsBulk;
};