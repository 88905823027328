import { LEGACY_SIDEBAR_CARD_TYPES } from '../../constants/LegacySidebarCardType';

// Legacy card should not have config type or query fragments

// Extra props on legacy card

// Instead of adding a dependency on customer-data-properties for 1 type, I have copied the following code:
// import { PropertiesFormValues } from 'customer-data-properties/form/types';
// Legacy Sidebar Card Metadata
export const PROPERTIES_METADATA = {
  cardType: 'PROPERTIES',
  legacySidebarCardType: LEGACY_SIDEBAR_CARD_TYPES.PropertiesCard,
  hasLegacyExtraProps: true,
  renderAsLegacyCardInCrmLocationResolver: false
};