import { useCallback } from 'react';
import { useHubSpotAppLinkHandler } from 'crm-links/common/context/HubSpotAppLinkHandlerContext';
const APP_LINKS = {
  ENGAGEMENT: 'ENGAGEMENT',
  CRM_OBJECT_RECORD: 'CRM_OBJECT_RECORD',
  PAGE: 'PAGE'
};
export function useBuildAppLinkActionHook(linkType, linkArgs) {
  const {
    external
  } = linkArgs;
  const linkHandler = useHubSpotAppLinkHandler(linkType);
  const href = linkHandler.getHref(linkArgs);
  const externalCallback = useCallback(() => {
    window.open(href, '_blank');
  }, [href]);
  const callback = useCallback(() => {
    window.location.href = href;
  }, [href]);
  return {
    callback: external ? externalCallback : callback
  };
}