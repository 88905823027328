import { CALL, CONVERSATION_SESSION, EMAIL, FORWARDED_EMAIL, INCOMING_EMAIL, MEETING, NOTE, POSTAL_MAIL, TASK, WHATS_APP, SMS, LINKEDIN_MESSAGE } from 'customer-data-objects/engagement/EngagementTypes';

// Values taken from https://git.hubteam.com/HubSpot/IdentityBase/blob/master/src/main/protobuf/contacts.proto
export const EngagementTypeToObjectTypeId = {
  [CALL]: 48,
  [CONVERSATION_SESSION]: 51,
  [EMAIL]: 49,
  [FORWARDED_EMAIL]: 49,
  [INCOMING_EMAIL]: 49,
  [LINKEDIN_MESSAGE]: 18,
  [MEETING]: 47,
  [NOTE]: 46,
  [POSTAL_MAIL]: 116,
  [SMS]: 18,
  [TASK]: 27,
  [WHATS_APP]: 18
};