'use es6';

import { CONTACTS_FETCH_SUCCEEDED, CONTACT_EMAIL_REMOVED, CONTACTS_EMAIL_STORE_FETCH_SUCCEEDED } from 'crm_data/actions/ActionTypes';
import { fetchByEmail } from 'crm_data/contacts/ContactsActions';
import { defineFactory } from 'general-store';
import dispatcher from 'dispatcher/dispatcher';
import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { getProperty } from 'customer-data-objects/model/ImmutableModel';
let fetched = ImmutableSet();
const contactsReducer = (map, contact) => {
  if (!contact) {
    return map;
  }
  const primaryEmail = getProperty(contact, 'email');
  map = map.set(primaryEmail ? primaryEmail.toLowerCase() : primaryEmail, contact);
  const additionalEmailsString = getProperty(contact, 'hs_additional_emails');
  const additionalEmails = additionalEmailsString && additionalEmailsString.split(';');
  if (additionalEmails) {
    additionalEmails.forEach(email => {
      map = map.set(email ? email.toLowerCase() : email, contact);
    });
  }
  return map;
};
export default defineFactory().defineName('ContactsEmailStore').defineGetInitialState(() => ImmutableMap()).defineGet((state, emailToFetch = '') => {
  const email = emailToFetch.toLowerCase();
  if (!state.has(email) && !fetched.has(email)) {
    fetched = fetched.add(email);
    fetchByEmail(email).catch(error => {
      if ((error && error.status) !== 404) {
        throw error;
      }
    });
  }
  return state.get(email);
}).defineResponseTo(CONTACTS_FETCH_SUCCEEDED, (state, contacts) => contacts.reduce(contactsReducer, state)).defineResponseTo(CONTACTS_EMAIL_STORE_FETCH_SUCCEEDED, (state, {
  contacts,
  fetchedEmail
}) => {
  if (contacts.isEmpty()) {
    return state.set(fetchedEmail, null);
  }
  return contacts.reduce(contactsReducer, state);
}).defineResponseTo(CONTACT_EMAIL_REMOVED, (state, {
  email
}) => state.remove(email ? email.toLowerCase() : email)).register(dispatcher);