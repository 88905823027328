// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
import PortalIdParser from 'PortalIdParser';
import { fromJS } from 'immutable';
function makeURI(objectType, subjectId) {
  return `/customer-success-crm-integrations/v1/associations/portals/${objectType}/${subjectId}?hub-id=${PortalIdParser.get()}`;
}
export function fetch(objectType, subjectId) {
  return ImmutableAPI.get(makeURI(objectType, subjectId), {}, response => {
    return fromJS(response).map(association => {
      return fromJS({
        objectId: association.get('portalId'),
        label: association.get('label')
      });
    });
  });
}