'use es6';

import { Map as ImmutableMap, List, fromJS } from 'immutable';
import http from 'hub-http/clients/apiClient';
const baseUrl = 'snippets/v1/render';
export const defaultApiWrapper = apiClient => ({
  fetchRenderedContent({
    id,
    contactEmail,
    contactVid,
    objectType,
    subjectId
  }) {
    const supplementalObject = subjectId && subjectId !== 'null' && subjectId !== 'undefined' ? {
      supplementalObjectType: objectType,
      supplementalObjectId: subjectId
    } : {};
    return apiClient.get(baseUrl, {
      query: Object.assign({
        id,
        toEmail: contactEmail,
        vid: contactVid
      }, supplementalObject)
    });
  },
  fetchUnrenderedContent({
    id
  }) {
    return apiClient.get(`snippets/v1/snippets/${id}`);
  },
  searchSnippets({
    count,
    offset,
    query
  }) {
    return apiClient.post('salescontentsearch/v2/search', {
      data: {
        contentTypesToHydrate: ['SNIPPET'],
        filters: [{
          field: 'content_type',
          values: ['SNIPPET']
        }],
        limit: count,
        offset,
        query
      }
    }).then(response => fromJS(response));
  }
});
export const fetchAllSnippets = () => http.get('snippets/v1/snippets').then(response => ImmutableMap({
  hasMore: false,
  offset: 0,
  count: response.length,
  total: response.length,
  results: List(response).map(snippet => fromJS(snippet))
}));
export const searchSnippets = ({
  count,
  offset,
  query
}) => http.post('salescontentsearch/v2/search', {
  data: {
    contentTypesToHydrate: ['SNIPPET'],
    filters: [{
      field: 'content_type',
      values: ['SNIPPET']
    }],
    limit: count,
    offset,
    query
  }
}).then(response => fromJS(response));