import { NOTE, EMAIL, CALL, TASK, MEETING } from 'customer-data-objects/engagement/EngagementTypes';
const ENGAGEMENT_TYPE_DISABLED_KEYS = {
  [NOTE]: 'note',
  [EMAIL]: 'email',
  [CALL]: 'call',
  [TASK]: 'task',
  [MEETING]: 'meeting'
};
const getDisabledEngagementTranslationKey = engagementType => {
  const disabledKey = engagementType ? ENGAGEMENT_TYPE_DISABLED_KEYS[engagementType] : null;
  if (!disabledKey) return '';
  return `components.generic.disabledEngagement.${disabledKey}`;
};
export default getDisabledEngagementTranslationKey;