import { Map as ImmutableMap, Record, Seq } from 'immutable';
const PassthruRtePluginsRecord = Record({
  type: null,
  plugins: Seq(),
  pluginComposers: Seq(),
  pluginGroupConfig: ImmutableMap()
}, 'PassthruRtePlugins');
export default class PassthruRtePlugins extends PassthruRtePluginsRecord {
  getRteConfigType(interactionType) {
    return this.type || interactionType;
  }
  concatPlugins(plugins) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Iterable<unknown, unknown>' is n... Remove this comment to see the full error message
    return this.set('plugins', this.plugins.concat(plugins));
  }
  concatPluginComposers(pluginComposers) {
    return this.set('pluginComposers',
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Iterable<unknown, unknown>' is n... Remove this comment to see the full error message
    this.pluginComposers.concat(pluginComposers));
  }
  extendPluginGroupConfig(additionalConfig) {
    return this.set('pluginGroupConfig', this.pluginGroupConfig.merge(additionalConfig));
  }
}