const ACTIVITY_CREATOR_ACTION_TYPES = {
  ADD_NOTE: 'ADD_NOTE',
  ADD_TASK: 'ADD_TASK',
  SEND_EMAIL: 'SEND_EMAIL',
  SCHEDULE_MEETING: 'SCHEDULE_MEETING'
};
const OBJECT_BUILDER_FORM_TYPES = {
  OPEN_RECORD_CREATOR_FORM: 'OPEN_RECORD_CREATOR_FORM',
  OPEN_RECORD_ASSOCIATION_FORM: 'OPEN_RECORD_ASSOCIATION_FORM'
};
const APP_LINK_ACTION_TYPES = {
  ENGAGEMENT_APP_LINK: 'ENGAGEMENT_APP_LINK',
  RECORD_APP_LINK: 'RECORD_APP_LINK',
  PAGE_APP_LINK: 'PAGE_APP_LINK'
};
const ACTION_TYPE_NAMES = Object.assign({
  PREVIEW_OBJECT: 'PREVIEW_OBJECT',
  EXTERNAL_URL: 'EXTERNAL_URL',
  OPEN_WORKFLOW_ENROLLMENT_MODAL: 'OPEN_WORKFLOW_ENROLLMENT_MODAL'
}, ACTIVITY_CREATOR_ACTION_TYPES, OBJECT_BUILDER_FORM_TYPES, APP_LINK_ACTION_TYPES);
export const ACTION_TYPES = Object.keys(ACTION_TYPE_NAMES);