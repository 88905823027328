'use es6';

import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
import { TASK } from 'customer-data-objects/engagement/EngagementTypes';
import { getProperty } from 'customer-data-objects/model/ImmutableModel';
import { CREATED_BY, DUE_DATE, ENGAGEMENT_SOURCE, ENGAGEMENT_SOURCE_ID, ENGAGEMENT_TYPE, LAST_MODIFIED_DATE, MODIFIED_BY, NOTES, OWNER_ID, PRIORITY, QUEUE_IDS, REMINDERS, SEQUENCE_STEP_ENROLLMENT_ID, STATUS, TITLE, TYPE, UNIQUE_ID } from 'customer-data-objects/task/TaskPropertyNames';
import { List, Map as ImmutableMap } from 'immutable';
import pick from 'transmute/pick';

// Defines how to get values for all fields used in crm_ui/timeline/components/events/task/TimelineTaskConstants
// Excludes sendDefaultReminder (deprecated https://git.hubteam.com/HubSpot/sales-efficiency/issues/2118)
const propertyNameToEngagementRecordKey = ImmutableMap({
  [CREATED_BY]: 'createdBy',
  [DUE_DATE]: 'timestamp',
  [ENGAGEMENT_SOURCE]: 'source',
  [ENGAGEMENT_SOURCE_ID]: 'sourceId',
  [ENGAGEMENT_TYPE]: 'type',
  [LAST_MODIFIED_DATE]: 'lastUpdated',
  [MODIFIED_BY]: 'modifiedBy',
  [NOTES]: 'body',
  [OWNER_ID]: 'ownerId',
  [PRIORITY]: 'priority',
  [REMINDERS]: 'reminders',
  [SEQUENCE_STEP_ENROLLMENT_ID]: 'sequenceStepEnrollmentId',
  [STATUS]: 'status',
  [TITLE]: 'subject',
  [TYPE]: 'taskType',
  [UNIQUE_ID]: 'uid'
});
const engagementKeys = ['createdBy', 'lastUpdated', 'modifiedBy', 'ownerId', 'source', 'sourceId', 'timestamp', 'type', 'uid'];
const metadataKeys = ['body', 'priority', 'reminders', 'sequenceStepEnrollmentId', 'status', 'subject', 'taskType'];
export default (task => {
  const transformedProperties = task.properties.filter((__property, propName) => propertyNameToEngagementRecordKey.has(propName)).map((property, propName) => {
    const engagementKey = propertyNameToEngagementRecordKey.get(propName);
    const rawEngagementValue = property.get('value');
    const engagementValue = engagementKey === 'timestamp' ? parseInt(rawEngagementValue, 10) : rawEngagementValue;
    return [engagementKey, engagementValue];
  }).toList().reduce((resultMap, propData) => {
    const [key, value] = propData;
    return resultMap.set(key, value);
  }, ImmutableMap());
  const queue = getProperty(task, QUEUE_IDS);
  const reminderValue = getProperty(task, REMINDERS);
  return EngagementRecord({
    associations: task.associations,
    engagement: pick(engagementKeys, transformedProperties).set('id', task.get('objectId')).set('queueMembershipIds', queue ? List([queue]) : null)
    // ensure 'type' is set in case task is missing hs_engagement_type property
    .set('type', TASK),
    metadata: pick(metadataKeys, transformedProperties).set('reminders', reminderValue ? List([reminderValue]) : null)
  });
});