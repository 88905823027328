import { useCallback, useContext } from 'react';
import { CopilotChannelContext } from '../channel/CopilotChannelContext';
export const useSetCopilotAppContext = () => {
  const {
    channel
  } = useContext(CopilotChannelContext);
  return useCallback(appContext => {
    if (channel && typeof channel.getVersion === 'function' && channel.getVersion() >= 1) {
      channel.setState(({
        appContext: prevAppContext
      }) => {
        return {
          appContext: Object.assign({}, prevAppContext, appContext)
        };
      });
    }
  }, [channel]);
};