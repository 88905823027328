import get from 'growth-payments-core/object/get';
import NetworkBinding from 'growth-payments-core/binding/networkBinding';
import { createNetworkHook } from 'growth-payments-core/binding/useNetworkBinding';
import { fetchUserInfo } from '../api/auth';
export const authBinding = new NetworkBinding(fetchUserInfo);
export const getAuth = authBinding.get;
export const useAuth = createNetworkHook(authBinding);
export const useUser = () => {
  const [userInfo] = useAuth();
  return get(userInfo, 'user');
};
export const getUser = () => {
  return get(getAuth(), 'user');
};