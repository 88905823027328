import { getShouldSeeVidyardShepherd } from './VidyardActions';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_data/actions/ActionNamespa... Remove this comment to see the full error message
import { VIDYARD } from 'crm_data/actions/ActionNamespaces';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_data/store/LazyValueStore'... Remove this comment to see the full error message
import { defineLazyValueStore } from 'crm_data/store/LazyValueStore';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'dispatcher/dispatcher'. Did yo... Remove this comment to see the full error message
import dispatcher from 'dispatcher/dispatcher';
const VidyardStore = defineLazyValueStore({
  namespace: VIDYARD,
  fetch: getShouldSeeVidyardShepherd
}).defineName('VidyardStore').register(dispatcher);
export default VidyardStore;