'use es6';

import { Record } from 'immutable';
const DealStage = Record({
  active: true,
  closedWon: false,
  displayOrder: -1,
  label: '',
  probability: 0,
  stageId: undefined
}, 'DealStage');
DealStage.fromJS = json => {
  if (!json || typeof json !== 'object') {
    return json;
  }
  return DealStage(json);
};
export default DealStage;