// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import registerService from 'crm_data/flux/registerService';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { dispatch } from 'crm_data/flux/dispatch';
import { fetchTimeline, fetchTimelineDefaults, fetchInstalledHubSpotOwnedIntegrations, fetchIntegrationEvents, fetchTimelineByUsersAndTeams } from '../apis/TimelineAPI';
import { Map as ImmutableMap, List } from 'immutable';
import { FETCH_TIMELINE_ITEMS_SUCCESS, FETCHING_INITIAL_TIMELINE_EVENTS_STARTED, FETCH_NEXT_TIMELINE_EVENTS_STARTED, CLEAR_ALL_FILTERS, CHANGE_FILTER, FETCH_TIMELINE_ITEMS_ERROR, POLLING_FETCH_TIMELINE_ITEMS_SUCCESS, POLLING_FETCH_TIMELINE_ITEMS_STARTED, POLLING_FETCH_TIMELINE_ITEMS_ERROR, FETCH_TIMELINE_ITEMS_FOR_VFP_STARTED, FETCH_TIMELINE_ITEMS_FOR_VFP_SUCCESS, SET_FAVORITE_EVENT_OVERRIDE, TIMELINE_CHANGE_USER_AND_TEAM_FILTER, TIMELINE_QUICK_FETCH_STORE_SUCCESS
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
import Raven from 'raven-js';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import EngagementsSearchAPIQuery from 'crm_data/engagements/api/EngagementsSearchAPIQuery';
import identity from 'transmute/identity';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import EngagementsStore from 'crm_data/engagements/EngagementsStore';
import { fillExtraEventOptions } from '../../filters/helpers/fillExtraEventOptions';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { dispatchQueue } from 'crm_data/dispatch/Dispatch';
import devLogger from 'react-utils/devLogger';
import { EMAIL, TASK, MEETING, CALL, NOTE, INCOMING_EMAIL, FORWARDED_EMAIL, CONVERSATION_SESSION, WHATS_APP, POSTAL_MAIL, SMS, LINKEDIN_MESSAGE } from 'customer-data-objects/engagement/EngagementTypes';
import TimelineItemsFailedError from '../helpers/TimelineItemsFailedError';
import emptyFunction from 'react-utils/emptyFunction';
import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
import { COMPANY, CONTACT, DEAL, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import I18n from 'I18n';
const engagementFilterToESFilter = ImmutableMap({
  ENGAGEMENTS_CALLS: CALL,
  ENGAGEMENTS_EMAILS_TRIMMED: EMAIL,
  ENGAGEMENTS_MEETINGS: MEETING,
  ENGAGEMENTS_NOTES: NOTE,
  ENGAGEMENTS_TASKS: TASK,
  ENGAGEMENTS_CONVERSATION_SESSIONS: CONVERSATION_SESSION,
  ENGAGEMENTS_WHATS_APP: WHATS_APP,
  ENGAGEMENTS_LINKEDIN_MESSAGE: LINKEDIN_MESSAGE,
  ENGAGEMENTS_SMS: SMS,
  ENGAGEMENTS_POSTAL_MAIL: POSTAL_MAIL
});
export function getTrackingObjectTypeFromTimelineFetch(objectType) {
  if ([COMPANY, CONTACT, DEAL, TICKET].includes(objectType)) {
    return objectType;
  }
  return 'CRM_OBJECT';
}
function hoistEngagements(data) {
  const engagementIds = data.get('events').filter(event => event.hasIn(['eventData', 'engagement', 'id'])).map(event => event.getIn(['eventData', 'engagement', 'id']));
  EngagementsStore.get(engagementIds);
}
function shouldUseElasticSearch(requestPayload) {
  return requestPayload.get('selectedOwners') && requestPayload.get('selectedOwners').size || requestPayload.get('selectedTeams') && requestPayload.get('selectedTeams').size || requestPayload.get('searchText') && requestPayload.get('searchText').length;
}
function logCountToNewRelic(failureType, items) {
  if (window.newrelic && Math.random() < 0.25) {
    window.newrelic.addPageAction(`timeline${failureType}`, {
      failedItemCount: items.size
    });
  }
}
function fetchWithErrorHandling(requestPayload, successCallback = emptyFunction, errorCallback = emptyFunction, {
  timesToRetry = 3,
  useDefaults = false,
  isFirstFetch = false
} = {}) {
  const objectType = getTrackingObjectTypeFromTimelineFetch(requestPayload.get('objectType'));
  const shouldUseObjectEndpoint = true;
  let promises = [useDefaults ? fetchTimelineDefaults(requestPayload, shouldUseObjectEndpoint) : fetchTimeline(requestPayload, shouldUseObjectEndpoint)];

  // TODO COBJECT TIMELINE: fetch integration events
  // https://git.hubteam.com/HubSpot/CRM-Issues/issues/5967
  if (isFirstFetch && shouldUseObjectEndpoint) {
    promises = [...promises, fetchIntegrationEvents(), fetchInstalledHubSpotOwnedIntegrations()];
  }
  return Promise.all(promises).then(([data, integrationsAppsData, installedIntegrationsData]) => {
    if (!data) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ message: string; }' is not ass... Remove this comment to see the full error message
      devLogger.warn({
        message: 'Timeline failed with no data'
      });
      return data;
    }

    // somehow, occasionally we are getting a mutable data value
    if (!ImmutableMap.isMap(data)) {
      Raven.captureMessage('Timeline data not immutable', {
        extra: {
          requestPayload,
          data
        }
      });
      return data;
    }
    if (integrationsAppsData || installedIntegrationsData) {
      data = data.merge({
        integrationsAppsData,
        installedIntegrationsData
      });
    }
    const dataFailed = data.get('failed', List());
    const dataTimedOut = data.get('timedOut', List());
    const hasDataTimedOut = !dataTimedOut.isEmpty();
    const hasDataFailed = !dataFailed.isEmpty();
    const isCompleteFailure = data.get('defaultTimelineTypes').size === dataFailed.size + dataTimedOut.size;
    if (hasDataFailed) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ message: string; }' is not ass... Remove this comment to see the full error message
      devLogger.warn({
        message: `Timeline items failed: ${dataFailed.toJS()}`
      });
      logCountToNewRelic('DataFailed', dataFailed);
      if (timesToRetry === 0) {
        Raven.captureMessage('Timeline items repeatedly failed', {
          extra: {
            requestPayload,
            data,
            isFirstFetch
          }
        });
        CrmLogger.log('timelineInteraction', {
          action: 'Timeline items repeatedly failed',
          eventType: dataFailed.join(', '),
          type: objectType
        });
        throw new TimelineItemsFailedError({
          failed: dataFailed,
          isCompleteFailure
        });
      }
      Raven.captureMessage('Timeline items failed', {
        extra: {
          requestPayload,
          data,
          isFirstFetch
        }
      });

      // isFirstFetch means that this is the default timeline request (i.e. early request to get
      // initial events for the timeline). We only retry the default request if we receive failed events
      // from the default request. We do not retry for any timeline requests that have failed events
      if (isFirstFetch) {
        requestPayload = requestPayload.set('eventOptions', dataFailed);
        fetchWithErrorHandling(requestPayload, successCallback, errorCallback, {
          timesToRetry: timesToRetry - 1,
          isFirstFetch
        }).then(successCallback, errorCallback);
      }
    }
    if (hasDataTimedOut) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ message: string; }' is not ass... Remove this comment to see the full error message
      devLogger.warn({
        message: `Timeline items timed out: ${dataTimedOut.toJS()}`
      });
      logCountToNewRelic('DataTimedOut', dataTimedOut);
      if (timesToRetry === 0) {
        Raven.captureMessage('Timeline items repeatedly timed out', {
          extra: {
            requestPayload,
            data,
            isFirstFetch
          }
        });
        throw new TimelineItemsFailedError({
          failed: dataTimedOut,
          isCompleteFailure
        });
      }
      requestPayload = requestPayload.set('eventOptions', dataTimedOut);
      fetchWithErrorHandling(requestPayload, successCallback, errorCallback, {
        timesToRetry: timesToRetry - 1,
        isFirstFetch
      }).then(successCallback, errorCallback);
    }
    return data;
  });
}
function handleSuccessfulElasticSearchFetch(originalPayload, data) {
  const timelineEvents = data.get('engagements').map(engagement => {
    const timestamp = engagement.getIn(['engagement', 'timestamp']);
    const engagementId = engagement.getIn(['engagement', 'id']);
    return ImmutableMap({
      etype: 'eventEngagement',
      eventData: engagement,
      timestamp,
      id: `eventEngagement-${timestamp}-${engagementId}`
    });
  });
  const nextTimestamp = data.get('engagements').last() && data.get('engagements').last().getIn(['engagement', 'timestamp']);
  data = data.set('events', timelineEvents).set('hasMore', data.get('has-more')).set('originalPayload', originalPayload).set('nextTimestamp', nextTimestamp);
  dispatch(FETCH_TIMELINE_ITEMS_SUCCESS, data);
}
function getSuccessCallback(originalPayload) {
  return data => {
    if (shouldUseElasticSearch(originalPayload)) {
      handleSuccessfulElasticSearchFetch(originalPayload, data);
    } else {
      data = data.set('originalPayload', originalPayload);
      dispatch(FETCH_TIMELINE_ITEMS_SUCCESS, data);
      hoistEngagements(data);
    }
  };
}
function getSuccessfulPollingCallback(originalPayload) {
  return data => {
    data = data.set('originalPayload', originalPayload);
    dispatch(POLLING_FETCH_TIMELINE_ITEMS_SUCCESS, data);
  };
}
function getSuccessfulVisualForceCallback(originalPayload) {
  return data => {
    data = data.set('originalPayload', originalPayload);
    dispatch(FETCH_TIMELINE_ITEMS_FOR_VFP_SUCCESS, data);
  };
}
function getErrorCallback(actionType) {
  return error => {
    dispatch(actionType, {
      error
    });
  };
}
function getEventOptions(requestPayload) {
  let eventOptions = requestPayload.get('eventOptions');
  const objectType = requestPayload.get('objectType');
  if (!eventOptions) {
    eventOptions = List();
  }
  return fillExtraEventOptions(objectType, eventOptions);
}
function getFormattedRequestPayload(requestPayload) {
  const subjectId = requestPayload.get('subjectId');
  const objectType = requestPayload.get('objectType');
  return requestPayload.merge({
    objectId: subjectId,
    objectType,
    eventOptions: getEventOptions(requestPayload)
  });
}
function fetchTimelineEvents(requestPayload, successCallback, errorCallback, {
  isFirstFetch = false,
  useDefaults = false
} = {
  isFirstFetch: false,
  useDefaults: false
}) {
  if (!requestPayload.get('eventOptions') || !requestPayload.get('eventOptions').size) {
    dispatchQueue(CLEAR_ALL_FILTERS);
    return;
  }
  const formattedRequestPayload = getFormattedRequestPayload(requestPayload);
  fetchWithErrorHandling(formattedRequestPayload, successCallback, errorCallback, {
    isFirstFetch,
    useDefaults
  }).then(successCallback, errorCallback);
}
function performSecondRequest(nextTimestamp, originalPayload, formattedRequestPayload, useDefaults) {
  const nextDateRange = ImmutableMap({
    now: originalPayload.get('now'),
    toDate: nextTimestamp
  });
  const nextOriginalPayload = originalPayload.merge(nextDateRange);
  const nextActivitiesFetchPayload = formattedRequestPayload.merge(nextDateRange);
  const secondSuccessCallback = getSuccessCallback(nextOriginalPayload);
  const errorCallback = getErrorCallback(FETCH_TIMELINE_ITEMS_ERROR);
  fetchWithErrorHandling(nextActivitiesFetchPayload,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(data: $tsMigrateAny) => void' i... Remove this comment to see the full error message
  secondSuccessCallback, errorCallback, {
    useDefaults
  }).then(secondSuccessCallback, errorCallback);
}
function fetchAfterNoResults(state, requestPayload, useDefaults = false) {
  const originalPayload = requestPayload;
  const formattedRequestPayload = getFormattedRequestPayload(originalPayload);
  const firstSuccessCallback = data => {
    data = data.set('originalPayload', originalPayload);
    dispatch(FETCH_TIMELINE_ITEMS_SUCCESS, data);
    if (useDefaults) {
      dispatchQueue(TIMELINE_QUICK_FETCH_STORE_SUCCESS, data);
    }
    hoistEngagements(data);
    const nextTimestamp = data.get('nextTimestamp');
    const shouldStopFetch = data.get('events').size || !nextTimestamp;
    if (!shouldStopFetch) {
      performSecondRequest(nextTimestamp, originalPayload, formattedRequestPayload, useDefaults);
    }
  };
  const errorCallback = getErrorCallback(FETCH_TIMELINE_ITEMS_ERROR);
  fetchWithErrorHandling(formattedRequestPayload,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(data: $tsMigrateAny) => void' i... Remove this comment to see the full error message
  firstSuccessCallback, errorCallback, {
    useDefaults,
    isFirstFetch: useDefaults
  }).then(firstSuccessCallback, errorCallback);
}
export function getFormattedRequestPayloadForES(requestPayload) {
  const subjectId = requestPayload.get('subjectId');
  const objectType = requestPayload.get('objectType');
  let engagementsToFetch = requestPayload.get('eventOptions').map(option => engagementFilterToESFilter.get(option)).filter(identity);
  if (engagementsToFetch.contains(EMAIL)) {
    engagementsToFetch = engagementsToFetch.push(INCOMING_EMAIL).push(FORWARDED_EMAIL);
  }
  if (!engagementsToFetch.size) {
    engagementsToFetch = List(['NOTE']);
  }
  const filters = [{
    operator: 'IN',
    property: `associations.${objectType.toLowerCase()}`,
    values: [subjectId]
  }, {
    operator: 'IN',
    property: 'engagement.type',
    values: engagementsToFetch.toArray()
  }];
  if (requestPayload.get('selectedTeams') && requestPayload.get('selectedTeams').size) {
    filters.push({
      operator: 'IN',
      property: 'engagement.teamId',
      values: requestPayload.get('selectedTeams')
    });
  }
  if (requestPayload.get('selectedOwners') && requestPayload.get('selectedOwners').size) {
    filters.push({
      operator: 'IN',
      property: 'engagement.ownerId',
      values: requestPayload.get('selectedOwners')
    });
  }
  if (requestPayload.get('toDate')) {
    filters.push({
      operator: 'LTE',
      property: 'engagement.timestamp',
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ operator: string; property: st... Remove this comment to see the full error message
      value: requestPayload.get('toDate')
    });
  }
  const filterGroups = [];
  if (requestPayload.get('shouldLimitTimeline')) {
    filterGroups.push({
      filters: [...filters, {
        property: 'hs_engagement_type',
        operator: 'IN',
        values: ['NOTE', 'TASK']
      }]
    }, {
      filters: [...filters, {
        property: 'hs_engagement_type',
        operator: 'NOT_IN',
        values: ['NOTE', 'TASK']
      }, {
        property: 'hs_timestamp',
        operator: 'GTE',
        value: I18n.moment().subtract(30, 'days').startOf('day').valueOf()
      }]
    });
  } else {
    filterGroups.push({
      filters
    });
  }
  let query = EngagementsSearchAPIQuery.default({
    count: 20,
    sorts: [{
      property: 'engagement.timestamp',
      order: 'DESC'
    }],
    filterGroups
  }, {
    excludeDefaultFilters: true
  });
  if (requestPayload.get('searchText') && requestPayload.get('searchText').length) {
    query = query.set('query', requestPayload.get('searchText'));
  }
  return query;
}
function fetchResultsByUsersAndTeams(state, requestPayload) {
  if (!requestPayload.get('eventOptions') || !requestPayload.get('eventOptions').size) {
    return;
  }
  const formattedRequestPayload = getFormattedRequestPayloadForES(requestPayload);
  const successCallback = getSuccessCallback(requestPayload);
  const errorCallback = getErrorCallback(FETCH_TIMELINE_ITEMS_ERROR);
  fetchTimelineByUsersAndTeams(formattedRequestPayload).then(successCallback, errorCallback);
}
const handlers = {
  [FETCHING_INITIAL_TIMELINE_EVENTS_STARTED]: (state, requestPayload) => {
    if (shouldUseElasticSearch(requestPayload)) {
      fetchResultsByUsersAndTeams(state, requestPayload);
    } else {
      const isFirstFetch = true;
      fetchAfterNoResults(state, requestPayload, isFirstFetch);
    }
  },
  [FETCH_NEXT_TIMELINE_EVENTS_STARTED]: (state, requestPayload) => {
    if (shouldUseElasticSearch(requestPayload)) {
      fetchResultsByUsersAndTeams(state, requestPayload);
    } else {
      const successCallback = getSuccessCallback(requestPayload);
      const errorCallback = getErrorCallback(FETCH_TIMELINE_ITEMS_ERROR);
      fetchTimelineEvents(requestPayload, successCallback, errorCallback);
    }
  },
  [CHANGE_FILTER]: (state, {
    requestPayload
  }) => {
    if (shouldUseElasticSearch(requestPayload)) {
      fetchResultsByUsersAndTeams(state, requestPayload);
    } else {
      const successCallback = getSuccessCallback(requestPayload);
      const errorCallback = getErrorCallback(FETCH_TIMELINE_ITEMS_ERROR);
      fetchTimelineEvents(requestPayload, successCallback, errorCallback);
    }
  },
  [TIMELINE_CHANGE_USER_AND_TEAM_FILTER]: (state, {
    requestPayload
  }) => {
    if (shouldUseElasticSearch(requestPayload)) {
      fetchResultsByUsersAndTeams(state, requestPayload);
    } else {
      const successCallback = getSuccessCallback(requestPayload);
      const errorCallback = getErrorCallback(FETCH_TIMELINE_ITEMS_ERROR);
      fetchTimelineEvents(requestPayload, successCallback, errorCallback);
    }
  },
  [SET_FAVORITE_EVENT_OVERRIDE]: (state, {
    requestPayload
  }) => {
    if (shouldUseElasticSearch(requestPayload)) {
      fetchResultsByUsersAndTeams(state, requestPayload);
    } else {
      fetchAfterNoResults(state, requestPayload);
    }
  },
  [POLLING_FETCH_TIMELINE_ITEMS_STARTED]: (state, requestPayload) => {
    if (shouldUseElasticSearch(requestPayload)) {
      fetchResultsByUsersAndTeams(state, requestPayload);
    } else {
      const successCallback = getSuccessfulPollingCallback(requestPayload);
      const errorCallback = getErrorCallback(POLLING_FETCH_TIMELINE_ITEMS_ERROR);
      fetchTimelineEvents(requestPayload, successCallback, errorCallback);
    }
  },
  [FETCH_TIMELINE_ITEMS_FOR_VFP_STARTED]: (state, requestPayload) => {
    const successCallback = getSuccessfulVisualForceCallback(requestPayload);
    const errorCallback = getErrorCallback(FETCH_TIMELINE_ITEMS_ERROR);
    fetchTimelineEvents(requestPayload, successCallback, errorCallback, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'true' is not assignable to type 'false'.
      isFirstFetch: true,
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'true' is not assignable to type 'false'.
      useDefaults: true
    });
  }
};
export default registerService(ImmutableMap(), handlers);