/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext } from 'react';
import { AlertContext } from './context';
export const useAlerts = () => {
  const context = useContext(AlertContext);
  if (!context || !context.isInitialized) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  const {
    addAlert
  } = context;
  const addAlertDanger = ({
    message,
    titleText
  }) => {
    addAlert({
      message,
      titleText,
      type: 'danger'
    });
  };
  const addAlertInfo = ({
    message,
    titleText
  }) => {
    addAlert({
      message,
      titleText,
      type: 'info'
    });
  };
  const addAlertSuccess = ({
    message,
    titleText
  }) => {
    addAlert({
      message,
      titleText,
      type: 'success'
    });
  };
  const addAlertWarning = ({
    message,
    titleText
  }) => {
    addAlert({
      message,
      titleText,
      type: 'warning'
    });
  };
  return {
    addAlertDanger,
    addAlertInfo,
    addAlertSuccess,
    addAlertWarning
  };
};