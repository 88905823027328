// The interaction types are duplicated in crm-message-bus for use in defining the message payload without bringing in an external dependency.
// https://git.hubteam.com/HubSpot/crm-message-bus-sdk/blob/4a5c692edc9e9f2c798822af2cef265b00fe0679/crm-message-bus/static-1.29182/js/types/ActivityCreatorTypes.ts
// If any changes are made, make sure both of these locations are in sync.

import { CALL as CallEngagementType, EMAIL as EmailEngagementType, LINKEDIN_MESSAGE as LinkedInMessageEngagementType, MEETING as MeetingEngagementType, POSTAL_MAIL as PostalMailEngagementType, SMS as SMSEngagementType, TASK as TaskEngagementType, WHATS_APP as WhatsAppEngagementType } from 'customer-data-objects/engagement/EngagementTypes';
export const CALL = 'call';
export const EMAIL = 'email';
export const ENROLLED_IN_SEQUENCE = 'enrolled-in-sequence';
export const LINKED_IN = 'linked-in';
export const LINKED_IN_CONNECT = 'linked-in-connect';
export const LINKED_IN_MESSAGE = 'linked-in-message';
export const LOGGED_CALL = 'logged-call';
export const LOGGED_EMAIL = 'logged-email';
export const LOGGED_LINKED_IN_MESSAGE = 'logged-linked-in-message';
export const LOGGED_MEETING = 'logged-meeting';
export const LOGGED_POSTAL_MAIL = 'logged-postal-mail';
export const LOGGED_SMS = 'logged-sms';
export const LOGGED_WHATS_APP = 'logged-whats-app';
export const NOTE = 'note';
export const SCHEDULE = 'schedule';
export const TASK = 'task';
export const WHATS_APP = 'whats-app';
export const LOGGED_INTERACTIONS = [LOGGED_CALL, LOGGED_EMAIL, LOGGED_LINKED_IN_MESSAGE, LOGGED_MEETING, LOGGED_POSTAL_MAIL, LOGGED_SMS, LOGGED_WHATS_APP];
export const THIRD_PARTY_CONVERSATION_INTERACTIONS = [LinkedInMessageEngagementType, SMSEngagementType, WhatsAppEngagementType];
export const INTERACTION_TYPE_TO_ENGAGEMENT_TYPE = {
  'logged-call': CallEngagementType,
  'logged-email': EmailEngagementType,
  'logged-linked-in-message': LinkedInMessageEngagementType,
  'logged-meeting': MeetingEngagementType,
  'logged-postal-mail': PostalMailEngagementType,
  'logged-sms': SMSEngagementType,
  'logged-whats-app': WhatsAppEngagementType
};
export const ENGAGEMENT_TYPE_TO_INTERACTION_TYPE = {
  [CallEngagementType]: CALL,
  [EmailEngagementType]: EMAIL,
  [MeetingEngagementType]: SCHEDULE,
  [TaskEngagementType]: TASK,
  [WhatsAppEngagementType]: WHATS_APP
};
export const INTERACTION_TYPES = [NOTE, EMAIL, CALL, TASK, SCHEDULE, WHATS_APP, LINKED_IN, LINKED_IN_CONNECT, LINKED_IN_MESSAGE, ENROLLED_IN_SEQUENCE, LOGGED_SMS, LOGGED_LINKED_IN_MESSAGE, LOGGED_WHATS_APP, LOGGED_CALL, LOGGED_MEETING, LOGGED_EMAIL, LOGGED_POSTAL_MAIL];
export const UNSUPPORTED_BUS_MESSAGE_INTERACTION_TYPES = [CALL, ENROLLED_IN_SEQUENCE, LINKED_IN];
export const LINKEDIN_SUB_INTERACTION_TYPES = [LINKED_IN_CONNECT, LINKED_IN_MESSAGE];
export const BUTTON_INTERACTION_TYPES = INTERACTION_TYPES.filter(interactionName => !LINKEDIN_SUB_INTERACTION_TYPES.includes(interactionName));