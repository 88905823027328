// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'disp... Remove this comment to see the full error message
import dispatcher from 'dispatcher/dispatcher';
import { fetch } from '../apis/HasEmailEngagementsAPI';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { HAS_EMAIL_ENGAGEMENTS } from 'crm_data/actions/ActionNamespaces';
import { defineLazyKeyStore, registerLazyKeyService
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/store/LazyKeyStore';
import { Map as ImmutableMap } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import makeBatch from 'crm_data/api/makeBatch';
const batchFetch = makeBatch(id => {
  const objectType = id.get('objectType');
  const subjectId = id.get('subjectId');
  return fetch(objectType, subjectId);
}, 'HasEmailEngagementsStore.fetch');
registerLazyKeyService({
  namespace: HAS_EMAIL_ENGAGEMENTS,
  fetch: batchFetch
});
export default defineLazyKeyStore({
  namespace: HAS_EMAIL_ENGAGEMENTS,
  idIsValid: key => {
    return ImmutableMap.isMap(key) && typeof key.get('subjectId') === 'string' && typeof key.get('objectType') === 'string';
  }
}).defineName('HasEmailEngagementsStore').register(dispatcher);