// Duplicated June 26, 2023 from customer-data-sidebar/universal/dataAccessHelpers/getCrmObjectPrimaryDisplayLabel
// Updated for TypeScript

import I18n from 'I18n';
import { COMPANY, CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import formatName from 'I18n/utils/formatName';
const EMPTY_LABEL = '--';
const getPropertyValue = (allProperties, propName) => {
  const foundProperty = allProperties.find(property => property.name === propName);
  return foundProperty && foundProperty.value;
};
const getCrmObjectPrimaryDisplayLabel = (objectType, crmObject) => {
  const allProperties = crmObject.allProperties;
  if (objectType === CONTACT || objectType === CONTACT_TYPE_ID) {
    const firstName = getPropertyValue(allProperties, 'firstname');
    const lastName = getPropertyValue(allProperties, 'lastname');
    const email = getPropertyValue(allProperties, 'email');
    return formatName({
      firstName,
      lastName
    }) || email || EMPTY_LABEL;
  }
  if (objectType === COMPANY || objectType === COMPANY_TYPE_ID) {
    const name = getPropertyValue(allProperties, 'name');
    const domain = getPropertyValue(allProperties, 'domain');
    return name || domain || EMPTY_LABEL;
  }
  if (objectType === COMMERCE_PAYMENT_TYPE_ID) {
    const currencyCode = getPropertyValue(allProperties, 'hs_currency_code');
    const amount = getPropertyValue(allProperties, 'hs_initial_amount');
    if (amount !== undefined && amount !== null) {
      return I18n.formatIntlCurrency(parseFloat(amount), {
        currencyCode
      }) || EMPTY_LABEL;
    } else {
      return EMPTY_LABEL;
    }
  }
  const propertyInfo = crmObject.primaryDisplayProperty;
  return propertyInfo && propertyInfo.value || EMPTY_LABEL;
};
export default getCrmObjectPrimaryDisplayLabel;