// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import UserSettingsStore from 'crm_data/settings/UserSettingsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import UserSettingsKeys from 'crm_data/settings/UserSettingsKeys';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'gene... Remove this comment to see the full error message
import { useStoreDependency } from 'general-store';
import { useLegacyQueryParams } from '../../../router/hooks/useLegacyQueryParams';
const dependencies = {
  isCollapsedByDefault: {
    stores: [UserSettingsStore],
    deref: props => {
      if (props.isEngagementDeepLink) {
        return false;
      }

      /**
       * Due to a known bug in LazyKeyStore where falsy values are set to null, we currently negate the
       * user setting around whether or not the compact timeline is expanded by default.
       *
       * https://git.hubteam.com/HubSpot/CRM-Issues/issues/7980
       *
       * To prevent a larger refactor we negate the user setting so downstream isEventCollapsed and the
       * TimelineCollapsedContext behaves as expected.
       */
      return !UserSettingsStore.get(UserSettingsKeys.IS_TIMELINE_EXPANDED_BY_DEFAULT);
    }
  }
};
export function useIsEventCollapsedByDefault() {
  // @ts-expect-error typing of query params
  const {
    engagement: engagementParam
  } = useLegacyQueryParams();
  const isEngagementDeepLink = !!engagementParam;
  const isCollapsedByDefault = useStoreDependency(dependencies.isCollapsedByDefault, {
    isEngagementDeepLink
  });
  return isCollapsedByDefault;
}