// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'gene... Remove this comment to see the full error message
import { connect } from 'general-store';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import makeOwnersTokenDependency from 'crm_data/tokens/makeOwnersTokenDependency';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import * as FormatOwnerName from 'customer-data-objects/owners/FormatOwnerName';
export function connectRte(RteComponent) {
  return connect({
    owners: makeOwnersTokenDependency(FormatOwnerName)
  })(RteComponent);
}