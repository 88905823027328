import { Map as ImmutableMap, List } from 'immutable';
import { CALL, CONVERSATION_SESSION, EMAIL_ACTIVITY, EMAIL, FORWARDED_EMAIL, INCOMING_EMAIL, MEETING, NOTE, TASK, POSTAL_MAIL } from 'customer-data-objects/engagement/EngagementTypes';
export const ENGAGEMENTS_ACTIVITY = 'ENGAGEMENTS_ACTIVITY';
export const ENGAGEMENTS_NOTES = 'ENGAGEMENTS_NOTES';
export const ENGAGEMENTS_EMAIL = 'ENGAGEMENTS_EMAILS_TRIMMED';
export const ENGAGEMENTS_CALLS = 'ENGAGEMENTS_CALLS';
export const ENGAGEMENTS_TASKS = 'ENGAGEMENTS_TASKS';
export const ENGAGEMENTS_MEETINGS = 'ENGAGEMENTS_MEETINGS';
export const ENGAGEMENTS_CONVERSATION_SESSIONS = 'ENGAGEMENTS_CONVERSATION_SESSIONS';
export const ENGAGEMENTS_WHATS_APP = 'ENGAGEMENTS_WHATS_APP';
export const ENGAGEMENTS_SMS = 'ENGAGEMENTS_SMS';
export const ENGAGEMENTS_LINKEDIN_MESSAGE = 'ENGAGEMENTS_LINKEDIN_MESSAGE';
export const ENGAGEMENTS_POSTAL_MAIL = 'ENGAGEMENTS_POSTAL_MAIL';
export const ENGAGEMENT_FILTER_TYPE_TO_ENGAGEMENT_TYPES = ImmutableMap({
  [ENGAGEMENTS_NOTES]: List([NOTE]),
  [ENGAGEMENTS_EMAIL]: List([EMAIL, EMAIL_ACTIVITY, INCOMING_EMAIL, FORWARDED_EMAIL]),
  [ENGAGEMENTS_CALLS]: List([CALL]),
  [ENGAGEMENTS_TASKS]: List([TASK]),
  [ENGAGEMENTS_MEETINGS]: List([MEETING]),
  [ENGAGEMENTS_CONVERSATION_SESSIONS]: List([CONVERSATION_SESSION, ENGAGEMENTS_WHATS_APP, ENGAGEMENTS_SMS, ENGAGEMENTS_LINKEDIN_MESSAGE]),
  [ENGAGEMENTS_POSTAL_MAIL]: List([POSTAL_MAIL])
});