import { useCurrentOwnerContext } from '../../owner/context/CurrentOwnerContext';
import { useTimelineAuthContext } from '../context/TimelineAuthContext';
import { getProperty } from 'customer-data-objects/model/ImmutableModel';
import { OWNER_ID, ALL_ACCESSIBLE_TEAM_IDS } from 'customer-data-objects/task/TaskPropertyNames';
const TASKS_EDIT_ALL = 'tasks-edit-all';
const TASKS_EDIT_TEAM_ONLY = 'tasks-edit-team-only';
const TASKS_EDIT_UNASSIGNED = 'tasks-edit-unassigned';
const TASKS_EDIT_OWNED_ONLY = 'tasks-edit-owned-only';
const parseTaskTeamIds = taskRecord => {
  const propertyValue = getProperty(taskRecord, ALL_ACCESSIBLE_TEAM_IDS);
  if (!propertyValue) {
    return [];
  }
  if (typeof propertyValue === 'string') {
    return propertyValue.split(';').map(teamId => parseInt(teamId, 10));
  }
  return propertyValue;
};
export function canEditTask({
  taskRecord,
  currentOwnerId,
  currentOwnerTeamIds,
  currentUserScopes
}) {
  if (!taskRecord) {
    return false;
  }
  const taskTeamIds = parseTaskTeamIds(taskRecord);
  const taskOwnerId = getProperty(taskRecord, OWNER_ID);
  const isTaskOwner = String(currentOwnerId) === String(taskOwnerId);
  if (isTaskOwner && currentUserScopes.includes(TASKS_EDIT_OWNED_ONLY)) {
    return true;
  }
  const canEditAll = currentUserScopes.includes(TASKS_EDIT_ALL);
  if (canEditAll) {
    return true;
  }
  const taskHasTeamId = taskTeamIds.some(teamId => currentOwnerTeamIds.includes(teamId));
  const canEditTeamTask = currentUserScopes.includes(TASKS_EDIT_TEAM_ONLY) && taskHasTeamId;
  if (canEditTeamTask) {
    return true;
  }
  const canEditUnassigned = !taskOwnerId && currentUserScopes.includes(TASKS_EDIT_UNASSIGNED);
  return canEditUnassigned;
}

/**
 * returns true if the current user can edit the task; false otherwise
 * @param {TaskRecord} taskRecord
 *
 * @example sample usage;
 *
 * const canEditTask = useTaskEditPermissions({ taskRecord })
 */
export function useTaskEditPermissions({
  taskRecord
}) {
  const {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'scopes' does not exist on type 'User | n... Remove this comment to see the full error message
    user: {
      scopes,
      teams
    }
  } = useTimelineAuthContext();
  const currentOwnerId = useCurrentOwnerContext();
  return canEditTask({
    taskRecord,
    currentOwnerId,
    currentOwnerTeamIds: (teams || []).map(team => team.id),
    currentUserScopes: scopes
  });
}