import { COMMERCE_PAYMENT_TYPE_ID, COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, PAYMENT_LINK_TYPE_ID, MEETING_EVENT_TYPE_ID, SUBSCRIPTION_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { CommercePayment } from './CommercePayment';
import { Company } from './Company';
import { Contact } from './Contact';
import { Deal } from './Deal';
import { Default } from './internal/Default';
import { MeetingEvent } from './MeetingEvent';
import { PaymentLink } from './PaymentLink';
import { Subscription } from './Subscription';
import { Ticket } from './Ticket';
import { SocialProfile } from './SocialProfile';
const extensions = {
  [COMMERCE_PAYMENT_TYPE_ID]: CommercePayment,
  [COMPANY_TYPE_ID]: Company,
  [CONTACT_TYPE_ID]: Contact,
  [DEAL_TYPE_ID]: Deal,
  [PAYMENT_LINK_TYPE_ID]: PaymentLink,
  [MEETING_EVENT_TYPE_ID]: MeetingEvent,
  [SUBSCRIPTION_TYPE_ID]: Subscription,
  [TICKET_TYPE_ID]: Ticket,
  ['0-144']: SocialProfile
};
export const getDefaultExtension = () => Default;
export const getExtensions = () => extensions;