import { OPEN_EDIT_SEQUENCE_MODAL } from 'crm-events-data';
// @ts-expect-error Could not find a declaration file
import SequenceActions from 'sequences-crm-lib/flux/SequenceActions';
import { useCallback } from 'react';
import { SequenceEditModalAsync } from 'sequences-crm-events-lib';
import { fetchSequenceEnrollment } from 'crm-timeline-tasks/queues/data/SequencesAPI';
const openEditSequenceModal = (enrollmentId, subjectId, objectType) => {
  return fetchSequenceEnrollment(enrollmentId).then(enrollment => {
    return SequenceEditModalAsync({
      enrollment
    }).then(({
      result,
      error
    }) => {
      return SequenceActions.updateEnrollmentAlert({
        enrollment: result,
        subjectId,
        objectType,
        error
      });
    }).catch(err => {
      if (!(err instanceof Error)) {
        // Promptable called onReject() because user closed the modal
        return;
      }
      throw err;
    });
  });
};
export const useEditSequenceModalActionHandler = ({
  objectType,
  subjectId
}) => {
  const handleEditSequenceModalAction = useCallback((actionType, data) => {
    if (actionType === OPEN_EDIT_SEQUENCE_MODAL) {
      const {
        enrollmentId
      } = data;
      openEditSequenceModal(enrollmentId, subjectId, objectType);
      return;
    }
    return;
  }, [subjectId, objectType]);
  return {
    handleEditSequenceModalAction
  };
};