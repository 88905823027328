import { useRef, useEffect } from 'react';
import { trackCrmObjectChangesClient } from '../api/trackCrmObjectChangesClient';
export const useTrackCrmObjectChanges = (objectTypeId, objectId, crmObject) => {
  const lastRunObjectCoordsRef = useRef(undefined);
  useEffect(() => {
    if (crmObject) {
      const objectCoords = `${objectTypeId}/${objectId}`;
      if (lastRunObjectCoordsRef.current !== objectCoords) {
        lastRunObjectCoordsRef.current = objectCoords;
        trackCrmObjectChangesClient.makeFetchWithOpts()(objectTypeId, objectId, crmObject).catch(() => {
          // Do nothing! This is used for logging purposes only.
        });
      } else {
        // Write any subsequent changes we're aware of to the cache,
        // under the assumption that they were performed by this user.
        trackCrmObjectChangesClient.syncIntoCache(Promise.resolve(crmObject), objectTypeId, objectId, crmObject);
      }
    }
  }, [crmObject, objectId, objectTypeId]);
};