import { useCallback } from 'react';
import { normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { getRootUrl, navigateToUrl } from '../utils/urls';
export function useRedirectOnObjectDeletion({
  subjectId,
  objectType
}) {
  const handleObjectDeleted = useCallback(({
    objectId,
    objectTypeId
  }) => {
    if (objectTypeId === normalizeTypeId(objectType) && objectId === subjectId) {
      navigateToUrl(`${getRootUrl()}/objects/${objectTypeId}`);
    }
  }, [objectType, subjectId]);
  useOnCrmMessageTopic(TOPIC_NAMES.OBJECT_DELETED, handleObjectDeleted);
}