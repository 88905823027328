import { useCallback } from 'react';
import { useTimelineDispatch, useTimelineSelector } from '../../reduxHooks';
import { makeGetEventCardDrafts, setDraftField as setDraftFieldAction, clearDraftField as clearDraftFieldAction } from '../eventCardsSlice';
export function useEventDraftField(eventId, field) {
  const getEventCardDrafts = makeGetEventCardDrafts(eventId);
  const draftsFromRedux = useTimelineSelector(getEventCardDrafts);
  const dispatch = useTimelineDispatch();
  const clearDraftField = useCallback(() => {
    dispatch(clearDraftFieldAction({
      eventId,
      field
    }));
  }, [dispatch, eventId, field]);
  const setDraftField = useCallback(value => {
    dispatch(setDraftFieldAction({
      eventId,
      field,
      value
    }));
  }, [dispatch, eventId, field]);
  return [draftsFromRedux[field], clearDraftField, setDraftField];
}