let _ = t => t,
  _t;
import { createGlobalStyle } from 'styled-components';
import { useTokens } from './useTokens';
const BREEZE_BRAND = '#E20185';
export const GlobalStyle = createGlobalStyle(_t || (_t = _`
${0};
`), () => {
  const {
    tokens
  } = useTokens();
  return `
  :root {
    --layout-lib-background-color: ${tokens.backgroundColor};
    --layout-lib-background-color-light: ${tokens.backgroundColorLight};
    --layout-lib-border-color: ${tokens.borderColor};
    --layout-lib-border-color-light: ${tokens.borderColorLight};
    --layout-lib-border: var(--layout-lib-border-width) solid var(--layout-lib-border-color);
    --layout-lib-border-light: var(--layout-lib-border-width) solid var(--layout-lib-border-color-light);
    --layout-lib-border-radius-sm: ${tokens.borderRadius.sm};
    --layout-lib-border-radius: ${tokens.borderRadius.default};
    --layout-lib-border-radius-md: ${tokens.borderRadius.md};
    --layout-lib-border-radius-lg: ${tokens.borderRadius.lg};
    --layout-lib-border-radius-xl: ${tokens.borderRadius.xl};
    --layout-lib-border-radius-xxl: ${tokens.borderRadius.xxl};
    --layout-lib-border-radius-full: ${tokens.borderRadius.full};
    --layout-lib-border-width: ${tokens.borderWidth};
    --layout-lib-shadow-card: ${tokens.shadow.card};
    --layout-lib-space-xxxs: ${tokens.space.xxxs};
    --layout-lib-space-xxs: ${tokens.space.xxs};
    --layout-lib-space-xs: ${tokens.space.xs};
    --layout-lib-space-sm: ${tokens.space.sm};
    --layout-lib-space: ${tokens.space.default};
    --layout-lib-space-md: ${tokens.space.md};
    --layout-lib-space-lg: ${tokens.space.lg};
    --layout-lib-space-xl: ${tokens.space.xl};
    --layout-lib-space-xxl: ${tokens.space.xxl};
    --layout-lib-space-xxxl: ${tokens.space.xxxl};
    --layout-lib-text-color: ${tokens.textColor.base};
    --layout-lib-text-color-danger: ${tokens.textColor.danger};
    --layout-lib-text-color-disabled: ${tokens.textColor.disabled};
    --layout-lib-text-color-light: ${tokens.textColor.light};
    --layout-lib-text-color-inverse: ${tokens.textColor.inverse};
    --layout-lib-breeze-brand: ${BREEZE_BRAND};
  `;
});