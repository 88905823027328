'use es6';

import { Map as ImmutableMap } from 'immutable';
import { defineLazyKeyStore, registerLazyKeyService } from 'crm_data/store/LazyKeyStore';
import { ASSOCIATED_OPEN_OBJECTS } from 'crm_data/actions/ActionNamespaces';
import { fetch } from './apis/AssociatedOpenObjectsAPI';
import makeBatch from 'crm_data/api/makeBatch';
const batchFetch = makeBatch(id => {
  const objectType = id.get('objectType');
  const subjectId = id.get('subjectId');
  const objectTypeToFetch = id.get('objectTypeToFetch');
  return fetch(objectType, subjectId, objectTypeToFetch);
}, 'AssociatedOpenObjectsStore.fetch');
registerLazyKeyService({
  namespace: ASSOCIATED_OPEN_OBJECTS,
  fetch: batchFetch
});
export default defineLazyKeyStore({
  namespace: ASSOCIATED_OPEN_OBJECTS,
  idIsValid: key => {
    return ImmutableMap.isMap(key) && typeof key.get('subjectId') === 'string' && typeof key.get('objectType') === 'string' && typeof key.get('objectTypeToFetch') === 'string';
  }
}).register();