'use es6';

import once from 'transmute/once';
export const getFileUploadOptions = once(() => ({
  query: {
    hidden: true
  },
  /*
   * RE: https://git.hubteam.com/HubSpot/draft-plugins/blob/master/static/js/api/FileManagerApi.js#L28
   * Hide the files in FileManager, block anonymous access and block search engine indexing
   */
  hideAndProtectFile: true
}));