// @ts-expect-error missing dependency types
import makeBatch from 'crm_data/api/makeBatch';
import dispatch from '../actions/dispatch';
import { getRegisteredDeals } from '../api/DealRegistrationAPI';
import { DEAL_REGISTRATIONS_REQUESTED, DEAL_REGISTRATIONS_SUCCEEDED, DEAL_REGISTRATIONS_FAILED } from '../actions/ActionTypes';
import { getAssociationsForObject } from '../api/AssociationsAPI';
import { ObjectTypeIds } from '../constants/CRMObjectConstants';
import { getBatchOfObjectIds, getCrmObject } from '../api/CRMObjectsAPI';
export function getDealRegistrations(dealId) {
  dispatch(DEAL_REGISTRATIONS_REQUESTED);
  return getRegisteredDeals(dealId).then(response => {
    dispatch(DEAL_REGISTRATIONS_SUCCEEDED, {
      dealId,
      response
    });
  }, () => dispatch(DEAL_REGISTRATIONS_FAILED));
}
function getAssociatedDealRegistrationIds(dealId) {
  return getAssociationsForObject(ObjectTypeIds.DEAL, dealId, ObjectTypeIds.DEAL_REGISTRATION);
}
export const getAssociatedDealRegistrationIdsBatch = makeBatch(getAssociatedDealRegistrationIds);
function getDealRegistrationCrmObject(id) {
  return getCrmObject(ObjectTypeIds.DEAL_REGISTRATION, id);
}
export const getDealRegistrationCrmObjectBatch = makeBatch(getDealRegistrationCrmObject);
export function getAssociatedCrmCompany(dealRegistrationId) {
  return getAssociationsForObject(ObjectTypeIds.DEAL_REGISTRATION, dealRegistrationId, ObjectTypeIds.COMPANY).then(data => {
    const companyIds = data.results.map(r => r.objectId);
    return getBatchOfObjectIds(ObjectTypeIds.COMPANY, companyIds);
  });
}