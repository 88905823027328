'use es6';

import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
const base_url = 'contacts/v1/gdpr/internal/gdpr-delete';
export const deleteContact = (vids, email) => ImmutableAPI.post(base_url, {
  vids,
  email
});
export const deleteContactWithProof = (vids, email, sendProof, emailAddressesToSendProof) => ImmutableAPI.post(base_url, {
  vids,
  email,
  sendProof,
  emailAddressesToSendProof
});