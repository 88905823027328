import events from '../../events.yaml';
import { createTracker } from 'usage-tracker';
export const DefaultUsageTracker = createTracker({
  events
});
/* MockUsageTracker is being created and altered here so that we can disable usage tracking
  Without having to do existence checks throughout all the cards and cards sdk. There are
  some usages where we won't want to track data, such as inside our sandbox. */
export const MockUsageTracker = createTracker({
  events: {}
});
MockUsageTracker.track = () => {};