import { useEffect, useState } from 'react';
export const useIsWindowVisible = () => {
  const [isWindowVisible, setIsWindowVisible] = useState(true);
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setIsWindowVisible(true);
      } else {
        setIsWindowVisible(false);
      }
    };
    window.addEventListener('visibilitychange', handleVisibilityChange);
    handleVisibilityChange();
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return {
    isWindowVisible
  };
};