'use es6';

import { defineFactory, InspectStore } from 'general-store';
import { Iterable, Set as ImmutableSet } from 'immutable';
import invariant from 'react-utils/invariant';
import isArray from 'transmute/isArray';
import isFunction from 'transmute/isFunction';
import isObject from 'transmute/isObject';
import { isEmpty, isLoading } from '../flux/LoadingStatus';
import map from 'transmute/map';
import memoize from 'transmute/memoize';
export function getUniqueActionTypes(stores) {
  return stores.reduce((allActions, store) => allActions.union(InspectStore.getActionTypes(store)), ImmutableSet()).toArray();
}
export function enforceIdIsValid(idIsValid, id, key = null) {
  if (key === null) {
    invariant(idIsValid(id), 'JoinStore: expected `idIsValid` to return true for id: `%s`', id);
  } else {
    invariant(idIsValid(id), 'JoinStore: expected `idIsValid` to return true for ids[%s]: `%s`', key, id);
  }
  return id;
}
export const defaultIdIsValid = id => id != null;
function isIdCollection(ids) {
  return isArray(ids) || isObject(ids) || Iterable.isIterable(ids);
}
export function defaultJoin(id, stores) {
  const len = stores.length;
  let value = stores[0].get(id);
  for (let i = 1; i < len; i++) {
    if (isEmpty(value) || isLoading(value)) {
      return value;
    }
    value = stores[i].get(value);
  }
  return value;
}
export function defineJoinStore({
  idIsValid = defaultIdIsValid,
  join = defaultJoin,
  stores
}) {
  invariant(Array.isArray(stores), 'expected `stores` to be an Array of Stores but got `%s`', stores);
  invariant(isFunction(idIsValid), 'expected `idIsValid` to be a function but got `%s`', idIsValid);
  invariant(isFunction(join), 'expected `idIsValid` to be a function but got `%s`', join);
  return defineFactory().defineGetInitialState(() => memoize(join)).defineGet((memoizedJoin, ids) => {
    if (idIsValid(ids)) {
      return memoizedJoin(ids, stores);
    }
    invariant(isIdCollection(ids), 'JoinStore: expected a valid id or an Array/Object/Iterable of valid ids but got `%s`', ids);
    return map((id, key) => memoizedJoin(enforceIdIsValid(idIsValid, id, key), stores), ids);
  }).defineResponseTo(getUniqueActionTypes(stores), joiner => {
    if (joiner.cache.isEmpty()) {
      return joiner;
    }
    return memoize(join);
  });
}