import { gql } from '@apollo/client';
export const AssociationPivotConfigFragment = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"AssociationPivotConfigFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"AssociationPivotCardConfiguration\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"toObjectTypeId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"associationTypes\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"associationCategory\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"associationTypeId\"}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "AssociationPivotConfigFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "AssociationPivotCardConfiguration"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "toObjectTypeId"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "associationTypes"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "associationCategory"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "associationTypeId"
            }
          }]
        }
      }]
    }
  }]
});