'use es6';

import { ASSOCIATIONS_REFRESH_QUEUED, OBJECT_ASSOCIATION_SUCCEEDED, OBJECT_DISASSOCIATION_SUCCEEDED } from 'crm_data/actions/ActionTypes';
import { associateObject, disassociateCrmObject, disassociateObject, fetch } from 'crm_data/associations/AssociationsAPI';
import { dispatchImmediate, dispatchQueue } from 'crm_data/dispatch/Dispatch';
import { CONTACT_TO_COMPANY, COMPANY_TO_CONTACT, CONTACT_TO_DEAL, DEAL_TO_CONTACT, DEAL_TO_COMPANY, COMPANY_TO_DEAL, ENGAGEMENT_TO_COMPANY, ENGAGEMENT_TO_CONTACT, ENGAGEMENT_TO_DEAL, ENGAGEMENT_TO_TICKET } from 'crm_data/associations/AssociationTypes';
import { DISASSOCIATE_CONTACT_AND_COMPANY, ASSOCIATE_CONTACT_AND_DEAL, DISASSOCIATE_CONTACT_AND_DEAL, ASSOCIATE_UNIVERSAL_ASSOCIATION_AND_ENGAGEMENT, ASSOCIATE_ALL_UNIVERSAL_ASSOCIATIONS_AND_ENGAGEMENT, DISASSOCIATE_UNIVERSAL_ASSOCIATION_AND_ENGAGEMENT } from 'crm_schema/association/AssociationActionTypes';
import { List, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { ASSOCIATIONS } from '../actions/ActionNamespaces';
const UPDATED = `${ASSOCIATIONS}_UPDATED`;
const addAssociationsToCachedRecords = (associationType, subjectId, objectIds, fromObjectType, toObjectType) => {
  objectIds.forEach(objectId => {
    switch (associationType) {
      case CONTACT_TO_COMPANY:
      case COMPANY_TO_DEAL:
        dispatchImmediate(`ASSOCIATE_${fromObjectType}_AND_${toObjectType}`, {
          subjectId: objectId,
          objectId: subjectId
        });
        break;
      case COMPANY_TO_CONTACT:
      case DEAL_TO_CONTACT:
      case DEAL_TO_COMPANY:
        dispatchImmediate(`ASSOCIATE_${toObjectType}_AND_${fromObjectType}`, {
          subjectId,
          objectId
        });
        break;
      case CONTACT_TO_DEAL:
        dispatchImmediate(ASSOCIATE_CONTACT_AND_DEAL, {
          subjectId: objectId,
          objectId: +subjectId
        });
        break;
      case ENGAGEMENT_TO_COMPANY:
      case ENGAGEMENT_TO_CONTACT:
      case ENGAGEMENT_TO_DEAL:
      case ENGAGEMENT_TO_TICKET:
        dispatchImmediate(`ASSOCIATE_${toObjectType}_AND_${fromObjectType}`, {
          subjectId,
          objectIds: List([objectId]),
          objectType: toObjectType
        });
        break;
      default:
        break;
    }
  });
};
const removeAssociationsFromCachedRecords = (associationType, subjectId, objectId, fromObjectType, toObjectType) => {
  switch (associationType) {
    case COMPANY_TO_CONTACT:
    case DEAL_TO_CONTACT:
    case DEAL_TO_COMPANY:
      dispatchImmediate(`DISASSOCIATE_${toObjectType}_AND_${fromObjectType}`, {
        subjectId,
        objectId
      });
      break;
    case COMPANY_TO_DEAL:
      dispatchImmediate(`DISASSOCIATE_${fromObjectType}_AND_${toObjectType}`, {
        subjectId: objectId,
        objectId: +subjectId
      });
      break;
    case CONTACT_TO_COMPANY:
      dispatchImmediate(DISASSOCIATE_CONTACT_AND_COMPANY, {
        subjectId: objectId,
        objectId: subjectId
      });
      break;
    case CONTACT_TO_DEAL:
      dispatchImmediate(DISASSOCIATE_CONTACT_AND_DEAL, {
        subjectId: objectId,
        objectId: +subjectId
      });
      break;
    case ENGAGEMENT_TO_COMPANY:
    case ENGAGEMENT_TO_CONTACT:
    case ENGAGEMENT_TO_DEAL:
    case ENGAGEMENT_TO_TICKET:
      dispatchImmediate(`DISASSOCIATE_${toObjectType}_AND_${fromObjectType}`, {
        subjectId,
        objectIds: List([objectId]),
        objectType: toObjectType
      });
      break;
    default:
      return null;
  }
  return null;
};
function onAssociationSuccess({
  associationType,
  subjectId,
  objectIds,
  fromObjectType,
  toObjectType
}) {
  dispatchImmediate(OBJECT_ASSOCIATION_SUCCEEDED, {
    objectId: subjectId,
    associationsAdded: objectIds,
    fromObjectType,
    toObjectType
  });
  addAssociationsToCachedRecords(associationType, subjectId, objectIds, fromObjectType, toObjectType);
}
export function associate(subjectId, objectIds, fromObjectType, toObjectType, associationTypeId // Optional parameter to specify a primary or unlabeled ID
) {
  const associationType = `${fromObjectType}_TO_${toObjectType}`;
  return associateObject({
    subjectId,
    objectIds,
    associationType,
    associationTypeId
  }).then(() => {
    onAssociationSuccess({
      associationType,
      subjectId,
      objectIds,
      fromObjectType,
      toObjectType
    });
  });
}
export function onDisassociationSuccess({
  associationType,
  subjectId,
  objectId,
  fromObjectType,
  toObjectType
}) {
  dispatchImmediate(OBJECT_DISASSOCIATION_SUCCEEDED, {
    objectId: subjectId,
    associationsRemoved: objectId,
    fromObjectType,
    toObjectType
  });
  removeAssociationsFromCachedRecords(associationType, subjectId, objectId, fromObjectType, toObjectType);
}
export function disassociate(subjectId, objectId, fromObjectType, toObjectType) {
  const associationType = `${fromObjectType}_TO_${toObjectType}`;
  return disassociateObject({
    subjectId,
    objectId,
    associationType
  }).then(() => {
    onDisassociationSuccess({
      associationType,
      subjectId,
      objectId,
      fromObjectType,
      toObjectType
    });
  });
}

// TODO
// This only has to live until https://git.hubteam.com/HubSpot/CRM-Issues/issues/1292 is fixed
// Then we can link all association cards to the filtered table
export function fetchMoreAssociations(objectType, objectId, associationObjectType, offset, currentassociationIds) {
  const associationType = `${objectType}_TO_${associationObjectType}`;
  const key = ImmutableMap({
    objectType,
    objectId,
    associationType,
    offset
  });
  return fetch(key).then(data => {
    const oldResults = List(currentassociationIds);
    const newResults = oldResults.concat(data.get('results'));
    const newResponse = ImmutableMap().set(key.delete('offset'), data.merge({
      results: newResults
    }));
    dispatchImmediate(UPDATED, newResponse);
  });
}
export const updateEngagementsStoreWithUniversalAssociations = ({
  engagementId,
  universalAssociationMap
}) => {
  dispatchQueue(ASSOCIATE_ALL_UNIVERSAL_ASSOCIATIONS_AND_ENGAGEMENT, {
    engagementId,
    universalAssociationMap
  });
};
export const associateUniversalAssociation = ({
  engagementId,
  objectId,
  updatedAssociationRecord
}) => {
  const associationTypeId = updatedAssociationRecord.get('associationTypeId');
  const associationCategory = updatedAssociationRecord.get('associationCategory');
  return associateObject({
    subjectId: engagementId,
    objectIds: [objectId],
    associationTypeId,
    associationCategory
  }).then(() => {
    dispatchImmediate(ASSOCIATE_UNIVERSAL_ASSOCIATION_AND_ENGAGEMENT, {
      engagementId,
      updatedAssociationRecord
    });
  });
};
export const disassociateUniversalAssociation = ({
  engagementId,
  objectId,
  updatedAssociationRecord
}) => {
  const associationTypeId = updatedAssociationRecord.get('associationTypeId');
  const associationCategory = updatedAssociationRecord.get('associationCategory');
  return disassociateCrmObject({
    engagementId,
    objectId,
    associationTypeId,
    associationCategory
  }).then(() => {
    dispatchImmediate(DISASSOCIATE_UNIVERSAL_ASSOCIATION_AND_ENGAGEMENT, {
      engagementId,
      updatedAssociationRecord
    });
  });
};
export const refreshAssociations = ({
  objectId,
  objectType,
  associationObjectType
}) => {
  dispatchQueue(ASSOCIATIONS_REFRESH_QUEUED, ImmutableSet([ImmutableMap({
    objectId,
    objectType,
    associationType: `${objectType}_TO_${associationObjectType}`
  })]));
};