// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import * as ActionTypes from 'crm_data/actions/ActionTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import * as ElasticSearchAPI from 'crm_data/elasticSearch/api/ElasticSearchAPI';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { dispatch } from 'crm_data/flux/dispatch';

/** TODO: RELIABILITY_CLEANUP: https://git.hubteam.com/HubSpot/CRM-Issues/issues/5708
 *
 * crm-legacy-global-containersis deprecated.
 * Please use the application-specific container likely in /containers or pass in this data instead.
 */
import GlobalScopesContainer from 'crm-legacy-global-containers/GlobalScopesContainer';
import { COMPANY, CONTACT, DEAL, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import { INDEX } from 'customer-data-objects/view/PageTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'gene... Remove this comment to see the full error message
import { define } from 'general-store';
import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { GRID_ALL_SELECTED, GRID_CHECKED, GRID_CLEANUP_SELECTED, GRID_NUMBER_EDITABLE_UPDATED, GRID_RESET, GRID_SAVED_FILTER_CHANGED, GRID_SEARCH_QUERY_UPDATED, GRID_SEARCH_UPDATED, GRID_SELECTED_CLEARED, GRID_SHOWING_CHANGED, GRID_TEMPORARY_EXCLUDE_IDS, GRID_TEMPORARY_INCLUDE_ID, GRID_TEMPORARY_RESET, GRID_TEMPORARY_SET, UPDATE_GRID_SEARCH_QUERIES } from '../actions/GridUIActionTypes';
import { updateUIState } from '../../helpers/gridStateLocalStorage';
const nullView = ImmutableMap({
  viewId: null,
  pageType: INDEX,
  query: ''
});
const _getInitialState = () => ImmutableMap({
  query: '',
  displayQuery: '',
  checked: ImmutableSet(),
  allSelected: false,
  numberEditable: 0,
  temporaryIds: ImmutableMap({
    include: ImmutableSet(),
    exclude: ImmutableSet()
  }),
  views: ImmutableMap({
    CONTACT: nullView,
    COMPANY: nullView,
    DEAL: nullView,
    TICKET: nullView
  })
});
let _state = _getInitialState();
const _checkEditable = function _checkEditable(userAppliedFilters) {
  if (userAppliedFilters == null) {
    userAppliedFilters = [];
  }
  const objectType = _state.get('objectType');
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | boolean | Set<... Remove this comment to see the full error message
  if (![CONTACT, COMPANY, DEAL, TICKET].includes(objectType)) {
    return;
  }

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  const scopes = GlobalScopesContainer.getContainer().get();
  if (
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  scopes['crm-view-all'] && scopes['crm-edit-all'] ||
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  scopes['crm-view-unassigned'] && scopes['crm-edit-unassigned']) {
    return;
  }
  if (_state.get('allSelected')) {
    const searchQuery = ImmutableMap({
      count: 0,
      offset: 0,
      filterGroups: [{
        filters: userAppliedFilters
      }],
      objectTypeId: objectType
    });
    ElasticSearchAPI.searchEditable(objectType, searchQuery).then(res => dispatch(GRID_NUMBER_EDITABLE_UPDATED, {
      numberEditable: res.get('total')
    })).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  }
};
export default define().defineGet((key, id) => {
  if (key === 'checked') {
    if (id) {
      return _state.get('allSelected') || _state.get('checked').has(id);
    } else {
      return _state.get('checked');
    }
  } else {
    return _state.get(key);
  }
}).defineResponseTo(GRID_NUMBER_EDITABLE_UPDATED, ({
  numberEditable
}) => _state = _state.set('numberEditable', numberEditable)).defineResponseTo(GRID_CHECKED, ({
  ids,
  isChecked
}) => {
  let checked = _state.get('checked');
  if (isChecked) {
    checked = checked.union(ids);
  } else {
    checked = checked.subtract(ids);
  }
  return _state = _state.merge({
    checked,
    allSelected: false
  });
}).defineResponseTo(GRID_ALL_SELECTED, ({
  ids,
  filters
}) => {
  const newState = {
    allSelected: true
  };
  if (ids) {
    newState.checked = ids;
  }
  _state = _state.merge(newState);
  return _checkEditable(filters);
}).defineResponseTo(
// This action merges the responses to GRID_SEARCH_UPDATED, GRID_SEARCH_QUERY_UPDATED, and GRID_RESET
// into one response that clears the store and sets both query and displayQuery. The three actions are
// only ever fired at the same time in the index route loader helpers, so this is logically identical.
UPDATE_GRID_SEARCH_QUERIES, ({
  query,
  objectTypeId
}) => {
  _state = _getInitialState().merge({
    query,
    displayQuery: query,
    objectType: objectTypeId,
    checked: ImmutableSet(),
    allSelected: false,
    numberEditable: 0
  });
  if (objectTypeId != null) {
    updateUIState({
      objectType: objectTypeId,
      key: 'query',
      value: query
    });
    return _state = _state.setIn(['views', objectTypeId, 'query'], query).setIn(['views', objectTypeId, 'displayQuery'], query);
  }
  return undefined;
}).defineResponseTo(GRID_SEARCH_UPDATED, ({
  query,
  objectType
}) => {
  _state = _state.merge({
    query,
    checked: ImmutableSet(),
    allSelected: false,
    numberEditable: 0
  });
  if (objectType != null) {
    updateUIState({
      objectType,
      key: 'query',
      value: query
    });
    return _state = _state.setIn(['views', objectType, 'query'], query);
  }
  return undefined;
}).defineResponseTo(GRID_SEARCH_QUERY_UPDATED, ({
  displayQuery,
  objectType
}) => {
  _state = _state.merge({
    displayQuery,
    checked: ImmutableSet(),
    allSelected: false,
    numberEditable: 0
  });
  if (objectType != null) {
    updateUIState({
      objectType,
      key: 'query',
      value: displayQuery
    });
    return _state = _state.setIn(['views', objectType, 'query'], displayQuery);
  }
  return undefined;
}).defineResponseTo(GRID_SELECTED_CLEARED, () => _state = _state.merge({
  checked: ImmutableSet(),
  allSelected: false,
  numberEditable: 0
})).defineResponseTo(GRID_CLEANUP_SELECTED, ids => {
  _state = _state.update('checked', checked => checked.filter(id => ids.includes(id)));
  return _state;
}).defineResponseTo(GRID_SHOWING_CHANGED, ({
  ids
}) => {
  const currentChecked = _state.get('checked');
  _state = _state.set('checked', currentChecked.intersect(ids));
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  return _checkEditable();
}).defineResponseTo(GRID_RESET, options => {
  if (options == null) {
    options = {};
  }
  options.query = _state.get('query');
  return _state = _getInitialState().merge(options);
}).defineResponseTo(GRID_TEMPORARY_INCLUDE_ID, id => {
  const currentIds = _state.getIn(['temporaryIds', 'include']);
  const updatedIds = ImmutableSet.of(id).concat(currentIds);
  return _state = _state.setIn(['temporaryIds', 'include'], updatedIds);
}).defineResponseTo([ActionTypes.CONTACT_CREATED], contact => {
  const vid = contact.get('vid');
  const currentIds = _state.getIn(['temporaryIds', 'include']);
  const updatedIds = ImmutableSet.of(vid).concat(currentIds);
  return _state = _state.setIn(['temporaryIds', 'include'], updatedIds);
}).defineResponseTo([ActionTypes.COMPANY_CREATED], company => {
  const companyId = company.get('companyId');
  const currentIds = _state.getIn(['temporaryIds', 'include']);
  const updatedIds = ImmutableSet.of(companyId).concat(currentIds);
  return _state = _state.setIn(['temporaryIds', 'include'], updatedIds);
}).defineResponseTo(GRID_TEMPORARY_EXCLUDE_IDS, ids => {
  const currentIds = _state.getIn(['temporaryIds', 'exclude']);
  _state = _state.setIn(['temporaryIds', 'exclude'], currentIds.union(ids));
  return _state;
}).defineResponseTo(GRID_TEMPORARY_RESET, () => _state = _state.setIn(['temporaryIds', 'include'], ImmutableSet())).defineResponseTo(GRID_TEMPORARY_SET, ids => _state = _state.set('temporaryIds', ids)).defineResponseTo(GRID_SAVED_FILTER_CHANGED, ({
  viewId,
  objectType,
  pageType
}) => {
  _state = _state.setIn(['views', objectType, 'viewId'], viewId);
  return _state = _state.setIn(['views', objectType, 'pageType'], pageType || INDEX);
}).register();