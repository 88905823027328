'use es6';

import dispatcher from 'dispatcher/dispatcher';
import { CRM_OBJECTS } from 'crm_data/actions/ActionNamespaces';
import { fetch } from 'crm_data/crmObjects/CrmObjectAPI';
import { defineLazyKeyStore, registerLazyKeyService } from 'crm_data/store/LazyKeyStore';
import { CRM_OBJECTS_UPDATE_STARTED, CRM_OBJECTS_UPDATE_SUCCEEDED, CRM_OBJECTS_UPDATE_FAILED } from 'crm_data/actions/ActionTypes';
import toString from 'transmute/toString';
import { setProperty, getProperty } from 'customer-data-objects/model/ImmutableModel';
registerLazyKeyService({
  namespace: CRM_OBJECTS,
  fetch
});
export default defineLazyKeyStore({
  namespace: CRM_OBJECTS,
  idIsValid: id => typeof id === 'string',
  idTransform: toString
}).defineResponseTo([CRM_OBJECTS_UPDATE_STARTED, CRM_OBJECTS_UPDATE_SUCCEEDED], (state, {
  id,
  nextProperties
}) => {
  if (!state.has(id)) {
    return state;
  }
  return state.updateIn([id], crmObject => {
    return nextProperties.reduce((acc, value, name) => {
      return setProperty(acc, name, value);
    }, crmObject);
  });
}).defineResponseTo(CRM_OBJECTS_UPDATE_FAILED, (state, {
  id,
  nextProperties,
  properties
}) => {
  return state.updateIn([id], object => {
    return properties.reduce((acc, value, name) => {
      if (nextProperties.get(name) !== getProperty(acc, name)) {
        return acc;
      }
      return setProperty(acc, name, value);
    }, object);
  });
}).defineName('CrmObjectStore').register(dispatcher);