import { LEGACY_SIDEBAR_CARD_TYPES } from '../../constants/LegacySidebarCardType';

// Legacy card should not have config type or query fragments
// Extra props on legacy card
// Legacy Sidebar Card Metadata
export const DEAL_SPLITS_METADATA = {
  cardType: 'DEAL_SPLITS',
  legacySidebarCardType: LEGACY_SIDEBAR_CARD_TYPES.DealSplitsCard,
  hasLegacyExtraProps: true,
  renderAsLegacyCardInCrmLocationResolver: true
};