import { useEffect, useState, useCallback } from 'react';
import { getResizeObserverManager } from '../getResizeObserverManager';
import emptyFunction from 'react-utils/emptyFunction';
export const useElementDimensions = ({
  target,
  onResize
}) => {
  const observerManager = getResizeObserverManager();
  const [elementDimensions, setElementDimensions] = useState({});

  // set initial dimensions
  useEffect(() => {
    if (!target) {
      return;
    }
    const contentRect = target.getBoundingClientRect();
    setElementDimensions({
      height: contentRect.height,
      width: contentRect.width
    });
  }, [target]);
  useEffect(() => {
    if (!target) {
      return emptyFunction;
    }
    let isSubscribed = true;
    const callback = entry => {
      if (!isSubscribed) {
        return;
      }
      setElementDimensions({
        height: entry.contentRect.height,
        width: entry.contentRect.width
      });
      if (onResize) {
        onResize(entry, observerManager);
      }
    };
    observerManager.subscribe(target, callback);
    return () => {
      isSubscribed = false;
      observerManager.unsubscribe(target);
    };
  }, [observerManager, onResize, target]);
  const resetElementDimensions = useCallback(() => {
    setElementDimensions({});
  }, []);
  return {
    elementDimensions,
    resetElementDimensions
  };
};