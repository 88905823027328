import { ModalDisplay } from '../types/paymentsInterstitialModal';
import { calculateKYCStatus, hasCompletedStripe } from 'growth-payments-core/eligibility/utils';
import { useHasPaymentLinkBinding } from '../api/SalesCheckoutApi';
import { useEligibilityBinding } from 'growth-payments-core/eligibility/eligibilityBinding';

/**
 * Business logic for the original Embed point experiment.
 */
const useEmbeddedPoint = () => {
  const [eligibility] = useEligibilityBinding();
  const [hasPaymentLink] = useHasPaymentLinkBinding();
  const hasCompletedKyc = hasCompletedStripe(calculateKYCStatus(eligibility && eligibility.underwritingStatus));
  let display = ModalDisplay.NONE;
  if (eligibility && !hasCompletedKyc) {
    display = ModalDisplay.CRM;
  } else if (eligibility && !hasPaymentLink) {
    display = ModalDisplay.PAYMENT_LINK;
  }
  return {
    display
  };
};
export default useEmbeddedPoint;