import { Record, Map as ImmutableMap } from 'immutable';
import pick from 'transmute/pick';
import partial from 'transmute/partial';
import AliasAddress from './AliasAddress';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './Em... Remove this comment to see the full error message
import EmailIntegration from './EmailIntegration';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './Em... Remove this comment to see the full error message
import EmailAliasList from './EmailAliasList';
import { HUBSPOT_HOSTED } from './InboxTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { logError } from 'customer-data-ui-utilities/eventLogging/eventLogger';
const pickAliasAddresses = pick(['address', 'displayableAddress', 'type', 'inboxId', 'disabled', 'conversationsInboxName', 'primary']);
const toAliasAddresses = (accountId, alias) => new AliasAddress(pickAliasAddresses(ImmutableMap(alias)).merge({
  accountId
}));
export default class ConnectedAccount extends Record({
  address: null,
  facsimileInbox: null,
  integration: new EmailIntegration(),
  aliasList: EmailAliasList(),
  shared: false,
  inboxType: null,
  friendlyFromName: null,
  resolvedFromName: null,
  sendFromEmail: null
}, 'ConnectedAccountRecord') {
  hasFacsimileInbox() {
    return !!this.facsimileInbox;
  }
  isShared() {
    return !!this.shared;
  }
  hasEmailIntegration() {
    return !!this.integration.exists();
  }
  isEmailIntegrationEnabled() {
    return this.integration.isEnabled();
  }
  getAliasEmailAddresses() {
    return this.aliasList.aliases.map(partial(toAliasAddresses, this.address));
  }
  isHubSpotHosted() {
    return !!this.inboxType && this.inboxType === HUBSPOT_HOSTED;
  }
  getAliasWithAddress(address) {
    return this.aliasList.aliases.find(alias => {
      return alias.address === address;
    });
  }
  hasAlias(address) {
    return !!this.getAliasWithAddress(address);
  }
  isEnabled(address) {
    const alias = this.getAliasWithAddress(address);
    return !!alias && !alias.disabled;
  }
  getPrimaryAlias() {
    const accountAlias = this.getAliasWithAddress(this.address);
    if (accountAlias) {
      return accountAlias;
    }
    return this.aliasList.aliases.find(alias => alias.primary);
  }
  isPrimaryAlias(address) {
    const alias = this.getAliasWithAddress(address);
    return !!alias && alias.primary;
  }
  updateSignature(address, signature) {
    const idx = this.aliasList.aliases.findIndex(alias => alias.address === address);
    if (idx === -1) {
      logError({
        error: new Error('Could not find alias in aliasList'),
        extraData: {
          aliases: this.aliasList.aliases,
          address,
          signature
        }
      });
      return this;
    }
    const aliases = this.aliasList.aliases.update(idx, alias => alias.set('signature', signature));
    return this.setIn(['aliasList', 'aliases'], aliases);
  }
  static fromJS(json) {
    if (!json || typeof json !== 'object') {
      return null;
    }
    return new ConnectedAccount(Object.assign({}, json, {
      // @ts-expect-error FIXME ts-migrate
      integration: EmailIntegration.fromJS(json.integration),
      // @ts-expect-error FIXME ts-migrate
      aliasList: EmailAliasList.fromJS(json.aliasList)
    }));
  }
}