import partial from 'transmute/partial';
import { Set as ImmutableSet } from 'immutable';
import { compareObjects } from './comparison/ObjectComparison';
import { compareStrings, normalizeString } from './comparison/StringComparison';
import { ProfilePropertyBlocklist, isVisibleProperty as isVisiblePropertyShim } from '../property/PropertyIdentifier';
/**
 * Returns true if property matches the search string.
 *
 * @param  {PropertyRecord}
 * @param  {string}
 * @return {bool}
 */
export function isMatchingProperty(searchString, property) {
  const compare = partial(compareStrings, normalizeString, searchString);
  return compare(property.label) || compare(property.name);
}

/**
 * Returns true if property matches all of the attributes.
 *
 * @param  {PropertyRecord}
 * @param  {attributes}
 * @return {bool}
 */
export function isMatchingPropertyAttributes(attributes, property) {
  return compareObjects(property, attributes);
}

/**
 * Properties that should not be visible on the profile.
 *
 * This is an immutable wrapper for ProfilePropertyBlocklist to maintain
 * backwards compatibility it should never be changed to include/exclude values
 * that aren't in the main blocklist.
 */
export const ProfilePropertyBlacklist = ImmutableSet(ProfilePropertyBlocklist);

/**
 * Returns `true` if the PropertyRecord should be visible to users.
 *
 * This is a shim to maintain backwards API compatibility, this function should
 * never be altered. Change the isVisibileProperty in PropertyIdentifier instead
 *
 * @param  {PropertyRecord}
 * @return {bool}
 */
export const isVisibleProperty = isVisiblePropertyShim;