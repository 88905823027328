import { colorMap, headingFontSizeMap, bodyFontSizeMap } from './typography';
import { useSpacingProps } from '../Layout/useSpacingProps';
export const useTypographyProps = props => {
  const {
    color,
    fontSize,
    isHeading,
    marginBlock,
    marginBlockEnd,
    marginBlockStart,
    marginInline,
    marginInlineEnd,
    marginInlineStart,
    paddingBlock,
    paddingBlockEnd,
    paddingBlockStart,
    paddingInline,
    paddingInlineEnd,
    paddingInlineStart
  } = props;
  const {
    spacingProps
  } = useSpacingProps({
    marginBlock,
    marginBlockEnd,
    marginBlockStart,
    marginInline,
    marginInlineEnd,
    marginInlineStart,
    paddingBlock,
    paddingBlockEnd,
    paddingBlockStart,
    paddingInline,
    paddingInlineEnd,
    paddingInlineStart
  });
  let fontSizeProps;
  const isHeadingFontSize = size => isHeading && fontSize ? fontSize.includes(size) : false;
  if (fontSize && isHeadingFontSize(fontSize)) {
    fontSizeProps = headingFontSizeMap[fontSize];
  } else if (fontSize) {
    fontSizeProps = bodyFontSizeMap[fontSize];
  }
  const typographyProps = Object.assign({
    color: color ? colorMap[color] : undefined,
    typography: fontSizeProps
  }, spacingProps);
  return {
    typographyProps
  };
};