import { gql } from '@apollo/client';
export const ExtensionCardConfigFragment = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"ExtensionItemFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmExtensionCardConfiguration\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"crmExtensionItem\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"appId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"appName\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"appAccessLevel\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"iconUri\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"singleColorIconUri\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"targetFunction\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"title\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"extensionArtifactUrl\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"projectName\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"projectId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"sourceId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"additionalProperties\"}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ExtensionItemFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "CrmExtensionCardConfiguration"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "crmExtensionItem"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "appId"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "appName"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "appAccessLevel"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "iconUri"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "singleColorIconUri"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "targetFunction"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "extensionArtifactUrl"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "projectName"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "projectId"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "sourceId"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "additionalProperties"
            }
          }]
        }
      }]
    }
  }]
});