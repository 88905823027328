'use es6';

import { dispatchImmediate, dispatchQueue } from 'crm_data/dispatch/Dispatch';
import { HIDE_COMMUNICATOR, MAXIMIZE_COMMUNICATOR, MINIMIZE_COMMUNICATOR, SET_COMMUNICATOR_INITIAL_CONTENT, SET_COMMUNICATOR_TRIGGER_LOCATION, SET_COMMUNICATOR_LAUNCH_CONTEXT, SHOW_COMMUNICATOR, SHOW_COMMUNICATOR_WITH_PERFORMANCE_TRACKING, OPEN_FULLSCREEN_COMMUNICATOR, CLOSE_FULLSCREEN_COMMUNICATOR, CLOSE_COMMUNICATOR_ON_SUBJECT_CHANGE, CLEAR_COMMUNICATOR_PERFORMANCE_TRACKING } from 'crm_data/actions/ActionTypes';
export const showCommunicator = (type, modifiers) => {
  dispatchQueue(SHOW_COMMUNICATOR, {
    type,
    modifiers
  });
};
export const setCommunicatorTriggerLocation = triggeredLocationContext => {
  dispatchQueue(SET_COMMUNICATOR_TRIGGER_LOCATION, triggeredLocationContext);
};
export const setCommunicatorLaunchContext = launchContextObject => {
  dispatchQueue(SET_COMMUNICATOR_LAUNCH_CONTEXT, launchContextObject);
};
export const hideCommunicator = () => {
  dispatchImmediate(HIDE_COMMUNICATOR);
};
export const minimizeCommunicator = () => {
  dispatchImmediate(MINIMIZE_COMMUNICATOR);
};
export const maximizeCommunicator = () => {
  dispatchImmediate(MAXIMIZE_COMMUNICATOR);
};
export const openFullScreenCommunicator = () => {
  dispatchImmediate(OPEN_FULLSCREEN_COMMUNICATOR);
};
export const closeFullScreenCommunicator = () => {
  dispatchImmediate(CLOSE_FULLSCREEN_COMMUNICATOR);
};
export const closeCommunicatorOnSubjectChange = () => {
  dispatchImmediate(CLOSE_COMMUNICATOR_ON_SUBJECT_CHANGE);
};
export const clearInitialContent = type => {
  dispatchQueue(SET_COMMUNICATOR_INITIAL_CONTENT, {
    type,
    content: null
  });
};
export const setInitialContent = (type, content) => {
  dispatchQueue(SET_COMMUNICATOR_INITIAL_CONTENT, {
    type,
    content
  });
};
export const showCommunicatorWithPerformanceTracking = (type, performanceStartTime, modifiers) => {
  dispatchQueue(SHOW_COMMUNICATOR_WITH_PERFORMANCE_TRACKING, {
    type,
    performanceStartTime,
    modifiers
  });
};
export const clearCommunicatorPerformanceTracking = () => {
  dispatchQueue(CLEAR_COMMUNICATOR_PERFORMANCE_TRACKING);
};