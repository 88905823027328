'use es6';

import { Map as ImmutableMap } from 'immutable';
import userInfo from 'hub-http/userInfo';
import { decodeSettingsValue, encodeSettingsValue } from 'crm_data/settings/UserSettingsSerialization';
import PortalIdParser from 'PortalIdParser';
import { HubUserAttributesClient, UserAttributesClient } from 'frontend-preferences-client';
const hubUserAttributesClient = HubUserAttributesClient.forCaller('crm_data');
const userAttributesClient = UserAttributesClient.forCaller('crm_data');
export const USER_SETTINGS_API = 'users/v1/app/attributes';
export function getUserId() {
  return userInfo().then(({
    user
  }) => user.user_id);
}
export function normalizeUserSettingsResponse(rawSettings) {
  return rawSettings.attributes.reduce((acc, setting) => Object.prototype.hasOwnProperty.call(setting, 'key') && Object.prototype.hasOwnProperty.call(setting, 'value') ? acc.set(setting.key, decodeSettingsValue(setting.value)) : acc, ImmutableMap());
}
export function fetchUserSetting(keys) {
  const portalId = String(PortalIdParser.get());
  const referenceKeysWithPortalId = {};
  const keysWithPortalId = keys.filter(key => key.includes(portalId)).map(key => {
    const normalizedKey = key.replace(portalId, '');
    referenceKeysWithPortalId[normalizedKey] = key;
    return [normalizedKey, ''];
  });
  const keysWithoutPortalId = keys.filter(key => !key.includes(portalId)).map(key => [key, '']);
  return Promise.all([
  // Fetch settings for keys with portalId.
  keysWithPortalId.length > 0 ? hubUserAttributesClient.batchFetch(Object.fromEntries(keysWithPortalId)).then(data => Object.entries(data).map(([normalizedKey, value]) => ({
    key: referenceKeysWithPortalId[normalizedKey],
    value
  }))) : Promise.resolve([]),
  // Fetch settings for keys without portalId.
  keysWithoutPortalId.length > 0 ? userAttributesClient.batchFetch(Object.fromEntries(keysWithoutPortalId)).then(data => Object.entries(data).map(([key, value]) => ({
    key,
    value
  }))) : Promise.resolve([])]).then(([responseOne, responseTwo]) => normalizeUserSettingsResponse({
    attributes: [...responseOne, ...responseTwo]
  }));
}
export function saveUserSetting(key, value) {
  const portalId = String(PortalIdParser.get());
  const hasPortalId = key.includes(portalId);
  const encodedValue = encodeSettingsValue(value);
  return getUserId().then(userId => {
    const mapResponse = responseValue => new ImmutableMap({
      key,
      userId,
      value: responseValue
    });
    try {
      if (hasPortalId) {
        return hubUserAttributesClient.write(key.replace(portalId, ''), encodedValue).then(mapResponse);
      } else {
        return userAttributesClient.write(key, encodedValue).then(mapResponse);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  });
}