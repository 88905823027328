import { CALL, MEETING, NOTE, TASK } from 'customer-data-objects/engagement/EngagementTypes';
import { LOGGED_CALL, LOGGED_EMAIL, LOGGED_LINKED_IN_MESSAGE, LOGGED_MEETING, LOGGED_POSTAL_MAIL, LOGGED_SMS, LOGGED_WHATS_APP } from 'customer-data-objects/interaction/InteractionTypes';
import I18n from 'I18n';

/**
 * @note EMAIL does not support Engagement History – do not add it here
 */
export const ENGAGEMENT_HISTORY_MAP_V2 = {
  [CALL]: {
    propertyNames: ['hs_activity_type', 'hs_call_body', 'hs_createdate', 'hs_call_direction', 'hs_call_disposition', 'hs_attachment_ids', 'hs_engagement_source', 'hs_timestamp'],
    getSearchPlaceholder: () => I18n.text('components.engagementHistory.searchPlaceholder.CALL')
  },
  [LOGGED_CALL]: {
    propertyNames: ['hs_activity_type', 'hs_attachment_ids', 'hs_call_body', 'hs_call_disposition', 'hs_createdate', 'hs_engagement_source', 'hs_timestamp'],
    getSearchPlaceholder: () => I18n.text('components.engagementHistory.searchPlaceholder.logged-call')
  },
  [LOGGED_EMAIL]: {
    propertyNames: ['hs_attachment_ids', 'hs_createdate', 'hs_engagement_source', 'hs_email_html', 'hs_timestamp'],
    getSearchPlaceholder: () => I18n.text('components.engagementHistory.searchPlaceholder.logged-email')
  },
  [LOGGED_MEETING]: {
    propertyNames: ['hs_activity_type', 'hs_attachment_ids', 'hs_engagement_source', 'hs_meeting_body', 'hs_meeting_start_time', 'hs_meeting_end_time', 'hs_timestamp'],
    getSearchPlaceholder: () => I18n.text('components.engagementHistory.searchPlaceholder.logged-meeting')
  },
  [LOGGED_WHATS_APP]: {
    propertyNames: ['hs_timestamp', 'hs_createdate', 'hs_engagement_source', 'hs_attachment_ids', 'hs_communication_body'],
    getSearchPlaceholder: () => I18n.text('components.engagementHistory.searchPlaceholder.logged-whats-app')
  },
  [NOTE]: {
    propertyNames: ['hs_attachment_ids', 'hs_engagement_source', 'hs_note_body'],
    getSearchPlaceholder: () => I18n.text('components.engagementHistory.searchPlaceholder.NOTE')
  },
  [MEETING]: {
    propertyNames: ['hs_activity_type', 'hs_attachment_ids', 'hs_engagement_source', 'hs_meeting_body', 'hs_meeting_end_time', 'hs_meeting_start_time', 'hs_meeting_title'],
    getSearchPlaceholder: () => I18n.text('components.engagementHistory.searchPlaceholder.MEETING')
  },
  [TASK]: {
    propertyNames: ['hubspot_owner_id', 'hs_engagement_source', 'hs_queue_membership_ids', 'hs_task_body', 'hs_task_priority', 'hs_task_reminders', 'hs_task_send_default_reminders', 'hs_task_subject', 'hs_task_status', 'hs_task_type', 'hs_timestamp'],
    getSearchPlaceholder: () => I18n.text('components.engagementHistory.searchPlaceholder.TASK')
  },
  [LOGGED_POSTAL_MAIL]: {
    propertyNames: ['hs_postal_mail_body', 'hs_postal_mail_type', 'hs_timestamp', 'hs_createdate', 'hs_engagement_source', 'hs_attachment_ids'],
    getSearchPlaceholder: () => I18n.text('components.engagementHistory.searchPlaceholder.logged-postal-mail')
  },
  [LOGGED_LINKED_IN_MESSAGE]: {
    propertyNames: ['hs_timestamp', 'hs_createdate', 'hs_engagement_source', 'hs_attachment_ids', 'hs_communication_body'],
    getSearchPlaceholder: () => I18n.text('components.engagementHistory.searchPlaceholder.logged-linked-in-message')
  },
  [LOGGED_SMS]: {
    propertyNames: ['hs_timestamp', 'hs_createdate', 'hs_engagement_source', 'hs_attachment_ids', 'hs_communication_body'],
    getSearchPlaceholder: () => I18n.text('components.engagementHistory.searchPlaceholder.logged-sms')
  }
};