'use es6';

import { fromJS } from 'immutable';
import { EMAIL, TASK, MEETING, CALL, NOTE, INCOMING_EMAIL } from 'customer-data-objects/engagement/EngagementTypes';
const DEFAULT_QUERY = fromJS({
  offset: 0,
  count: 10,
  filterGroups: [],
  sorts: []
});
const DEFAULT_FILTER = fromJS({
  operator: 'IN',
  property: 'engagement.type',
  values: [EMAIL, TASK, MEETING, CALL, NOTE, INCOMING_EMAIL]
});
const DEFAULT_SORT = fromJS({
  property: 'engagement.id',
  order: 'DESC'
});
const EngagementsSearchAPIQuery = {
  default(query, {
    excludeDefaultFilters
  } = {}) {
    return DEFAULT_QUERY.merge(fromJS(query)).updateIn(['sorts'], sorts => sorts.push(DEFAULT_SORT)).updateIn(['filterGroups'], filterGroups => {
      if (filterGroups.size === 0) {
        filterGroups = filterGroups.push(fromJS({
          filters: []
        }));
      }

      // allow the client to opt out of the default engagement set
      // the backend runs an AND on the supplied filters, so mismatched sets
      // will result in "missing" engagements - CRM-12391
      if (excludeDefaultFilters) {
        return filterGroups;
      }
      return filterGroups.map(filters => {
        const newFilters = filters.get('filters').push(DEFAULT_FILTER);
        return filters.set('filters', newFilters);
      });
    });
  }
};
export default EngagementsSearchAPIQuery;