'use es6';

import { Map as ImmutableMap, List, fromJS, Set as ImmutableSet } from 'immutable';
import always from 'transmute/always';
import ifElse from 'transmute/ifElse';
import invariant from 'react-utils/invariant';
import PortalIdParser from 'PortalIdParser';
import { DEALS_REFRESH_QUEUED, DEALS_UPDATED, DEALS_UPDATE_FAILED, DEALS_UPDATE_STARTED, DEALS_UPDATE_SUCCEEDED, DEAL_CREATED, DEAL_PIPELINE_SEARCH_RESULT_EXPIRED, ASSOCIATIONS_REFRESH_QUEUED, OBJECT_ASSOCIATION_SUCCEEDED } from 'crm_data/actions/ActionTypes';
import { dispatchImmediate, dispatchQueue } from 'crm_data/dispatch/Dispatch';
import * as DealsAPI from 'crm_data/deals/api/DealsAPI';
import { getId, getProperty } from 'customer-data-objects/model/ImmutableModel';
import { DEAL_TO_COMPANY, DEAL_TO_CONTACT, DEAL_TO_LINE_ITEM, DEAL_TO_QUOTE, DEAL_TO_TICKET } from 'crm_data/associations/AssociationTypes';
import { DEAL } from 'customer-data-objects/constants/ObjectTypes';
import * as ImmutableModel from 'customer-data-objects/model/ImmutableModel';
const PROPS = 'properties';
const ASSOC_COMPANY_ID = 'associatedcompanyid';
const ASSOC_CONTACT_ID = 'associatedcontactid';
const emptyArray = always([]);
const toIdArray = (...ids) => ids;
const toId = (dealRecord, prop) => parseInt(getProperty(dealRecord, prop), 10);
const toAssociatedIds = (dealRecord, prop) => ifElse(isNaN, emptyArray, toIdArray, toId(dealRecord, prop));
const toProperties = dealRecord => dealRecord.get(PROPS).reduce((list, {
  value,
  sourceId,
  source
}, name) => {
  if (name === ASSOC_CONTACT_ID || name === ASSOC_COMPANY_ID) return list;
  return list.push({
    value,
    name,
    sourceId,
    source
  });
}, List());
const getCreateDealPayload = dealRecord => {
  const portalId = PortalIdParser.get();
  const associatedCompanyIds = toAssociatedIds(dealRecord, ASSOC_COMPANY_ID);
  let associatedVids = toAssociatedIds(dealRecord, ASSOC_CONTACT_ID);
  if (dealRecord.hasIn(['associations', 'associatedVids'])) {
    associatedVids = [...associatedVids, ...dealRecord.getIn(['associations', 'associatedVids']).toJS()];
  }
  const associations = {
    associatedCompanyIds,
    associatedVids
  };
  dealRecord = dealRecord.deleteIn([PROPS, ASSOC_COMPANY_ID]).deleteIn([PROPS, ASSOC_CONTACT_ID]);
  const properties = toProperties(dealRecord);
  return fromJS({
    properties,
    portalId,
    associations
  });
};
const makeAssociationKey = (id, associationType) => {
  return ImmutableMap({
    objectId: id,
    objectType: DEAL,
    associationType
  });
};
export function createDeal(dealRecord) {
  const payload = getCreateDealPayload(dealRecord);
  return DealsAPI.createDeal(payload).then(response => {
    const deal = response.first();
    dispatchImmediate(DEAL_CREATED, deal);
    setTimeout(() => dispatchQueue(DEAL_PIPELINE_SEARCH_RESULT_EXPIRED, deal), 2000);
    return deal;
  });
}
export function createDealWithAssociations(dealRecord, {
  requestedAssociatedObjects
}) {
  const propertyValues = toProperties(dealRecord);
  return DealsAPI.createDealWithAssociations({
    propertyValues,
    requestedAssociatedObjects
  }).then(response => {
    const deal = response.first();
    dispatchImmediate(DEAL_CREATED, deal);
    requestedAssociatedObjects.forEach(association => {
      dispatchQueue(OBJECT_ASSOCIATION_SUCCEEDED, {
        objectId: association.objectId,
        associationsAdded: [ImmutableModel.getId(deal)],
        fromObjectType: association.objectType,
        toObjectType: DEAL
      });
    });
    setTimeout(() => dispatchQueue(DEAL_PIPELINE_SEARCH_RESULT_EXPIRED, deal), 2000);
    return deal;
  });
}
export function deleteDeal(dealId, callback) {
  DealsAPI.deleteDeal(dealId).then(() => {
    setTimeout(() => {
      dispatchImmediate(DEALS_UPDATED, ImmutableMap().set(`${dealId}`, null));
    }, 0);
    return typeof callback === 'function' ? callback() : undefined;
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return undefined;
}
export function refresh(ids) {
  dispatchQueue(DEALS_REFRESH_QUEUED, ImmutableSet(ids));
  dispatchQueue(ASSOCIATIONS_REFRESH_QUEUED, ImmutableSet([makeAssociationKey(ids[0], DEAL_TO_COMPANY), makeAssociationKey(ids[0], DEAL_TO_CONTACT), makeAssociationKey(ids[0], DEAL_TO_LINE_ITEM), makeAssociationKey(ids[0], DEAL_TO_QUOTE), makeAssociationKey(ids[0], DEAL_TO_TICKET)]));
}
export function updateDealProperties(deal, maybeNextProperties, options) {
  if (options == null) {
    options = {};
  }
  const id = `${getId(deal)}`;
  const nextProperties = maybeNextProperties instanceof Map ? ImmutableMap(maybeNextProperties.entries()) : maybeNextProperties;
  const properties = nextProperties.map((_, name) => getProperty(deal, name));
  dispatchImmediate(DEALS_UPDATE_STARTED, {
    id,
    nextProperties,
    properties
  });
  return DealsAPI.updateDealProperties(deal, nextProperties).then(updatedDeal => dispatchImmediate(DEALS_UPDATE_SUCCEEDED, {
    id,
    prevProperties: properties,
    properties: nextProperties,
    deal: updatedDeal
  }), error => {
    dispatchImmediate(DEALS_UPDATE_FAILED, {
      error,
      id,
      nextProperties,
      properties
    });
    throw error;
  });
}
export function updateDeals(deals) {
  invariant(ImmutableMap.isMap(deals), 'DealsActions: expected deals to be a Map but got `%s`', deals);
  return dispatchImmediate(DEALS_UPDATED, deals);
}