module.exports = {
  "subscriptionInteraction": {
    "name": "commerce-billing",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "buttonType": {
        "type": [
          "BILLING_WIZARD",
          "PAYMENT_LINK"
        ],
        "isOptional": true
      }
    },
    "namespace": "commerce-billing",
    "version": "1"
  }
};