'use es6';

import { Record, Map as ImmutableMap, Set as ImmutableSet, List } from 'immutable';
const BaseInteractionRecord = Record({
  engagement: ImmutableMap(),
  associations: ImmutableMap(),
  attachments: List(),
  metadata: ImmutableMap({
    body: '',
    plainText: ''
  }),
  inviteeEmails: ImmutableSet(),
  savable: false,
  queueId: null,
  richTextEditorResults: null,
  internalRichTextEditorResults: null,
  rawEditorContent: null,
  rawInternalNoteEditorContent: null,
  selectedFollowUpCategory: null,
  includeFollowUpTask: null
}, 'InteractionRecord');
export default class InteractionRecord extends BaseInteractionRecord {
  setEngagementType(type) {
    const engagement = this.engagement.merge({
      type
    });
    return this.set('engagement', engagement);
  }
  setOwnerId(ownerId) {
    if (ownerId === -1) {
      return this;
    }
    const engagement = this.engagement.merge({
      ownerId
    });
    return this.set('engagement', engagement);
  }
  setTeamId(teamId) {
    const engagement = this.engagement.merge({
      teamId
    });
    return this.set('engagement', engagement);
  }
  setObjectAssociation(objectAssociation) {
    const associations = this.associations.mergeDeep(objectAssociation);
    return this.set('associations', associations);
  }
  setLoggedFrom(loggedFrom) {
    return this.setIn(['metadata', 'loggedFrom'], loggedFrom);
  }
}