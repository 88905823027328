function getKeysToIgnore(ignoredFields) {
  return ignoredFields.filter(field => !field.includes('.')).map(field => field.replaceAll('*', ''));
}
function getSubFieldsToIgnore(key, ignoredFields) {
  return ignoredFields.filter(field => field.includes(`${key}.`) || field.includes('*')).map(field => field.replaceAll(`${key}.`, ''));
}
function isCrmCardNode(maybeCard) {
  if (typeof maybeCard === 'object' && maybeCard !== null && Object.hasOwn(maybeCard, 'type')) {
    return maybeCard.type === 'CARD';
  }
  return false;
}
function maybeGetCardType(maybeCard) {
  if (!isCrmCardNode(maybeCard)) {
    return undefined;
  }
  if (maybeCard.definition.cardType === 'LEGACY_SIDEBAR_CARD' && 'cardType' in maybeCard.configuration) {
    return `${maybeCard.configuration.cardType}`;
  }
  return maybeCard.definition.cardType;
}
function locationDeepEquals({
  fasLocationOrSubField,
  gqlLocationOrSubField,
  ignoredFields
}) {
  if (fasLocationOrSubField === gqlLocationOrSubField) {
    return true;
  }
  if (typeof fasLocationOrSubField === 'string' && typeof gqlLocationOrSubField === 'string') {
    try {
      const fasUrl = new URL(fasLocationOrSubField);
      const gqlUrl = new URL(gqlLocationOrSubField);
      // it's okay if the query params don't match as that can happen sometimes, so long as the base URL matches
      return fasUrl.origin === gqlUrl.origin && fasUrl.pathname === gqlUrl.pathname;
    } catch (e /* fields are not both URLs */) {
      // can return false due to === check above failing
      return false;
    }
  }
  if (typeof fasLocationOrSubField !== 'object' || typeof gqlLocationOrSubField !== 'object' || fasLocationOrSubField === null || gqlLocationOrSubField === null) {
    return false;
  }
  const keysToIgnore = getKeysToIgnore(ignoredFields);
  const fasCardTypes = Array.isArray(fasLocationOrSubField) ? fasLocationOrSubField.map(maybeGetCardType).filter(cardType => cardType) : [];
  const gqlCardTypes = Array.isArray(gqlLocationOrSubField) ? gqlLocationOrSubField.map(maybeGetCardType).filter(cardType => cardType) : [];
  const fasOnlyCardTypes = fasCardTypes.filter(cardType => !gqlCardTypes.includes(cardType));
  const filteredFasLocationOrSubField = Array.isArray(fasLocationOrSubField) ? fasLocationOrSubField.filter(item => {
    return item === undefined || item === null || !(maybeGetCardType(item) && fasOnlyCardTypes.includes(maybeGetCardType(item)));
  }) : fasLocationOrSubField;
  const keysFas = Object.keys(filteredFasLocationOrSubField).filter(key => {
    if (keysToIgnore.includes(key)) {
      return false;
    }
    return true;
  });
  const keysGql = Object.keys(gqlLocationOrSubField).filter(key => !keysToIgnore.includes(key));
  if (keysFas.length !== keysGql.length &&
  // it's okay to have extra keys in FAS, we just want to make sure we have all the fields from gql
  keysGql.some(keyGql => !keysFas.includes(keyGql))) {
    return false;
  }
  for (const key of keysGql) {
    if (!keysFas.includes(key) || !locationDeepEquals({
      fasLocationOrSubField: filteredFasLocationOrSubField[key],
      gqlLocationOrSubField: gqlLocationOrSubField[key],
      ignoredFields: getSubFieldsToIgnore(key, ignoredFields)
    })) {
      return false;
    }
  }
  return true;
}
export default locationDeepEquals;