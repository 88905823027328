export const PRIMARY_STATE = 'PRIMARY_STATE';
export const SECONDARY_STATE = 'SECONDARY_STATE';
export const PRIMARY_MODAL_PRIMARY_STATE = 'PRIMARY_MODAL_PRIMARY_STATE';
export const PRIMARY_MODAL_SECONDARY_PRIMARY_EXISTS_STATE = 'PRIMARY_MODAL_SECONDARY_PRIMARY_EXISTS_STATE';
export const PRIMARY_MODAL_SECONDARY_ASSOCIATION_PRIMARY_DOESNT_EXIST_STATE = 'PRIMARY_MODAL_SECONDARY_ASSOCIATION_PRIMARY_DOESNT_EXIST_STATE';
export const REMOVE_PRIMARY_ASSOCIATION_MODAL_STATE = 'REMOVE_PRIMARY_ASSOCIATION_MODAL_STATE';
export const REMOVE_ASSOCIATION_MODAL_PRIMARY_ONLY_ASSOCIATION_STATE = 'REMOVE_ASSOCIATION_MODAL_PRIMARY_ONLY_ASSOCIATION_STATE';
export const REMOVE_SECONDARY_ASSOCIATION_MODAL_STATE = 'REMOVE_SECONDARY_ASSOCIATION_MODAL_STATE';
export const REMOVE_REVERSE_PRIMARY_MODAL_STATE = 'REMOVE_REVERSE_PRIMARY_MODAL_STATE';
export const EDIT_ASSOCIATION_MODAL_STATE = 'EDIT_ASSOCIATION_MODAL_STATE';
export const PRIMARY_MODAL = 'PRIMARY_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';
export const EDIT_MODAL = 'EDIT_MODAL';