import { useCallback, useEffect, useRef } from 'react';
import { DEEP_LINK_KEYS } from '../../../constants/deepLinkSessionStorageKeys';
import { usePortalCurrencies } from '../../../hooks/usePortalCurrencies';
import { COMMERCE_SUBSCRIPTION_SCOPES, hasScope } from '../../../utils/auth';
import { getURLParameter, removeURLParam } from '../../../utils/urls';
import { clearSessionStorageValue, getSessionStorageValue } from '../../../utils/browserStorage';
export function useBillingChoiceModalDeepLinkHandler(callback) {
  const {
    loading,
    supportedCurrencies
  } = usePortalCurrencies();
  const condition = useCallback(() => {
    const hasSubscriptionWrite = hasScope(COMMERCE_SUBSCRIPTION_SCOPES.write);
    const hasValidCurrency = supportedCurrencies.length > 0;
    return Boolean(!loading && hasSubscriptionWrite && hasValidCurrency);
  }, [loading, supportedCurrencies]);
  useDeepLinkHandler(DEEP_LINK_KEYS.SUBSCRIPTION_CREATE_FLOW, condition, callback);
}
export function useDeepLinkHandler(deepLinkKey, condition, onDeepLinkDetected) {
  // we store things in refs to prevent re-running the effect
  // if the callback changes
  const didRun = useRef(false);
  const onDeepLinkDetectedRef = useRef(onDeepLinkDetected);
  const keyRef = useRef(deepLinkKey);
  useEffect(() => {
    onDeepLinkDetectedRef.current = onDeepLinkDetected;
    keyRef.current = deepLinkKey;
  });
  useEffect(() => {
    if (didRun.current) {
      return;
    }
    if (!condition()) {
      return;
    }
    if (!(getSessionStorageValue(keyRef.current) || getURLParameter(keyRef.current))) {
      didRun.current = true;
      return;
    }
    didRun.current = true;
    clearSessionStorageValue(keyRef.current);
    removeURLParam(keyRef.current);
    onDeepLinkDetectedRef.current();
  }, [condition]);
}