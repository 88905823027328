import { registerQuery, useQuery } from 'data-fetching-client';
import Raven from 'raven-js';
import http from 'hub-http/clients/apiClient';
import { Map as ImmutableMap, OrderedMap } from 'immutable';
export const EngagementAssociationsSummaryQuery = registerQuery({
  fieldName: 'engagementAssociationsSummary',
  args: ['limitPerAssociationType', 'objectId', 'objectTypeId'],
  fetcher({
    limitPerAssociationType,
    objectId,
    objectTypeId
  }) {
    return http.post('crm/events/v3/engagement-associations', {
      data: {
        limitPerAssociationType,
        objectId,
        objectTypeId
      }
    });
  }
});
export function useFetchEngagementAssociationsSummary({
  limitPerAssociationType,
  objectId,
  objectTypeId
}) {
  return useQuery(EngagementAssociationsSummaryQuery, {
    onError: error => {
      if (error.networkError && error.networkError.status === 401) {
        // 401s are expected when the user does not have permisisons to view the engagement, but it
        // is still returned by Minkowski
        return;
      }
      Raven.captureException(error, {
        tags: {
          apiError: 'Error fetching engagement associations summary'
        }
      });
    },
    variables: {
      limitPerAssociationType,
      objectId,
      objectTypeId
    }
  });
}

/**
 * Converts the summary associations data into a barebones version of the UniversalAssociationRecord and
 * UniversalAssociationOptionRecord format used by UAS and held in general store.
 *
 * We also add the `isFromSummary` field, allowing us to easily check if the records held on an engagement
 * are from the summary endpoint.
 */
export function getParsedRecordsFromSummary({
  summaryAssociations
}) {
  return Object.entries(summaryAssociations).reduce((acc, [objectTypeId, objectIds]) => {
    const associationOptionRecords = objectIds.reduce((nestedAcc, {
      id
    }) => {
      return nestedAcc.set(`${id}`, ImmutableMap({
        isSelected: true
      }));
    }, OrderedMap());
    return acc.set(objectTypeId, ImmutableMap({
      associationOptionRecords
    }));
  }, OrderedMap()).set('isFromSummary', true);
}