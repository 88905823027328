import { COMPANY, CONTACT, DEAL } from 'customer-data-objects/constants/ObjectTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { isLoading, isEmpty } from 'crm_data/flux/LoadingStatus';
import { isCustomerSummaryEnabledPipeline, isRenewalSummaryEnabledPipeline
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'BizO... Remove this comment to see the full error message
} from 'BizOpsCrmUIComponents/utils/BETDealUtils';
export const canRenderBETContactSummaryTab = ({
  objectType,
  scopes = []
}) => {
  return scopes.includes('bet-contact-summary-access') && objectType === CONTACT;
};
export const canRenderBETCustomerSummaryTab = ({
  objectType,
  scopes = [],
  subject
}) => {
  return scopes.includes('bet-customer-summary-access') && (objectType === DEAL && isCustomerSummaryEnabledPipeline(subject) || objectType === COMPANY);
};
export const canRenderBETRenewalSummaryTab = ({
  objectType,
  scopes = [],
  subject
}) => {
  return objectType === DEAL && scopes.includes('bet-renewal-summary-access') && isRenewalSummaryEnabledPipeline(subject);
};
export const canRenderBETPartnerSharedActivitiesTab = ({
  objectType,
  scopes = [],
  subject,
  dealRegistrations
}) => {
  const isScoped = scopes.includes('partner-shared-view-internal-access') || scopes.includes('partner-deal-registration-read');
  if (!subject || objectType !== DEAL || !isScoped || isLoading(dealRegistrations) || isEmpty(dealRegistrations) || dealRegistrations.size === 0) {
    return false;
  }
  return true;
};