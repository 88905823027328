import Loadable from 'UIComponents/decorators/Loadable';
import { AsyncComponentErrorHandler } from '../helpers/AsyncComponentErrorHandler';
import { AsyncComponentLoadingHandler } from '../helpers/AsyncComponentLoadingHandler';
const EngagementHistoryAsync = Loadable({
  loader: () => import( /* webpackChunkName: "engagement-history-v2" */'./EngagementHistoryV2').then(mod => mod.default),
  LoadingComponent: AsyncComponentLoadingHandler({
    isContextual: true
  }),
  ErrorComponent: AsyncComponentErrorHandler('engagement-history')
});
export default EngagementHistoryAsync;