// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { dispatchQueue } from 'crm_data/dispatch/Dispatch';
import { fromJS } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { ADD_SAMPLE_TIMELINE_ITEMS } from 'crm_data/actions/ActionTypes';
// For sample events, see:
// crm-timeline/crm_ui/timeline/constants/sampleData/sampleTimelineEvents.js
export const addSampleItems = (vid, sampleEvents) => {
  dispatchQueue(ADD_SAMPLE_TIMELINE_ITEMS, {
    vid,
    sampleEvents: fromJS(sampleEvents)
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return undefined;
};