// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { EMPTY, isLoading } from 'crm_data/flux/LoadingStatus';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import OwnersByEmailStore from 'crm_data/owners/OwnersByEmailStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import OwnersByRemoteIdStore from 'crm_data/owners/OwnersByRemoteIdStore';
import getIn from 'transmute/getIn';
const OwnerByTimelineItemDependency = {
  stores: [OwnersByEmailStore, OwnersByRemoteIdStore],
  deref({
    timelineItem
  }) {
    const userId = getIn(['eventData', 'updated-by-user-id'], timelineItem);
    if (!userId) {
      return EMPTY;
    }
    const owner = OwnersByRemoteIdStore.get(userId);

    // The owner API returns an empty array when the owner does not exist. This causing an infinite
    // loading state as the owner value above is set to undefined. Whenever a HubSpot user JITAs
    // into a portal and changes a LifeCycle stage event, this was happening. It adds a level of jank,
    // but is the most applicable solution at the time being.
    // https://issues.hubspotcentral.com/browse/CRM-39536
    if (isLoading(owner)) {
      return EMPTY;
    }
    return owner;
  }
};
export default OwnerByTimelineItemDependency;