import { useEffect, useMemo } from 'react';
import { ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { NOTE, UNSUPPORTED_BUS_MESSAGE_INTERACTION_TYPES } from 'crm-activity-creator-data/interaction/InteractionTypes';
import { useOverrideInteractionTypes } from './useOverrideInteractionTypes';
export const useDefaultInteraction = ({
  queryInteraction,
  isSampleContact = false,
  objectType,
  subjectId,
  taskId,
  initialEmailSubject,
  initialEmailBody
}) => {
  const sendCrmMessage = useSendCrmMessageTopic(TOPIC_NAMES.OPEN_ACTIVITY_CREATOR);
  const overridenInteractionTypes = useOverrideInteractionTypes();

  // We only want this to send the message on page load when an interaction is in the querystring and supported via message bus.
  // All other use cases involving the querystring and the message are covered by callbacks in useCreateActivityInteractionWindow.
  useEffect(() => {
    if (queryInteraction && !UNSUPPORTED_BUS_MESSAGE_INTERACTION_TYPES.includes(queryInteraction)) {
      sendCrmMessage({
        interactionType: queryInteraction,
        objectTypeId: ObjectTypesToIds[objectType] || objectType,
        objectId: Number(subjectId),
        taskId,
        initialEmailSubject,
        initialEmailBody
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return useMemo(() => {
    if (isSampleContact && !queryInteraction) {
      return queryInteraction && NOTE;
    }
    if (queryInteraction && !overridenInteractionTypes.includes(queryInteraction)) {
      return undefined;
    }
    return queryInteraction;
  }, [isSampleContact, overridenInteractionTypes, queryInteraction]);
};
export default useDefaultInteraction;