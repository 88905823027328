'use es6';

// https://stackoverflow.com/a/43640942
export function convertDataURItoFile(dataURI, fileName) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new File([ia], fileName, {
    type: mimeString,
    lastModifiedDate: new Date()
  });
  return blob;
}