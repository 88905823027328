// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import EngagementsStore from 'crm_data/engagements/EngagementsStore';
import PropTypes from 'prop-types';
import PinnedEngagementsStore from '../stores/PinnedEngagementsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { AnyCrmObjectTypePropType } from 'customer-data-objects-ui-components/propTypes/CrmObjectTypes';
import { Map as ImmutableMap } from 'immutable';
import { isLoading, isEmpty, EMPTY, LOADING
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/flux/LoadingStatus';
const PinnedEngagementDependency = {
  propTypes: {
    subjectId: PropTypes.string.isRequired,
    objectType: AnyCrmObjectTypePropType.isRequired
  },
  stores: [PinnedEngagementsStore, EngagementsStore],
  deref({
    objectType,
    subjectId
  }) {
    const pinnedEngagementMetadata = PinnedEngagementsStore.get(ImmutableMap({
      objectType,
      subjectId
    }));
    if (isLoading(pinnedEngagementMetadata)) {
      return LOADING;
    }
    const engagementId = pinnedEngagementMetadata.get('engagementId');
    if (isEmpty(engagementId)) {
      return EMPTY;
    }
    return EngagementsStore.get(engagementId);
  }
};
export default PinnedEngagementDependency;