'use es6';

import { Seq } from 'immutable';
import memoize from 'transmute/memoize';
const KB = 1024;
const MB = Math.pow(KB, 2);
const INVALID_FILE_TYPES = Seq(['ade', 'adp', 'bat', 'chm', 'cmd', 'com', 'cpl', 'exe', 'hta', 'ins', 'isp', 'jar', 'js', 'jse', 'lib', 'lnk', 'mde', 'msc', 'msi', 'msp', 'mst', 'nsh', 'pif', 'scr', 'sct', 'shb', 'sys', 'vb', 'vbe', 'vbs', 'vxd', 'wsc', 'wsf', 'wsh']);
const getFileSizeKey = type => {
  return `customerDataEmail.Attachments.fileSize.${type}`;
};
const invalidFileType = memoize(fileType => {
  return INVALID_FILE_TYPES.contains(fileType);
});
export function getFileSize(file) {
  const size = file.get('size');
  if (size < KB) {
    return {
      key: getFileSizeKey('bytes'),
      value: `${size}`
    };
  }
  if (size < MB) {
    return {
      key: getFileSizeKey('kb'),
      value: (size / KB).toFixed(2)
    };
  }
  return {
    key: getFileSizeKey('mb'),
    value: (size / MB).toFixed(2)
  };
}
export function validateFile(file) {
  const extension = file.get('extension');
  if (invalidFileType(extension)) {
    return {
      key: 'contactInteraction.errors.attachments.invalidType',
      options: {
        extension
      }
    };
  }
  return false;
}