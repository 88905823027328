import { fetchInitialActivities } from 'crm-events-data/internal/timeline/actions/TimelineFetchingActions';
import { buildTypedMap } from 'crm-events-data/types';
import { useEffect } from 'react';

/**
 * These imports are needed to initialize timeline related general-store stores/services before initial timelines events fetch is triggered.
 * Without them stores are not initialized when the action is triggered and the timeline events are not fetched.
 */
import 'crm_data/engagements/EngagementsStore';
import 'crm-events-data/internal/timeline/services/TimelineFetchingService';
import 'crm-events-data/internal/timeline/stores/TimelineMonthsStore';
import 'crm-events-data/internal/timeline/stores/TimelineStatusStore';
import 'crm-events-data/internal/timeline/stores/TimelineEventsStore';
import 'crm-events-data/internal/filters/stores/TimelineFavoriteEventsOverrideStore';
import 'conversations-thread-view-crm-events/email/EmailEngagementBodyStore';
import 'crm-timeline-tasks/data/stores/TaskDependencyStore';
const fetchInitialTimelineEvents = (objectType, subjectId) => {
  const apiState = buildTypedMap({
    subjectId,
    objectType,
    TIMELINE_ITEM_LIMIT: 20
  });
  fetchInitialActivities(apiState);
};
export const useInitialTimelineData = (objectType, subjectId) => {
  useEffect(() => {
    fetchInitialTimelineEvents(objectType, subjectId);
  }, [objectType, subjectId]);
};