import { registerQuery } from 'data-fetching-client';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
export const GET_PROPERTY_DEFINITION = registerQuery({
  fieldName: 'propertyDefinition',
  args: ['objectTypeId', 'propertyName'],
  fetcher: ({
    objectTypeId,
    propertyName
  }) => {
    return getFrameworkDataSchemasClient().then(client => client.properties.getProperty({
      frameworkTypeIdentifier: objectTypeId,
      propertyName,
      query: {
        showHighlySensitiveProperties: true
      }
    }));
  }
});