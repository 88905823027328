import { UNIQUE_VALUE_CONFLICT, INVALID_PROPERTY_VALUE, PIPELINE_ALREADY_SET, INVALID_PROPERTY_VALUE_CHANGE_ON_CLOSED_DEAL, NAME_CHANGE_OF_ACTIVE_COMPANY } from './PropertyUpdateErrorTypes';
import translateObjectTypeName from 'customer-data-objects/record/translateObjectTypeName';
import unescapedText from 'I18n/utils/unescapedText';
import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
import { ERROR, WARNING } from './ErrorSeverity';
import getIn from 'transmute/getIn';
import get from 'transmute/get';
const makeUniqueValueError = ({
  propertyName,
  objectType,
  objectTypeDefinition,
  getProperty
}) => {
  const objectTypeName = translateObjectTypeName({
    isCapitalized: false,
    isPlural: false,
    objectType,
    objectTypeDefinition
  });
  const property = getProperty(propertyName);
  const propertyLabel = get('label', property) || propertyName;
  return {
    message: unescapedText('customerDataProperties.propertyUpdateErrorMessages.uniqueValueConflict', {
      propertyName: propertyLabel,
      objectTypeName
    }),
    severity: ERROR
  };
};
const makeSimpleError = code => {
  switch (code) {
    case PIPELINE_ALREADY_SET:
      {
        return {
          message: unescapedText('customerDataProperties.propertyUpdateErrorMessages.betUpdatePropertiesErrors.pipelineAlreadySet'),
          severity: ERROR
        };
      }
    case INVALID_PROPERTY_VALUE_CHANGE_ON_CLOSED_DEAL:
      {
        return {
          message: unescapedText('customerDataProperties.propertyUpdateErrorMessages.betUpdatePropertiesErrors.invalidPropertyValueChangeOnClosedDeal'),
          severity: ERROR
        };
      }
    case NAME_CHANGE_OF_ACTIVE_COMPANY:
      {
        return {
          message: unescapedText('customerDataProperties.propertyUpdateErrorMessages.betUpdatePropertiesErrors.nameChangeOfActiveCompany'),
          severity: ERROR
        };
      }
    case INVALID_PROPERTY_VALUE:
      {
        return {
          message: unescapedText('customerDataProperties.propertyUpdateErrorMessages.invalidPropertyValue'),
          severity: ERROR
        };
      }
    default:
      return undefined;
  }
};

/**
 * @example
 * save(...)
 *   .catch(error => {
 *     const { message, severity } = getPropertyUpdateError({
 *       error,
 *       objectType,
 *       objectTypeDefinition,
 *       getProperty: propertyName => properties.get(propertyName),
 *     });
 *
 *     FloatingAlertStore.addAlert({
 *       message,
 *       type: severity,
 *       'data-alert-type': severity,
 *     });
 *   });
 *
 * @param {Object} error error from a REST API in response to a property update or object create
 * @param {String} objectType object type string or object type id
 * @param {Object} objectTypeDefinition object type definition loaded from `inbounddb-meta/v1` or graphql
 * @param {Function} getProperty helper that takes a property name and returns a property definition
 * @returns an object containing `message` and `severity` fields. `message` is a string
 * message to display to the user, and `severity` is one of the error types
 * supported by uicomponents' `FloatingAlertStore`.
 */
export const getPropertyUpdateError = ({
  error,
  objectType,
  objectTypeDefinition,
  getProperty
}) => {
  const errorType = getIn(['responseJSON', 'errorType'], error);
  switch (errorType) {
    case UNIQUE_VALUE_CONFLICT:
      {
        return makeUniqueValueError({
          propertyName: getIn(['responseJSON', 'errorTokens', 'propertyName', 0], error),
          objectType,
          objectTypeDefinition,
          getProperty
        });
      }
    default:
      {
        const simpleError = makeSimpleError(errorType);
        if (simpleError) {
          return simpleError;
        }
        const BETPortalId = enviro.isQa() ? 99535353 : 53;
        if (BETPortalId === PortalIdParser.get() && error.xhr && error.xhr.responseText) {
          return {
            message: JSON.parse(error.xhr.responseText).message,
            severity: WARNING
          };
        }
        return {
          message: unescapedText('customerDataProperties.alertUpdatePropertiesError'),
          severity: ERROR
        };
      }
  }
};

/**
 * @example
 * const [save] = useMutation(..., {
 *   onError: error => {
 *     const { message, severity } = getPropertyMutationError({
 *       error,
 *       objectType,
 *       objectTypeDefinition,
 *       getProperty: propertyName => properties.get(propertyName),
 *     });
 *
 *     FloatingAlertStore.addAlert({
 *       message,
 *       type: severity,
 *       'data-alert-type': severity,
 *     });
 *   });
 *
 * @param {Object} error error from graphql in response to a property update or object create
 * @param {String} objectType object type string or object type id
 * @param {Object} objectTypeDefinition object type definition loaded from `inbounddb-meta/v1` or graphql
 * @param {Function} getProperty helper that takes a property name and returns a property definition
 * @returns an object containing `message` and `severity` fields. `message` is a string
 * message to display to the user, and `severity` is one of the error types
 * supported by uicomponents' `FloatingAlertStore`.
 */
export const getPropertyMutationError = ({
  error,
  objectType,
  objectTypeDefinition,
  getProperty
}) => {
  const errorCode = error.code;
  switch (errorCode) {
    case UNIQUE_VALUE_CONFLICT:
      {
        return makeUniqueValueError({
          propertyName: error.propertyName,
          objectType,
          objectTypeDefinition,
          getProperty
        });
      }
    default:
      {
        const simpleError = makeSimpleError(errorCode);
        if (simpleError) {
          return simpleError;
        }
        const BETPortalId = enviro.isQa() ? 99535353 : 53;
        if (BETPortalId === PortalIdParser.get() && error.message) {
          return {
            message: error.message,
            severity: WARNING
          };
        }
        return {
          message: unescapedText('customerDataProperties.alertUpdatePropertiesError'),
          severity: ERROR
        };
      }
  }
};