import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import keyMirror from 'react-utils/keyMirror';
import * as HubLVideoParams from 'FileManagerCore/constants/HubLVideoParams';
// @ts-expect-error bender-url not typed
import model3dPreview from 'bender-url!FileManagerImages/images/ai-styles/3d-model.jpg';
// @ts-expect-error bender-url not typed
import analogPreview from 'bender-url!FileManagerImages/images/ai-styles/analog.jpg';
// @ts-expect-error bender-url not typed
import animePreview from 'bender-url!FileManagerImages/images/ai-styles/anime.jpg';
// @ts-expect-error bender-url not typed
import cinematicPreview from 'bender-url!FileManagerImages/images/ai-styles/cinematic.jpg';
// @ts-expect-error bender-url not typed
import comicBookPreview from 'bender-url!FileManagerImages/images/ai-styles/comic-book.jpg';
// @ts-expect-error bender-url not typed
import digitalArtPreview from 'bender-url!FileManagerImages/images/ai-styles/digital-art.jpg';
// @ts-expect-error bender-url not typed
import enhancePreview from 'bender-url!FileManagerImages/images/ai-styles/enhance.jpg';
// @ts-expect-error bender-url not typed
import fantasyArtPreview from 'bender-url!FileManagerImages/images/ai-styles/fantasy-art.jpg';
// @ts-expect-error bender-url not typed
import isometicPreview from 'bender-url!FileManagerImages/images/ai-styles/isometric.jpg';
// @ts-expect-error bender-url not typed
import lineArtPreview from 'bender-url!FileManagerImages/images/ai-styles/line-art.jpg';
// @ts-expect-error bender-url not typed
import lowPolyPreview from 'bender-url!FileManagerImages/images/ai-styles/low-poly.jpg';
// @ts-expect-error bender-url not typed
import modelingCompoundPreview from 'bender-url!FileManagerImages/images/ai-styles/modeling-compound.jpg';
// @ts-expect-error bender-url not typed
import neonPunkPreview from 'bender-url!FileManagerImages/images/ai-styles/neon-punk.jpg';
// @ts-expect-error bender-url not typed
import origamiPreview from 'bender-url!FileManagerImages/images/ai-styles/origami.jpg';
// @ts-expect-error bender-url not typed
import photographicPreview from 'bender-url!FileManagerImages/images/ai-styles/photographic.jpg';
// @ts-expect-error bender-url not typed
import pixelArtPreview from 'bender-url!FileManagerImages/images/ai-styles/pixel-art.jpg';
// @ts-expect-error bender-url not typed
import tileTexturePreview from 'bender-url!FileManagerImages/images/ai-styles/tile-texture.jpg';
// @ts-expect-error bender-url not typed
import breezeAiPreview from 'bender-url!FileManagerImages/images/ai-styles/breeze-ai.jpg';
export const PAGE_SIZE = 90;
export const SEARCH_DEBOUNCE_MS = 500;
export const Panels = {
  BROWSE: 'BROWSE',
  SEARCH: 'SEARCH',
  STOCK_IMAGES: 'STOCK_IMAGES',
  FOLDER: 'FOLDER',
  FROM_URL: 'FROM_URL',
  FILE_DETAIL: 'FILE_DETAIL',
  BULK_IMPORT_FROM_URL: 'BULK_IMPORT_FROM_URL',
  IMAGE_GENERATION: 'IMAGE_GENERATION',
  LANDING: 'LANDING'
};
export const SortByFilters = {
  UPDATED: '-updated',
  NAME: 'name',
  SIZE: 'size'
};
export const DEFAULT_SORT_BY_FILTER = SortByFilters.UPDATED;
export const CellTypes = keyMirror({
  FILE: null,
  FOLDER: null,
  THUMBNAIL: null,
  EMPTY: null
});
export const IMAGE_COLUMN_COUNT = 3;
export const DEFAULT_ROWS_TO_FETCH = 15;
export const FETCH_FILES_LIMIT = IMAGE_COLUMN_COUNT * DEFAULT_ROWS_TO_FETCH;
export const FILE_FOLDER_THUMB_SIZE = 34;
export const FILE_FOLDER_THUMB_SIZE_EXPANDED = 50; // half of THUMB width (100px) for ideal retina display
export const PICKER_FOLDER_CLASSNAME = 'folder-row';
export const PANEL_WIDTH = 590;
export const PANEL_WIDTH_EXPANDED = PANEL_WIDTH;
export const TrackingPanelLabels = {
  [Panels.BROWSE]: 'browse',
  [Panels.SEARCH]: 'search',
  [Panels.STOCK_IMAGES]: 'stock-images',
  [Panels.FOLDER]: 'browse-folders',
  [Panels.FROM_URL]: 'from-url',
  [Panels.FILE_DETAIL]: 'file-details]',
  [Panels.BULK_IMPORT_FROM_URL]: 'bulk-import-from-url',
  [Panels.IMAGE_GENERATION]: 'image-generation',
  [Panels.LANDING]: 'landing'
};
export const PickerTypes = keyMirror({
  PANEL: null,
  SIDE_MODAL: null
});
export const DrawerTypes = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  HUBL_VIDEO: 'HUBL_VIDEO',
  DOCUMENT: 'DOCUMENT',
  FILE: 'FILE',
  AUDIO: 'AUDIO'
};
export const DrawerTypesToExtensions = ImmutableMap({
  [DrawerTypes.DOCUMENT]: ImmutableSet(['csv', 'doc', 'docx', 'dot', 'dotx', 'key', 'pdf', 'pot', 'potx', 'pps', 'ppsx', 'ppt', 'pptx', 'txt', 'wpd', 'wps', 'wri', 'xls', 'xlsb', 'xlsx', 'xlt', 'xlx', 'xml'])
});
export const UploadStatus = {
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
  PROCESSING: 'PROCESSING',
  NOT_STARTED: 'NOT_STARTED'
};
export const SupportedHubLVideoParams = HubLVideoParams.SupportedHubLVideoParams;
export const LANDING_PANEL_FOLDER_LIMIT = 4;
export const LANDING_PANEL_FILE_LIMIT_GRID = 12;
export const LANDING_PANEL_FILE_LIMIT_LIST = 6;
export const PICKER_HEIGHT_FOR_DEFAULT_LANDING_PANEL_LIMITS = 900;

// These are test ids used in consumers library for their ATs downstream.
// Allows consumers to more easily harden tests and find upstream problems
// faster.
export const EXTERNAL_TEST_IDS = {
  TITLE_CELL: 'tile-cell'
};

// Style options for stability image generation
const PHOTOGRAPHIC = 'photographic';
const MODEL_3D = '3d-model';
const ANALOG_FILE = 'analog-film';
const ANIME = 'anime';
const CINEMATIC = 'cinematic';
const COMIC_BOOK = 'comic-book';
const DIGITAL_ART = 'digital-art';
const ENHANCE = 'enhance';
const FANTASY_ART = 'fantasy-art';
const ISOMETRIC = 'isometric';
const LINE_ART = 'line-art';
const LOW_POLY = 'low-poly';
const MODELING_COMPOUND = 'modeling-compound';
const NEON_PUNK = 'neon-punk';
const ORIGAMI = 'origami';
const PIXEL_ART = 'pixel-art';
const TILE_TEXTURE = 'tile-texture';
export const STABILITY_STYLES = {
  PHOTOGRAPHIC,
  MODEL_3D,
  ANALOG_FILE,
  ANIME,
  CINEMATIC,
  COMIC_BOOK,
  DIGITAL_ART,
  ENHANCE,
  FANTASY_ART,
  ISOMETRIC,
  LINE_ART,
  LOW_POLY,
  MODELING_COMPOUND,
  NEON_PUNK,
  ORIGAMI,
  PIXEL_ART,
  TILE_TEXTURE
};
const STABILITY_STYLE_VALUES = Object.values(STABILITY_STYLES);
export function matchKnownStyle(style) {
  return STABILITY_STYLE_VALUES.find(knownStyle => knownStyle === style) || null;
}
export const STYLE_PREVIEW_IMAGE = {
  [STABILITY_STYLES.MODEL_3D]: model3dPreview,
  [STABILITY_STYLES.ANALOG_FILE]: analogPreview,
  [STABILITY_STYLES.ANIME]: animePreview,
  [STABILITY_STYLES.CINEMATIC]: cinematicPreview,
  [STABILITY_STYLES.COMIC_BOOK]: comicBookPreview,
  [STABILITY_STYLES.DIGITAL_ART]: digitalArtPreview,
  [STABILITY_STYLES.ENHANCE]: enhancePreview,
  [STABILITY_STYLES.FANTASY_ART]: fantasyArtPreview,
  [STABILITY_STYLES.ISOMETRIC]: isometicPreview,
  [STABILITY_STYLES.LINE_ART]: lineArtPreview,
  [STABILITY_STYLES.LOW_POLY]: lowPolyPreview,
  [STABILITY_STYLES.MODELING_COMPOUND]: modelingCompoundPreview,
  [STABILITY_STYLES.NEON_PUNK]: neonPunkPreview,
  [STABILITY_STYLES.ORIGAMI]: origamiPreview,
  [STABILITY_STYLES.PHOTOGRAPHIC]: photographicPreview,
  [STABILITY_STYLES.PIXEL_ART]: pixelArtPreview,
  [STABILITY_STYLES.TILE_TEXTURE]: tileTexturePreview
};
export const breezeAiPreviewImage = breezeAiPreview;