import { useCallback } from 'react';
import { useTimelineDispatch, useTimelineSelector } from '../../reduxHooks';
import { useIsEventCollapsedByDefault } from './useIsEventCollapsedByDefault';
import { makeGetIsEventCardCollapsed, collapse, expand, setIsForcingIntersection } from '../eventCardsSlice';
import { batch } from 'react-redux';
export function useIsEventCollapsed(eventId, {
  onCollapse,
  onExpand
} = {}) {
  const getIsEventCardCollapsed = makeGetIsEventCardCollapsed(eventId);
  const isEventCollapsedByDefault = useIsEventCollapsedByDefault();
  const isCollapsedFromRedux = useTimelineSelector(getIsEventCardCollapsed);
  const dispatch = useTimelineDispatch();
  const setIsEventCollapsed = useCallback(shouldCollapse => {
    if (shouldCollapse) {
      batch(() => {
        dispatch(collapse(eventId));
      });
      if (onCollapse) onCollapse();
    } else {
      batch(() => {
        dispatch(expand(eventId));
        dispatch(setIsForcingIntersection(eventId));
      });
      if (onExpand) onExpand();
    }
  }, [dispatch, eventId, onCollapse, onExpand]);
  const isEventCollapsed = isCollapsedFromRedux === undefined ? isEventCollapsedByDefault : isCollapsedFromRedux;
  return {
    isEventCollapsed,
    setIsEventCollapsed
  };
}