// be wary of adding new imports here (especially hub-http), as it can break quick
// fetch for consumers, be sure to test against the record or another quick fetch
// consumer
import { CRM_LOCATION_NAMES } from 'crm-cards-sdk/constants/CrmLocationName';
import quickFetch from 'quick-fetch';
// keep in sync with useCrmLocationResolverQuery's gate logic until we are done
// with gated rollout
const getLocationFasReadGate = location => {
  if (location === CRM_LOCATION_NAMES.CRM_RECORD_LEFT || location === CRM_LOCATION_NAMES.CRM_RECORD_MIDDLE || location === CRM_LOCATION_NAMES.CRM_RECORD_RIGHT) {
    return 'CRM:card-infra-frontend:fas-read:CRM-RECORD';
  }
  if (location === CRM_LOCATION_NAMES.CRM_OBJECT_PREVIEW) {
    return 'CRM:card-infra-frontend:fas-read:CRM-OBJECT-PREVIEW';
  }
  if (location === CRM_LOCATION_NAMES.HELPDESK_SIDEBAR) {
    return 'CRM:card-infra-frontend:fas-read:HELPDESK-SIDEBAR';
  }
  if (location === CRM_LOCATION_NAMES.PROSPECTING_LEFT || location === CRM_LOCATION_NAMES.PROSPECTING_RIGHT || location === CRM_LOCATION_NAMES.FLYWHEEL_PROSPECTING_SIDEBAR) {
    return 'CRM:card-infra-frontend:fas-read:PROSPECTING';
  }
  return 'CRM:card-infra-frontend:fas-read:general';
};
export const getCardLocationQueryUrl = ({
  objectTypeId,
  objectId,
  location
}) => {
  return `/crm-record-cards/v3/record-cards/${objectTypeId}/for-object/${objectId}/for-location/${location}`;
};
export const getCrmLocationQuickFetchKey = ({
  objectTypeId,
  objectId,
  location
}) => `crm-location-quick-fetch:REST:${location}:${objectTypeId}:${objectId}`;
export const quickFetchCrmLocation = queryVariables => {
  const url = quickFetch.getApiUrl(getCardLocationQueryUrl(queryVariables), true);
  const quickFetchKey = getCrmLocationQuickFetchKey(queryVariables);
  quickFetch.makeEarlyRequest(quickFetchKey, {
    url,
    timeout: 5000,
    dataType: 'json'
  });
};
export const quickFetchCrmLocationWhenUngated = queryVariables => {
  quickFetch.afterAuth(authData => {
    const {
      enabled_v3_gates
    } = authData === null || authData === void 0 ? void 0 : authData.portal;
    if (enabled_v3_gates && enabled_v3_gates.includes(getLocationFasReadGate(queryVariables.location))) {
      quickFetchCrmLocation(queryVariables);
    }
  });
};