/* hs-eslint ignored failing-rules */

'use es6';

import isFunction from 'transmute/isFunction';
import emptyFunction from 'react-utils/emptyFunction';
import Promptable from 'UIComponents/decorators/Promptable';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
import DeleteDialog from 'customer-data-ui-utilities/dialog/DeleteDialog';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
const prompt = Promptable(DeleteDialog);
export default (props => {
  const {
    confirmLabel,
    rejectLabel,
    message,
    match,
    matchLabel,
    title,
    gdprEnabled,
    gdprDeletePossible,
    isScopedForGdprDelete,
    objectType,
    dialogBody,
    dialogNote,
    hasNoFilters,
    gdprNote
  } = props;
  const callback = isFunction(props.callback) ? props.callback : emptyFunction;
  const onReject = props.onReject || rethrowError;
  prompt({
    confirmLabel,
    gdprEnabled: gdprEnabled && objectType === CONTACT,
    gdprDeletePossible,
    isScopedForGdprDelete,
    rejectLabel,
    message,
    match,
    matchLabel,
    title,
    dialogBody,
    dialogNote,
    onReject,
    hasNoFilters,
    objectType,
    gdprNote
  }).then(callback, onReject).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
});