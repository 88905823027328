// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { dispatchImmediate } from 'crm_data/dispatch/Dispatch';
import { ENGAGEMENT_HISTORY_CLEARED, ENGAGEMENT_HISTORY_UPDATED } from '../stores/EngagementHistoryStore';
import { Map as ImmutableMap } from 'immutable';
export const clearEngagementHistory = () => {
  dispatchImmediate(ENGAGEMENT_HISTORY_CLEARED);
};
export const updateEngagementHistory = (engagementOrInteractionType, objectId, objectType) => {
  dispatchImmediate(ENGAGEMENT_HISTORY_UPDATED, ImmutableMap({
    engagementOrInteractionType,
    objectId,
    objectType
  }));
};