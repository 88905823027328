import PinnedEngagementsStore from 'crm-events-data/internal/pinnedEngagements/stores/PinnedEngagementsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { closePlaybook } from 'crm_data/playbooks/actions/PlaybooksActions';
import { COMPANY, CONTACT, DEAL, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import { isLegacyObjectType } from 'customer-data-objects/types/LegacyObjectType';
import { Map as ImmutableMap } from 'immutable';
import { useEffect, useRef } from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
import AssociatedOpenObjectsStore from 'crm-activity-creator-interactions-legacy/crm_ui/engagementAssociations/AssociatedOpenObjectsStore';
// @ts-expect-error File not migrated yet
import { closeCommunicatorOnSubjectChange } from 'customer-data-communicator/data/communicator/actions/CommunicatorActions';
import { useHistory, useLocation } from 'react-router-dom';
const getOpenObjectsKeys = (objectType, subjectId) => [DEAL, TICKET].map(objectTypeToFetch => ImmutableMap({
  objectType,
  subjectId,
  objectTypeToFetch
}));
function preloadSubjectStores(objectType, subjectId) {
  // TODO COBJECT RECORD: address the below stores
  const isCrmObject = !isLegacyObjectType(objectType);
  if (isCrmObject) {
    return;
  }
  PinnedEngagementsStore.get(ImmutableMap({
    objectType,
    subjectId
  }));
  if (objectType === CONTACT || objectType === COMPANY) {
    AssociatedOpenObjectsStore.get(getOpenObjectsKeys(objectType, String(subjectId)));
  }
}

/**
 * HACK: This is mimicking the way NAVIGATE would have dispatched in RecordHistoryListener.
 * (https://git.hubteam.com/HubSpot/CRM/blob/3acc94cda8ce104f9eb0d3bde6ef3ba7347e7d34/crm-records-ui/static/js/router/RecordHistoryListener.js)
 *
 * The communicator depends on an immediate dispatch to clear its store, otherwise
 * we run into race conditions where CommunicatorStore isn't cleared before the communicator
 * grabs the interaction from localstorage. This would leave the communicator open between record
 * navigations.
 */
const useCloseCommunicatorOnLocationChange = () => {
  const history = useHistory();
  const location = useLocation();
  const prevLocationRef = useRef(location.pathname);
  useEffect(() => {
    const removeHistoryListener = history.listen(nextLocation => {
      if (nextLocation.pathname !== prevLocationRef.current) {
        closeCommunicatorOnSubjectChange();
        prevLocationRef.current = nextLocation.pathname;
      }
    });
    return () => {
      removeHistoryListener();
    };
  }, [history]);
};

/**
 * This hook is used to initialize any general stores that are specific
 * to the current subject object for the record page. If the object Type
 * and subject id changes, then these stores need to be reinitialized.
 *
 * This hook is also used to replace some functionality done by the
 * now-deprecated NAVIGATE action that would fire whenever the page
 * URL changed.
 */
export function useSetStateForSubject(objectType, subjectId) {
  useCloseCommunicatorOnLocationChange();
  useEffect(() => {
    // Record Pages should always start with the playbook overlay closed
    closePlaybook();
    preloadSubjectStores(objectType, subjectId);
  }, [objectType, subjectId]);
}