import { List } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import ContactsStore from 'crm_data/contacts/ContactsStore';
import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import memoize from 'transmute/memoize';
const getContactIds = memoize(dataObj => {
  const contactOptionRecords = dataObj.getIn(['associations', 'universalAssociations', CONTACT_TYPE_ID, 'associationOptionRecords'], List());
  if (!contactOptionRecords.size) {
    return List();
  }
  const selectedOptions = contactOptionRecords.filter(option => option.get('isSelected'));
  return selectedOptions.keySeq().toList();
});
const AssociatedEngagementContactsDependency = {
  stores: [ContactsStore],
  deref(props) {
    const dataObj = props.interaction || props.engagement;
    const contactIds = getContactIds(dataObj);
    const contacts = ContactsStore.get(contactIds);
    if (!List.isList(contacts)) {
      return List();
    }
    return contacts;
  }
};
export default AssociatedEngagementContactsDependency;