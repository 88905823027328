import PortalIdParser from 'PortalIdParser';
export let GlobalGrowthPromptTypes;
(function (GlobalGrowthPromptTypes) {
  GlobalGrowthPromptTypes["PAYMENTS_EMBED_POINT_REMINDER"] = "PAYMENTS_EMBED_POINT_REMINDER";
  GlobalGrowthPromptTypes["PAYMENTS_ENROLL_NUDGE"] = "PAYMENTS_ENROLL_NUDGE";
})(GlobalGrowthPromptTypes || (GlobalGrowthPromptTypes = {}));
const currentDate = new Date();
const enrollNudgeTriggerDate = currentDate.setDate(currentDate.getDate() + 3);
export const enrollNudgeRequestParams = {
  modal: GlobalGrowthPromptTypes.PAYMENTS_ENROLL_NUDGE,
  activateOnTimestamp: enrollNudgeTriggerDate.valueOf(),
  portalId: PortalIdParser.get()
};