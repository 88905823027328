import { LOGGED_CALL, LOGGED_EMAIL, LOGGED_LINKED_IN_MESSAGE, LOGGED_MEETING, LOGGED_POSTAL_MAIL, LOGGED_SMS, LOGGED_WHATS_APP } from 'customer-data-communicator/schema/interactions/InteractionTypes';
import { CALL, CONVERSATION_SESSION, EMAIL, FORWARDED_EMAIL, INCOMING_EMAIL, MEETING, NOTE, TASK, SMS, WHATS_APP, CUSTOM_CHANNEL_CONVERSATION } from 'customer-data-objects/engagement/EngagementTypes';
import { ETypes, eventEngagement } from 'crm-events-data';
import { callConfig } from 'call-timeline-event/callConfigAsync';
import { loggedCallConfig } from 'call-timeline-event/loggedCallConfigAsync';
import { loggedPostalMailConfig } from 'crm-events/postalMail';
import { taskConfig } from 'crm-timeline-tasks/taskConfigAsync';
import { customBehavioralConfig } from 'crm-events/customBehavioralEvent';
import { integrationsConfig, salesforceSyncConfig, surveyMonkeyConfig, twitterConfig, webinarAttendanceConfig, webinarRegistrationConfig, wistiaConfig } from 'crm-events/integrations';
import { conversationSessionConfigAsync } from 'conversations-thread-view-crm-events/config/conversationSessionConfigAsync';
import { emailConfigAsync } from 'conversations-thread-view-crm-events/config/emailConfigAsync';
import { forwardedEmailConfigAsync } from 'conversations-thread-view-crm-events/config/forwardedEmailConfigAsync';
import { incomingEmailConfigAsync } from 'conversations-thread-view-crm-events/config/incomingEmailConfigAsync';
import { loggedEmailConfigAsync } from 'conversations-thread-view-crm-events/config/loggedEmailConfigAsync';
import { loggedLinkedInMessageConfig } from 'conversations-thread-view-crm-events/config/loggedLinkedInMessageConfigAsync';
import { loggedSMSConfigAsync } from 'conversations-thread-view-crm-events/config/loggedSMSConfigAsync';
import { loggedWhatsAppConfigAsync } from 'conversations-thread-view-crm-events/config/loggedWhatsAppConfigAsync';
import { smsConfigAsync } from 'conversations-thread-view-crm-events/config/smsConfigAsync';
import { whatsAppConfigAsync } from 'conversations-thread-view-crm-events/config/whatsAppConfigAsync';
import { emailSendConfig } from 'conversations-thread-view-crm-events/config/emailSendConfig';
import { emailUnbounceConfig } from 'conversations-thread-view-crm-events/config/emailUnbounceConfig';
import { emailTrackingConfig } from 'conversations-thread-view-crm-events/config/emailTrackingConfig';
import { emailOptOutConfig, whatsAppCommunicationSubscriptionStatusChangedConfig, smsCommunicationSubscriptionStatusChangedConfig } from 'crm-events/communicationSubscription';
import { formSubmissionConfig } from 'crm-events/formSubmission';
import { pageViewRollupConfig } from 'crm-events/pageViewActivity';
import { loggedMeetingConfig } from 'crm-meetings-components-lib/loggedMeetingConfigAsync';
import { meetingConfig } from 'crm-meetings-components-lib/meetingConfigAsync';
import { renderedEventConfig } from 'crm-events/renderedEvent';
import { sequencesConfig } from 'sequences-crm-events-lib';
import { noteConfig } from 'crm-events/note';
import { partnerProofOfInvolvementConfig } from 'partner-poi-timeline-event';
import { paymentFailedConfig, paymentInitiatedConfig, paymentSucceededConfig, paymentRefundFailedConfig, paymentRefundProcessingConfig, paymentRefundSucceededConfig } from 'crm-events/payment';
import { dealSplitUpdatedConfig, dealSplitDeletedConfig, dealSplitCreatedConfig } from 'crm-events/dealSplit';
import { marketingEventConfig } from 'crm-events/marketingEvent';
import { mediaBridgeConfig } from 'crm-events/mediaBridge';
import { adInteractionConfig } from 'crm-events/adInteraction';
import { automationPlatformConfig, workflowConfig } from 'crm-events/workflow';
import { ctaConfig, webInteractiveConfig } from 'crm-events/cta';
import { analyticConfig } from 'crm-events/analytic';
import { dealCreatedConfig, dealstageChangeConfig, lifecycleStageConfig, stageChangeConfig, ticketCreatedConfig, ticketStageChangeConfig } from 'crm-events/stage';
import { feedbackSubmissionConfig } from 'crm-events/feedbackSubmission';
import { listMembershipConfig } from 'crm-events/listMembership';
import { quoteConfig } from 'quotes-crm-events-lib';
import { mergeConfig } from 'crm-events/merge';
import { privacyConsentConfig } from 'crm-events/privacyConsent';
import { salesDocumentActivityConfig } from 'crm-events/salesDocumentActivity';
import { socialConfig } from 'social-components-lib/timelineInteraction';
/**
 * @reminder keep in sync with https://paper.dropbox.com/doc/Portals-with-Timeline-Events--BDAdWNyAzsWyahTCKUWt7_YWAg-CUPPdxTTMwzxvfNCVojZ3
 */
export const CompactEventConfig = {
  [eventEngagement]: {
    [CALL]: callConfig,
    [CONVERSATION_SESSION]: conversationSessionConfigAsync,
    [EMAIL]: emailConfigAsync,
    [FORWARDED_EMAIL]: forwardedEmailConfigAsync,
    [INCOMING_EMAIL]: incomingEmailConfigAsync,
    [LOGGED_CALL]: loggedCallConfig,
    [LOGGED_EMAIL]: loggedEmailConfigAsync,
    [LOGGED_LINKED_IN_MESSAGE]: loggedLinkedInMessageConfig,
    [LOGGED_SMS]: loggedSMSConfigAsync,
    [LOGGED_WHATS_APP]: loggedWhatsAppConfigAsync,
    [LOGGED_MEETING]: loggedMeetingConfig,
    [LOGGED_POSTAL_MAIL]: loggedPostalMailConfig,
    [MEETING]: meetingConfig,
    [NOTE]: noteConfig,
    [TASK]: taskConfig,
    [SMS]: smsConfigAsync,
    [WHATS_APP]: whatsAppConfigAsync,
    [CUSTOM_CHANNEL_CONVERSATION]: conversationSessionConfigAsync
  },
  [ETypes.dealCreated]: dealCreatedConfig,
  [ETypes.dealstageChange]: dealstageChangeConfig,
  [ETypes.eventAdInteraction]: adInteractionConfig,
  [ETypes.eventAnalytics]: analyticConfig,
  [ETypes.eventAutomation]: workflowConfig,
  [ETypes.eventAutomationPlatform]: automationPlatformConfig,
  [ETypes.eventCtaClick]: ctaConfig,
  [ETypes.eventCustomBehavioral]: customBehavioralConfig,
  [ETypes.eventDealSplitCreated]: dealSplitCreatedConfig,
  [ETypes.eventDealSplitDeleted]: dealSplitDeletedConfig,
  [ETypes.eventDealSplitUpdated]: dealSplitUpdatedConfig,
  [ETypes.eventEmailOptOut]: emailOptOutConfig,
  [ETypes.eventEmailSend]: emailSendConfig,
  [ETypes.eventEmailUnbounce]: emailUnbounceConfig,
  [ETypes.eventFeedbackSubmission]: feedbackSubmissionConfig,
  [ETypes.eventFormSubmission]: formSubmissionConfig,
  [ETypes.eventIntegrations]: integrationsConfig,
  [ETypes.eventLifecycleStage]: lifecycleStageConfig,
  [ETypes.eventListMembership]: listMembershipConfig,
  [ETypes.eventMarketingEvent]: marketingEventConfig,
  [ETypes.eventMediaPlaySession]: mediaBridgeConfig,
  [ETypes.eventMerge]: mergeConfig,
  [ETypes.eventPageView]: pageViewRollupConfig,
  [ETypes.eventPartnerProofOfInvolvement]: partnerProofOfInvolvementConfig,
  [ETypes.eventPaymentAchInitiated]: paymentInitiatedConfig,
  [ETypes.eventPaymentAchFailed]: paymentFailedConfig,
  [ETypes.eventPaymentCardFailed]: paymentFailedConfig,
  [ETypes.eventPaymentAchSucceeded]: paymentSucceededConfig,
  [ETypes.eventPaymentCardSucceeded]: paymentSucceededConfig,
  [ETypes.eventPaymentRefundFailed]: paymentRefundFailedConfig,
  [ETypes.eventPaymentRefundProcessing]: paymentRefundProcessingConfig,
  [ETypes.eventPaymentRefundSucceeded]: paymentRefundSucceededConfig,
  [ETypes.eventPresentation]: salesDocumentActivityConfig,
  [ETypes.eventPrivacyConsent]: privacyConsentConfig,
  [ETypes.eventProductQuote]: quoteConfig,
  [ETypes.eventRendered]: renderedEventConfig,
  [ETypes.eventSalesforceSync]: salesforceSyncConfig,
  [ETypes.eventSequence]: sequencesConfig,
  [ETypes.eventSidekick]: emailTrackingConfig,
  [ETypes.eventStageChange]: stageChangeConfig,
  [ETypes.eventSurveyMonkeySurvey]: surveyMonkeyConfig,
  [ETypes.eventTicketCreated]: ticketCreatedConfig,
  [ETypes.eventTicketStageChange]: ticketStageChangeConfig,
  [ETypes.eventTwitterMatch]: twitterConfig,
  [ETypes.eventWebinarAttendance]: webinarAttendanceConfig,
  [ETypes.eventWebinarRegistration]: webinarRegistrationConfig,
  [ETypes.eventWebInteractiveView]: webInteractiveConfig,
  [ETypes.eventWebInteractiveClose]: webInteractiveConfig,
  [ETypes.eventWebInteractiveClick]: webInteractiveConfig,
  [ETypes.eventWhatsAppCommunicationSubscriptionStatusChanged]: whatsAppCommunicationSubscriptionStatusChangedConfig,
  [ETypes.e_sms_communication_subscription_status_changed]: smsCommunicationSubscriptionStatusChangedConfig,
  [ETypes.e_social_interaction_detected]: socialConfig,
  [ETypes.eventWistia]: wistiaConfig
};