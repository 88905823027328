import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import { AssociationCategoryTypesToIds } from 'customer-data-objects/associations/AssociationCategoryTypes';
import Raven from 'raven-js';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
const getIdFromAssociation = associationObject => {
  const associationCategoryId = AssociationCategoryTypesToIds[associationObject.associationCategory];
  return `${associationCategoryId}-${associationObject.associationTypeId}`;
};
const ValidateBeforeObjectCreation = registerQuery({
  fieldName: 'validatedAssociations',
  args: ['fromObjectTypeId', 'fromObjectId', 'associations'],
  fetcher({
    fromObjectTypeId,
    fromObjectId,
    associations
  }) {
    return http.post(`associations-writes/v1/associations/${fromObjectTypeId}/${fromObjectId}/validate-before-association-creation-to-any-object?`, {
      data: associations
    });
  }
});
export const useValidateCanObjectBeCreated = ({
  associationCategory,
  associationTypeId,
  fromObjectId,
  fromObjectTypeId,
  toObjectTypeId
}) => {
  const shouldDoValidation = associationCategory && associationTypeId && fromObjectId && fromObjectTypeId;
  const {
    data,
    loading,
    error,
    refetch
  } = useQuery(ValidateBeforeObjectCreation, {
    variables: {
      fromObjectTypeId,
      fromObjectId,
      associations: [{
        associationCategory,
        associationTypeId
      }]
    },
    skip: !shouldDoValidation
  });
  useOnCrmMessageTopic(TOPIC_NAMES.UPDATE_ASSOCIATIONS, ({
    toObjectTypeId: messageToObjectTypeId,
    objectTypeId: messageObjectTypeId
  }) => {
    if (fromObjectTypeId === messageObjectTypeId && (!messageToObjectTypeId || toObjectTypeId === messageToObjectTypeId)) {
      refetch().catch(err => Raven.captureException(err));
    }
  });
  if (!data || loading || error) {
    return {
      data,
      loading,
      error
    };
  }
  const associationId = getIdFromAssociation({
    associationCategory,
    associationTypeId
  });
  const validationStatus = data.validatedAssociations[associationId];
  return {
    data: validationStatus,
    loading,
    error
  };
};