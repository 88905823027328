/*
  Only use this with functional React components
  use `WithEngagementPermissions` with legacy React class components
 */

import { getIsEngagementOwner } from '../hocs/WithEngagementPermissions';
import { useTimelinePermissions } from '../context/TimelinePermissionsContext';
import { useCurrentOwnerContext } from '../../owner/context/CurrentOwnerContext';
import { useCheckGranularPermissions } from './useCheckGranularPermissions';
/**
 * @example sample usage:
 *
 * const canEditEngagement = useEngagementPermissions({ engagement });
 */

export const useEngagementPermissions = ({
  engagement,
  actionType = 'edit'
}) => {
  const permissions = useTimelinePermissions();
  const currentOwnerId = useCurrentOwnerContext();
  const checkGranularPermissions = useCheckGranularPermissions(engagement);
  const currentUserPermissions = engagement && engagement.get('currentUserPermissions');
  const hasCurrentUserPermissions = currentUserPermissions && currentUserPermissions.size > 0;
  if (checkGranularPermissions && hasCurrentUserPermissions) {
    switch (actionType) {
      case 'edit':
        return currentUserPermissions.get('canEdit');
      case 'delete':
        return currentUserPermissions.get('canDelete');
      default:
        break;
    }
  }
  const currentUserCanEdit = permissions.currentUserCanEdit;
  return currentUserCanEdit || getIsEngagementOwner(engagement, currentOwnerId);
};