function isValidSearch(target) {
  if (!target.cardType) {
    return false;
  }
  return true;
}
export function findContainerWithCard(container, target) {
  if (!isValidSearch(target)) {
    return undefined;
  }
  let foundContainer;
  for (const cardOrContainer of container.contents) {
    if (cardOrContainer.type === 'CARD') {
      const typeMatch = cardOrContainer.definition.cardType === target.cardType;
      if (target.cardType && typeMatch) {
        foundContainer = container;
        break;
      }
    }
    if (cardOrContainer.type === 'CONTAINER') {
      const subcheck = findContainerWithCard(cardOrContainer, target);
      if (subcheck) {
        foundContainer = subcheck;
        break;
      }
    }
  }
  return foundContainer;
}