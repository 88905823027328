// @ts-expect-error js module
import { useStoreDependency } from 'general-store';
import SubjectDependency from '../crm_ui/flux/dependencies/SubjectDependency';
import { normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
// @ts-expect-error js module
import ObjectsActions from 'crm_data/objects/ObjectsActions';
export const useSubjectDependency = (objectTypeOrTypeId, objectId) => {
  const subject = useStoreDependency(SubjectDependency, {
    objectType: objectTypeOrTypeId,
    subjectId: objectId
  });
  const objectTypeId = normalizeTypeId(objectTypeOrTypeId);
  useOnCrmMessageTopic(TOPIC_NAMES.UPDATE_OBJECT, data => {
    if (data.objectTypeId === objectTypeId && data.objectId === objectId) {
      ObjectsActions.refresh(objectTypeOrTypeId, objectId);
    }
  });
  return subject;
};