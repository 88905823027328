import { ASSOCIATED_OBJECTS_CARD, BET_ASSOCIATED_COMPANIES_CARD, BET_DEAL_SUPPORT_REQUEST_CARD, BET_PARTNER_COLLAB_CARD, BET_PARTNER_POI_CARD, BET_QUOTES_CARD, BIZ_OPS_CUSTOMER_SUCCESS_CARD, BIZ_OPS_DOMAIN_CONTROLS_CARD, BIZ_OPS_RELATED_CONTACTS_CARD, CART_DISCOUNT_CARD, CART_SHIPMENT_DETAIL_CARD, CART_TOTAL_CARD, COMMERCE_PAYMENT_HISTORY_CARD, COMPANY_PARENT_CHILD_CARD, CONTACT_CREATE_ATTRIBUTION_CARD, DEAL_COLLABORATORS_CARD, DEAL_CREATE_ATTRIBUTION_CARD, DEAL_REGISTRATION_CARD, DEAL_SHARED_VIEW_CARD, DEAL_SPLITS_CARD, DOCUSIGN_CARD, ENGAGEMENT_ATTACHMENTS_CARD, FEEDBACK_CARD, FLYWHEEL_QL_SUMMARY_CARD, FLYWHEEL_RECENT_CONVERSIONS_CARD, GDPR_SUBSCRIPTIONS_CARD, INTEGRATION_CARD, INTEGRATIONS_SYNC_STATUS_CARD, INVOICES_CARD, LEAD_STAGE_TRACKER_CARD, LINKEDIN_SALES_NAVIGATOR_CARD, LISTS_CARD, MARKETING_EVENT_ABOUT_CARD, MARKETING_EVENT_ACTIVITY_CARD, NATIVE_INVOICES_CARD, NETSUITE_CARD, ORDER_DISCOUNT_CARD, ORDER_SHIPMENT_DETAIL_CARD, ORDER_TOTAL_CARD, PARTNER_REGISTRATION_CARD, PAST_CONVERSATIONS_CARD, PINNED_ACTIVITY_CARD, PLAYBOOKS_CARD, PROFILE_PRODUCTS_CARD, PROFILE_QUOTES_CARD, PROPERTIES_CARD, PROSPECTING_NOTES_CARD, QUALIFIED_LEADS_CARD, REVENUE_ATTRIBUTION_CARD, SALES_ACTIVITIES_TIMELINE_CARD, SFDC_SYNC_CARD, SUBSCRIPTION_BILLING_INFO_CARD, SUBSCRIPTION_PAYMENTS_TIMELINE_CARD, SURVEY_MONKEY_CARD, TARGET_ACCOUNT_CARD, TOOL_LINKS_CARD, TRANSCRIPT_CARD, UNIVERSAL_TIMELINE_CARD, UPCOMING_ACTIVITIES_CARD, WEBSITE_ACTIVIY_CARD, WORKFLOWS_CARD
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
} from 'customer-data-sidebar/universal/UniversalSidebarCardTypes';
import { makeAsyncActions
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
} from 'customer-data-sidebar/async';
import { makeAsyncCard, makeAsyncTitle } from './async';
// @ts-expect-error untyped dependency
import CompanyParentChildCard from 'card-company-parent-child-lib';
export const customerDataSidebarCards = {
  [COMPANY_PARENT_CHILD_CARD]: CompanyParentChildCard,
  [ASSOCIATED_OBJECTS_CARD]: {
    Content: makeAsyncCard({
      cardType: ASSOCIATED_OBJECTS_CARD,
      chunkName: 'associated-objects-card',
      loader: () => import( /* webpackChunkName: "associated-objects-card" */'card-associated-objects-lib-legacy/card/content/AssociatedObjectsCardContentUniversalSidebarDataProvider')
    }),
    Title: makeAsyncTitle({
      cardType: ASSOCIATED_OBJECTS_CARD,
      chunkName: 'associated-objects-card',
      loader: () => import( /* webpackChunkName: "associated-objects-card" */'card-associated-objects-lib-legacy/card/title/AssociatedObjectsCardTitleUniversalSidebarDataProvider')
    }),
    Actions: makeAsyncActions({
      cardType: ASSOCIATED_OBJECTS_CARD,
      chunkName: 'associated-objects-card',
      loader: () => import( /* webpackChunkName: "associated-objects-card" */'card-associated-objects-lib-legacy/card/actions/AssociatedObjectsCardActionsUniversalSidebarDataProvider')
    })
  },
  [BET_ASSOCIATED_COMPANIES_CARD]: {
    Content: makeAsyncCard({
      cardType: BET_ASSOCIATED_COMPANIES_CARD,
      chunkName: 'bet-associated-companies-card',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "bet-associated-companies-card" */
      'card-bet-associated-companies-lib/BetAssociatedCompaniesCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: BET_ASSOCIATED_COMPANIES_CARD,
      chunkName: 'bet-associated-companies-card',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "bet-associated-companies-card" */
      'card-bet-associated-companies-lib/BetAssociatedCompaniesCardSync').then(mod => mod.default.Title)
    })
  },
  [BET_DEAL_SUPPORT_REQUEST_CARD]: {
    Content: makeAsyncCard({
      cardType: BET_DEAL_SUPPORT_REQUEST_CARD,
      chunkName: 'sidebar-bet-deal-support-requests',
      loader: () => import( /* webpackChunkName: "sidebar-bet-deal-support-requests" */'card-bet-deal-support-requests-lib').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: BET_DEAL_SUPPORT_REQUEST_CARD,
      chunkName: 'sidebar-bet-deal-support-requests',
      loader: () => import( /* webpackChunkName: "sidebar-bet-deal-support-requests" */'card-bet-deal-support-requests-lib').then(mod => mod.default.Title)
    }),
    Actions: makeAsyncActions({
      cardType: BET_DEAL_SUPPORT_REQUEST_CARD,
      chunkName: 'sidebar-bet-deal-support-requests',
      loader: () => import( /* webpackChunkName: "sidebar-bet-deal-support-requests" */'card-bet-deal-support-requests-lib').then(mod => mod.default.Actions)
    })
  },
  [BET_PARTNER_COLLAB_CARD]: {
    Content: makeAsyncCard({
      cardType: BET_PARTNER_COLLAB_CARD,
      chunkName: 'sidebar-bet-partner-collab',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-bet-partner-collab" */
      'card-bet-partner-collab-lib/crm_ui/profile/sidebar/BizOps/BetPartnerCollabContainer')
    }),
    Title: makeAsyncTitle({
      cardType: BET_PARTNER_COLLAB_CARD,
      chunkName: 'sidebar-bet-partner-collab',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-bet-partner-collab" */
      'card-bet-partner-collab-lib/crm_ui/profile/sidebar/cardTitle/BetPartnerCollabHeader')
    })
  },
  [BET_PARTNER_POI_CARD]: {
    Content: makeAsyncCard({
      cardType: BET_PARTNER_POI_CARD,
      chunkName: 'sidebar-bet-partner-poi',
      loader: () => import( /* webpackChunkName: "sidebar-bet-partner-poi" */'partner-poi-crm-card/crm/PartnerProofOfInvolvementContainerSyncWithAdapter').then(mod => mod.default)
    }),
    Title: makeAsyncTitle({
      cardType: BET_PARTNER_POI_CARD,
      chunkName: 'sidebar-bet-partner-poi',
      loader: () => import( /* webpackChunkName: "sidebar-bet-partner-poi" */'partner-poi-crm-card/crm/PartnerProofOfInvolvementHeader').then(mod => mod.default)
    }),
    Actions: makeAsyncActions({
      cardType: BET_PARTNER_POI_CARD,
      chunkName: 'sidebar-bet-partner-poi',
      loader: () => import( /* webpackChunkName: "sidebar-bet-partner-poi" */'partner-poi-crm-card/crm/PoiAddButtonCrmContainer').then(mod => mod.default)
    })
  },
  [BET_QUOTES_CARD]: {
    Content: makeAsyncCard({
      cardType: BET_QUOTES_CARD,
      chunkName: 'sidebar-bet-quotes',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-bet-quotes" */
      'card-bet-quotes-lib').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: BET_QUOTES_CARD,
      chunkName: 'sidebar-bet-quotes',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-bet-quotes" */
      'card-bet-quotes-lib').then(mod => mod.default.Title)
    })
  },
  [BIZ_OPS_CUSTOMER_SUCCESS_CARD]: {
    Content: makeAsyncCard({
      cardType: BIZ_OPS_CUSTOMER_SUCCESS_CARD,
      chunkName: 'sidebar-biz-ops-customer-success',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-biz-ops-customer-success" */
      'card-biz-ops-customer-success-lib').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: BIZ_OPS_CUSTOMER_SUCCESS_CARD,
      chunkName: 'sidebar-biz-ops-customer-success',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-biz-ops-customer-success" */
      'card-biz-ops-customer-success-lib').then(mod => mod.default.Title)
    })
  },
  [BIZ_OPS_DOMAIN_CONTROLS_CARD]: {
    Content: makeAsyncCard({
      cardType: BIZ_OPS_DOMAIN_CONTROLS_CARD,
      chunkName: 'sidebar-biz-ops-domain-controls',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-biz-ops-domain-controls" */
      'card-biz-ops-domain-controls-lib').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: BIZ_OPS_DOMAIN_CONTROLS_CARD,
      chunkName: 'sidebar-biz-ops-domain-controls',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-biz-ops-domain-controls" */
      'card-biz-ops-domain-controls-lib').then(mod => mod.default.Title)
    })
  },
  [BIZ_OPS_RELATED_CONTACTS_CARD]: {
    Content: makeAsyncCard({
      cardType: BIZ_OPS_RELATED_CONTACTS_CARD,
      chunkName: 'sidebar-biz-ops-related-contacts',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-biz-ops-related-contacts" */
      'card-biz-ops-related-contacts-lib').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: BIZ_OPS_RELATED_CONTACTS_CARD,
      chunkName: 'sidebar-biz-ops-related-contacts',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-biz-ops-related-contacts" */
      'card-biz-ops-related-contacts-lib').then(mod => mod.default.Title)
    })
  },
  [CART_DISCOUNT_CARD]: {
    Content: makeAsyncCard({
      cardType: CART_DISCOUNT_CARD,
      chunkName: 'sidebar-cart-discount',
      loader: () => import( /* webpackChunkName: "sidebar-cart-discount" */'orders-card-lib/components/CartDiscountCard/CartDiscountCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: CART_DISCOUNT_CARD,
      chunkName: 'sidebar-cart-discount',
      loader: () => import( /* webpackChunkName: "sidebar-cart-discount" */'orders-card-lib/components/shared/DiscountCard/DiscountCardTitle')
    })
  },
  [CART_SHIPMENT_DETAIL_CARD]: {
    Content: makeAsyncCard({
      cardType: CART_SHIPMENT_DETAIL_CARD,
      chunkName: 'sidebar-cart-shipment-detail',
      loader: () => import( /* webpackChunkName: "sidebar-cart-shipment-detail" */'orders-card-lib/components/CartShipmentDetailCard/CartShipmentDetailCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: CART_SHIPMENT_DETAIL_CARD,
      chunkName: 'sidebar-cart-shipment-detail',
      loader: () => import( /* webpackChunkName: "sidebar-cart-shipment-detail" */'orders-card-lib/components/shared/ShipmentDetailCard/ShipmentDetailCardTitle')
    })
  },
  [CART_TOTAL_CARD]: {
    Content: makeAsyncCard({
      cardType: CART_TOTAL_CARD,
      chunkName: 'sidebar-cart-total',
      loader: () => import( /* webpackChunkName: "sidebar-cart-total" */'orders-card-lib/components/CartTotalCard/CartTotalCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: CART_TOTAL_CARD,
      chunkName: 'sidebar-cart-total',
      loader: () => import( /* webpackChunkName: "sidebar-cart-total" */'orders-card-lib/components/CartTotalCard/CartTotalCardTitle')
    })
  },
  [COMMERCE_PAYMENT_HISTORY_CARD]: {
    Content: makeAsyncCard({
      cardType: COMMERCE_PAYMENT_HISTORY_CARD,
      chunkName: 'sidebar-payment-history',
      loader: () => import( /* webpackChunkName: "sidebar-payment-history" */'card-payment-history-lib/components/card/PaymentHistoryCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: COMMERCE_PAYMENT_HISTORY_CARD,
      chunkName: 'sidebar-payment-history',
      loader: () => import( /* webpackChunkName: "sidebar-payment-history" */'card-payment-history-lib/components/card/PaymentHistoryCardTitle')
    })
  },
  [CONTACT_CREATE_ATTRIBUTION_CARD]: {
    Content: makeAsyncCard({
      cardType: CONTACT_CREATE_ATTRIBUTION_CARD,
      chunkName: 'sidebar-attribution-cards',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-attribution-cards" */
      'card-crm-attribution-lib/ContactCreateAttributionCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: CONTACT_CREATE_ATTRIBUTION_CARD,
      chunkName: 'sidebar-payment-history',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-attribution-cards" */
      'card-crm-attribution-lib/ContactCreateAttributionCardSync').then(mod => mod.default.Title)
    })
  },
  [DEAL_COLLABORATORS_CARD]: {
    Content: makeAsyncCard({
      cardType: DEAL_COLLABORATORS_CARD,
      chunkName: 'sidebar-deal-collaborators',
      loader: () => import( /* webpackChunkName: "sidebar-deal-collaborators" */'card-deal-collaborators-lib/DealCollaboratorsCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: DEAL_COLLABORATORS_CARD,
      chunkName: 'sidebar-deal-collaborators',
      loader: () => import( /* webpackChunkName: "sidebar-deal-collaborators" */'card-deal-collaborators-lib/DealCollaboratorsCardSync').then(mod => mod.default.Title)
    }),
    Actions: makeAsyncActions({
      cardType: DEAL_COLLABORATORS_CARD,
      chunkName: 'sidebar-deal-collaborators',
      loader: () => import( /* webpackChunkName: "sidebar-deal-collaborators" */'card-deal-collaborators-lib/DealCollaboratorsCardSync').then(mod => mod.default.Actions)
    })
  },
  [DEAL_CREATE_ATTRIBUTION_CARD]: {
    Content: makeAsyncCard({
      cardType: DEAL_CREATE_ATTRIBUTION_CARD,
      chunkName: 'sidebar-attribution-cards',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-attribution-cards" */
      'card-crm-attribution-lib/DealCreateAttributionCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: DEAL_CREATE_ATTRIBUTION_CARD,
      chunkName: 'sidebar-payment-history',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-attribution-cards" */
      'card-crm-attribution-lib/DealCreateAttributionCardSync').then(mod => mod.default.Title)
    })
  },
  [DEAL_REGISTRATION_CARD]: {
    Content: makeAsyncCard({
      cardType: DEAL_REGISTRATION_CARD,
      chunkName: 'sidebar-deal-registration',
      loader: () => import( /* webpackChunkName: "sidebar-deal-registration" */'deal-registration-ui/associatedSharedDealsCard/DealRegistrationCrmCardContainer')
    }),
    Title: makeAsyncTitle({
      cardType: DEAL_REGISTRATION_CARD,
      chunkName: 'sidebar-deal-registration',
      loader: () => import( /* webpackChunkName: "sidebar-deal-registration" */'deal-registration-ui/associatedSharedDealsCard/DealRegistrationCrmCardTitleContainer')
    }),
    Actions: makeAsyncActions({
      cardType: DEAL_REGISTRATION_CARD,
      chunkName: 'sidebar-deal-registration',
      loader: () => import( /* webpackChunkName: "sidebar-deal-registration" */'deal-registration-ui/associatedSharedDealsCard/DealRegistrationCrmCardActionsContainer')
    })
  },
  [DEAL_SHARED_VIEW_CARD]: {
    Content: makeAsyncCard({
      cardType: DEAL_SHARED_VIEW_CARD,
      chunkName: 'sidebar-deal-shared-view',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-deal-shared-view" */
      'card-deal-shared-view-lib/DealSharedViewCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: DEAL_SHARED_VIEW_CARD,
      chunkName: 'sidebar-deal-shared-view',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-deal-shared-view" */
      'card-deal-shared-view-lib/DealSharedViewCardSync').then(mod => mod.default.Title)
    }),
    Actions: makeAsyncActions({
      cardType: DEAL_SHARED_VIEW_CARD,
      chunkName: 'sidebar-deal-shared-view',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-deal-shared-view" */
      'card-deal-shared-view-lib/DealSharedViewCardSync').then(mod => mod.default.Actions)
    })
  },
  [DEAL_SPLITS_CARD]: {
    Content: makeAsyncCard({
      cardType: DEAL_SPLITS_CARD,
      chunkName: 'sidebar-deal-splits',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-deal-splits" */
      'card-deal-splits-lib/DealSplitsCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: DEAL_SPLITS_CARD,
      chunkName: 'sidebar-deal-splits',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-deal-splits" */
      'card-deal-splits-lib/DealSplitsCardSync').then(mod => mod.default.Title)
    }),
    Actions: makeAsyncActions({
      cardType: DEAL_SPLITS_CARD,
      chunkName: 'sidebar-deal-splits',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-deal-splits" */
      'card-deal-splits-lib/DealSplitsCardSync').then(mod => mod.default.Actions)
    })
  },
  [DOCUSIGN_CARD]: {
    Content: makeAsyncCard({
      cardType: DOCUSIGN_CARD,
      chunkName: 'sidebar-docusign',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-docusign" */
      'docusign-crm-card/components/card/DocusignCard')
    }),
    Title: makeAsyncTitle({
      cardType: DOCUSIGN_CARD,
      chunkName: 'sidebar-docusign',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-docusign" */
      'docusign-crm-card/components/card/DocusignCardTitle')
    })
  },
  [ENGAGEMENT_ATTACHMENTS_CARD]: {
    Content: makeAsyncCard({
      cardType: ENGAGEMENT_ATTACHMENTS_CARD,
      chunkName: 'sidebar-engagement-attachments',
      loader: () => import( /* webpackChunkName: "sidebar-engagement-attachments" */'card-engagement-attachments-lib/EngagementAttachmentsCard').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: ENGAGEMENT_ATTACHMENTS_CARD,
      chunkName: 'sidebar-engagement-attachments-actions',
      loader: () => import( /* webpackChunkName: "sidebar-engagement-attachments" */'card-engagement-attachments-lib/EngagementAttachmentsCard').then(mod => mod.default.Title)
    }),
    Actions: makeAsyncActions({
      cardType: ENGAGEMENT_ATTACHMENTS_CARD,
      chunkName: 'sidebar-engagement-attachments-actions',
      loader: () => import( /* webpackChunkName: "sidebar-engagement-attachments" */'card-engagement-attachments-lib/EngagementAttachmentsCard').then(mod => mod.default.Actions)
    })
  },
  [FEEDBACK_CARD]: {
    Content: makeAsyncCard({
      cardType: FEEDBACK_CARD,
      chunkName: 'sidebar-feedback',
      loader: () => import( /* webpackChunkName: "sidebar-feedback" */'card-past-feedback-lib/PastFeedbackCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: FEEDBACK_CARD,
      chunkName: 'sidebar-feedback',
      loader: () => import( /* webpackChunkName: "sidebar-feedback" */'card-past-feedback-lib/PastFeedbackCardSync').then(mod => mod.default.Title)
    })
  },
  [FLYWHEEL_QL_SUMMARY_CARD]: {
    Content: makeAsyncCard({
      cardType: FLYWHEEL_QL_SUMMARY_CARD,
      chunkName: 'sidebar-flywheel-ql-summary',
      loader: () => import( /* webpackChunkName: "sidebar-flywheel-ql-summary" */'card-flywheel-ql-summary-lib/components/QlSummaryCard').then(mod => mod.QlSummaryCardContent)
    }),
    Title: makeAsyncTitle({
      cardType: FLYWHEEL_QL_SUMMARY_CARD,
      chunkName: 'sidebar-flywheel-ql-summary',
      loader: () => import( /* webpackChunkName: "sidebar-flywheel-ql-summary" */'card-flywheel-ql-summary-lib/components/QlSummaryCard').then(mod => mod.QlSummaryCardTitle)
    })
  },
  [FLYWHEEL_RECENT_CONVERSIONS_CARD]: {
    Content: makeAsyncCard({
      cardType: FLYWHEEL_RECENT_CONVERSIONS_CARD,
      chunkName: 'sidebar-flywheel-recent-conversions',
      loader: () => import( /* webpackChunkName: "sidebar-flywheel-recent-conversions" */'card-flywheel-recent-conversions-lib/components/RecentConversionsCardContent').then(mod => mod.RecentConversionsCardContent)
    }),
    Title: makeAsyncTitle({
      cardType: FLYWHEEL_RECENT_CONVERSIONS_CARD,
      chunkName: 'sidebar-flywheel-recent-conversions',
      loader: () => import( /* webpackChunkName: "sidebar-flywheel-recent-conversions" */'card-flywheel-recent-conversions-lib/components/RecentConversionsCardTitle').then(mod => mod.RecentConversionsCardTitle)
    })
  },
  [GDPR_SUBSCRIPTIONS_CARD]: {
    Content: makeAsyncCard({
      cardType: GDPR_SUBSCRIPTIONS_CARD,
      chunkName: 'sidebar-gdpr-subscriptions',
      loader: () => import( /* webpackChunkName: "sidebar-gdpr-subscriptions" */'card-gdpr-subscriptions-card-lib/GdprSubscriptionsCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: GDPR_SUBSCRIPTIONS_CARD,
      chunkName: 'sidebar-gdpr-subscriptions',
      loader: () => import( /* webpackChunkName: "sidebar-gdpr-subscriptions" */'card-gdpr-subscriptions-card-lib/GdprSubscriptionsCardSync').then(mod => mod.default.Title)
    }),
    Actions: makeAsyncActions({
      cardType: GDPR_SUBSCRIPTIONS_CARD,
      chunkName: 'sidebar-gdpr-subscriptions',
      loader: () => import( /* webpackChunkName: "sidebar-gdpr-subscriptions" */'card-gdpr-subscriptions-card-lib/GdprSubscriptionsCardSync').then(mod => mod.default.Actions)
    })
  },
  [INTEGRATION_CARD]: {
    Content: makeAsyncCard({
      cardType: INTEGRATION_CARD,
      chunkName: 'sidebar-integration',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-integration" */
      'card-integration-card-lib/crm_ui/profile/sidebar/integrations/ProfileIntegrationsLinkedSalesObjectWrapper')
    }),
    Title: makeAsyncTitle({
      cardType: INTEGRATION_CARD,
      chunkName: 'sidebar-integration',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-integration" */
      'card-integration-card-lib/crm_ui/components/profile/sidebar/LinkedSalesObjectTitleWrapper')
    })
  },
  [INTEGRATIONS_SYNC_STATUS_CARD]: {
    Content: makeAsyncCard({
      cardType: INTEGRATIONS_SYNC_STATUS_CARD,
      chunkName: 'sidebar-integrations-sync-card',
      loader: () => import( /* webpackChunkName: "sidebar-integrations-sync-card" */'integrations-sync-card-ui/components/card/content/IntegrationsSyncCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: INTEGRATIONS_SYNC_STATUS_CARD,
      chunkName: 'sidebar-integrations-sync-card',
      loader: () => import( /* webpackChunkName: "sidebar-integrations-sync-card" */'integrations-sync-card-ui/components/card/title/IntegrationsSyncCardTitle')
    }),
    Actions: makeAsyncActions({
      cardType: INTEGRATIONS_SYNC_STATUS_CARD,
      chunkName: 'sidebar-integrations-sync-card',
      loader: () => import( /* webpackChunkName: "sidebar-integrations-sync-card" */'integrations-sync-card-ui/components/card/actions/IntegrationsSyncCardActions')
    })
  },
  [INVOICES_CARD]: {
    Content: makeAsyncCard({
      cardType: INVOICES_CARD,
      chunkName: 'sidebar-invoices',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-invoices" */
      'card-invoices-lib/InvoicesCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: INVOICES_CARD,
      chunkName: 'sidebar-invoices',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-invoices" */
      'card-invoices-lib/InvoicesCardSync').then(mod => mod.default.Title)
    })
  },
  [LEAD_STAGE_TRACKER_CARD]: {
    Content: makeAsyncCard({
      cardType: LEAD_STAGE_TRACKER_CARD,
      chunkName: 'sidebar-lead-stage-tracker',
      loader: () => import( /* webpackChunkName: "sidebar-lead-stage-tracker" */'lead-tracking-lib/components/crmCard/LeadCardContent').then(mod => mod.LeadCardContent)
    }),
    Actions: makeAsyncActions({
      cardType: LEAD_STAGE_TRACKER_CARD,
      chunkName: 'sidebar-lead-stage-tracker',
      loader: () => import( /* webpackChunkName: "sidebar-lead-stage-tracker" */'lead-tracking-lib/components/crmCard/LeadCardActions').then(mod => mod.LeadCardActions)
    }),
    Title: makeAsyncTitle({
      cardType: LEAD_STAGE_TRACKER_CARD,
      chunkName: 'sidebar-lead-stage-tracker',
      loader: () => import( /* webpackChunkName: "sidebar-lead-stage-tracker" */'lead-tracking-lib/components/crmCard/LeadCardTitle')
    })
  },
  [LINKEDIN_SALES_NAVIGATOR_CARD]: {
    Content: makeAsyncCard({
      cardType: LINKEDIN_SALES_NAVIGATOR_CARD,
      chunkName: 'sidebar-linkedin-sales-navigator',
      loader: () => import( /* webpackChunkName: "sidebar-linkedin-sales-navigator" */'card-linked-in-sales-navigator-lib/LinkedInSalesNavCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: LINKEDIN_SALES_NAVIGATOR_CARD,
      chunkName: 'sidebar-linkedin-sales-navigator',
      loader: () => import( /* webpackChunkName: "sidebar-linkedin-sales-navigator" */'card-linked-in-sales-navigator-lib/LinkedInSalesNavCardSync').then(mod => mod.default.Title)
    })
  },
  [LISTS_CARD]: {
    Content: makeAsyncCard({
      cardType: LISTS_CARD,
      chunkName: 'sidebar-lists',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-lists" */
      'card-lists-lib/ListsCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: LISTS_CARD,
      chunkName: 'sidebar-lists',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-lists" */
      'card-lists-lib/ListsCardContent').then(mod => mod.ListsCardTitle)
    }),
    Actions: makeAsyncActions({
      cardType: LISTS_CARD,
      chunkName: 'sidebar-lists',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-lists" */
      'card-lists-lib/ListsCardContent').then(mod => mod.ListsCardActions)
    })
  },
  [MARKETING_EVENT_ABOUT_CARD]: {
    Content: makeAsyncCard({
      cardType: MARKETING_EVENT_ABOUT_CARD,
      chunkName: 'sidebar-marketing-event-about',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-marketing-event-about" */
      'card-marketing-event-about-lib/MarketingEventAboutCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: MARKETING_EVENT_ABOUT_CARD,
      chunkName: 'sidebar-marketing-event-about',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-marketing-event-about" */
      'card-marketing-event-about-lib/MarketingEventAboutCardSync').then(mod => mod.default.Title)
    })
  },
  [MARKETING_EVENT_ACTIVITY_CARD]: {
    Content: makeAsyncCard({
      cardType: MARKETING_EVENT_ACTIVITY_CARD,
      chunkName: 'sidebar-marketing-event-activity',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-marketing-event-activity" */
      'card-marketing-event-activity-lib/MarketingEventActivityCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: MARKETING_EVENT_ACTIVITY_CARD,
      chunkName: 'sidebar-marketing-event-activity',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-marketing-event-activity" */
      'card-marketing-event-activity-lib/MarketingEventActivityCardSync').then(mod => mod.default.Title)
    })
  },
  [NATIVE_INVOICES_CARD]: {
    Content: makeAsyncCard({
      cardType: NATIVE_INVOICES_CARD,
      chunkName: 'sidebar-native-invoices',
      loader: () => import( /* webpackChunkName: "sidebar-native-invoices" */'invoices-card-lib/components/card/NativeInvoicesCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: NATIVE_INVOICES_CARD,
      chunkName: 'sidebar-native-invoices',
      loader: () => import( /* webpackChunkName: "sidebar-native-invoices" */'invoices-card-lib/components/card/NativeInvoicesCardSync').then(mod => mod.default.Title)
    }),
    Actions: makeAsyncActions({
      cardType: NATIVE_INVOICES_CARD,
      chunkName: 'sidebar-native-invoices',
      loader: () => import( /* webpackChunkName: "sidebar-native-invoices" */'invoices-card-lib/components/card/NativeInvoicesCardSync').then(mod => mod.default.Actions)
    })
  },
  [NETSUITE_CARD]: {
    Content: makeAsyncCard({
      cardType: NETSUITE_CARD,
      chunkName: 'sidebar-sales-order-wizard-card',
      loader: () => import( /* webpackChunkName: "sidebar-sales-order-wizard-card" */'ui-sales-order-wizard-card/components/card/content/SalesOrderWizardCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: NETSUITE_CARD,
      chunkName: 'sidebar-sales-order-wizard-card',
      loader: () => import( /* webpackChunkName: "sidebar-sales-order-wizard-card" */'ui-sales-order-wizard-card/components/card/title/SalesOrderWizardCardTitle')
    }),
    Actions: makeAsyncActions({
      cardType: NETSUITE_CARD,
      chunkName: 'sidebar-sales-order-wizard-card',
      loader: () => import( /* webpackChunkName: "sidebar-sales-order-wizard-card" */'ui-sales-order-wizard-card/components/card/actions/SalesOrderWizardCardActions')
    })
  },
  [ORDER_DISCOUNT_CARD]: {
    Content: makeAsyncCard({
      cardType: ORDER_DISCOUNT_CARD,
      chunkName: 'sidebar-order-discount',
      loader: () => import( /* webpackChunkName: "sidebar-order-discount" */'orders-card-lib/components/OrderDiscountCard/OrderDiscountCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: ORDER_DISCOUNT_CARD,
      chunkName: 'sidebar-order-discount',
      loader: () => import( /* webpackChunkName: "sidebar-order-discount" */'orders-card-lib/components/shared/DiscountCard/DiscountCardTitle')
    })
  },
  [ORDER_SHIPMENT_DETAIL_CARD]: {
    Content: makeAsyncCard({
      cardType: ORDER_SHIPMENT_DETAIL_CARD,
      chunkName: 'sidebar-order-shipment-detail',
      loader: () => import( /* webpackChunkName: "sidebar-order-shipment-detail" */'orders-card-lib/components/OrderShipmentDetailCard/OrderShipmentDetailCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: ORDER_SHIPMENT_DETAIL_CARD,
      chunkName: 'sidebar-order-shipment-detail',
      loader: () => import( /* webpackChunkName: "sidebar-order-shipment-detail" */'orders-card-lib/components/shared/ShipmentDetailCard/ShipmentDetailCardTitle')
    })
  },
  [ORDER_TOTAL_CARD]: {
    Content: makeAsyncCard({
      cardType: ORDER_TOTAL_CARD,
      chunkName: 'sidebar-order-total',
      loader: () => import( /* webpackChunkName: "sidebar-order-total" */'orders-card-lib/components/OrderTotalCard/OrderTotalCard')
    }),
    Title: makeAsyncTitle({
      cardType: ORDER_TOTAL_CARD,
      chunkName: 'sidebar-order-total',
      loader: () => import( /* webpackChunkName: "sidebar-order-total" */'orders-card-lib/components/OrderTotalCard/OrderTotalCardTitle')
    })
  },
  [PARTNER_REGISTRATION_CARD]: {
    Content: makeAsyncCard({
      cardType: PARTNER_REGISTRATION_CARD,
      chunkName: 'sidebar-partner-registration',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-partner-registration" */
      'card-partner-registration-lib/PartnerRegistrationCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: PARTNER_REGISTRATION_CARD,
      chunkName: 'sidebar-partner-registration',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-partner-registration" */
      'card-partner-registration-lib/PartnerRegistrationCardSync').then(mod => mod.default.Title)
    })
  },
  [PAST_CONVERSATIONS_CARD]: {
    Content: makeAsyncCard({
      cardType: PAST_CONVERSATIONS_CARD,
      chunkName: 'sidebar-past-conversations',
      loader: () => import( /* webpackChunkName: "sidebar-past-conversations" */'card-past-conversations-lib/PastConversationsCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: PAST_CONVERSATIONS_CARD,
      chunkName: 'sidebar-past-conversations',
      loader: () => import( /* webpackChunkName: "sidebar-past-conversations" */'card-past-conversations-lib/PastConversationsCardSync').then(mod => mod.default.Title)
    })
  },
  [PINNED_ACTIVITY_CARD]: {
    Content: makeAsyncCard({
      cardType: PINNED_ACTIVITY_CARD,
      chunkName: 'sidebar-pinned-activity',
      loader: () => import( /* webpackChunkName: "sidebar-pinned-activity" */
      'crm-events-viz-cards/pinnedActivitySidebarCard/PinnedActivitySidebarWrapper')
    }),
    Title: makeAsyncTitle({
      cardType: PINNED_ACTIVITY_CARD,
      chunkName: 'sidebar-pinned-activity',
      loader: () => import( /* webpackChunkName: "sidebar-pinned-activity" */'crm-events-viz-cards/pinnedActivitySidebarCard/PinnedActivitySidebarTitle')
    })
  },
  [PLAYBOOKS_CARD]: {
    Content: makeAsyncCard({
      cardType: PLAYBOOKS_CARD,
      chunkName: 'sidebar-playbooks',
      loader: () => import( /* webpackChunkName: "sidebar-playbooks" */'card-playbooks-lib/components/PlaybooksCardListCRM')
    }),
    Actions: makeAsyncActions({
      cardType: PLAYBOOKS_CARD,
      loader: () => import( /* webpackChunkName: "sidebar-playbooks" */'card-playbooks-lib/crm_ui/profile/sidebar/playbooks/PlaybooksCardActions')
    }),
    Title: makeAsyncTitle({
      cardType: PLAYBOOKS_CARD,
      loader: () => import( /* webpackChunkName: "sidebar-playbooks" */'card-playbooks-lib/crm_ui/profile/sidebar/playbooks/PlaybooksCardTitle')
    })
  },
  [PROFILE_PRODUCTS_CARD]: {
    Content: makeAsyncCard({
      cardType: PROFILE_PRODUCTS_CARD,
      chunkName: 'sidebar-products',
      loader: () => import( /* webpackChunkName: "sidebar-profile-products" */'card-profile-products-lib/ProfileProductsCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: PROFILE_PRODUCTS_CARD,
      chunkName: 'sidebar-products',
      loader: () => import( /* webpackChunkName: "sidebar-profile-products" */'card-profile-products-lib/ProfileProductsCardSync').then(mod => mod.default.Title)
    }),
    Actions: makeAsyncActions({
      cardType: PROFILE_PRODUCTS_CARD,
      chunkName: 'sidebar-products',
      loader: () => import( /* webpackChunkName: "sidebar-profile-products" */'card-profile-products-lib/ProfileProductsCardSync').then(mod => mod.default.Actions)
    })
  },
  [PROFILE_QUOTES_CARD]: {
    Content: makeAsyncCard({
      cardType: PROFILE_QUOTES_CARD,
      chunkName: 'sidebar-quotes',
      loader: () => import( /* webpackChunkName: "sidebar-profile-quotes" */'card-profile-quotes-lib/ProfileQuotesCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: PROFILE_QUOTES_CARD,
      chunkName: 'sidebar-quotes',
      loader: () => import( /* webpackChunkName: "sidebar-profile-quotes" */'card-profile-quotes-lib/ProfileQuotesCardSync').then(mod => mod.default.Title)
    }),
    Actions: makeAsyncActions({
      cardType: PROFILE_QUOTES_CARD,
      chunkName: 'sidebar-quotes',
      loader: () => import( /* webpackChunkName: "sidebar-profile-quotes" */'card-profile-quotes-lib/ProfileQuotesCardSync').then(mod => mod.default.Actions)
    })
  },
  [PROPERTIES_CARD]: {
    Content: makeAsyncCard({
      cardType: PROPERTIES_CARD,
      chunkName: 'sidebar-properties',
      loader: () => import( /* webpackChunkName: "sidebar-properties" */'card-properties-lib/sdk/components/DataProviderUniversalSidebarWrapper')
    }),
    Title: makeAsyncTitle({
      cardType: PROPERTIES_CARD,
      chunkName: 'sidebar-properties',
      loader: () => import( /* webpackChunkName: "sidebar-properties" */'card-properties-lib/card/PropertiesCardTitle')
    }),
    Actions: makeAsyncActions({
      cardType: PROPERTIES_CARD,
      chunkName: 'sidebar-properties',
      loader: () => import( /* webpackChunkName: "sidebar-properties" */'card-properties-lib/card/PropertiesCardActions')
    })
  },
  [PROSPECTING_NOTES_CARD]: {
    Title: makeAsyncTitle({
      cardType: PROSPECTING_NOTES_CARD,
      chunkName: 'sidebar-prospecting-notes',
      loader: () => import( /* webpackChunkName: "sidebar-prospecting-notes" */'card-prospecting-notes').then(mod => mod.default.Title)
    }),
    Content: makeAsyncCard({
      cardType: PROSPECTING_NOTES_CARD,
      chunkName: 'sidebar-prospecting-notes',
      loader: () => import( /* webpackChunkName: "sidebar-prospecting-notes" */'card-prospecting-notes').then(mod => mod.default.Content)
    })
  },
  [QUALIFIED_LEADS_CARD]: {
    Content: makeAsyncCard({
      cardType: QUALIFIED_LEADS_CARD,
      chunkName: 'sidebar-qualified-leads',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-qualified-leads" */
      'card-qualified-leads-lib/crm_ui/profile/sidebar/qualifiedLeads/QualifiedLeadsContent')
    }),
    Title: makeAsyncTitle({
      cardType: QUALIFIED_LEADS_CARD,
      chunkName: 'sidebar-qualified-leads',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-qualified-leads" */
      'card-qualified-leads-lib/crm_ui/profile/sidebar/qualifiedLeads/QualifiedLeadsTitle')
    })
  },
  [REVENUE_ATTRIBUTION_CARD]: {
    Content: makeAsyncCard({
      cardType: REVENUE_ATTRIBUTION_CARD,
      chunkName: 'sidebar-attribution-cards',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-attribution-cards" */
      'card-crm-attribution-lib/RevenueAttributionCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: REVENUE_ATTRIBUTION_CARD,
      chunkName: 'sidebar-attribution-cards',
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-attribution-cards" */
      'card-crm-attribution-lib/RevenueAttributionCardSync').then(mod => mod.default.Title)
    })
  },
  [SALES_ACTIVITIES_TIMELINE_CARD]: {
    Content: makeAsyncCard({
      cardType: SALES_ACTIVITIES_TIMELINE_CARD,
      chunkName: 'sidebar-sales-activities-timeline',
      loader: () => import( /* webpackChunkName: "sidebar-sales-activities-timeline" */'card-sales-activities-timeline').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: SALES_ACTIVITIES_TIMELINE_CARD,
      chunkName: 'sidebar-sales-activities-timeline',
      loader: () => import( /* webpackChunkName: "sidebar-sales-activities-timeline" */'card-sales-activities-timeline').then(mod => mod.default.Title)
    })
  },
  [SFDC_SYNC_CARD]: {
    Content: makeAsyncCard({
      cardType: SFDC_SYNC_CARD,
      chunkName: 'sidebar-sfdc-sync',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-sfdc-sync" */
      'card-sfdcsync-lib/SfdcSyncCardSyncLoaded').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: SFDC_SYNC_CARD,
      chunkName: 'sidebar-sfdc-sync',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-sfdc-sync" */
      'card-sfdcsync-lib/SfdcSyncCardSyncLoaded').then(mod => mod.default.Title)
    }),
    Actions: makeAsyncActions({
      cardType: SFDC_SYNC_CARD,
      chunkName: 'sidebar-sfdc-sync',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-sfdc-sync" */
      'card-sfdcsync-lib/SfdcSyncCardSyncLoaded').then(mod => mod.default.Actions)
    })
  },
  [SUBSCRIPTION_BILLING_INFO_CARD]: {
    Content: makeAsyncCard({
      cardType: SUBSCRIPTION_BILLING_INFO_CARD,
      chunkName: 'sidebar-subscription-billing-info',
      loader: () => import( /* webpackChunkName: "sidebar-subscription-billing-info" */'commerce-subscription-cards-lib/cards/billing-info/components/BillingInfoCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: SUBSCRIPTION_BILLING_INFO_CARD,
      chunkName: 'sidebar-subscription-billing-info',
      loader: () => import( /* webpackChunkName: "sidebar-subscription-billing-info" */'commerce-subscription-cards-lib/cards/billing-info/components/BillingInfoCardTitle')
    })
  },
  [SUBSCRIPTION_PAYMENTS_TIMELINE_CARD]: {
    Content: makeAsyncCard({
      cardType: SUBSCRIPTION_PAYMENTS_TIMELINE_CARD,
      chunkName: 'sidebar-subscription-billing-info',
      loader: () => import( /* webpackChunkName: "sidebar-subscription-timeline" */'commerce-subscription-cards-lib/cards/timeline/components/TimelineCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: SUBSCRIPTION_PAYMENTS_TIMELINE_CARD,
      chunkName: 'sidebar-subscription-billing-info',
      loader: () => import( /* webpackChunkName: "sidebar-subscription-timeline" */'commerce-subscription-cards-lib/cards/timeline/components/TimelineCardTitle')
    })
  },
  [SURVEY_MONKEY_CARD]: {
    Content: makeAsyncCard({
      cardType: SURVEY_MONKEY_CARD,
      chunkName: 'sidebar-surveymonkey',
      loader: () => import( /* webpackChunkName: "sidebar-surveymonkey" */'surveymonkey-crm-card/SurveyMonkeyCard')
    }),
    Title: makeAsyncTitle({
      cardType: SURVEY_MONKEY_CARD,
      chunkName: 'sidebar-surveymonkey-title',
      loader: () => import( /* webpackChunkName: "sidebar-surveymonkey" */'surveymonkey-crm-card/SurveyMonkeyCardTitle')
    })
  },
  [TARGET_ACCOUNT_CARD]: {
    Content: makeAsyncCard({
      cardType: TARGET_ACCOUNT_CARD,
      chunkName: 'sidebar-target-account',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-target-account" */
      'card-target-account-lib/TargetAccountCardSync').then(mod => mod.default.Content)
    }),
    Title: makeAsyncTitle({
      cardType: TARGET_ACCOUNT_CARD,
      chunkName: 'sidebar-target-account',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-target-account" */
      'card-target-account-lib/TargetAccountCardSync').then(mod => mod.default.Title)
    })
  },
  [TOOL_LINKS_CARD]: {
    Content: makeAsyncCard({
      cardType: TOOL_LINKS_CARD,
      chunkName: 'sidebar-toolLinks',
      loader: () => import( /* webpackChunkName: "sidebar-toolLinks" */'zorse-crm-components-lib/tools/components/TicketToolLinksCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: TOOL_LINKS_CARD,
      chunkName: 'sidebar-toolLinks',
      loader: () => import( /* webpackChunkName: "sidebar-toolLinks" */'zorse-crm-components-lib/tools/components/TicketToolLinksCardTitle')
    })
  },
  [TRANSCRIPT_CARD]: {
    Content: makeAsyncCard({
      cardType: TRANSCRIPT_CARD,
      chunkName: 'sidebar-transcript-card',
      loader: () => import( /* webpackChunkName: "sidebar-transcript-card" */'recording-preview-card/components/TranscriptCardContent')
    }),
    Title: makeAsyncTitle({
      cardType: TRANSCRIPT_CARD,
      chunkName: 'sidebar-transcript-card',
      loader: () => import( /* webpackChunkName: "sidebar-transcript-card" */'recording-preview-card/components/TranscriptCardTitle')
    })
  },
  [UNIVERSAL_TIMELINE_CARD]: {
    Content: makeAsyncCard({
      cardType: UNIVERSAL_TIMELINE_CARD,
      chunkName: 'sidebar-universal-timeline',
      loader: () => import( /* webpackChunkName: "sidebar-universal-timeline'," */'crm-events-viz-cards/universalTimelineSidebarCard/UniversalTimelineSidebarWrapper')
    }),
    Title: makeAsyncTitle({
      cardType: UNIVERSAL_TIMELINE_CARD,
      chunkName: 'sidebar-universal-timeline',
      loader: () => import( /* webpackChunkName: "sidebar-universal-timeline'," */'crm-events-viz-cards/universalTimelineSidebarCard/UniversalTimelineSidebarTitle')
    })
  },
  [UPCOMING_ACTIVITIES_CARD]: {
    Content: makeAsyncCard({
      cardType: UPCOMING_ACTIVITIES_CARD,
      chunkName: 'upcoming-activities-card-content',
      loader: () => import( /* webpackChunkName: "upcoming-activities-card" */'crm-events-viz-cards/upcomingActivitiesSidebarCard/UpcomingActivitiesSidebarWrapper')
    }),
    Title: makeAsyncTitle({
      cardType: UPCOMING_ACTIVITIES_CARD,
      chunkName: 'upcoming-activities-card-title',
      loader: () => import( /* webpackChunkName: "upcoming-activities-card" */'crm-events-viz-cards/upcomingActivitiesSidebarCard/UpcomingActivitiesSidebarTitle')
    })
  },
  [WEBSITE_ACTIVIY_CARD]: {
    Content: makeAsyncCard({
      cardType: WEBSITE_ACTIVIY_CARD,
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-website-activity" */
      'card-website-activity-lib/crm_ui/profile/sidebar/contacts/ProfileWebsiteActivity')
    }),
    Title: makeAsyncTitle({
      cardType: WEBSITE_ACTIVIY_CARD,
      loader: () => import(
      // @ts-expect-error untyped dependency
      /* webpackChunkName: "sidebar-website-activity" */
      'card-website-activity-lib/crm_ui/profile/sidebar/contacts/ProfileWebsiteActivityTitle')
    })
  },
  [WORKFLOWS_CARD]: {
    Content: makeAsyncCard({
      cardType: WORKFLOWS_CARD,
      chunkName: 'sidebar-workflows',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-workflows" */
      'customer-data-sidebar/universal/cards/WorkflowMembershipsCardContent').then(mod => mod.default)
    }),
    Title: makeAsyncTitle({
      cardType: WORKFLOWS_CARD,
      chunkName: 'sidebar-workflows',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-workflows" */
      'customer-data-sidebar/universal/cards/WorkflowMembershipsCardContent').then(mod => mod.WorkflowMembershipsCardTitle)
    }),
    Actions: makeAsyncActions({
      cardType: WORKFLOWS_CARD,
      chunkName: 'sidebar-workflows',
      loader: () => import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
      /* webpackChunkName: "sidebar-workflows" */
      'customer-data-sidebar/universal/cards/WorkflowMembershipsCardContent').then(mod => mod.WorkflowMembershipsCardActions)
    })
  }
};
export function getSidebarCard(cardName) {
  return customerDataSidebarCards[cardName];
}