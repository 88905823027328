'use es6';

import { Map as ImmutableMap, fromJS } from 'immutable';
import RichTextEditorResults from './RichTextEditorResults';
import { convertToHtml, convertToPlaintext, hasContent, convertToJSON, getEditorStateFromJSON, getEditorStateFromHtml, getAtMentions, getNumberOfImages, getNumberOfLinks, isUploadingImages, focusEditor } from 'hubspot-prosemirror/prosemirror/ProsemirrorUtils';
export default class ProsemirrorEditorResults extends RichTextEditorResults {
  isProsemirror() {
    return true;
  }
  hasContent() {
    return this.hasState() && hasContent(this.editorState);
  }
  isUploadingImages() {
    return this.hasState() && isUploadingImages(this.editorState);
  }
  toRawEditorContent() {
    return fromJS(convertToJSON(this.editorState));
  }
  pushRawEditorContent(rawEditorContent, {
    preserveNewlines = false
  } = {}) {
    if (ImmutableMap.isMap(rawEditorContent)) {
      rawEditorContent = rawEditorContent.toJS();
    }
    let editorState;
    try {
      editorState = getEditorStateFromJSON(rawEditorContent, this.customPlugins, {
        preserveNewlines
      });
    } catch (e) {
      editorState = getEditorStateFromHtml('', this.customPlugins);
    }
    return this.setEditorState(editorState);
  }
  pushContent(html, __undoable = false) {
    const editorState = getEditorStateFromHtml(html, this.customPlugins, {
      preserveNewlines: false
    });
    return this.setEditorState(editorState);
  }
  resetEditorState() {
    const editorState = getEditorStateFromHtml('', this.customPlugins);
    return this.setEditorState(editorState);
  }
  setEditorState(editorState, contentChanged = true) {
    this.putEditorState(focusEditor(editorState));
    return this.merge({
      editorState,
      contentChanged
    });
  }
  setEditorStateWithoutFocus(editorState, contentChanged = true) {
    this.putEditorState(editorState);
    return this.merge({
      editorState,
      contentChanged
    });
  }
  manageVideoTokens(__allRecipientCount, __recipientEmail) {
    // TODO: not complete
    return Promise.resolve(this);
  }

  // Caution, this operation is expensive, do not call on every change!
  html() {
    if (!this._html) {
      this._html = convertToHtml(this.editorState);
    }
    return this._html;
  }
  plainText() {
    if (!this._plainText) {
      this._plainText = convertToPlaintext(this.editorState);
    }
    return this._plainText;
  }
  atMentions() {
    if (!this._atMentions) {
      this._atMentions = getAtMentions(this.editorState);
    }
    return this._atMentions;
  }
  numberOf(property, getter) {
    if (!this[property]) {
      this[property] = getter(this.editorState);
    }
    return this[property];
  }
  numberOfImages() {
    return this.numberOf('_numberOfImages', getNumberOfImages);
  }
  numberOfVideos() {
    return 0;
  }
  numberOfLinks() {
    return this.numberOf('_numberOfLinks', getNumberOfLinks);
  }
  transform(transforms, opts) {
    delete this._html;
    delete this._plainText;
    delete this._atMentions;
    return this.setEditorState(transforms(this.editorState, opts), this.contentChanged);
  }
}