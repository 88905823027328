'use es6';

import { Map as ImmutableMap } from 'immutable';
import { logError } from 'customer-data-ui-utilities/eventLogging/eventLogger';
import EmailAddressRecord from 'customer-data-email/schema/email/EmailAddressRecord';
import once from 'transmute/once';
import { TICKET } from 'customer-data-objects/constants/ObjectTypes';
import PortalIdParser from 'PortalIdParser';
export class PersistentFromAddress {
  constructor(storage) {
    this.storage = storage;
    this.key = `EmailSettings:PersistentFromAddress:${PortalIdParser.get()}`;
    this.defaultKey = 'DEFAULT';
    this.addresses = ImmutableMap();
    this.allowedKeys = [TICKET];
    if (this.storage && this.isRestorable()) {
      this.restore();
    }
  }
  getStorageKeyByType(objectType) {
    return this.allowedKeys.includes(objectType) ? objectType : this.defaultKey;
  }
  setAddressByType(objectType, value) {
    const key = this.getStorageKeyByType(objectType);
    if (value && value.addressId) {
      this.addresses = this.addresses.set(key, value);
    }
    return this;
  }
  getAddressByType(objectType) {
    const key = this.getStorageKeyByType(objectType);
    if (this.addresses.has(key)) {
      return this.addresses.get(key);
    }
    return null;
  }
  isRestorable() {
    return this.storage && !!this.storage.getItem(this.key);
  }
  validateSavedAddresses(addressObject) {
    let invalidContents = false;
    this.addresses = ImmutableMap(addressObject).reduce((acc, address, objectType) => {
      if (address && address.addressId) {
        acc = acc.set(objectType, new EmailAddressRecord(address));
      } else {
        invalidContents = true;
      }
      return acc;
    }, ImmutableMap());
    if (invalidContents) {
      logError({
        error: new Error('Invalid address found in persisted from address'),
        extraData: {
          persistedData: this.addresses,
          storage: addressObject
        }
      });
      this.save();
    }
  }
  restore() {
    let addressObject;
    try {
      addressObject = this.storage.getItem(this.key);
      addressObject = JSON.parse(addressObject);
    } catch (error) {
      this.storage.removeItem(this.key);
      logError({
        error: new Error('Parsing failed for persisted from addresses'),
        extraData: {
          persistedData: this.addresses,
          storage: addressObject,
          error
        }
      });
      addressObject = null;
    }
    if (!addressObject) {
      return this;
    }
    this.validateSavedAddresses(addressObject);
    return this;
  }
  save() {
    try {
      this.storage.setItem(this.key, JSON.stringify(this.addresses.toJS()));
    } catch (error) {
      this.addresses = ImmutableMap();
      logError({
        error: new Error('Error saving persisted from address'),
        extraData: {
          error,
          storage: this.addresses
        }
      });
    }
    return this;
  }
  invalidate(objectType) {
    const key = this.getStorageKeyByType(objectType);
    this.addresses = this.addresses.delete(key);
    this.save();
    return this;
  }
}
export const getPersistentFromAddress = once((storage = window.localStorage) => new PersistentFromAddress(storage));