'use es6';

import { Map as ImmutableMap, Record, Seq } from 'immutable';
import identity from 'transmute/identity';
const RichTextEditorConfigurationRecord = Record({
  type: null,
  plugins: Seq(),
  pluginComposers: Seq(),
  pluginGroupConfig: ImmutableMap(),
  composed: identity,
  hasContent: null
});
export default class RichTextEditorConfiguration extends RichTextEditorConfigurationRecord {
  enhanceWith(name, additionalPlugins, additionalPluginComposers) {
    return this.merge({
      type: `${this.type}-${name}`,
      plugins: this.plugins.concat(additionalPlugins),
      pluginComposers: this.pluginComposers.concat(additionalPluginComposers)
    });
  }
  configureWith(composer, adapter = composer) {
    const plugins = this.plugins.toArray();
    if (this.pluginComposers.size > 0) {
      const adaptedComposer = adapter(...this.pluginComposers.toArray());
      return this.set('composed', adaptedComposer(...plugins));
    }
    return this.set('composed', composer(...plugins));
  }
}