import { useCallback } from 'react';
import { useTimelineDispatch, useTimelineSelector } from '../../reduxHooks';
import { makeGetIsEventCardEditingTaskSubject, startEditingTaskSubject, endEditingTaskSubject } from '../eventCardsSlice';
export function useIsEditingTaskSubject(eventId) {
  const getIsEditingTaskSubject = makeGetIsEventCardEditingTaskSubject(eventId);
  const isEditingTaskSubject = useTimelineSelector(getIsEditingTaskSubject);
  const dispatch = useTimelineDispatch();
  const setIsEditingTaskSubject = useCallback(isEditingTaskSubjectFromCard => {
    if (isEditingTaskSubjectFromCard) {
      dispatch(startEditingTaskSubject(eventId));
    } else {
      dispatch(endEditingTaskSubject(eventId));
    }
  }, [dispatch, eventId]);
  return [isEditingTaskSubject, setIsEditingTaskSubject];
}