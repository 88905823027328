import registerAllData from 'I18n/init/register/sync/registerAllData';
import 'I18n/init/data/currency';
import 'I18n/init/data/baseLocales';
import 'I18n/init/data/publicLocales';
import 'I18n/init/data/allTimezones';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_ui/i18n/getProvider'. Did ... Remove this comment to see the full error message
import { getProvider } from 'crm_ui/i18n/getProvider';
import mark from 'crm-fe-perf/timing/mark';
import measure from 'crm-fe-perf/timing/measure';
import { I18nLoadStart, I18nLoadEnd, I18nRegisterStart, I18nRegisterEnd } from 'crm-fe-perf/timing/marks/crm-records-ui/performanceMarks';
import { TimeToDownloadTranslations, TimeToRegisterTranslations } from 'crm-fe-perf/timing/measurements/crm-records-ui/performanceMeasurements';
mark(I18nLoadStart);
const enLangPromise = import('i2l?mode=very-lazy&query=sporks!../../../lang/en.lyaml').then(enLang => {
  mark(I18nLoadEnd);
  measure(TimeToDownloadTranslations, {
    lang: 'en'
  });
  return enLang;
});
export function i18nInit() {
  return enLangPromise.then(enLang => {
    const provider = getProvider();
    mark(I18nRegisterStart);
    return Promise.all([provider.register(enLang.default), registerAllData(provider)]).then(res => {
      mark(I18nRegisterEnd);
      measure(TimeToRegisterTranslations);
      return res;
    });
  });
}
export function setI18nLocale({
  portal,
  user
} = {}) {
  const provider = getProvider();
  provider.setLocale({
    locale: user.locale,
    langEnabled: user.lang_enabled,
    timezone: portal.timezone
  });
}