'use es6';

import { OWNER_IDS_BY_REMOTE_ID } from '../actions/ActionNamespaces';
import { LEGACY_ACTIVE_OWNERS_FETCH_SUCCEEDED, OWNERS_FETCH_SUCCEEDED, OWNER_IDS_BY_EMAIL_FETCH_SUCCEEDED, OWNERS_SEARCH_FETCH_SUCCEEDED, OWNERS_UPDATE_SUCCEEDED } from '../actions/ActionTypes';
import dispatcher from 'dispatcher/dispatcher';
import either from 'transmute/either';
import getIn from 'transmute/getIn';
import { getId } from 'customer-data-objects/protocol/Identifiable';
import isNumber from 'transmute/isNumber';
import isString from 'transmute/isString';
import { defineLazyKeyStore, registerLazyKeyService } from '../store/LazyKeyStore';
import { fetchByRemoteIds } from './OwnersAPI';
import { reduceIntoState, reduceSearchResponseIntoState } from '../store/ResponseToStateTransforms';
import toString from 'transmute/toString';
const reduceIdIntoStateByRemoteId = reduceIntoState(getIn(['remoteList', 0, 'remoteId']), getId);
registerLazyKeyService({
  namespace: OWNER_IDS_BY_REMOTE_ID,
  fetch: fetchByRemoteIds
});
export default defineLazyKeyStore({
  namespace: OWNER_IDS_BY_REMOTE_ID,
  idIsValid: either(isNumber, isString),
  idTransform: toString,
  responseTransform: getId
}).defineName('OwnerIdsByRemoteIdStore').defineResponseTo([LEGACY_ACTIVE_OWNERS_FETCH_SUCCEEDED, OWNERS_FETCH_SUCCEEDED, OWNER_IDS_BY_EMAIL_FETCH_SUCCEEDED, OWNERS_UPDATE_SUCCEEDED], reduceIdIntoStateByRemoteId).defineResponseTo(OWNERS_SEARCH_FETCH_SUCCEEDED, reduceSearchResponseIntoState(reduceIdIntoStateByRemoteId)).register(dispatcher);