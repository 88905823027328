'use es6';

import { Map as ImmutableMap, Set as ImmutableSet, List } from 'immutable';
import isFunction from 'transmute/isFunction';
import invariant from 'react-utils/invariant';
import ContactRecord from 'customer-data-objects/contact/ContactRecord';
import { CONTACTS_REFRESH_QUEUED, CONTACTS_FETCH_SUCCEEDED, CONTACTS_UPDATE_STARTED, CONTACTS_UPDATE_FAILED, CONTACTS_UPDATE_SUCCEEDED, CONTACT_CREATED, CONTACTS_UPDATED, CONTACT_SECONDARY_EMAIL_REMOVED, CONTACT_SECONDARY_EMAIL_PROMOTED, CONTACT_SECONDARY_EMAIL_UPDATED, CONTACT_SECONDARY_EMAIL_ADDED, CONTACT_EMAIL_REMOVED, ASSOCIATIONS_REFRESH_QUEUED, CONTACTS_EMAIL_STORE_FETCH_SUCCEEDED } from 'crm_data/actions/ActionTypes';
import * as ContactsAPI from 'crm_data/contacts/api/ContactsAPI';
import * as GdprDeleteAPI from 'crm_data/contacts/api/GdprDeleteAPI';
import { dispatchImmediate, dispatchQueue } from 'crm_data/dispatch/Dispatch';
import { getProperty, getId } from 'customer-data-objects/model/ImmutableModel';
import { transact } from 'crm_data/flux/transact';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { CONTACT_TO_COMPANY, CONTACT_TO_DEAL, CONTACT_TO_TICKET } from 'crm_data/associations/AssociationTypes';
const getCreateContactPayload = record => record.get('properties').reduce((list, {
  value,
  source
}, property) => list.push({
  value,
  property,
  source
}), List());
const makeAssociationKey = (id, associationType) => {
  return ImmutableMap({
    objectId: id,
    objectType: CONTACT,
    associationType
  });
};
export function createContact(record, {
  isMarketable
}) {
  const payload = getCreateContactPayload(record);
  const method = isMarketable ? 'createMarketableContact' : 'createContact';
  return ContactsAPI[method](payload).then(response => {
    const contact = response.first();
    dispatchImmediate(CONTACT_CREATED, contact);
    return contact;
  });
}
export function deleteContact(vid, callback) {
  ContactsAPI.deleteContact(vid).then(() => {
    setTimeout(() => {
      dispatchImmediate(CONTACTS_UPDATED, ImmutableMap().set(`${vid}`, null));
    }, 0);
    return typeof callback === 'function' ? callback() : undefined;
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return undefined;
}
export function gdprDeleteContact(vid, email, callback) {
  GdprDeleteAPI.deleteContact([vid], email).then(() => {
    setTimeout(() => {
      dispatchImmediate(CONTACTS_UPDATED, ImmutableMap().set(`${vid}`, null));
    }, 0);
    return typeof callback === 'function' ? callback() : undefined;
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return undefined;
}
export function gdprDeleteContactWithProof(vid, email, sendProof, emailAddressesToSendProof, callback) {
  GdprDeleteAPI.deleteContactWithProof([vid], email, sendProof, emailAddressesToSendProof).then(() => {
    setTimeout(() => {
      dispatchImmediate(CONTACTS_UPDATED, ImmutableMap().set(`${vid}`, null));
    }, 0);
    return typeof callback === 'function' ? callback() : undefined;
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return undefined;
}
export function fetchByEmail(email) {
  return ContactsAPI.fetchByEmail(email).then(contacts => {
    dispatchImmediate(CONTACTS_FETCH_SUCCEEDED, contacts);
    dispatchImmediate(CONTACTS_EMAIL_STORE_FETCH_SUCCEEDED, {
      contacts,
      fetchedEmail: email
    });
    return contacts;
  });
}
export function refresh(contactIds) {
  dispatchQueue(CONTACTS_REFRESH_QUEUED, ImmutableSet(contactIds));
  dispatchQueue(ASSOCIATIONS_REFRESH_QUEUED, ImmutableSet([makeAssociationKey(contactIds[0], CONTACT_TO_COMPANY), makeAssociationKey(contactIds[0], CONTACT_TO_DEAL), makeAssociationKey(contactIds[0], CONTACT_TO_TICKET)]));
}
export function updateContactProperties(contact, nextProperties, errorHandler) {
  const id = `${getId(contact)}`;
  const properties = nextProperties.map((_, name) => getProperty(contact, name));
  dispatchImmediate(CONTACTS_UPDATE_STARTED, {
    id,
    properties,
    nextProperties
  });
  return ContactsAPI.updateContactProperties(contact, nextProperties).then(() => dispatchImmediate(CONTACTS_UPDATE_SUCCEEDED, {
    id,
    properties: nextProperties,
    prevProperties: properties,
    contact
  }), error => {
    dispatchImmediate(CONTACTS_UPDATE_FAILED, {
      id,
      properties,
      nextProperties,
      error
    });
    if (isFunction(errorHandler)) {
      errorHandler(nextProperties, error);
    }
    throw error;
  });
}
export function updateContacts(contacts) {
  invariant(ImmutableMap.isMap(contacts), 'ContactsActions: expected contacts to be a Map but got "%s"');
  return dispatchImmediate(CONTACTS_UPDATED, contacts);
}
export function promoteEmailToPrimary(contact, promotedEmail) {
  const vid = `${getId(contact)}`;
  const demotedEmail = getProperty(contact, 'email');
  const additionalEmails = getProperty(contact, 'hs_additional_emails');
  const nextAdditionalEmails = getProperty(contact, 'hs_additional_emails').split(';');
  const promotedEmailIndex = nextAdditionalEmails.indexOf(promotedEmail);
  nextAdditionalEmails.slice(promotedEmailIndex, 1);
  nextAdditionalEmails.push(demotedEmail);
  return transact({
    operation() {
      return ContactsAPI.promoteEmailToPrimary(vid, promotedEmail);
    },
    commit: [CONTACT_SECONDARY_EMAIL_PROMOTED, {
      vid,
      promotedEmail,
      additionalEmails: nextAdditionalEmails.join(';')
    }],
    rollback: [CONTACT_SECONDARY_EMAIL_PROMOTED, {
      vid,
      additionalEmails,
      promotedEmail
    }]
  });
}
export function addSecondaryEmail(contact, email) {
  invariant(contact instanceof ContactRecord, 'ContactsActions: expected contact to be a ContactRecord but got "%s"');
  invariant(typeof email === 'string', 'ContactsActions: expected email to be a string but got "%s"');
  const vid = `${getId(contact)}`;
  return transact({
    operation() {
      return ContactsAPI.addSecondaryEmail(vid, email);
    },
    commit: [CONTACT_SECONDARY_EMAIL_ADDED, {
      vid,
      email
    }],
    rollback: [CONTACT_SECONDARY_EMAIL_REMOVED, {
      vid,
      email
    }]
  });
}
export function updateSecondaryEmail(contact, prevEmail, nextEmail) {
  invariant(contact instanceof ContactRecord, 'ContactsActions: expected contact to be a ContactRecord but got "%s"');
  invariant(typeof nextEmail === 'string', 'ContactsActions: expected nextEmail to be a string but got "%s"');
  invariant(typeof prevEmail === 'string', 'ContactsActions: expected prevEmail to be a string but got "%s"');
  const vid = `${getId(contact)}`;
  return transact({
    operation() {
      return ContactsAPI.updateSecondaryEmail(vid, prevEmail, nextEmail);
    },
    commit: [CONTACT_SECONDARY_EMAIL_UPDATED, {
      vid,
      prevEmail,
      nextEmail
    }],
    rollback: [CONTACT_SECONDARY_EMAIL_UPDATED, {
      vid,
      prevEmail: nextEmail,
      nextEmail: prevEmail
    }]
  });
}
export function deleteSecondaryEmail(contact, email) {
  invariant(contact instanceof ContactRecord, 'ContactsActions: expected contact to be a ContactRecord but got "%s"');
  invariant(typeof email === 'string', 'ContactsActions: expected email to be a string but got "%s"');
  const vid = `${getId(contact)}`;
  return transact({
    operation() {
      return ContactsAPI.deleteSecondaryEmail(vid, email);
    },
    commit: [CONTACT_SECONDARY_EMAIL_REMOVED, {
      vid,
      email
    }],
    rollback: [CONTACT_SECONDARY_EMAIL_ADDED, {
      vid,
      email
    }]
  });
}
export function removeEmail(contact, email) {
  const vid = `${getId(contact)}`;
  return ContactsAPI.removeEmail(vid, email).then(() => dispatchImmediate(CONTACT_EMAIL_REMOVED, {
    vid,
    email
  }));
}