// Platforms that can be used when enrolling a user into a sequence
export const CRM = 'CRM';
export const HS_EVERYWHERE_UI = 'HS_EVERYWHERE_UI';
export const SEQUENCES_UI = 'SEQUENCES_UI';
export const SALES_ACTIVITIES = 'SALES_ACTIVITIES';
export const GMAIL_WEB = 'GMAIL_WEB';
export const MOBILE = 'MOBILE';
export const OUTLOOK_DESKTOP = 'OUTLOOK_DESKTOP';
export const PUBLIC = 'PUBLIC';
export const WORKFLOW = 'WORKFLOW';
/*
  @Deprecated naming - use SEQUENCES_UI instead
*/
export const SEQUENCESUI = SEQUENCES_UI;
export const EnrollmentPlatforms = {
  CRM,
  HS_EVERYWHERE_UI,
  SEQUENCES_UI,
  SALES_ACTIVITIES,
  GMAIL_WEB,
  MOBILE,
  OUTLOOK_DESKTOP,
  PUBLIC,
  WORKFLOW
};

// The following are platforms related to where the sidekick email modal is displayed
// https://git.hubteam.com/HubSpot/SidekickEmailModal/blob/master/static/js/constants/Platforms.ts
export const GMAIL = 'GMAIL';
export const OUTLOOK = 'OUTLOOK';
export const OUTLOOK_365 = 'OUTLOOK_365';
export const SidekickPlatforms = {
  GMAIL,
  OUTLOOK,
  OUTLOOK_365
};