'use es6';

import * as eventLogger from 'customer-data-ui-utilities/eventLogging/eventLogger';
const updateLogOptions = options => {
  options.tags = options.tags || {};
  options.tags['type'] = 'COMMUNICATOR';
};
export const logPageAction = options => {
  updateLogOptions(options);
  // Send to Sentry and New Relic
  eventLogger.logPageAction(options);
};
export const logError = options => {
  updateLogOptions(options);
  eventLogger.logError(options);
};