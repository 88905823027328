import { useEffect } from 'react';
import { useRhumbSuccessTriggerContext } from '../context/RhumbSuccessTriggerContext';

/**
 * Called in every event, so the first one that gets rendered triggers the timeline load success
 */
export const useTriggerRhumbSuccess = () => {
  const triggerRhumbSuccess = useRhumbSuccessTriggerContext();
  useEffect(() => {
    triggerRhumbSuccess();
  }, [triggerRhumbSuccess]);
};