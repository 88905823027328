import * as EmailTrackingAPI from './EmailTrackingAPI';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { dispatchImmediate } from 'crm_data/dispatch/Dispatch';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { EMAIL_TRACKING_EVENT_DELETED } from 'crm_data/actions/ActionTypes';
export const deleteEmailTrackingActivity = ({
  activityGuid,
  ownerId,
  subjectId,
  timelineItem,
  trackerKey
}) => {
  return EmailTrackingAPI.deleteEmailTrackingActivity({
    activityGuid,
    ownerId,
    subjectId,
    trackerKey
  }).then(() => {
    dispatchImmediate(EMAIL_TRACKING_EVENT_DELETED, {
      timelineItem,
      trackerKey
    });
  }, error => {
    return Promise.reject(error);
  });
};