import { useEffect } from 'react';
import userInfo from 'hub-http/userInfo';
import { useIsMounted } from '../crm_ui/hooks/useIsMounted';
export function useUserInfo(callback) {
  const isMounted = useIsMounted();
  useEffect(() => {
    userInfo().then(user => {
      if (isMounted.current) {
        callback(user);
      }
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  }, [callback, isMounted]);
}