import { parse } from 'hub-http/helpers/params';
import { closest } from 'UIComponents/utils/Dom';
import isValidPasteContent from './isValidPasteContent';
import parseHTML from './parseHTML';
import { forEachNode } from './rlib-dom';
export const styles = {
  BOLD: '700',
  ITALIC: 'italic',
  STRIKETHROUGH: 'line-through',
  SUPERSCRIPT: 'super',
  SUBSCRIPT: 'sub'
};
export const elements = {
  ANCHOR: 'a',
  BOLD: 'strong',
  ITALIC: 'em',
  STRIKETHROUGH: 'del',
  SUPERSCRIPT: 'sup',
  SUBSCRIPT: 'sub',
  H2: 'h2',
  H3: 'h3'
};
const HEADERS = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];
const LISTS = ['OL', 'UL', 'LI'];
const TABLES = ['TR', 'TD', 'TABLE'];
const SPANBI = ['SPAN', 'B', 'I'];
const CHILD_NODES_OVERRIDES = ['P', 'A', 'SPAN', ...HEADERS, ...LISTS, ...TABLES];
const DOC_WRAPPER = /docs-internal-guid/;
const COMMENT_HREF = /#cmnt_ref\d+|#cmnt\d+/;
const GOOGLE_REDIRECT = /^https?:\/\/www.google.com\/url\?/;
const LIST_CLASS_PATTERN = /lst-kix_(\w*)-(\d*)/;
let listMap = {};
const contextualStyleNames = ['font-size', 'color', 'background-color'];
let contextualStyles;
const isCommentHref = href => COMMENT_HREF.test(href);
const defaultLinkColor = 'rgb(17, 85, 204)';
const resetContextualStyles = () => {
  contextualStyles = contextualStyleNames.reduce((acc, styleName) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    acc[styleName] = {};
    return acc;
  }, {});
};
const incrementContextualStyle = (styleName, styleValue, addedLength) => {
  contextualStyles[styleName][styleValue] = (contextualStyles[styleName][styleValue] || 0) + addedLength;
};
const applyContextualStyles = body => {
  contextualStyleNames.forEach(styleName => {
    let majorityStyleValue = '';
    const contextualStyleValues = contextualStyles[styleName];

    // This finds the most used style value by length of text content
    Object.keys(contextualStyleValues).forEach(styleValue => {
      if (contextualStyleValues[styleValue] > contextualStyleValues[majorityStyleValue] || !majorityStyleValue) {
        majorityStyleValue = styleValue;
      }
    });
    if (contextualStyleValues[majorityStyleValue] / body.textContent.length < 0.75) {
      return;
    }
    forEachNode(element => {
      const closestP = closest(element, 'P');
      // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      const closestPStyleValue = closestP && closestP.style[styleName];
      const closestLI = closest(element, 'LI');
      // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      const closestLIStyleValue = closestLI && closestLI.style[styleName];

      // Remove the style from the element if it uses the majorityStyleValue and
      // the element's closest parent `p` also uses the majorityStyleValue
      // The `p` also needs the style otherwise the element will fall back to a
      // a different style that was not intendeed for the element.
      if (closestPStyleValue !== null && element.style[styleName] === majorityStyleValue && !(closestPStyleValue && closestPStyleValue !== majorityStyleValue)) {
        element.style.removeProperty(styleName);
      }
      if (closestLIStyleValue !== null && element.style[styleName] === majorityStyleValue && !(closestLIStyleValue && closestLIStyleValue !== majorityStyleValue)) {
        element.style.removeProperty(styleName);
      }

      // Bring the child out of the span if no more styles are present
      if (element.nodeName === 'SPAN' && element.style.length === 0) {
        element.parentNode.replaceChild(element.firstChild, element);
      }
    }, body.querySelectorAll(`[style*="${styleName}"]`));
  });
};
const sanitizeAnchorStyles = node => {
  node.style.removeProperty('text-decoration');
  if (node.style.color === defaultLinkColor) {
    node.style.removeProperty('color');
  }
};
const removeGoogleRedirect = href => {
  if (GOOGLE_REDIRECT.test(href)) {
    const query = href.slice(href.indexOf('?') + 1);
    const params = parse(query);
    return params.q || href;
  }
  return href;
};

/**
 * this is the internal version of https://www.github.com/aem/docs-soap
 */

const isCommentsStyle = ({
  border,
  margin
}) => border.indexOf('1px') !== -1 && border.indexOf('solid') !== -1 && border.indexOf('black') !== -1 && margin === '5px';
const isCommentStyle = style => {
  if (!style) {
    return false;
  }
  const {
    color,
    fontFamily,
    fontSize,
    lineHeight,
    margin,
    padding,
    textAlign
  } = style;
  return color === 'rgb(0, 0, 0)' && fontFamily === 'Arial' && fontSize === '11pt' && lineHeight === '1' && margin === '0px' && padding === '0px' && textAlign === 'left';
};
const isTableContainer = node => {
  return node.nodeName === 'DIV' && node.childNodes.length === 1 && TABLES.includes(node.childNodes[0].nodeName);
};
const childrenHaveCommentStyle = ({
  childNodes
}) => {
  for (let i = 0; i < childNodes.length; i++) {
    if (!isCommentStyle(childNodes[i].style)) {
      return false;
    }
  }
  return true;
};
export const wrapNodeAnchor = (cleanChildren, href) => {
  if (href === '') {
    return document.createElement('a').append(...cleanChildren);
  }
  const anchor = document.createElement('a');
  anchor.href = removeGoogleRedirect(href);
  let spanChild = cleanChildren[0];
  while (spanChild && spanChild.nodeName !== 'SPAN') {
    const tempChildren = spanChild.childNodes;
    if (tempChildren.length) {
      spanChild = tempChildren[0];
    } else {
      spanChild = null;
    }
  }
  if (spanChild && spanChild.nodeName === 'SPAN') {
    sanitizeAnchorStyles(spanChild);
    anchor.style.cssText = spanChild.style.cssText;
    spanChild.removeAttribute('style');
  }
  anchor.append(...cleanChildren);
  return anchor;
};
export const wrapNodeInline = (node, style) => {
  const el = document.createElement(style);
  el.appendChild(node.cloneNode(true));
  return el;
};
export const wrapNode = (inner, result) => {
  let newNode = result.cloneNode(true);
  const stylePropertyPairs = [['fontWeight', 'BOLD'], ['fontStyle', 'ITALIC'], ['textDecoration', 'UNDERLINE'], ['textDecoration', 'STRIKETHROUGH'], ['verticalAlign', 'SUPERSCRIPT'], ['verticalAlign', 'SUBSCRIPT']];
  if (inner && inner.style) {
    stylePropertyPairs.forEach(([prop, style]) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (inner.style[prop] === styles[style]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newNode = wrapNodeInline(newNode, elements[style]);
      }
    });
  }
  return newNode;
};
export const getFlatChildNodes = node => {
  const childNodes = [];
  function getChildNodes(parent) {
    const children = [...parent.childNodes];
    children.forEach(child => {
      childNodes.push(child);
      if (child.childNodes.length) {
        getChildNodes(child);
      }
    });
  }
  if (node.childNodes.length) {
    getChildNodes(node);
  }
  return childNodes;
};

/**
 * Takes a block-level element (paragraphs and bullets) and applies inline styles as necessary
 */
export const applyBlockStyles = dirty => {
  const node = dirty.cloneNode(true);
  let newNode = document.createTextNode(node.textContent);
  let styledNode;
  if (node.childNodes.length) {
    if (node.className === 'title') {
      newNode = wrapNodeInline(newNode, elements.H2);
    } else if (node.className === 'subtitle') {
      newNode = wrapNodeInline(newNode, elements.H3);
    } else if (node.childNodes[0].style) {
      styledNode = node.childNodes[0];
    }
  }
  newNode = wrapNode(styledNode, newNode);
  return newNode;
};

/**
 * parse out the inline style properties and wrap the text in HTML elements instead
 */
const inlineStylePairs = {
  'font-style': {
    italic: 'em'
  },
  'font-weight': {
    bold: 'strong',
    700: 'strong'
  },
  'vertical-align': {
    sub: 'sub',
    super: 'sup'
  }
};
export const applyInlineStyles = node => {
  let didStyle = false;
  let styledNode = node;
  const nodeStyles = node.style;
  for (let index = 0; index < nodeStyles.length; index++) {
    const styleName = nodeStyles[index];
    if (Object.prototype.hasOwnProperty.call(inlineStylePairs, styleName)) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const newTagName = inlineStylePairs[styleName][nodeStyles[styleName]];
      if (newTagName) {
        if (styleName === 'vertical-align') {
          node.style.removeProperty('font-size');
        }
        node.style.removeProperty(styleName);
        styledNode = document.createElement(newTagName);
        styledNode.append(...getCleanNode(node)); // eslint-disable-line @typescript-eslint/no-use-before-define
        didStyle = styledNode.childNodes.length > 0;
        break;
      }
    }
  }
  return {
    didStyle,
    styledNode
  };
};

// Only used in parseList - mutates newWrapper
const handleExportedLists = (className, newNode, newWrapper) => {
  const listClasses = LIST_CLASS_PATTERN.exec(className);
  if (listClasses) {
    const [, key, depth] = listClasses;
    if (className.indexOf('start') !== -1) {
      newWrapper.appendChild(newNode);
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (listMap[key]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        listMap[key][depth] = newWrapper;
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        listMap[key][`${depth - 1}`].appendChild(newWrapper);
        return true;
      }
      // depth 0 -> make new entry AND allow node to be added to main nodes (entry point of this list)
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      listMap[key] = {
        [depth]: newWrapper
      };
      return false;
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    listMap[key][depth].appendChild(newNode);
    return true;
  }
  return false;
};
export const parseList = node => {
  const {
    className,
    childNodes
  } = node;
  const newWrapper = node.cloneNode(false);
  newWrapper.removeAttribute('id');
  newWrapper.removeAttribute('class');
  const newNode = document.createDocumentFragment();
  const items = [];
  for (let i = 0; i < childNodes.length; i++) {
    const childNode = childNodes[i];
    if (childNode.nodeName === 'A' && isCommentHref(childNode.href)) {
      return [];
    }
    items.push(...getCleanNode(childNode)); // eslint-disable-line @typescript-eslint/no-use-before-define
  }
  items.map(i => newNode.appendChild(i));
  if (handleExportedLists(className, newNode, newWrapper)) {
    return [];
  }
  newWrapper.appendChild(newNode);
  return [newWrapper];
};
const isEmptyNode = node => {
  if (node.nodeName === '#text') {
    return node.textContent === '';
  }
  if (node.nodeName === 'IMG') {
    return !node.src;
  }
  return node.innerHTML === '';
};

// replacing margin-left with padding-left since tinymce uses padding-left for indentation
// Google Docs uses pts but this replaces it with px since tinymce uses px
export const sanitizeIndentStyle = node => {
  node.style.removeProperty('padding');
  const marginLeft = node.style.marginLeft;
  if (marginLeft) {
    // only paragraphs can be indented from the tinymce editor
    if (node.nodeName === 'P') {
      const marginLeftPx = Number(node.style.marginLeft.replace(/[A-Za-z]./, '')) * 1.333;
      const paddingLeft = marginLeftPx - marginLeftPx % 40;
      if (paddingLeft) {
        node.style.paddingLeft = `${paddingLeft}px`;
      }
    }
  }
  node.style.removeProperty('margin');
};
const inlineStyleSanitation = {
  border: ['TABLE', 'TD'],
  textDecoration: ['underline', 'line-through'],
  textAlign: ['center', 'right', 'justify'],
  backgroundColor: ['transparent', 'rgb(255, 255, 255)']
};
export const sanitizeInlineStyles = node => {
  sanitizeIndentStyle(node);
  Object.keys(inlineStylePairs).forEach(styleName => {
    node.style.removeProperty(styleName);
  });
  if (!inlineStyleSanitation.border.includes(node.nodeName)) {
    node.style.removeProperty('border');
  }
  if (!inlineStyleSanitation.textDecoration.includes(node.style.textDecoration)) {
    node.style.removeProperty('text-decoration');
  }
  if (node.nodeName !== 'P' || !inlineStyleSanitation.textAlign.includes(node.style.textAlign)) {
    node.style.removeProperty('text-align');
  }
  if (inlineStyleSanitation.backgroundColor.includes(node.style.backgroundColor)) {
    node.style.removeProperty('background-color');
  }
  if (node.nodeName === 'LI') {
    node.style.removeProperty('font-size');
  }
  if (node.nodeName === 'SPAN' && node.style.length === 0 && node.firstChild) {
    if (node.childNodes.length === 1) {
      return node.firstChild;
    } else {
      return node;
    }
  }
  contextualStyleNames.forEach(styleName => {
    const styleValue = node.style[styleName];
    if (styleValue) {
      const addedLength = node.textContent.length;
      incrementContextualStyle(styleName, styleValue, addedLength);
    }
  });
  return node;
};

// @ts-expect-error ts-migrate(7023) FIXME: 'getCleanNode' implicitly has return type 'any' be... Remove this comment to see the full error message
const getCleanNode = node => {
  if (node.nodeName === 'SPAN') {
    const {
      didStyle,
      styledNode
    } = applyInlineStyles(node);
    if (didStyle) {
      return [styledNode];
    }
  }
  if (node.nodeName !== '#text') {
    node = sanitizeInlineStyles(node);
  }

  // recursive base case: 0 or 1 child nodes, we always know how to handle this case
  if (node.childNodes && !isTableContainer(node) && (node.childNodes.length <= 1 || CHILD_NODES_OVERRIDES.includes(node.nodeName) || node.nodeName === 'DIV' && isCommentsStyle(node.style)) && !DOC_WRAPPER.test(node.id)) {
    let newWrapper = null;
    // create a new target node
    let newNode = document.createTextNode(node.textContent);
    if (LISTS.includes(node.nodeName) || TABLES.includes(node.nodeName) || HEADERS.includes(node.nodeName)) {
      return parseList(node);
    } else if (node.nodeName === 'P') {
      // Google is pretty consistent about new paragraphs in <p></p> tags tho...
      if (node.childNodes.length > 1) {
        return parseList(node);
      }
      newWrapper = node.cloneNode(false);
      newWrapper.removeAttribute('id');
      newWrapper.removeAttribute('class');
      const childNodes = getFlatChildNodes(node);
      if (childNodes.length === 0) {
        return [];
      } else if (childNodes.length > 1) {
        newNode = getCleanNode(node.childNodes[0]);
      } else {
        newNode = applyBlockStyles(node);
      }
    } else if (node.nodeName === '#text') {
      return [newNode];
    } else if (node.nodeName === 'BR' || node.nodeName === 'HR') {
      newNode = document.createElement(node.tagName);
      return [newNode];
    } else if (node.nodeName === 'IMG') {
      return [node.cloneNode(true)];
    } else if (node.nodeName === 'A') {
      if (isCommentHref(node.href)) {
        return [];
      }
      // wrap the child node(s) in an anchor
      const cleanChildren = [];
      for (let i = 0; i < node.childNodes.length; i++) {
        const cleanNode = getCleanNode(node.childNodes[i]);
        cleanChildren.push(...cleanNode);
      }
      if (cleanChildren.length === 0) {
        return [];
      }
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'void | HTMLAnchorElement' is not assignable ... Remove this comment to see the full error message
      newNode = wrapNodeAnchor(cleanChildren, node.href);
      return [newNode];
    } else if (node.nodeName === 'DIV' && isCommentsStyle(node.style) && childrenHaveCommentStyle(node)) {
      return [];
    } else if (SPANBI.includes(node.nodeName)) {
      const cleanChildren = [];
      for (let i = 0; i < node.childNodes.length; i++) {
        const cleanNode = getCleanNode(node.childNodes[i]);
        cleanChildren.push(...cleanNode);
      }
      if (cleanChildren.length === 0) {
        return [];
      }
      switch (node.nodeName) {
        case 'I':
          // @ts-expect-error ts-migrate(2740) FIXME: Type 'HTMLElement' is missing the following proper... Remove this comment to see the full error message
          newNode = document.createElement('em');
          break;
        case 'B':
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'HTMLElement' is not assignable to type 'Text... Remove this comment to see the full error message
          newNode = document.createElement('strong');
          break;
        case 'SPAN':
          if (cleanChildren.length === 1 && cleanChildren[0].nodeName === 'A') {
            newNode = cleanChildren[0];
            sanitizeAnchorStyles(node);
            newNode.style.cssText = node.style.cssText;
            return [newNode];
          }
          newNode = node.cloneNode(false);
          break;
        default:
          newNode = node.cloneNode(false);
          break;
      }
      newNode.append(...cleanChildren);
      return [newNode];
    } else {
      newWrapper = node.cloneNode(false);
      newNode = node.childNodes.length ? getCleanNode(node.childNodes[0]) : [];
    }
    if (newWrapper) {
      if (Array.isArray(newNode)) {
        newNode.forEach(n => {
          if (!isEmptyNode(n)) {
            newWrapper.appendChild(n);
          }
        });
      } else {
        if (!isEmptyNode(newNode)) {
          newWrapper.appendChild(newNode);
        }
      }
      if (newWrapper.innerHTML === '') return [];
      return [newWrapper];
    }
    // if it's nothing that we want to specifically handle in the composer just return the node,
    // draft-js will clean it up for us
    return [node.cloneNode(true)];
  }
  if (node.childNodes) {
    const nodes = [];
    for (let i = 0; i < node.childNodes.length; i++) {
      // @ts-expect-error ts-migrate(7022) FIXME: 'nextNode' implicitly has type 'any' because it do... Remove this comment to see the full error message
      const nextNode = getCleanNode(node.childNodes[i]);
      nodes.push(...nextNode);
    }
    return nodes;
  }
  return [node];
};
const shouldKeepNode = node => {
  // Windows clipboards append the <!--StartFragment--> and <!--EndFragment--> which we don't want
  if (node.nodeName === '#comment') {
    return false;
  }
  // Ignore meta and style tags
  if (node.nodeName === 'META' || node.nodeName === 'STYLE') {
    return false;
  }
  // Windows clipboards seem to insert a stray space character at the start and end of body tag
  if (node.nodeName === '#text' && node.parentNode.nodeName === 'BODY' && (node.previousSibling === null && node.nextSibling || node.previousSibling && node.nextSibling === null)) {
    return false;
  }
  return true;
};
const getCleanDocument = dirty => {
  resetContextualStyles();
  const body = document.createElement('body');
  const nodes = dirty.childNodes;
  const cleanNodes = [];
  for (let i = 0; i < nodes.length; i++) {
    if (shouldKeepNode(nodes[i])) {
      const cleanNode = getCleanNode(nodes[i]);
      cleanNodes.push(...cleanNode);
    }
  }
  cleanNodes.map(node => body.appendChild(node.cloneNode(true)));
  applyContextualStyles(body);
  listMap = {};
  return body;
};
export default function convertGoogleToHTML(clipboardContent, htmlParser = parseHTML) {
  isValidPasteContent(clipboardContent);
  return getCleanDocument(htmlParser(clipboardContent.replace(/(\r\n|\n|\r)/, ''))).outerHTML;
}
export const convertGoogleDocToHTML = doc => convertGoogleToHTML(doc);