import { LEGACY_SIDEBAR_CARD_TYPES } from '../../constants/LegacySidebarCardType';

// Legacy card should not have config type or query fragments
// Extra props on legacy card
// Legacy Sidebar Card Metadata
export const TOOL_LINKS_METADATA = {
  cardType: 'TOOL_LINKS',
  legacySidebarCardType: LEGACY_SIDEBAR_CARD_TYPES.ToolLinksCard,
  hasLegacyExtraProps: true,
  renderAsLegacyCardInCrmLocationResolver: true
};