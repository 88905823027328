import { BET_PORTAL_OBJECT_ASSOCIATIONS_ADD, BET_PORTAL_OBJECT_ASSOCIATIONS_REMOVE
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
import { createPortalEngagementAssociation, removePortalEngagementAssociation } from '../api/BETAssociationsAPI';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { dispatchImmediate } from 'crm_data/dispatch/Dispatch';
import { ENGAGEMENT } from 'customer-data-objects/constants/ObjectTypes';
export function addBETPortalObjectAssociations(engagementId, BETPortalAssociations) {
  createPortalEngagementAssociation(engagementId, BETPortalAssociations.map(assoc => assoc.get('objectId'))).then(() => {
    dispatchImmediate(BET_PORTAL_OBJECT_ASSOCIATIONS_ADD, {
      objectType: ENGAGEMENT,
      engagementId,
      BETPortalAssociations
    });
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
}
export function removeBETPortalObjectAssociation(engagementId, BETPortalAssociation) {
  removePortalEngagementAssociation(engagementId, BETPortalAssociation.get('objectId')).then(() => {
    dispatchImmediate(BET_PORTAL_OBJECT_ASSOCIATIONS_REMOVE, {
      objectType: ENGAGEMENT,
      engagementId,
      BETPortalAssociation
    });
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
}