import { useMemo } from 'react';
const isContainerNode = node => {
  return node.type === 'CONTAINER';
};
const LEGACY_CONTEXT_REQUIRED_FOR_CARDTYPES = ['LEGACY_SIDEBAR_CARD', 'ASSOCIATION_V3'];
const requiresLegacyContexts = card => {
  return LEGACY_CONTEXT_REQUIRED_FOR_CARDTYPES.includes(card.definition.cardType);
};
const containerHasLegacySidebarCard = container => {
  return container.contents.some(content => {
    if (isContainerNode(content)) {
      return containerHasLegacySidebarCard(content);
    } else {
      return requiresLegacyContexts(content);
    }
  });
};
export const useHasLegacySidebarCard = content => {
  return useMemo(() => {
    let hasLegacySidebarCard;
    if (isContainerNode(content)) {
      hasLegacySidebarCard = containerHasLegacySidebarCard(content);
    } else {
      hasLegacySidebarCard = requiresLegacyContexts(content);
    }
    return hasLegacySidebarCard;
  }, [content]);
};