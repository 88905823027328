// @ts-expect-error module not typed
import { uploadFile } from 'draft-plugins/api/FileManagerApi';
import { fromJS, Map as ImmutableMap, List } from 'immutable';
import emptyFunction from 'react-utils/emptyFunction';
import partial from 'transmute/partial';
// @ts-expect-error module not typed
import ToolbarUploadActionPlugin from './ToolbarUploadActionPlugin';
export function handleFileUpload(onAttachmentUploadStarted = emptyFunction, onAttachmentSelected = emptyFunction, onAttachmentUploadFailed = emptyFunction, options = {}, event) {
  var _event$target;
  const files = (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.files;
  if (!files || files.length === 0) {
    return;
  }
  let inProgressFiles = List([]);
  Array.prototype.slice.call(files).forEach(file => {
    const fileToUpload = ImmutableMap({
      name: file.name,
      size: file.size,
      type: file.type
    });
    inProgressFiles = inProgressFiles.push(fileToUpload);
    uploadFile(file, options).then(data => fromJS(data)).then(data => {
      let fileData = data.getIn(['objects', 0]);
      if (fileData) {
        // @ts-expect-error adding a new prop to FileMap
        fileData = fileData.merge({
          originalName: file.name
        });
        onAttachmentSelected(fileData);
      }
    }).catch(error => onAttachmentUploadFailed(error, fileToUpload));
  });
  onAttachmentUploadStarted(inProgressFiles);
}
export default function AttachmentPlugin(opts = {}) {
  opts.onChange = partial(handleFileUpload, opts.onAttachmentUploadStarted, opts.onAttachmentSelected, opts.onAttachmentUploadFailed, opts.fileOptions);
  return ToolbarUploadActionPlugin(opts);
}