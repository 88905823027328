import httpClient from 'hub-http/clients/apiClient';
export const EMAIL_SETTINGS_URL = 'cursus/settings/email';
export const DEFAULT_SEND_FROM_URL = `${EMAIL_SETTINGS_URL}/DEFAULT_SEND_FROM`;
export const getEmailSettings = () => {
  return httpClient.get(EMAIL_SETTINGS_URL);
};
export const updateDefaultSendFromEmail = email => {
  return httpClient.put(DEFAULT_SEND_FROM_URL, {
    data: email,
    headers: {
      'content-type': 'text/plain'
    }
  });
};
export const deleteDefaultSendFromEmail = () => {
  return httpClient.delete(DEFAULT_SEND_FROM_URL);
};