import { CardAction, CardEmptyState, ChickletHoverActions, ChickletPrimaryDisplayLabel, ChickletSecondaryDisplayLabels } from '../../constants/BehaviorTypes';
import { getDefaultChickletHoverActions } from '../../behaviors/default/getDefaultChickletHoverActions';
import DefaultCardAction from '../../behaviors/default/DefaultCardAction';
import DefaultCardEmptyState from '../../behaviors/default/DefaultCardEmptyState';
import DefaultChickletPrimaryDisplayLabel from '../../behaviors/default/DefaultChickletPrimaryDisplayLabel';
import getDefaultChickletSecondaryDisplayLabels from '../../behaviors/default/getDefaultChickletSecondaryDisplayLabels';
export const Default = {
  [CardEmptyState]: () => DefaultCardEmptyState,
  [CardAction]: () => DefaultCardAction,
  [ChickletHoverActions]: () => getDefaultChickletHoverActions,
  [ChickletPrimaryDisplayLabel]: () => DefaultChickletPrimaryDisplayLabel,
  [ChickletSecondaryDisplayLabels]: () => getDefaultChickletSecondaryDisplayLabels
};