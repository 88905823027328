// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
import { fromJS, Map as ImmutableMap } from 'immutable';
import partial from 'transmute/partial';
import { EmailTrackingKeyTypes, EmailTrackingKeyTypeMap
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/constants/EmailTrackingConstants';
const BATCH_URI_BASE = 'stream/v1/activities/hubspot/portal/batch/aggregated';
const getKeyToTrackingMap = (eventAggregates, keyToCreatedByMap, keyType) => {
  return eventAggregates.reduce((acc, emailTracking, key) => {
    const createdBy = keyToCreatedByMap.get(key);
    const baseKeyMap = ImmutableMap({
      [keyType]: key,
      type: keyType
    });
    const keyMap = createdBy ? baseKeyMap.set('createdBy', createdBy) : baseKeyMap;
    return acc.set(keyMap, emailTracking);
  }, ImmutableMap());
};
export const getFormattedResponse = (keyToCreatedByMap, keyType, data) => {
  const response = fromJS(data);
  const eventAggregates = response.get('event_aggregates');
  return getKeyToTrackingMap(eventAggregates, keyToCreatedByMap, keyType);
};
const getFormattedPayload = requestPayload => {
  let payloadBodyKey;
  const formattedPayloadBody = requestPayload.map(requestKey => {
    if (!payloadBodyKey) {
      payloadBodyKey = EmailTrackingKeyTypeMap.getIn([requestKey.get('type'), 'bodyKey']);
    }
    return requestKey.get('type') === EmailTrackingKeyTypes.messageId ? ImmutableMap({
      message_id: requestKey.get(EmailTrackingKeyTypes.messageId),
      creator_hub_spot_user_id: requestKey.get('createdBy')
    }) : requestKey.get(EmailTrackingKeyTypes.trackerKey);
  });
  return ImmutableMap({
    [payloadBodyKey]: formattedPayloadBody
  });
};
export const fetchEmailTracking = function fetchEmailTracking(requestPayload) {
  const formattedPayload = getFormattedPayload(requestPayload);
  let keyType;
  const keyToCreatedByMap = requestPayload.reduce((acc, requestKey) => {
    if (!keyType) {
      keyType = requestKey.get('type');
    }
    const key = requestKey.get(keyType);
    const createdBy = requestKey.get('createdBy');
    return acc.set(key, createdBy);
  }, ImmutableMap());
  const requestType = EmailTrackingKeyTypeMap.getIn([keyType, 'uri']);
  const requestUri = `${BATCH_URI_BASE}/${requestType}?casing=snake`;
  return ImmutableAPI.post(requestUri, formattedPayload, partial(getFormattedResponse, keyToCreatedByMap, keyType));
};
export function deleteEmailTrackingActivity({
  activityGuid,
  ownerId,
  subjectId,
  trackerKey
}) {
  const requestPayload = {
    activity_guid: activityGuid,
    owner_id: ownerId,
    tracker_key: trackerKey,
    vid: subjectId
  };
  const url = 'tracking/write/v1/trackers/app/email/visibility?casing=snake';
  return ImmutableAPI.post(url, requestPayload);
}