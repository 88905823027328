// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import TeamsStore from 'crm_data/teams/TeamsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { LOADING } from 'crm_data/flux/LoadingStatus';
import get from 'transmute/get';
const getTeamByUserId = (userId, teams) => {
  const matchingTeam = teams.find(team => get('userIds', team).includes(userId));
  if (!matchingTeam) {
    return null;
  }
  return matchingTeam.name;
};
const TeamNameDependency = {
  stores: [TeamsStore],
  deref({
    userId
  }) {
    if (!userId) {
      return null;
    }
    const teams = TeamsStore.get();
    // TeamsStore returns an Empty Map when no teams exist
    if (!teams) {
      return LOADING;
    }
    return getTeamByUserId(userId, teams);
  }
};
export default TeamNameDependency;