'use es6';

import { get, put, del } from 'crm_data/api/ImmutableAPI';
import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
const API = 'engagements/v1/pinned';
const makeURI = (...args) => {
  return `${API}/${args.join('/')}`;
};
export function fetch(objectType, objectId) {
  return get(makeURI(objectType, objectId), {
    throwOnUnauthorized: true
  }, EngagementRecord.fromJS);
}
export function save(objectType, objectId, engagementId) {
  return put(makeURI(objectType, objectId, engagementId));
}
export function unpin(objectType, objectId) {
  return del(makeURI(objectType, objectId));
}