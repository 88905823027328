'use es6';

import { EditorState } from 'draft-js';
import { VIDEO_CONSTANTS } from '../lib/constants';
const {
  DRAFT_ATOMIC_TYPE_VIDEO
} = VIDEO_CONSTANTS;
const VYETOKEN_PARAM_PATTERN = /\?vyetoken=[^&]*/g;
export default (editorState => {
  const content = editorState.getCurrentContent();
  let blockMap = content.getBlockMap();
  blockMap.filter(block => block.getType() === 'atomic' && block.getData().get('atomicType') === DRAFT_ATOMIC_TYPE_VIDEO).forEach(block => {
    const blockData = block.getData();
    const originalUrl = blockData.get('url');
    const anchor = document.createElement('a');
    anchor.href = originalUrl;
    anchor.search = anchor.search.replace(VYETOKEN_PARAM_PATTERN, '');
    const dataWithUpdatedUrl = blockData.set('url', anchor.href);
    const blockWithUpdatedData = block.set('data', dataWithUpdatedUrl);
    blockMap = blockMap.set(block.getKey(), blockWithUpdatedData);
  });
  const newContent = content.set('blockMap', blockMap);
  return EditorState.createWithContent(newContent);
});