import { makeGetCommentStates, setDraftComment as setDraftCommentAction, clearDraftComment as clearDraftCommentAction, startEditingComment as startEditingCommentAction, endEditingComment as endEditingCommentAction } from '../eventCardsSlice';
import { useTimelineDispatch, useTimelineSelector } from '../../reduxHooks';
import { useCallback } from 'react';
export function useCommentStates({
  commentId,
  eventId
}) {
  const getCommentStates = makeGetCommentStates(eventId);
  const commentStatesFromRedux = useTimelineSelector(getCommentStates);
  const isEditingComment = commentStatesFromRedux[commentId] ? commentStatesFromRedux[commentId].isEditing : false;
  const draftComment = commentStatesFromRedux[commentId] ? commentStatesFromRedux[commentId].draft : undefined;
  const dispatch = useTimelineDispatch();
  const setDraftComment = useCallback(value => {
    dispatch(setDraftCommentAction({
      eventId,
      commentId,
      value
    }));
  }, [commentId, dispatch, eventId]);
  const clearDraftComment = useCallback(() => {
    dispatch(clearDraftCommentAction({
      eventId,
      commentId
    }));
  }, [commentId, dispatch, eventId]);
  const setIsEditingComment = useCallback(isEditingCommentFromCard => {
    if (isEditingCommentFromCard) {
      dispatch(startEditingCommentAction({
        eventId,
        commentId
      }));
    } else {
      dispatch(endEditingCommentAction({
        eventId,
        commentId
      }));
    }
  }, [commentId, dispatch, eventId]);
  return {
    clearDraftComment,
    draftComment,
    isEditingComment,
    setDraftComment,
    setIsEditingComment
  };
}