import I18n from 'I18n';
import { isValidEmail } from 'customer-data-properties/validation/PropertyValidations';
const SOCIAL_SOURCE_ID = 'iwsmp';
const titlecase = string => string.replace(/(?:^|\s)\S/g, a => {
  return a.toUpperCase();
});
export const humanizeSource = sourceText => {
  if (sourceText === 'CRM_UI') {
    return I18n.text('components.propertySource.sourceTypes.crmUi');
  }
  return titlecase(sourceText.toLowerCase().replace(/_/g, ' '));
};
export const getSourceNameFromSourceId = function getSourceNameFromSourceId(sourceId, defaultSourceName) {
  switch (sourceId) {
    case SOCIAL_SOURCE_ID:
      return I18n.text('components.propertySource.sourceTypes.social');
    case 'FORWARD_TO_CRM':
      return I18n.text('components.propertySource.sourceTypes.forwardToCrm');
    case 'EMAIL_INTEGRATION':
      return I18n.text('components.propertySource.sourceTypes.emailIntegration');
    case 'GMAIL_INTEGRATION':
      return I18n.text('components.propertySource.sourceTypes.gmailIntegration');
    default:
      return isValidEmail(sourceId) ? String(sourceId).toLowerCase() : defaultSourceName;
  }
};
export default function ({
  source,
  sourceId,
  sourceType,
  'source-vids': sourceVids
}) {
  if (sourceVids && sourceVids.size && sourceId !== SOCIAL_SOURCE_ID) {
    return I18n.text('components.propertySource.sourceTypes.merge');
  }
  const type = sourceType || source;
  switch (type) {
    case 'API':
      return I18n.text('components.propertySource.sourceTypes.api');
    case 'BIDEN': // BIDEN is deprecated in favor of COMPANY_INSIGHTS
    case 'COMPANY_INSIGHTS':
      return I18n.text('components.propertySource.sourceTypes.companyInsights');
    case 'AI_GROUP':
      return I18n.text('components.propertySource.sourceTypes.ai_group');
    case 'CALCULATED':
      if (sourceId && sourceId === 'deal-abandonment-worker') {
        return I18n.text('components.propertySource.sourceTypes.ecommerceIntegration');
      }
      return I18n.text('components.propertySource.sourceTypes.calculated');
    case 'COMPANIES':
      if (sourceId && sourceId.includes('ContactOwnerToCompanyPropagator')) {
        return I18n.text('components.propertySource.sourceTypes.crmSetting');
      }
      return I18n.text('components.propertySource.sourceTypes.companies');
    // CONTACTS_WEB, CONTACTS, SALES, and CRM_UI record the email of the user who made the
    // change as the source-id
    case 'CONTACTS_WEB':
    case 'CONTACTS':
      return getSourceNameFromSourceId(sourceId, I18n.text('components.propertySource.sourceTypes.contacts'));
    case 'SALES':
      {
        const salesSourceText = I18n.text('components.propertySource.sourceTypes.sales');
        if (sourceId) {
          return getSourceNameFromSourceId(sourceId, salesSourceText);
        }
        return salesSourceText;
      }
    case 'CRM_UI':
      if (sourceId === 'ContactsOwnerUpdateToTeamUpdater') {
        return I18n.text('components.propertySource.sourceTypes.teamSync');
      }
      return getSourceNameFromSourceId(sourceId, humanizeSource(sourceType || source || ''));
    case 'BATCH_UPDATE':
      if (sourceId && isValidEmail(sourceId)) {
        return I18n.text('components.propertySource.sourceTypes.bulkEditWithSource', {
          sourceId: String(sourceId).toLowerCase()
        });
      }
      return I18n.text('components.propertySource.sourceTypes.apiBulkEdit');
    case 'BEHAVIORAL_EVENTS':
      return I18n.text('components.propertySource.sourceTypes.customBehavioralEvents');
    case 'DEALS':
      return I18n.text('components.propertySource.sourceTypes.deals');
    case 'EMAIL':
      if (sourceId && sourceId === 'SOURCE_SDFC_SYNC') {
        return I18n.text('components.propertySource.sourceTypes.salesforce');
      }
      if (sourceId && sourceId === 'SOURCE_IMPORT') {
        return I18n.text('components.propertySource.sourceTypes.import');
      }
      if (sourceId && sourceId === 'SOURCE_HUBSPOT_CUSTOMER') {
        return I18n.text('components.propertySource.sourceTypes.hubspotUser');
      }
      return I18n.text('components.propertySource.sourceTypes.email');
    case 'ENGAGEMENTS':
      return I18n.text('components.propertySource.sourceTypes.engagements');
    case 'EXTENSION':
      return I18n.text('components.propertySource.sourceTypes.salesExtension');
    case 'FORM':
      return I18n.text('components.propertySource.sourceTypes.forms');
    case 'FORWARD_TO_CRM':
      return I18n.text('components.propertySource.sourceTypes.forwardToCrm');
    case 'HEISENBERG':
      return I18n.text('components.propertySource.sourceTypes.hubspotLocationService');
    case 'IMPORT':
      return I18n.text('components.propertySource.sourceTypes.import');
    case 'MIGRATION':
      return I18n.text('components.propertySource.sourceTypes.migration');
    case 'PAYMENTS':
      return I18n.text('components.propertySource.sourceTypes.payments');
    case 'SALESFORCE':
      return I18n.text('components.propertySource.sourceTypes.salesforce');
    case 'SOCIAL':
      return I18n.text('components.propertySource.sourceTypes.social');
    case 'WAL_INCREMENTAL':
    case 'TASK':
      if (sourceId && sourceId === SOCIAL_SOURCE_ID) {
        return I18n.text('components.propertySource.sourceTypes.social');
      }
      return I18n.text('components.propertySource.sourceTypes.backgroundTask');
    case 'WORKFLOWS':
      return I18n.text('components.propertySource.sourceTypes.workflow');
    case 'BCC_TO_CRM':
      return I18n.text('components.propertySource.sourceTypes.bccToCRM');
    case 'EMAIL_INTEGRATION':
      return I18n.text('components.propertySource.sourceTypes.emailIntegration');
    case 'SIDEKICK':
      return I18n.text('components.propertySource.sourceTypes.sidekick');
    case 'MOBILE_IOS':
    case 'MOBILE_ANDROID':
      return getSourceNameFromSourceId(sourceId, humanizeSource(sourceType || source || ''));
    default:
      return humanizeSource(sourceType || source || '');
  }
}