'use es6';

import { HIDDEN as CDO_HIDDEN, NOT_SPECIFIED as CDO_NOT_SPECIFIED, READ_ONLY as CDO_READ_ONLY } from 'customer-data-objects/property/AccessLevel';

// Aliased to avoid breaking usages, prefer using values from customer-data-objects instead
/** @deprecated import from 'customer-data-objects/property/AccessLevel' instead */
export const NOT_SPECIFIED = CDO_NOT_SPECIFIED;
/** @deprecated import from 'customer-data-objects/property/AccessLevel' instead */
export const READ_ONLY = CDO_READ_ONLY;
/** @deprecated import from 'customer-data-objects/property/AccessLevel' instead */
export const HIDDEN = CDO_HIDDEN;