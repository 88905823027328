'use es6';

import dispatcher from 'dispatcher/dispatcher';
import { ASSOCIATE_COMPANY_AND_DEAL, DISASSOCIATE_COMPANY_AND_DEAL, ASSOCIATE_CONTACT_AND_DEAL, DISASSOCIATE_CONTACT_AND_DEAL } from 'crm_schema/association/AssociationActionTypes';
import { DEAL_CREATED, DEALS_UPDATE_STARTED, DEALS_UPDATE_SUCCEEDED, DEALS_UPDATE_FAILED } from 'crm_data/actions/ActionTypes';
import { fetch } from 'crm_data/deals/api/DealsAPI';
import { definePooledObjectStore } from 'crm_data/flux/definePooledObjectStore';
import { List } from 'immutable';
import { getProperty, setProperty } from 'customer-data-objects/model/ImmutableModel';
import registerPooledObjectService from 'crm_data/flux/registerPooledObjectService';
const PREFIX = 'DEALS';
registerPooledObjectService({
  actionTypePrefix: PREFIX,
  fetcher: fetch
});
const DealsStore = definePooledObjectStore({
  actionTypePrefix: PREFIX
}).defineResponseTo(DEAL_CREATED, (state, deal) => {
  const dealId = `${deal.get('dealId')}`;
  return state.set(dealId, deal);
}).defineResponseTo(DEALS_UPDATE_STARTED, (state, {
  id,
  nextProperties
}) => {
  if (!state.has(id)) {
    return state;
  }
  return state.updateIn([id], deal => nextProperties.reduce((acc, value, name) => setProperty(acc, name, value), deal));
}).defineResponseTo(DEALS_UPDATE_SUCCEEDED, (state, {
  id,
  deal
}) => state.set(id, deal)).defineResponseTo(DEALS_UPDATE_FAILED, (state, {
  id,
  nextProperties,
  properties
}) => state.updateIn([id], deal => properties.reduce((acc, value, name) => {
  // don't overwrite changes made since the update began
  if (nextProperties.get(name) !== getProperty(acc, name)) {
    return acc;
  }
  return setProperty(acc, name, value);
}, deal))).defineResponseTo(ASSOCIATE_COMPANY_AND_DEAL, (state, {
  objectId,
  subjectId
}) => {
  const dealId = `${subjectId}`;
  if (!state.get(dealId)) {
    return state;
  }
  return state.setIn([dealId, 'associations', 'associatedCompanyIds'], List.of(objectId));
}).defineResponseTo(DISASSOCIATE_COMPANY_AND_DEAL, (state, {
  subjectId
}) => {
  const dealId = `${subjectId}`;
  if (!state.get(dealId)) {
    return state;
  }
  return state.setIn([dealId, 'associations', 'associatedCompanyIds'], List());
}).defineResponseTo(ASSOCIATE_CONTACT_AND_DEAL, (state, {
  objectId,
  subjectId
}) => {
  const dealId = `${subjectId}`;
  if (!state.get(dealId)) {
    return state;
  }
  const path = [dealId, 'associations', 'associatedVids'];
  let associatedVids = state.getIn(path) || List();
  if (!associatedVids.contains(objectId)) {
    associatedVids = associatedVids.push(objectId);
    state = state.setIn(path, associatedVids);
  }
  return state;
}).defineResponseTo(DISASSOCIATE_CONTACT_AND_DEAL, (state, {
  objectId,
  subjectId
}) => {
  const dealId = `${subjectId}`;
  if (!state.get(dealId)) {
    return state;
  }
  const path = [dealId, 'associations', 'associatedVids'];
  let associatedVids = state.getIn(path);
  const objectIdIndex = associatedVids.indexOf(objectId);
  if (objectIdIndex > -1) {
    associatedVids = associatedVids.remove(objectIdIndex);
    state = state.setIn(path, associatedVids);
  }
  return state;
}).register(dispatcher);
export default DealsStore;