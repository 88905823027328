import { CALL, EMAIL, ENROLLED_IN_SEQUENCE, LOGGED_CALL, LOGGED_EMAIL, LOGGED_LINKED_IN_MESSAGE, LOGGED_MEETING, LOGGED_POSTAL_MAIL, LOGGED_SMS, LOGGED_WHATS_APP, SCHEDULE, TASK } from 'crm-message-bus/types/ActivityCreatorTypes';
import { useFetchUserPermissionsForObject } from './useFetchUserPermissionsForObject';
import { useUserInfo } from './useUserInfo';
import { MISSING_PERMISSIONS, PERMISSIONS_ERROR } from '../types/ValidationError';
// List taken from https://git.hubteam.com/HubSpot/crm-activity-creator/blob/896219f3cf152424181d18b8742e43dc4e892cf9/crm-activity-creator-widgets/static/js/helpers/buttonPermissionTooltips.ts#L19
const COMMUNICATE_PERMISSION_REQUIRED = [CALL, EMAIL, ENROLLED_IN_SEQUENCE, LOGGED_CALL, LOGGED_EMAIL, LOGGED_LINKED_IN_MESSAGE, LOGGED_MEETING, LOGGED_POSTAL_MAIL, LOGGED_SMS, LOGGED_WHATS_APP, SCHEDULE];
const communicatePermissionIsRequired = interactionType => COMMUNICATE_PERMISSION_REQUIRED.includes(interactionType);
const getCommunicateErrors = (currentUserCanCommunicate, interactionType, errorMsg) => {
  const userCanCommunicate = currentUserCanCommunicate || !communicatePermissionIsRequired(interactionType);
  if (errorMsg || !userCanCommunicate) {
    return [errorMsg || MISSING_PERMISSIONS];
  }
  return [];
};

// Scope checks taken from https://git.hubteam.com/HubSpot/crm-activity-creator/blob/a56c0f3451b293682ac5bf3f51e4dc66018585f0/crm-activity-creator-widgets/static/js/hooks/useTaskCreatePermissions.ts
const ENGAGEMENT_WRITE_SCOPE = 'engagements-write';
const TASK_EDIT_SCOPES = ['tasks-edit-team-only', 'tasks-edit-all', 'tasks-edit-owned-only'];
const getTaskErrors = (userInfo, errorMsg) => {
  const scopes = (userInfo === null || userInfo === void 0 ? void 0 : userInfo.user.scopes) || [];
  const userHasPermission = scopes.includes(ENGAGEMENT_WRITE_SCOPE) && scopes.some(scope => TASK_EDIT_SCOPES.includes(scope));
  if (errorMsg || !userHasPermission) {
    return [errorMsg || MISSING_PERMISSIONS];
  }
  return [];
};
export const useGetActivityCreatorErrors = ({
  interactionType,
  objectId = 0,
  objectTypeId = ''
}) => {
  const {
    currentUserCanCommunicate,
    error: userPermissionsError
  } = useFetchUserPermissionsForObject({
    objectId,
    objectTypeId
  }, {
    skip: interactionType === TASK
  });
  const {
    userInfo,
    error: userInfoError
  } = useUserInfo();
  switch (interactionType) {
    case TASK:
      return getTaskErrors(userInfo, userInfoError ? PERMISSIONS_ERROR : '');
    default:
      return getCommunicateErrors(currentUserCanCommunicate, interactionType, userPermissionsError);
  }
};