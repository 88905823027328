import { useCallback } from 'react';
import { useTimelineDispatch, useTimelineSelector } from '../../reduxHooks';
import { getSelectedTab, setSelectedTab as setSelectedTabReducer } from '../timelineControlsSlice';
export function useSelectedTab() {
  const selectedTab = useTimelineSelector(getSelectedTab);
  const dispatch = useTimelineDispatch();
  const setSelectedTab = useCallback(newSelectedTab => {
    dispatch(setSelectedTabReducer(newSelectedTab));
  }, [dispatch]);
  return {
    selectedTab: selectedTab || '',
    setSelectedTab
  };
}