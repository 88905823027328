import hasOwn from './hasOwn';

/**
 * Like a simple version of lodash/get.
 * Doesn't support property keys, but does support undefined keys/values
 * @param obj Any object
 * @param key A key of that object
 * @returns obj[key]
 */
export default function get(obj, key) {
  if (obj && hasOwn(obj, key)) {
    return obj[key];
  }
  return undefined;
}