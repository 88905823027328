'use es6';

import * as UserSettingsAPI from 'crm_data/settings/UserSettingsAPI';
import { definePooledObjectStore } from 'crm_data/flux/definePooledObjectStore';
import registerPooledObjectService from 'crm_data/flux/registerPooledObjectService';
import { USER_SETTINGS_SAVED, USER_SETTINGS_SAVE_FAILED, USERSETTINGS_FETCH_FAILED } from 'crm_data/actions/ActionTypes';
import { USERSETTINGS } from 'crm_data/actions/ActionNamespaces';
import dispatcher from 'dispatcher/dispatcher';

/**
 * @deprecated Use 'frontend-preferences-client' directly instead of fetching via this store.
 * Docs: https://product.hubteam.com/docs/appsystems/frontend-preferences/user-attributes-frontend-client-migration.html
 */
registerPooledObjectService({
  actionTypePrefix: USERSETTINGS,
  fetcher: UserSettingsAPI.fetchUserSetting
});
export const FETCH_FAILED_KEY = 'FETCH_FAILED';
export const FETCH_FAILED_VALUE = 'FETCH_FAILED';

/**
 * @deprecated Use 'frontend-preferences-client' directly instead of fetching via this store.
 * Docs: https://product.hubteam.com/docs/appsystems/frontend-preferences/user-attributes-frontend-client-migration.html
 */
export default definePooledObjectStore({
  actionTypePrefix: USERSETTINGS
}).defineName('UserSettingsStore').defineResponseTo([USER_SETTINGS_SAVED, USER_SETTINGS_SAVE_FAILED], (state, payload) => {
  const {
    key,
    value
  } = payload;
  return state.set(key, value);
}).defineResponseTo([USERSETTINGS_FETCH_FAILED], state => {
  return state.set(FETCH_FAILED_KEY, FETCH_FAILED_VALUE);
}).register(dispatcher);