import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import formatName from 'I18n/utils/formatName';
import { getPropertyValueFromProperties } from '../../helpers/propertiesHelpers';
export const getSeleniumIdentifier = objectTypeId => {
  switch (objectTypeId) {
    case COMPANY_TYPE_ID:
      return 'company';
    case CONTACT_TYPE_ID:
      return 'contact';
    case DEAL_TYPE_ID:
      return 'deal';
    case TICKET_TYPE_ID:
      return 'ticket';
    default:
      return objectTypeId;
  }
};
export const getChickletPrimaryDisplayProperties = (objectTypeId, object, properties) => {
  switch (objectTypeId) {
    case COMPANY_TYPE_ID:
      return {
        primaryDisplayIdentifier: getPropertyValueFromProperties(properties, 'name'),
        secondaryDisplayIdentifier: getPropertyValueFromProperties(properties, 'domain')
      };
    case CONTACT_TYPE_ID:
      return {
        primaryDisplayIdentifier: formatName({
          firstName: getPropertyValueFromProperties(properties, 'firstname'),
          lastName: getPropertyValueFromProperties(properties, 'lastname')
        }),
        secondaryDisplayIdentifier: getPropertyValueFromProperties(properties, 'email')
      };
    case DEAL_TYPE_ID:
      return {
        primaryDisplayIdentifier: getPropertyValueFromProperties(properties, 'dealname'),
        secondaryDisplayIdentifier: undefined
      };
    case TICKET_TYPE_ID:
      return {
        primaryDisplayIdentifier: getPropertyValueFromProperties(properties, 'subject'),
        secondaryDisplayIdentifier: getPropertyValueFromProperties(properties, 'content')
      };
    default:
      return {
        primaryDisplayIdentifier: object.primaryDisplayProperty && object.primaryDisplayProperty.value,
        secondaryDisplayIdentifier: undefined
      };
  }
};
export default getChickletPrimaryDisplayProperties;