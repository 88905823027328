'use es6';

import { Record, List, Map as ImmutableMap } from 'immutable';

// The fields in this file correspond in the BE to the OutgoingEmail structure here:
// https://git.hubteam.com/HubSpot/Facsimile/blob/master/FacsimileBase/src/main/protobuf/facsimile.proto#L47
export const emailFields = {
  associations: null,
  subject: null,
  to: List(),
  cc: List(),
  bcc: List(),
  from: ImmutableMap({
    name: null,
    email: null
  }),
  sender: ImmutableMap({
    email: null
  }),
  html: '',
  plainText: '',
  attachments: List(),
  templateId: null,
  shouldAddSignalsTracking: true,
  shouldLogToCrm: true,
  trackerKey: null,
  legalBases: List(),
  isForward: false
};
const EmailRecord = Record(emailFields, 'EmailRecord');
export default EmailRecord;