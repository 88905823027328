module.exports = {
  "aiButtonInteraction": {
    "name": "aiButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "featureId": {
        "type": "string",
        "isOptional": true
      },
      "featureCategoryId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonInteraction": {
    "name": "copilotActionButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "prompt": {
        "type": "string",
        "isOptional": true
      },
      "hasPrefillValues": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copyMessageInteraction": {
    "name": "copyMessageInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_copied"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "feedbackInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_upvoted",
          "prompt_upvote_removed",
          "prompt_downvoted",
          "prompt_downvote_removed"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotPreviewPageView": {
    "name": "copilot-preview-view",
    "class": "view",
    "properties": {
      "action": {
        "type": [
          "page_view"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewPrimaryAction": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "primary_action_click",
        "primary_action_auto_generated"
      ],
      "command": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewClosePreview": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "close_preview"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewChatWithChatSpot": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "ask_a_question"
      ],
      "followUpMessage": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewRefreshSummary": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "refresh_summary"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  }
};