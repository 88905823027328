import http from 'hub-http/clients/apiClient';
import { fromJS } from 'immutable';
const responseToImmutable = result => result ? fromJS(result) : result;

/*
  @Deprecated - Use `fetchSequence` from `SequencesV1Api` instead
*/
export const fetchSequence = id => {
  const url = `sequences/v1/sequence/${id}`;
  return http.get(url).then(responseToImmutable);
};

/*
  @Deprecated - Use `updateEnrollment` from `SequencesV1Api` instead
*/
export const updateEnrollment = enrollment => {
  return http.put('sequences/v1/enrollment', {
    data: enrollment.toJS()
  }).then(responseToImmutable);
};

/*
  @Deprecated - Use `enroll` from `SequencesV1Api` instead
*/
export function enroll(enrollment, vid) {
  const url = `sequences/v1/enrollment/vid/${vid}`;
  const data = {
    sequence: enrollment.toJS(),
    steps: []
  };
  return http.post(url, {
    data
  }).then(responseToImmutable);
}

/*
  @Deprecated - Use `unenroll` from `SequencesV1Api` instead
*/
export const unenroll = enrollmentId => {
  const url = `sequences/v1/enrollment/${enrollmentId}/unenroll`;
  return http.post(url).then(responseToImmutable);
};

/*
  @Deprecated - Use `unenrollBatch` from `SequencesV1Api` instead
*/
export function unenrollBatch(enrollmentIds) {
  return http.post(`sequences/v1/enrollment/unenroll/batch`, {
    data: {
      ids: enrollmentIds.toJS()
    }
  }).then(responseToImmutable);
}

/*
  @Deprecated - Use `moveSequences` from `SequencesV1Api` instead
*/
export function moveSequences(sequenceIds, folderId) {
  return http.put('sequences/v1/sequence/move-to-folder/batch', {
    data: {
      sequenceIds: sequenceIds.toArray(),
      destinationFolderId: folderId
    }
  }).then(responseToImmutable);
}