'use es6';

import { Map as ImmutableMap } from 'immutable';
import { TASK } from 'customer-data-objects/constants/ObjectTypes';
import TaskRecord from 'customer-data-objects/task/TaskRecord';
import PropertyValueRecord from 'customer-data-objects/property/PropertyValueRecord';
import { convertCommonFieldsToInboundProperties, convertTypeSpecificFieldsToInboundProperties } from 'crm_data/engagements/inboundDbProperties/engagementInboundDbObjectHelpers';
export default function engagementToTaskRecord(engagement) {
  if (!engagement) {
    return TaskRecord();
  }
  const associations = engagement.get('associations', ImmutableMap());
  const properties = convertCommonFieldsToInboundProperties(engagement).concat(convertTypeSpecificFieldsToInboundProperties(TASK, engagement)).reduce((propertiesMap, property) => {
    if (property && property.get('value')) {
      return propertiesMap.set(property.get('name'), PropertyValueRecord(property));
    }
    return propertiesMap;
  }, ImmutableMap());
  return TaskRecord({
    associations,
    objectId: engagement.getIn(['engagement', 'id']),
    properties
  });
}