import { useCallback } from 'react';
import { OPEN_CALL_RECORDING_DELETE_MODAL } from 'crm-events-data';
// @ts-expect-error Could not find a declaration file
import DeleteObjectPrompt from 'customer-data-objects-ui-components/prompt/DeleteObjectPrompt';
// @ts-expect-error Could not find a declaration file
import { dispatch } from 'crm_data/flux/dispatch';
// @ts-expect-error Could not find a declaration file
import { DELETE_RECORDING } from 'crm_data/actions/ActionTypes';
// @ts-expect-error Could not find a declaration file
import { send } from 'crm_data/api/ImmutableAPI';
import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
import I18n from 'I18n';
import Raven from 'raven-js';
import { CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
// @ts-expect-error Could not find a declaration file
import { DELETE } from 'crm_data/actions/ActionVerbs';
// @ts-expect-error Could not find a declaration file
import { PUT } from 'crm_data/constants/HTTPVerbs';
function deleteRecordingClient(engagementId) {
  return send({
    type: DELETE,
    headers: {
      'X-Properties-Source': CRM_UI
    }
  }, `post-call/v1/voice-recording/crm/${engagementId}`);
}
function removeCallRecordingUrl(engagementId) {
  return send({
    type: PUT,
    headers: {
      'X-Properties-Source': CRM_UI
    }
  }, `engagements/v2/engagements/${engagementId}`, {
    properties: [{
      name: 'hs_call_recording_url',
      value: ''
    }]
  });
}
const deleteRecording = (callId, engagementId) => {
  return Promise.all([deleteRecordingClient(engagementId), removeCallRecordingUrl(engagementId)]).then(() => {
    dispatch(DELETE_RECORDING, {
      callId,
      engagementId
    });
  }).catch(error => {
    Raven.captureException(error, {
      extra: {
        engagementId,
        callId
      }
    });
  });
};
export const useDeleteCallRecordingModalActionHandler = ({
  objectType
}) => {
  const handleDeleteCallRecordingModalAction = useCallback((actionType, data) => {
    if (actionType === OPEN_CALL_RECORDING_DELETE_MODAL) {
      DeleteObjectPrompt({
        confirmLabel: I18n.text('timeline.callRecording.deleteConfirmCTA'),
        rejectLabel: I18n.text('timeline.callRecording.deleteCancelCTA'),
        message: I18n.text('timeline.callRecording.deleteRecordingConfirmDescription'),
        title: I18n.text('timeline.callRecording.deleteRecordingConfirmTitle'),
        callback: () => {
          CrmLogger.log('timelineInteraction', {
            action: 'Delete call recording',
            eventType: 'call',
            type: objectType
          });
          return deleteRecording(data.callsServiceCallId, data.engagementId);
        }
      });
    }
  }, [objectType]);
  return {
    handleDeleteCallRecordingModalAction
  };
};