import { useCallback } from 'react';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { MISSING_PERMISSIONS } from '../types/ValidationError';
import { useFetchUserPermissionsForObject } from '../hooks/useFetchUserPermissionsForObject';
export function usePreviewObjectAction({
  objectId,
  objectTypeId
}) {
  const {
    currentUserCanView,
    error
  } = useFetchUserPermissionsForObject({
    objectId,
    objectTypeId
  });
  const sendPreviewAction = useSendCrmMessageTopic(TOPIC_NAMES.PREVIEW_OBJECT);
  const callback = useCallback(() => {
    sendPreviewAction({
      objectTypeId,
      objectId: objectId.toString()
    });
  }, [sendPreviewAction, objectId, objectTypeId]);
  const errorMsg = error || (!currentUserCanView ? MISSING_PERMISSIONS : null);
  if (errorMsg) {
    return {
      errors: [errorMsg]
    };
  }
  return {
    callback
  };
}