import SampleEventFactoryInterface from './SampleEventFactoryInterface';
import { MEETING } from 'customer-data-objects/engagement/EngagementTypes';
import { EVENT_ENGAGEMENT } from '../../timeline/constants/TimelineConstants';
const makeMeetingSampleTimelineEvent = SampleEventFactoryInterface({
  requiredData: {
    eventType: EVENT_ENGAGEMENT,
    itemType: MEETING
  },
  eventDefaults: {
    title: '',
    body: '',
    endTime: Date.now(),
    startTime: Date.now(),
    inviteeEmails: []
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(meetingData: any) => { associateCurrentCont... Remove this comment to see the full error message
  build(meetingData) {
    const {
      title,
      body,
      endTime,
      startTime,
      inviteeEmails
    } = meetingData;
    const eventData = {
      associateCurrentContact: true,
      inviteeEmails,
      metadata: {
        title,
        body,
        endTime,
        startTime
      }
    };
    return eventData;
  }
});
export default makeMeetingSampleTimelineEvent;