import invariant from 'react-utils/invariant';
import { fromJS } from 'immutable';
import identity from 'transmute/identity';
const _userIsScoped = (scopes, scopeToCheck) => {
  const value = scopes[scopeToCheck];
  return value === true;
};
export const isScoped = (scopes, scopesToCheck) => {
  return Array.isArray(scopesToCheck) ? scopesToCheck.every(scopeToCheck => _userIsScoped(scopes, scopeToCheck)) : _userIsScoped(scopes, scopesToCheck);
};
export const someScoped = (scopes, scopesToCheck) => {
  invariant(Array.isArray(scopesToCheck), 'ScopesContainer: expected scopes to be Array but got "%s"');
  return scopesToCheck.some(scopeToCheck => _userIsScoped(scopes, scopeToCheck));
};
export const getAsSet = scopes => {
  return fromJS(scopes).filter(identity).keySeq().toSet();
};