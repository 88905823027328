import uniqueId from 'transmute/uniqueId';
import SampleEventFactoryInterface from './SampleEventFactoryInterface';
import { EMAIL } from 'customer-data-objects/engagement/EngagementTypes';
import { TRACKED_EVENT_TYPES, EVENT_SIDEKICK } from '../../timeline/constants/TimelineConstants';
const {
  EMAIL_OPEN
} = TRACKED_EVENT_TYPES;
const makeEmailOpenSampleTimelineEvent = SampleEventFactoryInterface({
  requiredData: {
    eventType: EVENT_SIDEKICK,
    itemType: EMAIL
  },
  eventDefaults: {
    subject: '',
    sources: []
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(emailOpenData: any) => { type: any; tracker... Remove this comment to see the full error message
  build(emailOpenData) {
    const {
      subject,
      sources
    } = emailOpenData;
    const eventData = {
      type: EMAIL_OPEN,
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      tracker_key: `${uniqueId()}`,
      object: subject,
      sources: sources.map(source => {
        source = Object.assign({}, {
          created: Date.now()
        }, source);
        const {
          domain,
          email,
          name,
          created
        } = source;
        return {
          domain,
          email,
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
          guid: `${uniqueId()}`,
          is_exact: true,
          is_mobile: false,
          link: '',
          location: '',
          name,
          timestamp: created
        };
      })
    };
    return eventData;
  }
});
export default makeEmailOpenSampleTimelineEvent;