import { gql, useQuery } from '@apollo/client';
import { PERMISSIONS_ERROR } from '../types/ValidationError';
const FETCH_USER_PERMISSIONS_FOR_OBJECT = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"fetchUserPermissionsForObject\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}}},{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Long\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"crmObject\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"type\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}}},{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectId\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"userPermissions\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"currentUserCanView\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"currentUserCanCommunicate\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"currentUserCanDelete\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"currentUserCanEdit\"}}]}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "fetchUserPermissionsForObject"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectTypeId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Long"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "crmObject"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "type"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectTypeId"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "id"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectId"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "userPermissions"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "currentUserCanView"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "currentUserCanCommunicate"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "currentUserCanDelete"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "currentUserCanEdit"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
});
const leastPermissive = {
  currentUserCanView: false,
  currentUserCanCommunicate: false,
  currentUserCanDelete: false,
  currentUserCanEdit: false
};
export function useFetchUserPermissionsForObject(context, queryOptions = {}) {
  const {
    error,
    data
  } = useQuery(FETCH_USER_PERMISSIONS_FOR_OBJECT, Object.assign({}, queryOptions, {
    variables: Object.assign({}, context)
  }));
  if (error) {
    return Object.assign({}, leastPermissive, {
      error: PERMISSIONS_ERROR
    });
  }
  return data && data.crmObject.userPermissions || leastPermissive;
}