import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BOX_BASE_HEIGHT, BOX_BASE_WIDTH } from '../constants/baseValues';
import { getSize } from '../operators/getSize';
import SkeletonBase from './SkeletonBase';
// Default props
const DEFAULT_HEIGHT = BOX_BASE_HEIGHT;
const DEFAULT_WIDTH = BOX_BASE_WIDTH;
const SkeletonBox = styled(SkeletonBase).withConfig({
  displayName: "SkeletonBox",
  componentId: "sc-3bzlxy-0"
})(["width:", ";height:", ";min-width:", ";"], ({
  width = DEFAULT_WIDTH
}) => getSize(width), ({
  height = DEFAULT_HEIGHT
}) => getSize(height), ({
  width = DEFAULT_WIDTH
}) => getSize(width));
SkeletonBox.displayName = 'SkeletonBox';
SkeletonBox.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export default SkeletonBox;