import { getDefaultActionRegistry } from './action-registry/ActionRegistry';
import { INVALID_ACTION_TYPE, MISSING_ACTION_TYPE, MISSING_ARGS } from './types/ValidationError';
import { ACTION_TYPES } from './types/ActionType';
const actionRegistry = getDefaultActionRegistry();
function validateRequiredProps(actionType, args) {
  const errorMsgs = [];
  if (!actionType) {
    errorMsgs.push(MISSING_ACTION_TYPE);
  }
  if (!args) {
    errorMsgs.push(MISSING_ARGS);
  }
  if (actionType && !ACTION_TYPES.includes(actionType)) {
    errorMsgs.push(INVALID_ACTION_TYPE);
  }
  return errorMsgs;
}
export function useCrmActionFromLibrary(actionType, args) {
  const errorMsgs = validateRequiredProps(actionType, args);
  if (errorMsgs.length > 0) {
    return {
      errors: errorMsgs
    };
  }
  const buildAction = actionRegistry[actionType];
  return buildAction(args);
}