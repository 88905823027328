// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'gene... Remove this comment to see the full error message
import { defineFactory } from 'general-store';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'disp... Remove this comment to see the full error message
import dispatcher from 'dispatcher/dispatcher';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { FETCH_TIMELINE_ITEMS_SUCCESS } from 'crm_data/actions/ActionTypes';
import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import getIn from 'transmute/getIn';
function getEventId(event) {
  const id = event.getIn(['eventData', 'engagement', 'id']) || event.getIn(['engagement', 'id']) || event.getIn(['eventData', 'sentBy', 'id']) || event.getIn(['eventData', 'id']) || event.get('id');
  return `${id}`;
}
export default defineFactory().defineName('TimelineSearchResults').defineGetInitialState(() => ImmutableMap()).defineGet((state, searchKey) => state.get(searchKey)).defineResponseTo([FETCH_TIMELINE_ITEMS_SUCCESS], (state, data) => {
  const events = data.get('events');
  const searchText = getIn(['originalPayload', 'searchText'], data);
  const previousIds = state && state.get(searchText, ImmutableSet());
  const hasNoEvents = !events || !events.size;
  if (hasNoEvents || !searchText) {
    return state;
  }
  const eventIds = events.reduce((idSet, event) => idSet.add(getEventId(event)), ImmutableSet());
  return state.set(searchText, previousIds.concat(eventIds));
}).register(dispatcher);