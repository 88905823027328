// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message

import { Map as ImmutableMap } from 'immutable';
const addPermissionsToEngagement = (engagement, permissions) => {
  const engagementPermissions = engagement.get('currentUserPermissions');
  if (engagementPermissions && engagementPermissions.size > 0) {
    return engagement;
  }
  return engagement.set('currentUserPermissions', ImmutableMap(permissions));
};
export default addPermissionsToEngagement;