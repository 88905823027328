'use es6';

import { Set as ImmutableSet } from 'immutable';
import { ENROLL_SUCCESS, ENROLLMENT_UPDATE_SUCCESS, UNENROLL_SUCCESS, PAUSE_SUCCESS, RESUME_SUCCESS } from './SequenceActionTypes';
import * as SequenceV2Api from 'sequences-lib/api/SequencesImmutableV2Api';
import { defineLazyKeyStore, registerLazyKeyService } from 'crm_data/store/LazyKeyStore';
import makeBatch from 'crm_data/api/makeBatch';
import { SEQUENCE_ENROLLMENTS } from 'crm_data/actions/ActionNamespaces';
import toString from 'transmute/toString';
import { dispatchQueue } from 'crm_data/dispatch/Dispatch';
export const batchFetch = makeBatch(SequenceV2Api.fetchEnrollment, 'SequenceEnrollmentStore.fetchEnrollment');
registerLazyKeyService({
  namespace: SEQUENCE_ENROLLMENTS,
  fetch: batchFetch
});
export default defineLazyKeyStore({
  namespace: SEQUENCE_ENROLLMENTS,
  idIsValid: enrollmentId => typeof enrollmentId === 'number' || typeof enrollmentId === 'string',
  idTransform: toString
}).defineName('SequenceEnrollmentStore').defineResponseTo(ENROLL_SUCCESS, (state, data) => {
  setTimeout(() => {
    dispatchQueue('SEQUENCE_ENROLLMENTS_REFRESH_QUEUED', ImmutableSet([data.get('vid')]));
  }, 1);
  return state;
}).defineResponseTo([UNENROLL_SUCCESS, ENROLLMENT_UPDATE_SUCCESS, PAUSE_SUCCESS, RESUME_SUCCESS], (state, data) => state.remove(data.get('vid').toString())).register();