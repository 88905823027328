import BETPortalObjectsStore from '../engagementAssociations/stores/BETPortalObjectsStore';
import { List, Map as ImmutableMap } from 'immutable';
import { ENGAGEMENT } from 'customer-data-objects/constants/ObjectTypes';
import PropTypes from 'prop-types';
const BETAssociatedPortalObjectsDependency = {
  propTypes: {
    scopes: PropTypes.arrayOf(PropTypes.string)
  },
  stores: [BETPortalObjectsStore],
  deref({
    interaction,
    engagement,
    BETAssociatedPortalObjects,
    scopes = []
  }) {
    if (!scopes.includes('bet-portal-association-access')) {
      return List();
    }
    if (BETAssociatedPortalObjects) {
      return BETAssociatedPortalObjects;
    }
    const engagementId = interaction && interaction.getIn(['engagement', 'id']) || engagement && engagement.getIn(['engagement', 'id']);
    if (!engagementId) {
      return List();
    }
    return BETPortalObjectsStore.get(ImmutableMap({
      objectType: ENGAGEMENT,
      subjectId: engagementId
    })) || List();
  }
};
export default BETAssociatedPortalObjectsDependency;