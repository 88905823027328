'use es6';

import { createPlugin, pluginUtils } from 'draft-extend';
import TransformText from 'draft-plugins/plugins/TransformText';
import MergeTagGroupDecorator from './MergeTagGroupDecorator';
import { createPluginStack } from '../createPluginStack';
import MergeTagSelectPopoverWrapper from './MergeTagSelectPopoverWrapper';
import MergeTagGroupButton from './MergeTagGroupButton';
import MergeTagDefaultOptions from './MergeTagDefaultOptions';
import { EntityTypes } from '../../lib/mergeTagConstants';
import { createTracker } from '../../tracking/usageTracker';
import getMergeTags from '../../utils/getMergeTags';
import { formatMergeTag } from '../../utils/propertyUtils';
import createEntityOptions from '../../utils/createMergeTagEntityOptions';
let Tracker;
const getPrefixes = mergeTags => mergeTags.map(tag => tag.toLowerCase()).join('|');
const MergeTagGroupPlugin = options => {
  if (!Tracker) {
    Tracker = createTracker();
  }
  const optionsWithDefaults = Object.assign({}, MergeTagDefaultOptions, options);
  const {
    buttonClassName,
    contentType,
    includeTicketTokens,
    includeCustomTokens,
    onInsertToken,
    tracker,
    showButtonIcon
  } = optionsWithDefaults;
  const mergeTags = getMergeTags({
    includeTicketTokens,
    includeCustomTokens
  });
  const textToEntity = (text, createEntity) => {
    const result = [];
    const prefixes = getPrefixes(mergeTags);
    const pattern = new RegExp(`\\{\\{\\s?(?:(${prefixes})\\.)([^\\s{}]*)\\s?\\}\\}`, 'gi');
    text.replace(pattern, (match, prefix, property, offset) => {
      const entityData = {
        prefix,
        property
      };
      result.push({
        offset,
        length: match.length,
        entity: createEntity(...createEntityOptions(entityData)),
        result: `${prefix}.${property}`
      });
    });
    return result;
  };
  const entityToHTML = (entity, originalText) => {
    if (entity.type === EntityTypes.MERGE_TAG) {
      const {
        prefix,
        property
      } = entity.data;
      return `{{ ${prefix}.${property} }}`;
    }
    return originalText;
  };
  const MergeTagGroupButtonWrapper = MergeTagSelectPopoverWrapper(MergeTagGroupButton, {
    buttonClassName,
    mergeTags,
    createEntityOptions,
    tracker,
    onInsertToken,
    contentType,
    showButtonIcon,
    Tracker
  });
  return createPlugin({
    displayName: 'MergeTagGroupPlugin',
    decorators: {
      strategy: pluginUtils.entityStrategy(EntityTypes.MERGE_TAG),
      component: MergeTagGroupDecorator
    },
    buttons: MergeTagGroupButtonWrapper,
    textToEntity,
    entityToHTML
  });
};
export default (options => {
  const transformOptions = {
    dataProp: 'flattenedProperties',
    entityType: EntityTypes.MERGE_TAG,
    dataFind: (flattenedProperties, entity) => {
      const {
        prefix,
        property
      } = entity.getData();
      const tagName = formatMergeTag(prefix, property, flattenedProperties);
      return {
        text: tagName
      };
    }
  };
  return createPluginStack(TransformText(transformOptions), MergeTagGroupPlugin(options));
});