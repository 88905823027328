export default class TimelineItemsFailedError extends Error {
  constructor({
    failed,
    isCompleteFailure,
    message
  }, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TimelineItemsFailedError);
    }
    this.name = 'TimelineItemsFailedError';
    this.message = message;
    this.failed = failed;
    this.isCompleteFailure = isCompleteFailure;
  }
}