// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import CompaniesStore from 'crm_data/companies/CompaniesStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { AnyCrmObjectTypePropType } from 'customer-data-objects-ui-components/propTypes/CrmObjectTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import ContactsStore from 'crm_data/contacts/ContactsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import DealsStore from 'crm_data/deals/DealsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import * as LoadingStatus from 'crm_data/constants/LoadingStatus';
import { COMPANY, CONTACT, DEAL, VISIT, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import PropTypes from 'prop-types';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import VisitsStore from 'crm_data/prospects/VisitsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import TicketsStore from 'crm_data/tickets/TicketsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import CrmObjectStore from 'crm_data/crmObjects/CrmObjectStore';
import { toCrmObjectKey } from 'customer-data-objects/crmObject/CrmObjectKey';
const storesByType = {
  [COMPANY]: CompaniesStore,
  [CONTACT]: ContactsStore,
  [DEAL]: DealsStore,
  [VISIT]: VisitsStore,
  [TICKET]: TicketsStore
};
const SubjectDependency = {
  propTypes: {
    objectType: AnyCrmObjectTypePropType.isRequired,
    subjectId: PropTypes.string
  },
  stores: [CompaniesStore, ContactsStore, CrmObjectStore, DealsStore, TicketsStore, VisitsStore],
  deref(props) {
    const {
      objectType,
      subjectId
    } = props;
    if (subjectId === null) {
      return LoadingStatus.EMPTY;
    }
    const isHubSpotDefinedObject = [COMPANY, CONTACT, DEAL, VISIT, TICKET].includes(objectType);
    if (!isHubSpotDefinedObject) {
      const key = toCrmObjectKey({
        objectId: subjectId,
        objectTypeId: objectType
      });
      return CrmObjectStore.get(key);
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const store = storesByType[objectType];
    return store && store.get(subjectId);
  }
};
export default SubjectDependency;