import { LEGACY_SIDEBAR_CARD_TYPES } from '../../constants/LegacySidebarCardType';

// Legacy card should not have config type or query fragments
// Extra props on legacy card
// Legacy Sidebar Card Metadata
export const ZORSE_MOVE_INBOX_METADATA = {
  cardType: 'ZORSE_MOVE_INBOX',
  legacySidebarCardType: LEGACY_SIDEBAR_CARD_TYPES.ZorseMoveInboxCard,
  hasLegacyExtraProps: true,
  renderAsLegacyCardInCrmLocationResolver: false
};