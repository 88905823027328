import { useState, useCallback } from 'react';
import { useIsEventCommentsCollapsed } from './useIsEventCommentsCollapsed';
export const useCommentsIndicator = eventId => {
  const {
    setIsCommentsCollapsed
  } = useIsEventCommentsCollapsed(eventId);
  const [isCommentSectionOpenedFromIndicator, setIsCommentSectionOpenedFromIndicator] = useState(false);
  const handleCommentsIndicatorClick = useCallback(evt => {
    setIsCommentsCollapsed(false);
    setIsCommentSectionOpenedFromIndicator(true);
    evt.stopPropagation();
  }, [setIsCommentSectionOpenedFromIndicator, setIsCommentsCollapsed]);
  const handleCollapseEvent = useCallback(() => {
    setIsCommentSectionOpenedFromIndicator(false);
  }, [setIsCommentSectionOpenedFromIndicator]);
  return {
    isCommentSectionOpenedFromIndicator,
    onCommentsIndicatorClick: handleCommentsIndicatorClick,
    onCollapseEvent: handleCollapseEvent
  };
};