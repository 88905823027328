'use es6';

import { registerLazyKeyService } from '../store/LazyKeyStore';
function idIsValid(id) {
  return typeof id === 'string' || typeof id === 'number';
}
function idTransform(id) {
  return `${id}`;
}
export default function registerPooledObjectService({
  actionTypePrefix,
  fetcher,
  fetchLimit
}) {
  return registerLazyKeyService({
    namespace: actionTypePrefix,
    fetch: fetcher,
    idIsValid,
    idTransform,
    fetchLimit
  });
}