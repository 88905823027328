// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_data/settings/UserPortalSe... Remove this comment to see the full error message
import UserPortalSettingsKeys from 'crm_data/settings/UserPortalSettingsKeys';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_data/settings/UserSettings... Remove this comment to see the full error message
import UserSettingsStore from 'crm_data/settings/UserSettingsStore';
export const setupUserSettingsStore = auth => {
  const {
    user
  } = auth;
  UserSettingsStore.get(UserPortalSettingsKeys.SALES_SEGMENTATION);
  if (user.scopes.indexOf('contacts-read') === -1) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Location ... Remove this comment to see the full error message
    window.location = '/';
  }
};