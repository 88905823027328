/* Tests for this file are in useCardRenderMarkers-test.tsx so that it can be tested
 * with the context and reducer in place */

import { logError, logTiming, incrementCounter } from './perfLogger';
import { getPerfStateForCard } from './cardPerfSelectors';
import mark from 'crm-fe-perf/timing/mark';
import measure from 'crm-fe-perf/timing/measure';
import { getCrmLocationInitialCardsReady } from 'crm-fe-perf/timing/marks/crm-cards/performanceMarks';
import { getCardRenderTimeout } from 'crm-fe-perf/timing/marks/crm-cards-sdk/performanceMarks';
import { getRealContentStartToTimeout } from 'crm-fe-perf/timing/measurements/crm-cards-sdk/performanceMeasurements';
import { getIsFirstLoad, getWasHidden } from '../../utils/sharedPerfDimensions';
const RENDER_TIMEOUT_MS = 60000;
const errorLoggingMiddleware = storeAPI => next => action => {
  try {
    return next(action);
  } catch (e) {
    const storeState = storeAPI.getState();
    const location = (storeState === null || storeState === void 0 ? void 0 : storeState.location) || 'unknownLocation';
    const cardIdNamespace = 'cardId' in action ? `.${action.cardId}` : '';
    logError('Error in Performance Tracking dispatch.', `cardPerf.dispatchError.${location}${cardIdNamespace}`, {
      action,
      cardPerfState: storeState
    }, e);
  }
};
const containerTabChangedMiddleware = storeAPI => next => action => {
  if (action.type !== 'CONTAINER_TAB_CHANGED') {
    return next(action);
  }
  const state = storeAPI.getState();
  // Clear all the timeouts, as these cards are in the previous tab
  Object.values(state.dataByCard).forEach(perfState => {
    const timeoutId = perfState.timeoutId;
    if (timeoutId) clearTimeout(timeoutId);
  });
  return next(action);
};
const firstCardLoadingStartedMiddleware = storeAPI => next => action => {
  if (action.type !== 'CARD_LOADING_STARTED') {
    return next(action);
  }
  const state = storeAPI.getState();
  // if no cards are in dataByCard then this is the first card
  const isFirstCard = Object.keys(state.dataByCard).length === 0 && !state.changedTabsBeforeInitialLoadSettled;
  if (!isFirstCard) {
    return next(action);
  }
  const firstCardRenderStartedMs = action.startTimeMs;
  const dimensions = Object.assign({
    location: state.location,
    is_virtualization_enabled: `${state.isVirtualizationEnabled}`,
    total_card_count: state.totalCardCount.toString()
  }, state.loggingTag ? {
    logging_tag: state.loggingTag
  } : {});
  if (state.isResolvedLocation) {
    const resolverStartedToFirstCardStartedMs = firstCardRenderStartedMs - state.timings.locationResolverRenderStartedMs;
    logTiming('resolver-to-first-card-started', dimensions, resolverStartedToFirstCardStartedMs);
    const resolverStartedToLocationRenderStarted = state.timings.locationRenderStartedMs - state.timings.locationResolverRenderStartedMs;
    logTiming('resolver-started-to-location-started', dimensions, resolverStartedToLocationRenderStarted);
  }
  const locationRenderStartedToFirstCardStarted = firstCardRenderStartedMs - state.timings.locationRenderStartedMs;
  logTiming('location-started-to-first-card-started', dimensions, locationRenderStartedToFirstCardStarted);
  return next(action);
};
const cardLoadingStartedMiddleware = storeAPI => next => action => {
  if (action.type !== 'CARD_LOADING_STARTED') {
    return next(action);
  }
  const state = storeAPI.getState();
  const perfStateForCard = getPerfStateForCard(state, action.cardType, action.cardId, action.tabIndex);

  // if the card already has an initialized perf state, ignore the duplicate message
  // by returning without calling next
  if (perfStateForCard) {
    return;
  }
  const dimensions = {
    location: state.location,
    logging_tag: state.loggingTag || '',
    card_type: action.cardType
  };
  const timeoutId = setTimeout(() => {
    mark(getCardRenderTimeout(action.cardType, action.cardId), dimensions, true);
    measure(getRealContentStartToTimeout(action.cardType, action.cardId), dimensions);
    // dispatch a new action saying the card was finished with a TIMEOUT state
    storeAPI.dispatch({
      type: 'CARD_LOADING_FINISHED',
      finishedState: 'TIMEOUT',
      cardId: action.cardId,
      cardType: action.cardType,
      tabIndex: action.tabIndex
    });
  }, RENDER_TIMEOUT_MS);

  // append the timeoutId to the current action before passing it to the next middleware
  return next(Object.assign({}, action, {
    timeoutId
  }));
};
const firstCardFinishedSuccessMiddleware = storeAPI => next => action => {
  if (action.type !== 'CARD_LOADING_FINISHED' || action.finishedState !== 'SUCCESS') {
    return next(action);
  }
  const state = storeAPI.getState();
  // Check to see if there are cards other than the one that just finished loading in a success state
  const areAnyCardsInSuccessStateAlready = Object.values(state.dataByCard).some(cardPerfData => {
    return cardPerfData.finishedState === 'SUCCESS';
  });
  if (
  // If there are any in the success state, then this is not the first finished card
  areAnyCardsInSuccessStateAlready ||
  // If location is already settled, don't fire this metric as we only care about initial load times
  state.isInitialLoadSettled ||
  // If location has abandoned cards, it means locationResolverRenderStartedMs is from a previous tab
  state.changedTabsBeforeInitialLoadSettled) {
    return next(action);
  }
  const dimensions = Object.assign({
    location: state.location,
    is_virtualization_enabled: `${state.isVirtualizationEnabled}`,
    total_card_count: state.totalCardCount.toString()
  }, state.loggingTag ? {
    logging_tag: state.loggingTag
  } : {});
  const cardFinishedAtMs = performance.now();
  if (state.isResolvedLocation) {
    const resolverStartedToFirstCardFinishedMs = performance.now() - state.timings.locationResolverRenderStartedMs;
    logTiming('resolver-started-to-first-card-finished', dimensions, resolverStartedToFirstCardFinishedMs, {
      firstCardId: action.cardId
    });
  }
  const locationStartedToFirstCardFinishedMs = cardFinishedAtMs - state.timings.locationRenderStartedMs;
  logTiming('location-started-to-first-card-finished', dimensions, locationStartedToFirstCardFinishedMs, {
    firstCardId: action.cardId
  });
  return next(action);
};
const thirdCardFinishedSuccessMiddleware = storeAPI => next => action => {
  if (!(action.type === 'CARD_LOADING_FINISHED' && action.finishedState === 'SUCCESS')) {
    return next(action);
  }
  const state = storeAPI.getState();
  // get all the cards in a success state (includes the card that just finished since we called next(action) above)
  const cardsInSuccessState = Object.values(state.dataByCard).filter(cardPerfData => {
    return cardPerfData.finishedState === 'SUCCESS';
  });
  if (
  // If there are 2 successful cards in the map, then this is the third card
  cardsInSuccessState.length !== 2 ||
  // If location is already settled, don't fire this metric as we only care about initial load times
  state.isInitialLoadSettled ||
  // If location has abandoned cards, it means locationResolverRenderStartedMs is from a previous tab
  state.changedTabsBeforeInitialLoadSettled) {
    return next(action);
  }
  const dimensions = Object.assign({
    location: state.location,
    is_virtualization_enabled: `${state.isVirtualizationEnabled}`,
    total_card_count: state.totalCardCount.toString()
  }, state.loggingTag ? {
    logging_tag: state.loggingTag
  } : {});
  const cardFinishedAtMs = performance.now();
  if (state.isResolvedLocation) {
    const resolverStartedToThirdCardFinishedMs = performance.now() - state.timings.locationResolverRenderStartedMs;
    logTiming('resolver-started-to-third-card-finished', dimensions, resolverStartedToThirdCardFinishedMs, {
      cardId: action.cardId
    });
  }
  const locationStartedToThirdCardFinishedMs = cardFinishedAtMs - state.timings.locationRenderStartedMs;
  logTiming('location-started-to-third-card-finished', dimensions, locationStartedToThirdCardFinishedMs, {
    cardId: action.cardId
  });
  return next(action);
};
const cardLoadingFinishedMiddleware = storeAPI => next => action => {
  if (action.type !== 'CARD_LOADING_FINISHED') {
    return next(action);
  }
  const state = storeAPI.getState();
  const perfStateForCard = getPerfStateForCard(state, action.cardType, action.cardId, action.tabIndex);
  if (!perfStateForCard) {
    logError(`Received CARD_LOADING_FINISHED action for uninitialized card. (cardId: ${action.cardId}, cardType: ${action.cardType}, finishedState: ${action.finishedState})`, `cardPerf.missingPerfStateForCard.${action.cardId}.${action.finishedState}`);
    return next(action);
  }

  // if the card has already finished, ignore the duplicate message
  if (perfStateForCard.finishedState) {
    return; // don't call next(action)
  }
  if (perfStateForCard.timeoutId) {
    clearTimeout(perfStateForCard.timeoutId);
  }
  const sharedDimensions = Object.assign({
    card_type: action.cardType,
    location: state.location
  }, state.loggingTag ? {
    logging_tag: state.loggingTag
  } : {});
  const consoleDebugInfo = {
    cardId: action.cardId
  };
  if (action.finishedState === 'TIMEOUT') {
    incrementCounter('card-timeouts', sharedDimensions, consoleDebugInfo);
    return next(action);
  }
  if (action.finishedState === 'ERROR') {
    // These errors are already handled by the card and render an Error Marker
    incrementCounter('card-render-error', Object.assign({}, sharedDimensions, {
      error_type: (action === null || action === void 0 ? void 0 : action.errorType) || 'UNKNOWN'
    }), consoleDebugInfo);
    return next(action);
  }

  // track state-based dimensions on a separate counter metric to keep performance queries simple and fast
  incrementCounter('card-render', Object.assign({}, sharedDimensions, {
    is_collapsed: action.isCollapsed ? 'true' : 'false',
    card_loading_order_index:
    // Reduce the # of unique values this dimension can have for metric performance reasons
    perfStateForCard.cardLoadingOrderIndex > 10 ? '>10' : perfStateForCard.cardLoadingOrderIndex.toString()
  }), consoleDebugInfo);

  // don't log timers for collapsed cards
  if (action.isCollapsed) {
    return next(action);
  }

  // isFirstLoadForCardType means that this card type doesn't yet have its component or translations already loaded.
  // If multiple association cards load at the same time, they would all have `isFirstLoadForCardType` set to true.
  // Once the initial load settles, if you scroll and load an additional association card, it will have isFirstLoadForCardType set to false
  if (perfStateForCard.isFirstLoadForCardType) {
    logTiming(perfStateForCard.isVisibleOnInitialLoad ? 'initial-load-card-render-finished' : 'after-initial-load-card-render-finished', sharedDimensions, performance.now() - perfStateForCard.startTimeMs, consoleDebugInfo);
  } else {
    // log cards whose type has already loaded once before separately.
    logTiming('prev-loaded-card-render-finished', sharedDimensions, performance.now() - perfStateForCard.startTimeMs, consoleDebugInfo);
  }
  return next(action);
};
const initialLoadSettledMiddleware = storeAPI => next => action => {
  // allow the action to fully process before running this middleware
  next(action);
  if (action.type !== 'CARD_LOADING_FINISHED') {
    return;
  }
  const state = storeAPI.getState();
  const firstCardStartedAtMs = state.timings.firstCardStartedAtMs;
  // This should not happen except potentially in local development
  if (!firstCardStartedAtMs) {
    logError('crm-cards-sdk: initialLoadSettledMiddleware middleware ran but no firstCardStartedAtMs was set.', 'cardPerf.missingFirstCardStartedAtMs.');
    return;
  }
  const visibleCardsAreStillLoading = Object.values(state.dataByCard).some(cardPerfData => cardPerfData.isVisibleOnInitialLoad && cardPerfData.finishedState === undefined);

  // if the initial load hasn't yet "settled", and there are no more visible cards loading
  if (!state.isInitialLoadSettled && !visibleCardsAreStillLoading) {
    const hasCardsInErrorState = Object.values(state.dataByCard).some(cardPerfState => cardPerfState.finishedState === 'ERROR');
    const hasCardsInTimeoutState = Object.values(state.dataByCard).some(cardPerfState => cardPerfState.finishedState === 'TIMEOUT');
    const initialLoadCardCount = Object.keys(state.dataByCard).length.toString();
    const initialLoadVisibleCardCount = Object.values(state.dataByCard).filter(cardPerfState => cardPerfState.isVisibleOnInitialLoad).length.toString();
    const dimensions = Object.assign({
      location: state.location,
      initial_load_card_count: initialLoadCardCount,
      initial_visible_card_count: initialLoadVisibleCardCount,
      has_timed_out_cards: hasCardsInTimeoutState ? 'true' : 'false',
      has_errored_cards: hasCardsInErrorState ? 'true' : 'false',
      is_virtualization_enabled: `${state.isVirtualizationEnabled}`,
      total_card_count: state.totalCardCount.toString()
    }, state.loggingTag ? {
      logging_tag: state.loggingTag
    } : {});
    if (state.changedTabsBeforeInitialLoadSettled) {
      // If there are abandoned cards, don't log timings
      incrementCounter('visible-cards-settled-after-interrupt', dimensions);
    } else {
      logTiming('first-card-started-to-visible-cards-settled', dimensions, performance.now() - firstCardStartedAtMs, {
        finalCardId: action.cardId
      });
      if (state.isResolvedLocation) {
        logTiming('resolver-started-to-visible-cards-settled', dimensions, performance.now() - state.timings.locationResolverRenderStartedMs, {
          finalCardId: action.cardId
        });
      }
      logTiming('location-started-to-visible-cards-settled', dimensions, performance.now() - state.timings.locationRenderStartedMs, {
        finalCardId: action.cardId
      });
    }
    const markDimensions = {
      is_first_load: getIsFirstLoad(state.location, state.loggingTag),
      was_hidden: getWasHidden()
    };
    mark(getCrmLocationInitialCardsReady(state.location), markDimensions);

    // dispatch a new action saying initial load has settled
    storeAPI.dispatch({
      type: 'INITIAL_LOAD_SETTLED'
    });
  }
};
export const middleware = [errorLoggingMiddleware,
// must be first, this will handle any errors down the chain
containerTabChangedMiddleware, cardLoadingStartedMiddleware, firstCardLoadingStartedMiddleware, cardLoadingFinishedMiddleware, firstCardFinishedSuccessMiddleware, thirdCardFinishedSuccessMiddleware, initialLoadSettledMiddleware];