import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
import { PreviewConfigContext, PreviewConfigRecord
// @ts-expect-error untyped module
} from 'customer-data-sidebar/universal/context/PreviewConfigContext';
import { useCallback, useContext } from 'react';
import { getPreviewDefaultActionsData, getRemoveDefaultActionsData } from '../../default/getDefaultChickletHoverActions';
export const FlywheelDefaultChickletHoverActions = args => {
  const {
    setPreviewConfig
  } = useContext(PreviewConfigContext);
  const {
    objectId,
    toObjectTypeId
  } = args;
  const handlePreviewRecord = useCallback(() => {
    CrmLogger.log('previewSidebarObject', {
      action: 'preview record',
      objectId: String(objectId),
      objectTypeId: toObjectTypeId
    });
    setPreviewConfig(PreviewConfigRecord({
      objectId: String(objectId),
      objectTypeId: toObjectTypeId
    }));
  }, [objectId, setPreviewConfig, toObjectTypeId]);
  const SecondaryActions = args.canEdit ? [getRemoveDefaultActionsData(args)] : [];
  return {
    PrimaryAction: getPreviewDefaultActionsData(Object.assign({}, args, {
      onPreview: handlePreviewRecord
    })),
    SecondaryActions
  };
};