import { useCallback } from 'react';
import { useTimelineAuthContext } from '../context/TimelineAuthContext';
import withGateOverride from '../utils/withGateOverride';

/**
 * Returns a callback that subscribes to the current `auth` object to check gates.
 *
 * @example
 * function MyGatedFeature() {
 *   const isUngated = useIsUngated();
 *   if (!isUngated('my-feature')) {
 *     return null;
 *   }
 *   return <MyFeatureContents />;
 * }
 * @returns (gateName: string) => boolean
 */
export function useIsUngated() {
  const {
    gates
  } = useTimelineAuthContext();
  return useCallback(gateName => {
    return withGateOverride(gateName, (gates || []).includes(gateName));
  }, [gates]);
}