import { TASK_PROPERTY_UPDATED } from 'crm-events-data';
// @ts-expect-error Could not find a declaration file
import { updateStoreWithResponseTask } from 'crm_data/tasks/TaskActions';
import { useCallback } from 'react';
import TaskRecord from 'customer-data-objects/task/TaskRecord';
import { Map as ImmutableMap } from 'immutable';
export const useTaskUpdateActionHandler = () => {
  const handleTaskUpdateAction = useCallback((actionType, data) => {
    if (actionType === TASK_PROPERTY_UPDATED) {
      const {
        tasksStoreKey,
        updatedTask
      } = data;
      const taskRecord = TaskRecord.fromJS(updatedTask);
      const immutableTasksStoreKey = ImmutableMap(tasksStoreKey);
      updateStoreWithResponseTask(taskRecord, immutableTasksStoreKey);
    }
    return null;
  }, []);
  return {
    handleTaskUpdateAction
  };
};