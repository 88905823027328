// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'disp... Remove this comment to see the full error message
import dispatcher from 'dispatcher/dispatcher';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { defineLazyKeyStore } from 'crm_data/store/LazyKeyStore';
import { ENGAGEMENTS_FETCH_SUCCEEDED, ES_FETCH_SUCCEEDED, ENGAGEMENTS_UPDATED
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
import { Map as ImmutableMap } from 'immutable';
import { getId } from 'customer-data-objects/model/ImmutableModel';
import { getFullEmailAndPreview, isEmailEngagement, getEngagementOrInteractionType, PINNED_ENGAGEMENT_UPDATED } from 'crm-events-data';
import { ENGAGEMENT } from 'customer-data-objects/constants/ObjectTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
export const emailEngagementBodyReducer = (acc, engagement) => {
  const engagementType = getEngagementOrInteractionType({
    engagement
  });
  if (!isEmailEngagement(engagementType)) {
    return acc;
  }
  const id = getId(engagement);
  const emailContent = getFullEmailAndPreview(engagement);
  return acc.set(`${id}`, emailContent);
};
export default defineLazyKeyStore({
  getInitialState: () => ImmutableMap(),
  namespace: 'EMAIL_ENGAGEMENT_BODY',
  idIsValid: key => typeof key === 'string'
}).defineResponseTo([ENGAGEMENTS_FETCH_SUCCEEDED, PINNED_ENGAGEMENT_UPDATED, ENGAGEMENTS_UPDATED], (state, data) => {
  const updatedState = data.reduce(emailEngagementBodyReducer, state);
  return updatedState;
}).defineResponseTo([ES_FETCH_SUCCEEDED], (state, data) => {
  if (data.objectType !== ENGAGEMENT || !data.results || data.results.isEmpty()) {
    return state;
  }
  const unformattedEngagements = data.results.get('_results');
  const updatedState = unformattedEngagements.reduce((acc, unformattedEngagement) => {
    const formattedEngagement = EngagementRecord.fromJS(unformattedEngagement);
    return emailEngagementBodyReducer(acc, formattedEngagement);
  }, state);
  return updatedState;
}).register(dispatcher);