import { BIDEN, COMPANY_INSIGHTS } from 'customer-data-objects/property/PropertySourceTypes';
/**
 * PropertyValueModel is a collection of functions for working
 * with PropertyValueRecords.
 */
/**
 * Returns true if the PropertyValue was generated
 * by Company Insights (HubSpot's company information service,
 * previously known as Biden).
 *
 * @param  {PropertyValueRecord}
 * @return {boolean}
 */
export function isBidenValue(propertyValue) {
  if (propertyValue === undefined) {
    return false;
  }
  return (
    // BIDEN property source is deprecated in favor of COMPANY_INSIGHTS
    propertyValue.source === BIDEN || propertyValue.source === COMPANY_INSIGHTS
  );
}