import enviro from 'enviro';
export const LOCAL_SETTINGS_PREFIX = 'CRM:SDK:';
export function makePrefixedKey(key) {
  return LOCAL_SETTINGS_PREFIX + key;
}

/**
 * Removes `key` from `localStorage`.
 *
 * @example
 * deleteFrom('setting');
 */
export function deleteFrom(key) {
  try {
    delete localStorage[makePrefixedKey(key)];
  } catch (error) {
    if (!enviro.isProd()) {
      console.error('Error: deleteFrom:', error);
    }
  }
}

/**
 * Retrieves `key` from localStorage.
 * Returns the result of JSON.parse'ing the string found in localStorage.
 * Returns `undefined` if localStorage access or JSON.parse fail.
 *
 * @example
 * getFrom('setting') === true
 */
export function getFrom(key) {
  try {
    const entry = localStorage[makePrefixedKey(key)];
    if (entry === undefined) {
      return entry;
    }
    return JSON.parse(entry);
  } catch (error) {
    if (!enviro.isProd()) {
      console.error('Error: getFrom:', error);
    }
    return undefined;
  }
}

/**
 * Adds `value` at `key`.
 * JSON.stringify's `value` before writing to localStorage.
 * Returns `value` if set successfully; otherwise `undefined`.
 *
 * @example
 * setFrom('setting', true);
 */
export function setFrom(key, value) {
  try {
    localStorage[makePrefixedKey(key)] = JSON.stringify(value);
    return value;
  } catch (error) {
    if (!enviro.isProd()) {
      console.error('Error: setFrom:', error);
    }
    return undefined;
  }
}