import styled from 'styled-components';
import { makeCustomProperty } from './utils';
export const LayoutBox = styled.div.withConfig({
  displayName: "LayoutBox",
  componentId: "hwaqae-0"
})(["", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";"], ({
  alignSelf
}) => alignSelf && `align-self: ${alignSelf};`, ({
  background
}) => background && `background: ${makeCustomProperty(background)};`, ({
  blockSize
}) => blockSize && `block-size: ${blockSize}`, ({
  border
}) => border && `border: ${makeCustomProperty(border)};`, ({
  borderBlock
}) => borderBlock && `border-block: ${makeCustomProperty(borderBlock)};`, ({
  borderBlockEnd
}) => borderBlockEnd && `border-block-end: ${makeCustomProperty(borderBlockEnd)};`, ({
  borderBlockStart
}) => borderBlockStart && `border-block-start: ${makeCustomProperty(borderBlockStart)};`, ({
  borderInline
}) => borderInline && `border-inline: ${makeCustomProperty(borderInline)};`, ({
  borderInlineEnd
}) => borderInlineEnd && `border-inline-end: ${makeCustomProperty(borderInlineEnd)};`, ({
  borderInlineStart
}) => borderInlineStart && `border-inline-start: ${makeCustomProperty(borderInlineStart)};`, ({
  borderRadius
}) => borderRadius && `border-radius: ${makeCustomProperty(borderRadius)};`, ({
  borderRadiusEndEnd
}) => borderRadiusEndEnd && `border-end-end-radius: ${makeCustomProperty(borderRadiusEndEnd)};`, ({
  borderRadiusEndStart
}) => borderRadiusEndStart && `border-end-start-radius: ${makeCustomProperty(borderRadiusEndStart)};`, ({
  borderRadiusStartEnd
}) => borderRadiusStartEnd && `border-start-end-radius: ${makeCustomProperty(borderRadiusStartEnd)};`, ({
  borderRadiusStartStart
}) => borderRadiusStartStart && `border-start-start-radius: ${makeCustomProperty(borderRadiusStartStart)};`, ({
  display
}) => display && `display: ${display};`, ({
  flex
}) => flex && `flex: ${flex}`, ({
  flexBasis
}) => flexBasis && `flex-basis: ${flexBasis}`, ({
  flexGrow
}) => flexGrow && `flex-grow: ${flexGrow}`, ({
  flexShrink
}) => flexShrink && `flex-shrink: ${flexShrink}`, ({
  inlineSize
}) => inlineSize && `inline-size: ${inlineSize}`, ({
  inset
}) => inset && `inset: ${makeCustomProperty(inset)};`, ({
  insetBlock
}) => insetBlock && `inset-block: ${makeCustomProperty(insetBlock)};`, ({
  insetBlockEnd
}) => insetBlockEnd && `inset-block-end: ${insetBlockEnd};`, ({
  insetBlockStart
}) => insetBlockStart && `inset-block-start: ${makeCustomProperty(insetBlockStart)};`, ({
  insetInline
}) => insetInline && `inset-inline: ${makeCustomProperty(insetInline)};`, ({
  insetInlineEnd
}) => insetInlineEnd && `inset-inline-end: ${makeCustomProperty(insetInlineEnd)};`, ({
  insetInlineStart
}) => insetInlineStart && `inset-inline-start: ${makeCustomProperty(insetInlineStart)};`, ({
  justifySelf
}) => justifySelf && `justify-self: ${justifySelf}`, ({
  margin
}) => margin && `margin: ${makeCustomProperty(margin)};`, ({
  marginBlock
}) => marginBlock && `margin-block: ${makeCustomProperty(marginBlock)};`, ({
  marginBlockEnd
}) => marginBlockEnd && `margin-block-end: ${makeCustomProperty(marginBlockEnd)};`, ({
  marginBlockStart
}) => marginBlockStart && `margin-block-start: ${makeCustomProperty(marginBlockStart)};`, ({
  marginInline
}) => marginInline && `margin-inline: ${makeCustomProperty(marginInline)};`, ({
  marginInlineEnd
}) => marginInlineEnd && `margin-inline-end: ${makeCustomProperty(marginInlineEnd)};`, ({
  marginInlineStart
}) => marginInlineStart && `margin-inline-start: ${makeCustomProperty(marginInlineStart)};`, ({
  maxBlockSize
}) => maxBlockSize && `max-block-size: ${maxBlockSize}`, ({
  maxInlineSize
}) => maxInlineSize && `max-inline-size: ${maxInlineSize}`, ({
  minBlockSize
}) => minBlockSize && `min-block-size: ${minBlockSize}`, ({
  minInlineSize
}) => minInlineSize && `min-inline-size: ${minInlineSize}`, ({
  opacity
}) => opacity && `opacity: ${opacity};`, ({
  overflow
}) => overflow && `overflow: ${overflow};`, ({
  padding
}) => padding && `padding: ${makeCustomProperty(padding)};`, ({
  paddingBlock
}) => paddingBlock && `padding-block: ${makeCustomProperty(paddingBlock)};`, ({
  paddingBlockEnd
}) => paddingBlockEnd && `padding-block-end: ${makeCustomProperty(paddingBlockEnd)};`, ({
  paddingBlockStart
}) => paddingBlockStart && `padding-block-start: ${makeCustomProperty(paddingBlockStart)};`, ({
  paddingInline
}) => paddingInline && `padding-inline: ${makeCustomProperty(paddingInline)};`, ({
  paddingInlineEnd
}) => paddingInlineEnd && `padding-inline-end: ${makeCustomProperty(paddingInlineEnd)};`, ({
  paddingInlineStart
}) => paddingInlineStart && `padding-inline-start: ${makeCustomProperty(paddingInlineStart)};`, ({
  position
}) => position && `position: ${position};`, ({
  textAlign
}) => textAlign && `text-align: ${textAlign};`, ({
  zIndex
}) => zIndex && `z-index: ${zIndex};`);