'use es6';

import InteractionWithValidatedAttachmentsComponentConfig from 'customer-data-communicator/schema/wrappers/InteractionWithValidatedAttachmentsComponentConfig';
import GenericPluginGroup from 'draft-plugins/plugins/GenericPluginGroup';
import AttachmentPlugin from 'customer-data-rte/plugins/AttachmentPlugin';
import FileManagerAttachmentPlugin from 'customer-data-rte/plugins/FileManagerAttachmentPlugin';
import AttachmentThumbnailUrlConfig from 'customer-data-communicator/data/attachments/AttachmentThumbnailUrlConfig';
export default new InteractionWithValidatedAttachmentsComponentConfig({
  GenericPluginGroup,
  AttachmentPlugin,
  FileManagerAttachmentPlugin,
  thumbnailUrlConfig: AttachmentThumbnailUrlConfig
});