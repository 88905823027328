'use es6';

export const COMMUNICATOR_OUTSIDE_PADDING_X = 'p-x-5';
export const LEFT_SIDEBAR = 'leftSidebar';
export const RIGHT_SIDEBAR = 'rightSidebar';
export const BUS_MESSAGE = 'busMessage';
export const TIMELINE_TAB = 'timelineTab';
export const POPOVER = 'popover';
export const PAGE_LOAD = 'PAGE_LOAD';
export const EMAIL_REPLY = 'EMAIL_REPLY';
export const SEQUENCE_STEP_TASK_QUEUE_EMAIL = 'SEQUENCE_STEP_TASK_QUEUE_EMAIL';