import { useCallback, useEffect, useMemo, useState } from 'react';
import { setCommunicatorTriggerLocation, showCommunicator, setInitialContent
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
} from 'customer-data-communicator/data/communicator/actions/CommunicatorActions';
import { OPEN_COMMUNICATOR, OPEN_EMAIL_COMMUNICATOR, useTimelineAuthContext } from 'crm-events-data';
import { EMAIL } from 'customer-data-communicator/schema/interactions/InteractionTypes';
import { clearInteractionDraft } from 'customer-data-communicator/schema/communicator/interaction/Interactions';
import { EMAIL_REPLY } from './constants/CommunicatorConstants';
import { getReplyInteractionRecord, getForwardInteractionRecord
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
} from 'customer-data-email/schema/email/EmailMediator';
import { getEmail } from 'conversations-thread-view-crm-events/email/EmailEventHelpers';
import { REPLY_ALL, FORWARD } from 'conversations-thread-view-crm-events/email/EmailReplyConstants';
import { EngagementDependency } from 'crm-events-data';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'gene... Remove this comment to see the full error message
import { useStoreDependency } from 'general-store';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import ConnectedAccountsStore from 'crm_data/connectedAccount/ConnectedAccountsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { isLoading, LOADING, EMPTY } from 'crm_data/flux/LoadingStatus';
import I18n from 'I18n';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import UserStore from 'crm_data/user/UserStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import ContactsEmailStore from 'crm_data/contacts/ContactsEmailStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import EmailContent from 'customer-data-communicator/schema/interactions/email/EmailContent';
import ConnectedAccounts from 'customer-data-email/schema/connectedAccount/ConnectedAccounts';
function parseInitialContent(interactionType, initialContent) {
  if (interactionType !== EMAIL || !initialContent) {
    return initialContent;
  }
  return EmailContent.fromJS(initialContent);
}
function getPrependedSubject(originalSubject, prependedSubjectString) {
  if (originalSubject && originalSubject.slice && originalSubject.slice(0, prependedSubjectString.length).toLocaleLowerCase() === prependedSubjectString.toLocaleLowerCase()) {
    return originalSubject;
  }
  return `${prependedSubjectString} ${originalSubject}`;
}
function getSubjectForReply(originalSubject) {
  const prependedSubjectString = I18n.text('timeline.email.reply.prependSubject');
  return getPrependedSubject(originalSubject, prependedSubjectString);
}
function getSubjectForForward(originalSubject) {
  const prependedSubjectString = I18n.text('timeline.email.forward.prependSubject');
  return getPrependedSubject(originalSubject, prependedSubjectString);
}
export const connectedAccountsDependency = {
  stores: [ConnectedAccountsStore],
  deref: ({
    scopes
  }) => {
    if (!scopes['email-clients-access']) {
      return new ConnectedAccounts();
    }
    const connectedAccounts = ConnectedAccountsStore.get();
    if (isLoading(connectedAccounts)) {
      return LOADING;
    }
    return connectedAccounts;
  }
};
export const ccContactsDependency = {
  stores: [ContactsEmailStore],
  deref: ({
    engagement
  }) => {
    if (!engagement) {
      return LOADING;
    }
    const contacts = engagement.getIn(['metadata', 'cc']).map(cc => {
      const email = cc.get('email');
      if (!email) {
        return EMPTY;
      }
      const ccContacts = ContactsEmailStore.get(email);
      if (isLoading(ccContacts)) {
        return LOADING;
      }
      return ccContacts || EMPTY;
    });
    if (contacts.some(isLoading)) {
      return LOADING;
    }
    return contacts.filter(contact => contact);
  }
};
export const toContactsDependency = {
  stores: [ContactsEmailStore],
  deref: ({
    engagement
  }) => {
    if (!engagement) {
      return LOADING;
    }
    const contacts = engagement.getIn(['metadata', 'to']).map(to => {
      const email = to.get('email');
      if (!email) {
        return EMPTY;
      }
      const toContact = ContactsEmailStore.get(email);
      if (isLoading(toContact)) {
        return LOADING;
      }
      return toContact || EMPTY;
    });
    if (contacts.some(isLoading)) {
      return LOADING;
    }
    return contacts.filter(contact => contact);
  }
};
export const fromContactDependency = {
  stores: [ContactsEmailStore],
  deref: ({
    engagement
  }) => {
    if (!engagement) {
      return LOADING;
    }
    if (!engagement.hasIn(['metadata', 'from', 'email'])) {
      return EMPTY;
    }
    const email = getEmail(engagement.getIn(['metadata', 'from', 'email']));
    const fromContact = ContactsEmailStore.get(email);
    if (isLoading(fromContact)) {
      return LOADING;
    }
    return fromContact || EMPTY;
  }
};
function openCommunicator({
  interactionType,
  initialContent
}) {
  if (initialContent) {
    setInitialContent(interactionType, initialContent);
  }
  if (interactionType === 'schedule') {
    showCommunicator(interactionType, {
      shouldOpenFullScreen: true
    });
  } else {
    showCommunicator(interactionType);
  }
}
export const useCommunicatorActionHandler = ({
  objectType,
  subjectId
}) => {
  const [emailEngagementId, setEmailEngagementId] = useState(null);
  const [replyType, setReplyType] = useState(null);
  const {
    user
  } = useTimelineAuthContext();
  const scopes = useMemo(() => {
    if (user) {
      return user.scopes.reduce((accum, scope) => {
        accum[scope] = true;
        return accum;
      }, {});
    }
    return {};
  }, [user]);
  const emailEngagement = useStoreDependency(EngagementDependency, {
    engagementId: emailEngagementId
  });
  const connectedAccounts = useStoreDependency(connectedAccountsDependency, {
    scopes
  });
  const userEmail = useStoreDependency({
    stores: [UserStore],
    deref: () => UserStore.get('email')
  });
  const ccContacts = useStoreDependency(ccContactsDependency, {
    engagement: emailEngagement
  });
  const toContacts = useStoreDependency(toContactsDependency, {
    engagement: emailEngagement
  });
  const fromContact = useStoreDependency(fromContactDependency, {
    engagement: emailEngagement
  });
  useEffect(() => {
    if (emailEngagement && replyType && !isLoading(connectedAccounts) && userEmail && !isLoading(ccContacts, toContacts, fromContact)) {
      const isForward = replyType === FORWARD;
      const isReplyingToAll = replyType === REPLY_ALL;
      const engagementWithModifiedSubject = emailEngagement.updateIn(['metadata', 'subject'], isForward ? getSubjectForForward : getSubjectForReply);
      const replyInteractionRecord = isForward ? getForwardInteractionRecord({
        engagement: engagementWithModifiedSubject,
        connectedAccounts,
        currentUserEmail: userEmail
      }) : getReplyInteractionRecord({
        ccContacts,
        connectedAccounts,
        currentUserEmail: userEmail,
        engagement: engagementWithModifiedSubject,
        fromContact,
        isReplyingToAll,
        toContacts,
        isForward
      });
      const replyEngagementId = engagementWithModifiedSubject.getIn(['engagement', 'id']);
      const replyEngagementType = engagementWithModifiedSubject.getIn(['engagement', 'type']);
      const initialContent = {
        replyInteractionRecord,
        replyEngagementId,
        replyEngagementType
      };
      openCommunicator({
        interactionType: EMAIL,
        initialContent
      });
      setReplyType(null);
      setEmailEngagementId(null);
    }
  }, [ccContacts, connectedAccounts, emailEngagement, fromContact, replyType, toContacts, userEmail]);
  const handleCommunicatorAction = useCallback((actionType, data) => {
    switch (actionType) {
      case OPEN_COMMUNICATOR:
        {
          const {
            triggerLocation,
            interactionType,
            initialContent
          } = data;
          setCommunicatorTriggerLocation({
            triggerLocation
          });
          const parsedInitialContent = parseInitialContent(interactionType, initialContent);
          openCommunicator({
            interactionType,
            initialContent: parsedInitialContent
          });
          return;
        }
      case OPEN_EMAIL_COMMUNICATOR:
        {
          const {
            engagementId,
            replyType: emailReplyType
          } = data;
          clearInteractionDraft(EMAIL, objectType, subjectId);
          setCommunicatorTriggerLocation({
            triggerLocation: EMAIL_REPLY
          });
          setEmailEngagementId(engagementId);
          setReplyType(emailReplyType);
          return;
        }
      default:
        return;
    }
  }, [objectType, subjectId]);
  return {
    handleCommunicatorAction
  };
};