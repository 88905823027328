import { Map as ImmutableMap } from 'immutable';

/**
 * @description TypedMap can be used to type ImmutableMaps which have a
 * specific structure associated with them.
 *
 * @example
 * const myMap: TypedMap<{ a: number; b: string; }> = ImmutableMap({
 *   a: 1234, b: 'some string',
 * });
 */

// @ts-expect-error ts-migrate(2430) FIXME: Interface 'TypedMap<T>' incorrectly extends interf... Remove this comment to see the full error message

/**
 * @param data
 * @returns An ImmutableMap as a TypedMap with full get, set, getIn, and setIn type safety
 */
export const buildTypedMap = data => ImmutableMap(data);