export const objectTypeFromUrl = (w = window) => {
  let screen = 'unknown';
  const location = w && w.location;
  if (location) {
    const {
      search,
      pathname
    } = location || {};
    const objectTypeFromPath = /\d+\/([a-z]+)/g;
    const objectTypeFromQuery = /objectType=([A-Z]+)/g;
    if (search && search.includes('objectType=')) {
      const result = objectTypeFromQuery.exec(search);
      screen = result && result[1] || '';
    } else if (pathname) {
      const result = objectTypeFromPath.exec(pathname);
      screen = result && result[1] || '';
    }
    screen = `${screen || pathname}`.toLowerCase();
  }
  return screen;
};