'use es6';

import uniqueId from 'transmute/uniqueId';
import TokenPlugin from '../token/TokenPlugin';
import TokenResults from '../token/TokenResults';
import TokenResultItem from '../token/TokenResultItem';
import AtMentionZeroState from './AtMentionInviteZeroState';
const DEFAULT_OPTS = {
  trigger: '@',
  entityType: uniqueId('ATMENTION'),
  dataProp: 'users',
  onInsertProp: 'onAtMention',
  useInviteZeroState: false,
  overlayPositioning: {
    placement: 'bottom right'
  },
  addTrailingSpace: true
};
const AtMentionPlugin = (opts = {}) => {
  const optsWithDefaults = Object.assign({}, DEFAULT_OPTS, opts);
  const {
    useInviteZeroState,
    TokenResultsComponent
  } = optsWithDefaults;
  if (useInviteZeroState && !TokenResultsComponent) {
    optsWithDefaults.TokenResultsComponent = TokenResults(TokenResultItem, AtMentionZeroState);
  }
  return TokenPlugin(optsWithDefaults);
};
export default ((...args) => {
  if (args.length === 0) {
    return AtMentionPlugin({});
  }
  return AtMentionPlugin(...args);
});