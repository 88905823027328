import { useQuery, gql } from '@apollo/client';
import { calculateKYCStatus, hasCompletedStripe, hasCompletedUnderwritingReview } from 'growth-payments-core/eligibility/utils';
import { useMemo } from 'react';
const FETCH_ELIGIBILITY_FOR_PAYMENTS = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"fetchUserEligibilityForPayments\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"paymentsEligibility\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"underwritingStatus\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "fetchUserEligibilityForPayments"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "paymentsEligibility"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "underwritingStatus"
            }
          }]
        }
      }]
    }
  }]
});
const useHasCompletedPaymentsApplication = () => {
  var _data$paymentsEligibi2;
  const {
    data,
    loading
  } = useQuery(FETCH_ELIGIBILITY_FOR_PAYMENTS);
  return useMemo(() => {
    var _data$paymentsEligibi;
    const underwritingStatus = data === null || data === void 0 || (_data$paymentsEligibi = data.paymentsEligibility) === null || _data$paymentsEligibi === void 0 ? void 0 : _data$paymentsEligibi.underwritingStatus;
    const kycStatus = calculateKYCStatus(underwritingStatus);
    const completedApplication = hasCompletedStripe(kycStatus);
    const hasCommerceAccess = hasCompletedUnderwritingReview(calculateKYCStatus(underwritingStatus));
    return {
      completedApplication,
      hasCommerceAccess,
      kycStatus,
      loading,
      underwritingStatus
    };
  }, [data === null || data === void 0 || (_data$paymentsEligibi2 = data.paymentsEligibility) === null || _data$paymentsEligibi2 === void 0 ? void 0 : _data$paymentsEligibi2.underwritingStatus, loading]);
};
export default useHasCompletedPaymentsApplication;