'use es6';

import { get } from 'crm_data/api/ImmutableAPI';
import { Map as ImmutableMap } from 'immutable';
import { put } from 'crm_data/api/ImmutableAPI';
const BASE_URI = 'campaigns/v1/campaigns';
const URI = `${BASE_URI}/guids`;
export function fetch(campaignIds) {
  const query = {
    guid: campaignIds.toJS(),
    includeDeletes: true
  };
  return get(URI, query).then(results => {
    return results.reduce((campaigns, campaign) => {
      return campaigns.set(campaign.get('guid'), campaign);
    }, ImmutableMap());
  });
}
export function updateCampaignProperties(campaign, guid) {
  return put(`${BASE_URI}/${guid}`, campaign);
}