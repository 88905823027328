import { shouldRedirectOnObjectCreation } from '../utils/shouldRedirectOnObjectCreation';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../router/useQueryParams';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './ho... Remove this comment to see the full error message
import { confirmNavigation } from 'customer-data-ui-utilities/navigation/NavigationConfirmation';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './ho... Remove this comment to see the full error message
import links from 'crm-legacy-links/links';
import { useCallback } from 'react';
import { denormalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
const getCreateRedirectLink = (objectType, objectId) => {
  return links.fromObjectTypeAndId(objectType, objectId, false);
};
export function useRedirectOnObjectCreation() {
  const queryParams = useQueryParams();
  const history = useHistory();
  const handleObjectCreated = useCallback(({
    objectId,
    objectTypeId,
    preventRedirect
  }) => {
    const redirectOnObjectCreation = shouldRedirectOnObjectCreation(denormalizeTypeId(objectTypeId), queryParams);
    if (!preventRedirect && redirectOnObjectCreation === true) {
      confirmNavigation().then(() => {
        history.push(getCreateRedirectLink(denormalizeTypeId(objectTypeId), Number(objectId)));
      }).catch(err => {
        setTimeout(() => {
          throw err;
        });
      });
    }
  }, [history, queryParams]);
  useOnCrmMessageTopic(TOPIC_NAMES.OBJECT_CREATED, handleObjectCreated);
}