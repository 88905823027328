'use es6';

import { QUEUES_CACHE_UPDATED, QUEUES_FETCH_STARTED, QUEUES_FETCH_SETTLED } from 'crm_data/actions/ActionTypes';
import { dispatchImmediate, dispatchQueue } from 'crm_data/dispatch/Dispatch';
import { fetch } from 'crm_data/queues/QueuesAPI';
import registerService from 'crm_data/flux/registerService';
import { Map as ImmutableMap } from 'immutable';
let pending = false;
const handlers = {
  [QUEUES_FETCH_STARTED]: (state, options = {}) => {
    const {
      ownerId
    } = options;
    if (pending) {
      return state;
    }
    pending = true;
    fetch(ownerId).then(results => {
      dispatchImmediate(QUEUES_CACHE_UPDATED, results);
      dispatchQueue(QUEUES_FETCH_SETTLED);
    }, () => {
      dispatchImmediate(QUEUES_FETCH_SETTLED);
    });
    return state;
  },
  [QUEUES_FETCH_SETTLED]: state => {
    pending = false;
    return state;
  }
};
registerService(ImmutableMap(), handlers);