'use es6';

import Superstore, { IndexedDB } from 'superstore';
export const defaultNamespace = 'crm-data-cache';
let client;
export const getSuperstoreClient = (namespace = defaultNamespace) => {
  if (!client) {
    client = new Superstore({
      backend: IndexedDB,
      namespace
    }).open();
  }
  return client;
};