// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'gene... Remove this comment to see the full error message
import { defineFactory } from 'general-store';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { IMAGE_ATTACHMENT_SELECTED } from 'crm_data/actions/ActionTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'disp... Remove this comment to see the full error message
import dispatcher from 'dispatcher/dispatcher';
import { fromJS } from 'immutable';
const RteImageAttachmentStore = defineFactory().defineName('RteImageAttachment').defineGetInitialState(() => null).defineResponseTo(IMAGE_ATTACHMENT_SELECTED, (_state, data) => {
  if (!data) {
    return null;
  }
  return fromJS(data.imgData);
}).register(dispatcher);
export default RteImageAttachmentStore;