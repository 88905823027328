module.exports = {
  "viewLineItems": {
    "properties": {
      "screen": "string",
      "sourceIsShopify": {
        "type": "boolean",
        "isOptional": true
      },
      "sourceIsEcommBridge": {
        "type": "boolean",
        "isOptional": true
      },
      "readOnly": {
        "type": "boolean"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "pageview",
    "class": "view"
  },
  "saveProductFromLineItem": {
    "properties": {
      "action": [
        "Save first product from line item",
        "Save product from line item"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems usage",
    "class": "usage"
  },
  "bulkSaveLineItems": {
    "properties": {
      "action": [
        "Bulk save line items"
      ],
      "numLineItemsWithTax": {
        "type": "number",
        "isOptional": true
      },
      "billingFrequency": {
        "type": "array",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems usage",
    "class": "usage"
  },
  "updateDealAmount": {
    "properties": {
      "action": [
        "Update deal amount"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems usage",
    "class": "usage"
  },
  "updateLineItemTableColumns": {
    "properties": {
      "action": [
        "Update line item table columns"
      ],
      "added": {
        "type": "array",
        "isOptional": true
      },
      "removed": {
        "type": "array",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems usage",
    "class": "usage"
  },
  "updateLineItemTableColumnWidth": {
    "properties": {
      "action": [
        "Update line item table column width"
      ],
      "propertyName": {
        "type": "string"
      },
      "value": {
        "type": "number"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems usage",
    "class": "usage"
  },
  "cancelLineItemTableColumnsUpdate": {
    "properties": {
      "action": [
        "Cancel edit columns",
        "Cancel edit columns confirmation"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems usage",
    "class": "usage"
  },
  "addLineItem": {
    "properties": {
      "action": [
        "Add line item",
        "Clone line item"
      ],
      "screen": {
        "type": "string"
      },
      "isCustom": {
        "type": "boolean",
        "isOptional": true
      },
      "subAction": {
        "type": [
          "SKU collision"
        ],
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "editLineItem": {
    "properties": {
      "action": [
        "Edit line item"
      ],
      "screen": {
        "type": "string"
      },
      "propertyName": {
        "type": "string"
      },
      "isInlineEdit": "boolean",
      "billingFrequency": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "removeLineItem": {
    "properties": {
      "action": [
        "Remove line item"
      ],
      "screen": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "reorderLineItem": {
    "properties": {
      "action": [
        "Reorder line item"
      ],
      "screen": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "selectInvalidCurrencyResolutionOption": {
    "properties": {
      "action": [
        "Select invalid currency resolution option"
      ],
      "option": [
        "product_price",
        "exchange_rate",
        "custom"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "resolveInvalidCurrencies": {
    "properties": {
      "action": [
        "Resolve line item currencies"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "cancelStagedLineItemChanges": {
    "properties": {
      "action": [
        "Cancel staged line item changes"
      ],
      "screen": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "goToLineItemEditorFromRecordCard": {
    "properties": {
      "action": [
        "Click line items sidebar card button"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "goToDealFromLineItemEditor": {
    "properties": {
      "action": [
        "Click deal link"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "editGlobalTerms": {
    "properties": {
      "action": [
        "Edit global terms"
      ],
      "screen": {
        "type": "string"
      },
      "propertyName": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "openGlobalTermsModal": {
    "properties": {
      "action": [
        "Open global terms modal"
      ],
      "screen": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "openEditColumnsModal": {
    "properties": {
      "action": [
        "Open edit columns modal"
      ],
      "screen": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "clickTableCell": {
    "properties": {
      "action": [
        "Click table cell"
      ],
      "propertyName": {
        "type": "string"
      },
      "screen": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "addLineItemsPanelFilter": {
    "properties": {
      "action": [
        "Filter products",
        "Select product view",
        "Clear selected product view"
      ],
      "filterProperties": {
        "type": "array",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "clickAddLineItemFromZeroState": {
    "properties": {
      "action": [
        "Select from product library",
        "Create custom line item"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "addLineItemsPanelInteractions": {
    "properties": {
      "action": [
        "Select product",
        "Cancel add line item from product",
        "Open add line items panel"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "lineItemValidation": {
    "properties": {
      "propertyName": {
        "type": "string"
      },
      "action": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  }
};