import usageTracker, { beaconTracker } from './usageTracker';
export function track(event, properties) {
  usageTracker.track(event, properties);
}
export function beaconTrack(event, properties) {
  beaconTracker.track(event, properties);
}
export const setDefaultProperties = properties => {
  usageTracker.setProperties(properties);
};

/**
 * Wrap a function, and track the event when it is called.
 *
 * The properties can either be a properties object, or a function that takes the arguments from the wrapped
 * funciton and returns a properties object.
 * @param func Function to wrap
 * @param event Event to track
 * @param properties Properties to track, or a function that takes the arguments from the wrapped function and returns a properties object.
 * @returns The original function, wrapped to track the event.
 */
export function trackWrapped(func, event, properties) {
  return function (...args) {
    if (properties instanceof Function) {
      track(event, properties(...args));
    } else {
      track(event, properties);
    }
    return func(...args);
  };
}
export function beaconTrackWrapped(func, event, properties) {
  return function (...args) {
    beaconTrack(event, properties);
    return func(...args);
  };
}