import { useState } from 'react';
export function useDisclosure({
  isOpen: controlledIsOpen,
  onOpen,
  onClose
}) {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);
  const isControlled = controlledIsOpen !== undefined;
  const isOpen = isControlled ? controlledIsOpen : uncontrolledIsOpen;
  const handleToggle = () => {
    const newIsOpen = !isOpen;
    if (!isControlled) {
      setUncontrolledIsOpen(newIsOpen);
    }
    if (newIsOpen) {
      onOpen();
    } else {
      onClose();
    }
  };
  return {
    isOpen,
    onToggle: handleToggle
  };
}