'use es6';

import dispatcher from 'dispatcher/dispatcher';
import isString from 'transmute/isString';
import { defineJoinStore } from '../store/JoinStore';
import OwnerIdsByEmailStore from './OwnerIdsByEmailStore';
import OwnersStore from './OwnersStore';
export default defineJoinStore({
  stores: [OwnerIdsByEmailStore, OwnersStore],
  idIsValid: isString
}).defineName('OwnersByEmailStore').register(dispatcher);