import I18n from 'I18n';
import formatName from 'I18n/utils/formatName';
import getSampleContacts from './getSampleContacts';
import makeCallLogSampleTimelineEvent from '../factories/makeCallLogSampleTimelineEvent';
import makeMeetingSampleTimelineEvent from '../factories/makeMeetingSampleTimelineEvent';
import makeTaskSampleTimelineEvent from '../factories/makeTaskSampleTimelineEvent';
import makeEmailLogSampleTimelineEvent from '../factories/makeEmailLogSampleTimelineEvent';
import makeEmailOpenSampleTimelineEvent from '../factories/makeEmailOpenSampleTimelineEvent';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import UserStore from 'crm_data/user/UserStore';
export const getSampleTimelineEvents = ownerId => {
  const USER_FIRSTNAME = UserStore.get('first_name') || '';
  const USER_LASTNAME = UserStore.get('last_name') || '';
  const {
    BRIAN,
    COOL_ROBOT,
    MARIA
  } = getSampleContacts();
  const sampleTimelineEvents = {
    BRIAN: [makeMeetingSampleTimelineEvent({
      inviteeEmails: [BRIAN.CONTACT_EMAIL],
      title: I18n.text('data.sampleData.sampleTimelineEvents.brian.sampleMeeting.title'),
      body: I18n.text('data.sampleData.sampleTimelineEvents.brian.sampleMeeting.body'),
      endTime: I18n.moment().add(3, 'days').valueOf(),
      startTime: I18n.moment().add(3, 'days').valueOf(),
      ownerId
    }), makeTaskSampleTimelineEvent({
      title: I18n.text('data.sampleData.sampleTimelineEvents.brian.sampleTask.title'),
      body: I18n.text('data.sampleData.sampleTimelineEvents.brian.sampleTask.body'),
      ownerId
    }), makeCallLogSampleTimelineEvent({
      body: I18n.text('data.sampleData.sampleTimelineEvents.brian.sampleCallNote.body'),
      ownerId
    }), makeEmailOpenSampleTimelineEvent({
      created: I18n.moment().subtract(1, 'days').valueOf(),
      subject: I18n.text('data.sampleData.sampleTimelineEvents.brian.sampleEmail.subject'),
      sources: [{
        domain: 'hubspot.com',
        email: BRIAN.CONTACT_EMAIL,
        name: `${BRIAN.CONTACT_FIRST_NAME} ${BRIAN.CONTACT_LAST_NAME}`
      }],
      ownerId
    }), makeEmailLogSampleTimelineEvent({
      subject: I18n.text('data.sampleData.sampleTimelineEvents.brian.sampleEmail.subject'),
      html: I18n.text('data.sampleData.sampleTimelineEvents.brian.sampleEmail.body', {
        userFirstName: USER_FIRSTNAME
      }),
      toEmail: BRIAN.CONTACT_EMAIL,
      toFirstName: BRIAN.CONTACT_FIRST_NAME,
      toLastName: BRIAN.CONTACT_LAST_NAME,
      created: I18n.moment().subtract(2, 'days').valueOf(),
      ownerId
    })],
    COOL_ROBOT: [makeMeetingSampleTimelineEvent({
      inviteeEmails: [COOL_ROBOT.CONTACT_EMAIL],
      title: I18n.text('data.sampleData.sampleTimelineEvents.coolRobot.sampleMeeting.title'),
      body: I18n.text('data.sampleData.sampleTimelineEvents.coolRobot.sampleMeeting.body'),
      endTime: I18n.moment().add(3, 'days').valueOf(),
      startTime: I18n.moment().add(3, 'days').valueOf(),
      ownerId
    }), makeTaskSampleTimelineEvent({
      title: I18n.text('data.sampleData.sampleTimelineEvents.coolRobot.sampleTask.title'),
      body: I18n.text('data.sampleData.sampleTimelineEvents.coolRobot.sampleTask.body'),
      ownerId
    }), makeCallLogSampleTimelineEvent({
      body: I18n.text('data.sampleData.sampleTimelineEvents.coolRobot.sampleCallNote.body'),
      ownerId
    }), makeEmailOpenSampleTimelineEvent({
      created: I18n.moment().subtract(1, 'days').valueOf(),
      subject: I18n.text('data.sampleData.sampleTimelineEvents.coolRobot.sampleEmail.subject'),
      sources: [{
        domain: 'hubspot.com',
        email: COOL_ROBOT.CONTACT_EMAIL,
        name: `${COOL_ROBOT.CONTACT_FIRST_NAME} ${COOL_ROBOT.CONTACT_LAST_NAME}`
      }],
      ownerId
    }), makeEmailLogSampleTimelineEvent({
      subject: I18n.text('data.sampleData.sampleTimelineEvents.coolRobot.sampleEmail.subject'),
      html: I18n.text('data.sampleData.sampleTimelineEvents.coolRobot.sampleEmail.body', {
        userFirstName: USER_FIRSTNAME
      }),
      toEmail: COOL_ROBOT.CONTACT_EMAIL,
      toFirstName: COOL_ROBOT.CONTACT_FIRST_NAME,
      toLastName: COOL_ROBOT.CONTACT_LAST_NAME,
      created: I18n.moment().subtract(2, 'days').valueOf(),
      ownerId
    })],
    MARIA: [makeTaskSampleTimelineEvent({
      title: I18n.text('data.sampleData.sampleTimelineEvents.maria.sampleTask.title'),
      body: I18n.text('data.sampleData.sampleTimelineEvents.maria.sampleTask.body'),
      timestamp: I18n.moment().add(2, 'hours').valueOf(),
      ownerId
    }), makeCallLogSampleTimelineEvent({
      body: I18n.text('data.sampleData.sampleTimelineEvents.maria.sampleCallNote.body'),
      ownerId
    }), makeMeetingSampleTimelineEvent({
      inviteeEmails: [MARIA.CONTACT_EMAIL],
      title: I18n.text('data.sampleData.sampleTimelineEvents.maria.sampleMeeting.title', {
        userName: formatName({
          firstName: USER_FIRSTNAME,
          lastName: USER_LASTNAME
        })
      }),
      body: I18n.text('data.sampleData.sampleTimelineEvents.maria.sampleMeeting.body'),
      endTime: I18n.moment().subtract(1, 'hours').valueOf(),
      startTime: I18n.moment().subtract(1, 'hours').valueOf(),
      ownerId
    }), makeEmailOpenSampleTimelineEvent({
      created: I18n.moment().subtract(2, 'hours').valueOf(),
      subject: I18n.text('data.sampleData.sampleTimelineEvents.maria.sampleEmail.subject'),
      sources: [{
        domain: 'hubspot.com',
        email: MARIA.CONTACT_EMAIL,
        name: `${MARIA.CONTACT_FIRST_NAME} ${MARIA.CONTACT_LAST_NAME}`
      }],
      ownerId
    }), makeEmailLogSampleTimelineEvent({
      subject: I18n.text('data.sampleData.sampleTimelineEvents.maria.sampleEmail.subject'),
      html: I18n.text('data.sampleData.sampleTimelineEvents.maria.sampleEmail.body', {
        userFirstName: USER_FIRSTNAME
      }),
      toEmail: MARIA.CONTACT_EMAIL,
      toFirstName: MARIA.CONTACT_FIRST_NAME,
      toLastName: MARIA.CONTACT_LAST_NAME,
      created: I18n.moment().subtract(3, 'hours').valueOf(),
      ownerId
    })]
  };
  return sampleTimelineEvents;
};