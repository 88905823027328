// ATTN: maintain alphabetical order
import { CALL } from 'customer-data-communicator/schema/interactions/InteractionTypes';
import { COMPANY, CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import CommunicatorLogger from 'customer-data-tracking/loggers/CommunicatorLogger';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'customer-data-communicator/dat... Remove this comment to see the full error message
import { logPageAction } from 'customer-data-communicator/data/communicator/utils/eventLogger';
import { ObjectTypesToIds, denormalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'customer-data-communicator/dat... Remove this comment to see the full error message
import { PageActions } from 'customer-data-communicator/data/communicator/actions/CommunicatorPageActions';
import { useCallback } from 'react';
import openCallingWidget from 'calling-global-api/methods/openCallingWidget';
import { RIGHT_SIDEBAR
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_ui/components/profile/inte... Remove this comment to see the full error message
} from 'crm_ui/components/profile/interactions/common/CommunicatorConstants';
import { setCommunicatorTriggerLocation, setCommunicatorLaunchContext
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'customer-data-communicator/dat... Remove this comment to see the full error message
} from 'customer-data-communicator/data/communicator/actions/CommunicatorActions';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
function _isCallInteraction(interactionType, defaultInteraction) {
  const interaction = interactionType || defaultInteraction;
  return interaction === CALL;
}
export const useCommunicatorState = ({
  objectType,
  subjectId,
  activeInteraction,
  defaultInteraction
}) => {
  const sendCrmMessage = useSendCrmMessageTopic(TOPIC_NAMES.OPEN_ACTIVITY_CREATOR);
  const isCallInteraction = useCallback((interactionType = activeInteraction) => {
    return _isCallInteraction(interactionType, defaultInteraction);
  }, [activeInteraction, defaultInteraction]);
  const handleCardCommunicatorClick = useCallback((interactionType, previewObjectType, previewSubjectId, _objectType, _subjectId, propertyName) => {
    /*
     * There are three subjectId/objectType pairs here
     * 1) this.props.subjectId/objectType => object id/type of the record page
     * 2) previewObject id/type => object id/type of the record preview
     * 3) subject id/type => subject id/type of the contact/company card that user has clicked on (email/call)
     */

    let communicatorSubjectId = _subjectId;
    let communicatorObjectType = _objectType;
    if (isCallInteraction(interactionType)) {
      // reassigning for code clarity below
      const recordObjectType = objectType;
      const recordSubjectId = subjectId;
      communicatorSubjectId = previewSubjectId || recordSubjectId;
      communicatorObjectType = previewObjectType || recordObjectType;
      if (window.hsCalling) {
        openCallingWidget({
          toSubject: {
            objectId: _subjectId,
            objectTypeId: ObjectTypesToIds[_objectType],
            // @ts-expect-error preserving potential legacy behavior, but this property is not expected
            legacyObjectType: _objectType,
            numberPropertyName: propertyName
          },
          startCall: false
        }).catch(console.error);
      }

      // if a company is selected from a contact sidebar choose the appended company rather than updating the callee.
      if (recordObjectType === CONTACT && _objectType === COMPANY) {
        propertyName = 'company';
        _subjectId = recordSubjectId;
        _objectType = recordObjectType;
        communicatorSubjectId = recordSubjectId;
      }
    }
    CommunicatorLogger.log('communicatorInteraction', {
      action: 'open communicator',
      activity: interactionType,
      channel: interactionType === 'call' ? 'outbound call' : interactionType,
      source: 'profile'
    });
    if (!isCallInteraction(interactionType)) {
      sendCrmMessage({
        interactionType,
        objectTypeId: ObjectTypesToIds[communicatorObjectType] || objectType,
        objectId: Number(communicatorSubjectId)
      });
    } else {
      setCommunicatorTriggerLocation({
        triggerLocation: RIGHT_SIDEBAR
      });
      setCommunicatorLaunchContext({
        objectId: _subjectId,
        objectType: _objectType
      });
    }
    logPageAction({
      key: PageActions.COMMUNICATOR_TRIGGER_LOCATION,
      tags: {
        location: RIGHT_SIDEBAR
      }
    });
  }, [isCallInteraction, objectType, sendCrmMessage, subjectId]);
  const handleOpenCallWidgetMessage = useCallback(({
    objectTypeId,
    objectId
  }) => {
    handleCardCommunicatorClick('call', objectType, subjectId, denormalizeTypeId(objectTypeId), objectId, 'phone');
  }, [handleCardCommunicatorClick, objectType, subjectId]);
  useOnCrmMessageTopic(TOPIC_NAMES.OPEN_CALL_WIDGET, handleOpenCallWidgetMessage, {
    ignoreLocal: true
  });
  return {
    handleCardCommunicatorClick
  };
};