import BETServiceObjectsStore from '../engagementAssociations/stores/BETServiceObjectsStore';
import { List, Map as ImmutableMap } from 'immutable';
import { ENGAGEMENT } from 'customer-data-objects/constants/ObjectTypes';
import PropTypes from 'prop-types';
const BETAssociatedServiceObjectsDependency = {
  propTypes: {
    scopes: PropTypes.arrayOf(PropTypes.string)
  },
  stores: [BETServiceObjectsStore],
  deref({
    interaction,
    scopes = [],
    engagement,
    BETAssociatedServiceObjects
  }) {
    if (!scopes.includes('bet-deliverable-service-association-access')) {
      return List();
    }
    if (BETAssociatedServiceObjects) {
      return BETAssociatedServiceObjects;
    }
    const engagementId = interaction && interaction.getIn(['engagement', 'id']) || engagement && engagement.getIn(['engagement', 'id']);
    if (!engagementId) {
      return List();
    }
    return BETServiceObjectsStore.get(ImmutableMap({
      objectType: ENGAGEMENT,
      subjectId: engagementId
    })) || List();
  }
};
export default BETAssociatedServiceObjectsDependency;