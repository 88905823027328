'use es6';

import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
import makeBatch from 'crm_data/api/makeBatch';
import { POST } from 'crm_data/constants/HTTPVerbs';
const BASE_URI = 'filemanager/api/v2/files';
const BASE_URI_V3 = 'filemanager/api/v3/files';
export function uploadFile(file, options = {}) {
  const {
    access
  } = options;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('options', JSON.stringify({
    access
  }));
  return ImmutableAPI.send({
    type: POST,
    timeout: 0,
    headers: {
      'content-type': false
    }
  }, `${BASE_URI_V3}/upload`, formData);
}
export function fetchSignedFile(fileId) {
  return ImmutableAPI.get(`filemanager/api/v2/files/${fileId}/signed-url`);
}
export const fetchSignedFiles = makeBatch(fetchSignedFile, 'FileManagerAPI.fetchSignedFile');
export function permanentlyDeleteFile(fileId) {
  return ImmutableAPI.post(`${BASE_URI}/${encodeURIComponent(fileId)}/delete-permanently`);
}
export function gdprDeleteFile(fileId) {
  return ImmutableAPI.post(`${BASE_URI}/${fileId}/gdpr-delete`);
}