export const EMAIL_ACTIVITY = 'EMAIL_ACTIVITY';
export const CALL_ACTIVITY = 'CALL_ACTIVITY';
export const MEETING_ACTIVITY = 'MEETING_ACTIVITY';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
// @ts-expect-error module/module.exports aren't currently supported
if (!!module && !!module.exports) {
  // @ts-expect-error module/module.exports aren't currently supported
  module.exports.default = Object.assign({}, module.exports);
}