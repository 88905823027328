import { Set as ImmutableSet, fromJS } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import InteractionRecord from 'customer-data-objects/interaction/InteractionRecord';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import * as LocalSettings from 'crm_data/settings/LocalSettings';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { generateKey } from 'customer-data-communicator/schema/communicator/interaction/local/LocalInteractionIdentifier';
import { userInfoSync } from 'hub-http/userInfo';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'customer-data-rte/state/Prosem... Remove this comment to see the full error message

import { LINKEDIN_MESSAGE, SMS, WHATS_APP } from 'customer-data-objects/engagement/EngagementTypes';
import PortalIdParser from 'PortalIdParser';
export function isInteractionReset(oldInteractionRecord, newInteractionRecord, metadataAttr = 'body') {
  return oldInteractionRecord !== newInteractionRecord && Boolean(oldInteractionRecord.metadata.get(metadataAttr)) && !newInteractionRecord.metadata.get(metadataAttr);
}
export function createLocalInteractionRecord(richTextEditorResults) {
  return new InteractionRecord({
    richTextEditorResults
  });
}
export function createLocalInteractionRecordWithInternalNotes({
  richTextEditorResults,
  internalRichTextEditorResults
}) {
  return new InteractionRecord({
    richTextEditorResults,
    internalRichTextEditorResults
  });
}
export function createInteractionRecordWithInternalNotes({
  richTextEditorResults,
  internalRichTextEditorResults,
  internalNotesPropertyName,
  sourceApp
}) {
  const attendeeDescriptionAtMentions = richTextEditorResults.atMentions().toSet();
  const internalNotesAtMentions = internalRichTextEditorResults && internalRichTextEditorResults.atMentions().toSet();
  const sourceId = userInfoSync().user.user_id;
  const interaction = fromJS({
    metadata: {
      body: richTextEditorResults.html(),
      plainText: richTextEditorResults.plainText()
    },
    associations: {
      ownerIds: attendeeDescriptionAtMentions.concat(internalNotesAtMentions || ImmutableSet())
    },
    engagement: {
      source: sourceApp,
      sourceId
    }
  });
  if (!internalNotesPropertyName) {
    return new InteractionRecord(interaction);
  }
  return new InteractionRecord(internalRichTextEditorResults ? interaction.setIn(['metadata', internalNotesPropertyName], internalRichTextEditorResults.html()) : interaction);
}
export function createInteractionRecord(richTextEditorResults, sourceApp) {
  const sourceId = userInfoSync().user.user_id;
  return new InteractionRecord(fromJS({
    metadata: {
      body: richTextEditorResults.html(),
      plainText: richTextEditorResults.plainText()
    },
    associations: {
      ownerIds: ImmutableSet(richTextEditorResults.atMentions())
    },
    engagement: {
      source: sourceApp,
      sourceId
    }
  }));
}
export function initInteractionRecord(interactionRecord, engagementType, ownerId, teamId) {
  if (engagementType) {
    interactionRecord = interactionRecord.setEngagementType(engagementType);
    if ([LINKEDIN_MESSAGE, SMS, WHATS_APP].includes(engagementType)) {
      interactionRecord = interactionRecord.setLoggedFrom('CRM');
    }
  }
  const owner = interactionRecord.getIn(['engagement', 'ownerId'], ownerId);
  interactionRecord = interactionRecord.setOwnerId(owner);
  const team = interactionRecord.getIn(['engagement', 'teamId'], teamId);
  if (team) {
    interactionRecord = interactionRecord.setTeamId(team);
  }
  return interactionRecord;
}
export function toEngagementRecord(interactionRecord) {
  return EngagementRecord(interactionRecord.toMap());
}
export function restoreLocalInteraction(localInteraction, localTransforms) {
  if (!localInteraction.isRestored()) {
    return null;
  }
  let interaction = new InteractionRecord();
  localTransforms.forEach(transform => {
    interaction = transform.toInteractionRecord(localInteraction, interaction);
  });
  return {
    interaction,
    attachments: localInteraction.get('attachments'),
    trackerKey: localInteraction.get('trackerKey')
  };
}
export function saveLocalInteraction(localInteraction, interactionRecord, localTransforms) {
  if (interactionRecord != null) {
    localTransforms.forEach(transform => transform.toLocalInteraction(interactionRecord, localInteraction));
  }
  return localInteraction.save();
}
export function isEmptyInteraction(interaction) {
  return interaction && interaction.equals(new InteractionRecord());
}
export function clearInteractionDraft(interactionType, objectType, subjectId) {
  const key = generateKey(interactionType, objectType, subjectId, PortalIdParser.get());
  LocalSettings.deleteFrom(localStorage, key);
}