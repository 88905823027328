import getIn from 'transmute/getIn';
import { isLegacyObjectType } from '../types/LegacyObjectType';
import { translateObjectName, translateObjectNameUnknownNumber } from './translateObjectName';
import { denormalizeTypeId } from '../constants/ObjectTypeIds';
const getLegacyHubSpotObjectTypeName = (objectType, {
  isCapitalized,
  isPlural
}) => isPlural ? translateObjectNameUnknownNumber(objectType, {
  isCapitalized
}) : translateObjectName(objectType, {
  isCapitalized
});
const translateObjectTypeName = ({
  isCapitalized = false,
  isPlural = false,
  objectType,
  objectTypeDefinition = {}
}) => {
  if (isLegacyObjectType(denormalizeTypeId(objectType))) {
    return getLegacyHubSpotObjectTypeName(objectType, {
      isCapitalized,
      isPlural
    });
  }
  let singularForm = getIn(['singularForm'], objectTypeDefinition);
  if (!singularForm) {
    singularForm = getIn(['name'], objectTypeDefinition);
  }
  let pluralForm = getIn(['pluralForm'], objectTypeDefinition);
  if (!pluralForm) {
    pluralForm = getIn(['name'], objectTypeDefinition);
  }
  return isPlural ? pluralForm : singularForm;
};
export default translateObjectTypeName;