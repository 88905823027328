'use es6';

import filter from 'transmute/filter';
import dispatcher from 'dispatcher/dispatcher';
import ContactRecord from 'customer-data-objects/contact/ContactRecord';
import { CONTACTS } from 'crm_data/actions/ActionNamespaces';
import { definePooledObjectStore } from 'crm_data/flux/definePooledObjectStore';
import { getProperty, setProperty } from 'customer-data-objects/model/ImmutableModel';
import { List } from 'immutable';
import registerPooledObjectService from 'crm_data/flux/registerPooledObjectService';
import { ASSOCIATE_CONTACT_AND_COMPANY, DISASSOCIATE_CONTACT_AND_COMPANY } from 'crm_schema/association/AssociationActionTypes';
import { CONTACTS_REMOVED_FROM_LIST, CONTACTS_UPDATE_STARTED, CONTACTS_UPDATE_FAILED, CONTACT_SET, CONTACT_CREATED, CONTACT_SECONDARY_EMAIL_REMOVED, CONTACT_SECONDARY_EMAIL_PROMOTED, CONTACT_SECONDARY_EMAIL_UPDATED, CONTACT_SECONDARY_EMAIL_ADDED, CONTACT_EMAIL_REMOVED } from 'crm_data/actions/ActionTypes';
import { fetchWithQuickFetch } from 'crm_data/contacts/api/ContactsAPI';
const splitValidEmails = emails => {
  if (!emails) {
    return [];
  }
  return emails.split(';').filter(val => val);
};
registerPooledObjectService({
  actionTypePrefix: CONTACTS,
  fetcher: fetchWithQuickFetch
});
export default definePooledObjectStore({
  actionTypePrefix: CONTACTS
}).defineName('ContactsStore').defineResponseTo(ASSOCIATE_CONTACT_AND_COMPANY, (state, {
  objectId,
  subjectId
}) => {
  const contactId = `${objectId}`;
  if (!state.get(contactId)) {
    return state;
  }
  return state.setIn([contactId, 'properties', 'associatedcompanyid', 'value'], subjectId);
}).defineResponseTo(DISASSOCIATE_CONTACT_AND_COMPANY, (state, {
  objectId
}) => {
  const contactId = `${objectId}`;
  if (!state.get(contactId)) {
    return state;
  }
  return state.setIn([contactId, 'properties', 'associatedcompanyid', 'value'], '');
}).defineResponseTo([CONTACT_SET, CONTACT_CREATED], (state, contact) => {
  const vid = contact.get('vid');
  return state.set(`${vid}`, contact);
}).defineResponseTo(CONTACTS_UPDATE_STARTED, (state, {
  id,
  nextProperties
}) => state.updateIn([id], contact => nextProperties.reduce((acc, value, name) => setProperty(acc, name, value), contact || ContactRecord({
  vid: id
})))).defineResponseTo(CONTACTS_UPDATE_FAILED, (state, {
  id,
  properties,
  nextProperties
}) => {
  if (state.has(id)) {
    state = state.updateIn([id], contact => properties.reduce((acc, value, name) => {
      // don't overwrite changes made since the update began
      if (nextProperties.get(name) !== getProperty(acc, name)) {
        return acc;
      }
      return setProperty(acc, name, value);
    }, contact));
  }
  return state;
}).defineResponseTo(CONTACT_SECONDARY_EMAIL_REMOVED, (state, {
  vid,
  email
}) => state.update(vid, contact => {
  const additionalEmails = splitValidEmails(getProperty(contact, 'hs_additional_emails'));
  const updatedAdditionalEmails = filter(additionalEmail => email !== additionalEmail, additionalEmails).join(';');
  return setProperty(state.get(vid), 'hs_additional_emails', updatedAdditionalEmails);
})).defineResponseTo(CONTACT_SECONDARY_EMAIL_PROMOTED, (state, {
  vid,
  promotedEmail,
  additionalEmails
}) => state.update(vid, () => {
  let updatedContact = setProperty(state.get(vid), 'hs_additional_emails', additionalEmails);
  updatedContact = setProperty(updatedContact, 'email', promotedEmail);
  return updatedContact;
})).defineResponseTo(CONTACT_SECONDARY_EMAIL_UPDATED, (state, {
  vid,
  prevEmail,
  nextEmail
}) => {
  const additionalEmails = splitValidEmails(getProperty(state.get(vid), 'hs_additional_emails'));
  const prevEmailIndex = additionalEmails.indexOf(prevEmail);
  if (prevEmailIndex > -1) {
    additionalEmails.splice(prevEmailIndex, 1);
  }
  if (!Array.from(additionalEmails).includes(nextEmail)) {
    additionalEmails.push(nextEmail);
  }
  return state.update(vid, () => setProperty(state.get(vid), 'hs_additional_emails', additionalEmails.join(';')));
}).defineResponseTo(CONTACT_SECONDARY_EMAIL_ADDED, (state, {
  vid,
  email
}) => state.update(vid, contact => {
  const secondaryEmailPropertyValue = getProperty(contact, 'hs_additional_emails');
  const additionalEmails = splitValidEmails(secondaryEmailPropertyValue);
  additionalEmails.push(email);
  return setProperty(state.get(vid), 'hs_additional_emails', additionalEmails.join(';'));
})).defineResponseTo(CONTACT_EMAIL_REMOVED, (state, {
  vid,
  email
}) => state.update(vid, contact => {
  const additionalEmails = splitValidEmails(getProperty(contact, 'hs_additional_emails'));
  const updatedAdditionalEmails = filter(additionalEmail => email !== additionalEmail, additionalEmails).join(';');
  return setProperty(state.get(vid), 'hs_additional_emails', updatedAdditionalEmails);
})).defineResponseTo(CONTACTS_REMOVED_FROM_LIST, (state, {
  vids,
  listId
}) => {
  let result = state;
  if (vids) {
    vids.forEach(vid => {
      result = result.updateIn([`${vid}`, 'list-memberships'], (lists = List()) => {
        return lists.filterNot(list => list.get('static-list-id') === listId);
      });
    });
  }
  return result;
}).register(dispatcher);