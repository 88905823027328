import { Map as ImmutableMap } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'gene... Remove this comment to see the full error message
import { define } from 'general-store';
import { ENGAGEMENTS_UPDATED, TASKS_AND_ENGAGEMENTS_UPDATED
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
// @ts-expect-error not typed
import { TASK_DEPENDENCY_FETCH_SUCCESS } from 'sequences-crm-lib/flux/SequenceActionTypes';
import { TASK_STATUS_PATH, TASK_UID_PATH } from '../../constants/TimelineTaskConstants';
import { COMPLETED } from 'customer-data-objects/engagement/EngagementStatusTypes';
const initialState = ImmutableMap();
let _data = initialState;

// this listens for task completion changes and updates the resolvedAt date
// which is used to determine if a manual email task dependency is blocking a sequence.
// We show special messaging in the timeline and task queue bar when tasks are blocking sequences.
const handleEngagementUpdate = engagementsMap => {
  engagementsMap.forEach(engagement => {
    if (!engagement || !engagement.hasIn(TASK_UID_PATH)) {
      return;
    }
    const taskUid = engagement.getIn(TASK_UID_PATH);
    if (engagement.getIn(TASK_STATUS_PATH) === COMPLETED && _data.getIn([taskUid, 'taskDependencyMetadata', 'resolvedAt']) === null) {
      _data = _data.setIn([taskUid, 'taskDependencyMetadata', 'resolvedAt'], Date.now());
    }
  });
  return _data;
};
export default define().defineGet(taskUid => _data.get(taskUid)).defineResponseTo(TASK_DEPENDENCY_FETCH_SUCCESS, results => {
  const taskDependency = results.get('results').first();
  return _data = _data.set(taskDependency.get('taskUid'), taskDependency);
}).defineResponseTo(ENGAGEMENTS_UPDATED, engagementsMap => {
  return handleEngagementUpdate(engagementsMap);
}).defineResponseTo(TASKS_AND_ENGAGEMENTS_UPDATED, ({
  engagementsMap
}) => {
  return handleEngagementUpdate(engagementsMap);
}).register();