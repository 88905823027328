'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["objectId"];
import { fromJS, Map as ImmutableMap } from 'immutable';
import userInfo from 'hub-http/userInfo';
import { CONTACTS, CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
import { PROSPECTS } from 'customer-data-objects/property/PropertySourceIds';
import CompanyRecord from 'customer-data-objects/company/CompanyRecord';
import { POST, PUT } from 'crm_data/constants/HTTPVerbs';
import { del, get, post, put, send } from 'crm_data/api/ImmutableAPI';
import * as sharedApi from 'crm_data/inboundDB/sharedAPI';
import { byIds } from './CompaniesAPIQuery';
const BASE_URI = 'companies/v2/companies';
const BASE_URI_V3 = 'companies/v3/companies';
const BASE_DOMAIN_URI = 'companies/v2/domains';
const BATCH_API = `${BASE_URI}/batch`;
const BATCH_DOMAIN_API = `${BASE_DOMAIN_URI}/batch`;
const BACKFILL_API = `${BASE_URI}/backfill`;
const contactsCrmUiSourceHeaders = {
  'X-Properties-Source': CONTACTS,
  'X-Properties-SourceId': CRM_UI
};
const contactsProspectsSourceHeaders = {
  'X-Properties-Source': CONTACTS,
  'X-Properties-SourceId': PROSPECTS
};
export function createCompany(payload) {
  return send({
    headers: contactsCrmUiSourceHeaders,
    type: POST
  }, BASE_URI, {
    properties: payload
  }, result => ImmutableMap().set(result.companyId, CompanyRecord.fromJS(result)));
}
const createCompanyFromProspectWithUser = (user, name, domain, ownerId) => {
  const sourceId = user.email;
  const properties = [{
    name: 'name',
    source: CRM_UI,
    sourceId,
    value: name
  }, {
    name: 'domain',
    source: CRM_UI,
    sourceId,
    value: domain
  }];
  if (ownerId !== -1) {
    properties.push({
      name: 'hubspot_owner_id',
      source: CRM_UI,
      sourceId,
      value: ownerId
    });
  }
  return send({
    headers: contactsProspectsSourceHeaders,
    type: POST
  }, BASE_URI, {
    properties
  }, result => CompanyRecord.fromJS(result));
};
export function createCompanyFromProspect(name, domain, ownerId) {
  return userInfo().then(({
    user
  }) => {
    return createCompanyFromProspectWithUser(user, name, domain, ownerId);
  });
}
export function deleteCompany(companyId) {
  return sharedApi.del(`${BASE_URI}/${companyId}`);
}
const parseCompanies = result => result.reduce((coll, val, key) => coll.set(key, CompanyRecord.fromJS(val)), ImmutableMap());
export function fetch(ids) {
  return get(`${BATCH_API}?flpViewValidation=true`, byIds(ids)).then(parseCompanies);
}
export function fetchById(id) {
  return get(`${BASE_URI_V3}/${id}`).then(result => {
    // companies v3 returns ID as objectId rather than companyId
    // this converts the id to match the standard in CompanyRecord
    const _result$toJS = result.toJS(),
      {
        objectId
      } = _result$toJS,
      company = _objectWithoutPropertiesLoose(_result$toJS, _excluded);
    return CompanyRecord.fromJS(Object.assign({
      companyId: objectId
    }, company));
  });
}
export function update(companyUpdate) {
  return send({
    headers: contactsCrmUiSourceHeaders,
    type: PUT
  }, `${BASE_URI}/${companyUpdate.get('companyId')}`, companyUpdate);
}
const updateCompanyPropertiesWithUser = (user, company, propertyUpdates) => {
  const companyId = company.get('companyId');
  return send({
    headers: contactsCrmUiSourceHeaders,
    type: PUT
  }, `${BASE_URI}/${companyId}`, {
    companyId,
    properties: propertyUpdates.reduce((acc, value, name) => {
      acc.push({
        name,
        value,
        source: CRM_UI,
        sourceId: user.email
      });
      return acc;
    }, [])
  });
};
export function updateCompanyProperties(company, propertyUpdates) {
  return userInfo().then(({
    user
  }) => updateCompanyPropertiesWithUser(user, company, propertyUpdates));
}
export function fetchByDomain(domain, limit, offset) {
  if (limit == null) {
    limit = 5;
  }
  if (offset == null) {
    offset = {
      companyId: null,
      isPrimary: true
    };
  }
  const options = {
    requestOptions: {
      properties: ['name', 'domain', 'hubspot_owner_id', 'hubspot_team_id']
    },
    limit,
    offset
  };
  return post(`${BASE_DOMAIN_URI}/${encodeURIComponent(domain)}/companies`, options).then(res => {
    const companies = res.get('results');
    const results = companies.reduce((map, company) => map = map.set(company.get('companyId'), CompanyRecord.fromJS(company)), ImmutableMap());
    return res.set('results', results);
  });
}
export function batchFetchByDomain(query) {
  return get(BATCH_DOMAIN_API, query, fromJS);
}
export function backfillCompanies() {
  return put(BACKFILL_API);
}
export function removeDomain(subject, domain) {
  const companyId = subject.get('companyId');
  return del(`${BASE_URI}/${companyId}/domains/${encodeURIComponent(domain)}`);
}