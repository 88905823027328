import { useAddNoteAction } from '../actions/activityCreator/AddNoteAction';
import { useSendEmailAction } from '../actions/activityCreator/SendEmailAction';
import { usePreviewObjectAction } from '../actions/PreviewObjectAction';
import { useScheduleMeetingAction } from '../actions/activityCreator/ScheduleMeetingAction';
import { useOpenRecordAssociationFormAction } from '../actions/openObjectBuilder/OpenRecordAssociationFormAction';
import { useOpenRecordCreatorFormAction } from '../actions/openObjectBuilder/OpenRecordCreatorFormAction';
import { useEngagementAppLinkAction } from '../actions/appLinks/EngagementAppLink';
import { useRecordAppLinkAction } from '../actions/appLinks/RecordAppLink';
import { usePageAppLinkAction } from '../actions/appLinks/PageAppLink';
import { useExternalUrlAction } from '../actions/ExternalUrlAction';
import { useOpenWorkflowEnrollmentModalAction } from '../actions/OpenWorkflowEnrollmentModalAction';
import { useAddTaskAction } from '../actions/activityCreator/AddTaskAction';
const DEFAULT_REGISTRY = {
  PREVIEW_OBJECT: usePreviewObjectAction,
  ADD_NOTE: useAddNoteAction,
  SEND_EMAIL: useSendEmailAction,
  SCHEDULE_MEETING: useScheduleMeetingAction,
  OPEN_RECORD_CREATOR_FORM: useOpenRecordCreatorFormAction,
  OPEN_RECORD_ASSOCIATION_FORM: useOpenRecordAssociationFormAction,
  ENGAGEMENT_APP_LINK: useEngagementAppLinkAction,
  RECORD_APP_LINK: useRecordAppLinkAction,
  PAGE_APP_LINK: usePageAppLinkAction,
  EXTERNAL_URL: useExternalUrlAction,
  ADD_TASK: useAddTaskAction,
  OPEN_WORKFLOW_ENROLLMENT_MODAL: useOpenWorkflowEnrollmentModalAction
};
export function getDefaultActionRegistry() {
  return DEFAULT_REGISTRY;
}