import { gql, useQuery } from '@apollo/client';
import { PERMISSIONS_ERROR } from '../types/ValidationError';
const FETCH_USER_PERMISSIONS_FOR_OBJECT_TYPE = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"fetchUserPermissionsForObjectType\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypePermissions\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"currentUserCanCreate\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "fetchUserPermissionsForObjectType"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectTypeId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "objectTypePermissions"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "objectTypeId"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectTypeId"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "currentUserCanCreate"
            }
          }]
        }
      }]
    }
  }]
});
export function useFetchUserPermissionsForObjectType(objectTypeId) {
  const {
    error,
    data
  } = useQuery(FETCH_USER_PERMISSIONS_FOR_OBJECT_TYPE, {
    variables: {
      objectTypeId
    }
  });
  if (error) {
    return {
      currentUserCanCreate: false,
      error: PERMISSIONS_ERROR
    };
  }
  return data && data.objectTypePermissions || {
    currentUserCanCreate: false
  };
}