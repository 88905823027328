'use es6';

import { isNodeWithinClass } from '../lib/isNodeWithinClass';
import BaseAtomicFocus from './BaseAtomicFocus';
const AtomicFocus = (parentClasses, removable = true) => {
  const isNodeWithinBlock = ({
    target
  }) => isNodeWithinClass({
    target,
    parentClasses
  });
  return BaseAtomicFocus(isNodeWithinBlock, removable);
};
export default AtomicFocus;