import { useEffect } from 'react';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_ui/dependencies/SubjectNam... Remove this comment to see the full error message
import SubjectNameDependency from 'crm_ui/dependencies/SubjectNameDependency';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'general-store'. Did you mean t... Remove this comment to see the full error message
import { useStoreDependency } from 'general-store';
export const useSetRecordPageTitle = ({
  objectType,
  subjectId
}) => {
  const subjectName = useStoreDependency(SubjectNameDependency, {
    objectType,
    subjectId
  });
  useEffect(() => {
    if (subjectName) {
      document.title = subjectName;
    }
  }, [subjectName]);
};