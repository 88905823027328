// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import EngagementsStore from 'crm_data/engagements/EngagementsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { LOADING } from 'crm_data/constants/LoadingStatus';
import PropTypes from 'prop-types';
const EngagementDependency = {
  propTypes: {
    engagementId: PropTypes.number
  },
  stores: [EngagementsStore],
  deref({
    engagementId
  }) {
    if (!engagementId) {
      return LOADING;
    }
    return EngagementsStore.get(engagementId);
  }
};
export default EngagementDependency;