'use es6';

import getIn from 'transmute/getIn';
import { List } from 'immutable';
import { toSubject } from 'customer-data-sidebar/graphQL/parse';
import { COMPANY } from 'customer-data-objects/constants/ObjectTypes';
import { FETCH_HIERARCHY_QUERY } from './profileCompanyHierarchyQueries';
const parse = (_, data) => {
  const subjectData = data.crmObject;
  if (!subjectData) {
    return {
      subject: null,
      childCompanies: List(),
      parentCompany: null,
      countObject: {
        count: 0,
        hasNextPage: false
      }
    };
  }
  const subject = toSubject(COMPANY, subjectData);
  const childToParentCompany = getIn(['associations', 'childToParentCompany'], subjectData);
  const parentCompany = childToParentCompany && toSubject(COMPANY, childToParentCompany);
  const parentToChildCompanyEdges = getIn(['associations', 'parentToChildCompany', 'edges'], subjectData) || [];
  const childCompanies = List(parentToChildCompanyEdges.map(edge => toSubject(COMPANY, edge.node)));
  const countEdges = getIn(['associations', 'count', 'edges'], subjectData) || [];
  const hasNextPage = getIn(['associations', 'count', 'pageInfo', 'hasNextPage'], subjectData);
  const countObject = {
    count: countEdges.length || 0,
    hasNextPage: !!hasNextPage
  };
  return {
    childCompanies,
    parentCompany,
    subject,
    countObject
  };
};
export const profileCompanyHierarchyGqlConfig = {
  parse,
  query: FETCH_HIERARCHY_QUERY
};