import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
const QUERY_ASSOCIATION_DEFINITION = registerQuery({
  fieldName: 'crmactions_associationDefinitionByAssociationSpec',
  args: ['associationSpec'],
  fetcher({
    associationSpec
  }) {
    return http.post('associations/v1/definitions/batch', {
      data: [associationSpec]
    }).then(res => {
      if (res.length) {
        return res[0];
      }
      throw new Error('No association definition found');
    });
  }
});
const useQueryAssociationDefinition = (associationSpec, {
  skip
} = {}) => {
  const {
    data,
    loading,
    error
  } = useQuery(QUERY_ASSOCIATION_DEFINITION, {
    variables: {
      associationSpec
    },
    skip
  });
  return {
    associationDefinition: data === null || data === void 0 ? void 0 : data.crmactions_associationDefinitionByAssociationSpec,
    loading,
    error
  };
};
export default useQueryAssociationDefinition;