import uniqueId from 'transmute/uniqueId';
import SampleEventFactoryInterface from './SampleEventFactoryInterface';
import { TASK } from 'customer-data-objects/engagement/EngagementTypes';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { IN_PROGRESS } from 'customer-data-objects/engagement/EngagementStatusTypes';
import PortalIdParser from 'PortalIdParser';
import { EVENT_ENGAGEMENT } from '../../timeline/constants/TimelineConstants';
const PORTAL_ID = PortalIdParser.get();
const makeTaskSampleTimelineEvent = SampleEventFactoryInterface({
  requiredData: {
    eventType: EVENT_ENGAGEMENT,
    itemType: TASK
  },
  eventDefaults: {
    title: '',
    body: '',
    forObjectType: CONTACT,
    status: IN_PROGRESS
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(taskData: any) => { associateCurrentContact... Remove this comment to see the full error message
  build(taskData) {
    const {
      title,
      body,
      forObjectType,
      status,
      timestamp
    } = taskData;
    const eventData = {
      associateCurrentContact: true,
      scheduledTasks: [{
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        engagementId: uniqueId(),
        engagementType: TASK,
        portalId: PORTAL_ID,
        taskType: 'REMINDER',
        timestamp,
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        uuid: `${TASK}:${uniqueId()}`
      }],
      metadata: {
        subject: title,
        body,
        forObjectType,
        status
      }
    };
    return eventData;
  }
});
export default makeTaskSampleTimelineEvent;