import Loadable from 'UIComponents/decorators/Loadable';
import { AsyncComponentErrorHandler } from '../helpers/AsyncComponentErrorHandler';
import { AsyncComponentLoadingHandler } from '../helpers/AsyncComponentLoadingHandler';
const TimelineCommentsList = Loadable({
  loader: () => import( /* webpackChunkName: "timeline-comments-list" */'./TimelineCommentsList').then(mod => mod.default),
  LoadingComponent: AsyncComponentLoadingHandler({
    isContextual: true
  }),
  ErrorComponent: AsyncComponentErrorHandler('timeline-comments-list')
});
export default TimelineCommentsList;