import I18n from 'I18n';
import { COMPANY_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { getPreviewDefaultActionsData, getRemoveDefaultActionsData } from '../../default/getDefaultChickletHoverActions';
export const getFlywheelContactChickletHoverActions = behaviorArgs => args => {
  const {
    hasScope
  } = behaviorArgs;
  const {
    canEdit,
    fromObjectTypeId
  } = args;
  const disabled = fromObjectTypeId === COMPANY_TYPE_ID && !hasScope('bet-change-associated-company');
  const removeActionButtonOverrides = {
    disabled,
    disabledMessage: I18n.text('flywheel.FlywheelContactCardAction.removeAssociationDisabledMessage')
  };
  const SecondaryActions = canEdit ? [getRemoveDefaultActionsData(args, removeActionButtonOverrides)] : [];
  return {
    PrimaryAction: getPreviewDefaultActionsData(args),
    SecondaryActions
  };
};