import { useFetchTimelineFilters, getSelectedFilters } from './useFetchTimelineFilters';
import TimelineFavoriteEventsOverrideStore from '../stores/TimelineFavoriteEventsOverrideStore';
import get from 'transmute/get';
import { isResolved, LOADING } from '../../helpers/LoadingStatus';
export function useSelectedTabOrEventFilters({
  objectType,
  subjectId
}) {
  const {
    data: gqlFilterData
  } = useFetchTimelineFilters({
    subjectId,
    objectTypeOrId: objectType
  });
  const selectedTab = get('eventTypes', TimelineFavoriteEventsOverrideStore.get(objectType));
  if (isResolved(selectedTab)) {
    return {
      selectedEventFilters: selectedTab
    };
  }
  if (!gqlFilterData) {
    return {
      selectedEventFilters: LOADING
    };
  }
  return {
    selectedEventFilters: getSelectedFilters(gqlFilterData.crmObject.timeline.filterGroups)
  };
}