import { useCallback } from 'react';
import { OPEN_PREVIEW_SIDEBAR } from 'crm-events-data';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
export function usePreviewSidebarActionHandler() {
  const sendPreviewObjectAction = useSendCrmMessageTopic(TOPIC_NAMES.PREVIEW_OBJECT);
  const handlePreviewSidebarAction = useCallback((actionType, data) => {
    if (actionType === OPEN_PREVIEW_SIDEBAR) {
      sendPreviewObjectAction({
        objectId: data.subjectId,
        objectTypeId: data.objectType
      });
      return;
    }
  }, [sendPreviewObjectAction]);
  return {
    handlePreviewSidebarAction
  };
}