import Raven from 'raven-js';
import { useQuery } from '@apollo/client';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
import { FETCH_UNIVERSAL_SIDEBAR_CARD } from 'customer-data-sidebar/universal/queries';
export function useLegacyCardDataRequest({
  objectTypeId,
  objectId,
  cardId,
  skip = false
}) {
  return useQuery(FETCH_UNIVERSAL_SIDEBAR_CARD, {
    skip: skip || !objectTypeId || !objectId,
    variables: {
      objectType: objectTypeId,
      subjectId: objectId,
      cardId
    },
    onError: e => {
      console.error(e);
      Raven.captureException(e, {
        extra: {
          objectTypeId,
          cardId
        }
      });
    }
  });
}