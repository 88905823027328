import { gql } from '@apollo/client';
export const CreateCrmObjectAssociationBySpec = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"mutation\",\"name\":{\"kind\":\"Name\",\"value\":\"CreateCrmObjectAssociationBySpec\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"createAssociationInput\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"CreateCrmObjectAssociationBySpecInput\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"createCrmObjectAssociationBySpec\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"createAssociationInput\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"createFailures\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"failReason\"}}]}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "CreateCrmObjectAssociationBySpec"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "createAssociationInput"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "CreateCrmObjectAssociationBySpecInput"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "createCrmObjectAssociationBySpec"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "input"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "createAssociationInput"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "createFailures"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "failReason"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
});