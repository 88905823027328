import { useMemo } from 'react';
import { getExtension } from '../helpers/getExtension';
/**
 *
 * useBehavior will return the appropriate value for a given BehaviorType.
 *
 * If a behavior is not defined for the object type, it will run the default behavior.
 *
 * If the portal is determined to be a Flywheel one via scopes, it will attempt to run the flywheel-specific defined behavior. If it
 * isn't defined, it will fall back to a customer portal defined behavior and then finally the default behavior.
 *
 * @param {BehaviorType} behaviorType One of the pre-defined behaviors
 * @param {ObjectTypeId} objectTypeId The object type to pull behavior for
 * @returns {BehaviorReturnTypes<TypeName>[BehaviorType]} Return value of behavior
 */
export const useBehavior = (behaviorType, objectTypeId, hasScope, isUngated) => {
  const extension = useMemo(() => getExtension(objectTypeId, hasScope), [objectTypeId, hasScope]);
  const behavior = extension[behaviorType];
  return behavior({
    objectTypeId,
    hasScope,
    isUngated
  });
};