'use es6';

import './PropertiesService';
import { PROPERTIES } from '../actions/ActionNamespaces';
import { PROPERTIES_CREATE_STARTED, PROPERTIES_CREATE_FAILED, PROPERTIES_CREATE_SUCCEEDED } from '../actions/ActionTypes';
import dispatcher from 'dispatcher/dispatcher';
import getIn from 'transmute/getIn';
import { defineLazyKeyStore } from '../store/LazyKeyStore';
import { isLegacyObjectType } from 'customer-data-objects/types/LegacyObjectType';
import { isObjectTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { denormalizePropertyGroups } from 'crm_data/properties/PropertiesAPI';
const PropertiesStore = defineLazyKeyStore({
  namespace: PROPERTIES,
  idIsValid: objectTypeOrId => isLegacyObjectType(objectTypeOrId) || isObjectTypeId(objectTypeOrId),
  serializeData: denormalizePropertyGroups,
  responseTransform: getIn(['properties']),
  unstable_enableCache: true
}).defineName('PropertiesStore').defineResponseTo([PROPERTIES_CREATE_STARTED, PROPERTIES_CREATE_SUCCEEDED], (state, {
  objectType,
  property
}) => state.setIn([objectType, property.name], property)).defineResponseTo(PROPERTIES_CREATE_FAILED, (state, {
  objectType,
  property
}) => state.deleteIn([objectType, property.name])).register(dispatcher);
export default PropertiesStore;