// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
const API = 'associations-writes/v1/associations';
const engagementToPortalAssociationType = 'ENGAGEMENT_TO_PORTAL';
const engagementToServiceAssociationType = 'ENGAGEMENT_TO_BET_DELIVERABLE_SERVICE';
const serviceAssociationTypeId = 24;
const portalAssociationTypeId = 56;
const associationCategory = 'HUBSPOT_DEFINED';
const makeURI = (...args) => {
  return `${API}/${args.join('/')}`;
};
const createEngagementAssociationRemover = associationType => {
  return (engagementId, objectId) => {
    return ImmutableAPI.delete(makeURI(engagementId, associationType, objectId));
  };
};
const createEngagementAssociationCreator = associationTypeId => {
  return (engagementId, objectIds) => {
    const associations = objectIds.map(id => {
      return {
        fromObjectId: engagementId,
        toObjectId: id,
        associationCategory,
        associationTypeId
      };
    });
    return ImmutableAPI.put(API, associations);
  };
};
export const createPortalEngagementAssociation = createEngagementAssociationCreator(portalAssociationTypeId);
export const createServiceEngagementAssociation = createEngagementAssociationCreator(serviceAssociationTypeId);
export const removePortalEngagementAssociation = createEngagementAssociationRemover(engagementToPortalAssociationType);
export const removeServiceEngagementAssociation = createEngagementAssociationRemover(engagementToServiceAssociationType);