import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["xproperty", "yproperty"];
import { getLegacyCrmCardType } from 'crm-cards-sdk/constants/LegacySidebarCardTypeMap';
import { isLegacySidebarCard } from './isLegacySidebarCard';
import { buildRecursiveCrmLocationAdapter } from '../../utils/buildRecursiveCrmLocationAdapter';
import Raven from 'raven-js';
const deserializeFieldValue = rawFieldValue => {
  try {
    return JSON.parse(rawFieldValue.replace(/\\n/g, ''));
  } catch (_unused) {
    // if parsing fails, we just set the value as a string
    return rawFieldValue;
  }
};
function isConfigurationWithProperties(configuration) {
  return 'properties' in configuration && Array.isArray(configuration.properties) && !configuration.properties.some(property => typeof property !== 'string');
}
export const deserializeConfigurationField = ({
  fieldType,
  fieldName,
  fieldValue: rawFieldValue
}) => {
  const fieldValue = deserializeFieldValue(rawFieldValue);
  if (fieldType === 'STRING' || fieldType === 'ENUM' || fieldType === 'OBJECT_TYPE_ID' || fieldType === 'OBJECT' || (fieldType === 'ARRAY_STRING' || fieldType === 'ARRAY_ENUM' || fieldType === 'ARRAY_OBJECT') && Array.isArray(fieldValue)) {
    return {
      fieldType,
      fieldName,
      fieldValue
    };
  }
  if (fieldType === 'BOOLEAN') {
    return {
      fieldType,
      fieldName,
      fieldValue: Boolean(fieldValue)
    };
  }
  if (fieldType === 'ARRAY_BOOLEAN' && Array.isArray(fieldValue)) {
    return {
      fieldType,
      fieldName,
      fieldValue: fieldValue.map(Boolean)
    };
  }
  if (fieldType === 'NUMBER' && !(isNaN(fieldValue) || fieldValue === null)) {
    return {
      fieldType,
      fieldName,
      fieldValue: Number(fieldValue)
    };
  }
  if (fieldType === 'ARRAY_NUMBER' && Array.isArray(fieldValue) && !fieldValue.some(value => isNaN(value) || value === null)) {
    return {
      fieldType,
      fieldName,
      fieldValue: fieldValue.map(Number)
    };
  }
  try {
    Raven.capturePageEvent('unknown or invalid configuration field encountered', {
      extra: {
        fieldType,
        rawFieldValue,
        parsedFieldValue: fieldValue
      }
    });
  } catch (e) {
    // no-op
  }
  return {
    fieldType,
    fieldName,
    fieldValue
  };
};
const adaptNonStandardCardConfiguration = configuration => {
  if (configuration) {
    if (isConfigurationWithProperties(configuration)) {
      if (configuration.type === '.PropertiesListCardConfiguration' || configuration.type === '.AssociationPropertiesListCardConfiguration' || configuration.type === '.AssociationStageTrackerCardConfiguration') {
        return Object.assign({}, configuration, {
          propertyNames: configuration.properties
        });
      }
      return Object.assign({}, configuration, {
        properties: configuration.properties.map(name => ({
          name: `${name}`
        }))
      });
    }
    if (configuration.type === '.ExtensionCardConfiguration') {
      const {
        appId,
        appName,
        title,
        targetFunction,
        extensionArtifactUrl,
        projectName,
        projectId,
        sourceId,
        appAccessLevel,
        additionalProperties,
        iconUri,
        singleColorIconUri
      } = configuration;
      return {
        crmExtensionItem: {
          appId,
          appName,
          title,
          targetFunction,
          extensionArtifactUrl,
          projectName,
          projectId,
          sourceId,
          appAccessLevel,
          additionalProperties,
          iconUri: iconUri || null,
          singleColorIconUri: singleColorIconUri || null
        }
      };
    }
    if (configuration.type === '.CustomerSpendTrendsCardConfiguration') {
      const _ref = configuration,
        {
          xproperty,
          yproperty
        } = _ref,
        rest = _objectWithoutPropertiesLoose(_ref, _excluded);
      return Object.assign({
        xProperty: xproperty,
        yProperty: yproperty
      }, rest);
    }
  }
  return configuration;
};
const adaptFasConfiguration = configuration => {
  if (configuration.type !== '.StandardCardConfiguration') {
    return adaptNonStandardCardConfiguration(configuration);
  }
  return Object.assign({}, configuration, {
    configurationFields: configuration.configurationFields.map(deserializeConfigurationField)
  });
};
export const getDefaultFasAdapter = () => buildRecursiveCrmLocationAdapter({
  partiallyTransformLocation: crmLocationQueryResult => {
    return {
      id: crmLocationQueryResult.containerViewId.toString(),
      viewId: crmLocationQueryResult.containerViewId.toString(),
      crmLocationName: crmLocationQueryResult.location,
      container: crmLocationQueryResult.container
    };
  },
  partiallyTransformContainer: container => {
    return {
      type: 'CONTAINER',
      // HACK @btarr: sometimes nodeId is null, we can fall back to location safely for now but when we start having
      // sub containers this will be an issue — by then the BE should have specific IDs for each container so we can
      // properly save dnd results, which is what this is mostly used for
      id: container.nodeId || container.location,
      label: container.label,
      layout: {
        type: container.layout.type,
        reorderable: container.layout.type === 'STACK' && container.layout.reorderable
      },
      contents: container.contents
    };
  },
  transformCard: card => {
    if (isLegacySidebarCard(card)) {
      return {
        type: 'CARD',
        isCollapsed: card.collapsed,
        definition: {
          cardId: card.cardId,
          cardType: 'LEGACY_SIDEBAR_CARD',
          cardTitle: card.content.cardTitle
        },
        configuration: {
          cardType: getLegacyCrmCardType(card.content.cardType),
          /**
           * cardData is the prop that is passed to the card component.
           * Historically, it is what would have been passed from the
           * sidebar to the card.
           * __typename is a necessary piece because cards may be expecting that
           * because they have always received it.
           **/
          cardData: {
            id: card.cardId,
            __typename: getLegacyCrmCardType(card.content.cardType),
            cardId: card.cardId,
            isCollapsed: card.collapsed
          }
        }
      };
    }
    return {
      type: 'CARD',
      definition: {
        cardId: card.cardId,
        cardType: card.content.cardType,
        cardTitle: card.content.cardTitle
      },
      configuration: adaptFasConfiguration(card.content.configuration),
      isCollapsed: card.collapsed
    };
  },
  isCard: cardOrContainer => {
    return cardOrContainer.type === 'CRM_CARD_DISPLAY';
  }
});