import { dispatchQueue, dispatchImmediate, dispatchSafe
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/dispatch/Dispatch';
import { FETCHING_INITIAL_TIMELINE_EVENTS_STARTED, FETCH_TIMELINE_ITEMS_FOR_VFP_STARTED, FETCH_NEXT_TIMELINE_EVENTS_STARTED, POLLING_FETCH_TIMELINE_ITEMS_STARTED
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
export const fetchInitialActivities = apiState => {
  dispatchSafe(FETCHING_INITIAL_TIMELINE_EVENTS_STARTED, apiState);
};
export const fetchTimelineEventsForVisualForcePage = apiState => {
  apiState = apiState.set('TIMELINE_ITEM_LIMIT', 5);
  dispatchImmediate(FETCH_TIMELINE_ITEMS_FOR_VFP_STARTED, apiState);
};
export const fetchNextTimelineEvents = apiState => {
  dispatchQueue(FETCH_NEXT_TIMELINE_EVENTS_STARTED, apiState);
};
export const fetchMostRecentActivities = apiState => {
  dispatchImmediate(POLLING_FETCH_TIMELINE_ITEMS_STARTED, apiState);
};