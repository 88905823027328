'use es6';

import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import * as EmailAPIErrorTypes from '../constants/EmailAPIErrorTypes';
import { logError } from 'customer-data-ui-utilities/eventLogging/eventLogger';
import PortalIdParser from 'PortalIdParser';
function getEmail(email) {
  return email && (email.substring(email.indexOf('<') + 1, email.indexOf('>')) || email);
}
export const getEmailApiErrorLangKey = errorType => {
  if (!isValidI18nKey(`customerDataEmail.Email.api.errors.${errorType}`)) {
    logError({
      error: new Error(`[EmailApi] Error Message Key not found for errorType: ${errorType}`)
    });
    return `customerDataEmail.Email.api.errors.${EmailAPIErrorTypes.GENERIC}`;
  }
  return `customerDataEmail.Email.api.errors.${errorType}`;
};

// returns properties for customer-data-ui-utilities/alerts/Alerts
export const getEmailApiAlertOptions = ({
  errorType,
  emailRecord,
  defaultMessage = `customerDataEmail.Email.api.errors.${EmailAPIErrorTypes.GENERIC}`
}) => {
  const options = {
    _defaultMessage: defaultMessage
  };
  let message = defaultMessage;
  if (errorType) {
    let updatedErrorType = errorType.toUpperCase();
    if (errorType === EmailAPIErrorTypes.BLACKLISTED_EMAIL_IN_RECIPIENTS) {
      options.toAddress = emailRecord && emailRecord.to && emailRecord.to.first() && getEmail(emailRecord.to.first());
      updatedErrorType = options.toAddress ? errorType : EmailAPIErrorTypes.BLACKLISTED_EMAIL_IN_RECIPIENTS_NO_EMAIL;
    }
    if (updatedErrorType.includes(EmailAPIErrorTypes.EMAIL_SENDING_API_ERROR)) {
      options.settingsUrl = `/settings/${PortalIdParser.get()}/user-preferences/email`;
    }
    if (errorType.includes(EmailAPIErrorTypes.CONVERSATIONS_HOSTED_EMAIL_ACCESS_SCOPE_NOT_FOUND)) {
      options.emailAccount = emailRecord.getIn(['from', 'email']);
    }
    message = getEmailApiErrorLangKey(updatedErrorType);
  }
  return {
    message,
    options
  };
};