/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useEffect, useState } from 'react';
import { hasCompletedUnderwritingReview, useKYCStatus } from '../../eligibility/utils';
var GoToPaymentsButtonType;
(function (GoToPaymentsButtonType) {
  GoToPaymentsButtonType["applicationPending"] = "applicationPending";
  GoToPaymentsButtonType["applicationReviewed"] = "applicationReviewed";
  GoToPaymentsButtonType["paymentsActivated"] = "paymentsActivated";
})(GoToPaymentsButtonType || (GoToPaymentsButtonType = {}));
export const useGoToPaymentsButtonType = () => {
  const kycStatus = useKYCStatus();
  const [type, setType] = useState(null);
  useEffect(() => {
    if (kycStatus !== undefined) {
      if (hasCompletedUnderwritingReview(kycStatus)) {
        setType(GoToPaymentsButtonType.applicationReviewed);
      } else {
        setType(GoToPaymentsButtonType.applicationPending);
      }
    }
  }, [kycStatus]);
  return type;
};