'use es6';

import PropTypes from 'prop-types';
import PropertyPropType from './PropertyPropType';
export const AllPropertiesType = PropTypes.arrayOf(PropertyPropType);
export const DefaultPropertiesType = PropTypes.objectOf(PropTypes.oneOfType([PropertyPropType, PropTypes.string]));
export const SecondaryPropertiesType = PropTypes.arrayOf(PropertyPropType);
const nodeShape = {
  id: PropTypes.number.isRequired,
  allProperties: AllPropertiesType,
  defaultProperties: DefaultPropertiesType
};
export const CrmObjectNodeType = PropTypes.shape(nodeShape);
export function withRequiredProperties({
  allProperties: allPropertiesRequired,
  defaultProperties: defaultPropertiesRequired,
  secondaryProperties: secondaryPropertiesRequired
} = {}) {
  return PropTypes.shape({
    node: PropTypes.shape(Object.assign({}, nodeShape, {
      allProperties: allPropertiesRequired ? AllPropertiesType.isRequired : AllPropertiesType,
      defaultProperties: defaultPropertiesRequired ? DefaultPropertiesType.isRequired : DefaultPropertiesType
    })),
    secondaryProperties: secondaryPropertiesRequired ? SecondaryPropertiesType.isRequired : SecondaryPropertiesType
  });
}