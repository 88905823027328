import { Map as ImmutableMap } from 'immutable';
import { HasEmailEngagementsStore, useIsScoped } from 'crm-events-data';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'gene... Remove this comment to see the full error message
import { useStoreDependency } from 'general-store';
const EmailEngagementsStoreDependency = {
  stores: [HasEmailEngagementsStore],
  deref({
    isScoped,
    objectType,
    subjectId
  }) {
    if (!subjectId || !objectType || isScoped('connected-email-data-access')) {
      return null;
    }
    return HasEmailEngagementsStore.get(ImmutableMap({
      subjectId: `${subjectId}`,
      objectType
    }));
  }
};
const useShouldRenderEmailJITACard = ({
  currentUserEmail,
  isEmailOrActivityTab = true,
  objectType,
  portalDomain,
  subjectId
}) => {
  const isScoped = useIsScoped();
  const isAllowedToViewEmailsOnTimeline = isScoped('connected-email-data-access');
  const hasEmailEngagements = useStoreDependency(EmailEngagementsStoreDependency, {
    subjectId,
    objectType,
    isScoped
  });
  if (
  //check that this shows an email, users have the scope, and the page isn't still loading auth/emails
  !isEmailOrActivityTab || isAllowedToViewEmailsOnTimeline || !portalDomain || !currentUserEmail || !hasEmailEngagements) {
    return false;
  }
  return true;
};
export default useShouldRenderEmailJITACard;