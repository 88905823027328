export const scrollEngagementIntoView = (selector, offsetPadding = 0) => {
  // timeline container will not exist when viewing a deeplinked engagement
  // therefore get the child element just below the scrollable container
  const container = document.querySelector(`[data-measured-element="timeline-container"]`) || document.querySelector(`[data-three-column-scrollable-container] > *`);
  const scrollableContainer = document.querySelector(`[data-three-column-scrollable-container]`);
  const renderedEngagement = document.querySelector(selector);
  if (!renderedEngagement) {
    return;
  }
  const renderedEngagementPosition = renderedEngagement.getBoundingClientRect();
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const containerPosition = container.getBoundingClientRect();

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  scrollableContainer.scrollTop = renderedEngagementPosition.top - containerPosition.top + offsetPadding;
};