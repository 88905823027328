import { useEffect, useState } from 'react';
import http from 'hub-http/clients/apiClient';
const BASE_URI = 'crm/v4/objects';
const fetchAssociations = ({
  fromObjectId,
  fromObjectType,
  toObjectType
}) => http.get(`${BASE_URI}/${fromObjectType}/${fromObjectId}/associations/${toObjectType}`);
export const useFetchAssociations = ({
  fromObjectId,
  fromObjectType,
  toObjectType
}) => {
  const [associations, setAssociations] = useState();
  useEffect(() => {
    fetchAssociations({
      fromObjectId,
      fromObjectType,
      toObjectType
    }).then(response => setAssociations(response.results)).catch(e => console.log(e));
  }, [fromObjectId, fromObjectType, toObjectType]);
  return associations;
};