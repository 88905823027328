'use es6';

import * as CampaignsAPI from 'crm_data/campaigns/CampaignsAPI';
import { CRM_OBJECTS_UPDATE_STARTED, CRM_OBJECTS_UPDATE_FAILED, CRM_OBJECTS_UPDATE_SUCCEEDED } from 'crm_data/actions/ActionTypes';
import { dispatchImmediate } from 'crm_data/dispatch/Dispatch';
import { getProperty } from 'customer-data-objects/model/ImmutableModel';
import { toCrmObjectKey } from 'customer-data-objects/crmObject/CrmObjectKey';

/**
 * List of CAMPAIGN properties we need to send at all times
 * when updating a campaign, even though its values haven't changed.
 */
const CAMPAIGN_STANDARD_PROPERTIES = [{
  campaignKey: 'audience',
  crmKey: 'hs_audience'
}, {
  campaignKey: 'colorHex',
  crmKey: 'hs_color_hex'
}, {
  campaignKey: 'currencyCode',
  crmKey: 'hs_currency_code'
}, {
  campaignKey: 'displayName',
  crmKey: 'hs_name'
}, {
  campaignKey: 'endedAt',
  crmKey: 'hs_end_date'
}, {
  campaignKey: 'notes',
  crmKey: 'hs_notes'
}, {
  campaignKey: 'owner',
  crmKey: 'hs_owner'
}, {
  campaignKey: 'startedAt',
  crmKey: 'hs_start_date'
}];
function getCampaignDefaultPropertyValue(propertyName, campaign, nextProperties) {
  return nextProperties.get(propertyName) || getProperty(campaign, propertyName) || null;
}
function isCampaignStandardProperty(propertyName) {
  return CAMPAIGN_STANDARD_PROPERTIES.some(map => map.crmKey === propertyName);
}
export function updateCampaignProperties(campaign, nextProperties) {
  /**
   * Campaigns API does not support partial updates and we need to
   * send all properties in CAMPAIGN_STANDARD_PROPERTIES.
   */
  const campaignStandardProperties = CAMPAIGN_STANDARD_PROPERTIES.reduce((acc, propertyMap) => {
    acc[propertyMap.campaignKey] = getCampaignDefaultPropertyValue(propertyMap.crmKey, campaign, nextProperties);
    return acc;
  }, {});
  const campaignCustomProperties = nextProperties.keySeq().filter(propertyName => !isCampaignStandardProperty(propertyName)).toArray().reduce((acc, propertyName) => {
    acc[propertyName] = nextProperties.get(propertyName);
    return acc;
  }, {});
  const campaignActionPayload = Object.assign({}, campaignStandardProperties, {
    customProperties: campaignCustomProperties
  });
  const campaignCrmObjectPayload = {
    id: toCrmObjectKey({
      objectId: campaign.objectId,
      objectTypeId: campaign.objectTypeId
    }),
    nextProperties,
    properties: nextProperties.map((_, name) => getProperty(campaign, name))
  };
  dispatchImmediate(CRM_OBJECTS_UPDATE_STARTED, {
    id: campaignCrmObjectPayload.id,
    nextProperties
  });
  return CampaignsAPI.updateCampaignProperties(campaignActionPayload, getProperty(campaign, 'hs_origin_asset_id')).then(() => {
    dispatchImmediate(CRM_OBJECTS_UPDATE_SUCCEEDED, {
      id: campaignCrmObjectPayload.id,
      nextProperties
    });
  }).catch(error => {
    dispatchImmediate(CRM_OBJECTS_UPDATE_FAILED, Object.assign({}, campaignCrmObjectPayload, {
      error
    }));
    throw error;
  });
}