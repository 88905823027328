// See `CrmObjectPropertiesMutationErrorEnum` in the CRM graphql schema
// https://git.hubteam.com/HubSpot/CrmMeta/blob/ecc9bed5c1efbd96bf603ee7e61298e829c38352/CrmMetaGraphQL/src/main/resources/crm.graphqls#L771

export const CRM_OBJECT_NOT_FOUND = 'CRM_OBJECT_NOT_FOUND';
export const UNIQUE_VALUE_CONFLICT = 'UNIQUE_VALUE_CONFLICT';
export const INVALID_PROPERTY_VALUE = 'INVALID_PROPERTY_VALUE';
export const INSUFFICIENT_FIELD_LEVEL_PERMISSIONS = 'INSUFFICIENT_FIELD_LEVEL_PERMISSIONS';

// BET-specific errors
export const INVALID_PROPERTY_VALUE_CHANGE_ON_CLOSED_DEAL = 'INVALID_PROPERTY_VALUE_CHANGE_ON_CLOSED_DEAL';
export const NAME_CHANGE_OF_ACTIVE_COMPANY = 'NAME_CHANGE_OF_ACTIVE_COMPANY';
export const PIPELINE_ALREADY_SET = 'PIPELINE_ALREADY_SET';