'use es6';

import getSampleContacts from '../constants/sampleData/getSampleContacts';
function getSampleContactEmails() {
  const {
    BRIAN,
    COOL_ROBOT,
    STEPH,
    MARIA
  } = getSampleContacts();
  return [BRIAN.CONTACT_EMAIL, BRIAN.CONTACT_EMAIL_UID, COOL_ROBOT.CONTACT_EMAIL, COOL_ROBOT.CONTACT_EMAIL_UID, MARIA.CONTACT_EMAIL, MARIA.CONTACT_EMAIL_UID, STEPH.CONTACT_EMAIL];
}
export const getIsSampleContact = email => {
  return getSampleContactEmails().includes(email);
};