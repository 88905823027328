'use es6';

import I18n from 'I18n';
import { EditorState, Modifier, Entity, SelectionState } from 'draft-js';
import { pluginUtils } from 'draft-extend';
import isSelectionAtomic from 'draft-plugins/utils/isSelectionAtomic';
import createMeetingEntityOptions from '../../utils/createMeetingEntityOptions';
export default ((editorState, linkType, text, entityKey, decoratedText, linkURL) => {
  const isNew = entityKey === undefined;
  let selection = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();
  const currentBlockKey = selection.get('anchorKey');
  if (isSelectionAtomic(editorState)) {
    const nextBlockKey = currentContent.getKeyAfter(currentBlockKey);
    if (nextBlockKey === undefined) {
      return editorState;
    }
    selection = SelectionState.createEmpty(nextBlockKey);
  }
  let customText = true;
  if (text === undefined || text.trim().length === 0) {
    text = I18n.text(`draftPlugins.meetings.${linkType}`);
    customText = false;
  }
  if (isNew) {
    const meetingEntityOptions = createMeetingEntityOptions(linkType, customText, linkURL);
    const contentStateWithEntity = editorState.getCurrentContent().createEntity(...meetingEntityOptions);
    const meetingEntityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const editorStateWithEntity = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });
    const modifierMethod = selection.isCollapsed() ? Modifier.insertText : Modifier.replaceText;
    return EditorState.push(editorStateWithEntity, modifierMethod(editorStateWithEntity.getCurrentContent(), selection, text, editorStateWithEntity.getCurrentInlineStyle(), meetingEntityKey), 'insert-characters');
  }
  const entitySelection = pluginUtils.getEntitySelection(editorState, entityKey);
  let contentStateWithEntityChange = editorState.getCurrentContent();
  if (contentStateWithEntityChange.mergeEntityData) {
    contentStateWithEntityChange = editorState.getCurrentContent().mergeEntityData(entityKey, {
      type: linkType,
      customText,
      linkURL
    });
  } else {
    Entity.mergeData(entityKey, {
      type: linkType,
      customText,
      linkURL
    });
  }
  const entityStyles = pluginUtils.getSelectedInlineStyles(EditorState.forceSelection(editorState, entitySelection));
  if (text !== decoratedText) {
    const newEditorState = EditorState.push(editorState, Modifier.replaceText(editorState.getCurrentContent(), entitySelection, text, entityStyles, entityKey), 'update-entity');
    return newEditorState;
  }
  return editorState;
});