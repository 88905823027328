import enviro from 'enviro';
import { createTracker } from 'usage-tracker';
import events from 'customer-data-tracking/tracking/crmEvents/events.yaml';
import { CRM } from './constants/namespaces';
export const _tracker = createTracker({
  events,
  properties: {
    namespace: CRM
  },
  onError(err) {
    console.error(err);
  },
  lastKnownEventProperties: ['screen', 'subscreen'],
  debug: () => enviro.debug('customer-data-tracker')
});
export const _trackerSendImmediate = _tracker.clone({
  bypassPool: true
});