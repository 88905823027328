'use es6';

import defaultDispatcher from 'dispatcher/dispatcher';
export default function registerService(initialState, handlers, dispatcher = defaultDispatcher) {
  let state = initialState;
  function runService({
    actionType,
    data
  }) {
    if (Object.prototype.hasOwnProperty.call(handlers, actionType)) {
      state = handlers[actionType](state, data, actionType);
    }
  }
  function resetStateForTesting() {
    state = initialState;
  }
  const dispatcherId = dispatcher.register(runService);
  return {
    dispatcherId,
    getState: () => state,
    handlers,
    initialState,
    runService,
    resetStateForTesting,
    unregister: () => dispatcher.unregister(dispatcherId)
  };
}
export const WEBPACK_3_FORCE_MODULE_IMPORT = 1;