import { Map as ImmutableMap } from 'immutable';
import BETServiceObjectsStore from '../stores/BETServiceObjectsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { LOADING } from 'crm_data/constants/LoadingStatus';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { AnyCrmObjectTypePropType } from 'customer-data-objects-ui-components/propTypes/CrmObjectTypes';
import PropTypes from 'prop-types';
const BETServiceObjectsAssociationOptionsDependency = {
  propTypes: {
    objectType: AnyCrmObjectTypePropType.isRequired,
    scopes: PropTypes.arrayOf(PropTypes.string),
    subjectId: PropTypes.string.isRequired
  },
  stores: [BETServiceObjectsStore],
  deref: props => {
    const {
      scopes = [],
      objectType,
      subjectId
    } = props;
    if (!scopes.includes('bet-deliverable-service-association-access') || objectType !== CONTACT) {
      return [];
    }
    const options = BETServiceObjectsStore.get(ImmutableMap({
      objectType,
      subjectId: +subjectId
    }));
    if (!options) {
      return LOADING;
    }
    return options.map(option => {
      return {
        text: option.get('label'),
        value: option.get('objectId')
      };
    }).toArray();
  }
};
export default BETServiceObjectsAssociationOptionsDependency;