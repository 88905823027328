import { INCOMING_EMAIL } from 'customer-data-objects/engagement/EngagementTypes';
import { getProperty } from 'customer-data-objects/model/ImmutableModel';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
export const isReply = engagement => {
  return engagement ? engagement.getIn(['engagement', 'type']) === INCOMING_EMAIL || engagement.getIn(['eventData', 'engagement', 'type']) === INCOMING_EMAIL : false;
};
export const isTracked = engagement => {
  // emails without a trackerKey are not tracked)
  return !!engagement.getIn(['metadata', 'trackerKey'], false);
};
export const getEmail = emailAddress => {
  const isEmailAddressSurroundedByBrackets = emailAddress && emailAddress.indexOf('<') > -1;
  if (!isEmailAddressSurroundedByBrackets) return emailAddress;
  return emailAddress.substring(emailAddress.indexOf('<') + 1, emailAddress.indexOf('>'));
};
export const getToEmail = (subject, objectType, engagement) => {
  if (objectType === CONTACT && !isReply(engagement)) {
    return getProperty(subject, 'email');
  }
  return getEmail(engagement.getIn(['metadata', 'to', 0, 'email']));
};