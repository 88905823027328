import { LEGACY_SIDEBAR_CARD_TYPES } from '../../constants/LegacySidebarCardType';

// Legacy card should not have config type or query fragments
// Extra props on legacy card
// Legacy Sidebar Card Metadata
export const CONVERSATIONS_CONTACT_TICKETS_METADATA = {
  cardType: 'CONVERSATION_CONTACT_TICKETS',
  legacySidebarCardType: LEGACY_SIDEBAR_CARD_TYPES.ConversationsContactTicketsCard,
  hasLegacyExtraProps: true,
  renderAsLegacyCardInCrmLocationResolver: false
};