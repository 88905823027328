'use es6';

import uniqueActionTypes from 'crm_data/flux/uniqueActionTypes';
const SequenceActionTypes = uniqueActionTypes({
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  ENROLL_SUCCESS: 'ENROLL_SUCCESS',
  ENROLLMENT_UPDATE_SUCCESS: 'ENROLLMENT_UPDATE_SUCCESS',
  PAUSE_SUCCESS: 'PAUSE_SUCCESS',
  RESUME_SUCCESS: 'RESUME_SUCCESS',
  UNENROLL_SUCCESS: 'UNENROLL_SUCCESS',
  TASK_DEPENDENCY_FETCH_SUCCESS: 'TASK_DEPENDENCY_FETCH_SUCCESS'
});
export const FETCH_SUCCESS = SequenceActionTypes.FETCH_SUCCESS;
export const ENROLL_SUCCESS = SequenceActionTypes.ENROLL_SUCCESS;
export const ENROLLMENT_UPDATE_SUCCESS = SequenceActionTypes.ENROLLMENT_UPDATE_SUCCESS;
export const PAUSE_SUCCESS = SequenceActionTypes.PAUSE_SUCCESS;
export const RESUME_SUCCESS = SequenceActionTypes.RESUME_SUCCESS;
export const UNENROLL_SUCCESS = SequenceActionTypes.UNENROLL_SUCCESS;
export const TASK_DEPENDENCY_FETCH_SUCCESS = SequenceActionTypes.TASK_DEPENDENCY_FETCH_SUCCESS;

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}