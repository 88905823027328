export function buildRecursiveCrmLocationAdapter({
  transformCard,
  partiallyTransformContainer,
  partiallyTransformLocation,
  isCard
}) {
  return data => {
    if (!data) {
      return undefined;
    }
    const partialCrmLocationConfig = partiallyTransformLocation(data);
    const transformContainer = container => {
      const partialContainer = partiallyTransformContainer(container);
      return Object.assign({}, partialContainer, {
        contents: partialContainer.contents.map(cardOrContainer => {
          if (isCard(cardOrContainer)) {
            return transformCard(cardOrContainer);
          }
          return transformContainer(cardOrContainer);
        })
      });
    };
    return Object.assign({}, partialCrmLocationConfig, {
      container: transformContainer(partialCrmLocationConfig.container)
    });
  };
}