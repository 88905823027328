import Raven from 'raven-js';
import devLogger from 'react-utils/devLogger';
import { Metrics } from './Metrics';
export function logLocationQueryErrors({
  errorPrefix,
  error,
  source,
  location,
  loggingTag,
  objectTypeId,
  objectId
}) {
  devLogger.warn({
    message: error.message,
    key: `${errorPrefix}: ${error.message}`
  });
  const extra = {
    errorMessage: error.message,
    name: error === null || error === void 0 ? void 0 : error.name,
    location,
    loggingTag,
    objectTypeId,
    objectId
  };

  // by default the message will just
  let message = errorPrefix;
  let shouldLogErrorToCounter = false;

  // Split out more expected errors so they can be ignored in the JSErrors UI
  // Example error:  {message=Request for https://app.hubspot.com/api/graphql/crm failed with status 0. Request timeout, name=ApolloError}
  if (error.message.includes('failed with status 0')) {
    message = `${errorPrefix}: status 0 timeout`;
  } else if (error.message.includes('Aborting: redirection in progress')) {
    message = `${errorPrefix}: redirect in progress`;
  } else {
    // Only errors not in one of the buckets above should log to the counter
    shouldLogErrorToCounter = true;
  }
  Raven.captureException(new Error(message), {
    extra,
    // Fingerprinting should only be done on the message so that we can group errors together
    fingerprint: [message]
  });
  if (shouldLogErrorToCounter) {
    Metrics.counter('location-error', Object.assign({
      location,
      source,
      errorType: 'query'
    }, loggingTag ? {
      logging_tag: loggingTag
    } : {})).increment();
  }
}