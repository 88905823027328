import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { NEW_COMMENT_ID } from '../../comments/CommentsConstants';
const initialState = {};
function getEventCardState(state, eventId) {
  return state[eventId] || {
    commentStates: {
      [NEW_COMMENT_ID]: {
        isEditing: true
      }
    },
    drafts: {},
    isCommentsCollapsed: true,
    isEditing: false,
    isEditingTaskSubject: false,
    isForcingIntersection: false
  };
}
const cardsSlice = createSlice({
  name: 'eventCards',
  initialState,
  reducers: {
    expandAll: state => {
      Object.keys(state).forEach(k => {
        delete state[k].isCollapsed;
      });
    },
    collapseAll: state => {
      Object.keys(state).forEach(k => {
        delete state[k].isCollapsed;
        state[k].isCommentsCollapsed = true;
      });
    },
    collapse: (state, action) => {
      const eventId = action.payload;
      const eventCard = getEventCardState(state, eventId);
      eventCard.isCollapsed = true;
      eventCard.isCommentsCollapsed = true;
      state[eventId] = eventCard;
    },
    expand: (state, action) => {
      const eventId = action.payload;
      const eventCard = getEventCardState(state, eventId);
      eventCard.isCollapsed = false;
      state[eventId] = eventCard;
    },
    collapseComments: (state, action) => {
      const eventId = action.payload;
      const eventCard = getEventCardState(state, eventId);
      eventCard.isCommentsCollapsed = true;
      state[eventId] = eventCard;
    },
    expandComments: (state, action) => {
      const eventId = action.payload;
      const eventCard = getEventCardState(state, eventId);
      eventCard.isCommentsCollapsed = false;
      eventCard.isCollapsed = false;
      state[eventId] = eventCard;
    },
    clearDraftField: (state, action) => {
      const eventId = action.payload.eventId;
      const field = action.payload.field;
      const eventCard = getEventCardState(state, eventId);
      delete eventCard.drafts[field];
      state[eventId] = eventCard;
    },
    setDraftField: (state, action) => {
      const eventId = action.payload.eventId;
      const field = action.payload.field;
      const value = action.payload.value;
      const eventCard = getEventCardState(state, eventId);
      eventCard.drafts[field] = value;
      state[eventId] = eventCard;
    },
    clearDraftComment: (state, action) => {
      const eventId = action.payload.eventId;
      const commentId = action.payload.commentId;
      const eventCard = getEventCardState(state, eventId);
      const commentState = eventCard.commentStates[commentId];
      delete commentState.draft;
      state[eventId] = eventCard;
    },
    setDraftComment: (state, action) => {
      const eventId = action.payload.eventId;
      const commentId = action.payload.commentId;
      const value = action.payload.value;
      const eventCard = getEventCardState(state, eventId);
      const commentState = eventCard.commentStates[commentId];
      commentState.draft = value;
      state[eventId] = eventCard;
    },
    startEditing: (state, action) => {
      const eventId = action.payload;
      const eventCard = getEventCardState(state, eventId);
      eventCard.isEditing = true;
      state[eventId] = eventCard;
    },
    endEditing: (state, action) => {
      const eventId = action.payload;
      const eventCard = getEventCardState(state, eventId);
      eventCard.isEditing = false;
      state[eventId] = eventCard;
    },
    startEditingComment: (state, action) => {
      const eventId = action.payload.eventId;
      const commentId = action.payload.commentId;
      const eventCard = getEventCardState(state, eventId);
      eventCard.commentStates[commentId] = {
        isEditing: true
      };
      state[eventId] = eventCard;
    },
    endEditingComment: (state, action) => {
      const eventId = action.payload.eventId;
      const commentId = action.payload.commentId;
      const eventCard = getEventCardState(state, eventId);
      eventCard.commentStates[commentId] = {
        isEditing: false
      };
      state[eventId] = eventCard;
    },
    startEditingTaskSubject: (state, action) => {
      const eventId = action.payload;
      const eventCard = getEventCardState(state, eventId);
      eventCard.isEditingTaskSubject = true;
      state[eventId] = eventCard;
    },
    endEditingTaskSubject: (state, action) => {
      const eventId = action.payload;
      const eventCard = getEventCardState(state, eventId);
      eventCard.isEditingTaskSubject = false;
      state[eventId] = eventCard;
    },
    setIsForcingIntersection: (state, action) => {
      const eventId = action.payload;
      const eventCard = getEventCardState(state, eventId);
      eventCard.isForcingIntersection = true;
      state[eventId] = eventCard;
    },
    removeIsForcingIntersection: (state, action) => {
      const eventId = action.payload;
      const eventCard = getEventCardState(state, eventId);
      eventCard.isForcingIntersection = false;
      state[eventId] = eventCard;
    }
  }
});
export const eventCardsReducer = cardsSlice.reducer;
export const {
  collapse,
  expand,
  collapseComments,
  expandComments,
  expandAll,
  collapseAll,
  clearDraftField,
  setDraftField,
  clearDraftComment,
  setDraftComment,
  startEditing,
  endEditing,
  startEditingComment,
  endEditingComment,
  startEditingTaskSubject,
  endEditingTaskSubject,
  setIsForcingIntersection,
  removeIsForcingIntersection
} = cardsSlice.actions;
export const eventCardsCaseReducers = cardsSlice.caseReducers;
const getEventCardStateFromRoot = rootState => rootState.eventCards;
const makeGetEventCardState = eventCardId => createSelector([getEventCardStateFromRoot], eventState => {
  return getEventCardState(eventState, eventCardId);
});
export const makeGetIsEventCardCollapsed = eventCardId => {
  const cardSelector = makeGetEventCardState(eventCardId);
  return createSelector([cardSelector], eventCardState => {
    const {
      isCollapsed
    } = eventCardState;
    return isCollapsed;
  });
};
export const makeGetIsEventCardCommentsCollapsed = eventCardId => {
  const cardSelector = makeGetEventCardState(eventCardId);
  return createSelector([cardSelector], eventCardState => {
    const {
      isCommentsCollapsed
    } = eventCardState;
    return isCommentsCollapsed;
  });
};
export const makeGetEventCardDrafts = eventCardId => {
  const cardSelector = makeGetEventCardState(eventCardId);
  return createSelector([cardSelector], eventCardState => {
    const {
      drafts
    } = eventCardState;
    return drafts;
  });
};
export const makeGetCommentStates = eventCardId => {
  const cardSelector = makeGetEventCardState(eventCardId);
  return createSelector([cardSelector], eventCardState => {
    const {
      commentStates
    } = eventCardState;
    return commentStates;
  });
};
export const makeGetIsEventCardEditing = eventCardId => {
  const cardSelector = makeGetEventCardState(eventCardId);
  return createSelector([cardSelector], eventCardState => {
    const {
      isEditing
    } = eventCardState;
    return isEditing;
  });
};
export const makeGetIsEventCardEditingTaskSubject = eventCardId => {
  const cardSelector = makeGetEventCardState(eventCardId);
  return createSelector([cardSelector], eventCardState => {
    const {
      isEditingTaskSubject
    } = eventCardState;
    return isEditingTaskSubject;
  });
};
export const makeGetEventIsForcingIntersection = eventCardId => {
  const cardSelector = makeGetEventCardState(eventCardId);
  return createSelector([cardSelector], eventCardState => {
    const {
      isForcingIntersection
    } = eventCardState;
    return isForcingIntersection;
  });
};