import { PRIMARY_STATE, SECONDARY_STATE, PRIMARY_MODAL_PRIMARY_STATE, PRIMARY_MODAL_SECONDARY_PRIMARY_EXISTS_STATE, PRIMARY_MODAL_SECONDARY_ASSOCIATION_PRIMARY_DOESNT_EXIST_STATE, REMOVE_PRIMARY_ASSOCIATION_MODAL_STATE, REMOVE_ASSOCIATION_MODAL_PRIMARY_ONLY_ASSOCIATION_STATE, REMOVE_SECONDARY_ASSOCIATION_MODAL_STATE, REMOVE_REVERSE_PRIMARY_MODAL_STATE, EDIT_ASSOCIATION_MODAL_STATE, PRIMARY_MODAL, REMOVE_MODAL, EDIT_MODAL } from '../types/ChickletHoverStates';
/**
 * State for ChickletHoverActionsDropdown
 *
 * @param {string} modal                                           The current modal being shown
 * @param {string} returnState                                     The state  to return to after the edit association modal closes
 */

export const initialState = {
  modal: null,
  returnState: null
};
const NEXT_STATE_GRAPH = {
  [PRIMARY_STATE]: {
    OPEN_SET_PRIMARY_MODAL: state => {
      return Object.assign({}, state, {
        currentState: PRIMARY_MODAL_PRIMARY_STATE,
        modal: PRIMARY_MODAL
      });
    },
    OPEN_REMOVE_ASSOCIATION_MODAL: (state, action) => {
      return Object.assign({}, state, {
        currentState: action.isOnlyAssociation ? REMOVE_ASSOCIATION_MODAL_PRIMARY_ONLY_ASSOCIATION_STATE : REMOVE_PRIMARY_ASSOCIATION_MODAL_STATE,
        modal: REMOVE_MODAL
      });
    },
    OPEN_EDIT_ASSOCIATION_MODAL: state => {
      return Object.assign({}, state, {
        currentState: EDIT_ASSOCIATION_MODAL_STATE,
        returnState: PRIMARY_STATE,
        modal: EDIT_MODAL
      });
    }
  },
  [SECONDARY_STATE]: {
    NEW_PRIMARY_SET: state => {
      return Object.assign({}, state);
    },
    OPEN_SET_PRIMARY_MODAL: (state, action) => {
      return action.hasPrimary ? Object.assign({}, state, {
        currentState: PRIMARY_MODAL_SECONDARY_PRIMARY_EXISTS_STATE,
        modal: PRIMARY_MODAL
      }) : Object.assign({}, state, {
        currentState: PRIMARY_MODAL_SECONDARY_ASSOCIATION_PRIMARY_DOESNT_EXIST_STATE,
        modal: PRIMARY_MODAL
      });
    },
    OPEN_EDIT_ASSOCIATION_MODAL: state => {
      return Object.assign({}, state, {
        currentState: EDIT_ASSOCIATION_MODAL_STATE,
        returnState: SECONDARY_STATE,
        modal: EDIT_MODAL
      });
    },
    OPEN_REMOVE_ASSOCIATION_MODAL: (state, action) => {
      return Object.assign({}, state, {
        currentState: action.isCompanyPrimaryAssociation ? REMOVE_REVERSE_PRIMARY_MODAL_STATE : REMOVE_SECONDARY_ASSOCIATION_MODAL_STATE,
        modal: REMOVE_MODAL
      });
    }
  },
  [PRIMARY_MODAL_PRIMARY_STATE]: {
    CONFIRM: state => {
      return Object.assign({}, state, {
        currentState: SECONDARY_STATE,
        modal: null
      });
    },
    EXIT_MODAL: state => {
      return Object.assign({}, state, {
        currentState: PRIMARY_STATE,
        modal: null
      });
    }
  },
  [PRIMARY_MODAL_SECONDARY_PRIMARY_EXISTS_STATE]: {
    CONFIRM: state => {
      return Object.assign({}, state, {
        modal: null
      });
    },
    NEW_PRIMARY_SET: state => {
      return Object.assign({}, state, {
        currentState: PRIMARY_STATE
      });
    },
    EXIT_MODAL: state => {
      return Object.assign({}, state, {
        currentState: SECONDARY_STATE,
        modal: null
      });
    }
  },
  [PRIMARY_MODAL_SECONDARY_ASSOCIATION_PRIMARY_DOESNT_EXIST_STATE]: {
    CONFIRM: state => {
      return Object.assign({}, state, {
        currentState: PRIMARY_STATE,
        modal: null
      });
    },
    EXIT_MODAL: state => {
      return Object.assign({}, state, {
        currentState: SECONDARY_STATE,
        modal: null
      });
    }
  },
  [EDIT_ASSOCIATION_MODAL_STATE]: {
    CONFIRM: state => {
      return Object.assign({}, state, {
        currentState: state.returnState,
        modal: null,
        returnState: null
      });
    },
    EXIT_MODAL: state => {
      return Object.assign({}, state, {
        currentState: state.returnState,
        modal: null
      });
    }
  },
  [REMOVE_PRIMARY_ASSOCIATION_MODAL_STATE]: {
    CONFIRM: state => {
      return Object.assign({}, state, {
        modal: null
      });
    },
    EXIT_MODAL: state => {
      return Object.assign({}, state, {
        currentState: PRIMARY_STATE,
        modal: null,
        returnState: null
      });
    }
  },
  [REMOVE_ASSOCIATION_MODAL_PRIMARY_ONLY_ASSOCIATION_STATE]: {
    CONFIRM: state => {
      return Object.assign({}, state, {
        modal: null
      });
    },
    EXIT_MODAL: state => {
      return Object.assign({}, state, {
        currentState: PRIMARY_STATE,
        modal: null
      });
    }
  },
  [REMOVE_SECONDARY_ASSOCIATION_MODAL_STATE]: {
    CONFIRM: state => {
      return Object.assign({}, state, {
        modal: null
      });
    },
    EXIT_MODAL: state => {
      return Object.assign({}, state, {
        currentState: SECONDARY_STATE,
        modal: null
      });
    }
  },
  [REMOVE_REVERSE_PRIMARY_MODAL_STATE]: {
    CONFIRM: state => {
      return Object.assign({}, state, {
        modal: null
      });
    },
    EXIT_MODAL: state => {
      return Object.assign({}, state, {
        currentState: SECONDARY_STATE,
        modal: null
      });
    }
  }
};
const ChickletHoverActionsDropdownReducer = (state, action) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const stateFunction = NEXT_STATE_GRAPH[state.currentState][action.type];
  if (!stateFunction) {
    return Object.assign({}, state, {
      currentState: SECONDARY_STATE
    });
  }
  return stateFunction(state, action);
};
export default ChickletHoverActionsDropdownReducer;