'use es6';

export const ENABLED = 'ENABLED';
export const DISABLED = 'DISABLED';
export const ERROR = 'ERROR';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}