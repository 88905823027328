export const capitalizeNetwork = network => {
  return network[0] + network.substring(1).toLowerCase();
};

/**
 * safely checks and convert strings "Twitter" to "X"
 * ignores and returns as is strings with any other values
 * @param value string
 * @returns string
 */
export const safeConvertTwitterLabel = value => {
  if (value === 'Twitter' || value === 'TWITTER') {
    return 'X';
  }
  if (value === 'twitter') {
    return 'x';
  }
  return value;
};