import { useMemo } from 'react';
const mergeProvidedLegacyProps = (initial, providedProps) => {
  if (!providedProps) {
    return initial;
  }
  const result = Object.assign({}, initial);
  const passedInCardTypes = Object.keys(providedProps);
  passedInCardTypes.forEach(cardType => {
    if (!providedProps[cardType]) {
      return;
    }
    result[cardType] = Object.assign({}, result[cardType], providedProps[cardType]);
  });
  return result;
};
export function useLegacySidebarCardProps(legacySidebarCardProps) {
  const extraCardProps = useMemo(() => {
    const systemCardProps = {};
    return mergeProvidedLegacyProps(systemCardProps, legacySidebarCardProps);
  }, [legacySidebarCardProps]);
  return extraCardProps;
}