export default {
  mode: "lazy",
  source: "crm-relative-timeline/lang",
  context: {
    "da": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./da.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-da" */); },
    "de": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./de.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-de" */); },
    "en": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./en.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-en" */); },
    "es": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./es.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-es" */); },
    "fi": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./fi.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-fi" */); },
    "fr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./fr.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-fr" */); },
    "it": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./it.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-it" */); },
    "ja": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./ja.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-ja" */); },
    "ko": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./ko.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-ko" */); },
    "nl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./nl.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-nl" */); },
    "no": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./no.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-no" */); },
    "pl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./pl.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-pl" */); },
    "pt-br": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./pt-br.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-pt-br" */); },
    "sv": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./sv.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-sv" */); },
    "th": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./th.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-th" */); },
    "zh-cn": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./zh-cn.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-zh-cn" */); },
    "zh-tw": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./zh-tw.lyaml?sporks" /* webpackChunkName: "crm-relative-timeline-lang-zh-tw" */); }  }
};
