import { DEFAULT_LOADING_COMPONENT_PROPS, SPINNER } from 'crm-cards-sdk/constants/LoadingComponentProps';
export function getLoadingComponentProps(cardData, __cardLocationContext) {
  const legacySidebarCardType = cardData.configuration.cardType;
  switch (legacySidebarCardType) {
    case 'WebsiteActivityCard':
      return [{
        type: SPINNER,
        minHeight: 200
      }];
    default:
      return DEFAULT_LOADING_COMPONENT_PROPS;
  }
}