'use es6';

import { createPlugin, pluginUtils } from 'draft-extend';
import { MEETINGS_PRO_TIP_ENTITY_KEY } from '../../lib/constants';
import MeetingsLinkProTip from './MeetingsLinkProTip';
const entityToHTML = (entity, originalText) => {
  return originalText;
};
export default (({
  onFetchMeetingsError,
  onHideProTip
} = {}) => createPlugin({
  decorators: {
    strategy: pluginUtils.entityStrategy(MEETINGS_PRO_TIP_ENTITY_KEY),
    component: MeetingsLinkProTip({
      onFetchMeetingsError,
      onHideProTip
    })
  },
  entityToHTML
}));