import { createRpcClientV2 } from 'rpc-client-utils';
import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
import { getAccessControlsRpc } from '../../../__generated__/chirp/com/hubspot/ai/access/controls/rpc/AiAccessControlsChirpService';
import Raven from 'raven-js';
const rpcClient = createRpcClientV2({
  hubHttpClient: http
});
const AIAccessControlsQuery = registerQuery({
  fieldName: 'AIAccessControls',
  args: [],
  fetcher() {
    return rpcClient.call(getAccessControlsRpc, {});
  }
});
export const useFetchCrmPropertiesAIEnabled = () => {
  const {
    data
  } = useQuery(AIAccessControlsQuery, {
    onError: e => {
      console.error(e);
      Raven.captureException(e, {
        tags: {
          apiError: 'Error fetching AI access controls'
        }
      });
    }
  });
  return {
    hasCrmPropertiesAIEnabled: data === null || data === void 0 ? void 0 : data.AIAccessControls.crmPropertiesEnabled
  };
};