import { List } from 'immutable';
import { isLegacyObjectType } from 'customer-data-objects/types/LegacyObjectType';
import TimelineFiltersStore from '../stores/TimelineFiltersStore';
const TimelineOwnersFilterDependency = {
  stores: [TimelineFiltersStore],
  deref: ({
    objectType,
    objectTypeId
  }) => {
    const isCrmObject = !isLegacyObjectType(objectType);
    const objectTypeOrTypeId = isCrmObject && objectTypeId ? objectTypeId : objectType;
    const userFilters = TimelineFiltersStore.get(objectTypeOrTypeId) && TimelineFiltersStore.get(objectTypeOrTypeId).get('selectedOwners');
    return userFilters || List();
  }
};
export default TimelineOwnersFilterDependency;