import { useMemo } from 'react';
import { useCardLocationContext } from './CardLocationContext';
import isReadOnlyObjectTypeId from '../utils/isReadOnlyObjectTypeId';
import { normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
export const useCardHookContext = () => {
  const cardLocationContext = useCardLocationContext();
  const objectTypeId = cardLocationContext.additionalLocationData && cardLocationContext.additionalLocationData.objectTypeId;
  const objectId = cardLocationContext.additionalLocationData && cardLocationContext.additionalLocationData.objectId;
  const searchFilters = cardLocationContext.additionalLocationData && cardLocationContext.additionalLocationData.searchFilters;
  const searchQuery = cardLocationContext.additionalLocationData && cardLocationContext.additionalLocationData.searchQuery;
  const viewId = cardLocationContext.additionalLocationData && cardLocationContext.additionalLocationData.viewId;
  const {
    editableAreasAtom,
    propertySource
  } = cardLocationContext;
  const cardHookContext = useMemo(() => {
    const readOnly = objectTypeId ? isReadOnlyObjectTypeId(normalizeTypeId(objectTypeId)) : false;
    return {
      editableAreasAtom,
      objectId,
      objectTypeId,
      propertySource,
      readOnly,
      searchFilters,
      searchQuery,
      viewId
    };
  }, [objectId, objectTypeId, editableAreasAtom, propertySource, searchFilters, searchQuery, viewId]);
  return cardHookContext;
};