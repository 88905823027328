'use es6';

import { defineLazyKeyStore, registerLazyKeyService } from 'crm_data/store/LazyKeyStore';
import { CONTACT, TASK } from 'customer-data-objects/constants/ObjectTypes';
import PropertyValueRecord from 'customer-data-objects/property/PropertyValueRecord';
import dispatcher from 'dispatcher/dispatcher';
import { Map as ImmutableMap } from 'immutable';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import reduce from 'transmute/reduce';
import { TASKS } from '../actions/ActionNamespaces';
import { ADD_SAMPLE_TIMELINE_ITEMS, TASKS_AND_ENGAGEMENTS_UPDATED, TASKS_FETCH_FAILED } from '../actions/ActionTypes';
import engagementToTaskRecord from './engagementToTaskRecord';
import { createTaskKey, fetchTasksByIds } from './TasksServiceAPI';
registerLazyKeyService({
  namespace: TASKS,
  fetch: fetchTasksByIds
});
export const FETCH_FAILED_KEY = 'FETCH_FAILED_KEY';
export default defineLazyKeyStore({
  namespace: TASKS,
  idIsValid: key => {
    return key === FETCH_FAILED_KEY || ImmutableMap.isMap(key) && typeof key.get('objectType') === 'string' && typeof key.get('objectId') === 'string' && typeof key.get('taskId') === 'string';
  },
  idTransform: createTaskKey,
  getInitialState: () => ImmutableMap({
    [FETCH_FAILED_KEY]: false
  })
}).defineName('TasksStore').defineResponseTo(TASKS_FETCH_FAILED, state => {
  return state.set(FETCH_FAILED_KEY, true);
}).defineResponseTo(TASKS_AND_ENGAGEMENTS_UPDATED, (state, {
  taskPropertiesUpdate
}) => {
  const {
    tasksStoreKey,
    properties: propertiesToUpdate
  } = taskPropertiesUpdate;
  const transformedTaskKey = createTaskKey(tasksStoreKey);
  const taskRecord = get(transformedTaskKey, state);
  if (taskRecord) {
    const currentTaskProperties = get('properties', taskRecord);
    const newProperties = reduce(ImmutableMap(), (propertyMap, currentProperty) => {
      const propertyName = get('name', currentProperty);
      const propertyToUpdate = get(propertyName, propertiesToUpdate);
      if (propertyToUpdate) {
        const {
          value
        } = propertyToUpdate;
        const updatedPropertyRecord = PropertyValueRecord(Object.assign({}, currentProperty.toJS(), {
          value
        }));
        return propertyMap.set(propertyName, updatedPropertyRecord);
      }
      return propertyMap.set(propertyName, currentProperty);
    }, currentTaskProperties);
    const updatedTaskRecord = taskRecord.set('properties', newProperties);
    return state.set(transformedTaskKey, updatedTaskRecord);
  }
  return state;
}).defineResponseTo(ADD_SAMPLE_TIMELINE_ITEMS, (state, {
  sampleEvents,
  vid
}) => {
  return sampleEvents.filter(event => getIn(['eventData', 'engagement', 'type'], event) === TASK).map(event => engagementToTaskRecord(get('eventData', event))).reduce((acc, curTask) => acc.set(ImmutableMap(createTaskKey({
    objectId: vid,
    objectType: CONTACT,
    taskId: curTask.objectId
  })), curTask), state);
}).register(dispatcher);