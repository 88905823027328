'use es6';

import Raven from 'raven-js';
import http from 'hub-http/clients/apiClient';
const eligibilityUrl = 'payments/v1/payment-accounts/eligibility';
export const PaymentsEligibilityStatus = {
  ELIGIBLE: 'ELIGIBLE',
  INELIGIBLE: 'INELIGIBLE',
  ACCOUNT_EXISTS: 'ACCOUNT_EXISTS'
};
export const fetchPaymentsEligibility = () => {
  return http.get(eligibilityUrl);
};
export const getPaymentsEligibility = () => {
  try {
    const isEligible = fetchPaymentsEligibility().then(response => {
      return response.status === PaymentsEligibilityStatus.ACCOUNT_EXISTS;
    }).catch(err => {
      Raven.captureException(new Error('Payment Link Eligibility request failed'), {
        extra: {
          error: err
        }
      });
      return false;
    });
    return isEligible;
  } catch (err) {
    Raven.captureException(new Error('Payment Link Eligibility error'), {
      extra: {
        error: err
      }
    });
    return Promise.resolve(false);
  }
};