import { fetch } from '../api/BETPortalObjectsApi';
import { Map as ImmutableMap } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { BET_PORTAL_OBJECT_ASSOCIATIONS } from 'crm_data/actions/ActionNamespaces';
import { defineLazyKeyStore, registerLazyKeyService
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/store/LazyKeyStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import makeBatch from 'crm_data/api/makeBatch';
import { BET_PORTAL_OBJECT_ASSOCIATIONS_ADD, BET_PORTAL_OBJECT_ASSOCIATIONS_REMOVE
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
const batchFetch = makeBatch(id => {
  const objectType = id.get('objectType');
  const subjectId = id.get('subjectId');
  return fetch(objectType, subjectId);
}, 'BETPortalObjectsStore.fetch');
const _makeKey = (subjectId, objectType) => {
  return ImmutableMap({
    subjectId,
    objectType
  });
};
registerLazyKeyService({
  namespace: BET_PORTAL_OBJECT_ASSOCIATIONS,
  fetch: batchFetch
});
export default defineLazyKeyStore({
  namespace: BET_PORTAL_OBJECT_ASSOCIATIONS,
  idIsValid: key => {
    return ImmutableMap.isMap(key) && typeof key.get('subjectId') === 'number' && typeof key.get('objectType') === 'string';
  }
}).defineResponseTo(BET_PORTAL_OBJECT_ASSOCIATIONS_ADD, (state, {
  objectType,
  engagementId,
  BETPortalAssociations
}) => {
  const key = _makeKey(engagementId, objectType);
  return state.update(key, associations => {
    if (!associations) {
      return associations;
    }
    return associations.concat(BETPortalAssociations);
  });
}).defineResponseTo(BET_PORTAL_OBJECT_ASSOCIATIONS_REMOVE, (state, {
  objectType,
  engagementId,
  BETPortalAssociation
}) => {
  const key = _makeKey(engagementId, objectType);
  if (!state.get(key)) {
    return state;
  }
  return state.update(key, value => {
    return value.filterNot(portalAssociation => {
      return portalAssociation.equals(BETPortalAssociation);
    });
  });
}).register();