import { gql } from '@apollo/client';
export const QuickActionsConfigFragment = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"QuickActionsConfigFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"QuickActionsCardConfiguration\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"actions\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"actionType\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"actionContext\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"engagementId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"external\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"includeEschref\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"href\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"path\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"toObjectTypeId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"associationSpec\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"associationCategory\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"associationTypeId\"}}]}}]}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "QuickActionsConfigFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "QuickActionsCardConfiguration"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "objectTypeId"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "actions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actionType"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "actionContext"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "engagementId"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "external"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "includeEschref"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "href"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "path"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "toObjectTypeId"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "associationSpec"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "associationCategory"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "associationTypeId"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
});