import { useEffect, useReducer, useMemo } from 'react';
import Raven from 'raven-js';
import emptyFunction from 'react-utils/emptyFunction';
import { normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { fetchRecordById } from '../api/CrmObjectAPI';
import { navigateToUrl, getRootUrl } from '../../utils/urls';
export const _redirectMergedObjectToNewObjectId = async ({
  objectType,
  subjectId,
  onNotRedirecting
}) => {
  if (!objectType || !subjectId) {
    onNotRedirecting();
    return;
  }
  const onError = err => {
    if (err.status === 404) return null;
    //silently fail and allow rest of record to handle error state
    Raven.captureException(err, {
      extra: {
        objectType,
        subjectId
      }
    });
    return null;
  };
  const resolvedId = await fetchRecordById(objectType, subjectId).then(subject => {
    if (subject && Object.prototype.hasOwnProperty.call(subject, 'objectId') && subject.objectId) {
      return subject.objectId;
    }
    return null;
  }).catch(onError);
  if (resolvedId !== null && `${resolvedId}` !== subjectId) {
    // redirect if subject returned has different ID than requested
    // usually in the case of the requested record having been merged
    // into the record that is returned
    navigateToUrl(`${getRootUrl()}/record/${normalizeTypeId(objectType)}/${resolvedId}/`);
  } else {
    onNotRedirecting();
  }
};
export function _refreshPageOnNoMatch({
  objectType,
  subjectId,
  currentUrl = window.location.href,
  currentSearchString = window.location.search,
  onNotRedirecting
}) {
  // Don't try refreshing the page multiple times, this causes infinite loops
  // And also don't try refreshing if we have all the required params, if that's
  // the case this might be a merged object or actually not a valid object
  if (currentSearchString.includes('redirectFrom=crm-records-ui') || objectType && subjectId) {
    onNotRedirecting();
    return;
  }
  let hrefWithQueryParam;
  if (currentSearchString !== '') {
    hrefWithQueryParam = `${currentUrl}&redirectFrom=crm-records-ui`;
  } else {
    hrefWithQueryParam = `${currentUrl}?redirectFrom=crm-records-ui`;
  }
  navigateToUrl(hrefWithQueryParam);
}
const REDIRECT_KEYS = {
  REDIRECT_TO_MERGED_OBJECT: 'REDIRECT_TO_MERGED_OBJECT',
  REFRESH_URL: 'REFRESH_URL'
};
export function useProfileRedirects({
  objectType,
  subjectId
}) {
  // This returns true when it is performing redirect logic and will return false when we know we will not redirect
  const [isMaybeRedirectingState, setKeyIsNotRedirecting] = useReducer((state, key) => Object.assign({}, state, {
    [key]: false
  }), {
    [REDIRECT_KEYS.REDIRECT_TO_MERGED_OBJECT]: true,
    [REDIRECT_KEYS.REFRESH_URL]: true
  });
  const isMaybeRedirecting = useMemo(() => Boolean(Object.values(isMaybeRedirectingState).find(isRedirecting => isRedirecting === true)), [isMaybeRedirectingState]);
  useEffect(() => {
    _refreshPageOnNoMatch({
      objectType,
      subjectId,
      onNotRedirecting: () => setKeyIsNotRedirecting(REDIRECT_KEYS.REFRESH_URL)
    });
    _redirectMergedObjectToNewObjectId({
      objectType,
      subjectId,
      onNotRedirecting: () => setKeyIsNotRedirecting(REDIRECT_KEYS.REDIRECT_TO_MERGED_OBJECT)
    }).catch(emptyFunction);
  }, [objectType, subjectId]);
  return isMaybeRedirecting;
}