import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import useAuth from './useAuth';
export const useIsSupportedRecordPage = (objectTypeId, error, data) => {
  const userInfo = useAuth();
  if (isPortalSpecificObjectType(objectTypeId) || error) {
    return true;
  }
  if (data) {
    var _data$objectTypeCrmRe, _data$objectTypeCrmRe2;
    const isEnabled = Boolean((_data$objectTypeCrmRe = data.objectTypeCrmRecordsPageFAS.metadata.enabled) === null || _data$objectTypeCrmRe === void 0 ? void 0 : _data$objectTypeCrmRe.value);
    if (!userInfo) {
      return isEnabled;
    }
    const {
      gates,
      user: {
        scopes
      }
    } = userInfo;
    const enableCases = (_data$objectTypeCrmRe2 = data.objectTypeCrmRecordsPageFAS.metadata.enableCases) === null || _data$objectTypeCrmRe2 === void 0 ? void 0 : _data$objectTypeCrmRe2.value;
    const isSupportedRecordPage = isEnabled && (!enableCases || !enableCases.length || enableCases.some(({
      scopes: enableScopes,
      gates: enableGates
    }) => {
      const includedGates = enableGates && enableGates.includes || [];
      const excludedGates = enableGates && enableGates.excludes || [];
      const includedScopes = enableScopes && enableScopes.includes || [];
      const excludedScopes = enableScopes && enableScopes.excludes || [];
      return includedGates.every(gate => gates.includes(gate)) && !excludedGates.some(gate => gates.includes(gate)) && includedScopes.every(scope => scopes.includes(scope)) && !excludedScopes.some(scope => scopes.includes(scope));
    }));
    return isSupportedRecordPage;
  }
  return true;
};