import setIn from 'transmute/setIn';
export function isAssociatedObjectsCard(card) {
  return card.__typename === 'AssociatedObjectsCard';
}
function isSingleCardQuery(previousResult) {
  return Boolean(previousResult && previousResult.crmObject && 'card' in previousResult.crmObject && previousResult.crmObject.card);
}
export function getAssociatedObjectsCardFromQueryCache(queryCacheResult, locator) {
  if (isSingleCardQuery(queryCacheResult)) {
    if (isAssociatedObjectsCard(queryCacheResult.crmObject.card) && locator(queryCacheResult.crmObject.card)) {
      return queryCacheResult.crmObject.card;
    }
    return undefined;
  }
  if (!queryCacheResult.crmObject.cards) {
    return undefined;
  }
  const foundCard = queryCacheResult.crmObject.cards.find(card => isAssociatedObjectsCard(card) && locator(card));
  return foundCard && isAssociatedObjectsCard(foundCard) ? foundCard : undefined;
}
export function updateCardInQueryCache(queryCacheResult, newCard) {
  if (!newCard) {
    return queryCacheResult;
  }

  // If the previousResult was a recordCard query instead of a recordCards query, do this merge logic
  if (isSingleCardQuery(queryCacheResult)) {
    return Object.assign({}, queryCacheResult, {
      crmObject: Object.assign({}, queryCacheResult.crmObject, {
        card: newCard
      })
    });
  }
  const cardIndex = queryCacheResult.crmObject.cards.findIndex(card => card.cardId === newCard.cardId);
  return setIn(['crmObject', 'cards', String(cardIndex)], newCard, queryCacheResult);
}