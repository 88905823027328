import { registerQuery, useQuery } from 'data-fetching-client';
import Raven from 'raven-js';
import http from 'hub-http/clients/apiClient';
export const DataPrivacySettingsQuery = registerQuery({
  fieldName: 'dataPrivacySettings',
  fetcher() {
    return http.get('data-privacy-settings/hub-settings');
  }
});
export function useDataPrivacySettings() {
  return useQuery(DataPrivacySettingsQuery, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    onError: error => {
      Raven.captureException(error, {
        tags: {
          apiError: 'Error fetching DataPrivacySettings'
        }
      });
    }
  });
}