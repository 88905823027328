import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["message"];
import { Record, List, Map as ImmutableMap } from 'immutable';
import invariant from 'react-utils/invariant';
import AliasAddress from './AliasAddress';
import ConnectedAccount from './ConnectedAccount';
// @ts-expect-error FIXME: No module
import EmailAddressRecord from '../email/EmailAddressRecord';
import { TICKET } from 'customer-data-objects/constants/ObjectTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
import { getPersistentFromAddress } from '../../utils/PersistentFromAddress';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { logError } from 'customer-data-ui-utilities/eventLogging/eventLogger';
export default class ConnectedAccounts extends Record({
  accounts: List(),
  defaultSendFrom: ''
}, 'ConnectedAccountsRecord') {
  logAccountsError(_ref) {
    let {
        message
      } = _ref,
      extraData = _objectWithoutPropertiesLoose(_ref, _excluded);
    logError({
      error: new Error(`ConnectedAccounts Error: ${message}`),
      extraData: Object.assign({
        accounts: this.accounts
      }, extraData),
      persistedData: getPersistentFromAddress().addresses
    });
  }
  isIntegrated(aliasEmailAddressId) {
    if (aliasEmailAddressId) {
      const containingAccount = this.getAccount(aliasEmailAddressId, false);
      return containingAccount && containingAccount.hasEmailIntegration();
    }
    return !this.accounts.isEmpty() && this.accounts.some(account => account.hasEmailIntegration());
  }
  getUsersConnectedAliasAddressId(userEmailAddress) {
    const userEmailAddressId = this.getAddressId(userEmailAddress, false);
    if (userEmailAddressId && this.isConnected(userEmailAddressId) && this.isEnabled(userEmailAddressId)) {
      return userEmailAddressId;
    }
    return undefined;
  }
  getPersistentFromAddressId(persistentFromAddress, objectType) {
    const {
      addressId
    } = persistentFromAddress;
    try {
      const account = this.getAccount(addressId, false);
      if (!account) {
        this.logAccountsError({
          message: 'No account found in persistent from address',
          extraData: {
            method: 'getDefaultIntegratedAliasAddress',
            addressId
          }
        });
      }
      const hasAliasAccount = account && account.hasAlias(persistentFromAddress.address);
      if (hasAliasAccount && this.isEnabled(addressId) && (this.isConnected(addressId) || this.isIntegrated(addressId))) {
        return addressId;
      } else {
        getPersistentFromAddress().invalidate(objectType);
      }
    } catch (error) {
      this.logAccountsError({
        message: 'Failed to get persistent address from accounts',
        extraData: {
          error,
          persistentFromAddress
        }
      });
      getPersistentFromAddress().invalidate(objectType);
    }
  }
  getDefaultIntegratedAliasAddress({
    userEmailAddress = '',
    objectType = '',
    isUngatedForDefaultSendFromGate = false,
    hasDefaultSendFromEmail = false
  }) {
    if (!isUngatedForDefaultSendFromGate || !hasDefaultSendFromEmail) {
      const persistentFromAddress = getPersistentFromAddress().getAddressByType(objectType);
      if (persistentFromAddress) {
        const persistentFromAddressId = this.getPersistentFromAddressId(persistentFromAddress, objectType);
        if (persistentFromAddressId) {
          return persistentFromAddressId;
        }
      }
    }
    const usersConnectedAliasAddressId = this.getUsersConnectedAliasAddressId(userEmailAddress);
    if (objectType !== TICKET && usersConnectedAliasAddressId) {
      return usersConnectedAliasAddressId;
    }
    const firstIntegratedAlias = this.getPrimaryIntegratedAlias(objectType);
    if (firstIntegratedAlias) {
      return firstIntegratedAlias.getAddressId();
    }
    return AliasAddress.toAddressId(userEmailAddress, userEmailAddress);
  }
  getEnabledAliasMap() {
    let aliases;
    const aliasMap = {
      connectedAliases: {
        shared: List(),
        nonShared: List()
      },
      integratedAliases: {
        shared: List(),
        nonShared: List()
      }
    };
    try {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 0.
      aliases = this.getAliases();
    } catch (error) {
      this.logAccountsError({
        message: 'Could not get Aliases from account list',
        extraData: {
          error
        }
      });
    }
    if (!aliases) {
      return aliasMap;
    }
    return aliases.reduce((map, alias) => {
      const addressId = alias.getAddressId();
      if (!this.isEnabled(addressId)) {
        return map;
      }
      const sharedKey = this.isShared(alias.getAddressId()) ? 'shared' : 'nonShared';
      if (this.isConnected(addressId)) {
        map.connectedAliases[sharedKey] = map.connectedAliases[sharedKey].push(alias);
      } else if (this.isIntegrated(addressId)) {
        map.integratedAliases[sharedKey] = map.integratedAliases[sharedKey].push(alias);
      }
      return map;
    }, aliasMap);
  }
  hasEnabledAliases() {
    const {
      connectedAliases,
      integratedAliases
    } = this.getEnabledAliasMap();
    return ['shared', 'nonShared'].reduce((total, type) => {
      return total +
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      connectedAliases[type].count() +
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      integratedAliases[type].count();
    }, 0) > 0;
  }
  getFirstIntegratedAlias(objectType) {
    const {
      connectedAliases,
      integratedAliases
    } = this.getEnabledAliasMap();
    const firstSharedConnectedAccount = connectedAliases.shared.get(0);
    if (firstSharedConnectedAccount && objectType === TICKET) {
      return firstSharedConnectedAccount;
    }
    const {
      nonShared,
      shared
    } = firstSharedConnectedAccount || connectedAliases.nonShared.count() ? connectedAliases : integratedAliases;
    return nonShared.get(0) || shared.get(0);
  }
  getPrimaryIntegratedAlias(objectType) {
    const {
      connectedAliases,
      integratedAliases
    } = this.getEnabledAliasMap();
    const getPrimary = arr => arr.find(item => item.primary);
    const primarySharedConnectedAccount = getPrimary(connectedAliases.shared) || connectedAliases.shared.get(0);
    if (primarySharedConnectedAccount && objectType === TICKET) {
      return primarySharedConnectedAccount;
    }
    const {
      nonShared,
      shared
    } = primarySharedConnectedAccount || connectedAliases.nonShared.count() ? connectedAliases : integratedAliases;
    return getPrimary(nonShared) || getPrimary(shared) || this.getFirstIntegratedAlias(objectType);
  }
  hasIntegration(type) {
    return this.accounts.some(account => account.isEmailIntegrationEnabled() && account.integration.type === type);
  }
  areAllIntegrationsDisabled() {
    return this.accounts.every(account => !account.isEmailIntegrationEnabled());
  }
  isConnected(aliasEmailAddressId) {
    const containingAccount = this.getAccount(aliasEmailAddressId, false);
    return !!containingAccount && containingAccount.hasEmailIntegration() && containingAccount.isEmailIntegrationEnabled();
  }
  isEnabled(aliasEmailAddressId) {
    const containingAccount = this.getAccount(aliasEmailAddressId, false);
    return !!containingAccount && containingAccount.isEnabled(AliasAddress.toAccountId(aliasEmailAddressId));
  }
  isShared(aliasEmailAddressId) {
    const containingAccount = this.getAccount(aliasEmailAddressId, false);
    return !!containingAccount && containingAccount.isShared();
  }
  getHubSpotHostedAccounts() {
    return this.accounts.filter(account => account.isHubSpotHosted());
  }
  getNonHubSpotHostedAccounts() {
    return this.accounts.filter(account => !account.isHubSpotHosted());
  }
  isHubSpotHosted(aliasEmailAddressId) {
    const account = this.getAccount(aliasEmailAddressId, false);
    return !!account && account.isHubSpotHosted();
  }
  isDisconnected(aliasEmailAddressId) {
    const containingAccount = this.getAccount(aliasEmailAddressId, false);
    return !!containingAccount && containingAccount.hasEmailIntegration() && !containingAccount.isEmailIntegrationEnabled();
  }
  isDisabled(aliasEmailAddressId) {
    const containingAccount = this.getAccount(aliasEmailAddressId, false);
    return !!containingAccount && containingAccount.hasFacsimileInbox() && containingAccount.hasEmailIntegration() && !containingAccount.isEmailIntegrationEnabled();
  }

  // Gets the aliases of accounts that are facsimileInbox enabled and of logged in user.
  // The logged-in user is added to handle the case of new user logging into hubspot for the first
  // time without having connected their account
  getFacsimileInboxAliases(loggedInUserEmailAddress, accounts = this.accounts) {
    const fascimileInboxAccounts = accounts.filter(account => account.address === loggedInUserEmailAddress || account.hasFacsimileInbox() && account.facsimileInbox === 'ENABLED');
    return this.getAliases(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Iterable<number, unknown>' is no... Remove this comment to see the full error message
    fascimileInboxAccounts, loggedInUserEmailAddress).filter(alias => alias.get('type') !== 'owner' || alias.get('address') === loggedInUserEmailAddress);
  }
  getAliases(accounts = this.accounts, loggedInUserEmailAddress) {
    // If there are duplicate aliases across the connected accounts, select one of them, giving preference to connected aliases.
    // Filter out all aliases that are of type owner with the exception of the loggedInUserEmailAddress alias
    return accounts.map(account => account.getAliasEmailAddresses()).flatten(true).filterNot(alias => loggedInUserEmailAddress && alias.type === 'owner' && alias.address !== loggedInUserEmailAddress).sortBy(alias => alias.get('accountId')).reduce((aliases, currentAlias) => {
      const existingAlias = aliases.get(currentAlias.address);
      if (existingAlias) {
        const aliasId = currentAlias.getAddressId();
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        const existingAliasId = existingAlias.getAddressId();
        const currentAliasAccount = this.getAccount(aliasId, false);
        // 1) Prioritize the connected primary alias (and make sure it's enabled)
        if (this.isConnected(aliasId) && this.isEnabled(aliasId) && currentAliasAccount.isPrimaryAlias(currentAlias.address)) {
          return aliases.set(currentAlias.address, currentAlias);
        }
        // 2) Keep the current alias if it is connected/enabled
        if (this.isConnected(existingAliasId) && this.isEnabled(existingAliasId)) {
          return aliases;
        }
      }
      return aliases.set(currentAlias.address, currentAlias);
    }, ImmutableMap()).valueSeq().sortBy(alias => alias.get('displayableAddress')).sortBy(alias => !!alias.get('disabled'));
  }
  getAddressId(aliasEmailAddress, validate = true) {
    const accounts = this.getAccountsWithAlias(aliasEmailAddress);
    if (validate) {
      invariant(!accounts.isEmpty(), 'No containing account found for alias %s', aliasEmailAddress);
    }
    if (accounts.isEmpty()) {
      return '';
    }
    const enabledAccounts = accounts.filter(acct => acct.isEnabled(aliasEmailAddress));
    let account = enabledAccounts.find(acct => {
      return acct.isPrimaryAlias(aliasEmailAddress) && acct.hasEmailIntegration() && acct.isEmailIntegrationEnabled();
    }) || enabledAccounts.find(acct => acct.hasEmailIntegration() && acct.isEmailIntegrationEnabled()) || accounts.find(acct => acct.isEmailIntegrationEnabled());
    if (!account) {
      account = accounts.first();
    }
    return AliasAddress.toAddressId(account.address, aliasEmailAddress);
  }
  getAliasEmailAddress(aliasEmailAddressId, validate = true) {
    const account = this.getAccount(aliasEmailAddressId, false);
    const aliasEmailAddress = AliasAddress.toAliasAddress(aliasEmailAddressId);
    if (validate) {
      invariant(account.hasAlias(aliasEmailAddress), 'No alias email address found for address id %s', aliasEmailAddressId);
    } else if (!account || !account.hasAlias(aliasEmailAddress)) {
      this.logAccountsError({
        message: 'Unable to get Alias Email Address',
        extraData: {
          aliasEmailAddressId,
          account
        }
      });
    }
    return aliasEmailAddress;
  }
  getAccountEmailAddress(aliasEmailAddressId) {
    const account = this.getAccount(aliasEmailAddressId, false);
    if (!account) {
      this.logAccountsError({
        message: 'Unable to get Account Email Address',
        extraData: {
          aliasEmailAddressId
        }
      });
    }
    return account && account.address;
  }
  getAccount(aliasEmailAddressId, validate = true) {
    const accountId = AliasAddress.toAccountId(aliasEmailAddressId);
    const account = this.accounts.find(acct => acct.address === accountId);
    if (validate) {
      invariant(!!account, 'No containing account found for alias address id %s', aliasEmailAddressId);
    }
    return account;
  }
  getAccountsWithAlias(aliasEmailAddress) {
    return this.accounts.filter(acct => acct.hasAlias(aliasEmailAddress));
  }
  getAccountWithAlias(aliasEmailAddress) {
    const accountsWithAlias = this.getAccountsWithAlias(aliasEmailAddress);
    const primaryEnabledAlias = accountsWithAlias.find(acct => acct.isEnabled(aliasEmailAddress) && acct.isPrimaryAlias(aliasEmailAddress));
    if (primaryEnabledAlias) {
      return primaryEnabledAlias;
    }
    return accountsWithAlias.get(0);
  }
  hasPrimaryAccount(userEmailAddress) {
    return !!this.getPrimaryAccount(userEmailAddress);
  }
  getPrimaryAccount(userEmailAddress) {
    return this.accounts.find(account => account.address === userEmailAddress);
  }
  getPrimarySignature(userEmailAddress) {
    const primaryAccount = this.getPrimaryAccount(userEmailAddress);
    if (!primaryAccount) {
      return null;
    }
    const primaryAlias = primaryAccount.getPrimaryAlias();
    return primaryAlias ? primaryAlias.signature : null;
  }
  updateSignature(aliasEmailAddressId, signature) {
    const idx = this.accounts.indexOf(this.getAccount(aliasEmailAddressId, false));
    if (idx === -1) {
      this.logAccountsError({
        message: 'Unable to update email signature - email does not exist',
        extraData: {
          aliasEmailAddressId
        }
      });
      return this;
    }
    return this.set('accounts', this.accounts.update(idx, account => account.updateSignature(this.getAliasEmailAddress(aliasEmailAddressId, false), signature)));
  }
  getAliasFromAliasAddressId(aliasAddressId) {
    const account = this.getAccount(aliasAddressId, false);
    if (account) {
      const address = account.get('address');
      return account.getAliasWithAddress(address);
    }
    return null;
  }

  /*
   * Given a set of toAddresses, identify a from address to which user has access to
   * Prioritize the shared address over personal address
   */
  getFromEmailAddress(toAddress, {
    skipPermissionsVerification = false
  } = {}) {
    let from;
    if (!toAddress) {
      return undefined;
    }
    if (!List.isList(toAddress)) {
      // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
      toAddress = new List([toAddress]);
    }
    if (this.accounts) {
      // find all connected addresses to which the email was sent to
      const emailAccountMapping = {}; // accountId: { isAlias, Account}
      let connectedAccounts = List();
      this.accounts.forEach(account => {
        toAddress.forEach(address => {
          const emailAddress = address.get('address');
          const isEnabledAlias = skipPermissionsVerification || account.isEnabled(emailAddress);
          const isSendFromEmail = emailAddress === account.sendFromEmail;
          const keepThisAccount = isEnabledAlias || isSendFromEmail && !account.hasAlias(emailAddress) && !account.getPrimaryAlias().disabled;
          if (keepThisAccount) {
            connectedAccounts = connectedAccounts.push(account);
            const accountId = account.get('address');
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            emailAccountMapping[accountId] = {
              isAlias: isEnabledAlias,
              emailAddress: isEnabledAlias ? emailAddress : account.get('address'),
              account
            };
          }
        });
      });
      if (connectedAccounts && connectedAccounts.size) {
        // Try picking a connected account that is shared
        let connectedAccount = connectedAccounts.find(account => account.isShared());
        if (!connectedAccount) {
          // If not, just pick the first address from the list
          connectedAccount = connectedAccounts.get(0);
        }
        const accountId = connectedAccount.get('address');
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const emailAddress = emailAccountMapping[accountId].emailAddress;
        try {
          from = new EmailAddressRecord({
            address: emailAddress,
            addressId: this.getAddressId(emailAddress),
            friendlyName: connectedAccount.get('friendlyFromName'),
            sendAsAddress: connectedAccount.get('sendFromEmail'),
            resolvedFromName: connectedAccount.get('resolvedFromName')
          });
        } catch (e) {
          this.logAccountsError({
            message: 'Could not get from address',
            extraData: {
              toAddress,
              emailAddress,
              accountId
            }
          });
        }
      }
    }
    return from;
  }
  static fromJS(json) {
    if (!json || typeof json !== 'object') {
      return json;
    }
    return new ConnectedAccounts({
      accounts: json.accounts ? List(json.accounts.map(ConnectedAccount.fromJS)) : List()
    });
  }
}