module.exports = {
  "installWizardModalInteraction": {
    "name": "install wizard modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      }
    },
    "namespace": "crm-records-ui"
  },
  "salesExtensionModalInteraction": {
    "name": "onboarding sales extension modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      }
    },
    "namespace": "crm-records-ui"
  },
  "middlePaneTabOpenedInteraction": {
    "name": "record middle pane tab opened",
    "class": "interaction",
    "properties": {
      "tabLabel": {
        "type": "string"
      },
      "isActivitiesTab": {
        "type": "boolean"
      },
      "isOpenedAsDefault": {
        "type": "boolean"
      }
    },
    "namespace": "crm-records-ui"
  }
};