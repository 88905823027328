import memoize from 'transmute/memoize';
export class IntersectionObserverManager {
  constructor(options) {
    this.callbacks = new Map();
    this.intersectionObserver = new IntersectionObserver((entries, observer) => {
      for (let i = 0; i < entries.length; i++) {
        const callback = this.callbacks.get(entries[i].target);
        if (callback) {
          callback(entries[i], observer);
        }
      }
    }, options);
  }
  subscribe(target, callback) {
    this.intersectionObserver.observe(target);
    this.callbacks.set(target, callback);
  }
  unsubscribe(target) {
    if (!this.callbacks.has(target)) {
      return;
    }
    this.intersectionObserver.unobserve(target);
    this.callbacks.delete(target);
  }
}
export const getIntersectionObserverManager = memoize((root, rootMargin, threshold) => new IntersectionObserverManager({
  root,
  rootMargin,
  threshold
}));