import enviro from 'enviro';
import emptyFunction from 'react-utils/emptyFunction';
// Safety wrapper around `window.hsFeedback`
function getHsFeedbackInstance() {
  if ('hsFeedback' in window) {
    return window.hsFeedback;
  }
  return {
    loadSurvey: emptyFunction
  };
}
export const useCsatSurvey = ({
  qaId,
  prodId
}) => {
  const triggerSurvey = () => {
    const isProd = enviro.isProd();
    getHsFeedbackInstance().loadSurvey('CSAT', isProd ? prodId : qaId);
  };
  return triggerSurvey;
};