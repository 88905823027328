import { useCallback } from 'react';
import { useTimelineDispatch, useTimelineSelector } from '../../reduxHooks';
import { getIsSearchInUse, startSearch as startSearchReducer, endSearch as endSearchReducer } from '../timelineControlsSlice';
export function useTimelineSearch() {
  const isSearchInUse = useTimelineSelector(getIsSearchInUse);
  const dispatch = useTimelineDispatch();
  const startSearch = useCallback(() => {
    dispatch(startSearchReducer());
  }, [dispatch]);
  const endSearch = useCallback(() => {
    dispatch(endSearchReducer());
  }, [dispatch]);
  return [isSearchInUse, startSearch, endSearch];
}