'use es6';

import LegacyActiveOwnerIdsStore from './LegacyActiveOwnerIdsStore';
import dispatcher from 'dispatcher/dispatcher';
import { InspectStore, defineFactory } from 'general-store';
import identity from 'transmute/identity';
import get from 'transmute/get';
import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { isLoading, LOADING, isResolved } from 'crm_data/flux/LoadingStatus';
import OwnerIdsByEmailStore from './OwnerIdsByEmailStore';
import OwnersStore from './OwnersStore';
import { dispatchQueue, dispatchImmediate } from '../dispatch/Dispatch';
import { LEGACY_OWNERS_FETCH_STARTED, LEGACY_OWNERS_FETCH_SUCCEEDED, LEGACY_OWNERS_UPDATED, LEGACY_OWNERS_FETCH_QUEUED, LEGACY_OWNERS_FETCH_FAILED } from 'crm_data/actions/ActionTypes';
import { fetchAll } from './OwnersAPI';
import { indexByIdString } from 'customer-data-objects/protocol/Identifiable';
import memoizeLast from 'transmute/memoizeLast';
const maybeFetchMore = (state, data) => {
  if (data && data.hasMore) {
    fetchAll(data.offset, true).then(response => {
      const owners = data.owners.concat(response.owners);
      const updates = Object.assign({}, response, {
        owners
      });
      dispatchQueue(LEGACY_OWNERS_UPDATED, updates);
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  }
  return state.set('owners', get('owners', data));
};
const shouldUsePagedEndpoint = options => options && options.usePaged;
const memoizedIndexByIdString = memoizeLast(collection => {
  return indexByIdString(collection);
});
const legacyOwnerFetch = () => {
  const ownerIds = LegacyActiveOwnerIdsStore.get();
  if (!isResolved(ownerIds)) {
    return ImmutableMap();
  }
  const activeOwners = OwnersStore.get(ownerIds);
  if (activeOwners.some(isLoading)) {
    return ImmutableMap();
  }
  return activeOwners;
};
export default defineFactory().defineName('LegacyOwnersStore').defineGetInitialState(() => ImmutableMap({
  promise: null,
  owners: LOADING
})).defineGet((state, options) => {
  if (shouldUsePagedEndpoint(options)) {
    if (!state.get('promise')) {
      dispatchQueue(LEGACY_OWNERS_FETCH_QUEUED);
    }
    if (!isResolved(state.get('owners'))) {
      return ImmutableMap();
    }
    return memoizedIndexByIdString(state.get('owners'));
  }
  return legacyOwnerFetch();
}).defineResponseTo(LEGACY_OWNERS_FETCH_QUEUED, state => {
  const promise = fetchAll(0, true);
  dispatchQueue(LEGACY_OWNERS_FETCH_STARTED);
  promise.then(response => dispatchImmediate(LEGACY_OWNERS_FETCH_SUCCEEDED, response), () => dispatchImmediate(LEGACY_OWNERS_FETCH_FAILED)).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return state.set('promise', promise);
}).defineResponseTo(LEGACY_OWNERS_FETCH_SUCCEEDED, maybeFetchMore).defineResponseTo(LEGACY_OWNERS_UPDATED, maybeFetchMore).defineResponseTo(LEGACY_OWNERS_FETCH_FAILED, state => state.set('promise', null)).defineResponseTo(
// HACK: makes LegacyOwnersStore respond to all actions of the other owners stores
[LegacyActiveOwnerIdsStore, OwnerIdsByEmailStore, OwnersStore].reduce((allActions, store) => allActions.union(InspectStore.getActionTypes(store)), ImmutableSet()).toArray(), identity).register(dispatcher);