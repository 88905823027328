import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { DeleteCrmObjectAssociationBySpec as DeleteCrmObjectAssociationBySpecMutation } from './useDeleteCrmObjectAssociationBySpecMutationQueries';
import getIn from 'transmute/getIn';
import { normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import addMutationResultAlert from 'customer-data-sidebar/universal/alertHelpers/addMutationResultAlert';
import { getAssociatedObjectEdges } from '../helpers/associationObjectsHelper';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { getAssociatedObjectsCardFromQueryCache, updateCardInQueryCache } from '../helpers/queryCacheHelpers';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
export const useRemoveAssociationCacheUpdate = ({
  toObjectType,
  toObjectId,
  toObjectIsPrimary,
  updateQuery
}) => {
  return useCallback(() => updateQuery(cachedCardsData => {
    // todo: update this as well to work with custom object associations (can just use objectTypeId by then)
    const toObjectTypeId = normalizeTypeId(toObjectType);
    const associationCard = getAssociatedObjectsCardFromQueryCache(cachedCardsData, card => card.configuration.associationDefinitions[0].toObjectTypeId === toObjectTypeId);
    if (!associationCard) {
      return cachedCardsData;
    }
    const edgeUpdates = toObjectIsPrimary ? {
      primaryEdge: null
    } : {
      secondaryEdges: associationCard.associatedObjects && associationCard.associatedObjects.secondaryEdges.filter(edge => edge.node.id !== toObjectId)
    };
    const associatedObjectEdges = getAssociatedObjectEdges(associationCard.associatedObjects);
    const updatedAssociationCard = Object.assign({}, associationCard, {
      associatedObjects: Object.assign({}, associationCard.associatedObjects, edgeUpdates, {
        edges: associatedObjectEdges.filter(edge => edge.node.id !== toObjectId)
      }),
      count: Object.assign({}, associationCard.count, {
        edges: associationCard.count && associationCard.count.edges.filter(edge => edge.node.id !== toObjectId)
      })
    });
    return updateCardInQueryCache(cachedCardsData, updatedAssociationCard);
  }), [toObjectType, toObjectId, toObjectIsPrimary, updateQuery]);
};

/**
 * Create callback to remove an association between two objects via the
 * deleteCrmObjectAssociationBySpecMutation GraphQL mutation
 *
 * @example
 *
 * function ContactChicklet({ objectId, objectType }) {
 *
 *   ...
 *
 *   const handleRemoveAssociation = useDeleteCrmObjectAssociationBySpecMutation({
 *     // need to coerce objectId to number if it isn't one already, or the cache updater won't
 *     // be able to find it in the existing cache
 *     fromObjectId: +objectId,
 *     toObjectId: contactId,
 *     fromObjectType: objectType,
 *     toObjectType: CONTACT,
 *   });
 *
 *   ...
 *
 *   return (
 *     ...
 *      <UIButton onClick={handleRemoveAssociation}>
 *         Remove Contact Association
 *      </UIButton>
 *     ...
 *   )
 * }
 *
 * @param {Object}                                        options
 * @param {ObjectType}                                    options.fromObjectType      the type of object to disassociate from, usually the subject in a record page
 * @param {Number}                                        options.fromObjectId        the ID of the object to disassociate from, usually the subject in a record page
 * @param {ObjectType}                                    options.toObjectType        the type of the object to be disassociated from the "fromObject", i.e. the type of the object in a sidebar chicklet
 * @param {Number}                                        options.toObjectId          the ID of the object to be disassociated from the "fromObject", i.e. the ID of the object in a sidebar chicklet
 * @param {Boolean}                                       options.toObjectIsPrimary   whether the toObject is primary
 * @param {Function}                                      options.onSuccess           callback for when the association request is queued on the server
 * @param {Function}                                      options.onError             callback for when the association request fails
 *
 * @return {Function} handleRemoveAssociation
 */
export const useDeleteCrmObjectAssociationBySpecMutation = ({
  associationSpecs,
  fromObjectId,
  fromObjectType,
  toObjectId,
  toObjectType,
  toObjectIsPrimary = false,
  preventRefresh = false,
  type = 'REMOVE_ASSOCIATION',
  onSuccess,
  onError,
  updateQuery,
  onAssociationUpdate
}) => {
  const [removeAssociationBySpecMutation] = useMutation(DeleteCrmObjectAssociationBySpecMutation);
  const toObjectTypeId = normalizeTypeId(toObjectType);
  const fromObjectTypeId = normalizeTypeId(fromObjectType);
  const sendUpdateAssociationsMessage = useSendCrmMessageTopic(TOPIC_NAMES.UPDATE_ASSOCIATIONS);
  const handleSuccess = useCallback(() => {
    if (typeof onSuccess === 'function') onSuccess();
    onAssociationUpdate({
      objectId: +fromObjectId,
      objectType: fromObjectType,
      associationObjectType: toObjectType
    });
    if (!preventRefresh) {
      sendUpdateAssociationsMessage({
        objectId: `${fromObjectId}`,
        objectTypeId: fromObjectTypeId,
        toObjectTypeId,
        actionType: type
      });
    }
  }, [onSuccess, onAssociationUpdate, fromObjectType, fromObjectId, toObjectType, sendUpdateAssociationsMessage, toObjectTypeId, preventRefresh, type, fromObjectTypeId]);
  const handleError = useCallback(error => {
    if (typeof onError === 'function') onError(error);
  }, [onError]);
  const handleUpdateCache = useRemoveAssociationCacheUpdate({
    toObjectType,
    toObjectId,
    toObjectIsPrimary,
    updateQuery
  });
  const handleRemoveAssociation = useCallback(({
    onSuccessDispatch,
    onFailureDispatch
  } = {}) => {
    removeAssociationBySpecMutation({
      variables: {
        removeAssociationInput: {
          fromObjectTypeAndId: {
            objectTypeId: fromObjectTypeId,
            objectId: fromObjectId
          },
          toObjectTypeAndId: {
            objectTypeId: toObjectTypeId,
            objectId: toObjectId
          },
          associationSpecs
        }
      },
      update: (_cache, {
        data
      }) => {
        const errors = getIn(['updateResponse', 'userErrors'], data) || [];
        if (errors.length !== 0) {
          handleError(errors);
          if (typeof onFailureDispatch === 'function') onFailureDispatch();
        } else {
          if (updateQuery) {
            handleUpdateCache();
          }
          handleSuccess();
          if (typeof onSuccessDispatch === 'function') onSuccessDispatch();
        }
      }
    }).catch(error => {
      if (typeof onFailureDispatch === 'function') onFailureDispatch();
      const messageKey = error && 'message' in error && error.message.toLowerCase().includes('unauthorized') ? 'universalSidebar.alertHelpers.addMutationResultAlert.mutationUnauthorized' : undefined;
      addMutationResultAlert({
        mutationFailed: true,
        messageKey
      });
    });
  }, [removeAssociationBySpecMutation, fromObjectTypeId, fromObjectId, toObjectTypeId, toObjectId, associationSpecs, handleUpdateCache, handleSuccess, handleError, updateQuery]);
  return handleRemoveAssociation;
};
export default useDeleteCrmObjectAssociationBySpecMutation;