'use es6';

import { ContentBlock, EditorState, genKey, Modifier, SelectionState } from 'draft-js';
import { Map as ImmutableMap } from 'immutable';
import { STYLE_DELINEATOR } from '../lib/colors';
import { LINK_ENTITY_TYPE } from '../lib/constants';
const prependStyleToCharacter = (character, style) => {
  const combinedStyles = style.union(character.getStyle());
  return character.set('style', combinedStyles);
};
export default ((editorState, contentState, {
  ignoreLinkColorStyle = false
} = {}) => {
  let didInsertDummyBlock = false;
  if (contentState.getBlockMap().size === 1 && contentState.getBlockMap().first().getType() === 'atomic') {
    // this is a hack to get around an issue with Draft:
    // https://github.com/facebook/draft-js/issues/1511
    const bufferBlockKey = genKey();
    const newBlockMap = contentState.getBlockMap().set(bufferBlockKey, new ContentBlock({
      key: bufferBlockKey,
      data: ImmutableMap({
        isHackPlaceholder: true
      })
    }));
    contentState = contentState.merge({
      blockMap: newBlockMap
    });
    didInsertDummyBlock = true;
  }
  const currentInlineStyle = editorState.getCurrentInlineStyle();
  const nonColorStyles = currentInlineStyle.filter(style => !style.includes(`color${STYLE_DELINEATOR}`));
  const newBlocks = contentState.getBlockMap().map(block => {
    const charactersWithCurrentStyle = block.getCharacterList().map(char => {
      const charEntity = char.getEntity();
      const ignoreColor = ignoreLinkColorStyle && charEntity && contentState.getEntity(charEntity).getType() === LINK_ENTITY_TYPE;
      return prependStyleToCharacter(char, ignoreColor ? nonColorStyles : currentInlineStyle);
    });
    return block.set('characterList', charactersWithCurrentStyle);
  });
  let updatedContentState = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), newBlocks);
  if (didInsertDummyBlock) {
    // remove the block added in our hack
    const updatedBufferBlock = updatedContentState.getBlockMap().find(block => block.getData().get('isHackPlaceholder'));
    // Modifier.replaceWithFragment re-generates block keys for inserted
    // blocks, so we can't just store the original
    const blockKeyToRemove = updatedBufferBlock.getKey();
    updatedContentState = Modifier.removeRange(updatedContentState, SelectionState.createEmpty(blockKeyToRemove), 'backward');
  }
  return EditorState.push(editorState, updatedContentState, 'insert-fragment');
});