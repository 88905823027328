import { isLegacyObjectType } from 'customer-data-objects/types/LegacyObjectType';
import TimelineFiltersStore from '../stores/TimelineFiltersStore';
const TimelineTextFilterDependency = {
  stores: [TimelineFiltersStore],
  deref: ({
    objectType,
    objectTypeId
  }) => {
    const isCrmObject = !isLegacyObjectType(objectType);
    const objectTypeOrTypeId = isCrmObject && objectTypeId ? objectTypeId : objectType;
    const text = TimelineFiltersStore.get(objectTypeOrTypeId) && TimelineFiltersStore.get(objectTypeOrTypeId).get('searchText');
    return text || '';
  }
};
export default TimelineTextFilterDependency;