import PropTypes from 'prop-types';
import { Map as ImmutableMap, List } from 'immutable';
import { listOf, mapContains } from 'react-immutable-proptypes';
const TimelineEventsPropType = PropTypes.oneOfType([
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
PropTypes.instanceOf(List), listOf(PropTypes.oneOfType([mapContains({
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  etype: PropTypes.string,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  eventData: PropTypes.instanceOf(ImmutableMap),
  id: PropTypes.string.isRequired,
  orderingId: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired
}), mapContains({
  label: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  text: PropTypes.string
}), mapContains({
  isFuture: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
})]))]);
export default TimelineEventsPropType;