import { useCallback, useEffect, useSyncExternalStore } from 'react';
import debug from 'growth-payments-core/debug/console';
import { deleteQueryStringParameter, getQueryStringParameter, ReplaceStateEvent, REPLACE_STATE, setQueryStringParameter, getParentWindow } from './utils';

/**
 * A react useState using a query string parameter as a backend. This is useful for storing
 * data that you want to persist across page loads, or to set state from an external link.
 *
 * This supports arbitrary json values, but is not intended to be used for large amounts of data.
 */
export const useQueryStringState = (key, initialValue) => {
  const fetchFromQS = useCallback(() => {
    if (key) {
      try {
        const item = getQueryStringParameter(key);
        return item ? typeof item === 'string' ? item : JSON.parse(item) : initialValue;
      } catch (e) {
        debug.error(e);
      }
    }
    return initialValue;
  }, [key, initialValue]);
  const storedValue = useSyncExternalStore(change => {
    const parentWindow = getParentWindow();
    if (!parentWindow) {
      debug.error('Parent window is not available');
      return () => null;
    }
    parentWindow.addEventListener(REPLACE_STATE, change);
    return () => {
      parentWindow.removeEventListener(REPLACE_STATE, change);
    };
  }, fetchFromQS);

  // If the key changes, fetch the query string value from our new key.
  useEffect(() => {
    getParentWindow().dispatchEvent(new ReplaceStateEvent());
  }, [key]);
  const setValue = useCallback(value => {
    if (key) {
      try {
        const valueToStore = value instanceof Function ? value(fetchFromQS()) : value;
        if (valueToStore === undefined) {
          deleteQueryStringParameter(key);
        } else {
          setQueryStringParameter(key, typeof valueToStore === 'string' ? valueToStore : JSON.stringify(valueToStore));
        }
      } catch (e) {
        debug.error(e);
      }
    }
  }, [key, fetchFromQS]);
  return [storedValue, setValue];
};