'use es6';

import { Set as ImmutableSet } from 'immutable';
import { getRequiredProperties } from '../crmObjectTypes/ObjectType';
import get from 'transmute/get';
export const getPropertiesToFetch = (objectType, view) => {
  // Required properties based on object type. This is a noop for legacy types
  // (e.g. CONTACT), but CrmObjectTypeRecord support is added in crm-index-ui

  const requiredProperties = getRequiredProperties(objectType);
  if (requiredProperties == null) {
    return null;
  }
  const columns = get('columns', view) || [];
  return ImmutableSet([...requiredProperties, ...columns.map(column => column.get('name'))]).toArray();
};