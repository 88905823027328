// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import uniqueActionTypes from 'crm_data/flux/uniqueActionTypes';
const GridUIActionTypes = uniqueActionTypes({
  GRID_NUMBER_EDITABLE_UPDATED: 'GRID_NUMBER_EDITABLE_UPDATED',
  GRID_CHECKED: 'GRID_CHECKED',
  GRID_ALL_SELECTED: 'GRID_ALL_SELECTED',
  GRID_SEARCH_UPDATED: 'GRID_SEARCH_UPDATED',
  GRID_SEARCH_QUERY_UPDATED: 'GRID_SEARCH_QUERY_UPDATED',
  GRID_SELECTED_CLEARED: 'GRID_SELECTED_CLEARED',
  GRID_SHOWING_CHANGED: 'GRID_SHOWING_CHANGED',
  GRID_RESET: 'GRID_RESET',
  GRID_CLEANUP_SELECTED: 'GRID_CLEANUP_SELECTED',
  GRID_TEMPORARY_EXCLUDE_IDS: 'GRID_TEMPORARY_EXCLUDE_IDS',
  GRID_TEMPORARY_INCLUDE_ID: 'GRID_TEMPORARY_INCLUDE_ID',
  GRID_TEMPORARY_RESET: 'GRID_TEMPORARY_RESET',
  GRID_TEMPORARY_SET: 'GRID_TEMPORARY_SET',
  GRID_SAVED_FILTER_CHANGED: 'GRID_SAVED_FILTER_CHANGED',
  UPDATE_GRID_SEARCH_QUERIES: 'UPDATE_GRID_SEARCH_QUERIES'
});
export const GRID_NUMBER_EDITABLE_UPDATED = GridUIActionTypes.GRID_NUMBER_EDITABLE_UPDATED;
export const GRID_CHECKED = GridUIActionTypes.GRID_CHECKED;
export const GRID_ALL_SELECTED = GridUIActionTypes.GRID_ALL_SELECTED;
export const GRID_SEARCH_UPDATED = GridUIActionTypes.GRID_SEARCH_UPDATED;
export const GRID_SEARCH_QUERY_UPDATED = GridUIActionTypes.GRID_SEARCH_QUERY_UPDATED;
export const GRID_SELECTED_CLEARED = GridUIActionTypes.GRID_SELECTED_CLEARED;
export const GRID_SHOWING_CHANGED = GridUIActionTypes.GRID_SHOWING_CHANGED;
export const GRID_RESET = GridUIActionTypes.GRID_RESET;
export const GRID_CLEANUP_SELECTED = GridUIActionTypes.GRID_CLEANUP_SELECTED;
export const GRID_TEMPORARY_EXCLUDE_IDS = GridUIActionTypes.GRID_TEMPORARY_EXCLUDE_IDS;
export const GRID_TEMPORARY_INCLUDE_ID = GridUIActionTypes.GRID_TEMPORARY_INCLUDE_ID;
export const GRID_TEMPORARY_RESET = GridUIActionTypes.GRID_TEMPORARY_RESET;
export const GRID_TEMPORARY_SET = GridUIActionTypes.GRID_TEMPORARY_SET;
export const GRID_SAVED_FILTER_CHANGED = GridUIActionTypes.GRID_SAVED_FILTER_CHANGED;
export const UPDATE_GRID_SEARCH_QUERIES = GridUIActionTypes.UPDATE_GRID_SEARCH_QUERIES;

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}