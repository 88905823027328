import { useCallback } from 'react';
import getIn from 'transmute/getIn';
import { Map as ImmutableMap } from 'immutable';
import { useMutation } from '@apollo/client';
import { CREATE_SECONDARY_EMAIL, DELETE_SECONDARY_EMAIL, PROMOTE_SECONDARY_EMAIL, UPDATE_PROPERTIES, UPDATE_SECONDARY_EMAIL } from '../../queries';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import addMutationResultAlert from 'customer-data-sidebar/universal/alertHelpers/addMutationResultAlert';
import { getProperty } from 'customer-data-objects/model/ImmutableModel';
import { parseMultiEnumValue } from 'customer-data-property-utils/parseMultiEnumValue';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { usePropertiesCardContext } from '../components/PropertiesCardContext';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
const CONTACT_EMAIL_PROPERTY = 'email';
const CONTACT_SECONDARY_EMAILS_PROPERTY = 'hs_additional_emails';
const emailArrayToString = emailArray => emailArray.join(';');
const getResponseErrors = updateData => getIn(['data', 'updateResponse', 'userErrors'], updateData) || [];
export const usePropertyInputV2Actions = subject => {
  const {
    objectId,
    objectTypeId,
    propertySourceInfo,
    onObjectUpdate
  } = usePropertiesCardContext();

  // if source context not yet present, CRM_UI is safe fallback
  const sourceHeader = propertySourceInfo ? propertySourceInfo.propertySource : CRM_UI;
  const mutationOptions = {
    context: {
      hubHttpOptions: {
        headers: {
          'X-Properties-Source': sourceHeader
        }
      }
    }
  };
  const [updateAnyProperties] = useMutation(UPDATE_PROPERTIES, mutationOptions);
  const [updateSecondaryEmail] = useMutation(UPDATE_SECONDARY_EMAIL, mutationOptions);
  const [createSecondaryEmail] = useMutation(CREATE_SECONDARY_EMAIL, mutationOptions);
  const [deleteSecondaryEmail] = useMutation(DELETE_SECONDARY_EMAIL, mutationOptions);
  const [promoteSecondaryEmail] = useMutation(PROMOTE_SECONDARY_EMAIL, mutationOptions);
  const dispatchUpdateObjectAction = useSendCrmMessageTopic(TOPIC_NAMES.UPDATE_OBJECT);
  const handleObjectUpdate = useCallback(propertiesArray => {
    if (typeof onObjectUpdate === 'function') {
      const propertiesMap = propertiesArray.reduce((acc, {
        name,
        value
      }) => {
        acc[name] = value;
        return acc;
      }, {});
      onObjectUpdate({
        objectTypeId,
        objectId,
        properties: propertiesMap
      });
      dispatchUpdateObjectAction({
        objectTypeId,
        objectId: `${objectId}`,
        propertyNames: Object.keys(propertiesMap)
      });
    }
  }, [onObjectUpdate, objectTypeId, objectId, dispatchUpdateObjectAction]);
  const updateProperties = properties => {
    const gqlReadyProperties = properties.map((propertyValue, propertyName) => ({
      name: propertyName,
      value: propertyValue
    })).toArray();

    // This action must return the promise it calls to update the data so the
    // property inputs can react to success/failure
    return updateAnyProperties({
      variables: {
        propertyUpdatesInput: {
          objectType: objectTypeId,
          objectId,
          properties: gqlReadyProperties
        },
        propertyNames: properties.keySeq().toArray()
      },
      update: (_cache, newData) => {
        const errors = getResponseErrors(newData);
        if (errors.length) {
          addMutationResultAlert({
            mutationFailed: true
          });
        } else {
          handleObjectUpdate(gqlReadyProperties);
        }
      }
    });
  };
  const updateSecondaryEmailAction = (oldSecondaryEmailAddress, newSecondaryEmailAddress) => {
    // This action must return the promise it calls to update the data so the
    // property inputs can react to success/failure
    return updateSecondaryEmail({
      variables: {
        updateEmailInput: {
          // objectType is intentionally omitted because
          // these mutations only apply to contacts
          objectId,
          newSecondaryEmailAddress,
          oldSecondaryEmailAddress
        },
        propertyNames: [CONTACT_SECONDARY_EMAILS_PROPERTY]
      },
      update: (_cache, newData) => {
        const errors = getResponseErrors(newData);
        if (errors.length) {
          addMutationResultAlert({
            mutationFailed: true
          });
        } else {
          const previousSecondaryEmailsArray = parseMultiEnumValue(getProperty(subject, CONTACT_SECONDARY_EMAILS_PROPERTY));
          const secondaryEmails = emailArrayToString(previousSecondaryEmailsArray.filter(email => !!email).map(email => email === oldSecondaryEmailAddress ? newSecondaryEmailAddress : email));
          handleObjectUpdate([{
            name: CONTACT_SECONDARY_EMAILS_PROPERTY,
            value: secondaryEmails
          }]);
        }
      }
    });
  };
  const deleteSecondaryEmailAction = secondaryEmailAddress => {
    // This action must return the promise it calls to update the data so the
    // property inputs can react to success/failure
    return deleteSecondaryEmail({
      variables: {
        deleteEmailInput: {
          objectId,
          secondaryEmailAddress
        },
        propertyNames: [CONTACT_SECONDARY_EMAILS_PROPERTY]
      },
      update: (_cache, newData) => {
        const errors = getResponseErrors(newData);
        if (errors.length) {
          addMutationResultAlert({
            mutationFailed: true
          });
        } else {
          const previousSecondaryEmailsArray = parseMultiEnumValue(getProperty(subject, CONTACT_SECONDARY_EMAILS_PROPERTY));
          const secondaryEmails = emailArrayToString(previousSecondaryEmailsArray.filter(email => !!email && email !== secondaryEmailAddress));
          handleObjectUpdate([{
            name: CONTACT_SECONDARY_EMAILS_PROPERTY,
            value: secondaryEmails
          }]);
        }
      }
    });
  };
  const promoteSecondaryEmailAction = secondaryEmailAddress => {
    // This action must return the promise it calls to update the data so the
    // property inputs can react to success/failure
    return promoteSecondaryEmail({
      variables: {
        promoteEmailInput: {
          objectId,
          secondaryEmailAddress
        },
        propertyNames: [CONTACT_EMAIL_PROPERTY, CONTACT_SECONDARY_EMAILS_PROPERTY]
      },
      update: (_cache, newData) => {
        const errors = getResponseErrors(newData);
        if (errors.length) {
          addMutationResultAlert({
            mutationFailed: true
          });
        } else {
          const previousPrimaryEmail = getProperty(subject, CONTACT_EMAIL_PROPERTY);
          const previousSecondaryEmailsArray = parseMultiEnumValue(getProperty(subject, CONTACT_SECONDARY_EMAILS_PROPERTY));
          const primaryEmail = secondaryEmailAddress;
          const secondaryEmails = emailArrayToString(previousSecondaryEmailsArray.filter(email => !!email && email !== primaryEmail).concat([previousPrimaryEmail]));
          handleObjectUpdate([{
            name: CONTACT_EMAIL_PROPERTY,
            value: primaryEmail
          }, {
            name: CONTACT_SECONDARY_EMAILS_PROPERTY,
            value: secondaryEmails
          }]);
        }
      }
    });
  };
  const createSecondaryEmailAction = secondaryEmailAddress => {
    // This action must return the promise it calls to update the data so the
    // property inputs can react to success/failure
    return createSecondaryEmail({
      variables: {
        createEmailInput: {
          objectId,
          secondaryEmailAddress
        },
        propertyNames: [CONTACT_EMAIL_PROPERTY, CONTACT_SECONDARY_EMAILS_PROPERTY]
      },
      update: (_cache, newData) => {
        const errors = getResponseErrors(newData);
        if (errors.length) {
          addMutationResultAlert({
            mutationFailed: true
          });
        } else {
          const previousSecondaryEmailsArray = parseMultiEnumValue(getProperty(subject, CONTACT_SECONDARY_EMAILS_PROPERTY));
          const secondaryEmails = emailArrayToString(previousSecondaryEmailsArray.concat([secondaryEmailAddress]));
          handleObjectUpdate([{
            name: CONTACT_SECONDARY_EMAILS_PROPERTY,
            value: secondaryEmails
          }]);
        }
      }
    });
  };
  return {
    email: {
      addAdditionalEmail: createSecondaryEmailAction,
      deleteAdditionalEmail: deleteSecondaryEmailAction,
      makeEmailPrimary: promoteSecondaryEmailAction,
      updatePrimaryEmail: email => updateProperties(ImmutableMap({
        email
      })),
      updateAdditionalEmail: updateSecondaryEmailAction
    }
  };
};