'use es6';

let ZORSE_IS_LOADED = false;
const getIsZorseLoaded = () => {
  if (ZORSE_IS_LOADED) {
    return true;
  }
  ZORSE_IS_LOADED = document.documentElement.classList.contains('zorse');
  return ZORSE_IS_LOADED;
};
export function openDocument(documentUrl) {
  if (getIsZorseLoaded()) {
    // eslint-disable-next-line hubspot-dev/hubspot-is-special
    return window.hubspot.zorse.openHelpWidget({
      url: documentUrl
    });
  } else {
    return window.open(documentUrl, '_blank');
  }
}
export function openSlideshow() {
  if (getIsZorseLoaded()) {
    // eslint-disable-next-line hubspot-dev/hubspot-is-special
    window.hubspot.zorse.openHelpWidget({
      segments: 'zorse-options/slideshow'
    });
  }
}