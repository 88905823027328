import { TIMELINE_NAVIGATE, ADD_QUERY_PARAMS, REMOVE_QUERY_PARAMS } from 'crm-events-data';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { navigateToUrl, addQueryParams, removeQueryParamsWithoutReload } from './navigationUtils';
export const useTimelineNavigationActionHandler = () => {
  const history = useHistory();
  const handleNavigationAction = useCallback((actionType, data) => {
    switch (actionType) {
      case TIMELINE_NAVIGATE:
        navigateToUrl(data.href);
        return;
      case ADD_QUERY_PARAMS:
        addQueryParams(history, data.params, data.refresh);
        return;
      case REMOVE_QUERY_PARAMS:
        removeQueryParamsWithoutReload(history, data.params);
        return;
      default:
        return;
    }
  }, [history]);
  return {
    handleNavigationAction
  };
};