import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { eventCardsReducer } from './eventCards/eventCardsSlice';
import { timelineControlsReducer } from './timelineControls/timelineControlsSlice';
const getStoreName = () => {
  const matches = window.location.pathname.match(/contacts\/\d+\/(contact|company|deal|ticket|record\/\d+-\d+)?\/(\d+)/) || [];
  const rawObjectType = matches[1] || '';
  const objectType = rawObjectType.includes('record') ? rawObjectType.split('/')[1] : rawObjectType;
  const subjectId = matches[2];
  return window.location.hostname === 'local.hsappstatic.net' ? 'crm-timeline-tests' : `crm-timeline-${objectType}-${subjectId}`;
};
const appReducer = combineReducers({
  eventCards: eventCardsReducer,
  timelineControls: timelineControlsReducer
});
export const RESET_TIMELINE_STATE = 'RESET_TIMELINE_STATE';
const rootReducer = (state, action) => {
  if (action.type === RESET_TIMELINE_STATE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export const createStore = ({
  preloadedState
} = {}) => {
  return configureStore({
    devTools: {
      name: getStoreName()
    },
    preloadedState,
    reducer: rootReducer
  });
};