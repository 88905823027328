import { getFrom } from '../../utils/LocalStorage';
import { isRestorableLocationStateValid } from './isRestoreableLocationStateValid';
import { makeRestoreableLocationStateKey } from './makeRestoreableLocationStateKey';
export const safelyGetRestorableLocationState = (locationName, objectTypeId) => {
  const localStorageKey = makeRestoreableLocationStateKey(locationName, objectTypeId);
  const restorableLocationState = getFrom(localStorageKey);

  // Return an empty state if there is not one in the cache
  if (!isRestorableLocationStateValid(restorableLocationState)) {
    return {
      state: undefined
    };
  }
  return restorableLocationState;
};