'use es6';

import { EditorState, SelectionState, Modifier } from 'draft-js';
export default (({
  editorState,
  contentState,
  offset,
  length
}) => {
  const blockKey = editorState.getSelection().getStartKey();
  const replaceSelection = SelectionState.createEmpty(blockKey).merge({
    anchorOffset: offset,
    focusOffset: offset + length,
    isBackward: false,
    hasFocus: true
  });
  const updatedContentState = Modifier.replaceWithFragment(editorState.getCurrentContent(), replaceSelection, contentState.getBlockMap());
  return EditorState.push(editorState, updatedContentState, 'insert-fragment');
});