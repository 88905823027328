'use es6';

import isArray from 'transmute/isArray';

// Fires a callback if the event's keyCode matches a list of acceptable keys
//
//  - Accepts one key:
//   import {ENTER, ESC} from 'UIComponents/constants/KeyCodes'
//
//   <UITextInput
//     onKeyUp={@partial(onKeyAction, {keys: ENTER, cb: @onSave})}
//   />
//
//  - Or an array of keys:
//   keys = [ENTER, ESC]
//
//   <UITextInput
//     onKeyUp={@partial(onKeyAction, {keys, cb: @onSave})}
//   />
//
// - Can also handle different callbacks by passing in an array:
//
//  <UITextInput
//     onKeyUp={@partial(onKeyAction, [
//       {keys: ENTER, cb: @save}
//       {keys: ESC, cb: @close}
//     ])}
//  />
//
// - You can also pass a validation function
//   that must return true for the callback to run
//
//   onKeyUp={@partial(onKeyAction, {
//     keys: ENTER
//     cb: @handleConfirm
//     validationFn: @isValidInput
//   })}
//
export function onKeyAction(events, {
  keyCode
}) {
  if (!isArray(events)) {
    events = [events];
  }
  return events.forEach(event => {
    const {
      keys,
      cb,
      validationFn
    } = event;
    if (isArray(keys)) {
      if (!Array.from(keys).includes(keyCode)) {
        return;
      }
    } else if (keyCode !== keys) {
      return;
    }
    if (validationFn) {
      if (!validationFn()) {
        return;
      }
    }
    cb();
  });
}