'use es6';

import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
const MAX_SHARD_NUMBER = 50;
const getPortalShard = () => {
  const shardNumber = Math.floor(PortalIdParser.get() % MAX_SHARD_NUMBER / 10);
  return `${shardNumber}0`;
};
export const isDefaultHubSpotHostedUrl = url => url.startsWith('https://videos.hubspot.com/');
export const buildShardedVideoShareUrl = (uuid, {
  appendPortalId = false
} = {}) => {
  const domainBase = enviro.isQa() ? 'videos.hubspotusercontentqa' : 'videos.hubspotusercontent';
  const queryString = appendPortalId ? `?portalId=${PortalIdParser.get()}` : '';
  return enviro.ifHublet({
    isNa1: () => {
      return `https://${domainBase}${getPortalShard()}.net/watch/${uuid}${queryString}`;
    },
    isNonNa1: () => {
      return `https://${domainBase}-${enviro.getHublet()}.net/watch/${uuid}${queryString}`;
    }
  });
};
export default ((sharing_page, customId) => {
  if (!sharing_page.includes('?')) {
    sharing_page = `${sharing_page}?`;
  }
  let queryString = `portalId=${PortalIdParser.get()}`;
  if (customId) {
    queryString = queryString.concat(`&vycustom_id=${customId}`);
  }
  return `${sharing_page}${queryString}`;
});