import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["first_name", "last_name", "user_id"];
import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { List } from 'immutable';
import { ObjectTypeIds } from '../constants/CRMObjectConstants';
import { registerQuery } from 'data-fetching-client';
export const getRegisteredDeals = dealId => {
  return http.get(`deal-registration/v1/deal-registrations/by-deal-id/${dealId}`, {
    portalId: PortalIdParser.get(),
    query: {
      limit: 100,
      offset: 0
    }
  }).then(response => List(response.results), () => List());
};
export const updateRegisteredDealType = (dealRegObjectId, isHubSpotReferred) => {
  return http.put(`deal-registration/v1/properties/${ObjectTypeIds.DEAL_REGISTRATION}/${dealRegObjectId}`, {
    data: [{
      name: 'hs_referred_to_partner_by_hubspot',
      value: `${isHubSpotReferred}`
    }]
  });
};
export const getPermissions = dealId => {
  return http.get(`deal-registration/v1/deal-registrations/authorized-actions/${dealId}`);
};
export const getUsers = ({
  limit = 100,
  mustMatchUserIds = [],
  searchQuery = '',
  hubId
}) => http.put('deal-registration/v1/users-search/search', {
  data: {
    searchQuery,
    offset: 0,
    limit,
    hubId,
    mustMatchUserIds
  }
}).then(({
  results
}) => {
  return results.map(_ref => {
    let {
        first_name,
        last_name,
        user_id
      } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    return Object.assign({}, rest, {
      firstName: first_name,
      lastName: last_name,
      userId: user_id
    });
  });
}).catch(() => []);
export const getDomainOwnership = (domain, partnerPortalId) => http.get('deal-registration/v1/domain-ownership', {
  query: {
    domain,
    partnerPortalId
  }
});
export const GET_HUBSPOTTER_OUT_OF_OFFICE = registerQuery({
  fieldName: 'user_ooo',
  args: ['userIds'],
  fetcher: ({
    userIds
  }) => {
    return http.get('deal-registration/v1/deal-team/out-of-office', {
      query: {
        userId: userIds
      }
    });
  }
});