'use es6';

import { USER_SETTINGS_SAVED, USER_SETTINGS_SAVE_FAILED } from 'crm_data/actions/ActionTypes';
import invariant from 'react-utils/invariant';
import { transact } from 'crm_data/flux/transact';
import * as UserSettingsAPI from 'crm_data/settings/UserSettingsAPI';
import UserSettingsStore from 'crm_data/settings/UserSettingsStore';
import { dispatchQueue } from 'crm_data/dispatch/Dispatch';
const _enforceKey = key => {
  invariant(typeof key === 'string', 'UserSettingsActions: Argument "key" must be of type String (got `%s`)', typeof key);
};
export function saveUserSetting(key, value) {
  _enforceKey(key);
  const oldValue = UserSettingsStore.get(key);
  return transact({
    operation() {
      return UserSettingsAPI.saveUserSetting(key, value);
    },
    commit: [USER_SETTINGS_SAVED, {
      key,
      value
    }],
    rollback: [USER_SETTINGS_SAVE_FAILED, {
      key,
      value: oldValue
    }]
  });
}
export function queueSaveUserSetting(key, value) {
  const oldValue = UserSettingsStore.get(key);
  return UserSettingsAPI.saveUserSetting(key, value).then(() => dispatchQueue(USER_SETTINGS_SAVED, {
    key,
    value
  })).catch(() => dispatchQueue(USER_SETTINGS_SAVE_FAILED, {
    key,
    value: oldValue
  }));
}