import { UnderwritingStatus, EligibilityStatus, ProcessorType } from './types';
import { useEligibilityBinding } from './eligibilityBinding';
import { useMemo } from 'react';

// This enum should be in the order in which the steps progress
export let KYCStatus;

/**
 *  Here is the underwritingStatus -> KYC status mapping
 *  ╔═════════════════════╦═════════════╦═══════════════════╦══════════════════╦═══════════════════╗
 *  ║ Underwriting Status ║ null        ║ IN_KYC            ║ IN_UNDERWRITING  ║ APPROVED/REJECTED ║
 *  ╠═════════════════════╬═════════════╬═══════════════════╬══════════════════╬═══════════════════╣
 *  ║ KYC Status          ║ NOT_STARTED ║ COMPLETED_HUBSPOT ║ COMPLETED_STRIPE ║ DONE              ║
 *  ╚═════════════════════╩═════════════╩═══════════════════╩══════════════════╩═══════════════════╝
 */
(function (KYCStatus) {
  KYCStatus[KYCStatus["NOT_STARTED"] = 0] = "NOT_STARTED";
  KYCStatus[KYCStatus["COMPLETED_HUBSPOT"] = 1] = "COMPLETED_HUBSPOT";
  KYCStatus[KYCStatus["COMPLETED_STRIPE"] = 2] = "COMPLETED_STRIPE";
  KYCStatus[KYCStatus["DONE"] = 3] = "DONE";
})(KYCStatus || (KYCStatus = {}));
export function calculateKYCStatus(underwritingStatus) {
  switch (underwritingStatus) {
    case UnderwritingStatus.IN_KYC:
      return KYCStatus.COMPLETED_HUBSPOT;
    case UnderwritingStatus.IN_UNDERWRITING:
      return KYCStatus.COMPLETED_STRIPE;
    case UnderwritingStatus.APPROVED:
    case UnderwritingStatus.REJECTED:
      return KYCStatus.DONE;
    default:
      return KYCStatus.NOT_STARTED;
  }
}
const KYCStatusStrings = {
  [KYCStatus.NOT_STARTED]: 'Not started',
  [KYCStatus.COMPLETED_HUBSPOT]: 'Completed HubSpot',
  [KYCStatus.COMPLETED_STRIPE]: 'Completed Stripe',
  [KYCStatus.DONE]: 'Done'
};
export function kycStatusString(status) {
  return typeof status === 'number' ? KYCStatusStrings[status] : String(status);
}
export function hasCompletedHubSpot(eligibility) {
  return calculateKYCStatus(eligibility.underwritingStatus) >= KYCStatus.COMPLETED_HUBSPOT && !!eligibility.isEnabled;
}
export function hasCompletedStripe(status) {
  return status !== undefined && status >= KYCStatus.COMPLETED_STRIPE;
}
export function hasCompletedUnderwritingReview(status) {
  return status !== undefined && status === KYCStatus.DONE;
}
export function shouldShowZeroState(status) {
  return status < KYCStatus.COMPLETED_STRIPE;
}
export function hasHSAccount(eligibility) {
  return eligibility.status === EligibilityStatus.ACCOUNT_EXISTS && !!eligibility.isEnabled;
}
export function isPaymentsEligible(eligibilityStatus) {
  return eligibilityStatus !== EligibilityStatus.INELIGIBLE;
}
export function useKYCStatus() {
  const [eligibility] = useEligibilityBinding();
  const eligibilityExists = Boolean(eligibility);
  const underwritingStatus = eligibility && eligibility.underwritingStatus;
  return useMemo(() => {
    if (!eligibilityExists) {
      return undefined;
    }
    return calculateKYCStatus(underwritingStatus);
  }, [eligibilityExists, underwritingStatus]);
}
export const getSelectedProcessor = eligibility => eligibility ? eligibility.processorType : ProcessorType.NONE;