import { useMemo, useRef } from 'react';
import Raven from 'raven-js';
import deepEquals from './internal/utils/deepEquals';
function mapCardsInContainer(container, transformCard) {
  return Object.assign({}, container, {
    contents: container.contents.map(cardOrContainer => {
      if (cardOrContainer.type === 'CONTAINER') {
        return mapCardsInContainer(cardOrContainer, transformCard);
      }
      return transformCard(cardOrContainer);
    })
  });
}
function useStableLocationData(data) {
  const cachedCardsById = useRef({});
  return useMemo(() => {
    if (!data) {
      return data;
    }
    return Object.assign({}, data, {
      container: mapCardsInContainer(data.container, card => {
        const prevCard = cachedCardsById.current[card.definition.cardId];
        if (!prevCard || !deepEquals(prevCard, card)) {
          cachedCardsById.current[card.definition.cardId] = card;
        }
        return cachedCardsById.current[card.definition.cardId];
      })
    });
  }, [data]);
}
export function useCrmLocationAdapter(adapter, data, transformer) {
  const transformedLocationData = useMemo(() => {
    if (!data) {
      return undefined;
    }
    let adaptedData = undefined;
    try {
      adaptedData = adapter(data);
    } catch (e) {
      adaptedData = undefined;
      console.error(e);
      Raven.captureException(new Error('useCrmLocationAdapter error'), {
        extra: {
          originalError: e,
          queryData: data
        }
      });
    }
    if (typeof transformer === 'function') {
      return transformer(adaptedData);
    }
    return adaptedData;
  }, [data, transformer, adapter]);

  // This will make sure any cards which haven't been modified are using the same reference
  const stableLocationData = useStableLocationData(transformedLocationData);
  return stableLocationData;
}