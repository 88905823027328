import Loadable from 'UIComponents/decorators/Loadable';
import EmptyComponent from './EmptyComponent';
export const AsyncFileManager = Loadable({
  loader: () => import( /* webpackChunkName: "file-manager" */'./FileManagerEntry').then(entry => entry.default(),
  // resolving because this ends up getting caught by the
  // ErrorBoundary
  // which makes for a better user experience. however, the error
  // that propagates is one related to React trying to render
  // the resolved result, i.e., the Error instance.
  () => new Error('failed to load the fileManagerEntry')),
  LoadingComponent: EmptyComponent
});