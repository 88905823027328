import { LEGACY_SIDEBAR_CARD_TYPES } from '../../constants/LegacySidebarCardType';

// Legacy card should not have config type or query fragments
// Extra props on legacy card
// Legacy Sidebar Card Metadata
export const BET_CUSTOMER_SUMMARY_METADATA = {
  cardType: 'BET_CUSTOMER_SUMMARY',
  legacySidebarCardType: LEGACY_SIDEBAR_CARD_TYPES.BetCustomerSummaryCard,
  hasLegacyExtraProps: true,
  renderAsLegacyCardInCrmLocationResolver: false
};