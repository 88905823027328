import { Metrics } from './Metrics';
class ObservedPerformance {
  constructor() {
    this.isEnabled = 'PerformanceObserver' in window && 'PerformanceLongTaskTiming' in window;
    this.totalResourceDuration = 0;
    this.totalLongTaskDuration = 0;
    this.initResourceObserver();
    this.initLongTaskObserver();
  }
  logMetrics() {
    if (this.shouldLogMetrics) {
      Metrics.timer('total_long_task_duration').update(this.totalLongTaskDuration);
      Metrics.timer('total_resource_duration').update(this.totalResourceDuration);
      this.stopObserving();
    }
  }
  get shouldLogMetrics() {
    return this.isEnabled && document.visibilityState === 'visible';
  }
  initResourceObserver() {
    if (this.isEnabled) {
      try {
        var _this$resourceObserve;
        this.resourceObserver = new PerformanceObserver(list => {
          const resourceEntries = list.getEntriesByType('resource');
          for (const {
            duration
          } of resourceEntries) {
            this.totalResourceDuration += duration;
          }
        });

        // Enable observers to access entries from before the observer creation with buffered flag.
        (_this$resourceObserve = this.resourceObserver) === null || _this$resourceObserve === void 0 || _this$resourceObserve.observe({
          type: 'resource',
          buffered: true
        });
      } catch (error) {
        console.error('Failed to initialize resource observer:', error);
      }
    }
  }
  initLongTaskObserver() {
    try {
      if (this.isEnabled) {
        var _this$longTaskObserve;
        this.longTaskObserver = new PerformanceObserver(list => {
          const longTaskEntries = list.getEntriesByType('longtask');
          for (const {
            duration
          } of longTaskEntries) {
            this.totalLongTaskDuration += duration;
          }
        });
        (_this$longTaskObserve = this.longTaskObserver) === null || _this$longTaskObserve === void 0 || _this$longTaskObserve.observe({
          type: 'longtask',
          buffered: true
        });
      }
    } catch (error) {
      console.error('Failed to initialize long task observer:', error);
    }
  }
  stopObserving() {
    try {
      var _this$resourceObserve2, _this$longTaskObserve2;
      (_this$resourceObserve2 = this.resourceObserver) === null || _this$resourceObserve2 === void 0 || _this$resourceObserve2.disconnect();
      (_this$longTaskObserve2 = this.longTaskObserver) === null || _this$longTaskObserve2 === void 0 || _this$longTaskObserve2.disconnect();
    } catch (error) {
      console.error('Failed to disconnect observers:', error);
    }
  }
}
export const observedPerformance = new ObservedPerformance();