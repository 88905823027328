'use es6';

import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
import { PROPERTIES_BY_ENGAGEMENT_TYPE, COMMON_PROPERTIES_WITH_PATHS, ARRAY_OF_NUMBERS } from 'crm_data/engagements/api/EngagementProperties';
import { fromJS } from 'immutable';
const convertToNumber = propertyValue => isNaN(propertyValue) ? propertyValue : +propertyValue;
const formatPropertyValue = (type, propertyValue) => {
  if (type === Number) {
    return convertToNumber(propertyValue);
  }
  if (type === Boolean) {
    if (typeof propertyValue === 'boolean') return propertyValue;
    return ['true', 'false'].includes(propertyValue) ? propertyValue === 'true' : propertyValue;
  }
  if (type === ARRAY_OF_NUMBERS && propertyValue.length) {
    return propertyValue.map(convertToNumber);
  }
  return propertyValue;
};
export const createEngagementFromProperties = ({
  properties,
  type
}) => {
  if (!properties) {
    return EngagementRecord();
  }
  const engagementType = type || (properties.hs_engagement_type ? properties.hs_engagement_type.value : undefined);
  if (!engagementType) {
    return EngagementRecord();
  }
  const propertiesForObjectType = PROPERTIES_BY_ENGAGEMENT_TYPE.get(engagementType);
  const applicablePropertyDefinitions = [...propertiesForObjectType, ...COMMON_PROPERTIES_WITH_PATHS];
  const engagement = applicablePropertyDefinitions.reduce((acc, propertyToFetch) => {
    // Properties can be derived from other properties.
    // - sourcePropertyNameV2 is the name of a different property
    //   that this property may be derived from
    // - propertyNameV2 is the name of the hubspot property
    //   when its value is either derived from sourcePropertyNameV2
    //   or pre-existing

    const sourcePropertyNameV2 = propertyToFetch.sourcePropertyNameV2;
    const propertyNameV2 = propertyToFetch.propertyNameV2;
    const propertyRawValueObj = properties[sourcePropertyNameV2 || propertyNameV2];
    if (propertyRawValueObj === undefined || propertyRawValueObj === null) {
      return acc;
    }
    const propertyRawValue = propertyRawValueObj.value;

    // many properties must be parsed to fit v1 format, or to be
    // derived from another property
    const propertyValue = propertyToFetch.v2ToV1Parser ? propertyToFetch.v2ToV1Parser(propertyRawValue) : propertyRawValue;
    if (propertyValue === undefined || propertyValue === null) {
      return acc;
    }
    return acc.setIn(propertyToFetch.propertyPathV1, fromJS(formatPropertyValue(propertyToFetch.type, propertyValue)));
  }, EngagementRecord());
  return type ? engagement.setIn(['engagement', 'type'], type) : engagement;
};