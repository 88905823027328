import { useCallback } from 'react';
export function useExternalUrlAction({
  href
}) {
  const url = /^https?:\/\//.test(href) ? href : `https://${href}`;
  const callback = useCallback(() => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }, [url]);
  return {
    callback
  };
}