'use es6';

import I18n from 'I18n';
import { Map as ImmutableMap } from 'immutable';
import memoize from 'transmute/memoize';
import unescapedText from 'I18n/utils/unescapedText';
const CONTACT = 'contact';
const COMPANY = 'company';
const DEAL = 'deal';
const TICKET = 'ticket';
const QUOTE = 'quote';
const ENGAGEMENT = 'engagement';
const LINE_ITEM = 'line_item';
const UNKNOWN = 'unknown';
const CONTACT_ID = '0-1';
const COMPANY_ID = '0-2';
const DEAL_ID = '0-3';
const TICKET_ID = '0-5';
const QUOTE_ID = '0-14';
const ENGAGEMENT_ID = '0-4';
const LINE_ITEM_ID = '0-8';
const UNKNOWN_ID = '0-0';
export const ToObjectType = {
  [UNKNOWN_ID]: UNKNOWN,
  [CONTACT_ID]: CONTACT,
  [COMPANY_ID]: COMPANY,
  [DEAL_ID]: DEAL,
  [TICKET_ID]: TICKET,
  [QUOTE_ID]: QUOTE,
  [ENGAGEMENT_ID]: ENGAGEMENT,
  [LINE_ITEM_ID]: LINE_ITEM
};
export const toI18nText = name => I18n.text(`draftPlugins.mergeTags.missingMergeTags.${name}`);
const _flattenPropertyList = propertyLists => {
  return propertyLists.reduce((propertyMap, propertyListData) => propertyListData.get('properties').reduce((updatedPropertyMap, property) => {
    const {
      name,
      label
    } = property.toObject();
    return updatedPropertyMap.set(name, label);
  }, propertyMap), ImmutableMap());
};
export const flattenPropertyList = memoize(_flattenPropertyList);
export const escapePlaceholderProperty = property => property.replace(/\s/g, '_');
export const unescapePlaceholderProperty = property => property.replace(/_/g, ' ');
export const cleanPlaceholderProperty = token => token.replace(/[_{}&<>"`;()\\.,]/g, '');
const CUSTOM_ID_REGEX = /(0-\d+)/;
export const isCustomObjectId = prefix => {
  return CUSTOM_ID_REGEX.test(prefix);
};
export const getPrefixId = customObjectPrefix => {
  return CUSTOM_ID_REGEX.exec(customObjectPrefix)[0];
};
export const formatMergeTag = (prefix, property, properties) => {
  let translatablePrefix = prefix;
  if (isCustomObjectId(prefix)) {
    const prefixId = getPrefixId(prefix);
    translatablePrefix = ToObjectType[prefixId];
  }
  const propertyType = `${prefix}Properties`;
  const propertiesForType = properties.get(propertyType);
  const canInferPropertyType = !!propertiesForType;
  if (canInferPropertyType) {
    // if no property for type we assume it's part of a placeholder token
    const propertyLabel = propertiesForType.get(property) || unescapePlaceholderProperty(property);
    return unescapedText('draftPlugins.mergeTagGroupPlugin.tagLabel', {
      propertyType: toI18nText(translatablePrefix),
      propertyLabel
    });
  } else {
    return toI18nText('unknown');
  }
};
export const resolveLabelFromProperties = (prefix, property, properties) => {
  const propertyType = `${prefix}Properties`;
  const propertiesForType = properties.get(propertyType);
  const canInferPropertyType = !!propertiesForType;
  if (canInferPropertyType) {
    return propertiesForType.get(property);
  }
  return toI18nText('missingLabel');
};