'use es6';

import { Seq, Record } from 'immutable';
import ThumbnailUrlConfig from 'customer-data-email/schema/attachments/ThumbnailUrlConfig';
import { getFileUploadOptions } from 'customer-data-communicator/lib/attachments/FileUploadOptions';
const emptyValidators = {
  file: () => false,
  all: () => Seq()
};
export default Record({
  GenericPluginGroup: null,
  AttachmentPlugin: null,
  FileManagerAttachmentPlugin: null,
  fileOptions: getFileUploadOptions(),
  thumbnailUrlConfig: ThumbnailUrlConfig(),
  validators: emptyValidators,
  onOpenPopover: () => {}
}, 'InteractionWithValidatedAttachmentsComponentConfig');