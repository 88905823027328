import EngagementHistoryStore from '../../engagementHistory/stores/EngagementHistoryStore';
import PropTypes from 'prop-types';
import { getId } from 'customer-data-objects/model/ImmutableModel';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { isEmpty, isResolved } from 'crm_data/flux/LoadingStatus';
import IsEmailBeingRepliedToDependency from '../../email/dependencies/IsEmailBeingRepliedToDependency';
const ShouldHighlightEngagementDependency = {
  propTypes: {
    engagement: PropTypes.instanceOf(EngagementRecord),
    shouldHighlight: PropTypes.bool
  },
  stores: [EngagementHistoryStore, ...IsEmailBeingRepliedToDependency.stores],
  deref({
    engagement,
    shouldHighlight = false,
    isEmailReplyWrapper = false,
    isViewingThread = false,
    isParentEngagement = false
  }) {
    if (!isResolved(engagement)) {
      return false;
    }
    const currentEngagementHistory = EngagementHistoryStore.get();
    if (isEmpty(currentEngagementHistory)) {
      const isEmailBeingRepliedTo = IsEmailBeingRepliedToDependency.deref({
        engagement
      });
      if (isEmailBeingRepliedTo) {
        const isEmailContainerInThreadView = isParentEngagement && isViewingThread;
        const isSalesEmailInTimeline = !isParentEngagement && !isViewingThread;
        if (isEmailContainerInThreadView || isSalesEmailInTimeline) {
          return false;
        }
      }
      if (isEmailReplyWrapper) {
        return !isViewingThread && isEmailBeingRepliedTo;
      }
      return shouldHighlight || isEmailBeingRepliedTo;
    }
    return getId(engagement) === currentEngagementHistory.get('objectId');
  }
};
export default ShouldHighlightEngagementDependency;