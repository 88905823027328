import SampleEventFactoryInterface from './SampleEventFactoryInterface';
import { CALL } from 'customer-data-objects/engagement/EngagementTypes';
import { EVENT_ENGAGEMENT } from '../../timeline/constants/TimelineConstants';
const makeCallLogSampleTimelineEvent = SampleEventFactoryInterface({
  requiredData: {
    eventType: EVENT_ENGAGEMENT,
    itemType: CALL
  },
  eventDefaults: {
    durationMilliseconds: 0,
    body: ''
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(callData: any) => { metadata: { body: any; ... Remove this comment to see the full error message
  build(callData) {
    const {
      durationMilliseconds,
      body
    } = callData;
    const eventData = {
      metadata: {
        body,
        durationMilliseconds,
        externalId: null,
        fromNumber: null,
        recordingUrl: null,
        status: null,
        toNumber: null
      }
    };
    return eventData;
  }
});
export default makeCallLogSampleTimelineEvent;