// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { defineLazyKeyStore } from 'crm_data/store/LazyKeyStore';
import { TIMELINE_CHANGE_FILTERS_IN_STORE, TIMELINE_CHANGE_USER_AND_TEAM_FILTER, TIMELINE_CLEAR_FILTERS
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
import { List, Map as ImmutableMap } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { TIMELINE_FILTERS } from 'crm_data/actions/ActionNamespaces';
import { isLegacyObjectType } from 'customer-data-objects/types/LegacyObjectType';
import { isObjectTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
export default defineLazyKeyStore({
  namespace: TIMELINE_FILTERS,
  idIsValid: objectTypeOrId => isLegacyObjectType(objectTypeOrId) || isObjectTypeId(objectTypeOrId)
}).defineResponseTo(TIMELINE_CHANGE_USER_AND_TEAM_FILTER, (state, {
  objectType,
  requestPayload
}) => {
  return state.update(objectType, (filters = ImmutableMap()) => {
    return filters.merge(ImmutableMap({
      selectedOwners: requestPayload.get('selectedOwners'),
      selectedTeams: requestPayload.get('selectedTeams'),
      searchText: requestPayload.get('searchText')
    }));
  });
}).defineResponseTo(TIMELINE_CHANGE_FILTERS_IN_STORE, (state, {
  objectType,
  newFilters
}) => {
  return state.update(objectType, filters => {
    if (!filters) {
      return newFilters;
    }
    return filters.merge(ImmutableMap({
      selectedOwners: newFilters.get('selectedOwners'),
      selectedTeams: newFilters.get('selectedTeams'),
      searchText: newFilters.get('searchText')
    }));
  });
}).defineResponseTo(TIMELINE_CLEAR_FILTERS, (state, objectType) => {
  return state.update(objectType, filters => {
    return filters.merge(ImmutableMap({
      selectedOwners: List(),
      selectedTeams: List(),
      searchText: ''
    }));
  });
}).register();