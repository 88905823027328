/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useEffect } from 'react';
import { triggerGrowthModal } from 'growth-payments-core/api/modals';
import PortalIdParser from 'PortalIdParser';
const useTriggerModal = (modal, delay = 0, condition = true) => {
  const portalId = PortalIdParser.get();
  useEffect(() => {
    if (!condition || !portalId) {
      return;
    }
    const now = new Date().getTime();
    triggerGrowthModal({
      modal,
      portalId,
      activateOnTimestamp: now + delay
    });
  }, [condition, delay, modal, portalId]);
};
export default useTriggerModal;