import { LEGACY_SIDEBAR_CARD_TYPES } from '../../constants/LegacySidebarCardType';

// Legacy card should not have config type or query fragments
// Extra props on legacy card
// Legacy Sidebar Card Metadata
export const ZORSE_CONVERSATIONS_TAXONOMY_METADATA = {
  cardType: 'ZORSE_CONVERSATIONS_TAXONOMY',
  legacySidebarCardType: LEGACY_SIDEBAR_CARD_TYPES.ZorseConversationsTaxonomyCard,
  hasLegacyExtraProps: true,
  renderAsLegacyCardInCrmLocationResolver: false
};