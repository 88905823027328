'use es6';

import { Record, List } from 'immutable';
import { ObjectTypeFromIds } from 'customer-data-objects/constants/ObjectTypeIds';
import Raven from 'raven-js';
class CrmObjectTypeRecord extends Record(
// Adapted from: custom-object-settings/CustomObjectRecord
{
  id: undefined,
  objectTypeId: undefined,
  name: '',
  singularForm: '',
  pluralForm: '',
  metaType: undefined,
  metaTypeId: undefined,
  hasCustomProperties: true,
  hasDefaultProperties: true,
  hasPipelines: false,
  hasOwners: false,
  hasExternalObjectIds: false,
  restorable: true,
  indexedForFiltersAndReports: false,
  defaultSearchPropertyNames: List(),
  requiredProperties: List(),
  primaryDisplayLabelPropertyName: undefined,
  // must also be a required property
  secondaryDisplayLabelPropertyNames: List(),
  createDatePropertyName: 'hs_createdate',
  lastModifiedPropertyName: 'hs_lastmodifieddate',
  pipelineCloseDatePropertyName: 'hs_pipeline_closedate',
  pipelinePropertyName: 'hs_pipeline',
  pipelineStagePropertyName: 'hs_pipeline_stage',
  pipelineTimeToClosePropertyName: 'hs_pipeline_time_to_close',
  integrationAppId: undefined,
  ownerPortalId: undefined,
  janusGroup: undefined,
  scopeMappings: [],
  fullyQualifiedName: undefined,
  error: false
}, 'CrmObjectType') {
  isHubspotDefined() {
    return this.metaTypeId === 0;
  }
  isIntegrationDefined() {
    return this.metaTypeId === 1;
  }
  isPortalDefined() {
    return this.metaTypeId === 2;
  }
  isCustomObject() {
    return this.isPortalDefined() || this.isIntegrationDefined();
  }
}
class UnknownObjectType extends CrmObjectTypeRecord {
  get error() {
    return true;
  }
}
class LegacyCrmObjectType extends CrmObjectTypeRecord {}
function getRecordType(typeDef) {
  const metaTypeId = typeDef.metaTypeId;
  switch (metaTypeId) {
    case 0:
    case 1:
    case 2:
      return Object.prototype.hasOwnProperty.call(ObjectTypeFromIds, typeDef.objectTypeId) ? LegacyCrmObjectType : CrmObjectTypeRecord;
    default:
      Raven.captureException(new Error(`[CrmObjectTypeRecords] expected metaTypeId to be 0|1|2, got: ${metaTypeId}`), {
        extra: typeDef
      });
      return UnknownObjectType;
  }
}
function normalize(rawData) {
  const {
    defaultSearchPropertyNames,
    requiredProperties,
    secondaryDisplayLabelPropertyNames
  } = rawData;
  return Object.assign({}, rawData, {
    defaultSearchPropertyNames: List(defaultSearchPropertyNames),
    requiredProperties: List(requiredProperties),
    secondaryDisplayLabelPropertyNames: List(secondaryDisplayLabelPropertyNames)
  });
}
CrmObjectTypeRecord.fromJSON = rawData => {
  const normalizedData = normalize(rawData);
  const RecordType = getRecordType(rawData);
  return new RecordType(normalizedData);
};
export { normalize,
// Primary Record type that holds all data & functionality
CrmObjectTypeRecord,
// Nominal records for use in utils/protocols
LegacyCrmObjectType, UnknownObjectType };