import { getEditDefaultActionsData, getPreviewDefaultActionsData, getPrimaryDefaultActionsData, getRemoveDefaultActionsData } from '../default/getDefaultChickletHoverActions';
import { CONTACT_TYPE_ID, DEAL_TYPE_ID, PARTNER_CLIENT_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
const useActions = args => {
  const {
    associatedObjectsIds,
    fromObjectTypeId,
    primaryAssociationObjectId
  } = args;
  const primaryDefaultActionsData = getPrimaryDefaultActionsData(args);

  // If there is only one associated company and it's already primary
  // the Remove as Primary button shouldn't show
  const hideRemovePrimaryFromContact = !primaryDefaultActionsData || !!primaryAssociationObjectId && associatedObjectsIds.length === 1 && (CONTACT_TYPE_ID === fromObjectTypeId || TICKET_TYPE_ID === fromObjectTypeId || DEAL_TYPE_ID === fromObjectTypeId || PARTNER_CLIENT_TYPE_ID === fromObjectTypeId);
  return {
    PrimaryAction: getPreviewDefaultActionsData(args),
    SecondaryActions: [hideRemovePrimaryFromContact ? undefined : primaryDefaultActionsData, getEditDefaultActionsData(args), getRemoveDefaultActionsData(args)]
  };
};
export default useActions;