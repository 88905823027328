export const SHARED_DEAL_QUOTES = 'SHARED_DEAL_QUOTES';
export const SHARED_DEAL_CONTACTS = 'SHARED_DEAL_CONTACTS';
export const PROPERTIES_TO_DISPLAY = 'PROPERTIES_TO_DISPLAY';
export const PARTNER_IDENTITY = 'PARTNER_IDENTITY';
export const ENRICHED_PRODUCTS = 'ENRICHED_PRODUCTS';
export const DEAL_REGISTRATIONS = 'DEAL_REGISTRATIONS';
export const DEAL_REGISTRATION_CRM_OBJECT = 'DEAL_REGISTRATION_CRM_OBJECT';
export const ASSOC_DEAL_REGISTRATIONS_META = 'ASSOC_DEAL_REGISTRATIONS_META';
export const POI_AGREEMENTS = 'POI_AGREEMENTS';
export const DEAL_CONTACTS = 'DEAL_CONTACTS';
export const SHARED_DEAL_PARTNER_COLLAB_REQUEST = 'SHARED_DEAL_PARTNER_COLLAB_REQUEST';