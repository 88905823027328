import { getCardKey } from './getCardKey';
import produce from 'immer';
const emptyState = {
  location: '',
  isResolvedLocation: false,
  isVirtualizationEnabled: false,
  timings: {
    locationRenderStartedMs: 0
  },
  totalCardCount: 0,
  dataByCard: {},
  cardTypesLoaded: [],
  changedTabsBeforeInitialLoadSettled: false
};
export function reducer(state = emptyState, action) {
  switch (action.type) {
    case 'CARD_WRAPPER_RENDERED':
      return produce(state, draft => {
        if (!draft.isInitialLoadSettled) draft.totalCardCount++;
      });
    case 'CONTAINER_TAB_CHANGED':
      return produce(state, draft => {
        if (!draft.isInitialLoadSettled) {
          draft.dataByCard = {};
          draft.totalCardCount = 0;
          draft.changedTabsBeforeInitialLoadSettled = true;
        }
      });
    case 'CARD_LOADING_STARTED':
      return produce(state, draft => {
        // check if this is the first card loaded
        if (Object.keys(draft.dataByCard).length === 0) {
          draft.timings.firstCardStartedAtMs = action.startTimeMs;
        }
        draft.dataByCard[getCardKey(action.cardType, action.cardId, state.location, action.tabIndex)] = {
          isVisibleOnInitialLoad: !state.isInitialLoadSettled ? action.isInViewport : false,
          cardLoadingOrderIndex: Object.keys(draft.dataByCard).length,
          timeoutId: action.timeoutId,
          startTimeMs: action.startTimeMs,
          isFirstLoadForCardType: !draft.cardTypesLoaded.includes(action.cardType),
          key: getCardKey(action.cardType, action.cardId, state.location, action.tabIndex)
        };
      });
    case 'CARD_LOADING_FINISHED':
      return produce(state, draft => {
        if (action.finishedState === 'SUCCESS' && !draft.cardTypesLoaded.includes(action.cardType)) {
          draft.cardTypesLoaded.push(action.cardType);
        }
        const draftCard = draft.dataByCard[getCardKey(action.cardType, action.cardId, state.location, action.tabIndex)];
        draftCard.finishedState = action.finishedState;
        draftCard.isCollapsed = action.isCollapsed;
        delete draftCard.timeoutId;
      });
    case 'INITIAL_LOAD_SETTLED':
      return produce(state, draft => {
        draft.isInitialLoadSettled = true;
      });
    case 'CARD_PERF_HOOKS_CALLED':
      return produce(state, draft => {
        try {
          draft.dataByCard[getCardKey(action.cardType, action.cardId, state.location, action.tabIndex)].perfHooksCalled = true;
        } catch (e) {
          // This shouldn't happen, but I don't want this code to crash the app under any circumstance
          console.error(e);
        }
      });
    case 'NESTED_CARD_PERF_CONTEXT_INITIALIZED':
      return produce(state, draft => {
        // if a nested perf context is initialized, we know this is a resolved location
        // and we need to shuffle some of the timing data around
        draft.isResolvedLocation = true;
        // Move the timing gathered on the first initialization to locationResolverRenderStartedMs
        draft.timings.locationResolverRenderStartedMs = draft.timings.locationRenderStartedMs;
        // Set the locationRenderStartedMs timing to the new start time of the nested context.
        draft.timings.locationRenderStartedMs = action.startedAtMs;
      });
    default:
      return state;
  }
}