import { COMMUNICATOR } from '../constants/namespaces';
import { objectTypeFromUrl } from '../utils/objectTypeFromUrl';
import enviro from 'enviro';
import { createTracker } from 'usage-tracker';
import events from 'customer-data-tracking/tracking/communicator/events.yaml';
import once from '../utils/once';
import UsageTracker from '../trackers/UsageTracker';
const trackerArgs = {
  properties: {
    namespace: COMMUNICATOR
  },
  onError(err) {
    console.error(err);
  },
  lastKnownEventProperties: ['screen', 'subscreen'],
  debug: () => enviro.debug('customer-data-tracker')
};
export const getTracker = once(() => createTracker(Object.assign({}, trackerArgs, {
  events
})));
export const getTrackerSendImmediate = once(() => createTracker(Object.assign({
  bypassPool: true,
  events
}, trackerArgs)));
export const CommunicatorLogger = {
  init: data => {
    getTracker().setProperties(data);
    getTrackerSendImmediate().setProperties(data);
  },
  logImmediate: (eventName, eventProps = {}, trackerOptions = {}) => {
    eventProps.screen = objectTypeFromUrl();
    CommunicatorLogger.log(eventName, eventProps, Object.assign({}, trackerOptions, {
      sendImmediate: true
    }));
  },
  log: (eventName, eventProps = {}, trackerOptions = {
    sendImmediate: false
  }) => {
    if (!Object.prototype.hasOwnProperty.call(eventProps, 'screen')) {
      eventProps.screen = objectTypeFromUrl();
    }
    CommunicatorLogger._log(eventName, eventProps, trackerOptions);
  },
  _log(eventName = '', eventProps = {}, trackerOptions = {
    sendImmediate: false,
    namespace: COMMUNICATOR
  }) {
    UsageTracker.track(eventName, eventProps, trackerOptions, getTracker(), getTrackerSendImmediate());
  }
};
export default CommunicatorLogger;