import { LEGACY_SIDEBAR_CARD_TYPES } from '../../constants/LegacySidebarCardType';

// Legacy card should not have config type or query fragments
// Extra props on legacy card
// Legacy Sidebar Card Metadata
export const DEAL_COLLABORATORS_METADATA = {
  cardType: 'DEAL_COLLABORATORS',
  legacySidebarCardType: LEGACY_SIDEBAR_CARD_TYPES.DealCollaboratorsCard,
  hasLegacyExtraProps: true,
  renderAsLegacyCardInCrmLocationResolver: true
};