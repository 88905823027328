import { ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';
import { useContext, useMemo } from 'react';
import { SubjectContext } from '../SubjectContext';
const useRecordSubject = () => {
  // @ts-expect-error Immutable troubles :(
  const {
    objectType,
    subjectId
  } = useContext(SubjectContext);
  const objectTypeId = useMemo(() => {
    if (objectType in ObjectTypesToIds) {
      return ObjectTypesToIds[objectType];
    }
    return objectType;
  }, [objectType]);
  return {
    objectType,
    objectTypeId,
    subjectId
  };
};
export default useRecordSubject;