// The following disable directive for no-restricted-imports is intentional. We need to import it for setup.
// eslint-disable-next-line no-restricted-imports
import GlobalScopesContainer from 'crm-legacy-global-containers/GlobalScopesContainer';
import ScopesContainer from '../containers/ScopesContainer';
export const setupScopesContainer = auth => {
  const {
    user
  } = auth;
  const scopes = user.scopes.reduce((acc, scope) => {
    acc[scope] = true;
    return acc;
  }, {});
  ScopesContainer.set(scopes);
  GlobalScopesContainer.setContainer(ScopesContainer);
};