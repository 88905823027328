'use es6';

import { fromJS, Map as ImmutableMap } from 'immutable';
import { get } from '../api/ImmutableAPI';
const API_URL = 'filemanager/api/v2/files/multiple';
export function fetch(attachmentIds) {
  return get(API_URL, {
    id: attachmentIds.toJS()
  }, attachments => fromJS(attachments).reduce((keyedAttachments, attachment) => {
    if (!attachment) {
      return keyedAttachments;
    }
    return keyedAttachments.set(`${attachment.get('id')}`, attachment);
  }, ImmutableMap()));
}

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}