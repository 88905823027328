import { CardEmptyState, ChickletHoverActions, ChickletPrimaryDisplayLabel, ChickletSecondaryDisplayLabels } from '../constants/BehaviorTypes';
import ContactCardEmptyState from '../behaviors/contact/ContactCardEmptyState';
import getContactChickletHoverActions from '../behaviors/contact/getContactChickletHoverActions';
import ContactChickletPrimaryDisplayLabel from '../behaviors/contact/ContactChickletPrimaryDisplayLabel';
import getContactChickletSecondaryDisplayLabels from '../behaviors/contact/getContactChickletSecondaryDisplayLabels';
export const Contact = {
  [CardEmptyState]: () => ContactCardEmptyState,
  [ChickletPrimaryDisplayLabel]: () => ContactChickletPrimaryDisplayLabel,
  [ChickletHoverActions]: () => getContactChickletHoverActions,
  [ChickletSecondaryDisplayLabels]: () => getContactChickletSecondaryDisplayLabels
};