// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import CommunicatorStore from 'customer-data-communicator/data/communicator/stores/CommunicatorStore';
import PropTypes from 'prop-types';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
import { getId } from 'customer-data-objects/model/ImmutableModel';
import { getReplyEngagementId } from './TimelineEmailBeingRepliedToDependency';
const IsEmailBeingRepliedToDependency = {
  propTypes: {
    engagement: PropTypes.instanceOf(EngagementRecord)
  },
  stores: [CommunicatorStore],
  deref({
    engagement
  }) {
    if (!engagement) {
      return false;
    }
    const emailEngagementId = getId(engagement);
    return getReplyEngagementId(CommunicatorStore) === emailEngagementId;
  }
};
export default IsEmailBeingRepliedToDependency;