import { useEffect, useRef, useState } from 'react';
export const useAnimatedStroke = percentage => {
  const ref = useRef(null);
  const [strokeDashData, setStrokeDashData] = useState({
    strokeDashArray: '141, 141',
    strokeDashOffset: '141'
  });
  useEffect(() => {
    if (ref.current) {
      const length = ref.current.getTotalLength();
      setStrokeDashData({
        strokeDashArray: `${length}, ${length}`,
        strokeDashOffset: `${length - percentage * length}`
      });
    }
  }, [ref, percentage]);
  return Object.assign({
    ref
  }, strokeDashData);
};