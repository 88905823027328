import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { Company } from './Company';
import { Contact } from './Contact';
import { Deal } from './Deal';
import { FlywheelDefault } from './internal/FlywheelDefault';
const flywheelExtensions = {
  [COMPANY_TYPE_ID]: Company,
  [CONTACT_TYPE_ID]: Contact,
  [DEAL_TYPE_ID]: Deal
};
export const getFlywheelDefaultExtension = () => FlywheelDefault;
export const getFlywheelExtensions = () => flywheelExtensions;