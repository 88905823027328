// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import CrmObjectTypeStore from 'crm_data/crmObjectTypes/CrmObjectTypeStore';
import { getProperty, toString } from 'customer-data-objects/model/ImmutableModel';
import SubjectDependency from './SubjectDependency';
import { isLoading, isEmpty, EMPTY, LOADING
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/flux/LoadingStatus';
import PropTypes from 'prop-types';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { AnyCrmObjectTypePropType } from 'customer-data-objects-ui-components/propTypes/CrmObjectTypes';
import { isLegacyObjectType } from 'customer-data-objects/types/LegacyObjectType';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import PropertiesStore from 'crm_data/properties/PropertiesStore';
import * as PropertyValueDisplay from 'customer-data-property-utils/PropertyValueDisplay';
import { List } from 'immutable';
// Note: for non-legacy object types we expect the objectType in the ID format
// so that it can be fetched in CrmObjectTypeStore.
const SubjectNameDependency = {
  propTypes: {
    objectType: AnyCrmObjectTypePropType,
    subjectId: PropTypes.string.isRequired
  },
  stores: [CrmObjectTypeStore, PropertiesStore, ...SubjectDependency.stores],
  deref: ({
    objectType,
    subjectId
  }) => {
    if (!objectType) {
      return EMPTY;
    }
    const subject = SubjectDependency.deref({
      objectType,
      subjectId
    });
    if (isLoading(subject)) {
      return LOADING;
    }
    if (isEmpty(subject)) {
      return EMPTY;
    }

    // no need to fetch primaryDisplayLabel for HubSpot defined object types
    if (isLegacyObjectType(objectType)) {
      return toString(subject);
    }
    const properties = PropertiesStore.get(objectType);
    const typeDef = CrmObjectTypeStore.get(objectType);
    if (isLoading(typeDef, properties)) {
      return LOADING;
    }
    if (isEmpty(typeDef, properties)) {
      return EMPTY;
    }
    const {
      primaryDisplayLabelPropertyName,
      secondaryDisplayLabelPropertyNames
    } = typeDef;
    if (!primaryDisplayLabelPropertyName && (!secondaryDisplayLabelPropertyNames || !secondaryDisplayLabelPropertyNames.size)) {
      return EMPTY;
    }
    const labelPropertyNames = List([primaryDisplayLabelPropertyName]).concat(secondaryDisplayLabelPropertyNames);
    const existingPropertyName = labelPropertyNames.find(propertyName => {
      return !!getProperty(subject, propertyName);
    });
    if (!existingPropertyName) {
      return EMPTY;
    }
    return PropertyValueDisplay.getDisplayedValue(properties.get(existingPropertyName), getProperty(subject, existingPropertyName));
  }
};
export default SubjectNameDependency;