import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["activeBranchNumber"],
  _excluded2 = ["activeBranchNumber"];
import http from 'hub-http/clients/apiClient';
import { fromJS } from 'immutable';
const responseToImmutable = result => result ? fromJS(result) : result;

/*
  @Deprecated - Use `fetchEnrollment` from `SequencesV2Api` instead
*/
export const fetchEnrollment = vid => {
  const url = `sequences/v2/enrollments/vid/${vid}/state`;
  return http.get(url).then(responseToImmutable);
};

/*
  @Deprecated - Use `enroll` from `SequencesV2Api` instead
*/
export const enroll = (enrollment, email) => {
  // As part of dynamic sequence changes, `activeBranchNumber` is needed for enrollment request payload
  // If the dynamic sequence is gated, `activeBranchNumber` will be `null`
  const _enrollment$toJS = enrollment.toJS(),
    {
      activeBranchNumber
    } = _enrollment$toJS,
    sequenceData = _objectWithoutPropertiesLoose(_enrollment$toJS, _excluded);
  const enrollmentPayload = activeBranchNumber !== null ? {
    activeBranchNumber,
    sequence: sequenceData,
    steps: []
  } : {
    sequence: sequenceData,
    steps: []
  };
  const data = {
    email,
    enrollment: enrollmentPayload
  };
  return http.post('sequences/v2/enrollments/enroll', {
    data
  }).then(responseToImmutable);
};

/*
  @Deprecated - Use `bulkEnroll` from `SequencesV2Api` instead
*/
export const bulkEnroll = enrollments => {
  const data = enrollments.map((enrollment, vid) => {
    // As part of dynamic sequence changes, `activeBranchNumber` is needed for enrollment request payload
    // If the dynamic sequence is gated, `activeBranchNumber` will be `null`
    const _enrollment$toJS2 = enrollment.toJS(),
      {
        activeBranchNumber
      } = _enrollment$toJS2,
      enrollmentData = _objectWithoutPropertiesLoose(_enrollment$toJS2, _excluded2);
    const enrollmentPayload = activeBranchNumber !== null ? {
      sequence: enrollmentData,
      activeBranchNumber
    } : {
      sequence: enrollmentData
    };
    return {
      enrollment: enrollmentPayload,
      vid
    };
  });
  return http.post('sequences/v2/enrollments/enroll/batch', {
    data
  }).then(responseToImmutable);
};

/*
  @Deprecated - Use `pause` from `SequencesV2Api` instead
*/
export const pause = enrollmentId => {
  return http.post('sequences/v2/enrollments/batch/actions/pause', {
    data: {
      inputs: [enrollmentId]
    }
  }).then(responseToImmutable);
};

/*
  @Deprecated - Use `fetchResumePreview` from `SequencesV2Api` instead
*/
export const fetchResumePreview = (enrollmentId, timeAdjustmentStrategy = 'CONTINUE_DELAY') => {
  return http.get(`sequences/v2/enrollments/${enrollmentId}/time-adjustment-preview?timeAdjustmentStrategy=${timeAdjustmentStrategy}`).then(responseToImmutable);
};

/*
  @Deprecated - Use `resume` from `SequencesV2Api` instead
*/
export const resume = enrollment => {
  return http.post('sequences/v2/enrollments/actions/update-and-unpause', {
    data: enrollment.toJS()
  }).then(responseToImmutable);
};

/*
  @Deprecated - Use `fetchTaskDependencies` from `SequencesV2Api` instead
*/
export const fetchTaskDependencies = taskUids => {
  const url = 'sequences/v2/dependencies/tasks';
  return http.post(url, {
    data: taskUids
  }).then(responseToImmutable);
};