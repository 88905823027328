import PropTypes from 'prop-types';
import { Map as ImmutableMap } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import QueuesStore from 'crm_data/queues/QueuesStore';

/** TODO: RELIABILITY_CLEANUP: https://git.hubteam.com/HubSpot/CRM-Issues/issues/5708
 *
 * crm-legacy-global-containersis deprecated.
 * Please use the application-specific container likely in /containers or pass in this data instead.
 */
import GlobalJitaContainer from 'crm-legacy-global-containers/GlobalJitaContainer';
export default {
  propTypes: {
    engagementId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
    queues: PropTypes.instanceOf(ImmutableMap)
  },
  stores: [QueuesStore],
  deref: ({
    engagement,
    engagementId
  }) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    if (GlobalJitaContainer.getContainer().get()) {
      return null;
    }
    let existingQueue = null;
    const queues = QueuesStore.get();
    if (!queues || queues.has('error')) {
      return null;
    }
    const activeEngagementId = engagement ? engagement.getIn(['engagement', 'id']) : engagementId;
    const queueId = engagement && engagement.getIn(['engagement', 'queueMembershipIds', 0]);
    if (!activeEngagementId) {
      return null;
    }
    queues.some(queue => {
      const queueIds = queue.get('engagementIds');
      if (queueIds.includes(parseInt(activeEngagementId, 10)) || queue.get('id') === queueId) {
        existingQueue = queue;
        return true;
      }
      return false;
    });
    return existingQueue;
  }
};