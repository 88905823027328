import useGetSocialProfileProperties from './data/useGetSocialProfileProperties';
import { GET_MATCH_ID } from './data/fetchSocialProfileMatchId';
import PortalIdParser from 'PortalIdParser';
import { useQuery } from 'data-fetching-client';
import I18n from 'I18n';
import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
const PROFILE_CARD_PROPERTIES = ['hs_profile_key', 'hs_network'];
const getSocialLinkActionsData = (args, profileKey, network, matchId) => {
  return {
    dataSeleniumTest: 'chicklet-social-profile-btn',
    label: I18n.text('cardAssociatedObjects.chicklet.SocialProfileHoverButton'),
    href: `/social/${PortalIdParser.get()}/matches/confirmed/review/${network}/${matchId}`,
    external: true,
    disabled: !args.canEdit || !profileKey || !network || !matchId,
    onClick: () => {
      CrmLogger.log('sidebar-interaction', {
        action: 'social profile - click review details'
      });
    }
  };
};
export const SocialProfileChickletHoverActions = args => {
  const properties = useGetSocialProfileProperties(args.objectId, PROFILE_CARD_PROPERTIES);
  const {
    data
  } = useQuery(GET_MATCH_ID, {
    skip: !properties.hs_profile_key || !args.fromObjectId,
    variables: {
      profileKey: properties.hs_profile_key,
      contactId: args.fromObjectId
    }
  });
  return {
    SecondaryActions: [getSocialLinkActionsData(args, properties.hs_profile_key, properties.hs_network, data && data.socialProfileMatchId && data.socialProfileMatchId.id)]
  };
};