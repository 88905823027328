import { useCallback } from 'react';
import { useTimelineDispatch, useTimelineSelector } from '../../reduxHooks';
import { getIsTeamFilterOpen, openTeamFilter as openTeamFilterReducer, closeTeamFilter as closeTeamFilterReducer } from '../timelineControlsSlice';
export function useTeamFilter() {
  const isTeamFilterOpen = useTimelineSelector(getIsTeamFilterOpen);
  const dispatch = useTimelineDispatch();
  const openTeamFilter = useCallback(() => {
    dispatch(openTeamFilterReducer());
  }, [dispatch]);
  const closeTeamFilter = useCallback(() => {
    dispatch(closeTeamFilterReducer());
  }, [dispatch]);
  return [isTeamFilterOpen, openTeamFilter, closeTeamFilter];
}