'use es6';

import { List, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import invariant from 'react-utils/invariant';
import { COMPANIES_UPDATED, COMPANY_DOMAIN_FOUND, COMPANY_DOMAIN_NOT_FOUND, COMPANIES_FETCH_SUCCEEDED, COMPANIES_REFRESH_QUEUED, COMPANY_CREATED, COMPANIES_UPDATE_STARTED, COMPANIES_UPDATE_FAILED, COMPANIES_UPDATE_SUCCEEDED, COMPANY_DOMAIN_REMOVED, ASSOCIATIONS_REFRESH_QUEUED } from 'crm_data/actions/ActionTypes';
import { transact } from 'crm_data/flux/transact';
import { dispatchImmediate, dispatchQueue } from 'crm_data/dispatch/Dispatch';
import { getProperty } from 'customer-data-objects/model/ImmutableModel';
import * as CompaniesAPI from 'crm_data/companies/api/CompaniesAPI';
import { COMPANY } from 'customer-data-objects/constants/ObjectTypes';
import { COMPANY_TO_CONTACT, COMPANY_TO_DEAL, COMPANY_TO_TICKET } from 'crm_data/associations/AssociationTypes';
const toPropertyList = record => record.get('properties').reduce((list, {
  value,
  source,
  sourceId
}, name) => list.push({
  value,
  name,
  sourceId,
  source
}), List());
const makeAssociationKey = (id, associationType) => {
  return ImmutableMap({
    objectId: id,
    objectType: COMPANY,
    associationType
  });
};
export function refresh(ids) {
  dispatchQueue(COMPANIES_REFRESH_QUEUED, ImmutableSet(ids));
  dispatchQueue(ASSOCIATIONS_REFRESH_QUEUED, ImmutableSet([makeAssociationKey(ids[0], COMPANY_TO_CONTACT), makeAssociationKey(ids[0], COMPANY_TO_DEAL), makeAssociationKey(ids[0], COMPANY_TO_TICKET)]));
}
export function createCompany(record) {
  const properties = toPropertyList(record);
  return CompaniesAPI.createCompany(properties).then(response => {
    const company = response.first();
    dispatchImmediate(COMPANY_CREATED, company);
    return company;
  });
}
export function deleteCompany(companyId, callback) {
  CompaniesAPI.deleteCompany(companyId).then(() => {
    setTimeout(() => {
      dispatchImmediate(COMPANIES_UPDATED, ImmutableMap().set(`${companyId}`, null));
    }, 0);
    return typeof callback === 'function' ? callback() : undefined;
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return undefined;
}
export function updateCompanies(companies) {
  invariant(ImmutableMap.isMap(companies), 'Companies Actions: expected companies to be a Map but got "%s"');
  return dispatchImmediate(COMPANIES_UPDATED, companies);
}
export function updateCompany(company, updates) {
  const companyId = company.get('companyId');
  return transact({
    operation() {
      return CompaniesAPI.update(ImmutableMap({
        companyId,
        properties: updates
      }));
    },
    commit: [COMPANIES_UPDATED, ImmutableMap().set(companyId, updates.reduce((updated, property) => updated.setIn(['properties', property.get('name'), 'value'], property.get('value')), company))],
    rollback: [COMPANIES_UPDATED, company]
  });
}
export function updateCompanyProperties(company, nextProperties) {
  const id = `${company.get('companyId')}`;
  const properties = nextProperties.map((_, name) => getProperty(company, name));
  dispatchImmediate(COMPANIES_UPDATE_STARTED, {
    id,
    properties,
    nextProperties
  });
  return CompaniesAPI.updateCompanyProperties(company, nextProperties).then(() => dispatchImmediate(COMPANIES_UPDATE_SUCCEEDED, {
    id,
    properties: nextProperties,
    prevProperties: properties
  }), error => {
    dispatchImmediate(COMPANIES_UPDATE_FAILED, {
      error,
      id,
      nextProperties,
      properties
    });
    throw error;
  });
}
export function fetchByDomain(domain) {
  CompaniesAPI.fetchByDomain(domain).then(companies => {
    dispatchImmediate(COMPANIES_FETCH_SUCCEEDED, companies);
    dispatchImmediate(COMPANY_DOMAIN_FOUND, {
      domain,
      companies
    });
  }, () => dispatchImmediate(COMPANY_DOMAIN_NOT_FOUND, {
    domain
  })).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return undefined;
}
export function removeDomain(subject, domain) {
  return CompaniesAPI.removeDomain(subject, domain).then(() => dispatchImmediate(COMPANY_DOMAIN_REMOVED, {
    domain,
    company: subject
  }));
}