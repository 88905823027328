'use es6';

import { UserAttributesClient } from 'frontend-preferences-client';
const userAttributesClient = UserAttributesClient.forCaller('draft-js');
export function fetchUserSetting(keys) {
  const keyArray = Array.isArray(keys) ? keys : [keys];
  const formattedKeys = keyArray.reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {});
  return userAttributesClient.batchFetch(Object.assign({}, formattedKeys));
}
export function saveUserSetting(settingName, key, value) {
  return userAttributesClient.write(key, value);
}