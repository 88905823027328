'use es6';

import { Map as ImmutableMap } from 'immutable';
import { TASKS_UPDATED, TASKS_AND_ENGAGEMENTS_UPDATED } from 'crm_data/actions/ActionTypes';
import { transact } from 'crm_data/flux/transact';
import taskToEngagementRecord from 'crm_data/tasks/taskToEngagementRecord';
import { patchTask } from 'crm_data/tasks/TasksServiceAPI';
import { dispatchImmediate } from 'crm_data/dispatch/Dispatch';
import { updateEngagementInStore } from '../engagements/EngagementsV2Actions';
export function updateTaskInStore(task, tasksStoreKey) {
  dispatchImmediate(TASKS_UPDATED, ImmutableMap().set(tasksStoreKey, task));
}
export const updateStoreWithResponseTask = (task, tasksStoreKey) => {
  if (tasksStoreKey) {
    updateTaskInStore(task, tasksStoreKey);
  }
  const taskV1Engagement = taskToEngagementRecord(task);
  updateEngagementInStore(taskV1Engagement);
  return task;
};

/**
 * @deprecated - separating patch requests/store updates and consolidating
 * the update functions into one entry point. use updateTaskV2 instead
 *
 * Performs a PATCH request and dispatches updates to our stores.
 * @param {ImmutableMap} propertyUpdates - looks like
 *     fromJS({ hs_task_subject: { name: 'hs_task_subject', value: 'NOT_STARTED' }}).
 *     Only pass the properties that changed
 * @param {ImmutableMap} associations - A map of associations that will be included with the request.
 *  If updating associations, send all associations in the request. If you don't want to update associations,
 *  leave this field undefined. To clear all associations, pass an empty Immutable Map
 */
export function updateTask(taskId, propertyUpdates, tasksStoreKey, associations) {
  return patchTask(taskId, propertyUpdates, associations).then(task => updateStoreWithResponseTask(task, tasksStoreKey));
}

/**
 * @param {ImmutableMap} updatedProperties - looks like
 *     fromJS({ hs_task_subject: { name: 'hs_task_subject', value: 'NOT_STARTED' }}).
 *     Only pass the properties that changed
 * @param {Array} associations
 * @param {Object} transactionInfo - info used in the transact operation
 *        { originalEngagement, updatedEngagement, originalProperties }
 */
export function updateTaskV2({
  tasksStoreKey,
  updatedProperties,
  associations,
  transactionInfo
}) {
  const taskId = tasksStoreKey.get('taskId');
  const {
    originalEngagement,
    updatedEngagement,
    originalProperties,
    onSuccess = () => {}
  } = transactionInfo;
  const engagementUpdate = ImmutableMap().set(`${taskId}`, updatedEngagement);
  const rollbackEngagementUpdate = ImmutableMap().set(`${taskId}`, originalEngagement);
  const optimisticStoreUpdates = {
    engagementsMap: engagementUpdate,
    taskPropertiesUpdate: {
      tasksStoreKey,
      properties: updatedProperties
    }
  };
  const rollbackStoreUpdates = {
    engagementsMap: rollbackEngagementUpdate,
    taskPropertiesUpdate: {
      tasksStoreKey,
      properties: originalProperties
    }
  };
  return transact({
    operation() {
      return patchTask(taskId, updatedProperties, associations);
    },
    commit: [TASKS_AND_ENGAGEMENTS_UPDATED, optimisticStoreUpdates],
    rollback: [TASKS_AND_ENGAGEMENTS_UPDATED, rollbackStoreUpdates],
    accept: onSuccess
  });
}