'use es6';

import { authLoaded } from 'crm_data/auth/AuthActions';
import UserSettingsStore from 'crm_data/settings/UserSettingsStore';
import UserPortalSettingsKeys from 'crm_data/settings/UserPortalSettingsKeys';
import UserSettingsKeys from 'crm_data/settings/UserSettingsKeys';
const defaultInitSettings = () => {
  UserSettingsStore.get([].concat(Object.values(UserPortalSettingsKeys), Object.values(UserSettingsKeys)));
};
const SetupData = (auth, initSettings = defaultInitSettings) => {
  require('crm_data/gates/IsUngatedStore');
  require('crm_data/user/UserStore');
  require('crm_data/portal/PortalStore');
  authLoaded(auth);
  initSettings();
};
export default SetupData;