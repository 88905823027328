import Loadable from 'UIComponents/decorators/Loadable';
import Raven from 'raven-js';
const TaskQueueBarRendererAsync = Loadable({
  loader: () => import( /* webpackChunkName: "TaskQueueBarRenderer" */
  'crm-timeline-tasks/recordTaskQueueBar/TaskQueueBarRenderer'),
  LoadingComponent: () => null,
  ErrorComponent: ({
    error
  }) => {
    Raven.captureException(error);
    console.error(error);
    return null;
  }
});
export default TaskQueueBarRendererAsync;