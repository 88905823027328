// @ts-expect-error FIXME: Could not find a declaration file
import { setupCsat } from 'wootric-nps/csat/csat-setup';
const RECORDS_WOOTRIC_KEY = 'RECORD_CRM';

// Wootric is our CSAT system for feedback. It's loaded in the global Navigation bar.
// Whether the survey shows or not is controlled by Wootric. (Surveys are only shown
// once, only on certain pages, and only if users have not been recently shown other
// surveys to avoid fatigue.)
export const triggerWootricsSurvey = () => {
  try {
    setupCsat(RECORDS_WOOTRIC_KEY);
  } catch (e) {
    // Do nothing; wootric CSAT surveys are best-effort
  }
};