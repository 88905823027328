import { ASSOCIATED_OBJECTS_METADATA } from '../card-definitions/legacy/AssociatedObjects';
import { BET_ASSOCIATED_COMPANIES_METADATA } from '../card-definitions/legacy/BetAssociatedCompanies';
import { BET_CUSTOMER_SUMMARY_METADATA } from '../card-definitions/legacy/BetCustomerSummary';
import { BET_DEAL_SUPPORT_REQUEST_METADATA } from '../card-definitions/legacy/BetDealSupportRequest';
import { BET_PARTNER_COLLAB_METADATA } from '../card-definitions/legacy/BetPartnerCollab';
import { BET_PARTNER_POI_METADATA } from '../card-definitions/legacy/BetPartnerPoi';
import { BET_QUOTES_METADATA } from '../card-definitions/legacy/BetQuotes';
import { BIZOPS_CUSTOMER_SUCCESS_METADATA } from '../card-definitions/legacy/BizopsCustomerSuccess';
import { BIZOPS_DOMAIN_CONTROLS_METADATA } from '../card-definitions/legacy/BizopsDomainControls';
import { BIZOPS_RELATED_CONTACTS_METADATA } from '../card-definitions/legacy/BizopsRelatedContacts';
import { BUYER_INTENT_METADATA } from '../card-definitions/legacy/BuyerIntent';
import { CART_DISCOUNT_METADATA } from '../card-definitions/legacy/CartDiscount';
import { CART_SHIPMENT_DETAILS_METADATA } from '../card-definitions/legacy/CartShipmentDetails';
import { CART_TOTAL_METADATA } from '../card-definitions/legacy/CartTotal';
import { COMMERCE_PAYMENT_HISTORY_METADATA } from '../card-definitions/legacy/CommercePaymentHistory';
import { COMPANY_PARENT_CHILD_METADATA } from '../card-definitions/legacy/CompanyParentChild';
import { CONTACT_CREATE_ATTRIBUTION_METADATA } from '../card-definitions/legacy/ContactCreateAttribution';
import { CONVERSATIONS_CONTACT_TICKETS_METADATA } from '../card-definitions/legacy/ConversationsContactTickets';
import { CONVERSATIONS_DEAL_METADATA } from '../card-definitions/legacy/ConversationsDeal';
import { CONVERSATIONS_THREAD_TICKET_METADATA } from '../card-definitions/legacy/ConversationsThreadTicket';
import { DEAL_COLLABORATORS_METADATA } from '../card-definitions/legacy/DealCollaborators';
import { DEAL_CREATE_ATTRIBUTION_METADATA } from '../card-definitions/legacy/DealCreateAttribution';
import { DEAL_REGISTRATION_METADATA } from '../card-definitions/legacy/DealRegistration';
import { DEAL_SHARED_VIEW_METADATA } from '../card-definitions/legacy/DealSharedView';
import { DEAL_SPLITS_METADATA } from '../card-definitions/legacy/DealSplits';
import { DOCUSIGN_METADATA } from '../card-definitions/legacy/Docusign';
import { EXTENSION_METADATA } from '../card-definitions/legacy/Extension';
import { FEEDBACK_METADATA } from '../card-definitions/legacy/Feedback';
import { FLYWHEEL_QL_SUMMARY_METADATA } from '../card-definitions/legacy/FlywheelQlSummary';
import { FLYWHEEL_RECENT_CONVERSIONS_METADATA } from '../card-definitions/legacy/FlywheelRecentConversions';
import { GDPR_SUBSCRIPTIONS_METADATA } from '../card-definitions/legacy/GdprSubscriptions';
import { INTEGRATION_METADATA } from '../card-definitions/legacy/Integration';
import { INTEGRATIONS_SYNC_STATUS_METADATA } from '../card-definitions/legacy/IntegrationsSyncStatus';
import { INVOICES_METADATA } from '../card-definitions/legacy/Invoices';
import { LEAD_STAGE_TRACKER_METADATA } from '../card-definitions/legacy/LeadStageTracker';
import { LINKEDIN_SALES_NAVIGATOR_METADATA } from '../card-definitions/legacy/LinkedinSalesNavigator';
import { LISTS_METADATA } from '../card-definitions/legacy/Lists';
import { MARKETING_EVENT_ABOUT_METADATA } from '../card-definitions/legacy/MarketingEventAbout';
import { MARKETING_EVENT_ACTIVITY_METADATA } from '../card-definitions/legacy/MarketingEventActivity';
import { NATIVE_INVOICES_METADATA } from '../card-definitions/legacy/NativeInvoices';
import { NETSUITE_METADATA } from '../card-definitions/legacy/Netsuite';
import { ORDER_DISCOUNT_METADATA } from '../card-definitions/legacy/OrderDiscount';
import { ORDER_SHIPMENT_DETAIL_METADATA } from '../card-definitions/legacy/OrderShipmentDetail';
import { ORDER_TOTAL_METADATA } from '../card-definitions/legacy/OrderTotal';
import { PARTNER_REGISTRATION_METADATA } from '../card-definitions/legacy/PartnerRegistration';
import { PAST_CONVERSATIONS_METADATA } from '../card-definitions/legacy/PastConversations';
import { PINNED_ACTIVITY_METADATA } from '../card-definitions/legacy/PinnedActivity';
import { PLAYBOOKS_METADATA } from '../card-definitions/legacy/Playbooks';
import { PRODUCTS_METADATA } from '../card-definitions/legacy/Products';
import { PROPERTIES_METADATA } from '../card-definitions/legacy/Properties';
import { PROSPECTING_NOTES_METADATA } from '../card-definitions/legacy/ProspectingNotes';
import { QUALIFIED_LEADS_METADATA } from '../card-definitions/legacy/QualifiedLeads';
import { QUOTES_METADATA } from '../card-definitions/legacy/Quotes';
import { REVENUE_ATTRIBUTION_METADATA } from '../card-definitions/legacy/RevenueAttribution';
import { SALES_ACTIVITIES_TIMELINE_METADATA } from '../card-definitions/legacy/SalesActivitiesTimeline';
import { SERVICE_DELIVERY_HOURS_LOGGING_METADATA } from '../card-definitions/legacy/ServiceDeliveryHoursLogging';
import { SFDC_SYNC_METADATA } from '../card-definitions/legacy/SfdcSync';
import { SUBSCRIPTION_BILLING_INFO_METADATA } from '../card-definitions/legacy/SubscriptionBillingInfo';
import { SUBSCRIPTION_PAYMENTS_TIMELINE_METADATA } from '../card-definitions/legacy/SubscriptionPaymentsTimeline';
import { SURVEY_MONKEY_METADATA } from '../card-definitions/legacy/SurveyMonkey';
import { TALKING_POINTS_METADATA } from '../card-definitions/legacy/TalkingPoints';
import { TARGET_ACCOUNT_METADATA } from '../card-definitions/legacy/TargetAccount';
import { TOOL_LINKS_METADATA } from '../card-definitions/legacy/ToolLinks';
import { TRANSCRIPT_METADATA } from '../card-definitions/legacy/Transcript';
import { UNIVERSAL_TIMELINE_METADATA } from '../card-definitions/legacy/UniversalTimeline';
import { UPCOMING_ACTIVITIES_METADATA } from '../card-definitions/legacy/UpcomingActivities';
import { WEBSITE_ACTIVITY_METADATA } from '../card-definitions/legacy/WebsiteActivity';
import { WORKFLOWS_METADATA } from '../card-definitions/legacy/Workflows';
import { ZORSE_CONVERSATIONS_TAXONOMY_METADATA } from '../card-definitions/legacy/ZorseConversationsTaxonomy';
import { ZORSE_MOVE_INBOX_METADATA } from '../card-definitions/legacy/ZorseMoveInbox';
import { ZORSE_REP_CHAT_ASSIGNMENT_METADATA } from '../card-definitions/legacy/ZorseRepChatAssignment';
import { ZORSE_TICKET_SEARCH_METADATA } from '../card-definitions/legacy/ZorseTicketSearch';

/**
 * ALL legacy card definitions should be in here
 */
export const LEGACY_CARDS_METADATA_MAP = {
  BET_ASSOCIATED_COMPANIES: BET_ASSOCIATED_COMPANIES_METADATA,
  BET_CUSTOMER_SUMMARY: BET_CUSTOMER_SUMMARY_METADATA,
  BET_DEAL_SUPPORT_REQUEST: BET_DEAL_SUPPORT_REQUEST_METADATA,
  BET_PARTNER_COLLAB: BET_PARTNER_COLLAB_METADATA,
  BET_PARTNER_POI: BET_PARTNER_POI_METADATA,
  BET_QUOTES: BET_QUOTES_METADATA,
  BIZOPS_CUSTOMER_SUCCESS: BIZOPS_CUSTOMER_SUCCESS_METADATA,
  BIZOPS_DOMAIN_CONTROLS: BIZOPS_DOMAIN_CONTROLS_METADATA,
  BIZOPS_RELATED_CONTACTS: BIZOPS_RELATED_CONTACTS_METADATA,
  BUYER_INTENT: BUYER_INTENT_METADATA,
  CART_DISCOUNT: CART_DISCOUNT_METADATA,
  CART_SHIPMENT_DETAILS: CART_SHIPMENT_DETAILS_METADATA,
  CART_TOTAL: CART_TOTAL_METADATA,
  COMMERCE_PAYMENT_HISTORY: COMMERCE_PAYMENT_HISTORY_METADATA,
  COMPANY_PARENT_CHILD: COMPANY_PARENT_CHILD_METADATA,
  CONTACT_CREATE_ATTRIBUTION: CONTACT_CREATE_ATTRIBUTION_METADATA,
  CONVERSATION_CONTACT_TICKETS: CONVERSATIONS_CONTACT_TICKETS_METADATA,
  CONVERSATION_DEALS: CONVERSATIONS_DEAL_METADATA,
  CONVERSATION_THREAD_TICKET: CONVERSATIONS_THREAD_TICKET_METADATA,
  CRM_OBJECT_ASSOCIATION: ASSOCIATED_OBJECTS_METADATA,
  DEAL_COLLABORATORS: DEAL_COLLABORATORS_METADATA,
  DEAL_CREATE_ATTRIBUTION: DEAL_CREATE_ATTRIBUTION_METADATA,
  DEAL_REGISTRATION: DEAL_REGISTRATION_METADATA,
  DEAL_SHARED_VIEW: DEAL_SHARED_VIEW_METADATA,
  DEAL_SPLITS: DEAL_SPLITS_METADATA,
  DOCUSIGN: DOCUSIGN_METADATA,
  EXTENSION: EXTENSION_METADATA,
  FEEDBACK: FEEDBACK_METADATA,
  FLYWHEEL_QL_SUMMARY: FLYWHEEL_QL_SUMMARY_METADATA,
  FLYWHEEL_RECENT_CONVERSIONS: FLYWHEEL_RECENT_CONVERSIONS_METADATA,
  GDPR_SUBSCRIPTIONS: GDPR_SUBSCRIPTIONS_METADATA,
  INTEGRATION: INTEGRATION_METADATA,
  INTEGRATIONS_SYNC_STATUS: INTEGRATIONS_SYNC_STATUS_METADATA,
  INVOICES: INVOICES_METADATA,
  LEAD_STAGE_TRACKER: LEAD_STAGE_TRACKER_METADATA,
  LINKEDIN_SALES_NAVIGATOR: LINKEDIN_SALES_NAVIGATOR_METADATA,
  LISTS: LISTS_METADATA,
  MARKETING_EVENT_ABOUT: MARKETING_EVENT_ABOUT_METADATA,
  MARKETING_EVENT_ACTIVITY: MARKETING_EVENT_ACTIVITY_METADATA,
  NATIVE_INVOICES: NATIVE_INVOICES_METADATA,
  NETSUITE: NETSUITE_METADATA,
  ORDER_DISCOUNT: ORDER_DISCOUNT_METADATA,
  ORDER_SHIPMENT_DETAIL: ORDER_SHIPMENT_DETAIL_METADATA,
  ORDER_TOTAL: ORDER_TOTAL_METADATA,
  PARTNER_REGISTRATION: PARTNER_REGISTRATION_METADATA,
  PAST_CONVERSATIONS: PAST_CONVERSATIONS_METADATA,
  PINNED_ACTIVITY: PINNED_ACTIVITY_METADATA,
  PLAYBOOKS: PLAYBOOKS_METADATA,
  PRODUCTS: PRODUCTS_METADATA,
  PROPERTIES: PROPERTIES_METADATA,
  PROSPECTING_NOTES: PROSPECTING_NOTES_METADATA,
  QUALIFIED_LEADS: QUALIFIED_LEADS_METADATA,
  QUOTES: QUOTES_METADATA,
  REVENUE_ATTRIBUTION: REVENUE_ATTRIBUTION_METADATA,
  SALES_ACTIVITIES_TIMELINE: SALES_ACTIVITIES_TIMELINE_METADATA,
  SERVICE_DELIVERY_HOURS_LOGGING: SERVICE_DELIVERY_HOURS_LOGGING_METADATA,
  SFDC_SYNC: SFDC_SYNC_METADATA,
  SUBSCRIPTION_BILLING_INFO: SUBSCRIPTION_BILLING_INFO_METADATA,
  SUBSCRIPTION_PAYMENTS_TIMELINE: SUBSCRIPTION_PAYMENTS_TIMELINE_METADATA,
  SURVEY_MONKEY: SURVEY_MONKEY_METADATA,
  TALKING_POINTS: TALKING_POINTS_METADATA,
  TARGET_ACCOUNT: TARGET_ACCOUNT_METADATA,
  TOOL_LINKS: TOOL_LINKS_METADATA,
  TRANSCRIPT: TRANSCRIPT_METADATA,
  UNIVERSAL_TIMELINE: UNIVERSAL_TIMELINE_METADATA,
  UPCOMING_ACTIVITIES: UPCOMING_ACTIVITIES_METADATA,
  WEBSITE_ACTIVITY: WEBSITE_ACTIVITY_METADATA,
  WORKFLOWS: WORKFLOWS_METADATA,
  ZORSE_CONVERSATIONS_TAXONOMY: ZORSE_CONVERSATIONS_TAXONOMY_METADATA,
  ZORSE_MOVE_INBOX: ZORSE_MOVE_INBOX_METADATA,
  ZORSE_REP_CHAT_ASSIGNMENT: ZORSE_REP_CHAT_ASSIGNMENT_METADATA,
  ZORSE_TICKET_SEARCH: ZORSE_TICKET_SEARCH_METADATA
};
export const METADATA_MAP_BY_LEGACY_SIDEBAR_CARD_TYPE = Object.fromEntries(Object.values(LEGACY_CARDS_METADATA_MAP).map(metadata => [metadata.legacySidebarCardType, metadata]));

/**
 * ONLY if a legacy sidebar card type uses extra props, add an entry here.
 */

/**
 * Card types that should be rendered with the legacy sidebar card adapter
 * when using the CrmLocationResolver
 */

/**
 * Legacy Sidebar Card types that use extra props
 */