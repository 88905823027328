import { addBETPortalObjectAssociations, removeBETPortalObjectAssociation } from './BETPortalObjectAssociationsActions';
import { addBETServiceObjectAssociations, removeBETServiceObjectAssociation } from './BETServiceObjectAssociationsActions';
import difference from 'transmute/difference';
export function createBETAssociationsUpdater(associationsAdder, associationRemover) {
  return (originalAssociations, newAssociations, engagementId, isEmailReply) => {
    if (isEmailReply) {
      associationsAdder(engagementId, newAssociations);
    }
    if (!originalAssociations.equals(newAssociations)) {
      const associationsToAdd = difference(originalAssociations, newAssociations);
      const associationsToRemove = difference(newAssociations, originalAssociations);
      if (associationsToAdd.size) {
        associationsAdder(engagementId, associationsToAdd);
      }
      if (associationsToRemove.size) {
        associationsToRemove.map(association => associationRemover(engagementId, association));
      }
    }
  };
}
export const updateBETPortalAssociations = createBETAssociationsUpdater(addBETPortalObjectAssociations, removeBETPortalObjectAssociation);
export const updateBETServiceAssociations = createBETAssociationsUpdater(addBETServiceObjectAssociations, removeBETServiceObjectAssociation);