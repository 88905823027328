'use es6';

import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
import { PROPERTIES_BY_ENGAGEMENT_TYPE, COMMON_PROPERTIES_WITH_PATHS, ARRAY_OF_NUMBERS } from 'crm_data/engagements/api/EngagementProperties';
function isImmutable(obj) {
  return obj && typeof obj.hashCode === 'function' && typeof obj.equals === 'function';
}
export const formatProperty = (propertyValue, isArrayOfNumbers, type) => {
  if (type === Number) {
    return +propertyValue;
  }
  if (type === Boolean) {
    if (typeof propertyValue === 'boolean') return propertyValue;
    return ['true', 'false'].includes(propertyValue) ? propertyValue === 'true' : propertyValue;
  }
  const propertyValueJS = isImmutable(propertyValue) ? propertyValue.toJS() : propertyValue;
  if (isArrayOfNumbers) {
    return propertyValueJS.split(';').map(num => +num);
  }
  if (typeof propertyValueJS === 'object' && !Array.isArray(propertyValueJS)) {
    return propertyValueJS;
  }
  return `${propertyValueJS}`;
};
export const createPropertiesFromEngagement = ({
  engagement,
  shouldJoinArraysOfNumbers = true,
  removeValuesExcludedOnCreate = false,
  type
}) => {
  if (!engagement) {
    return EngagementRecord();
  }
  const engagementType = type || engagement.getIn(['engagement', 'type']);
  if (!engagementType) {
    return [];
  }
  const propertiesForObjectType = PROPERTIES_BY_ENGAGEMENT_TYPE.get(engagementType);
  const applicablePropertyDefinitions = [...propertiesForObjectType, ...COMMON_PROPERTIES_WITH_PATHS];
  const properties = applicablePropertyDefinitions.reduce((acc, propertyToFetch) => {
    const propertyPathV1 = propertyToFetch.propertyPathV1;
    const propertyValue = engagement.getIn(propertyPathV1);
    const shouldExcludeProperty = removeValuesExcludedOnCreate && propertyToFetch.excludeOnCreate;
    if (shouldExcludeProperty || propertyValue === undefined || propertyValue === null) {
      return acc;
    }
    const isArrayOfNumbers = !shouldJoinArraysOfNumbers && propertyToFetch.type === ARRAY_OF_NUMBERS;
    const parsedPropertyValue = propertyToFetch.v1ToV2Parser ? propertyToFetch.v1ToV2Parser(propertyValue) : propertyValue;
    if (parsedPropertyValue === undefined || parsedPropertyValue === null) {
      return acc;
    }
    const formattedPropertyValue = formatProperty(parsedPropertyValue, isArrayOfNumbers, propertyToFetch.type);
    if (formattedPropertyValue === '') {
      return acc;
    }
    acc[propertyToFetch.propertyNameV2] = {
      value: formattedPropertyValue
    };
    return acc;
  }, {});
  return properties;
};