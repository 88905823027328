import enviro from 'enviro';
import debug from 'growth-payments-core/debug/console';
import { useQueryStringState } from 'growth-payments-core/query-string/useQueryStringState';
import { crmModals } from '../components/Modals';
import { ModalDisplay } from '../types/paymentsInterstitialModal';
import { Subscreen, isCrmSubscreen } from '../types/screen';
import useEmbeddedPoint from './useEmbeddedPoint';
import { useEligibilityBinding } from 'growth-payments-core/eligibility/eligibilityBinding';
import { UnderwritingStatus } from 'growth-payments-core/eligibility/types';
const useModalToDisplay = ({
  subscreen
}) => {
  const [eligibility] = useEligibilityBinding();
  const {
    display: legacyModalToDisplay
  } = useEmbeddedPoint();
  if (subscreen === Subscreen.INVOICE_TEMPLATE || subscreen === Subscreen.INVOICE_AUTOMATION) {
    return ModalDisplay.ENROLL_WITH_INVOICES;
  }

  /**
   * CRM is the only screen getting different treatments
   */
  if (!isCrmSubscreen(subscreen)) return legacyModalToDisplay;
  if (eligibility && eligibility.underwritingStatus && eligibility.underwritingStatus === UnderwritingStatus.IN_UNDERWRITING) return ModalDisplay.NONE;
  return crmModals[subscreen];
};
export const useEmbedPointModalDisplay = ({
  subscreen
}) => {
  // displayOverride allows us to override the computed state for debugging.
  const [displayOverride] = useQueryStringState('override-display', undefined);
  debug.log('displayOverride', displayOverride);
  const modalToDisplay = useModalToDisplay({
    subscreen
  });
  return enviro.isProd() ? modalToDisplay : displayOverride || modalToDisplay;
};