const emailDisabledTypes = {
  inactive: 'INACTIVE',
  needsPermission: 'NEEDS_PERMISSION',
  reconnect: 'RECONNECT',
  disabled: 'DISABLED',
  error: 'ERROR'
};
export default emailDisabledTypes;
export const INACTIVE = emailDisabledTypes.inactive;
export const NEEDS_PERMISSION = emailDisabledTypes.needsPermission;
export const RECONNECT = emailDisabledTypes.reconnect;
export const DISABLED = emailDisabledTypes.disabled;
export const ERROR = emailDisabledTypes.error;