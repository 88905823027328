import { parse, stringify } from 'hub-http/helpers/params';
import { getRootUrl } from '../utils/urls';
// abstracted as part of effort to move timeline into iframe, this is the first
// step - auditing and centrilzing all navigation actions
// for more info see https://git.hubteam.com/hubspot/crm-issues/issues/7730
// exported for unit testing purposes only
export const _updateUrlWithoutRefresh = (path, history) => {
  history.push(path.replace(getRootUrl(), ''));
};

// exported for unit testing purposes only
export const _getWindowHref = () => window.location.href;

// exported for unit testing purposes only
export const _getWindowPathName = () => window.location.pathname;

// exported for unit testing purposes only
export const _getWindowSearch = () => window.location.search;
export const navigateToUrl = url => {
  window.location.href = url;
};

// copied from updateQueryParams in crm-legacy-links/links
export const addQueryParams = (history, params, refresh = true) => {
  const existingParams = parse(_getWindowSearch().substring(1));
  const newParams = Object.assign({}, existingParams, params);
  if (refresh) {
    const urlWithoutParams = _getWindowHref().split('?')[0];
    const newUrl = `${urlWithoutParams}?${stringify(newParams)}`;
    navigateToUrl(newUrl);
  } else {
    // use relative path when pushing to React router history
    const newUrl = `${_getWindowPathName()}?${stringify(newParams)}`;
    _updateUrlWithoutRefresh(newUrl, history);
  }
};

// exported for unit testing purposes only
export const getSearchObject = (params, search) => {
  const paramsToKeep = [];
  search.replace('?', '').split('&').forEach(entry => {
    const newEntry = entry.split('=');
    if (!params.includes(newEntry[0])) {
      paramsToKeep.push([newEntry[0], newEntry[1]]);
    }
  });
  return paramsToKeep;
};
export const removeQueryParamsWithoutReload = (history, params) => {
  const search = _getWindowSearch();
  const searchObject = search ? getSearchObject(params, search) : [];
  const newParams = Object.keys(searchObject).map(key => `${searchObject[key][0]}=${searchObject[key][1]}`).join('&');
  const urlWithoutParams = _getWindowPathName();
  const currentUrlWithNewQuery = newParams.length > 0 ? `${urlWithoutParams}?${newParams}` : urlWithoutParams;
  _updateUrlWithoutRefresh(currentUrlWithNewQuery, history);
};