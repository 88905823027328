'use es6';

import partial from 'transmute/partial';
import { CONTACT, COMPANY, DEAL, TICKET, ENGAGEMENT, TASK } from 'customer-data-objects/constants/ObjectTypes';
import { getSearchResultsHandler, search, searchEditable } from 'crm_data/elasticSearch/api/ElasticSearchAPIInternals';
const parse = getSearchResultsHandler;
const searchContacts = partial(search, CONTACT);
const searchCompanies = partial(search, COMPANY);
const searchDeals = partial(search, DEAL);
const searchTickets = partial(search, TICKET);
const searchEngagements = partial(search, ENGAGEMENT);
const searchTasks = partial(search, TASK);

// allow `search` and `searchEditable` exports to be spied. imports are immutable in babel 7
const searchLocal = search;
const searchEditableLocal = searchEditable;
export { parse, searchLocal as search, searchEditableLocal as searchEditable, searchCompanies, searchContacts, searchDeals, searchTickets, searchEngagements, searchTasks };