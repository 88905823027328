import { getRelatedObjectTranslationPath } from './getRelatedObjectTranslationPath';
import I18n from 'I18n';
export const useGetTranslatedLabels = associationDefinitions => {
  return associationDefinitions.reduce((acc, def) => {
    // do not show unlabeled and primary association label
    if (def.hasAllAssociatedObjects || def.isPrimary) {
      return acc;
    }
    const nameKey = getRelatedObjectTranslationPath(def.name);
    if (def.name && nameKey) {
      acc.push(I18n.text(nameKey));
    } else if (def.label) {
      acc.push(def.label);
    }
    return acc;
  }, []).sort((a, b) => a.localeCompare(b, undefined, {
    caseFirst: 'upper'
  }));
};