import once from 'transmute/once';
export class ResizeObserverManager {
  constructor() {
    this.callbacks = new Map();
    this.resizeObserver = new ResizeObserver((entries, observer) => {
      for (let i = 0; i < entries.length; i++) {
        const callback = this.callbacks.get(entries[i].target);
        if (callback) {
          callback(entries[i], observer);
        }
      }
    });
  }
  subscribe(target, callback) {
    this.resizeObserver.observe(target);
    this.callbacks.set(target, callback);
  }
  unsubscribe(target) {
    if (!this.callbacks.has(target)) {
      return;
    }
    this.resizeObserver.unobserve(target);
    this.callbacks.delete(target);
  }
}
export const getResizeObserverManager = once(() => new ResizeObserverManager());