import { useCallback } from 'react';
import { useTimelineDispatch, useTimelineSelector } from '../../reduxHooks';
import { makeGetIsEventCardEditing, startEditing, endEditing, setIsForcingIntersection } from '../eventCardsSlice';
import { batch } from 'react-redux';
export function useIsEditing(eventId) {
  const getIsEditing = makeGetIsEventCardEditing(eventId);
  const isEditing = useTimelineSelector(getIsEditing);
  const dispatch = useTimelineDispatch();
  const setIsEditing = useCallback(isEditingFromCard => {
    if (isEditingFromCard) {
      batch(() => {
        dispatch(startEditing(eventId));
        dispatch(setIsForcingIntersection(eventId));
      });
    } else {
      dispatch(endEditing(eventId));
    }
  }, [dispatch, eventId]);
  return {
    isEditing,
    setIsEditing
  };
}