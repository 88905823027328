// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import CompaniesStore from 'crm_data/companies/CompaniesStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import ContactsStore from 'crm_data/contacts/ContactsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import DealsStore from 'crm_data/deals/DealsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { EMPTY } from 'crm_data/flux/LoadingStatus';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import IsUngatedStore from 'crm_data/gates/IsUngatedStore';
import QueuesDependency from './QueuesDependency';
import { TASK_UID_PATH } from '../../constants/TimelineTaskConstants';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { List } from 'immutable';
// @ts-expect-error not typed
import SequenceEnrollmentStore from 'sequences-crm-lib/flux/SequenceEnrollmentStore';
import TaskDependencyStore from '../stores/TaskDependencyStore';
const _createDeref = (Store, idKey) => props => {
  const ids = props.timelineItem.getIn(['eventData', 'associations', idKey]);
  if (!ids) {
    return List();
  }
  return Store.get(ids) && Store.get(ids).toList() || List();
};
export default Object.assign({
  associatedContacts: {
    stores: [ContactsStore],
    deref: _createDeref(ContactsStore, 'contactIds')
  },
  associatedCompanies: {
    stores: [CompaniesStore],
    deref: _createDeref(CompaniesStore, 'companyIds')
  },
  associatedDeals: {
    stores: [DealsStore],
    deref: _createDeref(DealsStore, 'dealIds')
  },
  sequenceEnrollment: {
    stores: [SequenceEnrollmentStore],
    deref(props) {
      const {
        subjectId,
        objectType
      } = props;
      const vid = subjectId;
      if (objectType !== CONTACT || subjectId == null) {
        return EMPTY;
      }
      return SequenceEnrollmentStore.get(parseInt(vid, 10));
    }
  },
  taskDependency: {
    stores: [IsUngatedStore, TaskDependencyStore],
    deref({
      engagement
    }) {
      if (!engagement) {
        return null;
      }
      return TaskDependencyStore.get(engagement.getIn(TASK_UID_PATH));
    }
  }
}, QueuesDependency);