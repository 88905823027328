import { useMemo } from 'react';
import useQueryUnlabeledAssociationDefinition from '../../hooks/useQueryUnlabeledAssociationDefinition';
import { buildOpenObjectBuilderActionHook } from './buildOpenObjectBuilderAction';
import useQueryAssociationDefinition from '../../hooks/useQueryAssociationDefinition';
export function useOpenRecordAssociationFormAction({
  objectTypeId,
  associationSpec,
  association
}) {
  // Extracts definition for the associationSpec
  const {
    associationDefinition: associationDefinitionForAssociationSpec
  } = useQueryAssociationDefinition(associationSpec, {
    skip: !associationSpec
  });

  // Extracts definition if objectTypeId is valid or associationSpec is undefined
  const {
    unlabeledAssociationDefinition
  } = useQueryUnlabeledAssociationDefinition({
    fromObjectTypeId: association.objectTypeId,
    toObjectTypeId: objectTypeId
  }, {
    skip: !objectTypeId || !!associationSpec
  });
  const definitionToUse = associationDefinitionForAssociationSpec !== null && associationDefinitionForAssociationSpec !== void 0 ? associationDefinitionForAssociationSpec : unlabeledAssociationDefinition;
  const associationConfig = useMemo(() => {
    if (definitionToUse) {
      const {
        inverseId: associationTypeId,
        category: associationCategory
      } = definitionToUse;
      return {
        objectTypeId: objectTypeId !== null && objectTypeId !== void 0 ? objectTypeId : definitionToUse.toObjectTypeId,
        association: {
          associationTypeId,
          associationCategory,
          objectTypeId: association.objectTypeId,
          objectId: association.objectId
        }
      };
    }
    return {
      objectTypeId: objectTypeId
    };
  }, [association, objectTypeId, definitionToUse]);
  return buildOpenObjectBuilderActionHook()(associationConfig);
}