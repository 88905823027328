import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { useCallback } from 'react';
import { useGetActivityCreatorErrors } from '../../hooks/useGetActivityCreatorErrors';
export const buildActivityCreatorActionHook = interactionType => {
  return ({
    objectId,
    objectTypeId,
    windowVariant
  }) => {
    const sendOpenActivityCreator = useSendCrmMessageTopic(TOPIC_NAMES.OPEN_ACTIVITY_CREATOR);
    const callback = useCallback(() => {
      sendOpenActivityCreator({
        interactionType,
        objectId,
        objectTypeId,
        windowVariant
      });
    }, [sendOpenActivityCreator, objectId, objectTypeId, windowVariant]);
    const errors = useGetActivityCreatorErrors({
      interactionType,
      objectId,
      objectTypeId
    });
    return errors.length > 0 ? {
      errors
    } : {
      callback
    };
  };
};