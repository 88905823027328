'use es6';

import { Map as ImmutableMap } from 'immutable';
import memoize from 'transmute/memoize';
import { stringify } from 'hub-http/helpers/params';
import { COMPANY, CONTACT, DEAL, ENGAGEMENT, TASK, TICKET, VISIT } from 'customer-data-objects/constants/ObjectTypes';
import CompanyRecord from 'customer-data-objects/company/CompanyRecord';
import ContactRecord from 'customer-data-objects/contact/ContactRecord';
import DealRecord from 'customer-data-objects/deal/DealRecord';
import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
import TicketRecord from 'customer-data-objects/ticket/TicketRecord';
import VisitRecord from 'customer-data-objects/visit/VisitRecord';
import { updateCompanies } from 'crm_data/companies/CompaniesActions';
import { updateContacts } from 'crm_data/contacts/ContactsActions';
import { isEligible } from 'crm_data/crmSearch/isEligible';
import { updateDeals } from 'crm_data/deals/DealsActions';
import { updateEngagements } from 'crm_data/engagements/EngagementsActions';
import { updateTickets } from 'crm_data/tickets/TicketsActions';
import { updateCompanies as updateCompaniesForVisits } from 'crm_data/visits/VisitsActions';
import { getQueryParamStr } from 'crm_data/contacts/api/ContactsExtraParams';
import { isObjectTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { updateCrmObjects } from 'crm_data/crmObjects/CrmObjectActions';
import CrmObjectRecord from 'customer-data-objects/crmObject/CrmObjectRecord';
const CRM_SEARCH_BASE = 'sales-views/v2/crm-search/with-placeholders';
const SEARCH_API_BASE = 'contacts/search/v1/search/';
const API_INFO = ImmutableMap({
  [CONTACT]: {
    updateStore: updateContacts,
    Record: ContactRecord,
    dataKey: 'contacts'
  },
  [COMPANY]: {
    updateStore: updateCompanies,
    Record: CompanyRecord,
    dataKey: 'companies'
  },
  [DEAL]: {
    updateStore: updateDeals,
    Record: DealRecord,
    dataKey: 'deals'
  },
  [ENGAGEMENT]: {
    updateStore: updateEngagements,
    Record: EngagementRecord,
    dataKey: 'engagements'
  },
  [TASK]: {
    updateStore: updateEngagements,
    Record: EngagementRecord,
    dataKey: 'engagements'
  },
  [TICKET]: {
    updateStore: updateTickets,
    Record: TicketRecord,
    dataKey: 'results'
  },
  [VISIT]: {
    updateStore: updateCompaniesForVisits,
    Record: VisitRecord,
    dataKey: 'results'
  }
});
const buildUrl = (url, ...rawParams) => {
  const params = rawParams.filter(p => !!p);
  return params.length ? `${url}?${params.join('&')}` : url;
};
const getApiUrls = memoize((queryString = '') => {
  return ImmutableMap({
    [CONTACT]: buildUrl(`${SEARCH_API_BASE}contacts`, getQueryParamStr(), queryString),
    [COMPANY]: buildUrl(`${SEARCH_API_BASE}companies/v2`, queryString),
    [DEAL]: buildUrl(`${SEARCH_API_BASE}deals`, queryString),
    [ENGAGEMENT]: `${SEARCH_API_BASE}engagements`,
    [TASK]: `${SEARCH_API_BASE}engagements`,
    [TICKET]: `${SEARCH_API_BASE}services/tickets?includeAllValues=true&allPropertiesFetchMode=latest_version`,
    [VISIT]: 'companyprospects/v1/prospects/search'
  });
});
export function elasticSearchApiInfo(objectType) {
  if (isObjectTypeId(objectType)) {
    return {
      updateStore: updateCrmObjects,
      Record: CrmObjectRecord,
      dataKey: 'results'
    };
  }
  const info = API_INFO.get(objectType);
  if (isEligible(objectType)) {
    return Object.assign({}, info, {
      dataKey: 'results'
    });
  }
  return info;
}
export function elasticSearchApiUrl(objectType, queryParams) {
  if (isObjectTypeId(objectType) || isEligible(objectType)) {
    const queryString = stringify(queryParams);
    return `${CRM_SEARCH_BASE}${queryString ? `?${queryString}` : ''}`;
  }
  return getApiUrls(stringify(queryParams)).get(objectType);
}