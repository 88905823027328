// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
import { ENGAGEMENT } from 'customer-data-objects/constants/ObjectTypes';
const API = 'commenting/v1/commenting';
export const COMMENT_LIMIT_PER_ENGAGEMENT = 10;
export const BATCH_COMMENT_LIMIT = 1000;
export function fetchComments(engagementId, offset = 0) {
  return ImmutableAPI.get(`${API}/ENGAGEMENT/${engagementId}?includeAtMentionUserIds=true&limit=${COMMENT_LIMIT_PER_ENGAGEMENT}&offset=${offset}`);
}
export function batchFetchComments(engagementIds) {
  const body = {
    objectIds: engagementIds.flatten()
  };
  return ImmutableAPI.post(`${API}/batch/ENGAGEMENT?includeAtMentionUserIds=true&limit=${BATCH_COMMENT_LIMIT}`, body);
}
export function deleteComment(commentId) {
  return ImmutableAPI.delete(`${API}/${commentId}`);
}
export function addComment(engagementId, message, atMentionsOwnerIds, ctaUrl) {
  const body = {
    objectType: ENGAGEMENT,
    objectId: `${engagementId}`,
    atMentionsOwnerIds,
    message,
    ctaUrl
  };
  return ImmutableAPI.post(API, body);
}
export function editComment(commentId, message, atMentionsOwnerIds, ctaUrl) {
  const body = {
    atMentionsOwnerIds,
    message,
    ctaUrl
  };
  return ImmutableAPI.patch(`${API}/${commentId}`, body);
}