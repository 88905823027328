import { wrapPromise } from 'persist-promise/wrapPromise';
const toCacheKey = (objectTypeId, objectId) => `${objectTypeId}/${objectId}`;
const convertKeyToMetricsDimension = key => {
  const objectTypeId = key.split('/')[0];
  if (objectTypeId.startsWith('2-')) {
    return 'customObject';
  }
  return objectTypeId;
};
export const trackCrmObjectChangesClient = wrapPromise({
  namespace: 'Records',
  entityName: 'CrmObject',
  toCacheKey,
  fetch: (__objectTypeId, __objectId, crmObject) => Promise.resolve(crmObject),
  metricsConfig: {
    enablePatchDiffing: true,
    convertKeyToMetricsDimension,
    normalizeForPatchDiffing: crmObject => {
      var _crmObject$allPropert;
      return {
        id: crmObject === null || crmObject === void 0 ? void 0 : crmObject.id,
        properties: Object.fromEntries((crmObject === null || crmObject === void 0 || (_crmObject$allPropert = crmObject.allProperties) === null || _crmObject$allPropert === void 0 ? void 0 : _crmObject$allPropert.map(({
          name,
          value
        }) => [name, value])) || [])
      };
    }
  }
});