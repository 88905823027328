import makeBaseSampleTimelineEvent from './makeBaseSampleTimelineEvent';
/*

  This interface has two intended purposes:
    1. to enforce consistency between sample event factories.
    2. Abstract away implementation details for composing a sample
       event from the base sample event factory (makeBaseSampleTimelineEvent).

*/

const SampleEventFactoryInterface = (interfaceData = {
  // Data required for all timeline events.
  requiredData: {},
  // Data specific to the event type.
  eventDefaults: {},
  // Callback for building unique event type,
  // expected to return an eventData object.
  build: null
}) => {
  const {
    requiredData,
    eventDefaults,
    build
  } = interfaceData;

  // Common defaults which all sample events share.
  const commonEventDefaults = Object.assign({}, eventDefaults, {
    created: Date.now()
  });
  return (sampleEventData = {}) => {
    sampleEventData = Object.assign({}, commonEventDefaults, sampleEventData);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'created' does not exist on type '{}'.
    const {
      created,
      startTime,
      timestamp,
      ownerId
    } = sampleEventData;
    // Make the base sample event
    requiredData.created = created;
    requiredData.timestamp = startTime || timestamp || created;
    const SampleEvent = makeBaseSampleTimelineEvent(requiredData, ownerId);

    // Override specific sample event data
    if (typeof build === 'function') {
      SampleEvent.eventData = Object.assign({}, SampleEvent.eventData,
      // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
      build(sampleEventData));
    }
    return SampleEvent;
  };
};
export default SampleEventFactoryInterface;