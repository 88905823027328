import { useQuery, gql } from '@apollo/client';
const FETCH_PAYMENTS_PROCESSOR_TYPE = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"fetchPaymentsProcessorType\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"paymentsEligibility\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"processorType\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "fetchPaymentsProcessorType"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "paymentsEligibility"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "processorType"
            }
          }]
        }
      }]
    }
  }]
});
const useFetchPaymentsProcessorType = skip => {
  const {
    data
  } = useQuery(FETCH_PAYMENTS_PROCESSOR_TYPE, {
    skip
  });
  return data && data.paymentsEligibility && data.paymentsEligibility.processorType;
};
export default useFetchPaymentsProcessorType;