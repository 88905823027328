import { EMAIL, MEETING, CALL, POSTAL_MAIL, WHATS_APP, SMS, LINKEDIN_MESSAGE } from 'customer-data-objects/engagement/EngagementTypes';
import { LOGGED_CALL, LOGGED_EMAIL, LOGGED_MEETING, LOGGED_WHATS_APP, LOGGED_SMS, LOGGED_LINKED_IN_MESSAGE, LOGGED_POSTAL_MAIL } from 'customer-data-objects/interaction/InteractionTypes';
import { CONVERSATIONS } from 'customer-data-objects/property/PropertySourceTypes';
import getIn from 'transmute/getIn';
import hasIn from 'transmute/hasIn';
const isLoggedEmail = ({
  engagement,
  timelineItem,
  type
}) => {
  if (type !== EMAIL) {
    return false;
  }
  if (engagement) {
    return !(hasIn(['metadata', 'trackerKey'], engagement) || hasIn(['metadata', 'threadId'], engagement) || getIn(['engagement', 'source'], engagement) === CONVERSATIONS);
  }
  return !(hasIn(['eventData', 'metadata', 'trackerKey'], timelineItem) || hasIn(['eventData', 'metadata', 'threadId'], timelineItem) || getIn(['eventData', 'engagement', 'source'], timelineItem) === CONVERSATIONS);
};
const isLoggedMeeting = ({
  engagement,
  timelineItem,
  type
}) => {
  if (type !== MEETING) {
    return false;
  }
  if (engagement) {
    return !hasIn(['metadata', 'title'], engagement);
  }
  return !hasIn(['eventData', 'metadata', 'title'], timelineItem);
};
const isLoggedCall = ({
  engagement,
  timelineItem,
  type
}) => {
  if (type !== CALL) {
    return false;
  }
  if (engagement) {
    return !hasIn(['metadata', 'fromNumber'], engagement) && !hasIn(['metadata', 'toNumber'], engagement);
  }
  return !hasIn(['eventData', 'metadata', 'fromNumber'], timelineItem) && !hasIn(['eventData', 'metadata', 'toNumber'], timelineItem);
};
const isLoggedWhatsApp = ({
  engagement,
  timelineItem,
  type
}) => {
  if (type !== WHATS_APP) {
    return false;
  }
  if (engagement) {
    return getIn(['metadata', 'loggedFrom'], engagement) === 'CRM';
  }
  return getIn(['eventData', 'metadata', 'loggedFrom'], timelineItem) === 'CRM';
};
const isLoggedSMS = ({
  engagement,
  timelineItem,
  type
}) => {
  if (type !== SMS) {
    return false;
  }
  if (engagement) {
    return getIn(['metadata', 'loggedFrom'], engagement) === 'CRM';
  }
  return getIn(['eventData', 'metadata', 'loggedFrom'], timelineItem) === 'CRM';
};
const isLoggedLinkedinMessage = ({
  engagement,
  timelineItem,
  type
}) => {
  if (type !== LINKEDIN_MESSAGE) {
    return false;
  }
  if (engagement) {
    return getIn(['metadata', 'loggedFrom'], engagement) === 'CRM';
  }
  return getIn(['eventData', 'metadata', 'loggedFrom'], timelineItem) === 'CRM';
};
function isLoggedPostalMail({
  type
}) {
  return type === POSTAL_MAIL;
}

/**
 * Engagement types = EMAIL, CALL, NOTE, TASK, MEETING, SMS, WHATS_APP, POSTAL MAIL
 * Interaction types = LOGGED_EMAIL, LOGGED_CALL, LOGGED_MEETING, LOGGED_SMS, LOGGED_WHATS_APP, LOGGED_POSTAL_MAIL
 */
export const getEngagementOrInteractionType = ({
  engagement,
  timelineItem
}) => {
  if (!engagement && !timelineItem) {
    return null;
  }
  if (engagement) {
    const engagementType = getIn(['engagement', 'type'], engagement);
    if (isLoggedEmail({
      engagement,
      type: engagementType
    })) {
      return LOGGED_EMAIL;
    }
    if (isLoggedMeeting({
      engagement,
      type: engagementType
    })) {
      return LOGGED_MEETING;
    }
    if (isLoggedCall({
      engagement,
      type: engagementType
    })) {
      return LOGGED_CALL;
    }
    if (isLoggedPostalMail({
      type: engagementType
    })) {
      return LOGGED_POSTAL_MAIL;
    }
    if (isLoggedWhatsApp({
      engagement,
      type: engagementType
    })) {
      return LOGGED_WHATS_APP;
    }
    if (isLoggedSMS({
      engagement,
      type: engagementType
    })) {
      return LOGGED_SMS;
    }
    if (isLoggedLinkedinMessage({
      engagement,
      type: engagementType
    })) {
      return LOGGED_LINKED_IN_MESSAGE;
    }
    return engagementType;
  }
  const engagementType = getIn(['eventData', 'engagement', 'type'], timelineItem);
  if (isLoggedEmail({
    timelineItem,
    type: engagementType
  })) {
    return LOGGED_EMAIL;
  }
  if (isLoggedMeeting({
    timelineItem,
    type: engagementType
  })) {
    return LOGGED_MEETING;
  }
  if (isLoggedCall({
    timelineItem,
    type: engagementType
  })) {
    return LOGGED_CALL;
  }
  if (isLoggedWhatsApp({
    timelineItem,
    type: engagementType
  })) {
    return LOGGED_WHATS_APP;
  }
  if (isLoggedSMS({
    timelineItem,
    type: engagementType
  })) {
    return LOGGED_SMS;
  }
  if (isLoggedLinkedinMessage({
    timelineItem,
    type: engagementType
  })) {
    return LOGGED_LINKED_IN_MESSAGE;
  }
  if (isLoggedPostalMail({
    type: engagementType
  })) {
    return LOGGED_POSTAL_MAIL;
  }
  return engagementType;
};