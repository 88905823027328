'use es6';

import * as params from 'hub-http/helpers/params';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
export function useQueryParams() {
  const {
    search
  } = useLocation();
  const queryParams = useMemo(() => params.parse(search.substring(1)), [search]);
  return queryParams;
}