'use es6';

import { SUCCEEDED } from '../actions/ActionSteps';
import { DELETE, UPDATE } from '../actions/ActionVerbs';
import { makeAsyncActionType, makeAction } from '../actions/MakeActions';
import { defineFactory } from 'general-store';
import { Iterable, Map as ImmutableMap, Seq } from 'immutable';
import invariant from 'react-utils/invariant';
function isIterable(ids) {
  return Iterable.isIterable(ids) || Array.isArray(ids);
}
function enforceId(idIsValid, namespace, id) {
  invariant(idIsValid(id), '`%s` expected `id` to pass `idIsValid` but got `%s`', namespace, id);
  return id;
}
function baseNormalizeIds(idIsValid, idTransform, namespace, ids) {
  if (idIsValid(ids)) {
    return Seq.of(idTransform(ids));
  }
  invariant(isIterable(ids), '`%s` expected `ids` to be an `Iterable` or an `Array` but got `%s`', namespace, ids);
  return Seq(ids).map(enforceId.bind(null, idIsValid, namespace)).map(idTransform).toSet();
}
function defaultIdTransform(id) {
  return id;
}
const initialState = ImmutableMap;
export function defineKeyStore({
  namespace,
  getInitialState = initialState,
  idIsValid,
  idTransform = defaultIdTransform
}) {
  invariant(typeof idIsValid === 'function', 'expected `idIsValid` to be a function but got `%s`', idIsValid);
  const DELETE_SUCCEEDED = makeAsyncActionType(namespace, DELETE, SUCCEEDED);
  const UPDATE_SUCCEEDED = makeAsyncActionType(namespace, UPDATE, SUCCEEDED);
  const CLEARED = makeAction(namespace, 'CLEARED');
  const normalizeIds = baseNormalizeIds.bind(null, idIsValid, idTransform, namespace);
  return defineFactory().defineName(`${namespace}_KeyStore`).defineGetInitialState(getInitialState).defineGet((state, ids) => {
    invariant(ids !== undefined, 'expected `ids` to not be undefined');
    const idList = normalizeIds(ids);
    if (idList.isEmpty()) {
      return ids;
    }

    // if it's a single id, just return the value
    if (idIsValid(ids)) {
      return state.get(idTransform(ids));
    }
    return ids.map(id => state.get(idTransform(id)));
  }).defineResponseTo(DELETE_SUCCEEDED, (state, keys) => {
    return state.withMutations(nextState => {
      keys.forEach(key => nextState.set(idTransform(key), null));
      return nextState;
    });
  }).defineResponseTo(UPDATE_SUCCEEDED, (state, updatedObjectFragments) => {
    return state.mergeDeep(updatedObjectFragments.reduce((acc, val, key) => acc.set(idTransform(key), val), ImmutableMap()));
  }).defineResponseTo(CLEARED, () => getInitialState());
}