import { associate, disassociate
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/associations/AssociationsActions';
import { CONTACT, COMPANY, DEAL, TICKET, ENGAGEMENT } from 'customer-data-objects/constants/ObjectTypes';
import difference from 'transmute/difference';
import ObjectAssociationTypes from 'customer-data-objects/constants/ObjectAssociationTypes';
// Given an engagement's existing associations and new associations,
// computes the difference and calls associate or disassociate for each
// record to associate or disassociate with the engagement.
const objectTypes = [CONTACT, COMPANY, DEAL, TICKET];
function addAssociation(subjectId, objectType, associationsToAdd) {
  if (!associationsToAdd.size) {
    return;
  }
  associate(subjectId, associationsToAdd, ENGAGEMENT, objectType);
}
function removeAssociation(subjectId, objectType, associationsToRemove) {
  if (!associationsToRemove.size) {
    return;
  }
  associationsToRemove.map(associationToRemove => {
    return disassociate(subjectId, associationToRemove, ENGAGEMENT, objectType);
  });
}
export function updateEngagementAssociations(engagementId, oldAssociations, newAssociations) {
  if (!oldAssociations.equals(newAssociations)) {
    objectTypes.forEach(objectType => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const objectKey = ObjectAssociationTypes[objectType];
      if (!oldAssociations.get(objectKey).equals(newAssociations.get(objectKey))) {
        const associationsToAdd = difference(oldAssociations.get(objectKey), newAssociations.get(objectKey));
        const associationsToRemove = difference(newAssociations.get(objectKey), oldAssociations.get(objectKey));
        addAssociation(engagementId, objectType, associationsToAdd);
        removeAssociation(engagementId, objectType, associationsToRemove);
      }
    });
  }
}