import { Map as ImmutableMap } from 'immutable';
import * as PinnedEngagementsActionTypes from '../actions/PinnedEngagementActionTypes';
import { getPinnedEngagement } from '../actions/PinnedEngagementsActions';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { EMPTY } from 'crm_data/constants/LoadingStatus';
import { defineLazyKeyStore, registerLazyKeyService
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/store/LazyKeyStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { PINNED_ENGAGEMENT } from 'crm_data/actions/ActionNamespaces';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import makeBatch from 'crm_data/api/makeBatch';
import { ENGAGEMENTS_UPDATED, TASKS_AND_ENGAGEMENTS_UPDATED
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
const {
  PIN_ENGAGEMENT,
  UNPIN_ENGAGEMENT
} = PinnedEngagementsActionTypes;
const NO_PINNED_ENGAGEMENT_METADATA = ImmutableMap({
  engagementId: EMPTY,
  exists: false
});
const batchFetch = makeBatch(id => {
  const objectType = id.get('objectType');
  const subjectId = id.get('subjectId');
  return getPinnedEngagement(objectType, subjectId);
}, 'PinnedEngagementsStore.getPinnedEngagement');
const _makeKey = (objectType, subjectId) => ImmutableMap({
  objectType,
  subjectId
});
const handleDeletedEngagement = (state, engagementsMap) => {
  const deletedPinnedEngagementMetadata = state.valueSeq().find(pinnedEngagementMetadata => engagementsMap.has(pinnedEngagementMetadata.get('engagementId')) && engagementsMap.get(pinnedEngagementMetadata.get('engagementId')) === null);
  if (deletedPinnedEngagementMetadata) {
    const key = state.findKey(pinnedEngagementMetadata => pinnedEngagementMetadata.get('engagementId') === deletedPinnedEngagementMetadata.get('engagementId'));
    return state.set(key, NO_PINNED_ENGAGEMENT_METADATA);
  }
  return state;
};
registerLazyKeyService({
  namespace: PINNED_ENGAGEMENT,
  fetch: batchFetch
});
const PinnedEngagementsStore = defineLazyKeyStore({
  idIsValid(key) {
    return ImmutableMap.isMap(key) && typeof key.get('subjectId') === 'string' && typeof key.get('objectType') === 'string';
  },
  namespace: PINNED_ENGAGEMENT
}).defineResponseTo(PIN_ENGAGEMENT, (state, {
  subjectId,
  objectType,
  engagementId
}) => {
  const key = _makeKey(objectType, subjectId);
  return state.set(key, ImmutableMap({
    engagementId,
    exists: true
  }));
}).defineResponseTo(UNPIN_ENGAGEMENT, (state, {
  subjectId,
  objectType
}) => {
  const key = _makeKey(objectType, subjectId);
  return state.set(key, NO_PINNED_ENGAGEMENT_METADATA);
}).defineResponseTo(ENGAGEMENTS_UPDATED, (state, engagementsMap) => {
  return handleDeletedEngagement(state, engagementsMap);
}).defineResponseTo(TASKS_AND_ENGAGEMENTS_UPDATED, (state, {
  engagementsMap
}) => {
  return handleDeletedEngagement(state, engagementsMap);
}).register();
export default PinnedEngagementsStore;