import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
const initialState = {
  currentSearchValue: '',
  isSearchInUse: false,
  isTeamFilterOpen: false,
  isUserFilterOpen: false,
  selectedTab: undefined
};
const timelineControlsSlice = createSlice({
  name: 'timelineControls',
  initialState,
  reducers: {
    startSearch: state => {
      state.isSearchInUse = true;
    },
    endSearch: state => {
      state.isSearchInUse = false;
    },
    openTeamFilter: state => {
      state.isTeamFilterOpen = true;
    },
    closeTeamFilter: state => {
      state.isTeamFilterOpen = false;
    },
    openUserFilter: state => {
      state.isUserFilterOpen = true;
    },
    closeUserFilter: state => {
      state.isUserFilterOpen = false;
    },
    setCurrentSearchValue: (state, action) => {
      state.currentSearchValue = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    }
  }
});
export const timelineControlsReducer = timelineControlsSlice.reducer;
export const {
  startSearch,
  endSearch,
  openTeamFilter,
  closeTeamFilter,
  openUserFilter,
  closeUserFilter,
  setCurrentSearchValue,
  setSelectedTab
} = timelineControlsSlice.actions;
export const timelineControlsCaseReducers = timelineControlsSlice.caseReducers;
const getTimelineControlsState = timelineControlsState => timelineControlsState;
export const getCurrentSearchValue = createSelector([getTimelineControlsState], incoming => {
  return incoming.timelineControls['currentSearchValue'];
});
export const getIsSearchInUse = createSelector([getTimelineControlsState], incoming => {
  return incoming.timelineControls['isSearchInUse'];
});
export const getIsTeamFilterOpen = createSelector([getTimelineControlsState], incoming => {
  return incoming.timelineControls['isTeamFilterOpen'];
});
export const getIsUserFilterOpen = createSelector([getTimelineControlsState], incoming => {
  return incoming.timelineControls['isUserFilterOpen'];
});
export const getSelectedTab = createSelector([getTimelineControlsState], incoming => {
  return incoming.timelineControls['selectedTab'];
});