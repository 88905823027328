'use es6';

import { Record, Map as ImmutableMap, fromJS } from 'immutable';
import addVyeTokensToEditorState from 'draft-plugins/utils/addVyeTokensToEditorState';
import removeVyeTokensFromEditorState from 'draft-plugins/utils/removeVyeTokensFromEditorState';
const RichTextEditorResultsRecord = Record({
  editorState: null,
  contentChanged: false,
  contentMediator: null,
  putEditorState: null,
  customPlugins: null
}, 'RichTextEditorResultsRecord');
export default class RichTextEditorResults extends RichTextEditorResultsRecord {
  isProsemirror() {
    return false;
  }
  hasState() {
    return !!this.editorState;
  }
  hasContent() {
    return this.hasState() && this.contentMediator.hasContent(this.editorState);
  }
  isUploadingImages() {
    return this.hasState() && this.contentMediator.isUploadingImages(this.editorState.getCurrentContent());
  }
  toRawEditorContent() {
    return fromJS(this.contentMediator.toJson(this.editorState.getCurrentContent()));
  }
  pushRawEditorContent(rawEditorContent) {
    if (ImmutableMap.isMap(rawEditorContent)) {
      rawEditorContent = rawEditorContent.toJS();
    }
    const contentState = this.contentMediator.fromJson(rawEditorContent);
    const editorState = this.contentMediator.setContent(this.editorState, contentState);
    return this.setEditorState(editorState);
  }
  checkElementAndChildrenForAtMentions(element) {
    if (element.hasAttribute('data-mention-id') && element.innerText.includes('@')) {
      element.innerText = element.innerText.replace('@', '');
    }
    for (let i = 0; i < element.children.length; i++) {
      const childElement = element.children[i];
      this.checkElementAndChildrenForAtMentions(childElement);
    }
  }
  pushContent(html, undoable = false) {
    // remove @ from atMentions if importing from prosemirror
    if (html && html.includes('data-mention-id')) {
      const domParser = new DOMParser();
      const tempDoc = domParser.parseFromString(html, 'text/html');
      this.checkElementAndChildrenForAtMentions(tempDoc.body);
      html = tempDoc.body.innerHTML;
    }
    const contentState = this.contentMediator.fromHtml(html);
    const editorState = this.contentMediator.setContent(this.editorState, contentState, undoable);
    return this.setEditorState(editorState);
  }
  resetEditorState() {
    const editorState = this.contentMediator.resetContent();
    return this.setEditorState(editorState);
  }
  setEditorState(editorState, contentChanged = true) {
    return this.merge({
      editorState,
      contentChanged
    });
  }
  setEditorStateWithoutFocus(editorState, contentChanged = true) {
    return this.setEditorState(editorState, contentChanged);
  }
  manageVideoTokens(allRecipientCount, recipientEmail) {
    if (this.numberOfVideos() === 0) {
      return Promise.resolve(this);
    }
    const shouldAddTokens = allRecipientCount === 1 && recipientEmail;
    if (shouldAddTokens) {
      return addVyeTokensToEditorState(this.editorState, recipientEmail).then(this.setEditorState.bind(this));
    }
    const stateWithoutTokens = removeVyeTokensFromEditorState(this.editorState);
    return Promise.resolve(this.setEditorState.call(this, stateWithoutTokens));
  }

  // Caution, this operation is expensive, do not call on every change!
  html() {
    if (!this._html) {
      this._html = this.contentMediator.toHtml(this.editorState.getCurrentContent());
    }
    return this._html;
  }
  plainText() {
    if (!this._plainText) {
      this._plainText = this.editorState.getCurrentContent().getPlainText();
    }
    return this._plainText;
  }
  atMentions() {
    if (!this._atMentions) {
      this._atMentions = this.contentMediator.getAtMentions(this.editorState.getCurrentContent());
    }
    return this._atMentions;
  }
  numberOf(property, getter) {
    if (!this[property]) {
      this[property] = getter(this.editorState.getCurrentContent());
    }
    return this[property];
  }
  numberOfImages() {
    return this.numberOf('_numberOfImages', this.contentMediator.getNumberOfImages);
  }
  numberOfVideos() {
    return this.numberOf('_numberOfVideos', this.contentMediator.getNumberOfVideos);
  }
  numberOfLinks() {
    return this.numberOf('_numberOfLinks', this.contentMediator.getNumberOfLinks);
  }
  transform(transforms, opts) {
    delete this._html;
    delete this._plainText;
    delete this._atMentions;
    return this.setEditorState(transforms(this.editorState, opts), this.contentChanged);
  }
}