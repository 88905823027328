import I18n from 'I18n';
import formatName from 'I18n/utils/formatName';
import isUndefined from 'transmute/isUndefined';
import { getTextContentFromHtml } from 'sanitize-text/sanitizers/HtmlSanitizer';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { isOnlyActiveSalesforceOwner } from 'customer-data-objects/owners/isActiveOwner';
export default ((user, options) => {
  options = options || {};
  let label = '';
  if (user) {
    let name;
    if (user.toJS) {
      user = user.toJS();
    }
    if (user.toJSON) {
      user = user.toJSON();
    }
    const firstName = user.firstName || user.firstname;
    const lastName = user.lastName || user.lastname;
    const {
      email
    } = user;
    const active = isUndefined(user.active) ? true : user.active;
    const includeEmail = isUndefined(options.includeEmail) ? true : options.includeEmail;
    const isSafeString = options.isSafeString || false;
    const formatNameOptions = {
      firstName,
      lastName
    };
    if (!active) {
      name = email || formatName(formatNameOptions);
      if (isSafeString) {
        name = I18n.SafeString(name);
      }
      label = I18n.text('owners.nameAndDeactivatedUser', {
        name
      });
    } else if (firstName && firstName.length || lastName && lastName.length) {
      label = formatName(formatNameOptions);
      if (isOnlyActiveSalesforceOwner(user)) {
        if (isSafeString) {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'SafeString' is not assignable to type 'strin... Remove this comment to see the full error message
          label = I18n.SafeString(label);
        }
        label = I18n.text('owners.salesforce', {
          name: label
        });
      } else if (email && email.length && includeEmail) {
        label += ` (${email})`;
      }
    } else if (email && email.length) {
      label = email;
    }
  }

  // @ts-expect-error ts-migrate(2551) FIXME: Property 'string' does not exist on type 'string'.... Remove this comment to see the full error message
  if (label.string) {
    // @ts-expect-error ts-migrate(2551) FIXME: Property 'string' does not exist on type 'string'.... Remove this comment to see the full error message
    return I18n.SafeString(getTextContentFromHtml(label.string));
  } else {
    return getTextContentFromHtml(label);
  }
});