import { ASSOC_DEAL_REGISTRATIONS_META } from '../actions/ActionNamespaces';
import { defineLazyKeyStore, registerLazyKeyService
// @ts-expect-error missing dependency types
} from 'crm_data/store/LazyKeyStore';
import { getAssociatedDealRegistrationIdsBatch } from '../sharedDealsCard/DealRegistrationActions';
registerLazyKeyService({
  namespace: ASSOC_DEAL_REGISTRATIONS_META,
  fetch: getAssociatedDealRegistrationIdsBatch
});
const AssociatedDealRegIdsStore = defineLazyKeyStore({
  namespace: ASSOC_DEAL_REGISTRATIONS_META,
  idIsValid: dealId => {
    return typeof dealId === 'string' || typeof dealId === 'number';
  }
}).register();
export default AssociatedDealRegIdsStore;