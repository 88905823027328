// @ts-expect-error Could not find a declaration file
import AttachmentsStore from 'crm_data/attachments/AttachmentsStore';
import { List } from 'immutable';
import identity from 'transmute/identity';
const EngagementAttachmentsDependency = {
  stores: [AttachmentsStore],
  deref: ({
    engagement
  }) => {
    if (!engagement || !engagement.get('attachments').size) {
      return List();
    }
    let attachmentIds = engagement.get('attachments');
    attachmentIds = attachmentIds.map(attachment => attachment.get('id')).filter(identity);
    return AttachmentsStore.get(attachmentIds).filter(identity);
  }
};
export default EngagementAttachmentsDependency;