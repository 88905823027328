import { DEAL_REGISTRATION_CRM_OBJECT } from '../actions/ActionNamespaces';
import { defineLazyKeyStore, registerLazyKeyService
// @ts-expect-error missing dependency types
} from 'crm_data/store/LazyKeyStore';
import { getDealRegistrationCrmObjectBatch } from '../sharedDealsCard/DealRegistrationActions';
registerLazyKeyService({
  namespace: DEAL_REGISTRATION_CRM_OBJECT,
  fetch: getDealRegistrationCrmObjectBatch
});
const DealRegistrationCrmObjectStore = defineLazyKeyStore({
  namespace: DEAL_REGISTRATION_CRM_OBJECT,
  idIsValid: id => {
    return typeof id === 'number';
  }
}).register();
export default DealRegistrationCrmObjectStore;