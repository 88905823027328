module.exports = {
  "communicatorInteraction": {
    "name": "communicator interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_fileInteraction": {
    "name": "communicator interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "fileType": {
        "type": "string"
      },
      "fileSource": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_bannerInteraction": {
    "name": "banner interaction",
    "class": "interaction",
    "properties": {
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "bannerName": {
        "type": "string",
        "isOptional": true
      },
      "cta": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicatorUsage_createFollowUpTask": {
    "name": "create followup task",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_emailActivation": {
    "name": "email activation",
    "class": "activation",
    "namespace": "communicator",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "communicator_firstEmailActivation": {
    "name": "first email activation",
    "class": "activation",
    "namespace": "communicator",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "communicator_timelineInteraction": {
    "name": "timeline interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "callType": {
        "type": "string",
        "isOptional": true
      },
      "outcomeType": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_formatText": {
    "name": "communicator interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "format text"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "formatType": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_saveSignature": {
    "name": "communicator interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "save signature"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "signatureType": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_recipientInteraction": {
    "name": "communicator interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "Add \"to\" recipient",
        "Add \"cc\" recipient",
        "Add \"bcc\" recipient"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "count": {
        "type": "number"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_sendEmail": {
    "name": "communicator interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "click to send email",
        "click to schedule email"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "emailType": "string",
      "replyAllCount": "number",
      "isReply": "boolean",
      "scheduleEmailType": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicatorUsage_sendEmail": {
    "name": "send email",
    "class": "usage",
    "properties": {
      "emailType": "string",
      "replyAllCount": "number",
      "isReply": "boolean",
      "source": "string",
      "numberOfAttachments": "number",
      "numberOfImages": "number",
      "numberOfLinks": "number",
      "numberOfVideos": "number",
      "scheduleEmailType": {
        "type": "string",
        "isOptional": true
      },
      "senderAccountType": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  }
};