'use es6';

import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
import AssociationTypeToAssociationTypeId from 'crm_data/associations/AssociationTypeToAssociationTypeId';
import { HUBSPOT_DEFINED } from 'crm_schema/association/AssociationCategoryTypes';
const API_WRITE = 'associations-writes/v1/associations';
export function fetch(key) {
  const objectType = key.get('objectType');
  const objectId = key.get('objectId');
  const associationType = key.get('associationType');
  const limit = key.get('limit');
  const offset = key.get('offset');
  const URI = `associations/v1/associations/${objectType}/${objectId}/${associationType}`;
  const query = {};
  if (offset) {
    query.offset = offset;
  }
  if (limit) {
    query.limit = limit;
  }
  return ImmutableAPI.get(URI, query);
}
export function associateObject({
  subjectId,
  objectIds,
  associationType,
  associationCategory,
  associationTypeId
}) {
  const associations = objectIds.map(objectId => {
    return {
      fromObjectId: subjectId,
      toObjectId: objectId,
      associationCategory: associationCategory || HUBSPOT_DEFINED,
      associationTypeId: associationTypeId || AssociationTypeToAssociationTypeId.get(associationType)
    };
  });
  return ImmutableAPI.put(API_WRITE, associations);
}
export function disassociateCrmObject({
  engagementId,
  objectId,
  associationTypeId,
  associationCategory
}) {
  const associationToRemove = [{
    fromObjectId: engagementId,
    toObjectId: objectId,
    associationCategory,
    associationTypeId
  }];
  return ImmutableAPI.put(`${API_WRITE}/delete`, associationToRemove);
}
export function disassociateObject({
  subjectId,
  objectId,
  associationType
}) {
  return ImmutableAPI.delete(`${API_WRITE}/${subjectId}/${associationType}/${objectId}`);
}
export function fetchAssociationDefinition(fromObjectTypeId, toObjectType) {
  return ImmutableAPI.get(`associations/v1/definitions/${fromObjectTypeId}/${toObjectType}`).then(res => {
    if (!res.size) {
      throw new Error('Association definition does not exist');
    }
    return res.first();
  }).catch(error => {
    throw error;
  });
}
export function fetchAllAssociationDefinitions(fromObjectTypeId) {
  return ImmutableAPI.get(`associations/v1/definitions/from-object-types?objectTypeId=${fromObjectTypeId}`);
}