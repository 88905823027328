'use es6';

import { fromJS, List, Map as ImmutableMap } from 'immutable';
import { PUT, POST } from 'crm_data/constants/HTTPVerbs';
import { send } from 'crm_data/api/ImmutableAPI';
import { CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
import userInfo from 'hub-http/userInfo';
import { engagementUpdatesToInboundDbProperties } from 'crm_data/engagements/inboundDbProperties/engagementInboundDbObjectHelpers';
import { ENGAGEMENT_TO_COMPANY, ENGAGEMENT_TO_CONTACT, ENGAGEMENT_TO_DEAL, ENGAGEMENT_TO_TICKET } from 'crm_data/associations/AssociationTypes';
import Raven from 'raven-js';
const ASSOCIATIONS_MAP = {
  contactIds: ENGAGEMENT_TO_CONTACT,
  companyIds: ENGAGEMENT_TO_COMPANY,
  dealIds: ENGAGEMENT_TO_DEAL,
  ticketIds: ENGAGEMENT_TO_TICKET
};
import { COMMON_PROPERTIES, PROPERTIES_BY_ENGAGEMENT_TYPE } from './LegacyEngagementsV2Properties';
import { createEngagementFromProperties } from './createEngagementFromProperties';
import { createPropertiesFromEngagement } from './createPropertiesFromEngagement';
import getIn from 'transmute/getIn';
import IsUngatedStore from 'crm_data/gates/IsUngatedStore';
const getSourceOwnerId = engagement => engagement.getIn(['engagement', 'ownerId'], 0);
const getSelectedHostOwnerId = engagement => {
  const sourceOwnerId = getSourceOwnerId(engagement);
  if (!sourceOwnerId) {
    Raven.captureMessage('Engagement missing ownerId', {
      extra: {
        engagement: engagement.toJS()
      }
    });
  }
  const selectedHostOwnerId = engagement.getIn(['metadata', 'ownerId'], sourceOwnerId);
  return selectedHostOwnerId;
};

/*
 * @param {Immutable.Map} associations - A map of associations for a task.
 *    Possible keys include contactIds, companyIds, dealIds, ticketIds.
 * @returns {Immutable.Map} The same map of associations with the keys translated for use in the associations API.
 */
export function _formatAssociationsForCreate(associations) {
  return associations.filter((__value, key) => key in ASSOCIATIONS_MAP).mapKeys(key => ASSOCIATIONS_MAP[key]);
}

/**
 * This maps the fields present on an engagement to the keys at which they are store on the backend
 *
 * A full listing of the keys on each engagement can be found here:
 * https://git.hubteam.com/HubSpot/InboundDbProperties/tree/master/InboundDbPropertiesBase/src/main/java/com/hubspot/inbounddb/properties/defaults/engagements
 *
 * */

export const formatPropertyValue = value => {
  if (List.isList(value)) {
    return value.join(';');
  }
  return value;
};
export const getPropertyName = (engagementType, property) => {
  if (COMMON_PROPERTIES.has(property)) {
    return COMMON_PROPERTIES.get(property);
  }
  const engagementProperties = PROPERTIES_BY_ENGAGEMENT_TYPE.get(engagementType);
  const propertyDef = engagementProperties.find(def => def.propertyNameV1 === property);
  return propertyDef.propertyNameV2;
};

/**
 * Given `engagementType` and `updates`, reduces the Map of updates into `List()`
 *
 * @example
 * formatUpdatedProperties("NOTE", Map({ body: '<div>New Body</div>', ownerIds: List([123456, 56789])}));
 *
 * @example return
 * List([
 *  {
 *    name: 'hs_note_body',
 *    value: '<div>New Body</div>'
 *  },
 *  {
 *    name: 'hs_at_mentioned_owner_ids',
 *    value: '123456;56789'
 *  }
 * ]);
 *
 * @param  {string}
 * @param  {Map}
 * @return {List<object>}
 */
const INTERNAL_ATTENDEES_V2_PROPERTY_NAME = 'hs_attendee_owner_ids';
export const formatUpdatedProperties = (engagementType, updates) => {
  return updates.reduce((acc, value, key) => {
    const propertyName = getPropertyName(engagementType, key);
    // adding this to format internal attendees correctly
    if (propertyName === INTERNAL_ATTENDEES_V2_PROPERTY_NAME) {
      return acc.push(ImmutableMap({
        name: propertyName,
        value: value.join(';')
      }));
    }
    return acc.push(ImmutableMap({
      name: propertyName,
      value: formatPropertyValue(value)
    }));
  }, List());
};
function updateEngagementWithUser(user, engagementType, engagementId, updates) {
  return send({
    type: PUT,
    headers: {
      'X-Properties-Source': CRM_UI,
      'X-Properties-SourceId': user.email
    }
  }, `engagements/v2/engagements/${engagementId}`, {
    properties: formatUpdatedProperties(engagementType, updates)
  });
}
export function updateEngagement(engagementType, engagementId, updates) {
  return userInfo().then(({
    user
  }) => updateEngagementWithUser(user, engagementType, engagementId, updates)).catch(error => {
    Raven.captureException(new Error('Engagement Creation'), {
      extra: {
        message: error.message,
        engagementType
      },
      tags: {
        engagementType
      }
    });
    throw error;
  });
}
function createEngagementV2WithUser(user, engagement, portalSpecificObjectsToAssociate) {
  const isUngatedForEngagementsParser = IsUngatedStore.get('CRM:EngagementParsing');
  if (!isUngatedForEngagementsParser) {
    return legacyCreateEngagementV2WithUser(user, engagement, portalSpecificObjectsToAssociate);
  }
  const atMentions = getIn(['associations', 'ownerIds'], engagement) || List();
  const source = engagement.getIn(['engagement', 'source']) || CRM_UI;
  const sourceId = engagement.getIn(['engagement', 'sourceId']) || user.email;
  const selectedHostOwnerId = getSelectedHostOwnerId(engagement);
  const engagementToConvert = engagement.setIn(['engagement', 'sourceId'], sourceId).setIn(['engagement', 'source'], source).setIn(['engagement', 'ownerId'], selectedHostOwnerId).setIn(['associations', 'ownerIds'], atMentions);
  const properties = Object.entries(createPropertiesFromEngagement({
    engagement: engagementToConvert,
    removeValuesExcludedOnCreate: true
  })).map(([key, valueMap]) => ({
    name: key,
    value: valueMap.value
  }));
  const hubSpotDefinedObjectAssociations = !portalSpecificObjectsToAssociate ? _formatAssociationsForCreate(engagement.get('associations')) : undefined;
  const body = {
    objectsToAssociate: portalSpecificObjectsToAssociate,
    shouldValidateAssociations: true,
    associations: hubSpotDefinedObjectAssociations,
    object: {
      properties
    }
  };
  return send({
    type: POST
  }, 'engagements/v2/engagements', body).then(result => {
    /**
     * @example result
     *
     * {
     *    objectId: 12345678,
     *    objectType: "ENGAGEMENT",
     *    properties: {
     *      hs_created_by: {
     *        value: 77777777
     *      }
     *      hs_createdate: {
     *        value: 88888888
     *      }
     *      hs_note_body: {
     *        value: "<p>this is the body</p>"
     *      }
     *      hs_timestamp: {
     *        value: 88888888
     *      }
     *    }
     * }
     *
     */
    const id = result.get('objectId');
    const engagementTypeFromResult = engagement.getIn(['engagement', 'type']);
    const engagementFromProperties = createEngagementFromProperties({
      properties: result.get('properties').toJS(),
      type: engagementTypeFromResult
    });
    return {
      createdEngagementRecord: engagementFromProperties.setIn(['engagement', 'id'], id).set('associations', engagement.get('associations')),
      associationCreateFailures: []
    };
  });
}
export function createEngagementV2(engagement, portalSpecificObjectsToAssociate) {
  return userInfo().then(({
    user
  }) => createEngagementV2WithUser(user, engagement, portalSpecificObjectsToAssociate)).catch(error => {
    const engagementType = engagement.getIn(['engagement', 'type']);
    Raven.captureException(new Error('Engagement Creation'), {
      extra: {
        message: error.message,
        engagementType
      },
      tags: {
        engagementType
      }
    });
    throw error;
  });
}

/**
 *
 * @param {Array} portalSpecificObjectsToAssociate
 *
 * [
 *   {
 *     "associationSpec": {
 *       "associationCategory": "HUBSPOT_DEFINED",
 *       "associationTypeId": 32
 *     },
 *     "objectIds": [20751]
 *   }
 * ]
 *
 */
function legacyCreateEngagementV2WithUser(user, engagement, portalSpecificObjectsToAssociate) {
  const engagementType = engagement.getIn(['engagement', 'type']);
  const engagementProperties = PROPERTIES_BY_ENGAGEMENT_TYPE.get(engagementType);

  /**
   * @example engagementSpecificProperties
   *
   * [
   *   {
   *     name: 'hs_note_body',
   *     value: '<p>this is the note body</p>'
   *   }
   * ]
   */
  const engagementSpecificProperties = engagementProperties.map(def => {
    const name = def.propertyNameV2;
    const shouldParsePropertyValue = !!def.parser;
    const value = shouldParsePropertyValue ? def.parser(engagement.getIn(def.propertyPathV1)) : engagement.getIn(def.propertyPathV1);
    if (name === INTERNAL_ATTENDEES_V2_PROPERTY_NAME) {
      return {
        name,
        value: value.join(';')
      };
    }
    return {
      name,
      value: formatPropertyValue(value)
    };
  }).filter(property => {
    // filter out properties with undefined or null value
    return !!property.value;
  });
  const attachments = engagement.get('attachments') || List();
  const attachmentsProperty = attachments.size > 0 ? [{
    name: COMMON_PROPERTIES.get('attachments'),
    value: attachments.map(attachment => attachment.get('id')).join(';')
  }] : [];
  const atMentions = getIn(['associations', 'ownerIds'], engagement) || List();
  const formattedAtMentions = atMentions.join(';');
  const source = engagement.getIn(['engagement', 'source']) || CRM_UI;
  const selectedHostOwnerId = getSelectedHostOwnerId(engagement);
  const properties = [{
    name: COMMON_PROPERTIES.get('engagementType'),
    value: engagementType
  }, {
    name: COMMON_PROPERTIES.get('timestamp'),
    value: engagement.getIn(['engagement', 'timestamp'])
  }, {
    name: COMMON_PROPERTIES.get('ownerId'),
    value: selectedHostOwnerId
  }, {
    name: COMMON_PROPERTIES.get('ownerIds'),
    value: formattedAtMentions
  }, {
    name: COMMON_PROPERTIES.get('engagementSource'),
    value: source
  }, ...engagementSpecificProperties, ...attachmentsProperty];

  /**
   * @example hubSpotDefinedObjectAssociations
   *
   * {
   *   [ENGAGEMENT_TO_CONTACT]: [123, 456],
   *   [ENGAGEMENT_TO_COMPANY]: [],
   *   [ENGAGEMENT_TO_DEAL]: [],
   *   [ENGAGEMENT_TO_TICKET]: [789],
   * }
   *
   */

  const hubSpotDefinedObjectAssociations = !portalSpecificObjectsToAssociate ? _formatAssociationsForCreate(engagement.get('associations')) : undefined;
  const body = {
    objectsToAssociate: portalSpecificObjectsToAssociate,
    shouldValidateAssociations: true,
    associations: hubSpotDefinedObjectAssociations,
    object: {
      properties
    }
  };
  const sourceId = engagement.getIn(['engagement', 'sourceId']) || user.email;
  return send({
    type: POST,
    headers: {
      'X-Properties-Source': source,
      'X-Properties-SourceId': sourceId
    }
  }, 'engagements/v2/engagements', body).then(result => {
    /**
     * @example result
     *
     * {
     *    objectId: 12345678,
     *    objectType: "ENGAGEMENT",
     *    properties: {
     *      hs_created_by: {
     *        value: 77777777
     *      }
     *      hs_createdate: {
     *        value: 88888888
     *      }
     *      hs_note_body: {
     *        value: "<p>this is the body</p>"
     *      }
     *      hs_timestamp: {
     *        value: 88888888
     *      }
     *    }
     * }
     *
     */
    const id = result.get('objectId');
    const createdAt = result.getIn(['properties', COMMON_PROPERTIES.get('createdAt'), 'value']);
    const timestamp = result.getIn(['properties', COMMON_PROPERTIES.get('timestamp'), 'value']);
    const createdBy = result.getIn(['properties', COMMON_PROPERTIES.get('createdBy'), 'value']);
    const engagementSource = result.getIn(['properties', COMMON_PROPERTIES.get('engagementSource'), 'value']);

    // hydrate engagement with id, create date, created by, timestamp, and source
    const postCreationData = fromJS({
      engagement: {
        id,
        createdAt: parseInt(createdAt, 10),
        createdBy,
        timestamp: parseInt(timestamp, 10),
        source: engagementSource
      }
    });
    const engagementPostCreation = engagement.mergeDeep(postCreationData);
    return {
      createdEngagementRecord: engagementPostCreation,
      associationCreateFailures: []
    };
  });
}
export function legacyCreateEngagementV2(engagement, portalSpecificObjectsToAssociate) {
  return userInfo.then(({
    user
  }) => {
    legacyCreateEngagementV2WithUser(user, engagement, portalSpecificObjectsToAssociate);
  });
}
function createTaskWithUser(user, task) {
  send({
    type: POST,
    headers: {
      'X-Properties-Source': CRM_UI,
      'X-Properties-SourceId': user.email
    }
  }, 'engagements/v2/engagements', {
    associations: _formatAssociationsForCreate(task.associations),
    object: {
      properties: task.properties.toList().map(({
        name,
        value
      }) => ({
        name,
        value
      }))
    }
  });
}
export function createTask(task) {
  return userInfo().then(({
    user
  }) => createTaskWithUser(user, task));
}
function updateTaskWithUser(user, task) {
  return send({
    type: PUT,
    headers: {
      'X-Properties-Source': CRM_UI,
      'X-Properties-SourceId': user.email
    }
  }, `engagements/v2/engagements/${task.objectId}`, {
    properties: task.properties.toList().map(({
      name,
      value
    }) => ({
      name,
      value
    }))
  });
}
export function updateTask(task) {
  return userInfo().then(({
    user
  }) => updateTaskWithUser(user, task));
}
function batchUpdateEngagementsWithUser(user, engagementIds, updates) {
  send({
    type: PUT,
    headers: {
      'X-Properties-Source': CRM_UI,
      'X-Properties-SourceId': user.email
    }
  }, 'engagements/v2/engagements/batch', engagementIds.map(engagementId => ({
    engagementId,
    properties: engagementUpdatesToInboundDbProperties(updates)
  })));
}
export function batchUpdateEngagements(engagementIds, updates) {
  return userInfo.then(({
    user
  }) => {
    batchUpdateEngagementsWithUser(user, engagementIds, updates);
  });
}