import Loadable from 'UIComponents/decorators/Loadable';
import Raven from 'raven-js';
import { withDelayUntilIdleAndPerfResolved } from '../../utils/withDelayUntilIdleAndPerfResolved';
const UseCallingWidgetContainerAsync = withDelayUntilIdleAndPerfResolved(Loadable({
  loader: () => import( /* webpackChunkName: "UseCallingWidgetContainer" */
  './UseCallingWidgetContainer'),
  LoadingComponent: () => null,
  ErrorComponent: ({
    error
  }) => {
    Raven.captureException(error);
    console.error(error);
    return null;
  }
}));
export default UseCallingWidgetContainerAsync;