import { useContext, useMemo, useCallback } from 'react';
import { buildCrmCard } from 'crm-cards-sdk/buildCrmCard';
import { LEGACY_SIDEBAR_CARD_TYPES } from 'crm-cards-sdk/constants/LegacySidebarCardType';
import { useExtraCardProps } from 'crm-cards-sdk/internal/ExtraPropsContext';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
import { useSidebarSubjectContext } from 'customer-data-sidebar/universal/context/SidebarSubjectContext';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module  Remove this comment to see the full error message
import { PermissionsContext } from 'customer-data-sidebar/universal/context/PermissionsContext';
import { isSimpleCardType } from './isSimpleCardType';
import { useLegacyCardDataRequest } from './useLegacyCardDataRequest';
import LegacySidebarCardContentAdapter from './LegacySidebarCardContentAdapter';
import LegacySidebarCardTitleAdapter from './LegacySidebarCardTitleAdapter';
function useCheckIsSidebarWrapperValid() {
  const sidebarSubject = useSidebarSubjectContext();
  const permissionsContext = useContext(PermissionsContext);
  return sidebarSubject && sidebarSubject.definition !== null && permissionsContext && permissionsContext !== null;
}
function getAssociatedObjectTypeIdFromQueryResult(secondaryCardQueryResultData) {
  return secondaryCardQueryResultData && secondaryCardQueryResultData.crmObject.card.associationDefinitions && secondaryCardQueryResultData.crmObject.card.associationDefinitions.length >= 1 && secondaryCardQueryResultData.crmObject.card.associationDefinitions[0].toObjectTypeId;
}
const LegacySidebarCard = buildCrmCard({
  displayName: 'LegacySidebarCard',
  enableLegacyActionFormat: true,
  ContentComponent: LegacySidebarCardContentAdapter,
  TitleComponent: LegacySidebarCardTitleAdapter,
  useCardProps: (props, context) => {
    const isSidebarWrapperValid = useCheckIsSidebarWrapperValid();
    const extraProps = useExtraCardProps(props.cardData.configuration.cardType);
    let isEditable = false;
    const permissionsContext = useContext(PermissionsContext);
    if (permissionsContext) {
      const {
        canEditSubject,
        readOnly
      } = permissionsContext;
      isEditable = canEditSubject && !readOnly;
    }
    return {
      objectTypeId: context.objectTypeId,
      objectId: context.objectId,
      cardType: props.cardData.configuration.cardType,
      title: props.cardData.definition.cardTitle,
      cardData: {
        id: props.cardData.definition.cardId,
        __typename: props.cardData.configuration.cardData.__typename,
        cardId: props.cardData.definition.cardId,
        isCollapsed: props.cardData.configuration.cardData.isCollapsed,
        legacyCardName: props.cardData.configuration.cardData.legacyCardName
      },
      extraProps,
      isEditable,
      isSidebarWrapperValid
    };
  },
  useCardActions: () => {
    return useCallback(() => import('./LegacySidebarCardActionsAdapter'), []);
  },
  useLegacyDataAttributes: (props, context) => {
    const {
      cardData
    } = props;
    const {
      objectTypeId,
      objectId
    } = context;
    const cardType = cardData.configuration.cardType;
    const legacyCardName = cardData.configuration.cardData.legacyCardName;

    // TODO: Ideally we wouldn't have to use this query here to determine
    // The legacy data attributes. We should try to migrate away from 'data-sidebar-card-association-object-type-id'
    // so that we can remove this query call here.
    const secondaryCardQueryResult = useLegacyCardDataRequest({
      objectTypeId,
      objectId,
      cardId: cardData.definition.cardId,
      skip: isSimpleCardType(cardData.configuration.cardType)
    });
    return useMemo(() => {
      const baseDataAttributes = {
        'data-sidebar-card-type': cardType,
        'data-sidebar-key': legacyCardName
      };
      if (cardType === LEGACY_SIDEBAR_CARD_TYPES.AssociatedObjectsCard) {
        return Object.assign({}, baseDataAttributes, {
          // TODO: We need to migrate tests and onboarding tours to a new selector so that this ugly code can be deleted
          'data-sidebar-card-association-object-type-id': getAssociatedObjectTypeIdFromQueryResult(secondaryCardQueryResult.data)
        });
      }
      return baseDataAttributes;
    }, [legacyCardName, cardType, secondaryCardQueryResult.data]);
  }
});
export default LegacySidebarCard;