import { Map as ImmutableMap } from 'immutable';
import { EMAIL, INCOMING_EMAIL, FORWARDED_EMAIL } from 'customer-data-objects/engagement/EngagementTypes';
export const removeBodyFromEmail = engagement => {
  const isPlainText = !engagement.hasIn(['metadata', 'html']) && !!engagement.getIn(['metadata', 'text']);
  const bodyLength = (engagement.getIn(['metadata', 'html']) || engagement.getIn(['metadata', 'text'], '')).length;
  return engagement.deleteIn(['metadata', 'html']).deleteIn(['metadata', 'text']).deleteIn(['engagement', 'bodyPreviewHtml']).deleteIn(['engagement', 'bodyPreview']).setIn(['metadata', 'bodyLength'], bodyLength).setIn(['metadata', 'isPlainText'], isPlainText);
};
export const getFullEmailAndPreview = engagement => {
  const fullEmail = engagement.getIn(['metadata', 'html']) || engagement.getIn(['metadata', 'text'], '');
  const preview = engagement.getIn(['engagement', 'bodyPreviewHtml'], fullEmail);
  return ImmutableMap({
    fullEmail,
    preview
  });
};
export const isEmailEngagement = engagementType => engagementType === EMAIL || engagementType === INCOMING_EMAIL || engagementType === FORWARDED_EMAIL;