import { useMemo } from 'react';
import { useQuery } from 'data-fetching-client';
import { FETCH_CURRENCY_INFORMATION } from '../queries/restQueries';
export const useMultiCurrencyStatus = () => {
  const {
    data: currencyInfo,
    loading: currencyLoading
  } = useQuery(FETCH_CURRENCY_INFORMATION);
  const allArchivedCurrencies = useMemo(() => {
    var _currencyInfo$currenc;
    return ((currencyInfo === null || currencyInfo === void 0 || (_currencyInfo$currenc = currencyInfo.currencyInformation) === null || _currencyInfo$currenc === void 0 ? void 0 : _currencyInfo$currenc.currencyExchangeRates) || []).filter(currencyExchangeRate => !currencyExchangeRate.visible).map(currencyExchangeRate => currencyExchangeRate.fromCurrencyCode);
  }, [currencyInfo]);
  return {
    currencyLoading,
    allArchivedCurrencies
  };
};