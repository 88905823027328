import { useCardLocationContext } from 'crm-cards-sdk/internal/CardLocationContext';
import { setFrom } from '../../utils/LocalStorage';
import { useCallback } from 'react';
import { makeRestoreableLocationStateKey } from '../utils/makeRestoreableLocationStateKey';
import { safelyGetRestorableLocationState } from '../utils/safelyGetRestoreableLocationState';
export const useRestoreableLocationState = () => {
  const {
    additionalLocationData,
    location
  } = useCardLocationContext();
  const setRestoreableLocationState = useCallback(value => {
    const localStorageKey = makeRestoreableLocationStateKey(location, additionalLocationData.objectTypeId);
    setFrom(localStorageKey, {
      state: value
    });
  }, [additionalLocationData.objectTypeId, location]);
  const restoreableLocationState = safelyGetRestorableLocationState(location, additionalLocationData.objectTypeId);
  return [restoreableLocationState.state, setRestoreableLocationState];
};