'use es6';

import dispatcher from 'dispatcher/dispatcher';
import { COMPANY_CREATED, COMPANIES_UPDATE_STARTED, COMPANIES_UPDATE_FAILED, COMPANY_DOMAIN_REMOVED } from 'crm_data/actions/ActionTypes';
import { fetch } from 'crm_data/companies/api/CompaniesAPI';
import { definePooledObjectStore } from 'crm_data/flux/definePooledObjectStore';
import { getProperty, setProperty } from 'customer-data-objects/model/ImmutableModel';
import registerPooledObjectService from 'crm_data/flux/registerPooledObjectService';
import { ASSOCIATE_CONTACT_AND_COMPANY, DISASSOCIATE_CONTACT_AND_COMPANY } from 'crm_schema/association/AssociationActionTypes';
const PREFIX = 'COMPANIES';
registerPooledObjectService({
  actionTypePrefix: PREFIX,
  fetcher: fetch,
  fetchLimit: 250
});
const CompaniesStore = definePooledObjectStore({
  actionTypePrefix: PREFIX
}).defineResponseTo(COMPANY_CREATED, (state, company) => {
  const companyId = `${company.get('companyId')}`;
  return state.set(companyId, company);
}).defineResponseTo(COMPANIES_UPDATE_STARTED, (state, {
  id,
  nextProperties
}) => state.updateIn([id], company => nextProperties.reduce((acc, value, name) => setProperty(acc, name, value), company))).defineResponseTo(COMPANIES_UPDATE_FAILED, (state, {
  id,
  nextProperties,
  properties
}) => state.updateIn([id], company => properties.reduce((acc, value, name) => {
  // don't overwrite changes made since the update began
  if (nextProperties.get(name) !== getProperty(acc, name)) {
    return acc;
  }
  return setProperty(acc, name, value);
}, company))).defineResponseTo(COMPANY_DOMAIN_REMOVED, (state, {
  domain,
  company
}) => {
  const companyId = `${company.get('companyId')}`;
  return state.updateIn([companyId], companyRecord => {
    const filteredResults = companyRecord.get('additionalDomains').filter(entry => entry.get('domain') !== domain);
    return companyRecord.set('additionalDomains', filteredResults);
  });
}).defineResponseTo(DISASSOCIATE_CONTACT_AND_COMPANY, (state, {
  subjectId
}) => {
  const companyId = `${subjectId}`;
  if (!state.get(companyId)) {
    return state;
  }
  return state.update(companyId, companyRecord => {
    const contactCount = getProperty(companyRecord, 'num_associated_contacts');
    const oneLessContact = parseInt(contactCount, 10) - 1;
    return setProperty(companyRecord, 'num_associated_contacts', oneLessContact);
  });
}).defineResponseTo(ASSOCIATE_CONTACT_AND_COMPANY, (state, {
  subjectId
}) => {
  const companyId = `${subjectId}`;
  if (!state.get(companyId)) {
    return state;
  }
  return state.update(companyId, companyRecord => {
    const contactCount = getProperty(companyRecord, 'num_associated_contacts') || 0;
    const oneMoreContact = parseInt(contactCount, 10) + 1;
    return setProperty(companyRecord, 'num_associated_contacts', oneMoreContact);
  });
}).register(dispatcher);
export default CompaniesStore;