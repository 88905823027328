// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { defineKeyStore } from 'crm_data/store/KeyStore';
import { ADD_ENGAGEMENT, CLEAR_ALL_FILTERS, CHANGE_FILTER, TIMELINE_CHANGE_USER_AND_TEAM_FILTER, FETCH_NEXT_TIMELINE_EVENTS_STARTED, FETCH_TIMELINE_ITEMS_ERROR, FETCH_TIMELINE_ITEMS_SUCCESS, FETCHING_INITIAL_TIMELINE_EVENTS_STARTED, FETCH_TIMELINE_ITEMS_FOR_VFP_SUCCESS, FETCH_TIMELINE_ITEMS_FOR_VFP_STARTED, SET_FAVORITE_EVENT_OVERRIDE, DELETE_TIMELINE_ENGAGEMENT
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
import { List, fromJS, Map as ImmutableMap } from 'immutable';
import enviro from 'enviro';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { TIMELINE_STATUSES } from '../constants/TimelineConstants';
const hubspotOwnedIntegrations = fromJS({
  WISTIA: {
    id: 'WISTIA',
    name: 'Wistia',
    objectTypes: [CONTACT]
  },
  SURVEY_MONKEY: {
    id: 'SURVEY_MONKEY',
    name: 'SurveyMonkey',
    objectTypes: [CONTACT]
  },
  GO_TO_WEBINAR: {
    id: 'WEBINARS',
    name: 'GoToWebinar',
    objectTypes: [CONTACT]
  }
});
const ADS_APP_FILTER_NAME = `integrations-app-${enviro.isQa() ? 1158877 : 27506}`;
function moveAdsToFrontOfList(list) {
  const adsIndex = list.findIndex(app => app.get('id') === ADS_APP_FILTER_NAME);
  if (adsIndex <= 0) {
    return list;
  }
  const adsApp = list.get(adsIndex);
  return list.delete(adsIndex).unshift(adsApp);
}
function addIntegrationEvents(state, data) {
  if (state.getIn(['timelineStatus', 'integrationsApps'])) {
    return state;
  }
  let integrationsApps = List();
  const integrationsAppsData = data.get('integrationsAppsData');
  if (integrationsAppsData) {
    integrationsAppsData.forEach(integrationsApp => {
      const id = `integrations-app-${integrationsApp.get('id')}`;
      if (id !== ADS_APP_FILTER_NAME) {
        integrationsApp = integrationsApp.set('id', id);
        integrationsApps = integrationsApps.push(integrationsApp);
      }
    });
  }
  const installedIntegrationsData = data.get('installedIntegrationsData');
  if (installedIntegrationsData) {
    installedIntegrationsData.forEach(installedIntegration => {
      const integrationName = installedIntegration.get('hubSpotOwnedIntegration');
      if (installedIntegration.get('appAuthorizationExists') && hubspotOwnedIntegrations.has(integrationName)) {
        integrationsApps = integrationsApps.push(hubspotOwnedIntegrations.get(integrationName));
      }
    });
  }
  const sortedIntegrationsApps = integrationsApps.sort((integrationA, integrationB) => {
    return integrationA.get('name').localeCompare(integrationB.get('name'));
  });
  const sortedIntegrationsAppsWithAdsAtFront = moveAdsToFrontOfList(sortedIntegrationsApps);
  return state.setIn(['timelineStatus', 'integrationsApps'], sortedIntegrationsAppsWithAdsAtFront);
}
export default defineKeyStore({
  namespace: 'TIMELINE_STATUS',
  getInitialState() {
    return ImmutableMap({
      now: Date.now(),
      timelineStatus: ImmutableMap()
    });
  },
  idIsValid(id) {
    return typeof id === 'string';
  }
}).defineResponseTo([FETCH_NEXT_TIMELINE_EVENTS_STARTED, CHANGE_FILTER, TIMELINE_CHANGE_USER_AND_TEAM_FILTER, FETCH_TIMELINE_ITEMS_FOR_VFP_STARTED, SET_FAVORITE_EVENT_OVERRIDE], state => {
  return state.setIn(['timelineStatus', 'isFetching'], true);
}).defineResponseTo(CLEAR_ALL_FILTERS, state => {
  return state.setIn(['timelineStatus', 'isFetching'], false);
}).defineResponseTo(FETCH_TIMELINE_ITEMS_FOR_VFP_SUCCESS, (state, data) => {
  state = state.setIn(['timelineStatus', 'isFetching'], false);
  return addIntegrationEvents(state, data);
}).defineResponseTo(FETCH_TIMELINE_ITEMS_SUCCESS, (state, data) => {
  const now = state.get('now');
  const currentNextTimestamp = state.getIn(['timelineStatus', 'nextTimestamp']);
  const incomingLastEventTimestamp = data.get('events').size ? data.get('events').last().get('timestamp') : null;
  const incomingNextTimestamp = data.get('nextTimestamp') || incomingLastEventTimestamp;
  // A nextTimestamp is not passed whenever there are no results or hasMore is false. We fallback to the previous
  // request's data, if not, we use now
  const nextTimestamp = incomingNextTimestamp || currentNextTimestamp || now;
  if (data.get('failed') && data.get('failed').length || data.get('timedOut') && data.get('timedOut').length) {
    // This handles requests with failed and timed out events from minkowski (timeline/v2).
    // We need to respect the current state of hasMore
    const incomingHasMore = data.get('hasMore');
    const currentHasMore = state.getIn(['timelineStatus', 'hasMore']);
    data = data.set('hasMore', Boolean(currentHasMore || incomingHasMore));
  }
  state = state.setIn(['timelineStatus', 'isFetching'], false).setIn(['timelineStatus', 'hasHiddenEvents'], data.get('hasHiddenEvents')).mergeIn(['timelineStatus'], data).deleteIn(['timelineStatus', 'fromDate']) // This data is only used for polling requests, no need to store
  .setIn(['timelineStatus', 'nextTimestamp'], nextTimestamp);
  return addIntegrationEvents(state, data);
}).defineResponseTo(FETCH_TIMELINE_ITEMS_ERROR, (state, {
  error
} = {}) => {
  return state.setIn(['timelineStatus', 'error'], error).setIn(['timelineStatus', 'status'], TIMELINE_STATUSES.ERROR).setIn(['timelineStatus', 'isFetching'], false);
}).defineResponseTo(ADD_ENGAGEMENT, (state, {
  engagement
}) => {
  const engagementTimestamp = engagement.get('timestamp');
  const currentNextTimestamp = state.getIn(['timelineStatus', 'nextTimestamp']);
  if (!currentNextTimestamp || currentNextTimestamp > engagementTimestamp) {
    state = state.setIn(['timelineStatus', 'nextTimestamp'], engagementTimestamp);
  }
  return state.updateIn(['timelineStatus', 'events'], List(), events => events.push(engagement));
}).defineResponseTo(DELETE_TIMELINE_ENGAGEMENT, (state, {
  engagement
}) => {
  return state.updateIn(['timelineStatus', 'events'], events => events.filterNot(timelineEvent => {
    return engagement.getIn(['engagement', 'id']) === timelineEvent.getIn(['eventData', 'engagement', 'id']);
  }));
}).defineResponseTo(FETCHING_INITIAL_TIMELINE_EVENTS_STARTED, state => {
  const now = Date.now();
  const integrationsApps = state.getIn(['timelineStatus', 'integrationsApps']);
  return ImmutableMap({
    timelineStatus: ImmutableMap({
      isFetching: true,
      integrationsApps
    }),
    now
  });
}).register();