import { useCallback } from 'react';
import { UPDATE_BIZ_OPS_STORES } from 'crm-events-data';
// @ts-expect-error Could not find a declaration file
import { dispatchQueue } from 'crm_data/dispatch/Dispatch';
import { fromJS } from 'immutable';
// @ts-expect-error Could not find a declaration file
import ActionTypes from 'BizOpsCrmUIComponents/actions/ActionTypes';
export const useBizOpsStoreActionHandler = () => {
  const handleBizOpsStoreAction = useCallback((actionType, data) => {
    if (actionType === UPDATE_BIZ_OPS_STORES) {
      if (data.actionType === ActionTypes.HUB_SUMMARIES_RECEIVED) {
        dispatchQueue(data.actionType, {
          results: data.dispatchData.results,
          sort: fromJS(data.dispatchData.sort)
        });
      } else {
        dispatchQueue(data.actionType, data.dispatchData);
      }
    }
  }, []);
  return {
    handleBizOpsStoreAction
  };
};