import { LEGACY_SIDEBAR_CARD_TYPES } from '../../constants/LegacySidebarCardType';

// Legacy card should not have config type or query fragments
// Extra props on legacy card
// Legacy Sidebar Card Metadata
export const QUOTES_METADATA = {
  cardType: 'QUOTES',
  legacySidebarCardType: LEGACY_SIDEBAR_CARD_TYPES.ProfileQuotesCard,
  hasLegacyExtraProps: true,
  renderAsLegacyCardInCrmLocationResolver: true
};