/**
 * Keys for cross-portal settings.
 *
 * If you need a key that can differ across portals, see
 * crm_data/settings/UserPortalSettingsKeys.js
 *
 * Key should be defined in ABC order as...
 *
 *  export const KEY_NAME = 'key';
 */
'use es6';

import { DEAL, TICKET, TASK } from 'customer-data-objects/constants/ObjectTypes';
import PortalIdParser from 'PortalIdParser';
const _portalId = PortalIdParser.get();
const BOARD_OBJECT_TYPES = [DEAL, TASK, TICKET];
const NPS_SURVEY_OBJECT_TYPE = 'NPS_SURVEY';
const UserSettingsKeys = {
  CLOSE_COUNT_IMPORT_KB_MODAL: ['closed-import-kb-modal'],
  DONT_SHOW_CONFIRM_ACCOUNT_SWITCH: ['no-show-confirm-account-switch'],
  // todo - this isnt related to queues
  DONT_SHOW_FOLLOW_UP_TASK: ['Tasks', 'Queues', 'FollowUpTasks', 'hide'],
  HAS_CHECKED_SEQUENCE_STEP_SKIP_TASK: ['Tasks', 'Queues', 'has-checked-sequence-step-skip-task'],
  HAS_CHECKED_SEQUENCE_STEP_COMPLETE_TASK: ['Tasks', 'Queues', 'has-checked-sequence-step-complete-task'],
  EMAIL_COMMUNICATOR_TRACKING_TOOLTIP_SHOWN: ['has-viewed-email-communicator-tracking-tooltip'],
  // This setting really belongs in UserPortalSettingsKey, but the format is not correct
  GOOGLE_CALENDAR: [`integrations:google-calendar-v2:portal-${_portalId}`],
  HAS_CHECKED_ASSOCIATION_DEAL_BOX_COMPANY: ['has-checked-association-deal-box-company'],
  HAS_CHECKED_ASSOCIATION_DEAL_BOX_CONTACT: ['has-checked-association-deal-box-contact'],
  HAS_CHECKED_ASSOCIATION_TICKET_BOX_COMPANY: ['has-checked-association-ticket-box-company'],
  HAS_CHECKED_ASSOCIATION_TICKET_BOX_CONTACT: ['has-checked-association-ticket-box-contact'],
  HAS_CLOSED_KILL_SENDGRID_ALERT: ['has-closed-kill-sendgrid-alert'],
  HAS_CLOSED_THREAD_REPLY_MESSAGE: ['has-closed-ticket-reply-message'],
  HAS_COMPLETED_INDEX_ONBOARDING: ['CRM', 'Index', 'Onboarding'],
  HAS_SEEN_COBJECT_ONBOARDING_SHEPHERD: ['CRM', 'Index', 'CobjectsOnboarding', _portalId, 'HasSeenShepherd'],
  HAS_SEEN_COBJECT_ONBOARDING_MODAL: ['CRM', 'Index', 'CobjectsOnboarding', _portalId, 'HasSeenModal'],
  HAS_SEEN_INLINE_EDITING_MODAL: ['CRM', 'Index', 'InlineEditing', _portalId, 'HasSeenModal'],
  HAS_COMPLETED_RECORD_ONBOARDING: ['CRM', 'ObjectRecord', 'Onboarding'],
  HAS_SEEN_ILS_LIST_MIGRATION_TOUR: ['CRM', 'Segments', _portalId, 'HasSeenMigrationTour'],
  HAS_SEEN_ILS_FOLDER_MIGRATION_TOUR: ['CRM', 'Segments', _portalId, 'HasSeenFolderTour'],
  HAS_SEEN_FILTERS_REDESIGN_TOUR: ['CRM', 'Segments', _portalId, 'HasSeenFiltersRedesignTour'],
  HAS_SEEN_PINNED_NOTE: ['CRM', 'hasSeenNotePopover'],
  HAS_VIEWED_DEAL_EMPTY_STATE: ['Deals', 'viewed', 'emptyState'],
  HAS_VIEWED_DEALS_CHICKLET_ONBOARDING: ['Deals', 'viewed', 'chickletOnboarding'],
  HAS_VIEWED_DEALS_CELEBRATION_MODAL: ['Deals', 'viewed', 'celebration-modal'],
  HAS_VIEWED_DEAL_STAGE_PROPERTIES_ONBOARDING: ['CRM', 'DealStageProperties', 'Onboarding'],
  HAS_VIEWED_IMPORT_CHANGE_BANNER: ['has-viewed-import-change-banner'],
  HAS_VIEWED_IMPORT_UPDATE_SHEPHERD: ['has-viewed-import-update-shepherd'],
  HAS_VIEWED_INDEX_REDESIGN_FIRST_TIME_ALERT: ['has-viewed-index-redesign-first-time-alert'],
  HAS_VIEWED_LINE_ITEMS_REDESIGN_SHEPHERD: ['has-viewed-line-items-redesign-shepherd'],
  HAS_VIEWED_SMART_TASK_ONBOARDING: ['Tasks', 'SmartTasks', 'ViewedOnboarding'],
  HAS_VIEWED_TASKS_EMPTY_STATE: ['Tasks', 'viewed', 'emptyState'],
  HIDE_ASSOCIATE_PANEL_ONBOARDING_ALERT: ['hide-associate-panel-onboarding-alert'],
  HIDE_IMPORT_INFO_STEP: ['hide-import-info-step'],
  HIDE_LINE_ITEM_COLUMN_CONFIRM_MODAL: ['hide-line-item-column-confirm-modal'],
  HIDE_MEETINGS_PRO_TIP: ['hide-meetings-pro-tip'],
  HIDE_QUOTE_DRAFTED_MODAL: ['hide-quotes-drafted-modal'],
  HIDE_QUOTE_EXPIRED_MODAL: ['hide-quotes-expired-modal'],
  HIDE_QUOTE_TEMPLATE_LANGUAGE_ALERT: ['hide-quote-template-language-alert'],
  HIDE_PROPOSAL_CUSTOMIZATION_ALERT: ['hide-proposal-customization-alert'],
  IS_TIMELINE_EXPANDED_BY_DEFAULT: ['is-timeline-expanded-by-default'],
  JOB_TITLE: ['jobtitle'],
  LINKEDIN_SALES_NAVIGATOR: ['LINKEDIN_SALES_NAVIGATOR'],
  NPS_SETTINGS: [NPS_SURVEY_OBJECT_TYPE, 'nps_survey_completed'],
  PICKED_PORTAL_MODAL: ['PickedPortalModal'],
  QUOTES_TEMPLATE_PREFERENCE: ['quotes-template-preference'],
  THREE_COLUMN_PROFILES_VIEWED: ['3-column-profiles-viewed'],
  VIEW_ALL_PROPERTIES_CLICKED: ['view-all-properties-clicked']
};
BOARD_OBJECT_TYPES.forEach(objectType => {
  UserSettingsKeys[`${objectType}_VIEWTYPE_DEFAULT`] = [`${objectType}`, 'viewtype', 'default'];
});

/* Formats like "Contacts:board_inactive_is_turned_on" */
Object.keys(UserSettingsKeys).forEach(key => {
  const value = UserSettingsKeys[key];
  UserSettingsKeys[key] = `${value.join(':')}`;
});
export default UserSettingsKeys;