'use es6';

import { fromJS } from 'immutable';
import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
import { PUT } from 'crm_data/constants/HTTPVerbs';
import partial from 'transmute/partial';
import { getEmailApiErrorLangKey, getEmailApiAlertOptions } from 'customer-data-email/utils/EmailAPIErrorMessages';
import { EMAIL_SEND_ERROR } from 'customer-data-email/constants/EmailAPIErrorTypes';
const handleSendError = (emailRecord, error) => {
  const sendError = error || {};
  const defaultMessage = getEmailApiErrorLangKey(EMAIL_SEND_ERROR);
  const errorJSON = fromJS(sendError.responseJSON);
  const errorType = errorJSON.getIn(['emailEngagementCreatorFailure', 'emailEngagementCreatorFailureType']) || errorJSON.get('sendSchedulingFailureType');
  const alertOptions = getEmailApiAlertOptions({
    errorType,
    defaultMessage,
    emailRecord,
    isUngatedForEmailSettingsMigration: true
  });
  return Promise.reject(alertOptions);
};
export function schedule(emailRecord, scheduledTime, {
  source
}, replyToEngagementId, recommendedSendTimeId) {
  const query = {};
  const API = `engagements-emails/v1/email/schedule-send`;
  if (replyToEngagementId) {
    query.replyToEngagementId = replyToEngagementId;
  }
  if (recommendedSendTimeId) {
    query.recommendedSendTimeId = recommendedSendTimeId;
  }
  return ImmutableAPI.send({
    type: PUT,
    headers: {
      'X-Properties-Source': source
    },
    query
  }, `${API}?scheduledTime=${scheduledTime}`, emailRecord).then(result => result && EngagementRecord.fromJS(result), partial(handleSendError, emailRecord));
}
export function deleteAndUnscheduleEmail(senderEmail, facsimileSendId) {
  return ImmutableAPI.put(`engagements-emails/v1/email/unschedule-send/${encodeURIComponent(senderEmail)}/${facsimileSendId}`);
}