import { LEGACY_SIDEBAR_CARD_TYPES } from '../../constants/LegacySidebarCardType';

// Legacy card should not have config type or query fragments
// Extra props on legacy card
// Legacy Sidebar Card Metadata
export const LEAD_STAGE_TRACKER_METADATA = {
  cardType: 'LEAD_STAGE_TRACKER',
  legacySidebarCardType: LEGACY_SIDEBAR_CARD_TYPES.LeadStageTrackerCard,
  hasLegacyExtraProps: true,
  renderAsLegacyCardInCrmLocationResolver: true
};