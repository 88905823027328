import SampleEventFactoryInterface from './SampleEventFactoryInterface';
import { EMAIL } from 'customer-data-objects/engagement/EngagementTypes';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import UserStore from 'crm_data/user/UserStore';
import { EVENT_ENGAGEMENT } from '../../timeline/constants/TimelineConstants';
const makeEmailLogSampleTimelineEvent = (sampleEventData = {}) => {
  const USER = UserStore.get();
  const emailLogSampleTimelineEventBuilder = SampleEventFactoryInterface({
    requiredData: {
      eventType: EVENT_ENGAGEMENT,
      itemType: EMAIL
    },
    eventDefaults: {
      subject: '',
      html: '',
      text: '',
      fromEmail: USER.email,
      fromFirstName: USER.first_name,
      fromLastName: USER.last_name
    },
    // @ts-expect-error ts-migrate(2322) FIXME: Type '(emailData: any) => { metadata: { html: any;... Remove this comment to see the full error message
    build(emailData) {
      const {
        subject,
        html,
        text,
        fromEmail,
        fromFirstName,
        fromLastName,
        toEmail,
        toFirstName,
        toLastName
      } = emailData;
      const eventData = {
        metadata: {
          html,
          text,
          subject,
          bcc: [],
          cc: [],
          from: {
            email: fromEmail,
            firstName: fromFirstName,
            lastName: fromLastName
          },
          to: [{
            email: toEmail,
            firstName: toFirstName,
            lastName: toLastName
          }]
        }
      };
      return eventData;
    }
  });
  return emailLogSampleTimelineEventBuilder(sampleEventData);
};
export default makeEmailLogSampleTimelineEvent;