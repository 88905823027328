import { useCallback, useState } from 'react';
// Naming convention just based on other usages of localStorage that I looked at in dev tools
const RIGHT_SIDEBAR_PREF_KEY = 'CRM:Sidebar:right-sidebar-closed';

/**
 * Convenience for checking whether the right sidebar has been collapsed by the user.
 * @returns {boolean} Whether the stored preference is set to true i.e. the right sidebar should be collapsed
 */
export const sidebarIsCollapsed = () => {
  try {
    return window.localStorage.getItem(RIGHT_SIDEBAR_PREF_KEY) === 'true';
  } catch (err) {
    // Always default to showing the sidebar (not collapsed) if pref isn't known
    return false;
  }
};

/**
 * Hook to work with the collapsed right sidebar preference.
 * @returns {[boolean, () => void]} A tuple similar to the return value of `useState`. The first item is a boolean indicating the current value of the preference, and the second item is a function that will toggle that value when called, for both the value of the state variable and the value stored in `localStorage`.
 */
export const useSidebarCollapsed = () => {
  const [isCollapsed, setIsCollapsed] = useState(sidebarIsCollapsed());
  const toggleCollapse = useCallback(() => {
    setIsCollapsed(prevPref => {
      const newPref = !prevPref;
      try {
        window.localStorage.setItem(RIGHT_SIDEBAR_PREF_KEY, newPref.toString());
      } catch (err) {
        // Nothing to do if localStorage is inaccessible for some reason
      }
      return newPref;
    });
  }, []);
  return [isCollapsed, toggleCollapse];
};

/**
 * For unit testing purposes only, there should generally be no need to remove the stored preference where toggling it wouldn't be sufficient.
 */
export const clearStoredPref = () => {
  try {
    window.localStorage.removeItem(RIGHT_SIDEBAR_PREF_KEY);
  } catch (err) {
    // Do nothing
  }
};