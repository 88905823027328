'use es6';

import { DragSource } from 'react-dnd';
const noop = () => ({});
export default ((Component, collect = noop) => DragSource(({
  dragGroup
}) => dragGroup, {
  beginDrag(props, monitor, component) {
    const {
      onDndDrag
    } = props;
    if (typeof onDndDrag === 'function') {
      onDndDrag(props, monitor, component);
    }
    return props;
  },
  canDrag(props, monitor) {
    const {
      canDrag
    } = props;
    if (typeof canDrag === 'function') {
      return canDrag(props, monitor);
    }
    return true;
  },
  endDrag(props, monitor) {
    const {
      onEndDrag
    } = props;
    if (typeof onEndDrag === 'function') {
      onEndDrag(props, monitor);
    }
  }
}, (connect, monitor) => Object.assign({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
  canDragResult: monitor.canDrag()
}, collect(connect, monitor)))(Component));
export const WEBPACK_3_FORCE_MODULE_IMPORT = 1;