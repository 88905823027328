import get from 'transmute/get';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { isEngagementEvent } from 'crm_data/engagements/isEngagementEvent';
import { getTimelineItemId } from '../helpers/getTimelineItemId';
import getIn from 'transmute/getIn';
export function getTimelineItemTimestamp(timelineItem) {
  if (typeof timelineItem !== 'object') {
    return 0;
  }
  return getIn(['eventData', 'engagement', 'timestamp'], timelineItem) || getIn(['engagement', 'timestamp'], timelineItem) || get('timestamp', timelineItem);
}
function timelineItemIdComparator(timelineItemA, timelineItemB) {
  const idA = getTimelineItemId(timelineItemA);
  const idB = getTimelineItemId(timelineItemB);
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  return idA < idB ? 1 : idA > idB ? -1 : 0;
}
export function timelineItemComparator(timelineItemA, timelineItemB) {
  const timestampA = getTimelineItemTimestamp(timelineItemA);
  const timestampB = getTimelineItemTimestamp(timelineItemB);
  if (timestampA === timestampB) {
    let itemACreatedAtDate;
    let itemBCreatedAtDate;
    if (isEngagementEvent(timelineItemA)) {
      itemACreatedAtDate = getIn(['eventData', 'engagement', 'createdAt'], timelineItemA);
    }
    if (isEngagementEvent(timelineItemB)) {
      itemBCreatedAtDate = getIn(['eventData', 'engagement', 'createdAt'], timelineItemB);
    }
    if (itemACreatedAtDate && itemBCreatedAtDate) {
      if (itemACreatedAtDate === itemBCreatedAtDate) {
        return timelineItemIdComparator(timelineItemA, timelineItemB);
      }
      return itemACreatedAtDate < itemBCreatedAtDate ? 1 : -1;
    }
    return timelineItemIdComparator(timelineItemA, timelineItemB);
  }
  return timestampA < timestampB ? 1 : -1;
}