import { PAYMENT_LINK_TYPE_ID, INVOICE_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { DEAL } from 'customer-data-objects/constants/ObjectTypes';
import PortalIdParser from 'PortalIdParser';
const DATA_QUALITY_FEEDBACK_OBJECT_TYPE_ID = '2-20371071';
const DATA_QUALITY_FEEDBACK_OBJECT_TYPE_ID_QA = '2-25683374';
const HUBSPOT_PORTAL_ID = 53;
const HUBSPOT_PORTAL_ID_QA = 99535353;
export const shouldRedirectOnObjectCreation = (associatedObjectType, queryParams) => {
  if (associatedObjectType === PAYMENT_LINK_TYPE_ID || associatedObjectType === INVOICE_TYPE_ID || associatedObjectType === COMMERCE_PAYMENT_TYPE_ID) {
    return false;
  }
  if (associatedObjectType === DEAL) {
    if (queryParams && queryParams.tourId && queryParams.tourId === 'deal-association') {
      return false;
    }
    if (queryParams && queryParams.onboardingTourId && queryParams.onboardingTourId === 'deal-association') {
      return false;
    }
  }
  //Business Logic to support a Flywheel internal only feature in Portal 53 (and QA).  This is the Data Quality
  //Feedback record, which we do not want to redirect each time a new piece of feedback is submitted.
  //Owning team: hs-flywheel-data-experience
  const portalId = PortalIdParser.get();
  if (portalId === HUBSPOT_PORTAL_ID && associatedObjectType === DATA_QUALITY_FEEDBACK_OBJECT_TYPE_ID || portalId === HUBSPOT_PORTAL_ID_QA && associatedObjectType === DATA_QUALITY_FEEDBACK_OBJECT_TYPE_ID_QA) {
    return false;
  }
  return true;
};