'use es6';

import LegacyOwnersStore from 'crm_data/owners/LegacyOwnersStore';
import memoizeLast from 'transmute/memoizeLast';
const formatOwners = memoizeLast((owners, ownerFormatter) => {
  return owners.map(owner => {
    const ownerName = ownerFormatter.format(owner);
    const ownerNameWithEmail = ownerFormatter.formatWithEmail(owner);
    return {
      value: String(owner.ownerId),
      text: ownerName,
      searchText: ownerNameWithEmail,
      dropdownText: ownerNameWithEmail
    };
  }).toArray();
});
export default function (ownerFormatter) {
  return {
    stores: [LegacyOwnersStore],
    deref: () => {
      const owners = LegacyOwnersStore.get({
        usePaged: true
      });
      return formatOwners(owners, ownerFormatter);
    }
  };
}