import { useCallback } from 'react';
import { useTimelineDispatch, useTimelineSelector } from '../../reduxHooks';
import { getCurrentSearchValue, setCurrentSearchValue as setCurrentSearchValueReducer } from '../timelineControlsSlice';
export function useTimelineSearchValue() {
  const currentSearchValue = useTimelineSelector(getCurrentSearchValue);
  const dispatch = useTimelineDispatch();
  const setCurrentSearchValue = useCallback(newSearchValue => {
    dispatch(setCurrentSearchValueReducer(newSearchValue));
  }, [dispatch]);
  return [currentSearchValue, setCurrentSearchValue];
}