'use es6';

let currentId = 0;
const uniqueId = namespace => {
  currentId++;
  return `${namespace}${currentId}`;
};
export default function (actionTypeMap) {
  const uniques = {};
  Object.keys(actionTypeMap).forEach(key => {
    uniques[key] = uniqueId(actionTypeMap[key] || key);
  });
  return uniques;
}