import { Record, Set as ImmutableSet } from 'immutable';
// @ts-expect-error FIXME: No module
import EmailAddressRecord from 'customer-data-email/schema/email/EmailAddressRecord';
export default class Sender extends Record({
  from: new EmailAddressRecord(),
  aliasEmailAddresses: ImmutableSet(),
  signature: null,
  hasPrimaryAccount: true,
  hasEnabledAccounts: true,
  hasGoogleIntegration: false,
  hasOffice365Integration: false,
  hasImapIntegration: false
}, 'Sender') {
  hasIntegration() {
    return this.hasGoogleIntegration || this.hasOffice365Integration || this.hasImapIntegration;
  }
}