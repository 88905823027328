'use es6';

import keyMirror from 'react-utils/keyMirror';
import { fromJS } from 'immutable';
export const EmailTrackingKeyTypes = keyMirror({
  messageId: null,
  trackerKey: null
});
export const EmailTrackingKeyTypeMap = fromJS({
  [EmailTrackingKeyTypes.messageId]: {
    bodyKey: 'messages',
    uri: 'message-id'
  },
  [EmailTrackingKeyTypes.trackerKey]: {
    bodyKey: 'tracker_keys',
    uri: 'tracker-key'
  }
});