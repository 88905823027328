import Raven from 'raven-js';
import devLogger from 'react-utils/devLogger';
import { Metrics } from '../Metrics';
const LOCAL_STORAGE_KEY = 'CRM_SDK:LOCAL_PERF_LOGGER';
function isLocalPerfLoggingEnabled() {
  try {
    return localStorage.getItem(LOCAL_STORAGE_KEY) === 'true';
  } catch (e) {
    return false;
  }
}
export function logTiming(metricsKey, dimensions, timeMs, debugInfo) {
  Metrics.timer(metricsKey, dimensions).update(timeMs);
  if (isLocalPerfLoggingEnabled()) {
    console.log(`[CRM SDK PERF] ${metricsKey}`, dimensions, timeMs, debugInfo);
  }
}
export function incrementCounter(metricsKey, dimensions, debugInfo) {
  Metrics.counter(metricsKey, dimensions).increment();
  if (isLocalPerfLoggingEnabled()) {
    console.log(`[CRM SDK PERF] ${metricsKey}`, dimensions, debugInfo);
  }
}
export function logError(errorMessage, key, extra = {}, originalError) {
  devLogger.warn({
    message: `${errorMessage}. ${originalError ? `Original Error: ${originalError.message}` : ''}`,
    key
  });
  Raven.captureException(new Error(errorMessage), {
    extra: Object.assign({
      originalError: {
        message: originalError === null || originalError === void 0 ? void 0 : originalError.message,
        name: originalError === null || originalError === void 0 ? void 0 : originalError.name
      }
    }, extra)
  });
}
const LOCAL_STORAGE_KEY_MEASURES = 'CRM_SDK:LOCAL_PERF_MEASURES_LOGGER';
function isLocalPerfMeasuresLoggingEnabled() {
  try {
    return localStorage.getItem(LOCAL_STORAGE_KEY_MEASURES) === 'true';
  } catch (e) {
    return false;
  }
}
if (isLocalPerfMeasuresLoggingEnabled()) {
  // eslint-disable-next-line compat/compat
  const observer = new PerformanceObserver((list, __obj) => {
    if (isLocalPerfMeasuresLoggingEnabled()) {
      list.getEntries().filter(entry => !entry.name.startsWith('--')).forEach(entry => {
        const additionalArgs = entry.entryType === 'measure' ? [Math.round(entry.duration)] : [`(${Math.round(entry.startTime)})`];
        console.log('%c %s ', entry.entryType === 'measure' ? 'color:lime;background:black;' : 'color:lime;background:dimgray;', entry.entryType, entry.name, ...additionalArgs);
      });
    }
  });
  observer.observe({
    entryTypes: ['mark', 'measure']
  });
}