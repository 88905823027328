'use es6';

import { Map as ImmutableMap } from 'immutable';
import { DOCUMENT_CONSTANTS } from '../lib/constants';
const {
  DOCUMENT_ATOMIC_TYPE
} = DOCUMENT_CONSTANTS;
export default (({
  name,
  id,
  description,
  thumbnail,
  link,
  align,
  imgWidth,
  imgHeight,
  skipForm
}) => {
  return ImmutableMap({
    atomicType: DOCUMENT_ATOMIC_TYPE,
    name,
    id,
    description,
    thumbnail,
    link,
    align,
    imgWidth,
    imgHeight,
    skipForm
  });
});