'use es6';

import { Map as ImmutableMap } from 'immutable';
import dispatcher from 'dispatcher/dispatcher';
import { COMMUNICATOR } from 'crm_data/actions/ActionNamespaces';
import { CLOSE_COMMUNICATOR_ON_SUBJECT_CHANGE, HIDE_COMMUNICATOR, MAXIMIZE_COMMUNICATOR, MINIMIZE_COMMUNICATOR, SET_COMMUNICATOR_INITIAL_CONTENT, SET_COMMUNICATOR_LAUNCH_CONTEXT, OPEN_FULLSCREEN_COMMUNICATOR, CLOSE_FULLSCREEN_COMMUNICATOR, SHOW_COMMUNICATOR, SET_COMMUNICATOR_TRIGGER_LOCATION, SHOW_COMMUNICATOR_WITH_PERFORMANCE_TRACKING, CLEAR_COMMUNICATOR_PERFORMANCE_TRACKING } from 'crm_data/actions/ActionTypes';
import { defineKeyStore } from 'crm_data/store/KeyStore';
export default defineKeyStore({
  namespace: COMMUNICATOR,
  getInitialState() {
    return ImmutableMap({
      interaction: null,
      isMinimized: false,
      isFullScreen: false,
      initialContent: ImmutableMap(),
      iframeDimensions: 'windowed',
      launchContext: null,
      performanceStartTime: null
    });
  },
  idIsValid(id) {
    return typeof id === 'string';
  }
}).defineResponseTo(SHOW_COMMUNICATOR, (state, {
  type,
  modifiers
}) => {
  const {
    shouldOpenFullScreen
  } = modifiers || {};
  const currentInteractionType = state.get('interaction');
  if (currentInteractionType !== type || state.get('isMinimized') || state.get('isFullScreen')) {
    return state.merge({
      interaction: type,
      isMinimized: false,
      isFullScreen: shouldOpenFullScreen || false,
      launchContext: null
    });
  }
  return state.merge({
    launchContext: null
  });
}).defineResponseTo(SHOW_COMMUNICATOR_WITH_PERFORMANCE_TRACKING, (state, {
  type,
  performanceStartTime,
  modifiers
}) => {
  const {
    shouldOpenFullScreen
  } = modifiers || {};
  const currentInteractionType = state.get('interaction');
  if (currentInteractionType !== type || state.get('isMinimized') || state.get('isFullScreen')) {
    return state.merge({
      interaction: type,
      isMinimized: false,
      isFullScreen: shouldOpenFullScreen || false,
      launchContext: null,
      performanceStartTime
    });
  }
  return state.merge({
    launchContext: null
  });
}).defineResponseTo(SET_COMMUNICATOR_TRIGGER_LOCATION, (state, triggeredLocationContext) => {
  triggeredLocationContext = triggeredLocationContext || {
    triggerLocation: null
  };
  return state.merge({
    triggerLocation: triggeredLocationContext.triggerLocation
  });
}).defineResponseTo(SET_COMMUNICATOR_LAUNCH_CONTEXT, (state, launchContext) => {
  return state.set('launchContext', launchContext);
}).defineResponseTo(HIDE_COMMUNICATOR, state => {
  return state.set('interaction', null).set('launchContext', null).set('performanceStartTime', null);
}).defineResponseTo(CLOSE_COMMUNICATOR_ON_SUBJECT_CHANGE, state => {
  return state.set('interaction', null).set('triggerLocation', null);
}).defineResponseTo(MINIMIZE_COMMUNICATOR, state => {
  return state.set('isMinimized', true).set('isFullScreen', false);
}).defineResponseTo(MAXIMIZE_COMMUNICATOR, state => {
  return state.set('isMinimized', false);
}).defineResponseTo(OPEN_FULLSCREEN_COMMUNICATOR, state => {
  return state.set('isFullScreen', true).set('isMinimized', false);
}).defineResponseTo(CLOSE_FULLSCREEN_COMMUNICATOR, state => {
  return state.set('isFullScreen', false);
}).defineResponseTo(SET_COMMUNICATOR_INITIAL_CONTENT, (state, {
  type,
  content
}) => {
  return state.setIn(['initialContent', type], content);
}).defineResponseTo(CLEAR_COMMUNICATOR_PERFORMANCE_TRACKING, state => state.set('performanceStartTime', null)).register(dispatcher);