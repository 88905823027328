'use es6';

import { Record } from 'immutable';
const EmailAlias = Record({
  address: null,
  displayableAddress: null,
  primary: false,
  signature: null,
  default: false,
  type: null,
  disabled: null,
  inboxId: null,
  conversationsInboxName: null,
  conversationsConnectedAccountId: null
}, 'EmailAlias');
EmailAlias.fromJS = json => {
  if (!json || typeof json !== 'object') {
    return null;
  }
  return EmailAlias(json);
};
export default EmailAlias;