'use es6';

export const ASSOCIATE_COMPANY_AND_DEAL = 'ASSOCIATE_COMPANY_AND_DEAL';
export const ASSOCIATE_COMPANY_AND_ENGAGEMENT = 'ASSOCIATE_COMPANY_AND_ENGAGEMENT';
export const ASSOCIATE_CONTACT_AND_COMPANY = 'ASSOCIATE_CONTACT_AND_COMPANY';
export const ASSOCIATE_CONTACT_AND_DEAL = 'ASSOCIATE_CONTACT_AND_DEAL';
export const ASSOCIATE_CONTACT_AND_ENGAGEMENT = 'ASSOCIATE_CONTACT_AND_ENGAGEMENT';
export const ASSOCIATE_DEAL_AND_ENGAGEMENT = 'ASSOCIATE_DEAL_AND_ENGAGEMENT';
export const ASSOCIATIONS_LOADED = 'ASSOCIATIONS_LOADED';
export const ASSOCIATION_BATCH_LOADED = 'ASSOCIATION_BATCH_LOADED';
export const ASSOCIATION_FETCH_FAILED = 'ASSOCIATION_FETCH_FAILED';
export const ASSOCIATION_FETCH_FINISHED = 'ASSOCIATION_FETCH_FINISHED';
export const ASSOCIATION_FETCH_QUEUED = 'ASSOCIATION_FETCH_QUEUED';
export const ASSOCIATION_LOADED = 'ASSOCIATION_LOADED';
export const DISASSOCIATE_COMPANY_AND_DEAL = 'DISASSOCIATE_COMPANY_AND_DEAL';
export const DISASSOCIATE_COMPANY_AND_ENGAGEMENT = 'DISASSOCIATE_COMPANY_AND_ENGAGEMENT';
export const DISASSOCIATE_CONTACT_AND_COMPANY = 'DISASSOCIATE_CONTACT_AND_COMPANY';
export const DISASSOCIATE_CONTACT_AND_DEAL = 'DISASSOCIATE_CONTACT_AND_DEAL';
export const DISASSOCIATE_CONTACT_AND_ENGAGEMENT = 'DISASSOCIATE_CONTACT_AND_ENGAGEMENT';
export const DISASSOCIATE_DEAL_AND_ENGAGEMENT = 'DISASSOCIATE_DEAL_AND_ENGAGEMENT';
export const ASSOCIATE_TICKET_AND_ENGAGEMENT = 'ASSOCIATE_TICKET_AND_ENGAGEMENT';
export const DISASSOCIATE_TICKET_AND_ENGAGEMENT = 'DISASSOCIATE_TICKET_AND_ENGAGEMENT';
export const ASSOCIATE_UNIVERSAL_ASSOCIATION_AND_ENGAGEMENT = 'ASSOCIATE_UNIVERSAL_ASSOCIATION_AND_ENGAGEMENT';
export const ASSOCIATE_ALL_UNIVERSAL_ASSOCIATIONS_AND_ENGAGEMENT = 'ASSOCIATE_ALL_UNIVERSAL_ASSOCIATIONS_AND_ENGAGEMENT';
export const DISASSOCIATE_UNIVERSAL_ASSOCIATION_AND_ENGAGEMENT = 'DISASSOCIATE_UNIVERSAL_ASSOCIATION_AND_ENGAGEMENT';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = module.exports;
}