/**
 * NOTICE: These constants also exist in crm_universal. Please make updates to both.
 * https://git.hubteam.com/HubSpot/crm_universal/blob/master/static/js/timeline/ETypes.js
 *
 * NOTE: Keep events alphabetized
 */
export const dealCreated = 'dealCreated';
export const dealstageChange = 'dealstageChange';
export const eventAnalytics = 'eventAnalytics';
export const eventAutomation = 'eventAutomation';
export const eventAutomationPlatform = 'eventAutomationPlatform';
export const eventAdInteraction = 'eventAdInteraction';
export const eventCreateSource = 'eventCreateSource';
export const eventCtaClick = 'eventCtaClick';
export const eventCustomBehavioral = 'eventCustomBehavioral';
export const eventDealSplitCreated = 'eventDealSplitCreated';
export const eventDealSplitDeleted = 'eventDealSplitDeleted';
export const eventDealSplitUpdated = 'eventDealSplitUpdated';
export const eventEmailOptOut = 'eventEmailOptOut';
export const eventEmailSend = 'eventEmailSend';
export const eventEmailUnbounce = 'eventEmailUnbounce';
export const eventEngagement = 'eventEngagement';
export const eventFeedbackSubmission = 'eventFeedbackSubmission';
export const eventFormSubmission = 'eventFormSubmission';
export const eventIntegrations = 'eventIntegrations';
export const eventLifecycleStage = 'eventLifecycleStage';
export const eventListMembership = 'eventListMembership';
export const eventMarketingEvent = 'eventMarketingEvent';
export const eventMediaPlaySession = 'eventMediaPlaySession';
export const eventMerge = 'eventMerge';
export const eventMessagingOutboundMessageDelivered = 'eventMessagingOutboundMessageDelivered';
export const eventMessagingOutboundMessageFailed = 'eventMessagingOutboundMessageFailed';
export const eventMessagingOutboundMessageOpened = 'eventMessagingOutboundMessageOpened';
export const eventMessagingOutboundMessageSent = 'eventMessagingOutboundMessageSent';
export const eventMonth = 'eventMonth';
export const eventPageView = 'eventPageView';
export const eventPartnerProofOfInvolvement = 'eventPartnerProofOfInvolvement';
export const eventPaymentAchFailed = 'eventPaymentAchFailed';
export const eventPaymentAchInitiated = 'eventPaymentAchInitiated';
export const eventPaymentAchSucceeded = 'eventPaymentAchSucceeded';
export const eventPaymentCardFailed = 'eventPaymentCardFailed';
export const eventPaymentCardSucceeded = 'eventPaymentCardSucceeded';
export const eventPaymentRefundFailed = 'eventPaymentRefundFailed';
export const eventPaymentRefundProcessing = 'eventPaymentRefundProcessing';
export const eventPaymentRefundSucceeded = 'eventPaymentRefundSucceeded';
export const eventPaymentSubscriptionCanceled = 'eventPaymentSubscriptionCanceled';
export const eventPaymentSubscriptionCreated = 'eventPaymentSubscriptionCreated';
export const eventPaymentSubscriptionStatusChanged = 'eventPaymentSubscriptionStatusChanged';
export const eventPresentation = 'eventPresentation';
export const eventPrivacyConsent = 'eventPrivacyConsent';
export const eventProductQuote = 'eventProductQuote';
export const eventRendered = 'eventRendered';
export const eventSalesforceSync = 'eventSalesforceSync';
export const eventSequence = 'eventSequence';
export const eventSidekick = 'eventSidekick';
export const eventStageChange = 'eventStageChange';
export const eventSurveyMonkeySurvey = 'eventSurveyMonkeySurvey';
export const eventTicket = 'eventTicket';
export const eventTicketCreated = 'eventTicketCreated';
export const eventTicketStageChange = 'eventTicketStageChange';
export const eventTwitterMatch = 'eventTwitterMatch';
export const eventWebinarAttendance = 'eventWebinarAttendance';
export const eventWebinarRegistration = 'eventWebinarRegistration';
export const eventWebInteractiveClose = 'eventWebInteractiveClose';
export const eventWebInteractiveClick = 'eventWebInteractiveClick';
export const eventWebInteractiveView = 'eventWebInteractiveView';
export const eventWhatsAppCommunicationSubscriptionStatusChanged = 'eventWhatsAppCommunicationSubscriptionStatusChanged';
export const e_sms_communication_subscription_status_changed = 'e_sms_communication_subscription_status_changed';
export const e_social_interaction_detected = 'e_social_interaction_detected';
export const eventWistia = 'eventWistia';
export const ETypes = {
  dealCreated,
  dealstageChange,
  eventAnalytics,
  eventAutomation,
  eventAutomationPlatform,
  eventAdInteraction,
  eventCreateSource,
  eventCtaClick,
  eventCustomBehavioral,
  eventDealSplitCreated,
  eventDealSplitDeleted,
  eventDealSplitUpdated,
  eventEmailOptOut,
  eventEmailSend,
  eventEmailUnbounce,
  eventEngagement,
  eventFeedbackSubmission,
  eventFormSubmission,
  eventIntegrations,
  eventLifecycleStage,
  eventListMembership,
  eventMarketingEvent,
  eventMediaPlaySession,
  eventMerge,
  eventMessagingOutboundMessageDelivered,
  eventMessagingOutboundMessageFailed,
  eventMessagingOutboundMessageOpened,
  eventMessagingOutboundMessageSent,
  eventMonth,
  eventPageView,
  eventPartnerProofOfInvolvement,
  eventPaymentAchFailed,
  eventPaymentAchInitiated,
  eventPaymentAchSucceeded,
  eventPaymentCardFailed,
  eventPaymentCardSucceeded,
  eventPaymentRefundFailed,
  eventPaymentRefundProcessing,
  eventPaymentRefundSucceeded,
  eventPaymentSubscriptionCanceled,
  eventPaymentSubscriptionCreated,
  eventPaymentSubscriptionStatusChanged,
  eventPresentation,
  eventPrivacyConsent,
  eventProductQuote,
  eventRendered,
  eventSalesforceSync,
  eventSequence,
  eventSidekick,
  eventStageChange,
  eventSurveyMonkeySurvey,
  eventTicket,
  eventTicketCreated,
  eventTicketStageChange,
  eventTwitterMatch,
  eventWebinarAttendance,
  eventWebinarRegistration,
  eventWebInteractiveClose,
  eventWebInteractiveClick,
  eventWebInteractiveView,
  eventWhatsAppCommunicationSubscriptionStatusChanged,
  e_sms_communication_subscription_status_changed,
  e_social_interaction_detected,
  eventWistia
};