import { getObjectType } from 'customer-data-objects/model/ImmutableModel';
import TimelineFavoriteEventsOverrideStore from '../stores/TimelineFavoriteEventsOverrideStore';
const TimelineOverrideFavoriteEventDependency = {
  stores: [TimelineFavoriteEventsOverrideStore],
  deref: ({
    objectType,
    subject
  }) => {
    if (subject && !objectType) {
      objectType = getObjectType(subject);
    }
    return TimelineFavoriteEventsOverrideStore.get(objectType);
  }
};
export default TimelineOverrideFavoriteEventDependency;