import { useMemo } from 'react';
import { LABEL_WITH_COLOR, asDecoratorColor, decoratorColorToHexColorMap } from 'customer-data-property-utils/optionDecoratorUtils';
import { usePropertyDefinition } from '../../v2/hooks/usePropertyDefinition';
import { useProperty } from '../../v2/hooks/useProperty';
export const decoratorColorToTagMap = {
  GRAY: 'default',
  GREEN: 'oz',
  YELLOW: 'marigold',
  RED: 'candy-apple',
  BLUE: 'calypso',
  ORANGE: 'lorax',
  BLACK: 'olaf',
  PINK: 'norman',
  PURPLE: 'thunderdome'
};
const useOptionColors = () => {
  var _propertyDefinition$e, _propertyDefinition$e2;
  const property = useProperty();
  const {
    propertyDefinition,
    loading
  } = usePropertyDefinition({
    // only enumeration properties can have colors
    skip: property.type !== 'enumeration'
  });
  const shouldUseOptionColors = (propertyDefinition === null || propertyDefinition === void 0 || (_propertyDefinition$e = propertyDefinition.extensionData) === null || _propertyDefinition$e === void 0 || (_propertyDefinition$e = _propertyDefinition$e.optionDecoratorsExtensionData) === null || _propertyDefinition$e === void 0 ? void 0 : _propertyDefinition$e.optionDecoratorStyle) === LABEL_WITH_COLOR;
  const optionDecorators = propertyDefinition === null || propertyDefinition === void 0 || (_propertyDefinition$e2 = propertyDefinition.extensionData) === null || _propertyDefinition$e2 === void 0 || (_propertyDefinition$e2 = _propertyDefinition$e2.optionDecoratorsExtensionData) === null || _propertyDefinition$e2 === void 0 ? void 0 : _propertyDefinition$e2.optionDecorators;
  const colorByOptionValue = useMemo(() => {
    if (!shouldUseOptionColors || !optionDecorators) {
      return {};
    }
    return Object.entries(optionDecorators).reduce((acc, [optionName, decorator]) => {
      const decoratorColor = asDecoratorColor(decorator.color);
      if (!decoratorColor) {
        return acc;
      }
      acc[optionName] = {
        hexColor: decoratorColorToHexColorMap[decoratorColor],
        tagColor: decoratorColorToTagMap[decoratorColor]
      };
      return acc;
    }, {});
  }, [optionDecorators, shouldUseOptionColors]);
  return {
    colorByOptionValue,
    shouldUseOptionColors,
    loading
  };
};
export default useOptionColors;