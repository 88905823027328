'use es6';

import { CharacterMetadata } from 'draft-js';
import { OrderedSet } from 'immutable';
import { STYLE_DELINEATOR } from '../lib/colors';
import { LINK_ENTITY_TYPE } from '../lib/constants';

/**
 * Apply `styles` to `contentState` with a preference for exisiting styles:
 * the new styles get added to the start of each CharacterMetaData's OrderedMap
 * of styles, meaning that they will be overridden by any styles of the same type
 * (e.g. background-color) that already exist
 */
export default ((contentState, styles, {
  ignoreLinkColorStyle = false
} = {}) => {
  if (!styles || !(OrderedSet.isOrderedSet(styles) && styles.size > 0)) {
    return contentState;
  }
  const contentBlocksWithStyles = contentState.getBlockMap().map(block => {
    return block.set('characterList', block.getCharacterList().map(char => {
      const currentStyles = char.getStyle();
      const nonColorDefaultStyles = styles.filter(style => !style.includes(`color${STYLE_DELINEATOR}`));
      const charEntity = char.getEntity();
      const ignoreColor = ignoreLinkColorStyle && charEntity && contentState.getEntity(charEntity).getType() === LINK_ENTITY_TYPE; // `styles` first so it gets overridden where necessary

      const mergedStyles = (ignoreColor ? nonColorDefaultStyles : styles).union(currentStyles);
      let charWithoutStyles = char;
      currentStyles.forEach(style => {
        charWithoutStyles = CharacterMetadata.removeStyle(charWithoutStyles, style);
      });
      let charWithAllStyles = charWithoutStyles;
      mergedStyles.forEach(style => {
        charWithAllStyles = CharacterMetadata.applyStyle(charWithAllStyles, style);
      });
      return charWithAllStyles;
    }));
  });
  return contentState.merge({
    blockMap: contentBlocksWithStyles
  });
});