'use es6';

import I18n from 'I18n';
import { createPlugin, pluginUtils } from 'draft-extend';
import createMeetingEntityOptions from '../../utils/createMeetingEntityOptions';
import MeetingsLinkButton from './MeetingsLinkButton';
import MeetingsLinkDecorator from './MeetingsLinkDecorator';
import { MEETINGS_LINK_TYPES as LINK_TYPES, MEETINGS_LINK_ENTITY_TYPE, MEETING_LINK_DOMAINS } from '../../lib/constants';
const REGEX_PATTERN = new RegExp('\\{\\{\\s?(?:(custom)\\.(sendermeetingslink|ownermeetingslink)|(owner)\\.(meetings_link))\\s?\\}\\}', 'gi');
const entityToHTML = (entity, originalText) => {
  if (entity.type === MEETINGS_LINK_ENTITY_TYPE) {
    const {
      type,
      customText,
      linkURL
    } = entity.data;
    let link;
    if (type === LINK_TYPES.OWNER) {
      link = '{{ owner.meetings_link }}';
    } else if (type === LINK_TYPES.SENDER) {
      link = `{{ custom.${type} }}`;
    } else {
      link = linkURL;
    }
    const text = customText ? originalText : link;
    return `<a href="${link}">${text}</a>`;
  }
  return originalText;
};
const textToEntity = (text, createEntity) => {
  const results = [];
  text.replace(REGEX_PATTERN, (match, customPrefix, customType, ownerPrefix, ownerType, offset) => {
    if (ownerPrefix === 'owner' && ownerType === 'meetings_link') {
      customType = LINK_TYPES.OWNER;
    }
    results.push({
      offset,
      length: match.length,
      result: I18n.text(`draftPlugins.meetings.${customType}`),
      entity: createEntity(...createMeetingEntityOptions(customType))
    });
  });
  return results;
};
const htmlToEntity = (nodeName, node, createEntity) => {
  if (nodeName === 'a' && node.hasAttribute('href')) {
    const url = node.getAttribute('href');
    if (MEETING_LINK_DOMAINS.some(domain => url.indexOf(domain) !== -1)) {
      return createEntity(...createMeetingEntityOptions(LINK_TYPES.CUSTOM, true, url));
    }
    const result = REGEX_PATTERN.exec(node.getAttribute('href'));
    if (result !== null) {
      const [__match, __customPrefix, originalCustomType, ownerPrefix, ownerType] = result;
      let customType = originalCustomType;
      if (ownerPrefix === 'owner' && ownerType === 'meetings_link') {
        customType = LINK_TYPES.OWNER;
      }
      return createEntity(...createMeetingEntityOptions(customType, true));
    }
  }
  return null;
};
export default (({
  onFetchMeetingsError,
  onInsertMeetingsLink,
  getMeetingsLink
} = {}) => createPlugin({
  buttons: MeetingsLinkButton({
    onInsertMeetingsLink,
    getMeetingsLink
  }),
  decorators: {
    strategy: pluginUtils.entityStrategy(MEETINGS_LINK_ENTITY_TYPE),
    component: MeetingsLinkDecorator({
      onFetchMeetingsError,
      onInsertMeetingsLink,
      getMeetingsLink
    })
  },
  entityToHTML,
  textToEntity,
  htmlToEntity
}));