'use es6';

/**
 * Parse float from a property value which is returned from GQL as a string
 *
 * @example
 *
 * propertyValueToFloat(null)
 * > null
 *
 * propertyValueToFloat(undefined)
 * > undefined
 *
 * propertyValueToFloat('')
 * > null
 *
 * propertyValueToFloat('abc')
 * > null
 *
 * propertyValueToFloat('123')
 * > 123
 *
 * @param {string} value the property value to be parsed
 *
 * @returns {number} the float value of the property, null if it's not a valid float, or undefined if the passed in value was undefined
 */
export default function propertyValueToNumber(value) {
  if (value === undefined) return undefined;
  const number = parseFloat(value);
  return !isNaN(number) && isFinite(value) ? number : null;
}