'use es6';

import { Map as ImmutableMap } from 'immutable';
import userInfo from 'hub-http/userInfo';
import { CONTACTS, CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
import DealRecord from 'customer-data-objects/deal/DealRecord';
import { get, send } from 'crm_data/api/ImmutableAPI';
import { del } from 'crm_data/inboundDB/sharedAPI';
import { getId } from 'customer-data-objects/model/ImmutableModel';
import { PUT, POST } from 'crm_data/constants/HTTPVerbs';
import { byIdsStr } from './DealsAPIQuery';
import { DEAL } from 'customer-data-objects/constants/ObjectTypes';
import { EMPTY } from 'crm_data/flux/LoadingStatus';
const contactsCrmUiSourceHeaders = {
  'X-Properties-Source': CONTACTS,
  'X-Properties-SourceId': CRM_UI
};
const BASE_URI = 'deals/v1/deal';
const BATCH_URI = `${BASE_URI}/batch`;
const toUri = queryStr => {
  return `${BATCH_URI}?${queryStr}`;
};
export function createDeal(payload) {
  return send({
    headers: contactsCrmUiSourceHeaders,
    type: POST
  }, BASE_URI, payload, result => ImmutableMap().set(result.dealId, DealRecord.fromJS(result)));
}
export function createDealWithAssociations({
  propertyValues,
  requestedAssociatedObjects
}) {
  return send({
    headers: contactsCrmUiSourceHeaders,
    type: POST
  }, 'inbounddb-objects/v1/create-and-bulk-associate', {
    createObjectType: DEAL,
    propertyValues,
    requestedAssociatedObjects
  }, result => {
    result['dealId'] = result.objectId;
    return ImmutableMap().set(result.dealId, DealRecord.fromJS(result));
  });
}
export function deleteDeal(dealId) {
  return del(`${BASE_URI}/${dealId}`);
}
const parseDeals = result => result.reduce((coll, val) => {
  const deal = DealRecord.fromJS(val);
  const dealId = getId(deal);
  return coll.set(String(dealId), deal);
}, ImmutableMap());
export function fetchById(id) {
  return get(`${BASE_URI}/${id}`).then(deal => {
    return DealRecord.fromJS(deal);
  }).catch(err => {
    if (err.status === 404) {
      return EMPTY;
    }
    throw err;
  });
}
export function fetch(ids) {
  return get(`${toUri(byIdsStr(ids))}&flpViewValidation=true`).then(parseDeals);
}
const updateDealPropertiesWithUser = (user, deal, propertyUpdates) => {
  const dealId = deal.get('dealId');
  return send({
    headers: contactsCrmUiSourceHeaders,
    type: PUT
  }, `${BASE_URI}/${dealId}`, {
    dealId,
    properties: [...propertyUpdates.entries()].reduce((acc, [name, value]) => {
      acc.push({
        name,
        value,
        source: CRM_UI,
        sourceId: user.email
      });
      return acc;
    }, [])
  }, DealRecord.fromJS);
};
export function updateDealProperties(deal, propertyUpdates) {
  return userInfo().then(({
    user
  }) => {
    return updateDealPropertiesWithUser(user, deal, propertyUpdates);
  });
}