// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'ExtensionInstallHelpers.Sideki... Remove this comment to see the full error message
import SidekickExtensionInstallHelpers from 'ExtensionInstallHelpers.SidekickExtensionInstallHelpers';
import requiredData from 'customer-data-tracking/constants/requiredData';
const {
  CHROME_EXTENSION_INSTALLED
} = requiredData;
export function get() {
  return {
    [CHROME_EXTENSION_INSTALLED]: SidekickExtensionInstallHelpers.hasExtension()
  };
}