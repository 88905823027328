module.exports = {
  "embeddedReportView": {
    "name": "embedded report view",
    "class": "view",
    "properties": {
      "action": "string",
      "subscreen2": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "embeddedReportInteraction": {
    "name": "embedded report interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "value": {
        "type": "string",
        "isOptional": true
      },
      "subscreen2": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  }
};