import { CardAction, CardEmptyState, ChickletHoverActions, ChickletSecondaryDisplayLabels } from '../constants/BehaviorTypes';
import PaymentLinkCardEmptyState from '../behaviors/paymentLink/PaymentLinkCardEmptyState';
import getPaymentLinkChickletHoverActions from '../behaviors/paymentLink/getPaymentLinkChickletHoverActions';
import getPaymentLinkChickletSecondaryDisplayLabels from '../behaviors/paymentLink/getPaymentLinkChickletSecondaryDisplayLabels';
import PaymentLinkCardAction from '../behaviors/paymentLink/PaymentLinkCardAction';
export const PaymentLink = {
  [CardEmptyState]: () => PaymentLinkCardEmptyState,
  [CardAction]: () => PaymentLinkCardAction,
  [ChickletHoverActions]: () => getPaymentLinkChickletHoverActions,
  [ChickletSecondaryDisplayLabels]: () => getPaymentLinkChickletSecondaryDisplayLabels
};