import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import Raven from 'raven-js';
import { registerMutation } from 'data-fetching-client';
import { useMutation } from 'data-fetching-client';
export const collapseSdkLocationCard = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"mutation\",\"name\":{\"kind\":\"Name\",\"value\":\"collapseSdkLocationCard\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"CollapseCardInput\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"collapseCard\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"status\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "collapseSdkLocationCard"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "input"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "CollapseCardInput"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collapseCard"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "input"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "input"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "status"
            }
          }]
        }
      }]
    }
  }]
});
export const useCrmLocationService = ({
  additionalLocationData,
  location,
  httpClient,
  onUpdateCardOrder
}) => {
  const apolloClient = useApolloClient();
  const registeredUpdateCardOrderMutation = useMemo(() => {
    if (!additionalLocationData.objectTypeId) {
      return registerMutation({
        fieldName: 'cardOrder',
        args: ['orderedCardIds'],
        fetcher() {
          Raven.captureException(new Error('useCrmLocationService.updateCardOrder: attempt to update card order with either objectTypeId or httpClient absent, card order will not be saved'), {
            extra: {
              objectTypeId: additionalLocationData.objectTypeId,
              httpClientProvided: !!httpClient
            }
          });
          return new Promise(resolve => resolve(null));
        }
      });
    }
    return registerMutation({
      fieldName: 'cardOrder',
      args: ['orderedCardIds'],
      fetcher({
        orderedCardIds
      }) {
        return httpClient.put(`crm-record-cards/v2/card-configuration/${additionalLocationData.objectTypeId}/order`, {
          data: {
            location,
            order: orderedCardIds
          }
        });
      }
    });
  }, [additionalLocationData.objectTypeId, httpClient, location]);
  const [updateCardOrderMutation] = useMutation(registeredUpdateCardOrderMutation);
  const updateIsCardCollapsed = useMemo(() => {
    if (additionalLocationData.objectTypeId !== undefined) {
      const objectTypeId = additionalLocationData.objectTypeId;
      return (cardId, collapsed) => {
        apolloClient.mutate({
          mutation: collapseSdkLocationCard,
          variables: {
            input: {
              cardId,
              collapsed,
              objectTypeId
            }
          }
        }).catch(e => {
          Raven.captureException(new Error('useCrmLocationService.updateIsCardCollapsed: Error saving new state'), {
            extra: {
              originalError: {
                message: e.message,
                name: e.name
              },
              variables: {
                cardId,
                collapsed,
                objectTypeId
              }
            }
          });
        });
      };
    }
    return undefined;
  }, [additionalLocationData.objectTypeId, apolloClient]);
  const updateCardOrder = useCallback(orderedCardIds => {
    updateCardOrderMutation({
      variables: {
        orderedCardIds
      }
    }).then(() => {
      if (onUpdateCardOrder != null) {
        onUpdateCardOrder(orderedCardIds);
      }
    }).catch(e => {
      Raven.captureException(new Error('useCrmLocationService.updateCardOrder: Error saving new state'), {
        extra: {
          originalError: {
            message: e.message,
            name: e.name
          },
          variables: {
            orderedCardIds
          }
        }
      });
    });
  }, [onUpdateCardOrder, updateCardOrderMutation]);
  const service = useMemo(() => {
    return {
      updateIsCardCollapsed,
      updateCardOrder
    };
  }, [updateCardOrder, updateIsCardCollapsed]);
  return service;
};