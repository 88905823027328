import { getObjectTypeIdForMetrics } from './Metrics';
import { getObjectTypeIdFromPath } from './urls';
import { history } from '../router/history';
import { getSkeletonStateSafely } from 'card-associated-objects-lib/localStorage/skeletonState/skeletonStateUtils';
import DefaultTabContainer from '../containers/DefaultTabContainer';
export const getMeasureOptions = ({
  includeAssociationCardLocalStorage,
  includeIsActivitiesTab
} = {}) => {
  const path = history.location.pathname;
  const unformattedObjectTypeId = getObjectTypeIdFromPath(path);
  const options = {
    object_type_id: unformattedObjectTypeId ? getObjectTypeIdForMetrics(unformattedObjectTypeId) : undefined
  };
  if (includeAssociationCardLocalStorage) {
    const skellyState = getSkeletonStateSafely();
    const hasKeys = Boolean(skellyState && Object.keys(skellyState).length > 0);
    options.has_association_card_local_storage = hasKeys;
  }
  if (includeIsActivitiesTab && DefaultTabContainer.get()) {
    const {
      isActivitiesTab
    } = DefaultTabContainer.get();
    options.is_activities_tab = isActivitiesTab;
  }
  return options;
};