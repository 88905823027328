import { scopes } from '../../constants/scopes';
export const useEnrichmentActionPolicies = ({
  hasScope
}) => {
  const hasAccessBilling = hasScope === null || hasScope === void 0 ? void 0 : hasScope(scopes.BILLING_ACCESS);
  const hasAccessEnrichment = hasScope === null || hasScope === void 0 ? void 0 : hasScope(scopes.DATA_ENRICHMENT_ACCESS);
  const hasAccessPlatform = hasScope === null || hasScope === void 0 ? void 0 : hasScope(scopes.ENRICHMENT_PLATFORM_ACCESS);
  const hasFree = hasScope === null || hasScope === void 0 ? void 0 : hasScope(scopes.BREEZE_INTELLIGENCE_FREE);
  const canUpgrade = hasAccessBilling;
  const enrichmentIcon = 'breezeSingleStar';
  const isDisabled = !hasAccessEnrichment;
  const showEnrichmentActions = hasAccessPlatform || hasFree;
  return {
    canUpgrade,
    enrichmentIcon,
    isDisabled,
    showEnrichmentActions
  };
};