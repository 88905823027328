'use es6';

export const GENERIC = 'GENERIC';
export const BLACKLISTED_EMAIL_IN_RECIPIENTS = 'BLACKLISTED_EMAIL_IN_RECIPIENTS';
export const BLACKLISTED_EMAIL_IN_RECIPIENTS_NO_EMAIL = 'BLACKLISTED_EMAIL_IN_RECIPIENTS_NO_EMAIL';
export const ENGAGEMENT_ALREADY_EXISTS = 'ENGAGEMENT_ALREADY_EXISTS';
export const ENGAGEMENT_LIMIT_EXCEEDED = 'ENGAGEMENT_LIMIT_EXCEEDED';
export const ENGAGEMENT_TOO_LARGE = 'ENGAGEMENT_TOO_LARGE';
export const ENGAGEMENT_LOCKED = 'ENGAGEMENT_LOCKED';
export const INVALID_ASSOCIATIONS = 'INVALID_ASSOCIATIONS';
export const INVALID_EMAIL_IN_RECIPIENTS = 'INVALID_EMAIL_IN_RECIPIENTS';
export const MISSING_CONTENT = 'MISSING_CONTENT';
export const MISSING_RECIPIENT_CONTACTS = 'MISSING_RECIPIENT_CONTACTS';
export const SCHEDULED_SEND_LIMIT_EXCEEDED = 'SCHEDULED_SEND_LIMIT_EXCEEDED';
export const SCHEDULED_SEND_TIME_TOO_FAR_IN_FUTURE = 'SCHEDULED_SEND_TIME_TOO_FAR_IN_FUTURE';
export const EMAIL_SEND_ERROR = 'EMAIL_SEND_ERROR';
export const UNSATISFIABLE_CONSTRAINTS = 'UNSATISFIABLE_CONSTRAINTS';
export const ENGAGEMENT_CREATION_INTERNAL_ERROR = 'ENGAGEMENT_CREATION_INTERNAL_ERROR';
export const SEND_LIMIT_PER_SEC_EXCEEDED = 'SEND_LIMIT_PER_SEC_EXCEEDED';
export const EMAIL_SENDING_API_ERROR = 'EMAILSENDINGAPIERROR';
export const CONVERSATIONS_HOSTED_EMAIL_ACCESS_SCOPE_NOT_FOUND = 'CONVERSATIONS_HOSTED_EMAIL_ACCESS_SCOPE_NOT_FOUND';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}