import { Map as ImmutableMap } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import QueuesStore from 'crm_data/queues/QueuesStore';

/** TODO: RELIABILITY_CLEANUP: https://git.hubteam.com/HubSpot/CRM-Issues/issues/5708
 *
 * crm-legacy-global-containersis deprecated.
 * Please use the application-specific container likely in /containers or pass in this data instead.
 */
import GlobalCurrentOwnerContainer from 'crm-legacy-global-containers/GlobalCurrentOwnerContainer';
import GlobalJitaContainer from 'crm-legacy-global-containers/GlobalJitaContainer';
import GridUIStore from '../stores/GridUIStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import EngagementsStore from 'crm_data/engagements/EngagementsStore';
import existingQueueDependency from './existingQueueDependency';
const QueuesDependency = {
  queues: {
    stores: [QueuesStore],
    deref: () => {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      if (GlobalJitaContainer.getContainer().get()) {
        return ImmutableMap();
      }
      return QueuesStore.get();
    }
  },
  existingQueue: existingQueueDependency,
  isTaskOwner: {
    stores: [GridUIStore, EngagementsStore],
    deref: () => {
      const checked = GridUIStore.get('checked');
      const tasks = EngagementsStore.get(checked);
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      const currentOwner = GlobalCurrentOwnerContainer.getContainer().get();
      const notOwner = tasks.some(task => {
        if (!task) {
          return false;
        }
        return task.getIn(['engagement', 'ownerId']) !== currentOwner;
      });
      return !notOwner;
    }
  }
};
export default QueuesDependency;