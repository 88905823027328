import { ETypes, getEngagementOrInteractionType } from 'crm-events-data';
import { isLegacyObjectType } from 'customer-data-objects/types/LegacyObjectType';
// returns the lowercase engagement name for tracking purposes
export const getEngagementTypeForTracking = engagement => {
  const engagementOrInteractionType = getEngagementOrInteractionType({
    engagement
  });
  if (!engagementOrInteractionType) {
    return '';
  }
  return engagementOrInteractionType.toLowerCase();
};
export const getObjectTypeForTracking = objectType => isLegacyObjectType(objectType) ? objectType : 'CRM_OBJECT';

// returns the lowercase event name for tracking purposes
export const getEventTypeForTracking = timelineItem => {
  const etype = timelineItem.get('etype');
  const isEngagement = etype === ETypes.eventEngagement;
  if (isEngagement) {
    return getEngagementTypeForTracking(timelineItem.get('eventData'));
  }
  return etype;
};