import { LEGACY_SIDEBAR_CARD_TYPES } from '../../constants/LegacySidebarCardType';

// Legacy card should not have config type or query fragments
// Extra props on legacy card
// Legacy Sidebar Card Metadata
export const ASSOCIATED_OBJECTS_METADATA = {
  cardType: 'CRM_OBJECT_ASSOCIATION',
  legacySidebarCardType: LEGACY_SIDEBAR_CARD_TYPES.AssociatedObjectsCard,
  hasLegacyExtraProps: true,
  renderAsLegacyCardInCrmLocationResolver: false
};