import { UNPIN_ENGAGEMENT, PIN_ENGAGEMENT, PINNED_ENGAGEMENT_UPDATED } from './PinnedEngagementActionTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { dispatch } from 'crm_data/flux/dispatch';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import * as PinnedEngagementsAPI from 'crm_data/engagements/api/PinnedEngagementsAPI';
import { Map as ImmutableMap } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { ENGAGEMENTS_UPDATED } from 'crm_data/actions/ActionTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { EMPTY } from 'crm_data/constants/LoadingStatus';
export function getPinnedEngagement(objectType, subjectId) {
  return PinnedEngagementsAPI.fetch(objectType, subjectId).then(response => {
    if (!response) {
      return ImmutableMap({
        engagementId: EMPTY,
        exists: false
      });
    }
    const engagementId = response.getIn(['engagement', 'id']);
    dispatch(ENGAGEMENTS_UPDATED, ImmutableMap().set(`${engagementId}`, response));
    dispatch(PINNED_ENGAGEMENT_UPDATED, ImmutableMap().set(`${engagementId}`, response));
    return ImmutableMap({
      engagementId,
      exists: true
    });
  }).catch(error => {
    // Pinned engagement exists, but the user does not have view permissions for it
    if (error.status === 403) {
      return ImmutableMap({
        engagementId: EMPTY,
        exists: true
      });
    }
    return ImmutableMap({
      engagementId: EMPTY,
      exists: false
    });
  });
}
export function pinEngagement(objectType, subjectId, engagementId) {
  PinnedEngagementsAPI.save(objectType, subjectId, engagementId).then(() => {
    dispatch(PIN_ENGAGEMENT, {
      objectType,
      subjectId,
      engagementId
    });
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
}
export function unpinEngagement(objectType, subjectId) {
  PinnedEngagementsAPI.unpin(objectType, subjectId).then(() => {
    dispatch(UNPIN_ENGAGEMENT, {
      objectType,
      subjectId
    });
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return undefined;
}