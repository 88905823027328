import { CARD_TYPES } from 'crm-cards-sdk/constants/CardType';
import { LEGACY_SIDEBAR_CARD_TYPES } from 'crm-cards-sdk/constants/LegacySidebarCardType';
import { isLegacyCrmCardType } from 'crm-cards-sdk/constants/LegacySidebarCardTypeMap';
let __sidebarCardkeys = undefined;
function getSidebarCardKeys(collection) {
  if (!__sidebarCardkeys) {
    __sidebarCardkeys = Object.keys(collection);
  }
  return __sidebarCardkeys;
}
function isSidebarCardType(type) {
  return type && getSidebarCardKeys(LEGACY_SIDEBAR_CARD_TYPES).includes(type);
}
function isExtensionCard(card) {
  return card.definition.cardType === CARD_TYPES.EXTENSION;
}
function isGraphqlQueryResult(card) {
  return Object.prototype.hasOwnProperty.call(card, '__typename');
}
function isCrmCardQueryResult(card) {
  return isGraphqlQueryResult(card) && card.__typename === 'CrmCard';
}
function isFasCardQueryResult(card) {
  return !isGraphqlQueryResult(card);
}

/**
 * Determines if the Card passed in should be treated as a LEGACY_SIDEBAR_CARD
 * LegacySidebarCards require a SidebarCardLocationWrapper and are rendered
 * using the legacy-sidebar-card-adapter
 * @param card
 * @returns
 */
export function isLegacySidebarCard(card) {
  // Check the shape of `card` because some card types are rendered as legacy
  // in the CrmRecordCardsResolver, but not the CrmLocationResolver
  if (isFasCardQueryResult(card)) {
    return isLegacyCrmCardType(card.content.cardType);
  }
  if (isCrmCardQueryResult(card)) {
    // This cardType will look like ATTACHMENTS
    return isLegacyCrmCardType(card.definition.cardType);
  } else {
    // This __typename will look like EngagementAttachmentsCard
    if (isSidebarCardType(card.__typename)) {
      // This is going to be a little trial and error. If we see an extension card,
      // we want to try to run it as a native card instead of a legacy card - but we need
      // to do real testing to validate if this is really possible.
      return !isExtensionCard(card);
    }
  }
  return false;
}