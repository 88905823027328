import { defineLazyKeyStore, registerLazyKeyService
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/store/LazyKeyStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'disp... Remove this comment to see the full error message
import dispatcher from 'dispatcher/dispatcher';
import { TIMELINE_COMMENT_CREATED, TIMELINE_COMMENT_EDITED, TIMELINE_COMMENT_DELETED, TIMELINE_COMMENT_FETCHED_MORE
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { TIMELINE_COMMENT } from 'crm_data/actions/ActionNamespaces';
import { batchFetchComments } from './TimelineCommentsAPI';
import toString from 'transmute/toString';
import { List } from 'immutable';
export const sortedBatchFetch = engagementIds => batchFetchComments(engagementIds).then(response => response.map(engagementId => {
  return engagementId.updateIn(['results'], comments => comments.sortBy(comment => comment.get('createdAt')));
}));
registerLazyKeyService({
  namespace: TIMELINE_COMMENT,
  fetch: sortedBatchFetch
});
export default defineLazyKeyStore({
  namespace: TIMELINE_COMMENT,
  idIsValid: id => typeof id === 'string' || typeof id === 'number',
  idTransform: toString
}).defineResponseTo(TIMELINE_COMMENT_CREATED, (state, {
  engagementId,
  response
}) => {
  engagementId = `${engagementId}`;
  return state.updateIn([engagementId, 'results'], List(), comments => comments.push(response).sortBy(comment => comment.get('createdAt'))).updateIn([engagementId, 'total'], total => total + 1);
}).defineResponseTo(TIMELINE_COMMENT_EDITED, (state, {
  engagementId,
  commentId,
  message,
  atMentionedUsers
}) => {
  if (!engagementId) {
    return state;
  }
  engagementId = `${engagementId}`;
  return state.updateIn([engagementId, 'results'], comments => {
    const commentIdx = comments.findIndex(comment => +comment.get('commentId') === +commentId);
    return comments.update(commentIdx, comment => comment.merge({
      message,
      atMentionedUsers
    }));
  });
}).defineResponseTo(TIMELINE_COMMENT_FETCHED_MORE, (state, {
  engagementId,
  response
}) => {
  engagementId = `${engagementId}`;
  const newComments = response.get('results');
  const newOffset = response.get('offset');
  return state.updateIn([engagementId, 'results'], comments => comments.concat(newComments).sortBy(comment => comment.get('createdAt'))).setIn([engagementId, 'offset'], newOffset);
}).defineResponseTo(TIMELINE_COMMENT_DELETED, (state, {
  engagementId,
  commentId
}) => {
  if (!engagementId || !state || state.size === 0) {
    return state;
  }
  engagementId = `${engagementId}`;
  return state.updateIn([engagementId, 'results'], comments => comments.filterNot(comment => +comment.get('commentId') === +commentId).sortBy(comment => comment.get('createdAt'))).updateIn([engagementId, 'total'], total => total - 1);
}).defineName('EngagementCommentsStore').register(dispatcher);