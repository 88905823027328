'use es6';

import { catchAndRethrowNetworkError } from './monitoring/errorUtils';
import { reviver, get } from './utils/quotesApi';
const INBOUND_QUOTES_ENDPOINT = 'inbounddbquotes/v1';

// ***********************
// * InboundDb endpoints *
// ***********************

// Used to populate QuotesStore
export function batchFetch(ids) {
  return get(`${INBOUND_QUOTES_ENDPOINT}/quotes/with-associated-objects/batch`, {
    id: ids.toArray()
  }, reviver).catch(catchAndRethrowNetworkError);
}