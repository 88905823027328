export const spacingMap = {
  '0': 'none',
  initial: 'none',
  inherit: 'none',
  unset: 'none',
  'space-xxxs': '50',
  'space-xxs': '100',
  'space-xs': '200',
  'space-sm': '300',
  space: '400',
  'space-md': '600',
  'space-lg': '800',
  'space-xl': '1200',
  'space-xxl': '1300',
  'space-xxxl': '1400'
};
export const useSpacingProps = props => {
  const {
    marginBlock,
    marginBlockEnd,
    marginBlockStart,
    marginInline,
    marginInlineEnd,
    marginInlineStart,
    paddingBlock,
    paddingBlockEnd,
    paddingBlockStart,
    paddingInline,
    paddingInlineEnd,
    paddingInlineStart
  } = props;
  const spacingProps = {
    marginBlock: marginBlock ? spacingMap[marginBlock] : undefined,
    marginBlockEnd: marginBlockEnd ? spacingMap[marginBlockEnd] : undefined,
    marginBlockStart: marginBlockStart ? spacingMap[marginBlockStart] : undefined,
    marginInline: marginInline ? spacingMap[marginInline] : undefined,
    marginInlineEnd: marginInlineEnd ? spacingMap[marginInlineEnd] : undefined,
    marginInlineStart: marginInlineStart ? spacingMap[marginInlineStart] : undefined,
    paddingBlock: paddingBlock ? spacingMap[paddingBlock] : undefined,
    paddingBlockEnd: paddingBlockEnd ? spacingMap[paddingBlockEnd] : undefined,
    paddingBlockStart: paddingBlockStart ? spacingMap[paddingBlockStart] : undefined,
    paddingInline: paddingInline ? spacingMap[paddingInline] : undefined,
    paddingInlineEnd: paddingInlineEnd ? spacingMap[paddingInlineEnd] : undefined,
    paddingInlineStart: paddingInlineStart ? spacingMap[paddingInlineStart] : undefined
  };
  return {
    spacingProps
  };
};