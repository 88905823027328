'use es6';

import { Map as ImmutableMap } from 'immutable';
const THUMBNAILS = ImmutableMap({
  pdf: 'pdfFile',
  doc: '',
  txt: '',
  xls: '',
  default: 'file'
});
export function getThumbnailType(file) {
  return THUMBNAILS.get(file.get('extension')) || THUMBNAILS.get('default');
}