'use es6';

import { Set as ImmutableSet } from 'immutable';
export function keyCombination(...keyTesters) {
  function testKeys(inputKeys) {
    if (keyTesters.length !== inputKeys.size) {
      return false;
    }
    return keyTesters.filter(tester => {
      return !!inputKeys.find(key => tester.testKeys(key));
    }).length === keyTesters.length;
  }
  return {
    testKeys
  };
}
export function anyOf(...keys) {
  function testKeys(inputKeys) {
    let inputKey = inputKeys;
    if (ImmutableSet.isSet(inputKeys)) {
      if (inputKeys.size > 1) {
        return false;
      }
      inputKey = inputKeys.first();
    }
    return keys.includes(inputKey);
  }
  return {
    testKeys
  };
}
export function exactly(key) {
  function testKeys(inputKeys) {
    let inputKey = inputKeys;
    if (ImmutableSet.isSet(inputKeys)) {
      if (inputKeys.size > 1) {
        return false;
      }
      inputKey = inputKeys.first();
    }
    return inputKey === key;
  }
  return {
    testKeys
  };
}