// Only to be used for components with engagements (Call, Note, Email, Meeting)

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import OwnersStore from 'crm_data/owners/OwnersStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { isLoading, LOADING, EMPTY } from 'crm_data/flux/LoadingStatus';
const EngagementOwnerDependency = {
  stores: [OwnersStore],
  deref: props => {
    if (!props.engagement) {
      return EMPTY;
    }
    const ownerId = props.engagement.getIn(['engagement', 'ownerId']);
    if (!ownerId) {
      return EMPTY;
    }
    const owner = OwnersStore.get(ownerId);
    if (isLoading(owner)) {
      return LOADING;
    }
    return owner;
  }
};
export default EngagementOwnerDependency;