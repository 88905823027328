import { OPEN_VIEW_SEQUENCE_MODAL } from 'crm-events-data';
import { useCallback } from 'react';
import { SequenceViewModalAsync } from 'sequences-crm-events-lib';
import { fetchSequenceEnrollment } from 'crm-timeline-tasks/queues/data/SequencesAPI';
const openViewSequenceModal = enrollmentId => {
  return fetchSequenceEnrollment(enrollmentId).then(enrollment => {
    return SequenceViewModalAsync({
      enrollment
    }).catch(err => {
      if (!(err instanceof Error)) {
        // Promptable called onReject() because user closed the modal
        return;
      }
      throw err;
    });
  });
};
export const useViewSequenceModalActionHandler = () => {
  const handleViewSequenceModalAction = useCallback((actionType, data) => {
    if (actionType === OPEN_VIEW_SEQUENCE_MODAL) {
      const {
        enrollmentId
      } = data;
      openViewSequenceModal(enrollmentId);
      return;
    }
    return;
  }, []);
  return {
    handleViewSequenceModalAction
  };
};