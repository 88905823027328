'use es6';

import IsUngatedStore from 'crm_data/gates/IsUngatedStore';
import { useStoreDependency } from 'general-store';
import withGateOverride from 'crm_data/gates/withGateOverride';
export const useGateOverride = gate => {
  return useStoreDependency({
    stores: [IsUngatedStore],
    deref: () => {
      return withGateOverride(gate, IsUngatedStore.get(gate));
    }
  });
};
export default useGateOverride;