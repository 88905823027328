import { gql } from '@apollo/client';
export const AllboundTimelineConfigFragment = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"AllboundTimelineConfigFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"AllboundCardConfiguration\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"eventFilters\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"filterKey\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"selected\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"dateRangeFilter\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"startTimestamp\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"option\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"endTimestamp\"}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "AllboundTimelineConfigFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "AllboundCardConfiguration"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "eventFilters"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "filterKey"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "selected"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "dateRangeFilter"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "startTimestamp"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "option"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "endTimestamp"
            }
          }]
        }
      }]
    }
  }]
});