// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import CallingAdminSettingsStore from 'crm_data/callingSettings/CallingAdminSettingsStore';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import IsUngatedStore from 'crm_data/gates/IsUngatedStore';
export default {
  stores: [CallingAdminSettingsStore, IsUngatedStore],
  deref() {
    const callingAdminSettings = CallingAdminSettingsStore.get();
    return Boolean(callingAdminSettings && callingAdminSettings.get('callAndMeetingTypesTrackingEnabled'));
  }
};