import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { registerQuery } from 'data-fetching-client';
export function getBatchOfObjectIds(objectTypeId, objectIds) {
  return http.get(`inbounddb-objects/v1/crm-objects/${objectTypeId}/batch`, {
    portalId: PortalIdParser.get(),
    query: {
      includeAllProperties: true,
      allPropertiesFetchMode: 'all_versions',
      associatedObjectFetch: true,
      id: objectIds
    }
  });
}
export function getCrmObject(type, id) {
  return http.get(`inbounddb-objects/v1/crm-objects/${type}/${id}`, {
    portalId: PortalIdParser.get(),
    query: {
      includeAllProperties: true,
      allPropertiesFetchMode: 'all_versions',
      associatedObjectFetch: true
    }
  });
}
export function updateObject(objectTypeId, objectId, properties) {
  return http.put(`inbounddb-objects/v1/crm-objects/${objectTypeId}/${objectId}`, {
    data: properties,
    portalId: PortalIdParser.get()
  });
}
export const getPropertyDetails = (property, objectType) => http.get(`properties/v4/${objectType}/named/${property}`);
export const GET_PROPERTY_DETAILS_QUERY = registerQuery({
  fieldName: 'propertyDetails',
  args: ['property', 'objectType'],
  fetcher: ({
    property,
    objectType
  }) => getPropertyDetails(property, objectType)
});