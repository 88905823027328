import { EMAIL } from 'customer-data-communicator/schema/interactions/InteractionTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import CommunicatorStore from 'customer-data-communicator/data/communicator/stores/CommunicatorStore';
export const getReplyEngagementId = communicatorStore => {
  const interaction = communicatorStore.get('interaction');
  if (!interaction) {
    return null;
  }
  const initialContent = communicatorStore.get('initialContent');
  if (!initialContent) {
    return null;
  }
  const emailContent = initialContent.get(EMAIL);
  if (!emailContent) {
    return null;
  }
  const replyEngagementId = emailContent.replyEngagementId;
  if (!replyEngagementId) {
    return null;
  }
  return replyEngagementId;
};
const TimelineEmailBeingRepliedToDependency = {
  stores: [CommunicatorStore],
  deref() {
    return getReplyEngagementId(CommunicatorStore);
  }
};
export default TimelineEmailBeingRepliedToDependency;