import { List, Map as ImmutableMap, OrderedMap } from 'immutable';
import reduce from 'transmute/reduce';
export function threadEmailReplies(events) {
  if (!events) {
    return List();
  }
  let currentMonth = '';
  let eventsPerMonth = ImmutableMap();
  let threadedEngagementsMap = OrderedMap();
  events.forEach(event => {
    const initialThreadId = event.getIn(['eventData', 'metadata', 'threadId']);
    const isMigratedEmail = event.getIn(['eventData', 'metadata', 'migratedViaPortalDataMigration']);
    const threadId = isMigratedEmail && initialThreadId ? `${initialThreadId}_MIGRATED` : initialThreadId;
    const monthLabel = event.get('label');
    const hasMonthLabel = !!monthLabel;
    currentMonth = hasMonthLabel ? monthLabel : currentMonth;
    if (!threadId || !threadedEngagementsMap.get(threadId)) {
      threadedEngagementsMap = threadedEngagementsMap.set(
      // label case for month labels, id case for logged emails
      threadId || event.get('label') || event.get('id'), event);
      if (!hasMonthLabel && !eventsPerMonth.get(monthLabel)) {
        eventsPerMonth = eventsPerMonth.set(currentMonth, true);
      }
    }
  });
  return reduce(List(), (acc, val) => acc.push(val), threadedEngagementsMap).filter(event => {
    // to prevent showing empty month labels
    return !event.get('label') || !!eventsPerMonth.get(event.get('label'));
  });
}