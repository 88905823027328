/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { EditorState } from 'draft-js';
import { fetchVideoSharingPage } from '../api/FileManagerApi';
import { VIDEO_CONSTANTS } from '../lib/constants';
const {
  DRAFT_ATOMIC_TYPE_VIDEO
} = VIDEO_CONSTANTS;
export default ((editorState, recipientEmail) => {
  const toAwait = [];
  const content = editorState.getCurrentContent();
  let blockMap = content.getBlockMap();
  blockMap.filter(block => block.getType() === 'atomic' && block.getData().get('atomicType') === DRAFT_ATOMIC_TYPE_VIDEO).forEach(block => {
    const blockData = block.getData();
    const {
      uuid,
      customId
    } = blockData.toJS();
    const responseWithToken = fetchVideoSharingPage(uuid, recipientEmail, customId);
    toAwait.push(responseWithToken);
    responseWithToken.then(({
      sharing_page
    }) => {
      const dataWithUpdatedUrl = blockData.set('url', sharing_page);
      const blockWithUpdatedData = block.set('data', dataWithUpdatedUrl);
      blockMap = blockMap.set(block.getKey(), blockWithUpdatedData);
    });
  });
  const createNewContent = () => {
    const newContent = content.set('blockMap', blockMap);
    return EditorState.createWithContent(newContent);
  };

  // in the case of a catch, the create new content is essentially a no-op
  return Promise.all(toAwait).then(createNewContent).catch(createNewContent);
});