'use es6';

import dispatcher from 'dispatcher/dispatcher';
import { ACTIVITY_TYPES } from '../actions/ActionNamespaces';
import { ACTIVITY_TYPES_UPDATED } from '../actions/ActionTypes';
import { defineLazyValueStore } from '../store/LazyValueStore';
import { fetch } from './ActivityTypesAPI';
export default defineLazyValueStore({
  fetch,
  namespace: ACTIVITY_TYPES
}).defineName('ActivityTypesStore').defineResponseTo(ACTIVITY_TYPES_UPDATED, (state, {
  error,
  newActivityTypes
}) => {
  if (error) {
    return state;
  }
  return state.set('value', newActivityTypes);
}).register(dispatcher);