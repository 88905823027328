'use es6';

import partial from 'transmute/partial';
import FileManagerSidebar from './FileManagerSidebar';
function handleToolbarActionClick(onPluginOpenModal) {
  onPluginOpenModal();
}
export default function FileManagerAttachmentPlugin(opts = {}) {
  opts.onClick = partial(handleToolbarActionClick, opts.onAttachmentSelected, opts.onPluginOpenModal, opts.onPluginCloseModal, opts.gates, opts.AsyncFileManager);
  return FileManagerSidebar(opts);
}