// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import EngagementsStore from 'crm_data/engagements/EngagementsStore';
import { isEmpty, isLoading, EMPTY, LOADING
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/flux/LoadingStatus';
const EngagementBodyDependency = {
  stores: [EngagementsStore],
  deref: ({
    engagementId,
    overriddenBodyPath
  }) => {
    const engagement = EngagementsStore.get(engagementId.toString());
    if (isLoading(engagement)) {
      return LOADING;
    }
    if (isEmpty(engagement)) {
      return EMPTY;
    }
    if (overriddenBodyPath) {
      return engagement.getIn(overriddenBodyPath);
    }
    const body = engagement.getIn(['metadata', 'body']) || engagement.getIn(['metadata', 'html'], '');
    const text = engagement.getIn(['metadata', 'text'], '');
    return body || text;
  }
};
export default EngagementBodyDependency;