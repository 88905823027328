'use es6';

import { fromJS, Map as ImmutableMap } from 'immutable';
import isEmpty from 'transmute/isEmpty';
import memoize from 'transmute/memoize';
import { DEAL, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import { connectPromiseSingle } from 'crm_data/flux/connectPromiseSingle';
import { send } from 'crm_data/api/ImmutableAPI';
import { POST } from 'crm_data/constants/HTTPVerbs';
import { replaceSpecialTypes } from 'crm_data/filters/FilterPlaceholderResolver';
import DealStageStore from 'crm_data/deals/DealStageStore';
import TicketsPipelinesStagesStore from 'crm_data/tickets/TicketsPipelinesStagesStore';
import { elasticSearchApiInfo, elasticSearchApiUrl } from 'crm_data/elasticSearch/api/ElasticSearchAPIInfo';
import { isEligible } from 'crm_data/crmSearch/isEligible';
import { getPropertiesToFetch } from '../../views/PropertiesToFetch';
import { maybeDecorateQueryWithPipelinePermissions } from './maybeDecorateQueryWithPipelinePermissions';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import PortalIdParser from 'PortalIdParser';
export const REQUEST_OPTIONS = {
  type: POST,
  timeout: 60000
};
const fetchDealStages = connectPromiseSingle(DealStageStore, dealStages => dealStages.isEmpty());
const fetchTicketStages = connectPromiseSingle(TicketsPipelinesStagesStore, ticketStages => ticketStages.isEmpty());
export const getNormalizer = memoize(objectType => {
  const api = elasticSearchApiInfo(objectType);
  const isEligibleForCrmSearch = isEligible(objectType);
  const {
    Record
  } = api;
  const idAccessor = isEligibleForCrmSearch ? ['objectId'] : Record._idKey;
  const idSetter = Record._idKey;
  return (data = ImmutableMap()) => {
    const objectsById = data.reduce((map, obj) => {
      let objWithId = obj.setIn(idSetter, obj.getIn(idAccessor));
      if (objectType === CONTACT && isEligibleForCrmSearch) {
        objWithId = objWithId
        // crm search only indexes canonical objects; after a merge the 'losing' objects
        // are dropped from the ES index. the objectId of a result from crm search can be
        // assumed to be its canonical id
        .set('canonical-vid', obj.getIn(idAccessor))
        // crm search only returns results from the current portal
        .set('portal-id', PortalIdParser.get());

        // The following fields returned by contacts search are explicitly not normalized:
        // * is-contact - we rely on the default value `true` in ContactRecord
        // * merged-vids - unused
        // * identity-profiles - unused
        // * merge-audits - unused
      }
      return map.set(objWithId.getIn(idSetter), Record.fromJS(objWithId));
    }, ImmutableMap());
    if (objectsById.size > 0) {
      api.updateStore(objectsById);
    }
    return data.toList().map(obj => obj.getIn(idAccessor));
  };
});
export const getSearchResultsHandler = memoize(objectType => {
  const api = elasticSearchApiInfo(objectType);
  return data => {
    const normalize = getNormalizer(objectType);
    return fromJS(data).updateIn([api.dataKey], normalize).set('_results', data.getIn([api.dataKey]));
  };
});
export const decoratePropertiesToFetch = (objectType, searchQuery = {}) => {
  const properties = getPropertiesToFetch(objectType, searchQuery);
  if (!isEmpty(searchQuery.properties) || isEmpty(properties)) {
    return searchQuery;
  }
  return Object.assign({}, searchQuery, {
    properties
  });
};
const fetchSearchResults = (objectType, searchQuery, queryParams) => {
  searchQuery = searchQuery.toJS();
  if (searchQuery.filterGroups) {
    searchQuery.filterGroups = searchQuery.filterGroups.map(filterGroup => {
      filterGroup.filters = replaceSpecialTypes(filterGroup.filters);
      return filterGroup;
    });
  }
  return send(REQUEST_OPTIONS, elasticSearchApiUrl(objectType, queryParams), decoratePropertiesToFetch(objectType, searchQuery)).then(getSearchResultsHandler(objectType));
};
export const search = (objectType, searchQuery, queryParams) => {
  if (!elasticSearchApiInfo(objectType)) {
    return undefined;
  }
  const queryWithPipelinePermissions = maybeDecorateQueryWithPipelinePermissions(objectType, searchQuery);
  queryParams = new URLSearchParams(queryParams);
  queryParams.set('shouldEnforceFlpViewPermissions', true);
  const fetch = () => fetchSearchResults(objectType, queryWithPipelinePermissions, queryParams.toString());
  if (objectType === DEAL) {
    return fetchDealStages().then(fetch);
  }
  if (objectType === TICKET) {
    return fetchTicketStages().then(fetch);
  }
  return fetch();
};
const onlyShowEditableParam = {
  requestAction: 'EDIT'
};
export const searchEditable = (objectType, searchQuery) => search(objectType, searchQuery, onlyShowEditableParam);