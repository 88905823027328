import { LEGACY_CARDS_METADATA_MAP } from './LegacySidebarCardMetadata';
/**
 * Get the legacy sidebar card type given a crm-sdk card type
 * @param cardType crm card type of a card that has a matching legacy sidebar card type
 *
 */
export function getLegacyCrmCardType(cardType) {
  // TODO: This function should probably be renamed to `getLegacySidebarCardType`
  return LEGACY_CARDS_METADATA_MAP[cardType].legacySidebarCardType;
}

/**
 * Is this a CardType that should be rendered using its legacy-sidebar-card-adapter
 * when using the CrmLocationResolver
 * @param cardType
 * @returns
 */
export function isLegacyCrmCardType(cardType) {
  var _LEGACY_CARDS_METADAT;
  // The flag `renderAsLegacyCardInCrmLocationResolver` is usually true, perhaps we should invert it
  return Boolean((_LEGACY_CARDS_METADAT = LEGACY_CARDS_METADATA_MAP[cardType]) === null || _LEGACY_CARDS_METADAT === void 0 ? void 0 : _LEGACY_CARDS_METADAT.renderAsLegacyCardInCrmLocationResolver);
}