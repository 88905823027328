'use es6';

import { SUCCEEDED } from '../actions/ActionSteps';
import { DELETE, FETCH, REFRESH } from '../actions/ActionVerbs';
import { dispatchImmediate, dispatchQueue } from '../dispatch/Dispatch';
import { makeAsyncActionType, makeAsyncActionTypes, makeAction } from '../actions/MakeActions';
import enviro from 'enviro';
import { defineFactory } from 'general-store';
import identity from 'transmute/identity';
import { fromJS, is, Iterable, Map as ImmutableMap, Record, Seq, Set as ImmutableSet } from 'immutable';
import invariant from 'react-utils/invariant';
import registerService from '../flux/registerService';
import devLogger from 'react-utils/devLogger';
import { getSuperstoreClient } from './getSuperstoreClient';
import { getSuperstoreKey } from './getSuperstoreKey';
export const LazyKeyServiceState = Record({
  pending: ImmutableSet(),
  queue: ImmutableSet()
}, 'LazyKeyServiceState');
function isIterable(ids) {
  return Iterable.isIterable(ids) || Array.isArray(ids);
}
function baseFillUnfoundKeys(idTransform, keys, result) {
  // if any keys are missing we fill them with null so
  // we know they don't exist
  return keys.reduce((filled, key) => filled.set(idTransform(key), result.get(key) || null), ImmutableMap());
}
function enforceId(idIsValid, namespace, id) {
  invariant(idIsValid(id), '`%s` expected `idIsValid` to return true for id: `%s`', namespace, JSON.stringify(id));
  return id;
}
function baseNormalizeIds(idIsValid, idTransform, namespace, ids) {
  if (idIsValid(ids)) {
    return Seq.of(idTransform(ids));
  }
  invariant(isIterable(ids), '`%s` expected `ids` to be an `Iterable` or an `Array` but got `%s`', namespace, ids);
  return Seq(ids).map(enforceId.bind(null, idIsValid, namespace)).map(idTransform);
}
export function defineLazyKeyStore({
  namespace,
  getInitialState = ImmutableMap,
  idIsValid,
  idTransform = identity,
  serializeData = collection => collection && collection.toJS ? collection.toJS() : collection,
  responseTransform = identity,
  unstable_enableCache: enableCache = false
}) {
  invariant(typeof idIsValid === 'function', 'expected `idIsValid` to be a function but got `%s`', idIsValid);
  const FetchTypes = makeAsyncActionTypes(namespace, FETCH);
  const DELETE_SUCCEEDED = makeAsyncActionType(namespace, DELETE, SUCCEEDED);
  const UPDATED = `${namespace}_UPDATED`;
  const CLEARED = makeAction(namespace, 'CLEARED');
  const normalizeIds = baseNormalizeIds.bind(null, idIsValid, idTransform, namespace);
  return defineFactory().defineName(`${namespace}_LazyKeyStore`).defineGetInitialState(getInitialState).defineGet((state, ids) => {
    if (ids === undefined) {
      if (!enviro.deployed() || enviro.getShort() !== 'prod') {
        const warningInfo = {
          message: "Calling LazyKeyStore.get() with no arguments is a debugging feature. It isn't safe for production!",
          url: 'https://git.hubteam.com/HubSpot/CRM/pull/4291',
          key: 'LazyKeyStore:getWarning'
        };
        devLogger.warn(warningInfo);
      }
      return state;
    }
    const idList = normalizeIds(ids);
    if (idList.isEmpty()) {
      return ids;
    }
    const missing = Seq(idList).filterNot(id => state.has(id)).toSet();
    if (!missing.isEmpty()) {
      // sends the missing ids to the LazyKeyService to be fetched
      dispatchQueue(FetchTypes.QUEUED, missing).catch(err => {
        setTimeout(() => {
          throw err;
        });
      });
    }

    // if it's a single id, just return the value
    if (idIsValid(ids)) {
      return state.get(idTransform(ids));
    }
    return ids.map(id => state.get(idTransform(id)));
  }).defineResponseTo(DELETE_SUCCEEDED, (state, keys) => {
    return state.withMutations(nextState => {
      keys.forEach(key => nextState.set(idTransform(key), null));
      return nextState;
    });
  }).defineResponseTo(FetchTypes.SUCCEEDED, (state, newObjects) => {
    if (enableCache) {
      getSuperstoreClient().then(store => store.get(getSuperstoreKey(namespace)).then((existingData = {}) => {
        const dataToStore = existingData;
        newObjects.forEach((entry, key) => {
          dataToStore[key] = serializeData(entry);
        });
        return store.set(getSuperstoreKey(namespace), dataToStore).catch(() => {
          // Some part of the cache write failed. It doesn't really matter what failed,
          // regardless we want to blow away cache so it's clear for next fetch
          return store.delete(getSuperstoreKey(namespace));
        }).catch(() => {
          // ignore. on systems with IndexedDB disabled `store.delete` can fail,
          // but there's nothing we can do about it, so just ignore the error
        });
      }).catch(() => {
        // ignore
      })).catch(() => {
        // ignore
      });
    }
    return newObjects.reduce((acc, entry, key) => acc.set(idTransform(key), responseTransform(entry, key)), state);
  }).defineResponseTo(UPDATED, (state, updatedObjectFragments) => {
    return state.mergeDeep(updatedObjectFragments.reduce((acc, val, key) => acc.set(idTransform(key), val), ImmutableMap()));
  }).defineResponseTo(CLEARED, () => getInitialState());
}
export function registerLazyKeyService({
  namespace,
  fetch,
  // idIsValid,
  idTransform = identity,
  fetchLimit,
  deserializeData = fromJS,
  unstable_enableCache: enableCache = false
}) {
  invariant(typeof fetch === 'function', 'expected `fetch` to be a function but got `%s`', fetch);
  const FetchTypes = makeAsyncActionTypes(namespace, FETCH);
  const RefreshTypes = makeAsyncActionTypes(namespace, REFRESH);
  const cacheKey = getSuperstoreKey(namespace);
  const fillUnfoundKeys = baseFillUnfoundKeys.bind(null, idTransform);
  const handleQueued = (state, ids) => {
    const {
      pending,
      queue
    } = state;
    const newIds = ids.toSet().subtract(pending, queue);
    if (newIds.isEmpty()) {
      return state;
    }
    dispatchQueue(FetchTypes.STARTED).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
    return state.set('queue', queue.union(newIds));
  };
  const handleStarted = state => {
    const {
      pending,
      queue
    } = state;
    if (queue.isEmpty()) {
      return state;
    }
    const fetchQueue = fetchLimit && queue.size > fetchLimit ? queue.slice(0, fetchLimit) : queue;
    const waitQueue = fetchLimit && queue.size > fetchLimit ? queue.slice(fetchLimit) : ImmutableSet();
    const idsToFetch = fetchQueue.toList();
    function fetchItems(maybeCachedData) {
      fetch(idsToFetch).then(result => {
        const fetchedData = fillUnfoundKeys(fetchQueue, result);
        if (!maybeCachedData || !fetchQueue.every(id => {
          const fetched = fetchedData.get(id);
          const cached = maybeCachedData.get(id);
          // if cached value is null/undefined we don't need an immutable compare
          return fetched === cached || is(fetched, cached);
        })) {
          dispatchImmediate(FetchTypes.SUCCEEDED, fetchedData);
        }
        if (waitQueue.size) {
          dispatchQueue(FetchTypes.QUEUED, waitQueue);
        }
        // FIXME: ideally we want to dispatch SETTLED even when the request
        // fails but we don't want that request to be retried forever
        return dispatchQueue(FetchTypes.SETTLED, fetchQueue);
      }, error => dispatchImmediate(FetchTypes.FAILED, error)).catch(err => {
        setTimeout(() => {
          throw err;
        });
      });
    }

    // only execute caching code if cache is enabled and fetch list has different ids
    if (enableCache && !pending.equals(fetchQueue)) {
      getSuperstoreClient().then(store => store.has(cacheKey).then(hasCachedData => {
        // If the store has cached data we can return it and continue the promise chain.
        // If not, we throw an exception to bypass the next `.then` block.
        if (hasCachedData) {
          return store.get(cacheKey);
        }
        return {};
      }).then(cachedState => {
        // With the data returned from the cache, pull out any entries
        // that are a part of the current fetch queue. Dispatch those to the store
        const cachedData = idsToFetch.reduce((acc, _id) => {
          const id = idTransform(_id);
          if (cachedState[id]) {
            return acc.set(id, deserializeData(cachedState[id]));
          }
          return acc;
        }, ImmutableMap());
        if (cachedData.size) {
          dispatchImmediate(FetchTypes.SUCCEEDED, cachedData);
          dispatchImmediate(FetchTypes.SETTLED, idsToFetch);
          return cachedData;
        }
        return undefined;
      }).then(restoredCacheData => {
        // Always fetch items regardless of cache hit - we still want to refresh the
        // cache in the background.
        setTimeout(fetchItems.bind(this, restoredCacheData), 1);
      }).catch(() => {
        // Always fetch items regardless of cache hit - we still want to refresh the
        // cache in the background.
        setTimeout(fetchItems, 1);
        // Regardless of what failed above, pessamistically blow away the cache
        // assuming that there's corruped data present somewhere.
        return store.delete(cacheKey);
      }).catch(() => {
        // ignore. on systems with IndexedDB disabled `store.delete` can fail,
        // but there's nothing we can do about it, so just ignore the error
      })).catch(() => {
        setTimeout(fetchItems, 1);
      });
    } else if (!pending.equals(fetchQueue)) {
      fetchItems();
    }
    return state.merge({
      pending: pending.union(fetchQueue),
      queue: ImmutableSet()
    });
  };
  return registerService(LazyKeyServiceState(), {
    [FetchTypes.QUEUED]: handleQueued,
    [RefreshTypes.QUEUED]: handleQueued,
    [FetchTypes.STARTED]: handleStarted.bind(this),
    [FetchTypes.SETTLED]: (state, keys) => {
      const {
        pending
      } = state;
      return state.set('pending', pending.subtract(keys));
    }
  });
}