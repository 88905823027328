import Raven from 'raven-js';
import env from 'enviro';
import memoize from 'transmute/memoize';
import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
export const AsyncComponentErrorHandler = memoize(chunkName => {
  return function asyncComponentErrorHandler(error) {
    const errorMessage = `Failed to load chunk ${chunkName}`;
    if (!env.deployed()) {
      console.warn(errorMessage);
      console.error(error);
      return null;
    }
    Raven.captureMessage(errorMessage, {
      extra: {
        chunkName,
        originalError: error
      }
    });
    CrmLogger.log('debugChunkLoadIssues', {
      chunk: chunkName,
      error: error && error.message || `unknown error loading chunk ${chunkName}`
    });
    return null;
  };
});