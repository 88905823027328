import Loadable from 'UIComponents/decorators/Loadable';
import Raven from 'raven-js';
function ErrorComponent({
  error
}) {
  Raven.captureException(error);
  return null;
}
export default Loadable({
  loader: () => import( /* webpackChunkName: "PlaybookOverlayWrapper" */'./PlaybookOverlayWrapper').then(mod => mod.default),
  LoadingComponent: () => null,
  ErrorComponent
});