'use es6';

import { fetch } from './AttachmentsAPI';
import { definePooledObjectStore } from '../flux/definePooledObjectStore';
import registerPooledObjectService from '../flux/registerPooledObjectService';
const PREFIX = 'ATTACHMENTS';
registerPooledObjectService({
  actionTypePrefix: PREFIX,
  fetcher: fetch
});
export default definePooledObjectStore({
  actionTypePrefix: PREFIX
}).register();