import usageTracker from '../utils/usageTracker';
import { events } from './events';
export function track(event, properties) {
  usageTracker.track(event, properties);
}
export function trackHover(properties, e) {
  if (e) {
    let timeoutId;
    const onMouseLeave = () => {
      e.target.removeEventListener('mouseleave', onMouseLeave);
      clearTimeout(timeoutId);
      timeoutId = undefined;
    };
    e.target.addEventListener('mouseleave', onMouseLeave);
    if (!timeoutId) {
      timeoutId = setTimeout(() => {
        track(events.embedPointsInteraction, Object.assign({}, properties, {
          action: 'hover'
        }));
      }, 300);
    }
  }
}