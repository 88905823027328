/**
 * Number of milliseconds to wait for browser to become idle, after that execute the callback anyway.
 */
const IDLE_DELAY_TIMEOUT = 5000;
export function delayUntilIdle(callback) {
  if (window.requestIdleCallback) {
    return window.requestIdleCallback(callback, {
      timeout: IDLE_DELAY_TIMEOUT
    });
  } else {
    return window.setTimeout(callback, 0);
  }
}