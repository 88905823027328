'use es6';

import events from 'customer-data-sidebar/events.yaml';
const CRM_TRACKING_NAMESPACE = 'crm';
const getTracker = tracker => tracker.clone({
  events,
  properties: {
    namespace: CRM_TRACKING_NAMESPACE
  }
});
const getExternalTracker = tracker => tracker.clone({
  events,
  properties: {
    namespace: CRM_TRACKING_NAMESPACE
  }
});
export const trackOpenSidebar = (tracker, eventProperties = {}) => {
  const {
    type
  } = eventProperties;
  if (tracker) {
    getTracker(tracker).track('recordInteractions', {
      action: 'preview item from side card',
      type
    });
  }
};
export const trackOpenRecord = (tracker, eventProperties = {}) => {
  const {
    subType,
    type
  } = eventProperties;
  if (tracker) {
    getExternalTracker(tracker).track('sidebar-interaction', {
      action: 'open record',
      subType,
      type
    });
  }
};
export const trackExternalLink = (tracker, eventProperties = {}) => {
  const {
    type
  } = eventProperties;
  if (tracker) {
    getExternalTracker(tracker).track('sidebar-interaction', {
      action: 'open link to external domain',
      type
    });
  }
};
export const trackExternalEmail = (tracker, eventProperties = {}) => {
  const {
    type
  } = eventProperties;
  if (tracker) {
    getExternalTracker(tracker).track('sidebar-interaction', {
      action: 'click external email',
      type
    });
  }
};
export const trackAssociateDisassociate = (tracker, eventProperties = {}) => {
  const {
    type
  } = eventProperties;
  if (tracker) {
    getExternalTracker(tracker).track('sidebar-interaction', {
      action: 'associate/disassociate',
      type
    });
  }
};
export const trackDisassociate = (tracker, eventProperties = {}) => {
  const {
    type
  } = eventProperties;
  if (tracker) {
    getExternalTracker(tracker).track('sidebar-interaction', {
      action: 'disassociate',
      type
    });
  }
};
export const trackAssociate = (tracker, eventProperties = {}) => {
  const {
    type
  } = eventProperties;
  if (tracker) {
    getExternalTracker(tracker).track('sidebar-interaction', {
      action: 'associate',
      type
    });
  }
};
export const trackSpecificPropertyEvent = (tracker, eventProperties = {}) => {
  const {
    action,
    type
  } = eventProperties;
  if (tracker) {
    getExternalTracker(tracker).track('sidebar-interaction', {
      action,
      type
    });
  }
};