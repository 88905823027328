import I18n from 'I18n';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import UserStore from 'crm_data/user/UserStore';
export default function getSampleContacts() {
  const USER_ID = UserStore.get('user_id');
  const sampleContactTag = I18n.text('data.sampleData.sampleContacts.tag');
  return {
    BRIAN: {
      CONTACT_VID: 1,
      // should be unused for tour
      CONTACT_EMAIL: 'bh@hubspot.com',
      // should be unused for tour
      CONTACT_EMAIL_UID: `bh+${USER_ID}@hubspot.com`,
      CONTACT_PHONE: '888-482-7768',
      CONTACT_FIRST_NAME: 'Brian',
      CONTACT_LAST_NAME: `Halligan ${sampleContactTag}`,
      CONTACT_JOBTITLE: 'CEO'
    },
    COOL_ROBOT: {
      CONTACT_VID: 1,
      CONTACT_EMAIL: 'coolrobot@hubspot.com',
      CONTACT_EMAIL_UID: `coolrobot+${USER_ID}@hubspot.com`,
      CONTACT_FIRST_NAME: I18n.text('data.sampleData.sampleContacts.coolRobot.firstname'),
      CONTACT_LAST_NAME: I18n.text('data.sampleData.sampleContacts.coolRobot.lastname'),
      CONTACT_JOB_TITLE: I18n.text('data.sampleData.sampleContacts.coolRobot.jobtitle'),
      CONTACT_PHONE: ''
    },
    STEPH: {
      CONTACT_EMAIL: 'stephanie@glsharchitecture.com'
    },
    MARIA: {
      // Same as Cool Robot, but in practice these will be mutually exclusive contacts
      // as we will be switching from one to the other eventually.
      CONTACT_VID: 1,
      CONTACT_EMAIL: 'emailmaria@hubspot.com',
      CONTACT_EMAIL_UID: `emailmaria+${USER_ID}@hubspot.com`,
      CONTACT_FIRST_NAME: I18n.text('data.sampleData.sampleContacts.maria.firstname'),
      CONTACT_LAST_NAME: I18n.text('data.sampleData.sampleContacts.maria.lastname'),
      CONTACT_JOB_TITLE: I18n.text('data.sampleData.sampleContacts.maria.jobtitle'),
      CONTACT_PHONE: ''
    }
  };
}