import PropTypes from 'prop-types';
import styled from 'styled-components';
import { KOALA } from 'HubStyleTokens/colors';
export const SKELETON_TEXT_HEIGHT = 16;

/**
 * A simple skeleton ui for text which is currently loading; shows a gray bar.
 *
 * Example:
 *   <SkeletonText />
 *   <SkeletonText width={80} />
 *
 * See https://git.hubteam.com/HubSpot/conversations-skeleton-state
 *
 * This file is a copy of conversations-skeleton-state's SkeletonText with the height
 * changed to use px instead of em to simplify dynamic skeleton state size calculations.
 */

const getSize = value => {
  if (!value) {
    return '6em';
  }
  return typeof value === 'number' ? `${value}px` : value;
};
export const SkeletonText = styled.div.attrs(({
  className,
  width
}) => ({
  className,
  ['data-loading']: 'true',
  width
})).withConfig({
  displayName: "SkeletonText",
  componentId: "sc-1ni4or6-0"
})(["background-color:", ";border-radius:999px;display:inline-block;height:", "px;width:", ";"], KOALA, SKELETON_TEXT_HEIGHT, ({
  width
}) => getSize(width));
SkeletonText.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};