import { ADD_SEQUENCE_DEPENDENCY_ALERT } from 'crm-events-data';
import TaskDependencyStore from 'crm-timeline-tasks/data/stores/TaskDependencyStore';
import TimelineTaskDependencies from 'crm-timeline-tasks/data/dependencies/TimelineTaskDependencies';
import { didTaskDependencyPauseSequence as getDidTaskDependencyPauseSequence, isTaskDependencyPausingSequence as getIsTaskDependencyPausingSequence, shouldFetchTaskDependency } from 'crm-timeline-tasks/helpers/timelineTaskUtils';
// @ts-expect-error Could not find a declaration file
import EngagementsStore from 'crm_data/engagements/EngagementsStore';
import { showAlreadyResolvedTaskDependencyAlert, showResolveTaskDependencyAlert
// @ts-expect-error Could not find a declaration file
} from './taskAlerts';
import { TASK_UID_PATH } from 'crm-timeline-tasks/constants/TimelineTaskConstants';
import { COMPLETED } from 'customer-data-objects/engagement/EngagementStatusTypes';
// @ts-expect-error Could not find a declaration file
import { useStoreDependency } from 'general-store';
import promiseDone from 'hs-promise-utils/promiseDone';
import { useCallback } from 'react';
// @ts-expect-error Could not find a declaration file
import SequenceActions from 'sequences-crm-lib/flux/SequenceActions';
export const useTaskQueueActionHandler = ({
  objectType,
  subjectId
}) => {
  const sequenceEnrollment = useStoreDependency(TimelineTaskDependencies.sequenceEnrollment, {
    subjectId,
    objectType
  });
  const isTaskDependencyPausingSequence = useCallback((taskEngagement, taskDependency) => getIsTaskDependencyPausingSequence(taskEngagement, sequenceEnrollment, taskDependency), [sequenceEnrollment]);
  const didTaskDependencyPauseSequence = useCallback((taskEngagement, taskDependency) => {
    return getDidTaskDependencyPauseSequence(taskEngagement, sequenceEnrollment, taskDependency);
  }, [sequenceEnrollment]);
  const showTaskDependencyAlert = useCallback((taskEngagement, taskDependency, futureTaskStatus) => {
    /**
     * When the task status changes, we dispatch the action to update the task
     * and engagement in the corresponding stores BEFORE we dispatch the action
     * to show the sequence alert. The engagement fetch from the store below
     * should have the most-up-to-date status (matching futureTaskStatus) but there
     * is a possibility that the timing could be off, which will cause the wrong
     * messaging to show.
     **/
    if (isTaskDependencyPausingSequence(taskEngagement, taskDependency) && futureTaskStatus === COMPLETED) {
      showResolveTaskDependencyAlert(taskDependency);
    } else if (didTaskDependencyPauseSequence(taskEngagement, taskDependency)) {
      showAlreadyResolvedTaskDependencyAlert(taskDependency, futureTaskStatus !== COMPLETED ? 'notStarted' : 'completed');
    }
  }, [isTaskDependencyPausingSequence, didTaskDependencyPauseSequence]);
  const handleTaskQueueAction = useCallback((actionType, data) => {
    if (actionType === ADD_SEQUENCE_DEPENDENCY_ALERT) {
      const parsedTaskId = data.engagementId;
      const taskEngagement = EngagementsStore.get(parsedTaskId);
      const taskDependency = taskEngagement && TaskDependencyStore.get(taskEngagement.getIn(TASK_UID_PATH));
      if (taskDependency) {
        showTaskDependencyAlert(taskEngagement, taskDependency, data.status);
      } else {
        const shouldFetch = shouldFetchTaskDependency(taskEngagement, sequenceEnrollment, taskDependency);
        if (shouldFetch) {
          const taskUid = taskEngagement.getIn(TASK_UID_PATH);
          promiseDone(SequenceActions.fetchTaskDependency(taskUid), () => {
            const fetchedTaskDependency = TaskDependencyStore.get(taskUid);
            showTaskDependencyAlert(taskEngagement, fetchedTaskDependency, data.status);
          });
        }
      }
    }
    return null;
  }, [showTaskDependencyAlert, sequenceEnrollment]);
  return {
    handleTaskQueueAction
  };
};