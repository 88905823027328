'use es6';

import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { UpdateCrmObjectProperties } from './useUpdateCrmObjectPropertiesMutationQueries';
import { CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
import getIn from 'transmute/getIn';
const isRequired = name => {
  throw new Error(`[useUpdateCrmObjectPropertiesMutation]: required prop ${name} was not provided`);
};

/**
 * Update a CrmObject's properties through the updateCrmObjectPropertiesMutation
 * if the data you need from after the mutation is not being refetched you may have
 * to update useUpdateCrmObjectPropertiesMutationQueries to include the values
 * you want returned in updatedObject
 *
 * @example
 *
 * function ContactNameInput(props) {
 *    const handleError = useCallback(() => {...})
 *    const handleSuccess = useCallback(() => {...})
 *
 *    const propertySourceInfo = useContext(SidebarPropertySourceContext);
 *
 *    const handleUpdateProperties = useUpdateCrmObjectPropertiesMutation({
 *      objectType: props.objectType,
 *      objectId: props.objectId,
 *      propertySourceInfo: propertySourceInfo,
 *      onError: handleError,
 *      onSuccess: handleSuccess,
 *    })
 *
 *    const updateContactName = useCallback((firstName, lastName) => {
 *      const propertyUpdates = [
 *        {
 *          name: 'firstname',
 *          value: firstName,
 *        },
 *        {
 *          name: 'lastname',
 *          value: lastName,
 *        }
 *      ]
 *      handleUpdateProperties(propertyUpdates)
 *    }, [handleUpdateProperties, handleError, handleSuccess])
 *
 *    return (
 *      ...
 *      <InputComponent onSave={updateContactName} />
 *      ...
 *    )
 * }
 *
 *
 * @param {Object}                                                              options
 * @param {String}                                                              options.objectType            the type of the object whose properties are being updated (objectTypeId is not currently supported by the mutation)
 * @param {Number}                                                              options.objectId              the id of the object whose properties are being updated
 * @param {{ propertySource: String, propertySourceId: String }}                options.propertySourceInfo    source application and source id (usually user email) for changes
 * @param {Function}                                                            onSuccess                     callback for when the property updates are successfully completed
 * @param {Function}                                                            onCompleted                   callback for when the request is completed, with or without error
 * @param {Function}                                                            onError                       callback for when the property updates fail
 *
 * @callback onError
 * @param {Object}                                                              error                         an ApolloError object with `networkError` or `graphQLErrors`, or an object with `userErrorsExpanded`
 * @param {Error}                                                               error.networkError            network error object, if a network error occurred
 * @param {Object[]}                                                            error.graphQLErrors           array of graphQL errors, if the mutation was invalid
 * @param {Object[]}                                                            extra.userErrorsExpanded      array of CrmObjectPropertiesMutationError objects with `code` and `message`, if the mutation failed a constraint
 *
 * @return {Function} handleUpdateProperties
 *
 * @callback handleUpdateProperties
 * @param {{ name: String, value: String} []}                                   propertyUpdates               an array of property updates to apply to the object
 *
 */

const useUpdateCrmObjectPropertiesMutation = ({
  objectType = isRequired('objectType'),
  objectId = isRequired('objectId'),
  propertySourceInfo = isRequired('propertySourceInfo'),
  onError,
  onSuccess,
  onCompleted
}) => {
  const handleCompleted = useCallback(() => {
    if (typeof onCompleted === 'function') onCompleted();
  }, [onCompleted]);
  const handleSuccess = useCallback(() => {
    if (typeof onSuccess === 'function') onSuccess();
    handleCompleted();
  }, [onSuccess, handleCompleted]);
  const handleError = useCallback(error => {
    if (typeof onError === 'function') onError(error);
    handleCompleted();
  }, [onError, handleCompleted]);

  // if source context not yet present, CRM_UI is safe fallback
  const sourceHeader = propertySourceInfo ? propertySourceInfo.propertySource : CRM_UI;
  const [updatePropertiesMutation] = useMutation(UpdateCrmObjectProperties, {
    onError: handleError,
    context: {
      hubHttpOptions: {
        headers: {
          'X-Properties-Source': sourceHeader
        }
      }
    }
  });
  const handleUpdateProperties = useCallback(propertyUpdates => {
    updatePropertiesMutation({
      variables: {
        propertyUpdatesInput: {
          objectType,
          objectId,
          properties: propertyUpdates
        }
      },
      update: (_cache, response) => {
        const userErrorsExpanded = getIn(['data', 'updateResponse', 'userErrorsExpanded'], response);
        if (userErrorsExpanded && userErrorsExpanded.length) {
          handleError({
            userErrorsExpanded
          });
        } else {
          handleSuccess();
        }
      }
    });
  }, [updatePropertiesMutation, objectType, objectId, handleSuccess, handleError]);
  return handleUpdateProperties;
};
export { useUpdateCrmObjectPropertiesMutation };