'use es6';

import { Set as ImmutableSet, List } from 'immutable';
import './QueuesService';
import { QUEUES_FETCH_STARTED, QUEUES_CACHE_UPDATED, QUEUES_SET_QUEUE, QUEUES_QUEUE_DELETED, QUEUES_MERGE_QUEUES, ADD_ENGAGEMENTS_TO_QUEUES, ADD_USERS_TO_QUEUE, REMOVE_USERS_FROM_QUEUE } from 'crm_data/actions/ActionTypes';
import { dispatchQueue } from 'crm_data/dispatch/Dispatch';
import dispatcher from 'dispatcher/dispatcher';
import { defineFactory } from 'general-store';
export default defineFactory().defineName('QueuesStore').defineGetInitialState(() => null).defineGet((state, options = {}) => {
  const {
    forceFetch,
    ownerId
  } = options;
  if (!state || forceFetch) {
    dispatchQueue(QUEUES_FETCH_STARTED, {
      ownerId
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  }
  return state;
}).defineResponseTo(QUEUES_CACHE_UPDATED, (state, results) => {
  return results;
}).defineResponseTo(QUEUES_SET_QUEUE, (state, results) => {
  const {
    key,
    value
  } = results;
  return state.set(key, value);
}).defineResponseTo(ADD_ENGAGEMENTS_TO_QUEUES, (state, result) => {
  const {
    queueId,
    engagementIds
  } = result;
  return state.updateIn([queueId, 'engagementIds'], queueEngagementIds => queueEngagementIds.concat(engagementIds));
}).defineResponseTo(QUEUES_MERGE_QUEUES, (state, results) => {
  return state.merge(results);
}).defineResponseTo(QUEUES_QUEUE_DELETED, (state, queueId) => {
  return state.remove(queueId);
}).defineResponseTo(ADD_USERS_TO_QUEUE, (state, result) => {
  const {
    queueId,
    userIds
  } = result;
  return state.updateIn([queueId, 'userParticipants'], userParticipants => {
    return userParticipants ? ImmutableSet(userParticipants).union(ImmutableSet(userIds)).toList() : userIds;
  });
}).defineResponseTo(REMOVE_USERS_FROM_QUEUE, (state, result) => {
  const {
    queueId,
    userIds
  } = result;
  return state.updateIn([queueId, 'userParticipants'], userParticipants => {
    return userParticipants ? ImmutableSet(userParticipants).subtract(ImmutableSet(userIds)).toList() : List();
  });
}).register(dispatcher);