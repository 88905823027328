'use es6';

export const ID = 'id';
export const TYPE = 'type';
export function generateKey(type, objectType, subjectId, portalId) {
  return `Activity:${portalId}-${objectType}-${subjectId}-${type}`;
}
export function toId(type, objectType, subjectId, portalId) {
  return `${portalId}-${objectType}-${subjectId}-${type}`;
}
export function isLocalIdEqualTo(key, id, localAccessor, storage = localStorage) {
  const data = localAccessor(storage, key);
  return !!data && data[ID] === id;
}
export function getLocalType(type, objectType, subjectId, localAccessor, portalId, storage = localStorage) {
  const key = generateKey(type, objectType, subjectId, portalId);
  const id = toId(type, objectType, subjectId, portalId);
  const data = localAccessor(storage, key);
  if (data && data[ID] === id) {
    return data[TYPE];
  }
  return false;
}