import { COMPANY, CONTACT, DEAL, TICKET } from 'customer-data-objects/constants/ObjectTypes';
const getRouteObjectTypeOverride = objectType => {
  switch (objectType) {
    case '0-2':
    case 'company':
    case 'companies':
      return COMPANY;
    case '0-1':
    case 'contact':
    case 'contacts':
      return CONTACT;
    case '0-3':
    case 'deal':
    case 'deals':
      return DEAL;
    case '0-5':
    case 'ticket':
    case 'tickets':
      return TICKET;
    default:
      return objectType;
  }
};
export default getRouteObjectTypeOverride;