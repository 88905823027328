'use es6';

import { List, Record } from 'immutable';
const TeamRecord = Record({
  id: undefined,
  name: '',
  userIds: List()
}, 'TeamRecord');
TeamRecord.fromJS = json => {
  if (json === null || json === undefined) {
    return json;
  }
  return TeamRecord(Object.assign({}, json, {
    userIds: json.userIds ? List(json.userIds) : List()
  }));
};
export default TeamRecord;