// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import EngagementsStore from 'crm_data/engagements/EngagementsStore';
import { getCompactEventMinkowskiEventNameForFiltering } from '../../helpers/compactEventHelpers';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import CRM_UNIVERSAL_ETypes from 'crm_universal/timeline/ETypes';
import { TASK } from 'customer-data-objects/engagement/EngagementTypes';
import { getId as getObjectId, getObjectType, getProperty } from 'customer-data-objects/model/ImmutableModel';
import I18n from 'I18n';
import { List, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import get from 'transmute/get';
import identity from 'transmute/identity';
import memoize from 'transmute/memoize';
import partial from 'transmute/partial';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import TasksStore from 'crm_data/tasks/TasksStore';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { EVENT, MONTH_LABEL } from '../../components/config/TimelineItemTypes';
import { TimelineEventsStore, TimelineStatusStore, timelineItemComparator, getEngagementOrInteractionType, TimelineMonthsStore, TIMELINE_FUTURE_KEY, TimelineSearchResultsStore, FAVORITE_ENGAGEMENT_TYPES, TimelineFavoriteEventsOverrideStore, TimelineFiltersStore, PinnedEngagementsStore, getIsSampleContact } from 'crm-events-data';
function getId(event) {
  return event.get('id');
}
function getMonthsPassed(fromMonth, toMonth) {
  return Math.max(Math.ceil(I18n.moment.duration(toMonth - fromMonth).asMonths()), 1);
}
function getMonthTimestampFromToNow(now, monthsToGoBack) {
  return I18n.moment.userTz(now).subtract(monthsToGoBack, 'month').endOf('month').valueOf();
}
function hasEvents(month) {
  return month && month.size > 0;
}
function isMonthEmpty(currentMonthTimestamp, monthsStore) {
  const currentMonthEvents = monthsStore.get(currentMonthTimestamp);
  return !hasEvents(currentMonthEvents);
}
function isOptOutOrUnbounce(eventType) {
  return eventType === CRM_UNIVERSAL_ETypes.eventEmailOptOut || eventType === CRM_UNIVERSAL_ETypes.eventEmailUnbounce;
}
function sharesTeamId(selectedFilters, timelineItem) {
  const teamId = timelineItem.get('teamId');
  const selectedTeams = selectedFilters.get('selectedTeams');
  const hasSelectedTeam = selectedFilters.get('selectedTeams') && selectedFilters.get('selectedTeams').size;
  if (!hasSelectedTeam) {
    return true;
  }
  return hasSelectedTeam && selectedTeams.includes(teamId);
}
function sharesUserId(selectedFilters, timelineItem) {
  const ownerId = timelineItem.get('ownerId');
  const selectedOwners = selectedFilters.get('selectedOwners');
  const hasSelectedOwners = selectedOwners && selectedOwners.size;
  if (!hasSelectedOwners) {
    return true;
  }
  return hasSelectedOwners && selectedOwners.includes(ownerId);
}
function sharesSearchText(selectedFilters, timelineItem) {
  const searchTextFilter = selectedFilters.get('searchText');
  if (!searchTextFilter) {
    return true;
  }
  const searchResults = TimelineSearchResultsStore.get(searchTextFilter);
  return !!searchResults && searchResults.includes(timelineItem.get('id'));
}
export function shouldFilter(objectType, allFilters, pinnedEngagementId, scopes, timelineItem) {
  const eventType = timelineItem.get('eventType');
  const clientFilterTypes = timelineItem.get('clientFilterTypes');
  const filterToCheck = timelineItem.get('integrationAppId') || FAVORITE_ENGAGEMENT_TYPES.get(eventType) || clientFilterTypes && clientFilterTypes.first() || getCompactEventMinkowskiEventNameForFiltering(eventType, objectType);
  const isTimelineItemPinned = `${pinnedEngagementId}` === timelineItem.get('id');
  const hasSelectedTeam = sharesTeamId(allFilters, timelineItem);
  const hasSelectedOwner = sharesUserId(allFilters, timelineItem);
  const hasSearchText = sharesSearchText(allFilters, timelineItem);
  const isMarketingStarterOrFree = (scopes.includes('marketing-free-product') || scopes.includes('marketing-starter-product')) && !scopes.includes('hub-marketing-product');

  // analytics events aren't available for marketing free/starter users but cookie banners, which
  // are under the analytics filter, are available so allow them
  // can be removed after https://git.hubteam.com/HubSpot/CRM-Issues/issues/4020 is resolved
  const shouldFilterAnalyticsEvent = !(filterToCheck === 'MATCHED_EVENTS' && eventType !== 'eventPrivacyConsent' && isMarketingStarterOrFree);
  return !isTimelineItemPinned && hasSelectedOwner && hasSelectedTeam && hasSearchText && shouldFilterAnalyticsEvent && allFilters.get('eventTypes') && (allFilters.get('eventTypes').includes(filterToCheck) || allFilters.get('eventTypes').includes('EMAIL_SENDS') && isOptOutOrUnbounce(eventType));
}
function getMonthLabel(timestamp, format = 'MMMM YYYY') {
  if (timestamp._isAMomentObject) {
    return timestamp.format(format);
  }
  return I18n.moment.userTz(Number(timestamp)).format(format);
}
function getMonthLabels(timestamp) {
  const label = getMonthLabel(timestamp);
  return ImmutableMap({
    label,
    text: I18n.text('timeline.timelineMonthEvent.emptyMonth', {
      month: label
    })
  });
}
function getCollapsedMonthsLabels(monthInfo) {
  const timestampList = monthInfo.keySeq().map(timestamp => Number(timestamp)).sort().reverse();
  const recentMonth = I18n.moment.userTz(timestampList.first());
  const oldestMonth = I18n.moment.userTz(timestampList.last());
  const oldestMonthWithoutYear = getMonthLabel(oldestMonth, 'MMMM');
  const oldestMonthWithYear = getMonthLabel(oldestMonth);
  const recentMonthWithoutYear = getMonthLabel(recentMonth, 'MMMM');
  const recentMonthWithYear = getMonthLabel(recentMonth);
  if (recentMonth.year() === oldestMonth.year()) {
    return ImmutableMap({
      label: `${recentMonthWithoutYear} - ${oldestMonthWithYear}`,
      text: I18n.text('timeline.timelineMonthEvent.collapsedMonths', {
        start: oldestMonthWithoutYear,
        end: recentMonthWithYear
      })
    });
  }
  return ImmutableMap({
    label: `${recentMonthWithYear} - ${oldestMonthWithYear}`,
    text: I18n.text('timeline.timelineMonthEvent.collapsedMonths', {
      start: oldestMonthWithYear,
      end: recentMonthWithYear
    })
  });
}
function getMonthLabelAndEvents(monthInfo) {
  const events = monthInfo.first();
  if (monthInfo.size > 1) {
    const labels = getCollapsedMonthsLabels(monthInfo);
    return ImmutableMap({
      events,
      label: labels.get('label'),
      text: labels.get('text')
    });
  }
  if (events.size === 0) {
    const labels = getMonthLabels(monthInfo.keySeq().first());
    return ImmutableMap({
      events,
      label: labels.get('label'),
      text: labels.get('text')
    });
  }
  const label = getMonthLabel(monthInfo.keySeq().first());
  return ImmutableMap({
    events,
    label
  });
}
function getMonthsToRender(monthsList, filteredMonthsStore) {
  return monthsList.reduce((list, currentMonthTimestamp) => {
    if (isMonthEmpty(currentMonthTimestamp, filteredMonthsStore)) {
      return list;
    } else {
      const monthInfo = ImmutableMap({
        [currentMonthTimestamp]: filteredMonthsStore.get(currentMonthTimestamp)
      });
      list = list.push(monthInfo);
    }
    return list;
  }, List()).map(getMonthLabelAndEvents);
}
function getTrimmedMonthsToRender(monthsToRender) {
  if (monthsToRender.last().get('events').size === 0) {
    return monthsToRender.delete(-1);
  }
  return monthsToRender;
}
function getFilteredMonthsStore(allFilters, monthsStore, monthsList, objectType, pinnedEngagementId, scopes) {
  const _shouldFilter = partial(shouldFilter, objectType, allFilters, pinnedEngagementId, scopes);
  return monthsList.reduce((map, monthTimestamp) => {
    const allEvents = monthsStore.get(monthTimestamp) || ImmutableSet();
    const eventsToRender = allEvents.filter(_shouldFilter).map(getId);
    return map.set(monthTimestamp, eventsToRender);
  }, ImmutableMap());
}
const getMonthsList = memoize((nextTimestamp, now) => {
  const fromMonth = I18n.moment.userTz(nextTimestamp).startOf('month').valueOf();
  const toMonth = I18n.moment.userTz(now).endOf('month').valueOf();
  const monthsPassed = getMonthsPassed(fromMonth, toMonth);
  const monthsList = [];
  let monthsProcessed = 0;
  while (monthsProcessed < monthsPassed) {
    const monthTimestamp = `${getMonthTimestampFromToNow(now, monthsProcessed)}`;
    if (nextTimestamp <= +monthTimestamp && +monthTimestamp <= toMonth) {
      monthsList.push(monthTimestamp);
    }
    monthsProcessed += 1;
  }
  return monthsList;
});
export function shouldFilterFuture(objectType, allFilters, pinnedEngagementId, timelineItem) {
  const eventType = timelineItem.get('eventType');
  const hasSelectedTeam = sharesTeamId(allFilters, timelineItem);
  const hasSelectedOwner = sharesUserId(allFilters, timelineItem);
  const hasSearchText = sharesSearchText(allFilters, timelineItem);
  const isTimelineItemPinned = `${pinnedEngagementId}` === timelineItem.get('id');
  const filterToCheck = FAVORITE_ENGAGEMENT_TYPES.get(eventType) || getCompactEventMinkowskiEventNameForFiltering(eventType, objectType);
  return !isTimelineItemPinned && hasSelectedTeam && hasSelectedOwner && hasSearchText && allFilters.get('eventTypes').includes(filterToCheck);
}
function getFilteredFutureTimelineEvents(allFilters, futureEvents, objectType, pinnedEngagementId) {
  const _shouldFilter = partial(shouldFilterFuture, objectType, allFilters, pinnedEngagementId);
  return futureEvents.filter(_shouldFilter).map(getId);
}
const hydrateTasksForIds = (ids, {
  objectType,
  subjectId,
  subject
}) => {
  const taskEngagements = ids.map(id => TimelineEventsStore.get(id)).filter(event => event && getEngagementOrInteractionType({
    timelineItem: event
  }) === TASK);
  taskEngagements.map(taskEngagement => {
    // Avoid the initial task fetch for sample contacts in order to
    // allow the sample data to be injected into the TasksStore
    // This is to try and avoid a race condition, although one still could
    // potentially occur in TaskSupplier if the sample tasks aren't
    // loaded before the second TasksStore get called is made
    if (objectType !== CONTACT || !getIsSampleContact(getProperty(subject, 'email'))) {
      const taskId = taskEngagement.getIn(['eventData', 'engagement', 'id']);
      const id = ImmutableMap({
        objectType,
        objectId: subjectId,
        taskId: taskId.toString()
      });
      return TasksStore.get(id);
    }
    return null;
  });
};
const hydrateEventsForIds = (ids, subjectInfo) => {
  hydrateTasksForIds(ids, subjectInfo);
  return ids.map(id => TimelineEventsStore.get(id)).filter(identity).sort(timelineItemComparator).toList().map(event => event.set('timelineItemType', EVENT));
};
const TimelineEventsVirtualizedDependency = {
  stores: [TimelineFiltersStore, TimelineMonthsStore, TimelineStatusStore, PinnedEngagementsStore, TimelineFavoriteEventsOverrideStore, TimelineEventsStore, EngagementsStore, TasksStore, TimelineSearchResultsStore],
  deref: ({
    objectType,
    scopes,
    selectedEventFilters,
    subject,
    subjectId
  }) => {
    if (subject && !(objectType || subjectId)) {
      objectType = getObjectType(subject);
      subjectId = String(getObjectId(subject));
    }

    // Timeline tab selection
    const favoriteEventsOverride = get('eventTypes', TimelineFavoriteEventsOverrideStore.get(objectType));
    const eventTypeFilters = favoriteEventsOverride || selectedEventFilters;
    if (!eventTypeFilters) {
      return List();
    }
    const filtersWithSearchUsersTeams = TimelineFiltersStore.get(objectType) || ImmutableMap();
    const allFilters = filtersWithSearchUsersTeams.set('eventTypes', eventTypeFilters);
    const now = TimelineStatusStore.get('now');
    const timelineStatus = TimelineStatusStore.get('timelineStatus');
    const nextTimestamp = timelineStatus.get('nextTimestamp');
    const monthsList = getMonthsList(nextTimestamp, now);
    const pinnedEngagementMetadata = PinnedEngagementsStore.get(ImmutableMap({
      objectType,
      subjectId
    }));
    const pinnedEngagementId = get('engagementId', pinnedEngagementMetadata);
    const filteredMonthsStore = getFilteredMonthsStore(allFilters, TimelineMonthsStore, monthsList, objectType, pinnedEngagementId, scopes);
    let monthsToRender = getMonthsToRender(monthsList, filteredMonthsStore);
    if (monthsToRender.size && favoriteEventsOverride && !timelineStatus.get('hasMore')) {
      monthsToRender = getTrimmedMonthsToRender(monthsToRender);
    }
    const pastEvents = monthsToRender.reduceRight((acc, month) => List([ImmutableMap({
      label: month.get('label'),
      text: month.get('text', ''),
      count: month.get('events').size,
      timelineItemType: MONTH_LABEL
    })]).concat(hydrateEventsForIds(month.get('events'), {
      objectType,
      subjectId,
      subject
    })).concat(acc), List());
    const futureEvents = TimelineMonthsStore.get(TIMELINE_FUTURE_KEY);
    if (!allFilters || !futureEvents || !futureEvents.size) {
      return pastEvents;
    } else {
      const filteredFutureEvents = getFilteredFutureTimelineEvents(allFilters, futureEvents, objectType, pinnedEngagementId);
      const hydratedFutureEvents = hydrateEventsForIds(filteredFutureEvents, {
        objectType,
        subjectId,
        subject
      }).map(item => item.set('isFuture', true));
      if (!filteredFutureEvents.size) {
        return pastEvents;
      }
      return List([ImmutableMap({
        label: I18n.text('timeline.upcomingEvents'),
        isFuture: true,
        timelineItemType: MONTH_LABEL
      })]).concat(hydratedFutureEvents.reverse()).concat(pastEvents);
    }
  }
};
export default TimelineEventsVirtualizedDependency;