import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { denormalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import IsUngatedStore from 'crm_data/gates/IsUngatedStore';
import { EMAIL_SENDS, INTEGRATION_EVENTS, EMAIL_UNBOUNCES, EMAIL_OPTOUTS } from '../../timeline/constants/TimelineConstants';
export const fillExtraEventOptions = (objectType, eventTypes) => {
  const isUngatedForCommunicationActivityFilter = IsUngatedStore.get('subscriptions:communicationActivityFilter');
  let eventTypesToSave = eventTypes.toSet();
  const hasIntegrations = eventTypesToSave.find(eventOption => {
    return /integrations-app.*/.test(eventOption);
  });
  const hasEmailSends = eventTypesToSave.has(EMAIL_SENDS);
  if (hasIntegrations) {
    eventTypesToSave = eventTypesToSave.add(INTEGRATION_EVENTS);
  }
  if (!hasIntegrations && eventTypesToSave.has(INTEGRATION_EVENTS)) {
    eventTypesToSave = eventTypesToSave.remove(INTEGRATION_EVENTS);
  }
  if (objectType === CONTACT && hasEmailSends) {
    eventTypesToSave = eventTypesToSave.add(EMAIL_UNBOUNCES);
    if (!isUngatedForCommunicationActivityFilter) {
      eventTypesToSave = eventTypesToSave.add(EMAIL_OPTOUTS);
    }
  }
  if (objectType === CONTACT && !hasEmailSends) {
    eventTypesToSave = eventTypesToSave.remove(EMAIL_UNBOUNCES);
    if (!isUngatedForCommunicationActivityFilter) {
      eventTypesToSave = eventTypesToSave.remove(EMAIL_OPTOUTS);
    }
  }
  return eventTypesToSave.toList();
};
export const fillExtraEventOptionsJS = ({
  objectTypeOrId,
  eventTypes
}) => {
  const isUngatedForCommunicationActivityFilter = IsUngatedStore.get('subscriptions:communicationActivityFilter');
  const objectType = denormalizeTypeId(objectTypeOrId);
  const eventTypesToSave = new Set(eventTypes);
  const hasIntegrations = eventTypes.some(eventOption => /integrations-app.*/.test(eventOption));
  const hasEmailSends = eventTypesToSave.has(EMAIL_SENDS);
  if (hasIntegrations) {
    eventTypesToSave.add(INTEGRATION_EVENTS);
  }
  if (!hasIntegrations && eventTypesToSave.has(INTEGRATION_EVENTS)) {
    eventTypesToSave.delete(INTEGRATION_EVENTS);
  }
  if (objectType === CONTACT && hasEmailSends) {
    eventTypesToSave.add(EMAIL_UNBOUNCES);
    if (!isUngatedForCommunicationActivityFilter) {
      eventTypesToSave.add(EMAIL_OPTOUTS);
    }
  }
  if (objectType === CONTACT && !hasEmailSends) {
    eventTypesToSave.delete(EMAIL_UNBOUNCES);
    if (!isUngatedForCommunicationActivityFilter) {
      eventTypesToSave.delete(EMAIL_OPTOUTS);
    }
  }
  return [...eventTypesToSave];
};