export const MISSING_PERMISSIONS = 'crmActions.validationErrors.missingPermission';
export const PERMISSIONS_ERROR = 'crmActions.validationErrors.permissionsError';
export const MISSING_ACTION_TYPE = 'crmActions.validationErrors.missingActionType';
export const MISSING_ARGS = 'crmActions.validationErrors.missingArgs';
export const INVALID_ACTION_TYPE = 'crmActions.validationErrors.invalidActionType';
const VALIDATION_ERROR_KEYS = {
  MISSING_PERMISSIONS,
  PERMISSIONS_ERROR,
  MISSING_ACTION_TYPE,
  MISSING_ARGS,
  INVALID_ACTION_TYPE
};