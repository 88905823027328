import { convertRgbStringToHex, RGB_PATTERN } from './colorUtils';
import { forEachNode } from './rlib-dom';
import isValidPasteContent from './isValidPasteContent';
import parseHTML from './parseHTML';
import { closest } from 'UIComponents/utils/Dom';
const MS_LIST_FIRST = 'MsoListParagraphCxSpFirst';
const MS_LIST_MIDDLE = 'MsoListParagraphCxSpMiddle';
const MS_LIST_LAST = 'MsoListParagraphCxSpLast';
const MSFT_LIST_CLASSNAMES = [MS_LIST_FIRST, MS_LIST_MIDDLE, MS_LIST_LAST];
const MSFT_IGNORE = /mso-list:Ignore/;
export const LINK_COLOR = '#1155cc';
export const CONVERT_MSWORD_TO_HTML = 'msword';

// trailing 'o' is intentional: MS Word literally
// uses an 'o' character sometimes
const MS_BULLET_REGEX = /[\u2022\u00b7\u00a7\u25CFoo]/g;
const MS_LIST_PSEUDO_STYLE = 'mso-list:Ignore';
const cleanWhiteSpace = text => text.replace(/\s+/g, '').trim();
const makeStyleTracker = () => {
  let styleCount = {};
  const trackStyle = (styleValue, lengthOfStyledContent) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (styleCount[styleValue]) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      styleCount[styleValue] += lengthOfStyledContent;
    } else {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      styleCount[styleValue] = lengthOfStyledContent;
    }
  };
  const reset = () => {
    styleCount = {};
  };
  const getStyleCount = val => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return styleCount[val];
  };
  const getMajorityStyling = () => {
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    return Object.keys(styleCount).reduce((styleA, styleB) => {
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'null' cannot be used as an index type.
      const styleACount = styleCount[styleA] || 0;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const styleBCount = styleCount[styleB] || 0;
      return styleACount > styleBCount ? styleA : styleB;
    }, null);
  };
  return {
    trackStyle,
    reset,
    getStyleCount,
    getMajorityStyling
  };
};
const makeStyleManager = () => {
  const styleTrackers = {
    ['font-size']: makeStyleTracker(),
    ['color']: makeStyleTracker(),
    ['background-color']: makeStyleTracker()
  };
  const reset = () => {
    Object.values(styleTrackers).forEach(tracker => tracker.reset());
  };
  const getTrackedStyles = () => {
    return Object.keys(styleTrackers);
  };
  const getTracker = styleKey => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return styleTrackers[styleKey];
  };
  const trackNodeStyles = node => {
    getTrackedStyles().forEach(styleName => {
      const tracker = getTracker(styleName);
      const styleValue = node.style[styleName];
      if (styleValue) {
        const lengthOfStyledContent = cleanWhiteSpace(node.textContent).length;
        tracker.trackStyle(styleValue, lengthOfStyledContent);
      }
    });
  };
  return {
    reset,
    getTracker,
    getTrackedStyles,
    trackNodeStyles
  };
};
const styleManager = makeStyleManager();
const stripElementStyle = (element, styleName, styleValue, tagName) => {
  const closestTag = closest(element, tagName);
  const closestTagStyleValue = closestTag && closestTag.style[styleName];
  const hasStyledParent = closestTag && closestTagStyleValue !== '';
  const hasStyleApplied = element.style[styleName] === styleValue;
  if (!hasStyledParent && hasStyleApplied) {
    element.style.removeProperty(styleName);
  }

  // Remove the style from the element if it uses the majorityStyleValue and
  // the element's closest parent `tagName` also uses the majorityStyleValue
  // The `tagName` also needs the style otherwise the element will fall back to a
  // a different style that was not intendeed for the element.
  const parentHasStyleApplied = closestTagStyleValue === styleValue;
  if (parentHasStyleApplied && hasStyleApplied) {
    element.style.removeProperty(styleName);
  }
};
const removeSpanIfEmpty = element => {
  // Bring the child out of the span if no more styles are present
  if (element.nodeName === 'SPAN' && element.style.length === 0) {
    element.parentNode.replaceChild(element.firstChild, element);
  }
};
const stripStyles = (element, styleName, styleValue) => {
  stripElementStyle(element, styleName, styleValue, 'P');
  stripElementStyle(element, styleName, styleValue, 'LI');
  removeSpanIfEmpty(element);
};
const applyContextualStyles = body => {
  styleManager.getTrackedStyles().forEach(styleName => {
    const tracker = styleManager.getTracker(styleName);
    const majorityStyleValue = tracker.getMajorityStyling();
    if (!majorityStyleValue) {
      return;
    }
    const overAllStyleCount = cleanWhiteSpace(body.textContent).length;
    const majorityStyleCount = tracker.getStyleCount(majorityStyleValue);
    // if style is applied to less than 75% of the content keep that style
    const isBelowMajorityStylingThreshold = majorityStyleCount / overAllStyleCount < 0.75;
    if (isBelowMajorityStylingThreshold) {
      return;
    }
    forEachNode(element => stripStyles(element, styleName, majorityStyleValue), body.querySelectorAll(`[style*="${styleName}"]`));
  });
};
const getListType = node => {
  const fakeStyleNode = node.querySelector(`[style='${MS_LIST_PSEUDO_STYLE}']`);
  const isBullet = fakeStyleNode && fakeStyleNode.textContent.trim().match(MS_BULLET_REGEX);
  if (isBullet) {
    return 'ul';
  }
  return 'ol';
};
const MS_LEVEL_REGEX = /mso-list:\w+\slevel(\d)\s/;
const getListDepth = node => {
  const style = node.getAttribute('style');
  const match = style.match(MS_LEVEL_REGEX);
  const level = match && match[1];
  if (!level) {
    return 1;
  }
  return parseInt(level, 10);
};
const createListItem = node => {
  const listItem = document.createElement('li');
  forEachNode(child => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getCleanNode(child).forEach(cleanChild => {
      listItem.appendChild(cleanChild);
    });
  }, node.childNodes);
  return listItem;
};

// @ts-expect-error ts-migrate(7023) FIXME: 'diveToListDepth' implicitly has return type 'any'... Remove this comment to see the full error message
const diveToListDepth = (node, depth, currentDepth = 1) => {
  if (depth === currentDepth) {
    return node;
  }
  const nextPossibleParent = node.querySelector('ol, ul');
  if (!nextPossibleParent) {
    return node;
  }
  return diveToListDepth(nextPossibleParent, depth, currentDepth + 1);
};

// @ts-expect-error ts-migrate(7023) FIXME: 'parseList' implicitly has return type 'any' becau... Remove this comment to see the full error message
const parseList = (node, currentDepth = 1, currentParent = null) => {
  const listType = getListType(node);
  const newDepth = getListDepth(node);
  const listItem = createListItem(node);
  const listParent = currentParent && currentParent.cloneNode(true) || document.createElement(listType);
  const sibling = node.nextElementSibling;
  if (!sibling || !MSFT_LIST_CLASSNAMES.includes(sibling.className)) {
    // base case: no further list nodes
    listParent.appendChild(listItem);
    return listParent;
  } else if (newDepth === currentDepth) {
    // current node and sibling are also list siblings
    const targetParent = diveToListDepth(listParent, currentDepth);
    targetParent.appendChild(listItem);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'HTMLUListElement' is not assigna... Remove this comment to see the full error message
    return parseList(sibling, newDepth, listParent);
  } else if (newDepth < currentDepth) {
    // going UP a nesting level
    const targetParent = diveToListDepth(listParent, newDepth);
    targetParent.appendChild(listItem);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'HTMLUListElement' is not assigna... Remove this comment to see the full error message
    return parseList(sibling, newDepth, listParent);
  } else {
    // newDepth > currentDepth -> going DOWN a nesting level
    const newList = document.createElement(listType);
    newList.appendChild(listItem);
    const targetParent = diveToListDepth(listParent, currentDepth);
    targetParent.appendChild(newList);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'HTMLUListElement' is not assigna... Remove this comment to see the full error message
    return parseList(sibling, newDepth, listParent);
  }
};

// turn margin into padding -- TinyMCE uses padding in its indentation plugin --
// unless we're an li in which case we remove it entirely since MS Word
// includes print spacing in its lists which is unnecessary in the browser
// @ts-expect-error ts-migrate(7023) FIXME: 'processIndentationStyles' implicitly has return t... Remove this comment to see the full error message
const processIndentationStyles = (node, deep = true) => {
  if (node.childNodes && deep) {
    // need to process child indentation styles too
    // first process this node
    // @ts-expect-error ts-migrate(7022) FIXME: 'newParent' implicitly has type 'any' because it d... Remove this comment to see the full error message
    const newParent = processIndentationStyles(node, false);
    // recurse over children (some of whom might have their own children)
    forEachNode(child => {
      newParent.appendChild(processIndentationStyles(child, true));
    }, node.childNodes);
    return newParent;
  } else {
    // just processes this node
    // we still respect the deep param here because other
    // recursive fns might want to just clone a parent
    // like we do in our if case above
    const newNode = node.cloneNode(deep);
    if (!newNode.style) {
      return newNode;
    }
    const currentLeft = newNode.style.marginLeft;
    if (newNode.nodeName === 'LI' && currentLeft) {
      newNode.style.marginLeft = null;
    } else if (currentLeft) {
      newNode.style.paddingLeft = currentLeft;
      newNode.style.marginLeft = null;
    }
    return newNode;
  }
};
const getCleanNode = node => {
  // base case
  if (node.childNodes && node.childNodes.length <= 1) {
    if (node.nodeName === '#comment') {
      return []; // don't want comments
    }
    if (node.outerHTML && node.outerHTML.match(MSFT_IGNORE)) {
      return []; // MS says ignore, we ignore
    }
    const newNode = processIndentationStyles(node, true);
    if (newNode.nodeName === 'A') {
      const firstChild = newNode.firstChild;
      if (!firstChild) {
        return []; // don't return empty links
      }
      if (firstChild.nodeName === 'SPAN' && firstChild.style) {
        // possibly dealing with link-specific inline styles
        const color = firstChild.style.color;
        const match = color && color.match(RGB_PATTERN);
        if (!match) {
          if (color === 'blue') {
            firstChild.style.color = null;
          }
        } else if (color && convertRgbStringToHex(color) === LINK_COLOR) {
          // we handle coloring links without using inline styles in the editors
          firstChild.style.color = null;
        }
      }
    }
    return [newNode];
  }

  // list case
  if (node.className === MS_LIST_FIRST) {
    return [parseList(node)];
  }
  if (node.className === MS_LIST_MIDDLE || node.className === MS_LIST_LAST) {
    return []; // parseList handles all list nodes so we skip the rest
  }

  // recursive (non-list) case
  if (node.childNodes) {
    const newNode = processIndentationStyles(node, false);
    const cleanChildren = [];
    forEachNode(child => {
      cleanChildren.push(...getCleanNode(child));
    }, node.childNodes);
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'childNode' implicitly has an 'any' type... Remove this comment to see the full error message
    cleanChildren.forEach(childNode => {
      newNode.appendChild(childNode);
    });
    return [newNode];
  }

  // fallback case, namely node.childNodes == null
  return [processIndentationStyles(node)];
};
const getCleanDocument = dirty => {
  styleManager.reset();
  const body = document.createElement('body');
  const nodes = dirty.childNodes;
  forEachNode(node => {
    getCleanNode(node).forEach(cleanNode => {
      body.appendChild(cleanNode);
    });
  }, nodes);
  forEachNode(node => {
    if (node.style) {
      styleManager.trackNodeStyles(node);
    }
  }, body.querySelectorAll('[style]'));
  applyContextualStyles(body);
  return body;
};
export default function convertMSWordToHtml(clipboardContent, htmlParser = parseHTML) {
  isValidPasteContent(clipboardContent);
  return getCleanDocument(htmlParser(clipboardContent.replace(/(\r\n|\n|\r)/, ''))).outerHTML;
}