'use es6';

import { Record, Seq } from 'immutable';
const EmailContentRecord = Record({
  subject: null,
  to: null,
  ccs: Seq(),
  bccs: Seq(),
  body: null,
  replyEngagementId: null,
  trackerKey: null,
  templateId: null
}, 'EmailContentRecord');
export default class EmailContent extends EmailContentRecord {
  static fromJS(json) {
    if (!json || typeof json !== 'object') {
      return json;
    }
    return new EmailContent(Object.assign({}, json, {
      ccs: Seq(json.ccs),
      bccs: Seq(json.bccs)
    }));
  }
}