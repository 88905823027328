'use es6';

import { List, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import invariant from 'react-utils/invariant';
import PortalIdParser from 'PortalIdParser';
import { TICKET } from 'customer-data-objects/constants/ObjectTypes';
import { ES_DATA_UPDATED, TICKETS_CREATE_FAILED, TICKETS_CREATE_QUEUED, TICKETS_CREATE_SUCCEEDED, TICKETS_UPDATE_FAILED, TICKETS_UPDATE_STARTED, TICKETS_UPDATE_SUCCEEDED, TICKETS_UPDATED, TICKETS_REFRESH_QUEUED, ASSOCIATIONS_REFRESH_QUEUED, OBJECT_ASSOCIATION_SUCCEEDED } from 'crm_data/actions/ActionTypes';
import { dispatchImmediate, dispatchQueue } from 'crm_data/dispatch/Dispatch';
import * as TicketsAPI from 'crm_data/tickets/api/TicketsAPI';
import * as ImmutableModel from 'customer-data-objects/model/ImmutableModel';
import { TICKET_TO_COMPANY, TICKET_TO_CONTACT, TICKET_TO_DEAL } from 'crm_data/associations/AssociationTypes';
const makeAssociationKey = (id, associationType) => {
  return ImmutableMap({
    objectId: id,
    objectType: TICKET,
    associationType
  });
};
export function createTicket(ticketRecord) {
  const getPropertiesList = objectRecord => objectRecord.get('properties').reduce((list, {
    value,
    sourceId,
    source
  }, name) => list.push({
    value,
    name,
    sourceId,
    source
  }), List());
  const apiPayload = {
    associations: {},
    portalId: PortalIdParser.get(),
    properties: getPropertiesList(ticketRecord)
  };
  dispatchImmediate(TICKETS_CREATE_QUEUED);
  return TicketsAPI.createTicket(apiPayload).then(ticket => {
    dispatchQueue(TICKETS_CREATE_SUCCEEDED, ticket);
    return ticket;
  }).catch(_ => dispatchQueue(TICKETS_CREATE_FAILED, _));
}
export function createTicketWithAssociations(ticketRecord, {
  requestedAssociatedObjects
}) {
  const getPropertiesList = objectRecord => objectRecord.get('properties').reduce((list, {
    value,
    sourceId,
    source
  }, name) => list.push({
    value,
    name,
    sourceId,
    source
  }), List());
  const propertyValues = getPropertiesList(ticketRecord);
  dispatchImmediate(TICKETS_CREATE_QUEUED);
  return TicketsAPI.createTicketWithAssociations({
    propertyValues,
    requestedAssociatedObjects
  }).then(ticket => {
    dispatchQueue(TICKETS_CREATE_SUCCEEDED, ticket);
    requestedAssociatedObjects.forEach(association => {
      dispatchQueue(OBJECT_ASSOCIATION_SUCCEEDED, {
        objectId: association.objectId,
        associationsAdded: [ImmutableModel.getId(ticket)],
        fromObjectType: association.objectType,
        toObjectType: TICKET
      });
    });
    return ticket;
  }).catch(error => {
    dispatchQueue(TICKETS_CREATE_FAILED, error);
    throw error;
  });
}
export function updateElasticSearch(ticket, searchQuery, searchResults) {
  const ticketId = ImmutableModel.getId(ticket);
  const optimisticResults = searchResults.update('_results', (results = List()) => results.push(ticketId)).update('results', (results = List()) => results.push(ticketId)).update('total', (total = 0) => total + 1);
  dispatchQueue(TICKETS_UPDATED, ImmutableMap({
    [ticketId]: ticket
  }));
  dispatchQueue(ES_DATA_UPDATED, {
    objectType: TICKET,
    searchQuery,
    results: optimisticResults
  });
}
export function updateTickets(tickets) {
  invariant(ImmutableMap.isMap(tickets), 'TicketsActions: expected tickets to be a Map but got `%s`', tickets);
  return dispatchImmediate(TICKETS_UPDATED, tickets);
}
export function updateTicketProperties(ticket, nextProperties, options) {
  if (options == null) {
    options = {};
  }
  const ticketActionPayload = {
    id: `${ImmutableModel.getId(ticket)}`,
    nextProperties,
    properties: nextProperties.map((_, name) => ImmutableModel.getProperty(ticket, name)),
    options
  };
  dispatchImmediate(TICKETS_UPDATE_STARTED, ticketActionPayload);
  return TicketsAPI.updateTicketProperties(ticket, nextProperties).then(nextTicket => {
    dispatchImmediate(TICKETS_UPDATE_SUCCEEDED, {
      id: `${ImmutableModel.getId(nextTicket)}`,
      nextProperties
    });
  }).catch(error => {
    dispatchImmediate(TICKETS_UPDATE_FAILED, Object.assign({}, ticketActionPayload, {
      error
    }));
    throw error;
  });
}
export function deleteTicket(ticketId, callback) {
  return TicketsAPI.deleteTicket(ticketId).then(() => {
    setTimeout(() => {
      dispatchImmediate(TICKETS_UPDATED, ImmutableMap().set(`${ticketId}`, null));
    }, 0);
    return typeof callback === 'function' ? callback() : undefined;
  });
}
export function refresh(ids) {
  dispatchQueue(TICKETS_REFRESH_QUEUED, ImmutableSet(ids));
  dispatchQueue(ASSOCIATIONS_REFRESH_QUEUED, ImmutableSet([makeAssociationKey(ids[0], TICKET_TO_COMPANY), makeAssociationKey(ids[0], TICKET_TO_CONTACT), makeAssociationKey(ids[0], TICKET_TO_DEAL)]));
}