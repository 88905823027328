module.exports = {
  "sidebar-interaction": {
    "name": "sidebar-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "subType": {
        "type": "string",
        "isOptional": true
      },
      "cardId": {
        "type": "string",
        "isOptional": true
      },
      "startIndex": {
        "type": "string",
        "isOptional": true
      },
      "endIndex": {
        "type": "string",
        "isOptional": true
      },
      "sourceApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "recordInteractions": {
    "name": "record interactions",
    "class": "interaction",
    "properties": {
      "toObjectType": {
        "type": "string",
        "isOptional": true
      },
      "action": "string",
      "objectType": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  }
};