import { List } from 'immutable';
export const ENGAGEMENT_ID_PATH = List(['engagement', 'id']);
export const DUE_DATE_PATH = List(['engagement', 'timestamp']);
export const TASK_SUBJECT_PATH = List(['metadata', 'subject']);
export const REMINDERS_PATH = List(['metadata', 'reminders']);
export const TASK_TYPE_PATH = List(['metadata', 'taskType']);
export const TASK_PRIORITY_PATH = List(['metadata', 'priority']);
export const ASSIGNED_TO_PATH = List(['engagement', 'ownerId']);
export const DEFAULT_REMINDER_PATH = List(['metadata', 'sendDefaultReminder']);
export const CREATED_BY_PATH = List(['engagement', 'createdBy']);
export const SOURCE_PATH = List(['engagement', 'source']);
export const SOURCE_ID_PATH = List(['engagement', 'sourceId']);
export const BODY_PATH = List(['metadata', 'body']);
export const TASK_STATUS_PATH = List(['metadata', 'status']);
export const QUEUE_MEMBERSHIP_IDS_PATH = List(['engagement', 'queueMembershipIds']);
export const TASK_UID_PATH = List(['engagement', 'uid']);