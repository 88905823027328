'use es6';

import dispatcher from 'dispatcher/dispatcher';
import either from 'transmute/either';
import isNumber from 'transmute/isNumber';
import isString from 'transmute/isString';
import { defineJoinStore } from '../store/JoinStore';
import OwnerIdsByRemoteIdStore from './OwnerIdsByRemoteIdStore';
import OwnersStore from './OwnersStore';
export default defineJoinStore({
  stores: [OwnerIdsByRemoteIdStore, OwnersStore],
  idIsValid: either(isNumber, isString)
}).defineName('OwnersByRemoteIdStore').register(dispatcher);