import { useCallback } from 'react';
import { UPDATE_ENGAGEMENT_ATTACHMENTS, UPDATE_NOTE_WITH_EDITS } from 'crm-events-data';
import { Map as ImmutableMap, fromJS } from 'immutable';
// @ts-expect-error Could not find a declaration file
import { updateEngagementAttachments } from 'crm_data/engagements/EngagementsActions';
// @ts-expect-error Could not find a declaration file
import { batchFetch } from 'crm_data/engagements/api/EngagementsAPI';
// @ts-expect-error Could not find a declaration file
import { updateEngagementInStore } from 'crm_data/engagements/EngagementsV2Actions';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
function updateEngagementWithNewAttachment(engagementId, newAttachmentId) {
  batchFetch([engagementId]).then(engagementRecords => {
    const engagement = engagementRecords.get(`${engagementId}`);
    if (engagement) {
      const updatedAttachments = engagement.get('attachments').push(ImmutableMap({
        id: newAttachmentId
      }));
      updateEngagementAttachments(engagement, ImmutableMap({
        attachments: updatedAttachments
      }));
    }
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
}
function updateNoteBodyAndOwnerIds(engagementId, body, ownerIds) {
  batchFetch([engagementId]).then(engagementRecords => {
    const engagement = engagementRecords.get(`${engagementId}`);
    if (engagement) {
      const updatedEngagement = engagement.setIn(['metadata', 'body'], body).setIn(['associations', 'ownerIds'], ownerIds);
      updateEngagementInStore(updatedEngagement);
    }
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
}
export const useAttachmentsActionHandler = (objectType, subjectId) => {
  const sendUpdateAttachmentsMessage = useSendCrmMessageTopic(TOPIC_NAMES.UPDATE_RECORD_ATTACHMENTS);
  const handleAttachmentsAction = useCallback((actionType, data) => {
    switch (actionType) {
      case UPDATE_ENGAGEMENT_ATTACHMENTS:
        sendUpdateAttachmentsMessage({
          objectTypeId: normalizeTypeId(objectType),
          objectId: subjectId,
          engagementId: data.engagementId
        });
        updateEngagementWithNewAttachment(data.engagementId, data.newAttachmentId);
        return;
      case UPDATE_NOTE_WITH_EDITS:
        updateNoteBodyAndOwnerIds(data.engagementId, data.body, fromJS(data.ownerIds));
        return;
      default:
        return;
    }
  }, [sendUpdateAttachmentsMessage, objectType, subjectId]);
  return {
    handleAttachmentsAction
  };
};