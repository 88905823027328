import { LEGACY_SIDEBAR_CARD_TYPES } from '../../constants/LegacySidebarCardType';

// Legacy card should not have config type or query fragments
// Extra props on legacy card
// Legacy Sidebar Card Metadata
export const CONVERSATIONS_THREAD_TICKET_METADATA = {
  cardType: 'CONVERSATION_THREAD_TICKET',
  legacySidebarCardType: LEGACY_SIDEBAR_CARD_TYPES.ConversationsThreadTicketCard,
  hasLegacyExtraProps: true,
  renderAsLegacyCardInCrmLocationResolver: false
};