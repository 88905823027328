import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { getProperty, getId } from 'customer-data-objects/model/ImmutableModel';
import { injectSampleContactTimelineEvents } from '../helpers/SampleTimelineEventUtils';
import { getIsSampleContact } from '../helpers/SampleContactUtils';
import { useEffect } from 'react';
export const injectSampleTimelineEventsForContact = (objectType, email, subjectId, ownerId) => {
  if (objectType !== CONTACT || !getIsSampleContact(email)) {
    return;
  }
  const sampleContactEmail = email;
  injectSampleContactTimelineEvents(subjectId, sampleContactEmail, ownerId);
  CrmLogger.logRecordInteraction(CONTACT, {
    action: 'view sample record'
  });
};
export const useInjectSampleTimelineEventsForContact = ({
  objectType,
  subject,
  ownerId
}) => {
  const email = getProperty(subject, 'email');
  const subjectId = getId(subject);
  useEffect(() => {
    injectSampleTimelineEventsForContact(objectType, email, subjectId, ownerId);
  }, [email, objectType, ownerId, subjectId]);
};