'use es6';

export default ((editorState, selectionState) => {
  if (selectionState === undefined) {
    selectionState = editorState.getSelection();
  }
  const currentContent = editorState.getCurrentContent();
  const startBlockKey = selectionState.getStartKey();
  const startOffset = selectionState.getStartOffset();
  const endBlockKey = selectionState.getEndKey();
  const endOffset = selectionState.getEndOffset();
  const currentBlockMap = currentContent.getBlockMap();
  let newBlocks = currentBlockMap.skipUntil((_, blockKey) => blockKey === startBlockKey).takeUntil((_, blockKey) => blockKey === endBlockKey).set(endBlockKey, currentBlockMap.get(endBlockKey));
  if (startBlockKey === endBlockKey) {
    const targetBlock = newBlocks.get(startBlockKey);
    newBlocks = newBlocks.set(startBlockKey, targetBlock.merge({
      text: targetBlock.getText().slice(startOffset, endOffset),
      characterList: targetBlock.getCharacterList().slice(startOffset, endOffset)
    }));
  } else {
    const startBlock = newBlocks.get(startBlockKey);
    newBlocks = newBlocks.set(startBlockKey, startBlock.merge({
      text: startBlock.getText().slice(startOffset),
      characterList: startBlock.getCharacterList().slice(startOffset)
    }));
    const endBlock = newBlocks.get(endBlockKey);
    newBlocks = newBlocks.set(endBlockKey, endBlock.merge({
      text: endBlock.getText().slice(0, endOffset),
      characterList: endBlock.getCharacterList().slice(0, endOffset)
    }));
  }
  return currentContent.merge({
    blockMap: newBlocks,
    selectionBefore: selectionState,
    selectionAfter: selectionState.merge({
      anchorKey: selectionState.getStartKey(),
      anchorOffset: selectionState.getStartOffset(),
      focusKey: selectionState.getEndKey(),
      focusOffset: selectionState.getEndOffset()
    })
  });
});