'use es6';

import { Map as ImmutableMap, List, fromJS } from 'immutable';
import http from 'hub-http/clients/apiClient';
const baseUrl = 'knowledge-content/v1/knowledge-articles';
const filterPublished = articles => articles.filter(a => a.currentState === 'PUBLISHED');
const formatResponse = response => {
  const {
    objects,
    offset,
    count,
    total,
    hasMore
  } = response;
  return ImmutableMap({
    hasMore: hasMore || false,
    offset: offset || 0,
    count: count || objects.length,
    total: total || objects.length,
    results: List(filterPublished(objects)).map(article => fromJS(article))
  });
};
export const defaultApiWrapper = apiClient => ({
  fetchRenderedContent({
    id
  }) {
    return apiClient.get(baseUrl, {
      query: {
        id
      }
    }).then(results => {
      const article = results.objects[0];
      const {
        absoluteUrl,
        title
      } = article;
      return {
        body: title,
        htmlBody: `<a href=${absoluteUrl}>${title}</p>`
      };
    });
  },
  searchArticles({
    count,
    offset,
    query
  }) {
    return apiClient.get(baseUrl, {
      query: {
        limit: count,
        offset,
        name__icontains: query,
        order: '-updated',
        state__eq: 'PUBLISHED',
        archived: false
      }
    }).then(formatResponse);
  }
});
export const searchArticles = ({
  count,
  offset,
  query
}) => {
  return http.get(baseUrl, {
    query: {
      limit: count,
      offset,
      name__icontains: query,
      order: '-updated',
      state__eq: 'PUBLISHED',
      archived: false
    }
  }).then(formatResponse);
};