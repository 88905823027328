import http from 'hub-http/clients/apiClient';
import catchAndRethrow from '../utils/catchAndRethrow';
import NetworkBinding from 'growth-payments-core/binding/networkBinding';
import { createNetworkHook } from 'growth-payments-core/binding/useNetworkBinding';
const HAS_PAYMENT_LINK_ENDPOINT = 'sales-checkout/v2/session-templates/has-payment-link';
export const fetchHasPaymentLink = () => http.get(`${HAS_PAYMENT_LINK_ENDPOINT}`).catch(catchAndRethrow);
export const hasPaymentLinkBinding = new NetworkBinding(fetchHasPaymentLink);
export const useHasPaymentLinkBinding = createNetworkHook(hasPaymentLinkBinding);
const fetchHasPaymentLinkExcludingDeleted = () => http.get(`${HAS_PAYMENT_LINK_ENDPOINT}?includeDeleted=false`).catch(catchAndRethrow);
export const hasPaymentLinkExcludingDeletedBinding = new NetworkBinding(fetchHasPaymentLinkExcludingDeleted);
export const useHasPaymentLinkExcludingDeletedBinding = createNetworkHook(hasPaymentLinkExcludingDeletedBinding);