import { registerQuery } from 'data-fetching-client';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
export const GET_PROPERTY_DEFINITIONS = registerQuery({
  fieldName: 'propertyDefinitions',
  args: ['objectTypeId'],
  fetcher: ({
    objectTypeId
  }) => {
    return getFrameworkDataSchemasClient().then(client => client.properties.get({
      frameworkTypeIdentifier: objectTypeId,
      query: {
        showHighlySensitiveProperties: true
      }
    }));
  }
});