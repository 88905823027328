import CommercePaymentCardEmptyState from '../behaviors/commercePayment/CommercePaymentCardEmptyState';
import { CardAction, CardEmptyState, ChickletSecondaryDisplayLabels, ChickletHoverActions } from '../constants/BehaviorTypes';
import CommercePaymentCardAction from '../behaviors/commercePayment/CommercePaymentCardAction';
import getCommercePaymentChickletHoverActions from '../behaviors/commercePayment/getCommercePaymentChickletHoverActions';
import getPaymentChickletSecondaryDisplayLabels from '../behaviors/commercePayment/getPaymentChickletSecondaryDisplayLabels';
export const CommercePayment = {
  [CardEmptyState]: () => CommercePaymentCardEmptyState,
  [CardAction]: () => CommercePaymentCardAction,
  [ChickletSecondaryDisplayLabels]: () => getPaymentChickletSecondaryDisplayLabels,
  [ChickletHoverActions]: () => getCommercePaymentChickletHoverActions
};