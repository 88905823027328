'use es6';

import quickFetch from 'quick-fetch';
import { fromJS } from 'immutable';
import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
const BASE_URL = 'inbounddb-meta/v1';

// Copy this name when quick-fetching object type definitions
const QUICK_FETCH_NAME = 'object-type-definitions-fetch';
const removeRequest = () => quickFetch.removeEarlyRequest(QUICK_FETCH_NAME);
export function fetchAllObjectTypes() {
  const quickFetchedRequest = quickFetch.getRequestStateByName(QUICK_FETCH_NAME);
  if (quickFetchedRequest) {
    return new Promise((resolve, reject) => {
      quickFetchedRequest.whenFinished(result => {
        removeRequest();
        resolve(fromJS(result));
      });
      quickFetchedRequest.onError(() => {
        removeRequest();
        fetchAllObjectTypes().then(resolve, reject);
      });
    });
  }
  return ImmutableAPI.get(`${BASE_URL}/object-types/for-portal`);
}