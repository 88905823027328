import { deleteEngagement, updateEngagement, deleteScheduledEmailEngagement
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/engagements/EngagementsActions';
import { fromJS } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { dispatchImmediate } from 'crm_data/dispatch/Dispatch';
import { ADD_ENGAGEMENT, CHANGE_FILTER, CLEAR_FAVORITE_EVENT_OVERRIDE, DELETE_TIMELINE_ENGAGEMENT, HOVER_ITEM, SET_FAVORITE_EVENT_OVERRIDE, TIMELINE_CHANGE_FILTERS_IN_STORE, TIMELINE_CHANGE_USER_AND_TEAM_FILTER, TIMELINE_CLEAR_FILTERS
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
import Raven from 'raven-js';
export default {
  clearFilters(objectType) {
    dispatchImmediate(TIMELINE_CLEAR_FILTERS, objectType);
  },
  overrideFavoriteEvent(objectType, eventTypes, requestPayload) {
    dispatchImmediate(SET_FAVORITE_EVENT_OVERRIDE, {
      objectType,
      eventTypes,
      requestPayload
    });
  },
  clearFavoriteEventOverride(objectType) {
    dispatchImmediate(CLEAR_FAVORITE_EVENT_OVERRIDE, {
      objectType
    });
  },
  addEngagement(engagement) {
    engagement = fromJS(engagement);
    dispatchImmediate(ADD_ENGAGEMENT, {
      engagement
    });
  },
  updateSelectedEventFilters({
    objectType,
    newEventTypes,
    requestPayload
  }) {
    dispatchImmediate(CHANGE_FILTER, {
      objectType,
      newEventTypes,
      requestPayload
    });
  },
  updateTimelineUserAndTeamFilters(objectType, requestPayload) {
    if (objectType && requestPayload) {
      dispatchImmediate(TIMELINE_CHANGE_USER_AND_TEAM_FILTER, {
        objectType,
        requestPayload
      });
    } else {
      Raven.captureMessage('updateTimelineUserAndTeamFilters called with missing objectType or requestPayload', {
        extra: {
          objectType,
          requestPayload
        }
      });
    }
  },
  changeTimelineFiltersInStore(objectType, newFilters) {
    dispatchImmediate(TIMELINE_CHANGE_FILTERS_IN_STORE, {
      objectType,
      newFilters
    });
  },
  deleteTimelineEngagement(engagement, onSuccess, onFailed) {
    const engagementId = engagement.getIn(['engagement', 'id']);
    const engagementType = engagement.getIn(['engagement', 'type']);
    return deleteEngagement(engagementId, engagementType).then(() => {
      if (onSuccess) {
        onSuccess();
      }
      return dispatchImmediate(DELETE_TIMELINE_ENGAGEMENT, {
        engagement
      });
    }).catch(onFailed);
  },
  deleteScheduledEmailEngagement(engagement, onSuccess, onFailed) {
    const metadata = engagement.getIn(['metadata']);
    const engagementId = engagement.getIn(['engagement', 'id']);
    const senderEmail = metadata.getIn(['sender', 'email']);
    const facsimileSendId = metadata.getIn(['facsimileSendId']);
    return deleteScheduledEmailEngagement(engagementId, senderEmail, facsimileSendId).then(() => {
      if (onSuccess) {
        onSuccess();
      }
      return dispatchImmediate(DELETE_TIMELINE_ENGAGEMENT, {
        engagement
      });
    }).catch(onFailed);
  },
  hoverTimelineItem(timelineItem) {
    dispatchImmediate(HOVER_ITEM, {
      timelineItem
    });
  },
  updateTimelineEngagement(engagementId, updates) {
    updateEngagement(engagementId, updates);
  }
};