import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import links from 'crm-legacy-links/links';
import { EMAIL } from 'crm-activity-creator-data/interaction/InteractionTypes';
import { hideCommunicator
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
} from 'customer-data-communicator/data/communicator/actions/CommunicatorActions';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { useQueryParams } from 'crm-activity-creator-interactions-legacy/router/useQueryParams';
const useUpdateUrl = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const updateUrl = useCallback(nextParams => {
    const currentQueryParams = links.getQueryParams();
    const params = Object.assign({}, currentQueryParams, queryParams, nextParams);
    history.push({
      pathname: location.pathname,
      search: links.toQueryString(params)
    });
  }, [queryParams, history, location.pathname]);
  const hasEmailContentInUrl = currentQueryParams => {
    return currentQueryParams && currentQueryParams.initialEmailSubject && currentQueryParams.initialEmailBody;
  };
  const handleActivityCreatorOpen = useCallback(payload => {
    const {
      interactionType
    } = payload;
    hideCommunicator();

    // If the interaction is email and the url contains initialEmailBody & initialEmailSubject on the query string
    // then we should preserve the email content. Currently this is needed for invoices, quotes, and payments.
    const currentQueryParams = links.getQueryParams();
    const params = interactionType === EMAIL && hasEmailContentInUrl(currentQueryParams) ? Object.assign({}, currentQueryParams) : {
      interaction: interactionType,
      initialEmailBody: null,
      initialEmailSubject: null
    };
    updateUrl(params);
  }, [updateUrl]);
  const handleActivityCreatorClose = useCallback(() => {
    updateUrl({
      interaction: null,
      initialEmailBody: null,
      initialEmailSubject: null
    });
  }, [updateUrl]);
  useOnCrmMessageTopic(TOPIC_NAMES.ACTIVITY_CREATOR_OPENED, handleActivityCreatorOpen);
  useOnCrmMessageTopic(TOPIC_NAMES.ACTIVITY_CREATOR_CLOSED, handleActivityCreatorClose);
};
export default useUpdateUrl;