'use es6';

import { dispatchQueue } from 'crm_data/dispatch/Dispatch';
import { OPEN_PLAYBOOK, CLOSE_PLAYBOOK } from 'crm_data/actions/ActionTypes';
export function openPlaybook(playbookId, saveNavigationHistory = false) {
  dispatchQueue(OPEN_PLAYBOOK, {
    playbookId,
    saveNavigationHistory
  });
}
export function closePlaybook() {
  dispatchQueue(CLOSE_PLAYBOOK);
}