import { fetchProductLevels } from '../api/iris';
import NetworkBinding from 'growth-payments-core/binding/networkBinding';
import { HubProducts, ProductLevel } from '../types/products';
import { createNetworkHook } from 'growth-payments-core/binding/useNetworkBinding';
import { getNetworkBindingData } from '../binding/getNetworkBindingData';
export const AllHubProducts = Object.values(HubProducts);
const productLevelOrder = [ProductLevel.NONE, ProductLevel.STARTER, ProductLevel.PRO, ProductLevel.ENTERPRISE];
export function calculateHighestLevelFromProducts(levels) {
  const highestIndex = Object.values(levels).reduce((highest, current) => {
    const index = productLevelOrder.indexOf(current);
    return Math.max(index, highest);
  }, -1);
  return highestIndex === -1 ? undefined : productLevelOrder[highestIndex];
}
export function fetchHighestProductLevel(products = AllHubProducts) {
  return fetchProductLevels(products).then(productLevels => {
    return calculateHighestLevelFromProducts(productLevels);
  });
}
export const HighestProductLevelBinding = new NetworkBinding(fetchHighestProductLevel);
export const useHighestProductLevel = createNetworkHook(HighestProductLevelBinding);
export const getHighestProductLevelFromBinding = () => getNetworkBindingData(HighestProductLevelBinding);