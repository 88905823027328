export const colorMap = {
  danger: 'text-alert-default',
  disabled: 'text-core-disabled',
  'extra-light': 'text-primary-subtle',
  light: 'text-primary-subtle',
  base: 'text-core-default',
  dark: 'text-core-default',
  inverse: 'text-primary-on-fill-default'
};

/**
 * heading-600: 32px
 * heading-500: 24px
 * heading-400: 22px
 * heading-300: 20px
 * heading-100: 16px
 * heading-50: 14px
 */
export const headingFontSizeMap = {
  xxs: 'heading-50',
  xs: 'heading-100',
  sm: 'heading-300',
  md: 'heading-400',
  lg: 'heading-500',
  xl: 'heading-600'
};

/**
 * body-100: 14px
 * detail-100: 12px
 * body-100: 14px
 * body-200: 16px
 */
export const bodyFontSizeMap = {
  base: 'body-100',
  sm: 'detail-100',
  md: 'body-100',
  lg: 'body-200'
};