'use es6';

import replaceSelectionWithText from 'draft-plugins/utils/replaceSelectionWithText';
import { replaceSelectionChangeTypes } from 'draft-plugins/lib/constants';
export default (({
  editorState,
  data,
  dataFind,
  entityType
}) => {
  const contentState = editorState.getCurrentContent();
  const blockArray = contentState.getBlocksAsArray();
  const ranges = [];
  blockArray.forEach(block => {
    block.findEntityRanges(char => {
      const entityKey = char.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === entityType;
    }, (start, end) => {
      ranges.push({
        block,
        start,
        end
      });
    });
  });
  return ranges.reduceRight((updatedEditorState, {
    block,
    start,
    end
  }) => {
    const text = block.getText();
    const originalString = text.slice(start, end);
    const entityKey = block.getEntityAt(start);
    const inlineStyles = block.getInlineStyleAt(start);
    const getEntityForUpdatedState = updatedEditorState.getCurrentContent().getEntity;
    const entity = getEntityForUpdatedState(entityKey);
    const option = dataFind(data, entity);
    if (option && option.text !== originalString) {
      return replaceSelectionWithText({
        start,
        end,
        entityKey,
        inlineStyles,
        blockKey: block.getKey(),
        editorState: updatedEditorState,
        text: option.text,
        changeType: replaceSelectionChangeTypes.SET
      });
    }
    return updatedEditorState;
  }, editorState);
});