'use es6';

import { Map as ImmutableMap } from 'immutable';
import QuoteRecord from 'customer-data-objects/quote/QuoteRecord';
import { send } from 'crm_data/api/ImmutableAPI';
import { QUOTES, CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
import { GET } from 'crm_data/constants/HTTPVerbs';
import partial from 'transmute/partial';
export const reviver = response => ImmutableMap(response).map(quote => QuoteRecord.fromJS(quote));
function baseConfig(type) {
  return {
    headers: {
      'X-Properties-Source': QUOTES,
      'X-Properties-SourceId': CRM_UI
    },
    type
  };
}
function make(type) {
  return partial(send, baseConfig(type));
}
export const get = make(GET);