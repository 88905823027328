'use es6';

export { ADVISOR_TO_COMPANY, BOARD_MEMBER_TO_COMPANY, CONTRACTOR_TO_COMPANY, MANAGER_TO_COMPANY, OWNER_TO_COMPANY, PARTNER_TO_COMPANY, RESELLER_TO_COMPANY } from 'customer-data-objects/contact/ContactRoleTypes';
export { COMPANY_TO_ADVISOR, COMPANY_TO_BOARD_MEMBER, COMPANY_TO_CONTRACTOR, COMPANY_TO_MANAGER, COMPANY_TO_OWNER, COMPANY_TO_PARTNER, COMPANY_TO_RESELLER } from 'customer-data-objects/company/CompanyRoleTypes';
export const DEAL_TO_LINE_ITEM = 'DEAL_TO_LINE_ITEM';
export const TICKET_TO_CONTACT = 'TICKET_TO_CONTACT';
export const TICKET_TO_COMPANY = 'TICKET_TO_COMPANY';
export const TICKET_TO_FEEDBACK_SUBMISSION = 'TICKET_TO_FEEDBACK_SUBMISSION';
export const CONTACT_TO_TICKET = 'CONTACT_TO_TICKET';
export const COMPANY_TO_TICKET = 'COMPANY_TO_TICKET';
export const CONTACT_TO_DEAL = 'CONTACT_TO_DEAL';
export const COMPANY_TO_DEAL = 'COMPANY_TO_DEAL';
export const DEAL_TO_COMPANY = 'DEAL_TO_COMPANY';
export const DEAL_TO_CONTACT = 'DEAL_TO_CONTACT';
export const TICKET_TO_DEAL = 'TICKET_TO_DEAL';
export const DEAL_TO_QUOTE = 'DEAL_TO_QUOTE';
export const DEAL_TO_TICKET = 'DEAL_TO_TICKET';
export const COMPANY_TO_CONTACT = 'COMPANY_TO_CONTACT';
export const COMPANY_TO_CONTACT_UNLABELED = 'COMPANY_TO_CONTACT_UNLABELED';
export const CONTACT_TO_COMPANY = 'CONTACT_TO_COMPANY';
export const CONTACT_TO_ENGAGEMENT = 'CONTACT_TO_ENGAGEMENT';
export const CONTACT_TO_FEEDBACK_SUBMISSION = 'CONTACT_TO_FEEDBACK_SUBMISSION';
export const ENGAGEMENT_TO_COMPANY = 'ENGAGEMENT_TO_COMPANY';
export const ENGAGEMENT_TO_CONTACT = 'ENGAGEMENT_TO_CONTACT';
export const ENGAGEMENT_TO_DEAL = 'ENGAGEMENT_TO_DEAL';
export const ENGAGEMENT_TO_TICKET = 'ENGAGEMENT_TO_TICKET';
export const FEEDBACK_SUBMISSION_TO_CONTACT = 'FEEDBACK_SUBMISSION_TO_CONTACT';
export const FEEDBACK_SUBMISSION_TO_TICKET = 'FEEDBACK_SUBMISSION_TO_TICKET';
export const CONVERSATION_TO_DEAL = 'CONVERSATION_TO_DEAL';
export const CONVERSATION_TO_TICKET = 'CONVERSATION_TO_TICKET';
export const CONTACT_TO_QUOTE = 'CONTACT_TO_QUOTE';
export const QUOTE_TO_CONTACT = 'QUOTE_TO_CONTACT';
export const QUOTE_TO_COMPANY = 'QUOTE_TO_COMPANY';
export const COMPANY_TO_QUOTE = 'COMPANY_TO_QUOTE';
export const PARTNER_CLIENT_TO_COMPANY = 'PARTNER_CLIENT_TO_COMPANY';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}