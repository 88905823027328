import { CONTACT, COMPANY, DEAL, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import { useEffect } from 'react';
const typeToClassMap = {
  [CONTACT]: 'contacts',
  [COMPANY]: 'companies',
  [DEAL]: 'deals',
  [TICKET]: 'tickets'
};
export function useBodyClass(objectType) {
  useEffect(() => {
    if (objectType && objectType in typeToClassMap) {
      document.body.classList.add(typeToClassMap[objectType]);
    }
  }, [objectType]);
}