import convertGoogleToHTML, { convertGoogleDocToHTML } from './convertGoogleToHTML';
import convertMSWordToHTML from './convertMSWordToHTML';
import convertEvernoteToHTML from './convertEvernoteToHTML';
import { getGenericPasteProcessingConverter } from './genericPasteProcessingConverter';
import { processEntities } from './processEntities';
export function compose(...fns) {
  return fns.reduceRight((prevFn, nextFn) => (...args) => nextFn(prevFn(...args)), value => value);
}
const DOCS_IMPORT_TEST = `<b id="google-docs-import">`;
const DOCS_TEST = new RegExp(`id="docs-internal-guid`);
const WORD_TEST = /<meta name=ProgId content=Word\.Document>/;
const EVERNOTE_TEST = /style=("|')-.[a-z]-clipboard:true;"/i;
const TINYMCE_TEST = `<meta source='HubSpot-TinyMCE'>`;
const UNKNOWN = 'unknown';
export const converterNames = {
  CONVERT_GOOGLE_IMPORT_TO_HTML: 'googleImport',
  CONVERT_GOOGLE_TO_HTML: 'google',
  CONVERT_MSWORD_TO_HTML: 'msword',
  CONVERT_EVERNOTE_TO_HTML: 'evernote',
  TINYMCE: 'tinymce'
};
const pasteSourceMap = [{
  test: DOCS_IMPORT_TEST,
  converter: convertGoogleDocToHTML,
  converterName: converterNames.CONVERT_GOOGLE_IMPORT_TO_HTML
}, {
  test: DOCS_TEST,
  converter: convertGoogleToHTML,
  converterName: converterNames.CONVERT_GOOGLE_TO_HTML
}, {
  test: WORD_TEST,
  converter: convertMSWordToHTML,
  converterName: converterNames.CONVERT_MSWORD_TO_HTML
}, {
  test: EVERNOTE_TEST,
  converter: convertEvernoteToHTML,
  converterName: converterNames.CONVERT_EVERNOTE_TO_HTML
}, {
  test: TINYMCE_TEST,
  converter: x => x,
  converterName: converterNames.TINYMCE
}];
export const getBaseConverterForHTML = html => {
  return pasteSourceMap.find(pasteSource => html.match(pasteSource.test)) || {
    converter: x => x,
    converterName: UNKNOWN
  };
};
export const getPastePreProcessWithConfig = ({
  formatTransformMap,
  clearAllStyles,
  defaultStylesMap,
  defaultAttributesMap
} = {}) => (html = '') => {
  const {
    converter,
    converterName
  } = getBaseConverterForHTML(html);

  // Add as many converters that take in one {html} arg as wanted
  const composedConverter = compose(processEntities, getGenericPasteProcessingConverter(formatTransformMap, clearAllStyles || converterName === UNKNOWN, defaultStylesMap, defaultAttributesMap), converter);
  return {
    converter: composedConverter,
    converterName
  };
};