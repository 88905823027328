import { CHICLET_LIST } from 'crm-cards-sdk/constants/LoadingComponentProps';
import { getSkeletonStateSafely } from './skeletonStateUtils';
export function getLoadingComponentProps(cardData, cardLocationContext) {
  var _localStorageSkeleton;
  const cardId = cardData.definition.cardId;
  const localStorageSkeletonState = getSkeletonStateSafely();
  const {
    objectTypeId,
    objectId
  } = cardLocationContext.additionalLocationData || {};
  const objectCoordinatesKey = `${objectTypeId}:${objectId}`;
  const skeletonState = (_localStorageSkeleton = localStorageSkeletonState.state[objectCoordinatesKey]) === null || _localStorageSkeleton === void 0 ? void 0 : _localStorageSkeleton[cardId];
  return [{
    type: CHICLET_LIST,
    numberOfSkeletons: (skeletonState === null || skeletonState === void 0 ? void 0 : skeletonState.value) || 1,
    numberOfProperties: cardData.configuration.propertyNames.length
  }];
}