import { useCallback } from 'react';
import { useTimelineDispatch, useTimelineSelector } from '../../reduxHooks';
import { getIsUserFilterOpen, openUserFilter as openUserFilterReducer, closeUserFilter as closeUserFilterReducer } from '../timelineControlsSlice';
export function useUserFilter() {
  const isUserFilterOpen = useTimelineSelector(getIsUserFilterOpen);
  const dispatch = useTimelineDispatch();
  const openUserFilter = useCallback(() => {
    dispatch(openUserFilterReducer());
  }, [dispatch]);
  const closeUserFilter = useCallback(() => {
    dispatch(closeUserFilterReducer());
  }, [dispatch]);
  return [isUserFilterOpen, openUserFilter, closeUserFilter];
}