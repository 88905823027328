'use es6';

import TicketRecord from 'customer-data-objects/ticket/TicketRecord';
import { byIdsStr } from './TicketsAPIQuery';
import userInfo from 'hub-http/userInfo';
import { PUT, POST } from 'crm_data/constants/HTTPVerbs';
import { CONTACTS, CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
import { TICKET } from 'customer-data-objects/constants/ObjectTypes';
import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
import { Map as ImmutableMap } from 'immutable';
const BASE_URI = 'services/v1/tickets';
const contactsCrmUiSourceHeaders = {
  'X-Properties-Source': CONTACTS,
  'X-Properties-SourceId': CRM_UI
};
const parseItems = result => {
  return result.reduce((acc, ticket, id) => acc.set(String(id), TicketRecord.fromJS(ticket)), ImmutableMap());
};
const toUri = queryStr => `${BASE_URI}/batch?${queryStr}&allPropertiesFetchMode=latest_version`;
export function fetch(ids) {
  return ImmutableAPI.get(`${toUri(byIdsStr(ids))}&flpViewValidation=true`).then(parseItems);
}
export function fetchById(id) {
  return ImmutableAPI.get(`${BASE_URI}/${id}`).then(TicketRecord.fromJS);
}
export function createTicket(objectPayload) {
  const payload = {
    object: objectPayload
  };
  return ImmutableAPI.send({
    headers: contactsCrmUiSourceHeaders,
    type: POST
  }, BASE_URI, payload, TicketRecord.fromJS);
}
export function createTicketWithAssociations({
  propertyValues,
  requestedAssociatedObjects
}) {
  return ImmutableAPI.send({
    headers: contactsCrmUiSourceHeaders,
    type: POST
  }, 'inbounddb-objects/v1/create-and-bulk-associate', {
    createObjectType: TICKET,
    propertyValues,
    requestedAssociatedObjects
  }, result => {
    return TicketRecord.fromJS(result);
  });
}
export function updateTicketPropertiesWithUser(user, ticket, propertyUpdates) {
  const ticketId = ticket.get('objectId');
  return ImmutableAPI.send({
    headers: contactsCrmUiSourceHeaders,
    type: PUT
  }, `${BASE_URI}/${ticketId}?allPropertiesFetchMode=latest_version`, {
    ticketId,
    properties: propertyUpdates.reduce((acc, value, name) => {
      acc.push({
        name,
        value,
        source: CRM_UI,
        sourceId: user.email
      });
      return acc;
    }, [])
  }, TicketRecord.fromJS);
}
export function updateTicketProperties(ticket, propertyUpdates) {
  return userInfo().then(({
    user
  }) => {
    return updateTicketPropertiesWithUser(user, ticket, propertyUpdates);
  });
}
export function deleteTicket(ticketId) {
  return ImmutableAPI.delete(`${BASE_URI}/${ticketId}`);
}