import { getUsersVidyardStatus } from './VidyardAPI';
import PortalIdParser from 'PortalIdParser';
//@ts-expect-error JS file
import { getDaysFromToday } from 'crm_ui/utils/dateHelpers';
const vidyardRevisitThresholdDays = 30;
export async function getShouldSeeVidyardShepherd() {
  const portalId = PortalIdParser.get();
  const response = await getUsersVidyardStatus();
  const attribute = response.find(a => a.portalId === portalId);
  const isFirstTimeVidyardUser = !(attribute && attribute.id);
  const userShouldRevisitVidyard = !isFirstTimeVidyardUser && Math.abs(getDaysFromToday(attribute.updatedAt)) > vidyardRevisitThresholdDays;
  return isFirstTimeVidyardUser || userShouldRevisitVidyard;
}