'use es6';

import { EMAIL_ACTIVITY, CALL_ACTIVITY, MEETING_ACTIVITY } from 'customer-data-objects/constants/ActivityTypes';
import { CALL, EMAIL, MEETING, NOTE, TASK } from 'customer-data-objects/engagement/EngagementTypes';
import { LOGGED_LINKED_IN_MESSAGE, LOGGED_MEETING, LOGGED_POSTAL_MAIL, LOGGED_SMS, LOGGED_WHATS_APP } from 'customer-data-objects/interaction/InteractionTypes';
import { compose } from 'draft-extend';
import { createPluginStack, createPluginStackWithAdapters } from 'draft-plugins/plugins/createPluginStack';
import CopyOverride from 'draft-plugins/plugins/CopyOverride';
import ImagePaste from 'draft-plugins/plugins/ImagePaste';
import InsertGroupPlugin from 'draft-plugins/plugins/insert/InsertGroupPlugin';
import createCommunicatorConfig from 'draft-content-plugins/plugins/insertGroup/createCommunicatorConfig';
import { Map as ImmutableMap, Seq } from 'immutable';
import invariant from 'react-utils/invariant';
import get from 'transmute/get';
import { createPlugins, PLUGINS, withDefaultOptions } from './PluginsConfig';
import RichTextEditorConfiguration from './RichTextEditorConfiguration';
import { hasContent } from './RichTextEditorContent';
const DEFAULT = 'default';
const UNKNOWN_CONFIG_ERROR = 'Unknown RichTextEditor config type %s';
const INIT_ERROR = 'RichTextEditorDefaultConfigurations not initialized';
const DEFAULT_PLUGIN_COMPOSERS = Seq.of(CopyOverride, ImagePaste);
const DEFAULT_RTE_CONFIG_OPTIONS = {
  hasContent,
  pluginComposers: DEFAULT_PLUGIN_COMPOSERS
};
let CONFIG = ImmutableMap({
  [DEFAULT]: null,
  [TASK]: null,
  [MEETING]: null,
  [LOGGED_MEETING]: null,
  [CALL]: null,
  [EMAIL]: null,
  [NOTE]: null,
  [EMAIL_ACTIVITY]: null,
  [CALL_ACTIVITY]: null,
  [MEETING_ACTIVITY]: null,
  [LOGGED_LINKED_IN_MESSAGE]: null,
  [LOGGED_POSTAL_MAIL]: null,
  [LOGGED_SMS]: null,
  [LOGGED_WHATS_APP]: null
});
const init = _opts => {
  const opts = withDefaultOptions(_opts);
  const {
    plugins,
    pluginConfig,
    commonPluginsGroup
  } = createPlugins(opts);
  const {
    hasVideo,
    usePluginStack = false,
    hasPayments = false,
    hasInvoices = false
  } = opts;
  const filterEnabled = (...selection) => Seq(selection).filter(e => !opts[e] || get('enabled', opts[e]) !== false).map(e => plugins[e]);
  const defaultConfiguration = new RichTextEditorConfiguration({
    type: DEFAULT,
    plugins: filterEnabled(PLUGINS.atMention),
    pluginComposers: DEFAULT_PLUGIN_COMPOSERS,
    hasContent
  }).configureWith(compose);
  const meetingRteConfiguration = new RichTextEditorConfiguration({
    type: MEETING,
    plugins: Seq.of(...filterEnabled(PLUGINS.atMention, PLUGINS.inlineStyles, PLUGINS.inlineStylesOverride, PLUGINS.link, PLUGINS.snippets), commonPluginsGroup),
    pluginComposers: DEFAULT_PLUGIN_COMPOSERS,
    hasContent
  }).configureWith(compose);

  // logged meetings have an image plugin, but regular meetings don't
  const loggedMeetingRteConfiguration = new RichTextEditorConfiguration({
    type: MEETING,
    plugins: Seq.of(...filterEnabled(PLUGINS.atMention, PLUGINS.inlineStyles, PLUGINS.inlineStylesOverride, PLUGINS.link, PLUGINS.image, PLUGINS.snippets), commonPluginsGroup),
    pluginComposers: DEFAULT_PLUGIN_COMPOSERS,
    hasContent
  }).configureWith(compose);
  const loggedCommunicationRteConfiguration = new RichTextEditorConfiguration({
    type: '',
    plugins: Seq.of(...filterEnabled(PLUGINS.atMention, PLUGINS.inlineStyles, PLUGINS.inlineStylesOverride, PLUGINS.link, PLUGINS.image, PLUGINS.snippets), commonPluginsGroup),
    pluginComposers: DEFAULT_PLUGIN_COMPOSERS,
    hasContent
  }).configureWith(compose);
  const loggedPostalMailRteConfiguration = new RichTextEditorConfiguration({
    type: LOGGED_POSTAL_MAIL,
    plugins: Seq.of(...filterEnabled(PLUGINS.atMention, PLUGINS.inlineStyles, PLUGINS.inlineStylesOverride, PLUGINS.link, PLUGINS.image, PLUGINS.snippets), commonPluginsGroup),
    pluginComposers: DEFAULT_PLUGIN_COMPOSERS,
    hasContent
  }).configureWith(compose);
  const taskRteConfiguration = new RichTextEditorConfiguration({
    type: TASK,
    plugins: Seq.of(...filterEnabled(PLUGINS.atMention, PLUGINS.inlineStyles, PLUGINS.inlineStylesOverride, PLUGINS.image, PLUGINS.link, PLUGINS.snippets), commonPluginsGroup),
    pluginComposers: DEFAULT_PLUGIN_COMPOSERS,
    hasContent
  }).configureWith(compose);
  const callRteConfiguration = new RichTextEditorConfiguration({
    type: CALL,
    plugins: Seq.of(...filterEnabled(PLUGINS.atMention, PLUGINS.inlineStyles, PLUGINS.inlineStylesOverride), commonPluginsGroup, ...filterEnabled(PLUGINS.separator, PLUGINS.link, PLUGINS.image, PLUGINS.snippets)),
    pluginComposers: DEFAULT_PLUGIN_COMPOSERS,
    hasContent
  }).configureWith(compose);
  const emailPlugins = Seq.of(...filterEnabled(PLUGINS.inlineStyles, PLUGINS.inlineStylesOverride), commonPluginsGroup, ...filterEnabled(PLUGINS.separator, PLUGINS.link, PLUGINS.image, PLUGINS.unstyledAsDiv, PLUGINS.documentLinkPreview));
  const emailRteConfigOptions = {
    type: EMAIL,
    plugins: emailPlugins,
    pluginComposers: DEFAULT_PLUGIN_COMPOSERS,
    hasContent
  };
  const emailPluginGroupConfig = {
    knowledgeArticles: Object.assign({}, pluginConfig.knowledgeArticles, {
      trackClick: pluginConfig.knowledgeArticles.onOpenPopover
    }),
    snippets: Object.assign({}, pluginConfig.snippets, {
      trackClick: pluginConfig.snippets.onOpenPopover
    }),
    meetings: {}
  };
  if (hasPayments) {
    emailPluginGroupConfig.paymentLinks = Object.assign({}, pluginConfig.paymentLinks, {
      trackClick: pluginConfig.paymentLinks.onOpenPopover
    });
  }
  if (hasInvoices) {
    emailPluginGroupConfig.invoices = Object.assign({}, pluginConfig.invoices, {
      trackClick: pluginConfig.invoices.onOpenPopover
    });
  }
  if (hasVideo) {
    // deeply nested data for the loss
    const trackClick = pluginConfig.video && pluginConfig.video.handleVideoTracking && pluginConfig.video.handleVideoTracking.openVideo;
    emailPluginGroupConfig.video = Object.assign({}, pluginConfig.video, {
      useInsertPopover: true,
      trackClick
    });
  }
  emailRteConfigOptions.pluginGroupConfig = emailPluginGroupConfig;
  let emailComposer = compose;
  let emailAdapter;
  if (usePluginStack) {
    emailComposer = createPluginStack;
    emailAdapter = createPluginStackWithAdapters;
  }
  const emailRteConfiguration = new RichTextEditorConfiguration(emailRteConfigOptions).configureWith(emailComposer, emailAdapter);
  const activityRteConfiguration = new RichTextEditorConfiguration({
    plugins: Seq.of(...filterEnabled(PLUGINS.atMention, PLUGINS.inlineStyles, PLUGINS.inlineStylesOverride), commonPluginsGroup, ...filterEnabled(PLUGINS.link, PLUGINS.image, PLUGINS.snippets, PLUGINS.knowledgeArticles)),
    pluginComposers: DEFAULT_PLUGIN_COMPOSERS,
    hasContent
  }).configureWith(compose);
  const noteRteConfiguration = new RichTextEditorConfiguration({
    type: NOTE,
    plugins: Seq.of(...filterEnabled(PLUGINS.atMention, PLUGINS.inlineStyles, PLUGINS.inlineStylesOverride), commonPluginsGroup, ...filterEnabled(PLUGINS.separator, PLUGINS.link, PLUGINS.image, PLUGINS.knowledgeArticles, PLUGINS.snippets)),
    pluginComposers: DEFAULT_PLUGIN_COMPOSERS,
    hasContent
  }).configureWith(compose);
  CONFIG = ImmutableMap({
    [DEFAULT]: defaultConfiguration,
    [TASK]: taskRteConfiguration,
    [MEETING]: meetingRteConfiguration,
    [LOGGED_MEETING]: loggedMeetingRteConfiguration,
    [CALL]: callRteConfiguration,
    [EMAIL]: emailRteConfiguration,
    [NOTE]: noteRteConfiguration,
    [EMAIL_ACTIVITY]: activityRteConfiguration.set('type', EMAIL_ACTIVITY),
    [CALL_ACTIVITY]: activityRteConfiguration.set('type', CALL_ACTIVITY),
    [MEETING_ACTIVITY]: activityRteConfiguration.set('type', MEETING_ACTIVITY),
    [LOGGED_LINKED_IN_MESSAGE]: loggedCommunicationRteConfiguration.set('type', LOGGED_LINKED_IN_MESSAGE),
    [LOGGED_POSTAL_MAIL]: loggedPostalMailRteConfiguration,
    [LOGGED_SMS]: loggedCommunicationRteConfiguration.set('type', LOGGED_SMS),
    [LOGGED_WHATS_APP]: loggedCommunicationRteConfiguration.set('type', LOGGED_WHATS_APP)
  });
};

// Options
//   atMentions:
//     useInviteZeroState
//     inviteZeroStateOptions
export function initializeRteConfig(opts) {
  init(opts);
}
export function getRteConfig(type = DEFAULT) {
  invariant(CONFIG.has(type), UNKNOWN_CONFIG_ERROR, type);
  const config = CONFIG.get(type);
  invariant(config, INIT_ERROR);
  return config;
}
export function createRteConfig(type, plugins = Seq(), pluginComposers = Seq(), pluginGroupConfig = ImmutableMap(), usePluginStack = false) {
  let config = CONFIG.get(type);
  invariant(config !== null, INIT_ERROR); // undefined is valid
  if (!config) {
    const options = Object.assign({
      type
    }, DEFAULT_RTE_CONFIG_OPTIONS);
    config = new RichTextEditorConfiguration(options);
  }
  plugins = Seq(plugins);
  pluginComposers = Seq(pluginComposers);
  pluginGroupConfig = pluginGroupConfig.merge(config.pluginGroupConfig);
  if (!pluginGroupConfig.isEmpty()) {
    const insertPlugin = InsertGroupPlugin(createCommunicatorConfig(pluginGroupConfig.toJS()));
    plugins = plugins.concat([insertPlugin]);
  }
  let composer = compose;
  let adapter;
  if (usePluginStack && type === EMAIL) {
    composer = createPluginStack;
    adapter = createPluginStackWithAdapters;
  }
  if (!plugins.isEmpty() || !pluginComposers.isEmpty()) {
    config = config.enhanceWith(Date.now(), plugins, pluginComposers).configureWith(composer, adapter);
  }
  return config;
}