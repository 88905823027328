import Loadable from 'UIComponents/decorators/Loadable';
import Raven from 'raven-js';
const MiniProfileHighlightContainerAsync = Loadable({
  loader: () => import( /* webpackChunkName: "MiniProfileHighlightContainer" */
  /* webpackPreload: true */
  './MiniProfileHighlightContainer'),
  LoadingComponent: () => null,
  ErrorComponent: ({
    error
  }) => {
    Raven.captureException(error);
    console.error(error);
    return null;
  }
});
export default MiniProfileHighlightContainerAsync;