'use es6';

import { get, put } from 'crm_data/api/ImmutableAPI';
import { Map as ImmutableMap } from 'immutable';
import uniqueId from 'transmute/uniqueId';
const URI = 'engagements/v1/activity-types';
export function fetch() {
  return get(URI).then(results => {
    return results.map(activityType => {
      return activityType.set('id', uniqueId('activityType-'));
    });
  });
}
export function update(expectedCurrentNameOrder, newNameOrder) {
  return put(URI, ImmutableMap({
    expectedCurrentNameOrder,
    newNameOrder
  }));
}