import { useState, useLayoutEffect, useCallback } from 'react';
export const useRefWidth = nodeRef => {
  const [width, setWidth] = useState(0);
  const {
    current
  } = nodeRef;
  const updateWidth = useCallback(() => current && setWidth(current.offsetWidth), [current]);
  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, [updateWidth]);
  return width;
};