'use es6';

import { Map as ImmutableMap, List, Seq, Set as ImmutableSet } from 'immutable';
import pluck from 'transmute/pluck';
import some from 'transmute/some';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { GMAIL, OUTLOOK365, IMAP } from 'customer-data-email/schema/connectedAccount/EmailIntegrationTypes';
import { getProperty, getId } from 'customer-data-objects/record/ObjectRecordAccessors';
import EmailRecord from 'customer-data-email/schema/email/EmailRecord';
import HubSpotHostedEmailRecord from 'customer-data-email/schema/email/HubSpotHostedEmailRecord';
import EmailAddressRecord from 'customer-data-email/schema/email/EmailAddressRecord';
import Sender from 'customer-data-email/schema/email/Sender';
import Recipient from 'customer-data-email/schema/email/Recipient';
import InteractionRecord from 'customer-data-objects/interaction/InteractionRecord';
import identity from 'transmute/identity';
import getIn from 'transmute/getIn';
import AliasAddress from '../connectedAccount/AliasAddress';
import { logError } from 'customer-data-ui-utilities/eventLogging/eventLogger';
const CONTACT_EMAIL_PROPS = Seq(['firstname', 'lastname', 'email', 'hs_additional_emails']);
const pluckTo = pluck('to');
const associationNameMappings = {
  contactIds: 'CONTACT',
  companyIds: 'COMPANY',
  dealIds: 'DEAL',
  ticketIds: 'TICKET'
};
const parseOwnerId = id => {
  if (typeof id !== 'string' || id.indexOf('owner.') !== 0) {
    return false;
  }
  return id.split('.')[1];
};
const createOwnerRecipientRecord = owner => {
  const ownerEmail = owner.email;
  return new Recipient({
    to: new EmailAddressRecord({
      id: owner.ownerId,
      firstName: owner.firstName,
      lastName: owner.lastName,
      address: ownerEmail
    }),
    emailAddresses: ImmutableSet([ownerEmail])
  });
};
const createSenderEmailRecord = (user, connectedAccounts, objectType, isUngatedForDefaultSendFromGate = false) => {
  const userEmailAddress = user.get('email').toLowerCase();
  const primarySendFromEmailAddress = isUngatedForDefaultSendFromGate && connectedAccounts.defaultSendFrom ? connectedAccounts.defaultSendFrom : userEmailAddress;
  const defaultSenderEmailAddressId = connectedAccounts.getDefaultIntegratedAliasAddress({
    userEmailAddress: primarySendFromEmailAddress,
    objectType,
    isUngatedForDefaultSendFromGate,
    hasDefaultSendFromEmail: !!connectedAccounts.defaultSendFrom
  });
  const connectedAccount = connectedAccounts.getAccount(defaultSenderEmailAddressId, false);
  if (!connectedAccount) {
    return new EmailAddressRecord({
      id: user.get('user_id')
    });
  }
  let emailAlias;
  let aliasAddress;
  try {
    aliasAddress = AliasAddress.toAliasAddress(defaultSenderEmailAddressId);
    emailAlias = connectedAccount.getAliasWithAddress(aliasAddress);
  } catch (e) {
    logError({
      error: new Error('Could not find Alias in ConnectedAccount record'),
      extraData: {
        aliasAddress,
        connectedAccount,
        connectedAccounts,
        defaultSenderEmailAddressId
      }
    });
  }
  let addressInfo = {};
  if (emailAlias) {
    addressInfo = {
      addressId: defaultSenderEmailAddressId,
      address: aliasAddress,
      firstName: user.get('first_name'),
      lastName: user.get('last_name'),
      friendlyName: getIn(['friendlyFromName'], connectedAccount),
      sendAsAddress: getIn(['sendFromEmail'], connectedAccount),
      resolvedFromName: getIn(['resolvedFromName'], connectedAccount)
    };
  }
  return new EmailAddressRecord(Object.assign({
    id: user.get('user_id')
  }, addressInfo));
};
export function createRecipientRecordFromEmailAddressRecord(emailAddressRecord) {
  if (!emailAddressRecord) {
    return new Recipient();
  }
  return new Recipient({
    to: emailAddressRecord,
    emailAddresses: ImmutableSet([emailAddressRecord.address])
  });
}
const createSenderFromUser = (user, connectedAccounts, objectType, isUngatedForDefaultSendFromGate = false) => {
  const userEmailAddress = user.get('email').toLowerCase();
  return new Sender({
    from: createSenderEmailRecord(user, connectedAccounts, objectType, isUngatedForDefaultSendFromGate),
    signature: connectedAccounts.getPrimarySignature(userEmailAddress),
    aliasEmailAddresses: connectedAccounts.getFacsimileInboxAliases(userEmailAddress),
    hasPrimaryAccount: connectedAccounts.hasPrimaryAccount(userEmailAddress),
    hasEnabledAccounts: connectedAccounts.isIntegrated(),
    hasGoogleIntegration: connectedAccounts.hasIntegration(GMAIL),
    hasOffice365Integration: connectedAccounts.hasIntegration(OUTLOOK365),
    hasImapIntegration: connectedAccounts.hasIntegration(IMAP)
  });
};
const formatFile = file => {
  return ImmutableMap({
    fileId: file.get('id')
  });
};
export function isOwner(id) {
  return !!parseOwnerId(id);
}
export function toContactInfo(id) {
  if (typeof id !== 'string' || id.indexOf(CONTACT) !== 0) {
    return {
      id
    };
  }
  const idParts = id.split(' ');
  return {
    id: idParts[1],
    emailAddress: idParts[2]
  };
}
export function toContactId(contactId, emailAddress) {
  return `${CONTACT} ${contactId} ${emailAddress}`;
}
export function toOwnerContactId(contactId) {
  return `owner.${contactId}`;
}
export function extractContactEmailAddresses(contactRecord) {
  const primaryEmailAddress = getProperty(contactRecord, 'email');
  const emailAddresses = ImmutableSet([primaryEmailAddress]);
  const additionalEmailAddresses = getProperty(contactRecord, 'hs_additional_emails');
  if (!additionalEmailAddresses) {
    return emailAddresses;
  }
  return emailAddresses.concat(additionalEmailAddresses.split(';'));
}
export function contactEmailChanged(contactRecord, newContactRecord) {
  const changed = key => {
    return getProperty(contactRecord, key) !== getProperty(newContactRecord, key);
  };
  return some(changed, CONTACT_EMAIL_PROPS);
}
export function createSenderRecord(user, connectedAccounts, objectType, isUngatedForDefaultSendFromGate = false) {
  return createSenderFromUser(user, connectedAccounts, objectType, isUngatedForDefaultSendFromGate);
}
export function createEmailAddressRecordFromContactRecord(contactRecord, emailAddress) {
  if (!emailAddress) {
    emailAddress = getProperty(contactRecord, 'email');
  }
  return new EmailAddressRecord({
    id: getId(contactRecord),
    firstName: getProperty(contactRecord, 'firstname'),
    lastName: getProperty(contactRecord, 'lastname'),
    address: emailAddress
  });
}
export function createContactRecipientRecord(contactRecord, emailAddress) {
  return new Recipient({
    to: createEmailAddressRecordFromContactRecord(contactRecord, emailAddress),
    emailAddresses: extractContactEmailAddresses(contactRecord)
  });
}
export function createTransientRecipientRecord(address) {
  return new Recipient({
    to: new EmailAddressRecord({
      address
    }),
    emailAddresses: ImmutableSet([address])
  });
}

/*
 * Creates a recipient record
 * using is either a Recipient, EmailAddressRecord
 * in case of transient record (e.g. searching/adding a contact)
 *    using = ("CONTACT 152 hsingh@hubspot.com" | "email@email.com")
 */
export function createRecipientRecord(contacts, owners, using) {
  if (using && using instanceof Recipient) {
    return using;
  }
  if (!using || using instanceof EmailAddressRecord) {
    return createRecipientRecordFromEmailAddressRecord(using);
  }
  const id = using;
  const ownerId = parseOwnerId(id);
  if (ownerId) {
    return createOwnerRecipientRecord(owners.get(ownerId));
  }
  const contactInfo = toContactInfo(id);
  const contact = contacts.get(contactInfo.id);
  if (contact) {
    return createContactRecipientRecord(contact, contactInfo.emailAddress);
  }
  const email = contactInfo.id && contactInfo.id.toLowerCase();
  return createTransientRecipientRecord(email);
}
export function toEmailInteractionRecord(interactionRecord, emailAttrs, associations) {
  const {
    subject,
    recipient,
    to,
    ccs,
    bccs,
    from,
    templateId,
    hasEmailBrandingBanner,
    replyEngagementId,
    replyEngagementType,
    shouldLogToCrm,
    isForward
  } = emailAttrs;
  const metadata = interactionRecord.metadata.merge({
    subject,
    to: recipient ? List([recipient.to]) : pluckTo(to),
    ccs: pluckTo(ccs),
    bccs: pluckTo(bccs),
    from,
    templateId,
    hasEmailBrandingBanner,
    replyEngagementId,
    replyEngagementType,
    shouldLogToCrm: shouldLogToCrm === undefined ? true : shouldLogToCrm,
    isForward
  });
  return interactionRecord.mergeIn(['associations'], associations).set('metadata', metadata);
}
export function getAllRecipients(emailAttrs) {
  const {
    recipient,
    to,
    bccs,
    ccs
  } = emailAttrs;
  const emails = recipient ? Seq([recipient]) : to;
  return emails.concat(ccs, bccs).toList().filter(recipientRecord => {
    return identity(recipientRecord.to.address);
  });
}
export function getAllAssociations(emailAttrs) {
  const recipients = getAllRecipients(emailAttrs);
  // Get the addresses in the lists with contact record.
  return recipients.map(recipient => recipient.to.id).filter(contactId => typeof contactId === 'number');
}
export function getAllRecipientAddresses(emailAttrs) {
  const recipients = getAllRecipients(emailAttrs);
  return recipients.map(recipient => recipient.get('to').get('address'));
}
export function getIntegrations(sender) {
  let emailIntegrations = List();
  if (sender.hasGoogleIntegration) {
    emailIntegrations = emailIntegrations.push(GMAIL);
  }
  if (sender.hasOffice365Integration) {
    emailIntegrations = emailIntegrations.push(OUTLOOK365);
  }
  if (sender.hasImapIntegration) {
    emailIntegrations = emailIntegrations.push(IMAP);
  }
  return emailIntegrations;
}
const getUniversalAssociations = associations => {
  const universalAssociations = associations.get('engagementsV2UniversalAssociations');
  if (!universalAssociations || universalAssociations.size === 0) {
    return null;
  }
  let formattedAssociations = List([]);
  universalAssociations.forEach((associationInfo, objectTypeId) => {
    const objectIds = associationInfo.get('associationOptionRecordsMap').keySeq();
    objectIds.forEach(objectId => {
      formattedAssociations = formattedAssociations.push(ImmutableMap({
        objectId,
        objectTypeId
      }));
    });
  });
  return formattedAssociations;
};
function extractEmailRecordData({
  interactionRecord,
  attachments,
  connectedAccounts,
  nameFormatter,
  trackerKey,
  legalBases
}) {
  const {
    metadata,
    associations
  } = interactionRecord;
  const from = metadata.get('from');
  const isForward = metadata.get('isForward');
  let to = metadata.get('to');
  if (!List.isList(to)) {
    to = new List([to]);
  }
  const emailRecord = {
    associations: getUniversalAssociations(associations),
    subject: metadata.get('subject'),
    to: to.map(ea => ea.formatted(nameFormatter)),
    cc: metadata.get('ccs').map(ea => ea.formatted(nameFormatter)),
    bcc: metadata.get('bccs').map(ea => ea.formatted(nameFormatter)),
    from: ImmutableMap({
      name: from.getFriendlyName(nameFormatter),
      email: from.getSendAsAddress()
    }),
    sender: ImmutableMap({
      name: from.getFriendlyName(nameFormatter),
      email: connectedAccounts.getAccountEmailAddress(from.addressId)
    }),
    html: metadata.get('body'),
    plainText: metadata.get('plainText'),
    templateId: metadata.get('templateId'),
    attachments: attachments.map(formatFile),
    shouldLogToCrm: metadata.get('shouldLogToCrm'),
    trackerKey,
    legalBases,
    isForward
  };
  return emailRecord;
}
export function createEmail({
  interactionRecord,
  attachments,
  connectedAccounts,
  nameFormatter,
  trackerKey,
  legalBases
}) {
  const emailRecord = extractEmailRecordData({
    interactionRecord,
    attachments,
    connectedAccounts,
    nameFormatter,
    trackerKey,
    legalBases
  });
  return new EmailRecord(emailRecord);
}
export function createHubSpotHostedEmail({
  interactionRecord,
  attachments,
  connectedAccounts,
  nameFormatter,
  replyToEngagementId,
  legalBases
}) {
  const {
    associations
  } = interactionRecord;
  const emailRecord = extractEmailRecordData({
    interactionRecord,
    attachments,
    connectedAccounts,
    nameFormatter,
    legalBases
  });
  let {
    associations: associationsList
  } = emailRecord;
  if (!associationsList && associations && associations.size) {
    associationsList = List([]);
    associations.forEach((ids, name) => {
      if (ids && ids.size && associationNameMappings[name]) {
        ids.forEach(id => {
          if (typeof id === 'number') {
            associationsList = associationsList.push(ImmutableMap({
              id,
              type: associationNameMappings[name]
            }));
          }
        });
      }
    });
  }
  const attachmentList = pluck('id')(attachments);
  const record = Object.assign({}, emailRecord, {
    connectedAccountAddress: emailRecord.sender.get('email'),
    associations: associationsList,
    attachments: attachmentList,
    replyToEngagementId
  });
  return new HubSpotHostedEmailRecord(record);
}
function createEmailAddressRecordsFromContactRecords(contacts, emails) {
  if (!emails) {
    return List();
  }
  contacts = List.isList(contacts) ? contacts : List([contacts]);
  emails = List.isList(emails) ? emails : List([emails]);
  const recipientRecords = emails.map(email => {
    const emailAddress = email.get('email');
    const contact = contacts.find(c => {
      const extractedEmails = extractContactEmailAddresses(c);
      return extractedEmails.includes(emailAddress) || extractedEmails.includes(emailAddress && emailAddress.toLowerCase());
    });
    return contact ? createEmailAddressRecordFromContactRecord(contact, emailAddress) : new EmailAddressRecord({
      address: emailAddress,
      firstName: email.get('firstName'),
      lastName: email.get('lastName')
    });
  });
  return recipientRecords.filter(record => !!record);
}
function isIncomingOrForwardedEmail(engagement) {
  const engagementType = engagement && engagement.getIn(['engagement', 'type']);
  return engagementType === 'INCOMING_EMAIL' || engagementType === 'FORWARDED_EMAIL';
}
function getToAddressList(fromContact, toContacts, engagement, isReplyingToAll, fromAddress) {
  const isIncomingEmail = isIncomingOrForwardedEmail(engagement);
  let toAddressRecords = List();
  const address = engagement.getIn(['metadata', 'from']);
  toAddressRecords = toAddressRecords.concat(createEmailAddressRecordsFromContactRecords(fromContact, address));
  if (isReplyingToAll || !isIncomingEmail) {
    const emails = engagement.getIn(['metadata', 'to']);
    toAddressRecords = toAddressRecords.concat(createEmailAddressRecordsFromContactRecords(toContacts, emails));
  }
  if (fromAddress) {
    const fromEmailAddress = fromAddress.get('address');
    const sendAsAddress = fromAddress.get('sendAsAddress');
    toAddressRecords = toAddressRecords.filter(addressRecord => {
      let toAddr = addressRecord.get('address');
      toAddr = toAddr && toAddr.toLowerCase();
      return toAddr !== fromEmailAddress && toAddr !== sendAsAddress;
    });
  }

  // De-dupe the toAddressList
  toAddressRecords = toAddressRecords.toSet().toList();
  return toAddressRecords;
}
export function getFromAddress(engagement, connectedAccounts, currentUserEmail) {
  const isIncomingEmail = isIncomingOrForwardedEmail(engagement);
  if (!isIncomingEmail) {
    const address = engagement.getIn(['metadata', 'from', 'email']);

    // Find a fromAddress from original fromAddress in engagement or using the logged in user
    let fromAddress = address && connectedAccounts.getFromEmailAddress(new EmailAddressRecord({
      address
    }));
    if (!fromAddress && currentUserEmail) {
      fromAddress = connectedAccounts.getFromEmailAddress(new EmailAddressRecord({
        address: currentUserEmail
      }));
    }
    if (!fromAddress) {
      // If from address can't be constructed using from address or logged in user
      // select the default integrated alias
      const defaultAliasAddressId = connectedAccounts.getDefaultIntegratedAliasAddress({});
      fromAddress = defaultAliasAddressId && defaultAliasAddressId.split(' ').length === 2 && connectedAccounts.getFromEmailAddress(new EmailAddressRecord({
        address: AliasAddress.toAliasAddress(defaultAliasAddressId)
      }));
    }
    return fromAddress || connectedAccounts.getFromEmailAddress(new EmailAddressRecord({
      address: currentUserEmail
    }), {
      skipPermissionsVerification: true
    });
  }
  const toEmails = engagement.getIn(['metadata', 'to']).concat(engagement.getIn(['metadata', 'cc']) || List()).concat(engagement.getIn(['metadata', 'bcc']) || List());
  const forwardingAddress = engagement.getIn(['metadata', 'sender']);
  const toEmailsWithForwardingAddress = forwardingAddress ? toEmails.push(forwardingAddress) : toEmails;

  // Identify the first connected account in the to list as the from address.
  const toEmailAddresses = toEmailsWithForwardingAddress.map(email => ImmutableMap({
    address: email.get('email')
  }));
  return connectedAccounts.getFromEmailAddress(toEmailAddresses);
}
export function getForwardInteractionRecord({
  engagement,
  connectedAccounts,
  currentUserEmail
}) {
  let updatedEngagement = engagement.setIn(['associations', 'contactIds'], ImmutableSet());

  // Setup From Address -
  const fromAddress = getFromAddress(engagement, connectedAccounts, currentUserEmail);
  updatedEngagement = updatedEngagement.setIn(['metadata', 'from'], fromAddress);
  updatedEngagement = updatedEngagement.setIn(['metadata', 'to'], List());
  updatedEngagement = updatedEngagement.setIn(['metadata', 'isForward'], true);
  return new InteractionRecord(updatedEngagement);
}

/*
 * There are four cases that we need to handle { EMAIL, INCOMING_EMAIL, FORWARDED_EMAIL } x {  reply, replyToAll }
 *  EMAIL.reply - Maintain to all contacts in the to field
 *  EMAIL.replyToAll  - Reply to all contacts in the to/cc fields
 *  INCOMING_EMAIL.reply - Reply to the contact in the from field
 *  INCOMING_EMAIL.replyToAll - Reply to all contacts in the to+from and cc fields
 *  Note that FORWARDED_EMAIL is essentially an INCOMING_EMAIL that reached CRM through different means
 * An edge case for the INCOMING_EMAIL with many non-contacts emails, for now we'd ignore those
 * emails due to GDPR/etc.
 * Removing the ownership check, we are not sure why this was needed
 */
export function getReplyInteractionRecord({
  engagement,
  isReplyingToAll,
  ccContacts,
  toContacts,
  fromContact,
  connectedAccounts,
  currentUserEmail
}) {
  let updatedEngagement = engagement.setIn(['associations', 'contactIds'], ImmutableSet());

  // Setup From Address -
  const fromAddress = getFromAddress(engagement, connectedAccounts, currentUserEmail);
  updatedEngagement = updatedEngagement.setIn(['metadata', 'from'], fromAddress);

  // For all replies, update the to field
  const toList = getToAddressList(fromContact, toContacts, engagement, isReplyingToAll, fromAddress);
  updatedEngagement = updatedEngagement.setIn(['metadata', 'to'], toList);

  // For reply all, include the cc'd contact as well
  const cc = isReplyingToAll && engagement.getIn(['metadata', 'cc']);
  if (cc) {
    let allToEmails = ImmutableSet();
    toList.forEach(to => {
      allToEmails = allToEmails.add(to.get('address'));
    });

    // Filter out any cc'd email address that is also in to list
    const emails = cc.filter(ccd => !allToEmails.includes(ccd.get('email')));
    const ccdRecipients = createEmailAddressRecordsFromContactRecords(ccContacts, emails);
    updatedEngagement = updatedEngagement.setIn(['metadata', 'ccs'], List(ccdRecipients));
  }
  return new InteractionRecord(updatedEngagement);
}