import { useIsScoped, useIsUngated } from 'crm-events-data';
import { CALL, EMAIL, MEETING, NOTE, TASK } from 'customer-data-objects/engagement/EngagementTypes';
import { NOT_SPECIFIED } from 'customer-data-objects/property/AccessLevel';
import { useAccessLevels } from 'customer-data-properties/accessLevel/AccessLevelContext';
import { gate } from 'hub-http/gates';
const SHAREABLE_ENGAGEMENT_TYPES = [NOTE, CALL, EMAIL, MEETING, TASK];
const SHARED_USERS_PROPERTY = 'hs_shared_user_ids';
const SHARED_TEAMS_PROPERTY = 'hs_shared_team_ids';
const useShouldRenderShareButton = (canEdit, engagementType) => {
  const isScoped = useIsScoped();
  const isUngated = useIsUngated();
  const accessLevels = useAccessLevels({
    objectType: engagementType || '',
    propertyNames: [SHARED_USERS_PROPERTY, SHARED_TEAMS_PROPERTY]
  });
  if (!canEdit || !engagementType) {
    return false;
  }
  const isUngatedToActivitySharing = isUngated(gate('Permissions:ActivitySharing'));
  const hasAccessToRecordSharing = isScoped('crm-record-sharing-access');
  const engagementTypeIsShareable = engagementType && SHAREABLE_ENGAGEMENT_TYPES.includes(engagementType);
  const hasAccessToActivitySharing = hasAccessToRecordSharing && isUngatedToActivitySharing && engagementTypeIsShareable;
  const canEditSharedUsers = accessLevels[SHARED_USERS_PROPERTY] === NOT_SPECIFIED;
  const canEditSharedTeams = accessLevels[SHARED_TEAMS_PROPERTY] === NOT_SPECIFIED;
  const canEditAllProperties = canEditSharedUsers && canEditSharedTeams;
  return hasAccessToActivitySharing && canEditAllProperties;
};
export default useShouldRenderShareButton;