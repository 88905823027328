'use es6';

import { ENGAGEMENTS_ATTACHMENTS_UPDATED, ENGAGEMENTS_UPDATED } from 'crm_data/actions/ActionTypes';
import { dispatchImmediate } from 'crm_data/dispatch/Dispatch';
import * as EngagementsV2API from 'crm_data/engagements/api/EngagementsV2API';
import { engagementFromInboundDbObject } from 'crm_data/engagements/inboundDbProperties/engagementInboundDbObjectHelpers';
import { transact } from 'crm_data/flux/transact';
import EngagementRecord from 'customer-data-objects/engagement/EngagementRecord';
import { Map as ImmutableMap } from 'immutable';
import { engagementRecordInvariant } from './EngagementInvariants';
export function updateEngagement(originalEngagement, updatedEngagement, updates) {
  const engagementId = updatedEngagement.getIn(['engagement', 'id']);
  const engagementType = updatedEngagement.getIn(['engagement', 'type']);
  return transact({
    operation() {
      return EngagementsV2API.updateEngagement(engagementType, engagementId, updates);
    },
    commit: [ENGAGEMENTS_UPDATED, ImmutableMap().set(`${engagementId}`, updatedEngagement)],
    rollback: [ENGAGEMENTS_UPDATED, ImmutableMap().set(`${engagementId}`, originalEngagement)]
  });
}
export function updateEngagementsInStore(engagements) {
  dispatchImmediate(ENGAGEMENTS_UPDATED, engagements);
}
export function updateEngagementInStore(engagement) {
  const engagementId = engagement.getIn(['engagement', 'id']);
  dispatchImmediate(ENGAGEMENTS_UPDATED, ImmutableMap().set(engagementId, engagement));
}
export function updateV2EngagementInStore(inboundDbEngagement) {
  const v1Engagement = EngagementRecord(engagementFromInboundDbObject(inboundDbEngagement));
  updateEngagementInStore(v1Engagement);
}
export function createEngagementV2(engagement, portalSpecificObjectsToAssociate) {
  engagementRecordInvariant(engagement);
  return EngagementsV2API.createEngagementV2(engagement, portalSpecificObjectsToAssociate);
}
export function updateEngagementAttachments(originalEngagement, updatedEngagement, updates) {
  const engagementId = updatedEngagement.getIn(['engagement', 'id']);
  const engagementType = updatedEngagement.getIn(['engagement', 'type']);
  return transact({
    operation() {
      return EngagementsV2API.updateEngagement(engagementType, engagementId, updates);
    },
    commit: [ENGAGEMENTS_ATTACHMENTS_UPDATED, ImmutableMap().set(`${engagementId}`, updatedEngagement)],
    rollback: [ENGAGEMENTS_ATTACHMENTS_UPDATED, ImmutableMap().set(`${engagementId}`, originalEngagement)]
  });
}