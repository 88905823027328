import { createTracker } from 'usage-tracker';
import events from 'growth-payments-core/events.yaml';
import initDefaultTrackingProperties from './defaultProperties';
const usageTracker = createTracker({
  events
});

// used to fire events before page unload
// See https://product.hubteam.com/docs/usage-tracking-manual/docs/frontend/external-link-click-tracking.html#content
export const beaconTracker = usageTracker.clone({
  isBeforeUnload: true
});
export function initGPCDefaultTrackingProperties() {
  initDefaultTrackingProperties(usageTracker, beaconTracker);
}
export default usageTracker;