'use es6';

const hasSupportedContent = block => block.getLength() > 0 || block.getType().indexOf('list-item') > -1 || block.getType() === 'atomic' && ['IMAGE', 'VIDEO', 'MEETING_TIMES'].includes(block.getData().get('atomicType'));
export function hasContent(editorState) {
  return editorState.getCurrentContent().getBlockMap().some(hasSupportedContent);
}
export function atMentionHtmlToToken(nodeName, node) {
  if (nodeName === 'span' && node.hasAttribute('data-at-mention')) {
    return {
      value: parseInt(node.getAttribute('data-owner-id'), 10),
      text: node.textContent
    };
  } else if (nodeName === 'span' && node.hasAttribute('data-mention-id')) {
    return {
      value: parseInt(node.getAttribute('data-mention-id'), 10),
      text: node.getAttribute('data-mention-name')
    };
  }
  return null;
}

// Note, this data attribute format is shared with mobile.
export function atMentionTokenToHtml({
  value,
  text
}) {
  return `<span data-at-mention data-token data-owner-id=${value}>${text}</span>`;
}