export let EligibilityStatus;
(function (EligibilityStatus) {
  EligibilityStatus["ELIGIBLE"] = "ELIGIBLE";
  EligibilityStatus["INELIGIBLE"] = "INELIGIBLE";
  EligibilityStatus["ACCOUNT_EXISTS"] = "ACCOUNT_EXISTS";
})(EligibilityStatus || (EligibilityStatus = {}));
export let UnderwritingStatus;
(function (UnderwritingStatus) {
  UnderwritingStatus["IN_KYC"] = "IN_KYC";
  UnderwritingStatus["IN_UNDERWRITING"] = "IN_UNDERWRITING";
  UnderwritingStatus["APPROVED"] = "APPROVED";
  UnderwritingStatus["REJECTED"] = "REJECTED";
})(UnderwritingStatus || (UnderwritingStatus = {}));
export let ProcessorStatus;
(function (ProcessorStatus) {
  ProcessorStatus["SETUP_REQUIRED"] = "SETUP_REQUIRED";
  ProcessorStatus["ENABLED"] = "ENABLED";
  ProcessorStatus["DISABLED"] = "DISABLED";
  ProcessorStatus["BLOCKED"] = "BLOCKED";
})(ProcessorStatus || (ProcessorStatus = {}));
export let ProcessorType;

/**
 *  Here is an explanation of what different connectionTypes mean and at what point is the connectionType value set.
 *  This value is set when a Payment account is created for the portal.
 *
 *  NONE - Initial value. No payment account has been created for portal.
 *  STRIPE_CUSTOM_CONNECT - User started onboarding with HSP, processorType is set to HS_PAYMENTS, uwStatus is set to IN_KYC
 *  OAUTH - User has FINISHED Stripe OAUTH onboarding, processorType is set to BYO_STRIPE, uwStatus is set to IN_UNDERWRITING/APPROVED
 */
(function (ProcessorType) {
  ProcessorType["HS_PAYMENTS"] = "HS_PAYMENTS";
  ProcessorType["BYO_STRIPE"] = "BYO_STRIPE";
  ProcessorType["NONE"] = "NONE";
})(ProcessorType || (ProcessorType = {}));
export let ConnectionType;
(function (ConnectionType) {
  ConnectionType["OAUTH"] = "OAUTH";
  ConnectionType["STRIPE_CUSTOM_CONNECT"] = "STRIPE_CUSTOM_CONNECT";
  ConnectionType["NONE"] = "NONE";
})(ConnectionType || (ConnectionType = {}));