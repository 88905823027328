'use es6';

import { get } from 'crm_data/api/ImmutableAPI';
import { List } from 'immutable';
import ConnectedAccounts from 'customer-data-email/schema/connectedAccount/ConnectedAccounts';
import { INACTIVE, DISABLED } from 'customer-data-email/schema/email/EmailDisabledTypes';
import EmailAlias from 'customer-data-email/schema/connectedAccount/EmailAlias';
import EmailAliasList from 'customer-data-email/schema/connectedAccount/EmailAliasList';
import EmailIntegration from 'customer-data-email/schema/connectedAccount/EmailIntegration';
import ConnectedAccount from 'customer-data-email/schema/connectedAccount/ConnectedAccount';
import { getEmailSettings } from 'customer-data-email/data/defaultSendFrom/clients';
import userInfo from 'hub-http/userInfo';
const URLS = {
  connectedAccounts: 'connectedaccounts/v1/accounts/connected-inboxes',
  emailSignature: 'userpreferences/v1/signature'
};
function getEmailAliasList(inbox) {
  const aliases = inbox.get('aliases');
  const aliasState = inbox.get('aliasState');
  let inboxStatus = inbox.get('inboxStatus');
  if (inboxStatus === INACTIVE && !inbox.get('shared')) {
    inboxStatus = DISABLED;
  }
  let aliasList = List();
  if (aliasState === 'ENABLED') {
    aliasList = aliases.map(alias => {
      const fields = {
        address: alias.get('email'),
        displayableAddress: alias.get('sendFromEmail') || alias.get('email'),
        primary: alias.get('primary'),
        signature: alias.get('signature'),
        default: alias.get('default'),
        type: alias.get('type'),
        disabled: inboxStatus === 'ENABLED' ? null : inboxStatus,
        inboxId: alias.get('conversationsInboxId'),
        conversationsInboxName: alias.get('conversationsInboxName'),
        conversationsConnectedAccountId: alias.get('conversationsConnectedAccountId')
      };
      return EmailAlias(fields);
    });
  }
  return EmailAliasList({
    aliases: List(aliasList),
    state: aliasState
  });
}
function getPortalEmailSignature() {
  return get(URLS.emailSignature, {
    clienttimeout: 60000
  });
}
function getDisplayNames(primaryAlias) {
  const names = {
    friendlyFromName: null,
    resolvedFromName: null,
    sendFromEmail: null
  };
  if (primaryAlias) {
    names.friendlyFromName = primaryAlias.get('friendlyFromName');
    names.resolvedFromName = primaryAlias.get('resolvedFromName');
    names.sendFromEmail = primaryAlias.get('sendFromEmail');
  }
  return names;
}
export function getConnectedAccounts(loggedInUserEmailAddress, defaultSendFromEmailAddress) {
  return get(URLS.connectedAccounts, {
    clienttimeout: 60000
  }).then(response => {
    const inboxes = response.get('connectedInboxes').sortBy(inbox => {
      return inbox.get('inboxStatus') === 'ENABLED' ? 0 : 1;
    });
    let accounts = inboxes.map(inbox => {
      const accountId = inbox.get('accountId');
      const aliases = inbox.get('aliases');
      const primaryAlias = aliases && aliases.find(alias => alias.get('primary'));
      const fields = Object.assign({
        address: accountId && accountId.toLowerCase(),
        facsimileInbox: inbox.get('facsimileInboxState'),
        integration: new EmailIntegration({
          type: inbox.get('emailProviderType'),
          state: inbox.get('crmEmailIntegrationState')
        }),
        aliasList: getEmailAliasList(inbox),
        shared: inbox.get('shared'),
        inboxType: inbox.get('emailProviderType')
      }, getDisplayNames(primaryAlias));
      return new ConnectedAccount(fields);
    });
    const ca = new ConnectedAccounts({
      accounts,
      defaultSendFrom: defaultSendFromEmailAddress
    });
    const hasOwnerAccount = ca.hasPrimaryAccount(loggedInUserEmailAddress);
    if (!hasOwnerAccount) {
      const aliasFields = {
        address: loggedInUserEmailAddress,
        displayableAddress: loggedInUserEmailAddress,
        signature: null,
        primary: true,
        default: true,
        type: 'owner'
      };
      return getPortalEmailSignature().then(sig => {
        aliasFields.signature = sig.get('signature');
        const fields = {
          address: loggedInUserEmailAddress,
          aliasList: EmailAliasList({
            aliases: List([EmailAlias(aliasFields)]),
            state: 'ENABLED'
          })
        };
        accounts = accounts.concat([new ConnectedAccount(fields)]);
        return new ConnectedAccounts({
          accounts,
          defaultSendFrom: defaultSendFromEmailAddress
        });
      }).catch(() => {
        const fields = {
          address: loggedInUserEmailAddress,
          aliasList: EmailAliasList({
            aliases: List([EmailAlias(aliasFields)]),
            state: 'ENABLED'
          })
        };
        accounts = accounts.concat([new ConnectedAccount(fields)]);
        return new ConnectedAccounts({
          accounts,
          defaultSendFrom: defaultSendFromEmailAddress
        });
      });
    }
    return ca;
  });
}

/*
 * HubSpot CRM and Conversations have their own set of connected accounts.  The emails sent through
 * conversations create engagements that appear on the contact's CRM profile page.  A CRM user can
 * respond to those email engagements as any other using a CRM or a Conversations email account as
 * sender. The CRM's connected account API retuns a UNION of CRM's and Conversations' connected
 * accounts, but Conversations' connected account have permissions and additional
 * fields (sendAsAddress, friendlyFromName, displayAddress, etc.). The fetchAndHydrateConnectedAccounts function
 * standardizes the connected account list for the communicator.
 */
function fetchAndHydrateConnectedAccountsWithUser(user, defaultSendFromEmailAddress) {
  const userHasScope = user.scopes.includes('email-clients-access');
  if (!userHasScope) {
    return Promise.resolve(new ConnectedAccounts());
  }
  const userEmail = user.email.toLowerCase();
  return getConnectedAccounts(userEmail, defaultSendFromEmailAddress);
}
export function fetchAndHydrateConnectedAccounts() {
  return Promise.all([userInfo(), getEmailSettings()]).then(([{
    user
  }, {
    defaultSendFrom
  }]) => {
    return fetchAndHydrateConnectedAccountsWithUser(user, defaultSendFrom);
  });
}