import { CHICLET_LIST, TEXT_LIST } from 'crm-cards-sdk/constants/LoadingComponentProps';
import callSummaryCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-call-summaries-lib/lang/en.lyaml';
import aiObjectSummaryCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-object-summary-lib/lang/en.lyaml';
import crmAssociationPivotTranslations from 'i2l?mode=very-lazy&query=sporks!crm-associations-pivot/lang/en.lyaml';
import crmSimpleDeadlineTranslations from 'i2l?mode=very-lazy&query=sporks!crm-simple-deadline/lang/en.lyaml';
import crmStageTrackerTranslations from 'i2l?mode=very-lazy&query=sporks!crm-stage-tracker/lang/en.lyaml';
import crmAssociationTableTranslations from 'i2l?mode=very-lazy&query=sporks!crm-associations-table/lang/en.lyaml';
import crmDataHighlightsTranslations from 'i2l?mode=very-lazy&query=sporks!crm-data-highlights/lang/en.lyaml';
import crmEventsVizTimelineCardTranslations from 'i2l?mode=very-lazy&query=sporks!crm-events-viz-cards/lang/crmEventsVizTimelineCard/en.lyaml';
import crmAllboundTimelineCardTranslations from 'i2l?mode=very-lazy&query=sporks!crm-events-viz-cards/lang/crmAllboundTimelineCard/en.lyaml';
import crmActivitiesV2CardTranslations from 'i2l?mode=very-lazy&query=sporks!crm-events-viz-cards/lang/crmActivitiesV2Card/en.lyaml';
import crmExtensionTranslations from 'i2l?mode=very-lazy&query=sporks!crm-extension/lang/en.lyaml';
import crmPropertiesListTranslations from 'i2l?mode=very-lazy&query=sporks!crm-properties-list/lang/en.lyaml';
import crmReportCardTranslations from 'i2l?mode=very-lazy&query=sporks!crm-report/lang/en.lyaml';
import leadQualificationCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-lead-qualification-lib/lang/en.lyaml';
import unifiedUsageScoreCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-partner-client-unified-usage-score-lib/lang/en.lyaml';
import LegacySidebarCard from 'legacy-sidebar-card-lib/LegacySidebarCard';
import legacySidebarCardTranslations from 'i2l?mode=very-lazy&query=sporks!legacy-sidebar-card-lib/lang/en.lyaml';
import { getLoadingComponentProps as getLoadingComponentPropsForLegacySidebarCard } from 'legacy-sidebar-card-lib/getLoadingComponentProps';
import advancedPlaybooksCardTranslations from 'i2l?mode=very-lazy&query=sporks!playbooks-card/lang/en.lyaml';
import purchaseHistoryCardsTranslations from 'i2l?mode=very-lazy&query=sporks!purchase-history-lib/lang/en.lyaml';
import aboutThisRecordCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-properties-lib/lang/en.lyaml';
import guidedActionsCardTranslations from 'i2l?mode=very-lazy&query=sporks!guided-actions-card/lang/en.lyaml';
import scoreSummaryCardTranslations from 'i2l?mode=very-lazy&query=sporks!predictive-scores-card/lang/en.lyaml';
import paymentScheduleCardTranslations from 'i2l?mode=very-lazy&query=sporks!payment-schedule-card-lib/lang/en.lyaml';
import subscriptionSidebarCardTranslations from 'i2l?mode=very-lazy&query=sporks!commerce-subscription-cards-lib/lang/en.lyaml';
import activityTotalsCardTranslations from 'i2l?mode=very-lazy&query=sporks!crm-events-viz-totals/lang/en.lyaml';
import associatedObjectsCardSdkCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-associated-objects-lib/lang/en.lyaml';
import docusignCrmCardTranslations from 'i2l?mode=very-lazy&query=sporks!docusign-cards-lib/lang/en.lyaml';
import adobeSignCrmCardTranslations from 'i2l?mode=very-lazy&query=sporks!adobe-sign-cards-lib/lang/en.lyaml';
import pipelineApprovalsCardTranslations from 'i2l?mode=very-lazy&query=sporks!crm-pipeline-approvals-lib/lang/en.lyaml';
import invoicesHistoryCardTranslations from 'i2l?mode=very-lazy&query=sporks!invoices-history-card-lib/lang/en.lyaml';
import consentsCardTranslations from 'i2l?mode=very-lazy&query=sporks!ui-consent-crm-card/lang/en.lyaml';
import datawellReportCardTranslations from 'i2l?mode=very-lazy&query=sporks!datawell-report-crm-card/lang/en.lyaml';
import customerSpendTrendsCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-customer-spend-trends-lib/lang/en.lyaml';
import crmStatisticsTranslations from 'i2l?mode=very-lazy&query=sporks!crm-statistics/lang/en.lyaml';
import companyResearchInsightsCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-company-research-insights-lib/lang/en.lyaml';
import helpDeskInsightsCardsTranslations from 'i2l?mode=very-lazy&query=sporks!help-desk-insights-lib-crm-cards/lang/en.lyaml';
import companySummaryCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-company-summary-lib/lang/en.lyaml';
import otherTicketsCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-other-tickets-lib/lang/en.lyaml';
import ticketSummaryCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-ticket-summary-lib/lang/en.lyaml';
import tasksTimelineCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-tasks-timeline-lib/lang/en.lyaml';
import healthScoreCardTranslations from 'i2l?mode=very-lazy&query=sporks!health-score-card-lib/lang/en.lyaml';
import knowledgeBaseCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-knowledge-base-lib/lang/en.lyaml';
import leadScoresCardTranslations from 'i2l?mode=very-lazy&query=sporks!lead-scores-card/lang/en.lyaml';
import portalUsageOutcomesCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-partner-client-portal-usage-outcomes-lib/lang/en.lyaml';
import guidedSellingDealSummaryTranslations from 'i2l?mode=very-lazy&query=sporks!deal-summary-components/lang/en.lyaml';
import buyerIntentTranslations from 'i2l?mode=very-lazy&query=sporks!buyer-intent-card/lang/en.lyaml';
import intelligenceOverviewTranslations from 'i2l?mode=very-lazy&query=sporks!intelligence-overview-lib/lang/en.lyaml';
import crmActionsTranslations from 'i2l?mode=very-lazy&query=sporks!crm-actions/lang/en.lyaml';
import crmRelativeTimelineTranslations from 'i2l?mode=very-lazy&query=sporks!crm-relative-timeline/lang/en.lyaml';
import customerSuccessContactsCardTranslations from 'i2l?mode=very-lazy&query=sporks!customer-success-contacts-card-lib/lang/en.lyaml';
import crmPropertyHistoryTranslations from 'i2l?mode=very-lazy&query=sporks!crm-property-history/lang/en.lyaml';
import flywheelCompanyOverviewCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-flywheel-company-overview-lib/lang/en.lyaml';
import flywheelHighIntentAssociatedContactsCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-flywheel-high-intent-associated-contacts-lib/lang/en.lyaml';
import workflowsInvokerCardTranslations from 'i2l?mode=very-lazy&query=sporks!automation-ui-crm-card-workflows-invoker/lang/en.lyaml';
import processGuideCardTranslations from 'i2l?mode=very-lazy&query=sporks!process-guide-cards/lang/en.lyaml';
import attachmentsCardTranslations from 'i2l?mode=very-lazy&query=sporks!card-engagement-attachments-lib/lang/en.lyaml';
export const CRM_CARDS_GLOBAL_REGISTRY_MAP = {
  ACTIVITIES: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmRecentActivitiesCard" */'crm-events-viz-cards/crmRecentActivitiesCard/CrmRecentActivitiesCard'),
    lazyLoadedTranslations: crmEventsVizTimelineCardTranslations
  },
  ACTIVITIES_V2: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmActivitiesV2Card" */'crm-events-viz-cards/crmActivitiesV2Card/CrmActivitiesV2Card'),
    lazyLoadedTranslations: crmActivitiesV2CardTranslations
  },
  ACTIVITY_TOTALS: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmEventsVizTotalsCard" */'crm-events-viz-totals/CrmEventsVizTotalsCard'),
    lazyLoadedTranslations: activityTotalsCardTranslations
  },
  AI_OBJECT_SUMMARY: {
    cardComponentLoader: () => import( /* webpackChunkName: "AIObjectSummaryCard" */'card-object-summary-lib/components/AIObjectSummaryCard'),
    lazyLoadedTranslations: aiObjectSummaryCardTranslations
  },
  ASSOCIATION_PIVOT: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmAssociationsPivotCard" */'crm-associations-pivot/CrmAssociationsPivotCard'),
    lazyLoadedTranslations: crmAssociationPivotTranslations
  },
  ASSOCIATION_PROPERTIES_LIST: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmAssociationPropertyListCard" */'crm-properties-list/CrmAssociationPropertyListCard'),
    lazyLoadedTranslations: crmPropertiesListTranslations
  },
  ASSOCIATION_STAGE_TRACKER: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmAssociationStageTrackerCard" */'crm-stage-tracker/CrmAssociationStageTrackerCard'),
    lazyLoadedTranslations: crmStageTrackerTranslations
  },
  ADVANCED_PLAYBOOKS: {
    cardComponentLoader: () => import( /* webpackChunkName: "AdvancedPlaybooksCard" */'playbooks-card/AdvancedPlaybooksCard'),
    lazyLoadedTranslations: advancedPlaybooksCardTranslations
  },
  ASSOCIATION_TABLE: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmAssociationsTableCard" */'crm-associations-table/CrmAssociationsTableCard'),
    lazyLoadedTranslations: crmAssociationTableTranslations
  },
  ASSOCIATION_V3: {
    cardComponentLoader: () => import( /* webpackChunkName: "AssociatedObjectsCardV3" */'card-associated-objects-lib/sdk'),
    lazyLoadedTranslations: associatedObjectsCardSdkCardTranslations,
    getLoadingComponentProps: cardData => {
      return [{
        type: CHICLET_LIST,
        numberOfSkeletons: 1,
        numberOfProperties: cardData.configuration.propertyNames.length || 2
      }];
    }
  },
  ATTACHMENTS: {
    cardComponentLoader: () => import( /* webpackChunkName: "EngagementAttachmentsCard" */'card-engagement-attachments-lib/EngagementAttachmentsCardSdk'),
    lazyLoadedTranslations: attachmentsCardTranslations
  },
  BUYER_INTENT: {
    cardComponentLoader: () => import( /* webpackChunkName: "BuyerIntentCard" */'buyer-intent-card'),
    lazyLoadedTranslations: buyerIntentTranslations
  },
  CALL_SUMMARIES: {
    cardComponentLoader: () => import( /* webpackChunkName: "CallSummariesCard" */'card-call-summaries-lib/CallSummariesCard'),
    lazyLoadedTranslations: callSummaryCardTranslations
  },
  COMPANY_RESEARCH_INSIGHTS: {
    cardComponentLoader: () => import( /* webpackChunkName: "CompanyResearchInsightsCard" */'card-company-research-insights-lib/CompanyResearchInsightsCard'),
    lazyLoadedTranslations: companyResearchInsightsCardTranslations
  },
  COMPANY_SUMMARY: {
    cardComponentLoader: () => import( /* webpackChunkName: "CompanySummaryCard" */'card-company-summary-lib/CompanySummaryCard'),
    lazyLoadedTranslations: companySummaryCardTranslations
  },
  CONSENTS: {
    cardComponentLoader: () => import( /* webpackChunkName: "SdkConsentsCard" */'ui-consent-crm-card/components/SdkConsentsCard'),
    lazyLoadedTranslations: consentsCardTranslations
  },
  CRM_EVENTS_VIZ_TIMELINE: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmEventsVizTimelineCard" */'crm-events-viz-cards/crmEventsVizTimelineCard/CrmEventsVizTimelineCard').then(mod => mod.CrmEventsVizTimelineCard),
    lazyLoadedTranslations: crmEventsVizTimelineCardTranslations
  },
  ALLBOUND_TIMELINE: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmAllboundTimelineCard" */'crm-events-viz-cards/crmAllboundTimelineCard/CrmAllboundTimelineCard').then(mod => mod.CrmAllboundTimelineCard),
    lazyLoadedTranslations: crmAllboundTimelineCardTranslations
  },
  CRM_REPORT: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmReportCard" */'crm-report/CrmReportCard'),
    lazyLoadedTranslations: crmReportCardTranslations
  },
  CUSTOMER_SPEND_TRENDS: {
    cardComponentLoader: () => import( /* webpackChunkName: "CustomerSpendTrendsCard" */'card-customer-spend-trends-lib/CustomerSpendTrendsCard'),
    lazyLoadedTranslations: customerSpendTrendsCardTranslations
  },
  CUSTOMER_SUCCESS_CONTACTS: {
    cardComponentLoader: () => import( /* webpackChunkName: "CustomerSuccessContactsCard" */'customer-success-contacts-card-lib/CustomerSuccessContactsCard'),
    lazyLoadedTranslations: customerSuccessContactsCardTranslations
  },
  CUSTOMER_SUCCESS_HEALTH_SCORE: {
    cardComponentLoader: () => import( /* webpackChunkName: "HealthScoreCard" */'health-score-card-lib/HealthScoreCard'),
    lazyLoadedTranslations: healthScoreCardTranslations
  },
  DATA_HIGHLIGHTS: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmDataHighlightsCard" */'crm-data-highlights/CrmDataHighlightsCard'),
    lazyLoadedTranslations: crmDataHighlightsTranslations
  },
  DATAWELL_REPORT: {
    cardComponentLoader: () => import( /* webpackChunkName: "DatawellCard" */'datawell-report-crm-card/Card'),
    lazyLoadedTranslations: datawellReportCardTranslations
  },
  DEAL_SCORE_SUMMARY: {
    cardComponentLoader: () => import( /* webpackChunkName: "ScoreSummaryCard" */'predictive-scores-card/ScoreSummaryCard'),
    lazyLoadedTranslations: scoreSummaryCardTranslations
  },
  DEAL_SCORE_WITHOUT_HISTORY: {
    cardComponentLoader: () => import( /* webpackChunkName: "ScoreWithoutHistoryCard" */'predictive-scores-card/ScoreWithoutHistoryCard'),
    lazyLoadedTranslations: scoreSummaryCardTranslations
  },
  DEAL_SCORE_SUMMARY_PREVIEW: {
    cardComponentLoader: () => import( /* webpackChunkName: "ScoreSummaryPreviewCard" */'predictive-scores-card/ScoreSummaryPreviewCard'),
    lazyLoadedTranslations: scoreSummaryCardTranslations
  },
  ADOBE_SIGN: {
    cardComponentLoader: async () => {
      const {
        AdobeSignCrmCard
      } = await import( /* webpackChunkName: "AdobeSignCrmCard" */'adobe-sign-cards-lib');
      return AdobeSignCrmCard;
    },
    lazyLoadedTranslations: adobeSignCrmCardTranslations
  },
  DOCUSIGN_V2: {
    cardComponentLoader: async () => {
      const {
        DocusignCrmCard
      } = await import( /* webpackChunkName: "docusign" */'docusign-cards-lib');
      return DocusignCrmCard;
    },
    lazyLoadedTranslations: docusignCrmCardTranslations
  },
  EXTENSION: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmExtensionCard" */'crm-extension/CrmExtensionCard'),
    lazyLoadedTranslations: crmExtensionTranslations
  },
  FLYWHEEL_COMPANY_OVERVIEW: {
    cardComponentLoader: () => import( /* webpackChunkName: "CompanyOverviewCard" */'card-flywheel-company-overview-lib/CompanyOverviewCard'),
    lazyLoadedTranslations: flywheelCompanyOverviewCardTranslations
  },
  FLYWHEEL_HIGH_INTENT_ASSOCIATED_CONTACTS: {
    cardComponentLoader: () => import( /* webpackChunkName: "AssociatedContactsCard" */'card-flywheel-high-intent-associated-contacts-lib/AssociatedContactsCard'),
    lazyLoadedTranslations: flywheelHighIntentAssociatedContactsCardTranslations
  },
  GUIDED_ACTIONS: {
    cardComponentLoader: () => import( /* webpackChunkName: "GuidedActionsCard" */'guided-actions-card/GuidedActionsCard'),
    lazyLoadedTranslations: guidedActionsCardTranslations
  },
  HELP_DESK_TICKET_SUMMARY: {
    cardComponentLoader: () => import('card-ticket-summary-lib/TicketSummaryCard'),
    lazyLoadedTranslations: ticketSummaryCardTranslations
  },
  HELP_DESK_KNOWLEDGE_BASE: {
    cardComponentLoader: () => import('card-knowledge-base-lib/KnowledgeBaseCard'),
    lazyLoadedTranslations: knowledgeBaseCardTranslations
  },
  INTELLIGENCE_CONTENT: {
    cardComponentLoader: () => import( /* webpackChunkName: "IntelligenceOverviewCard" */'intelligence-overview-card'),
    lazyLoadedTranslations: intelligenceOverviewTranslations
  },
  LEAD_QUALIFICATION: {
    cardComponentLoader: () => import( /* webpackChunkName: "LeadQualificationCard" */'card-lead-qualification-lib/LeadQualificationCard'),
    lazyLoadedTranslations: leadQualificationCardTranslations
  },
  LEGACY_SIDEBAR_CARD: {
    CardComponent: LegacySidebarCard,
    lazyLoadedTranslations: legacySidebarCardTranslations,
    getLoadingComponentProps: getLoadingComponentPropsForLegacySidebarCard
  },
  MARKETING_LEAD_SCORES: {
    cardComponentLoader: () => import( /* webpackChunkName: "LeadScoresCard" */'lead-scores-card/LeadScoresCard'),
    lazyLoadedTranslations: leadScoresCardTranslations
  },
  OTHER_TICKETS: {
    cardComponentLoader: () => import('card-other-tickets-lib/other-tickets/OtherTicketsCard'),
    lazyLoadedTranslations: otherTicketsCardTranslations
  },
  PAYMENT_SCHEDULE: {
    cardComponentLoader: () => import( /* webpackChunkName: "payment-schedule-card-lib" */'payment-schedule-card-lib').then(mod => mod.PaymentScheduleCard),
    lazyLoadedTranslations: paymentScheduleCardTranslations
  },
  PINNED_ACTIVITY: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmPinnedActivityCard" */'crm-events-viz-cards/crmPinnedActivityCard/CrmPinnedActivityCard'),
    lazyLoadedTranslations: crmEventsVizTimelineCardTranslations
  },
  PIPELINE_APPROVALS: {
    cardComponentLoader: () => import( /* webpackChunkName: "crm-pipeline-approvals-lib" */'crm-pipeline-approvals-lib').then(mod => mod.PipelineApprovalsCard),
    lazyLoadedTranslations: pipelineApprovalsCardTranslations
  },
  PROCESS_GUIDE_LIST: {
    cardComponentLoader: () => import( /* webpackChunkName: "ProcessGuideListCard" */'process-guide-cards/cards/ProcessGuideListCard'),
    lazyLoadedTranslations: processGuideCardTranslations
  },
  PRODUCT_HISTORY: {
    cardComponentLoader: () => import( /* webpackChunkName: "purchase-history-lib" */'purchase-history-lib').then(mod => mod.ProductHistoryCard),
    lazyLoadedTranslations: purchaseHistoryCardsTranslations
  },
  PROPERTIES_LIST: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmPropertiesListCard" */'crm-properties-list/CrmPropertiesListCard'),
    lazyLoadedTranslations: crmPropertiesListTranslations
  },
  PROPERTIES_V3: {
    cardComponentLoader: () => import( /* webpackChunkName: "PropertiesV3" */'card-properties-lib/sdk'),
    lazyLoadedTranslations: aboutThisRecordCardTranslations,
    getLoadingComponentProps: cardData => {
      return [{
        type: TEXT_LIST,
        count: cardData.configuration.propertyNames.length
      }];
    }
  },
  PROPERTY_HISTORY: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmPropertiesHistoryCard" */'crm-property-history/CrmPropertyHistoryCard'),
    lazyLoadedTranslations: crmPropertyHistoryTranslations
  },
  PURCHASE_DATA_WELL: {
    cardComponentLoader: () => import( /* webpackChunkName: "purchase-history-lib" */'purchase-history-lib').then(mod => mod.PurchaseDataWell),
    lazyLoadedTranslations: purchaseHistoryCardsTranslations
  },
  RELATIVE_TIMELINE: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmRelativeTimelineCard" */'crm-relative-timeline/CrmRelativeTimelineCard'),
    lazyLoadedTranslations: crmRelativeTimelineTranslations
  },
  SIMPLE_DEADLINE: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmSimpleDeadlineCard" */'crm-simple-deadline/CrmSimpleDeadlineCard'),
    lazyLoadedTranslations: crmSimpleDeadlineTranslations
  },
  STAGE_TRACKER: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmStageTrackerCard" */'crm-stage-tracker/CrmStageTrackerCard'),
    lazyLoadedTranslations: crmStageTrackerTranslations
  },
  STATISTICS: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmStatisticsCard" */'crm-statistics/CrmStatisticsCard'),
    lazyLoadedTranslations: crmStatisticsTranslations
  },
  SUBSCRIPTION_BILLING_INFO: {
    cardComponentLoader: () => import( /* webpackChunkName: "SubscriptionSidebarBillingInfoCard" */'commerce-subscription-cards-lib/cards/billing-info').then(mod => mod.SubscriptionSidebarBillingInfoCard),
    lazyLoadedTranslations: subscriptionSidebarCardTranslations
  },
  SUBSCRIPTION_PAYMENTS_TIMELINE: {
    cardComponentLoader: () => import( /* webpackChunkName: "SubscriptionSidebarTimelineCard" */'commerce-subscription-cards-lib/cards/timeline').then(mod => mod.SubscriptionSidebarTimelineCard),
    lazyLoadedTranslations: subscriptionSidebarCardTranslations
  },
  TASKS_TIMELINE: {
    cardComponentLoader: () => import('card-tasks-timeline-lib/TasksTimelineCard'),
    lazyLoadedTranslations: tasksTimelineCardTranslations
  },
  UPCOMING_ACTIVITIES: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmUpcomingActivitiesCard" */'crm-events-viz-cards/crmUpcomingActivitiesCard/CrmUpcomingActivitiesCard'),
    lazyLoadedTranslations: crmEventsVizTimelineCardTranslations
  },
  NATIVE_INVOICES_HISTORY: {
    cardComponentLoader: () => import( /* webpackChunkName: "invoices-history-card-lib" */'invoices-history-card-lib').then(mod => mod.InvoicesHistoryCard),
    lazyLoadedTranslations: invoicesHistoryCardTranslations
  },
  HELP_DESK_TEAM_SUMMARY: {
    cardComponentLoader: () => import( /* webpackChunkName: "TeamSummaryCard" */'help-desk-insights-lib-crm-cards/public/TeamSummaryCard'),
    lazyLoadedTranslations: helpDeskInsightsCardsTranslations
  },
  HELP_DESK_CALLING_PERFORMANCE: {
    cardComponentLoader: () => import( /* webpackChunkName: "CallingPerformanceCard" */'help-desk-insights-lib-crm-cards/public/CallingPerformanceCard'),
    lazyLoadedTranslations: helpDeskInsightsCardsTranslations
  },
  HELP_DESK_AGENT_AVAILABILITY: {
    cardComponentLoader: () => import( /* webpackChunkName: "AgentAvailabilityCard" */'help-desk-insights-lib-crm-cards/public/AgentAvailabilityCard'),
    lazyLoadedTranslations: helpDeskInsightsCardsTranslations
  },
  HELP_DESK_SLA_PROGRESS: {
    cardComponentLoader: () => import( /* webpackChunkName: "SLAProgressCard" */'help-desk-insights-lib-crm-cards/public/SLAProgressCard'),
    lazyLoadedTranslations: helpDeskInsightsCardsTranslations
  },
  HELP_DESK_TICKETS_BY_CHANNEL: {
    cardComponentLoader: () => import( /* webpackChunkName: "TicketsByChannelCard" */'help-desk-insights-lib-crm-cards/public/TicketsByChannelCard'),
    lazyLoadedTranslations: helpDeskInsightsCardsTranslations
  },
  PARTNER_CLIENT_UNIFIED_USAGE_SCORE: {
    cardComponentLoader: () => import( /* webpackChunkName: "UnifiedUsageScoreCard" */'card-partner-client-unified-usage-score-lib/UnifiedUsageScoreCard'),
    lazyLoadedTranslations: unifiedUsageScoreCardTranslations
  },
  PARTNER_CLIENT_PRODUCT_USAGE_OUTCOMES: {
    cardComponentLoader: () => import( /* webpackChunkName: "ProductUsageOutcomesCard" */'card-partner-client-portal-usage-outcomes-lib/ProductUsageOutcomesCard'),
    lazyLoadedTranslations: portalUsageOutcomesCardTranslations
  },
  GUIDED_SELLING_DEAL_SUMMARY: {
    cardComponentLoader: () => import( /* webpackChunkName: "GuidedSellingDealSummaryCard" */'deal-summary-components/GuidedSellingDealSummaryCard'),
    lazyLoadedTranslations: guidedSellingDealSummaryTranslations
  },
  QUICK_ACTIONS: {
    cardComponentLoader: () => import( /* webpackChunkName: "CrmQuickActionsCard" */'crm-quick-actions/CrmQuickActionsCard'),
    lazyLoadedTranslations: crmActionsTranslations
  },
  WORKFLOWS_INVOKER: {
    cardComponentLoader: () => import( /* webpackChunkName: "WorkflowsInvokerCard" */'automation-ui-crm-card-workflows-invoker/WorkflowsInvokerCard'),
    lazyLoadedTranslations: workflowsInvokerCardTranslations
  }
};