'use es6';

import { makeAsyncCard } from 'customer-data-sidebar/async';
import UniversalSidebarCardAdapter from '../../card/universal/UniversalSidebarCardAdapter';
import { withUniversalSidebarCardQuery } from '../../card/universal/withUniversalSidebarCardQuery';
import { profileCompanyHierarchyGqlConfig } from '../../../profile/sidebar/relatedCompany/profileCompanyHierarchyGqlConfig';
import RelatedCompanyHeader from '../../../profile/sidebar/cardTitle/RelatedCompanyHeader';
const CompanyParentChildCard = {
  Content: UniversalSidebarCardAdapter(withUniversalSidebarCardQuery(makeAsyncCard({
    chunkName: 'sidebar-company-hierarchy',
    loader: () => import( /* webpackChunkName: "sidebar-company-hierarchy" */'card-company-parent-child-lib/crm_ui/profile/sidebar/relatedCompany/ProfileCompanyHierarchyWrapper')
  }), {
    gqlConfig: profileCompanyHierarchyGqlConfig,
    key: 'CompanyParentChild'
  })),
  Title: RelatedCompanyHeader
};
export default CompanyParentChildCard;