// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'disp... Remove this comment to see the full error message
import dispatcher from 'dispatcher/dispatcher';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'gene... Remove this comment to see the full error message
import { defineFactory } from 'general-store';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { EMPTY } from 'crm_data/flux/LoadingStatus';
import { Map as ImmutableMap } from 'immutable';
export const ENGAGEMENT_HISTORY_CLEARED = 'ENGAGEMENT_HISTORY_CLEARED';
export const ENGAGEMENT_HISTORY_UPDATED = 'ENGAGEMENT_HISTORY_UPDATED';

/** Example state
 *  Map({
 *    value: Map({
 *      engagemetOrInteractionType: NOTE,
 *      objectId: 123,
 *      objectType: ENGAGEMENT
 *    })
 *  })
 */
export default defineFactory().defineName('EngagementHistory').defineGetInitialState(() => ImmutableMap({
  value: EMPTY
})).defineGet(state => {
  return state.get('value');
}).defineResponseTo(ENGAGEMENT_HISTORY_CLEARED, state => {
  return state.set('value', EMPTY);
}).defineResponseTo(ENGAGEMENT_HISTORY_UPDATED, (state, newState) => {
  return state.set('value', newState);
}).register(dispatcher);